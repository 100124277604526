import { Backdrop, Box, Stack, Avatar, AppBar, Toolbar, Grid, Breadcrumbs, Link, Typography, Stepper, Step, StepLabel, stepConnectorClasses, StepConnector, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import infossLogo from '../../../Assets/Icon/infossLogo.svg';

import ApiService from '../../../services/ApiService'
import LoadingSpinner from '../../../components/Components/LoadingSpinner'
import { Icon } from '../../../components/ui';
import LiveClock from '../../../components/Clocks/LiveClock';
import PropTypes from 'prop-types';

const ColorLibConnector = styled(StepConnector)(() => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: 'var(--Blue-Button-Primary-1, #0087C2);',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: 'var(--Blue-Button-Primary-1, #0087C2);',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 10  ,
        border: 0,
        backgroundColor: '#CACED8',
        borderRadius: 1,
    },
}));

const ColorLibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: '#CACED8',
    zIndex: 1,
    color: '#F2FAFF',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px', // default font size for step numbers
    ...(ownerState.active && {
        border: '5px solid #0087C2',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        backgroundColor: '#F2FAFF',
    }),
    ...(ownerState.completed && {
        border: '5px solid #0087C2',
        backgroundColor: '#F2FAFF',
    }),
    '& .step-number': {
        overflow: 'hidden',
        color: 'var(--Disable-Grey-Text-2, #7F888C)',
        leadingTrim: 'both',
        textEdge: 'cap',
        textOverflow: 'ellipsis',
        fontFamily: 'Nunito',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '34px',
        letterSpacing: '0.36px',
        ...(ownerState.active && {
            color: '#0087C2',
            overflow: 'hidden',
            leadingTrim: 'both',
            textEdge: 'cap',
            textOverflow: 'ellipsis',
            fontFamily: 'Nunito',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '34px',
            letterSpacing: '0.36px',
        }),
        ...(ownerState.completed && {
            color: '#0087C2',
            overflow: 'hidden',
            leadingTrim: 'both',
            textEdge: 'cap',
            textOverflow: 'ellipsis',
            fontFamily: 'Nunito',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '34px',
            letterSpacing: '0.36px',
        }),
    },
}));

function ColorLibStepIcon(props) {
    const { active, completed, className, icon } = props;

    return (
        <ColorLibStepIconRoot ownerState={{ completed, active }} className={className}>
            {
                completed? 
                    <Icon
                        iconName={'outline-select'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                width: '24px',
                                height: '24px',
                                alignItems: 'center',
                            }
                        }} 
                    /> 
                : 
                    <div 
                        className="step-number" 
                        style={{
                            cursor: 'default',
                            userSelect: 'none',
                        }}
                    >
                        {icon}
                    </div>
            }
        </ColorLibStepIconRoot>
    );
}

ColorLibStepIcon.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    completed: PropTypes.bool,
    icon: PropTypes.node,
};

const TrackingTicket = () => {
    const { id } = useParams();
    let date = new Date();
    const [dataTicket, setDataTicket] = useState([]);
    const [activeStep, setActiveStep] = React.useState(0);
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);

    let countRefresh = 0;

    useEffect(() => {
        if (countRefresh === 0) {
            document.title = 'Infoss Tracking Ticket - ' + id
            countRefresh++;
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = () => {
        ApiService.PostByTicketId('ToDo/ToDo', id)
            .then((response) => {
                if (response.data.code === 200) {
                    var data = response.data.data.toDo
                    
                    data.sort(function(a, b) {
                        var keyA = new Date(a.sequence),
                        keyB = new Date(b.sequence);
                        if (keyA < keyB) return -1;
                        if (keyA > keyB) return 1;
                        return 0;
                    });

                    const groupedData = data.reduce((acc, item) => {
                        const { name } = item;
                        if (!acc[name]) {
                            acc[name] = [];
                        }
                        acc[name].push(item);
                        return acc;
                    }, {});
                      
                    let result = Object.keys(groupedData).map(key => ({
                        name: key,
                        data: groupedData[key]
                    }));

                    result.forEach(element => {
                        if(element.name === 'Booking Confirmation')
                        {
                            let createIndex = -1;
                            let submitData = null;

                            for (let i = 0; i < element.data.length; i++) {
                                if (element.data[i].action === "Create" && element.data[i].createdOn === "0001-01-01T00:00:00") 
                                {
                                    createIndex = i;
                                } 
                                else if (element.data[i].action === "Submit" && element.data[i].createdOn !== "0001-01-01T00:00:00") 
                                {
                                    submitData = element.data[i];
                                }
                        
                                if (createIndex !== -1 && submitData) 
                                {
                                    break;
                                }
                            }
                        
                            if (createIndex !== -1 && submitData) 
                            {
                                const updatedData = { ...element.data[createIndex], ...submitData, action: data[createIndex].action };
                                element.data[createIndex] = updatedData;
                            }
                        }
                    });
                    setDataTicket(result)
                    
                    const firstInvalidCreatedOnGroup = result.find(group => 
                        group.data.some(item => item.createdOn === '0001-01-01T00:00:00')
                    );
                    const endedStep = result.findIndex(group => 
                        group.name === firstInvalidCreatedOnGroup.name
                    );
                    setActiveStep(endedStep)
                    setIsLoadingBackDrop(false)
                }
                else{
                    setIsLoadingBackDrop(false)
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false)
                console.error('error saat fetch', error)
            })
    };

    return (
        <>
            {/* <Box sx={{ maxWidth: 400, p: 5 }}>
                <div className='mb-3'>
                    <h5 style={{fontWeight: 'bold'}}>
                        {
                            isLoadingBackDrop?
                                <Skeleton animation="wave" width={300} height={35} />
                            :
                                <>Ticket Number {id}</>
                        }
                    </h5>
                </div>

                <Stepper activeStep={activeStep} orientation="vertical">
                    {
                        dataTicket.length > 0?
                            dataTicket.map((step, index) => (
                                <Step key={index}>
                                    <StepLabel
                                        optional={
                                            index === 8 ? (
                                                <Typography variant="caption">Last Step</Typography>
                                            ) : null
                                        }
                                    >
                                        <h6>{step.action} {step.name}</h6>  
                                        {
                                            step.createdOn === '0001-01-01T00:00:00'?
                                                <>On Progress</> 
                                            :
                                                <>
                                                    {new Date(step.createdOn).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "2-digit", }) + ' | ' + new Date(step.createdOn).toLocaleTimeString("sv-se", { hour: "2-digit", minute: "2-digit" })}
                                                    
                                                    <div className='mt-1'>
                                                        <i>{step.description}</i> 
                                                    </div>
                                                </>
                                        }
                                    </StepLabel>
                                    <StepContent>
                                        <Typography>{step.createdOn}</Typography>
                                        <Box sx={{ mb: 2 }}>
                                            <div>
                                                <Button
                                                    variant="contained"
                                                    onClick={handleNext}
                                                    sx={{ mt: 1, mr: 1 }}
                                                >
                                                    {index === dataTicket.length - 1 ? 'Finish' : 'Continue'}
                                                </Button>
                                                <Button
                                                    disabled={index === 0}
                                                    onClick={handleBack}
                                                    sx={{ mt: 1, mr: 1 }}
                                                >
                                                    Back
                                                </Button>
                                            </div>
                                        </Box>
                                    </StepContent>
                                </Step>
                            ))
                        :
                            <>
                                <Skeleton animation="wave" sx={{ mt: 2 }} variant="rectangular" width={300} height={100} />
                                
                                <Skeleton animation="wave" sx={{ mt: 2 }} variant="rectangular" width={300} height={100} />
                                
                                <Skeleton animation="wave" sx={{ mt: 2 }} variant="rectangular" width={300} height={100} />
                                
                                <Skeleton animation="wave" sx={{ mt: 2 }} variant="rectangular" width={300} height={100} />
                                
                                <Skeleton animation="wave" sx={{ mt: 2 }} variant="rectangular" width={300} height={100} />
                                
                                <Skeleton animation="wave" sx={{ mt: 2 }} variant="rectangular" width={300} height={100} />
                                
                                <Skeleton animation="wave" sx={{ mt: 2 }} variant="rectangular" width={300} height={100} />
                                
                                <Skeleton animation="wave" sx={{ mt: 2 }} variant="rectangular" width={300} height={100} />
                                
                                <Skeleton animation="wave" sx={{ mt: 2 }} variant="rectangular" width={300} height={100} />
                                
                                <Skeleton animation="wave" sx={{ mt: 2 }} variant="rectangular" width={300} height={100} />
                            </>
                    }
                </Stepper>
                {activeStep === dataTicket.length && (
                    <Paper square elevation={0} sx={{ p: 3 }}>
                        <Typography>All steps completed - you&apos;re finished</Typography>
                        <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                            Reset
                        </Button>
                    </Paper>
                )}
            </Box> */}

            <Box 
                sx={{
                    background: 'var(--Disable-Grey-1, #E4E8EC)',
                    width: '100vw',
                    height: '100vh',
                }}
            >
                <div>
                    <Box sx={{ flexGrow: 1, alignContent: 'center', alignItems: 'center', background: '#0087C2' }} style={{ width: '100%', padding: '0px 8px', height: 'fit-content' }}>
                        <AppBar position="static" sx={{ boxShadow: 0, backgroundColor: 'transparent' }}>
                            <Toolbar sx={{ p: 0 }}>
                                <Stack direction={'row'} spacing={'24px'} sx={{ alignItems: 'center' }}>
                                    <Avatar 
                                        src={infossLogo}
                                        sx={{ width: 'fit-content', height: '40.25px' }}
                                        variant='square'
                                        alt='.'
                                    />
                                </Stack>
                            </Toolbar>
                        </AppBar>
                    </Box>
                </div>

                <div 
                    style={{ 
                        padding: '24px 16px 8px', 
                    }}
                >
                    <div>
                        <Box 
                            sx={{ 
                                padding: '0px 16px 8px', 
                                background: '#F2F2F2',
                                borderRadius: '12px',
                                boxShadow: '0px 8px 8px 0px #0000001A',
                                height: 'fit-content'
                            }}
                        >
                            <Grid container spacing={0} direction="column">
                                <Grid item xs={12}>
                                    <Grid container item direction="row" alignItems={'center'} sx={{ maxHeight: 'fit-content'}}>
                                        <Grid item xs={12} sx={{ marginTop: '4px' }}>
                                            <Stack direction={'column'} spacing = {'3px'}>
                                                <Breadcrumbs
                                                    separator={
                                                        <Icon 
                                                            iconName={'arrow-right'} 
                                                            svgIconProps={{
                                                                sx: {
                                                                    width: '6px',
                                                                    height: '6px',
                                                                    alignItems: 'center',
                                                                    padding: '0px',
                                                                    margin: '12px 0px 8px'
                                                                }
                                                            }} 
                                                        />
                                                    } 
                                                    sx={{
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}
                                                    aria-label="breadcrumb"
                                                >
                                                    <Link
                                                        key = {0} 
                                                        underline="hover" 
                                                        color="inherit" 
                                                        href={'/Infoss/Tracking/' + id}
                                                        sx = {{ 
                                                            fontFamily: 'Nunito',
                                                            fontSize: '10px',
                                                            letterSpacing: '0em',
                                                            color: '#0087C2', 
                                                            fontWeight: '700',
                                                            userSelect: 'none',
                                                        }}
                                                    >
                                                        Document Tracking
                                                    </Link>
                                                </Breadcrumbs>
                                                
                                                <div 
                                                    style={{
                                                        height: '1px',
                                                        border: '1px solid #DDDDDD',
                                                        width: 'auto',
                                                    }} 
                                                />

                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Nunito',
                                                        fontSize: '16px',
                                                        fontWeight: '700',
                                                        letterSpacing: '0em',
                                                        textAlign: 'left',
                                                        color: '#083A50',
                                                        marginTop: '4px !important',
                                                        cursor: 'default',
                                                        userSelect: 'none',
                                                    }}
                                                >
                                                    Document Tracking - Ticket Number: {id}
                                                </Typography>
                                                
                                                <Stack direction={'row'} spacing={1} alignItems="center">
                                                    <Typography
                                                        sx={{
                                                            color: 'var(--Primary-One, #083A50)',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '14px',
                                                            fontstyle: 'normal',
                                                            fontWeight: '600',
                                                            lineHeight: 'normal',
                                                            cursor: 'default',
                                                            userSelect: 'none',
                                                        }}
                                                    >
                                                        { date.toLocaleDateString("en-US", { year: "numeric", month: "long", day: "2-digit", }) }
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            color: 'var(--Primary-One, #083A50)',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '14px',
                                                            fontstyle: 'normal',
                                                            fontWeight: '500',
                                                            lineHeight: 'normal',
                                                            cursor: 'default',
                                                            userSelect: 'none',
                                                        }}
                                                    >
                                                        { ' | ' }
                                                    </Typography>
                                                    <LiveClock />
                                                    <Typography
                                                        sx={{
                                                            color: 'var(--Primary-One, #083A50)',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '12px',
                                                            fontstyle: 'normal',
                                                            fontWeight: '500',
                                                            lineHeight: 'normal',
                                                            cursor: 'default',
                                                            userSelect: 'none',
                                                        }}
                                                    >
                                                        Last Updated At: { date.toLocaleTimeString("sv-se", { hour: "2-digit", minute: "2-digit", second: "2-digit" }) }
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>

                    <div
                        style={{
                            padding: '16px 0px 0px'
                        }}
                    >
                        <Box 
                            sx={{ 
                                padding: '28px 18px', 
                                background: '#F2F2F2',
                                borderRadius: '12px',
                                boxShadow: '0px 8px 8px 0px #0000001A',
                                height: 'fit-content'
                            }}
                        >
                            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorLibConnector />}>
                                {
                                    dataTicket.map((data, index) => (
                                        <Step 
                                            key={index} 
                                            sx={{ 
                                                alignItems: 'center', 
                                                justifyContent: 'center' 
                                            }}
                                        >
                                            <StepLabel 
                                                StepIconComponent={ColorLibStepIcon} 
                                                sx={{ 
                                                    alignItems: 'center !important', 
                                                    justifyContent: 'center !important', 
                                                    width: '100%',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                <Grid 
                                                    container 
                                                    sx={{ 
                                                        justifyContent: 'center', 
                                                        width: '100%', 
                                                        marginTop: '20px !important',
                                                    }}
                                                >
                                                    <Grid item xs={12} lg={12} sm={12} md={12} sx={{ justifyContent: 'center', width: '100%' }}>
                                                        <span 
                                                            style={{
                                                                width: '100%',
                                                                display: 'grid',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}
                                                        >
                                                            <div 
                                                                style={{ 
                                                                    position: 'relative',
                                                                    width: 'fit-content', 
                                                                    borderRadius: '8px', 
                                                                    background: index > activeStep? 'transparent' : '#E8F7FF', 
                                                                    padding: '16px', 
                                                                    display: 'grid',
                                                                    boxShadow: index === activeStep? '0px 4px 25px 0px rgba(159, 249, 255, 0.80)' : 'null',
                                                                }}
                                                            >
                                                            {
                                                                index === activeStep && (
                                                                    <div 
                                                                        style={{ 
                                                                            content: '""', 
                                                                            position: 'absolute',
                                                                            top: 0, 
                                                                            left: 0, 
                                                                            right: 0, 
                                                                            bottom: 0, 
                                                                            borderRadius: '8px', 
                                                                            padding: '2px', 
                                                                            background: 'linear-gradient(90deg, #229ACE 0%, #005E92 100%)', 
                                                                            WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)', 
                                                                            WebkitMaskComposite: 'xor', 
                                                                            maskComposite: 'exclude',
                                                                        }} 
                                                                    />
                                                                )
                                                            }
                                                            {
                                                                index < activeStep && (
                                                                    <div 
                                                                        style={{ 
                                                                            content: '""', 
                                                                            position: 'absolute',
                                                                            top: 0, 
                                                                            left: 0, 
                                                                            right: 0, 
                                                                            bottom: 0, 
                                                                            borderRadius: '8px', 
                                                                            padding: '2px', 
                                                                            background: '#0087C2', 
                                                                            WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)', 
                                                                            WebkitMaskComposite: 'xor', 
                                                                            maskComposite: 'exclude' 
                                                                        }} 
                                                                    />
                                                                )
                                                            }
                                                            {
                                                                index > activeStep && (
                                                                    <div 
                                                                        style={{ 
                                                                            content: '""', 
                                                                            position: 'absolute',
                                                                            top: 0, 
                                                                            left: 0, 
                                                                            right: 0, 
                                                                            bottom: 0, 
                                                                            borderRadius: '8px', 
                                                                            padding: '2px', 
                                                                            background: '#CACED8', 
                                                                            WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)', 
                                                                            WebkitMaskComposite: 'xor', 
                                                                            maskComposite: 'exclude' 
                                                                        }} 
                                                                    />
                                                                )
                                                            }
                                                                <Typography
                                                                    sx={{
                                                                        color: 
                                                                            index === activeStep? 
                                                                                '#0087C2' 
                                                                            : 
                                                                                index > activeStep? 
                                                                                    'var(--Blue-Primary-2, #4F6B77)' 
                                                                                : 
                                                                                    'var(--Blue-Primary-1, #083A50)'
                                                                        ,
                                                                        fontFamily: 'Nunito',
                                                                        fontSize: '16px',
                                                                        fontStyle: 'normal',
                                                                        textAlign: 'center',
                                                                        fontWeight: '700',
                                                                        lineHeight: '12px',
                                                                        cursor: 'default',
                                                                        userSelect: 'none',
                                                                    }}
                                                                >
                                                                    {data.name}
                                                                </Typography>
                                                                    
                                                                <hr 
                                                                    style={{ 
                                                                        strokeWidth: '1px',
                                                                        borderStyle: 'dashed',
                                                                        color: '#A5ACBE !important',
                                                                        borderColor: '#A5ACBE !important',
                                                                        backgroundColor: '#A5ACBE !important',
                                                                        stroke: 'var(--Disable-Grey-Text-1, #A5ACBE)',
                                                                        opacity: '0.2',
                                                                        margin: '12px 0px 0px',
                                                                    }}
                                                                />

                                                                {
                                                                    data.data.map((newData, newIndex) => (
                                                                        <div 
                                                                            style={{ 
                                                                                marginTop: newIndex === 0? '14px' : '4px', 
                                                                                width: '215px', 
                                                                                alignItems: 'center', 
                                                                                justifyContent: 'center' 
                                                                            }}
                                                                        >
                                                                            <Stack direction={'row'} spacing={'6px'} sx={{ width: '100% !important' }}>
                                                                                <Stack direction={'column'} spacing={'6px'} sx={{ width: '100%' }}>
                                                                                    <Typography
                                                                                        key={newIndex}
                                                                                        sx={{
                                                                                            color: 
                                                                                                newData.createdOn !== '0001-01-01T00:00:00'? 
                                                                                                    'var(--Blue-Primary-1, #083A50)' 
                                                                                                : 
                                                                                                    'var(--Blue-Primary-2, #4F6B77)'
                                                                                            ,
                                                                                            fontFamily: 'Nunito',
                                                                                            fontSize: '12px',
                                                                                            fontStyle: 'normal',
                                                                                            textAlign: 'left !important',
                                                                                            fontWeight: '700',
                                                                                            lineHeight: '14px',
                                                                                            cursor: 'default',
                                                                                            userSelect: 'none',
                                                                                        }}
                                                                                    >
                                                                                        {data.name} {newData.action}
                                                                                    </Typography>
                                                                                    
                                                                                    {
                                                                                        newData.createdOn !== '0001-01-01T00:00:00'?
                                                                                            <>
                                                                                                <Typography
                                                                                                    key={newIndex}
                                                                                                    sx={{
                                                                                                        color: 'var(--Disable-Grey-Text-2, #7F888C)',
                                                                                                        fontFamily: 'Nunito',
                                                                                                        fontSize: '11px',
                                                                                                        fontStyle: 'normal',
                                                                                                        fontWeight: '400',
                                                                                                        lineHeight: '14px',
                                                                                                        textAlign: 'left !important',
                                                                                                        letterSpacing: '0.07px',
                                                                                                        cursor: 'default',
                                                                                                        userSelect: 'none',
                                                                                                    }}
                                                                                                >
                                                                                                    { new Date(newData.createdOn).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "2-digit", }) } | { new Date(newData.createdOn).toLocaleTimeString("sv-se", { hour: "2-digit", minute: "2-digit", second: "2-digit" }) }
                                                                                                </Typography>
                                                                                        
                                                                                                <Typography
                                                                                                    key={newIndex}
                                                                                                    sx={{
                                                                                                        color: 'var(--Disable-Grey-Text-2, #7F888C)',
                                                                                                        fontFamily: 'Nunito',
                                                                                                        fontSize: '11px',
                                                                                                        fontStyle: 'italic',
                                                                                                        fontWeight: '200',
                                                                                                        textAlign: 'left !important',
                                                                                                        lineHeight: '14px',
                                                                                                        letterSpacing: '0.07px',
                                                                                                        cursor: 'default',
                                                                                                        userSelect: 'none',
                                                                                                    }}
                                                                                                >
                                                                                                    {newData.description}
                                                                                                </Typography>
                                                                                            </>
                                                                                        :
                                                                                            <>
                                                                                                <Typography
                                                                                                    key={newIndex}
                                                                                                    sx={{
                                                                                                        color: 'var(--Disable-Grey-Text-2, #7F888C)',
                                                                                                        fontFamily: 'Nunito',
                                                                                                        fontSize: '11px',
                                                                                                        fontStyle: 'italic',
                                                                                                        fontWeight: '200',
                                                                                                        textAlign: 'left !important',
                                                                                                        lineHeight: '14px',
                                                                                                        letterSpacing: '0.07px',
                                                                                                        cursor: 'default',
                                                                                                        userSelect: 'none',
                                                                                                    }}
                                                                                                >
                                                                                                    On Process
                                                                                                </Typography>
                                                                                            </>
                                                                                    }
                                                                                </Stack>

                                                                                <Icon
                                                                                    iconName={'check-circle'}
                                                                                    svgIconProps={{
                                                                                        htmlColor: newData.createdOn === '0001-01-01T00:00:00'? '#A5ACBE' : '#168444',
                                                                                        sx: {
                                                                                            width: '24px',
                                                                                            height: '24px',
                                                                                            alignItems: 'center',
                                                                                        }
                                                                                    }} 
                                                                                /> 
                                                                            </Stack>

                                                                            {
                                                                                parseInt(newIndex) < parseInt(data.data.length) - 1?
                                                                                    <hr 
                                                                                        style={{ 
                                                                                            strokeWidth: '1px',
                                                                                            borderStyle: 'dashed',
                                                                                            color: '#A5ACBE !important',
                                                                                            borderColor: '#A5ACBE !important',
                                                                                            backgroundColor: '#A5ACBE !important',
                                                                                            stroke: 'var(--Disable-Grey-Text-1, #A5ACBE)',
                                                                                            opacity: '0.2',
                                                                                            margin: '8px 0px',
                                                                                        }}
                                                                                    />
                                                                                :
                                                                                    <></>
                                                                            }
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </span>
                                                    </Grid>
                                                </Grid>
                                            </StepLabel>
                                        </Step>
                                    ))
                                }
                            </Stepper>
                        </Box>
                    </div>
                </div>
            </Box>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoadingBackDrop}
            >
                <LoadingSpinner />
            </Backdrop>
        </>
    )
}

export default TrackingTicket