import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Box, Typography, Stack, Avatar, IconButton } from "@mui/material";

import ButtonService from "./ButtonService";
import GeneralButton from "./GeneralButton";
import ApiService from "../../services/ApiService";
import TableX from "../../components/Tables/TableX";
import freezeContainer from "../../Styles/freezeContainer";
import ModalDelete from "../../components/Modals/ModalDelete";
import ButtonComponent from "../../components/Buttons/ButtonComponent";
import LoadingSpinner from "../../components/Components/LoadingSpinner";
import HeaderInfo from "../../components/Components/HeaderInfo";
import { Icon, Paginate, showToast } from "../../components/ui";

const TempListMaster2 = (props) => {
  const btnPageRef = useRef(null)
  const btnTableRef = useRef(null)

  const [rowsCount, setRowsCount] = useState(50);
  const [numPage, setNumPage] = useState(1);
  const paramRegUserAccess = "regUserAccess/regUserAccess";
  const history = useNavigate();
  const [DataRegUserAccess, setDataRegUserAccess] = useState([]);
  const [goTo, setGoTo] = useState(1);

  const [isModalDelete, setIsModalDelete] = useState(false);
  const [titleModalDelete, setTitleModalDelete] = useState("");

  const BreadcrumbsItems = [
    { label: 'Infoss', link: '/Infoss', style: '' },
    { label: props.title, link: '/Infoss/' + props.title.replace(/ /g, ''), style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useEffect(() => {
    document.title = props.title + ' - ' + JSON.parse(localStorage.getItem('branchName'))
    getDataAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDataAccess = () => {
    let params = [];
    let pId = 0;
    let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
    let hasAccess = false

    const indexMenu = listMenu.findIndex((elm) => elm.code === props.menuCode)
    if (indexMenu !== -1) {
      pId = listMenu[indexMenu].id
      hasAccess = true
    }

    if (hasAccess) {
      params = [
        ...params,
        { attr: "permissionId", value: "" + pId },
        {
          attr: "userId",
          value: "" + JSON.parse(localStorage.getItem("userId"))
        },
        { attr: "roleId", value: "" + JSON.parse(localStorage.getItem("roleId")) }
      ];
      ApiService.IdentityPostByPage(paramRegUserAccess, "PAGE", 1, 100, params, [])
        .then((response) => {
          if (response.data.code === 200) {
            setDataRegUserAccess(response.data.data.userAccess);

            props.getData(1, 50);
          }
        })
        .catch(function (error) {
          console.error("error saat fetch", error);
          history('/Infoss');
        });
    }
    else {
      history('/Infoss');
    }
  };

  const ReloadData = () => {
    setGoTo(1);
    props.setStateX({});
    props.setFilter([]);
    props.setFilterBy([]);
    props.getData(1, 50);
  };

  const handleClickDelete = () => {
    if (isModalDelete === false) {
      setIsModalDelete(true);
    } else {
      setIsModalDelete(false);
    }
  };

  const DeleteData = () => {
    if (props.SelectedData.id === undefined) {
      showToast({ type: 'error', message: 'Please Select Data!' })
    } else {
      if (props.SelectedData.approved) {
        showToast({ type: 'error', message: 'Can not delete data, already approved!' })
      } else {
        setTitleModalDelete(props.title);
        handleClickDelete();
      }
    }
  };

  const Delete = () => {
    if (props.SelectedData.contactId === undefined) {
      showToast({ type: 'error', message: 'Please Select Data!' })
    } else {
      ApiService.deleteData(props.param, props.SelectedData.contactId)
        .then(() => {
          let msg = "Delete Data " + props.title + ' Success';
          showToast({ type: 'success', message: msg })
          window.location.reload();
        })
        .catch(function (error) {
          console.error("error saat create", error);
        });
    }
  };

  const PrintData = () => {
    var content = document.getElementById("tabel");
    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };
  const ExportPDF = () => {
    ButtonService.ExportPDF(props.data, props.title);
  };

  const ExportExcel = () => {
    ButtonService.ExportExcel(props.data, props.title);
  };

  const handleReset = () => {
    setGoTo(1);
    props.setStateX({});
    props.setFilter([]);
    props.setFilterBy([]);
    props.getData(1, 50);
  };

  const getContainerHeight = () => {
    const hTitle = 64
    const hHeader = 116
    const hPadding = 10 * 2
    const hBtnPage = btnPageRef?.current?.clientHeight || 28
    const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

    const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable

    return `${totalHeight}px`
  }

  const getContainerHeight2 = () => {
    const hTitle = 64
    const hHeader = 116
    const hPagination = 75
    const hPadding = 10 * 2
    const hTitle2 = 375
    const hBtnPage = btnPageRef?.current?.clientHeight || 28
    const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

    const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable + hPagination + hTitle2

    return `${totalHeight}px`
  }

  const ButtonComponents = () => {
    return (
      <GeneralButton
        ReloadData={ReloadData}
        DataRegUserAccess={DataRegUserAccess}
        toggleAdd={props.toggleAdd}
        toggleEdit={props.toggleEdit}
        DeleteData={DeleteData}
        PrintData={PrintData}
        ExportPDF={ExportPDF}
        ExportExcel={ExportExcel}
      />
    )
  }

  const onChangePaging = (value) => {
    setNumPage(value);
    props.getData(value, rowsCount, props.filter);
  }

  const onChangeLimit = (value) => {
    setNumPage(1);
    setRowsCount(value);
    props.getData(1, value, props.filter);
  }

  const CustomerLatestTransaction = (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        padding: '12px 24px 24px 24px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderRadius: '8px',
        background: '#F2F2F2',
        marginTop: '16px',
      }}
    >
      <Typography
        sx={{
          color: 'var(--Blue-Primary-1, #083A50)',
          fontFamily: 'Nunito',
          fontSize: '24px',
          fontStyle: 'normal',
          fontWeight: '600',
          lineHeight: 'normal',
        }}
      >
        Invoices
      </Typography>

      <Stack direction={'row'} spacing={'12px'} alignItems={'center'} sx={{ marginTop: '16px', width: '100%' }}>
        <Avatar style={{ width: 48, height: 48 }} sx={{ bgcolor: '#EAEAEA', }}>
          <Icon
            iconName={'documents'}
            svgIconProps={{
              htmlColor: '#0087C2',
              sx: {
                height: '24px',
                width: '24px',
                padding: '0px !important'
              }
            }}
          />
        </Avatar>

        <div style={{ width: '100%' }}>
          <Typography
            sx={{
              color: 'var(--Blue-Primary-1, #083A50)',
              fontFamily: 'Inter',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            INV00231456789
          </Typography>

          <Grid container direction="row" sx={{ marginTop: '8px', width: '100%', alignItems: 'center' }}>
            <Grid item xs={6} display={'flex'}>
              <div
                style={{
                  display: 'flex',
                  height: '12px',
                  padding: '10px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '10px',
                  flexShrink: '0',
                  borderRadius: '32px',
                  border: '0.5px solid #168621',
                  background: '#CBFFD0',
                }}
              >
                <Typography
                  sx={{
                    color: '#168621',
                    fontFamily: 'Inter',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: 'normal',
                  }}
                >
                  Paid
                </Typography>
              </div>
            </Grid>

            <Grid item xs={6} className='text-end' display={'flex'} justifyContent={'flex-end'}>
              <Typography
                sx={{
                  color: 'var(--Blue-Primary-1, #083A50)',
                  fontFamily: 'Nunito',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: 'normal',
                }}
              >
                Rp 2.432.567,00-
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Stack>
    </Box>
  )

  const CustomerDetail = (
    <Box
      sx={{
        display: 'flex',
        padding: '12px 24px 24px 24px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderRadius: '8px',
        background: '#F2F2F2',
        marginTop: '16px',
      }}
    >
      <Grid container direction="row" alignItems={'center'}>
        <Grid item xs={6} display={'flex'}>
          <Typography
            sx={{
              color: 'var(--Blue-Primary-1, #083A50)',
              fontFamily: 'Nunito',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Customer
          </Typography>
        </Grid>

        <Grid item xs={6} className='text-end' display={'flex'} justifyContent={'flex-end'}>
          <IconButton size="small">
            <Icon
              iconName={'three-dot-vert'}
              svgIconProps={{
                sx: {
                  height: '24px',
                  width: '24px',
                  padding: '0px !important'
                }
              }}
            />
          </IconButton>
        </Grid>
      </Grid>

      <Stack direction={'row'} spacing={'12px'} alignItems={'center'} sx={{ marginTop: '16px' }}>
        <Avatar style={{ width: 48, height: 48 }} sx={{ bgcolor: '#45454d', }} />

        <div>
          <Typography
            sx={{
              color: 'var(--Blue-Primary-1, #083A50)',
              fontFamily: 'Inter',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            {props.SelectedData?.contactName}
          </Typography>

          <Typography
            sx={{
              color: '#000',
              fontFamily: 'Inter',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
              marginTop: '8px'
            }}
          >
            {props.SelectedData?.contactId}
          </Typography>
        </div>
      </Stack>

      <div style={{ marginTop: '24px' }}>
        <Typography
          sx={{
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
          }}
        >
          Contact Detail
        </Typography>

        <Stack direction={'row'} spacing={'8px'} alignItems={'center'} sx={{ marginTop: '12px' }}>
          <Icon
            iconName={'mail'}
            svgIconProps={{
              sx: {
                height: '24px',
                width: '24px',
                padding: '0px !important'
              }
            }}
          />

          <Typography
            sx={{
              color: 'var(--Blue-Primary-1, #083A50)',
              fontFamily: 'Nunito',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: '600',
              textDecoration: 'underline',
              marginTop: '-8px !important'
            }}
          >
            {props.SelectedData?.contactName}
          </Typography>
        </Stack>

        <Stack direction={'row'} spacing={'8px'} alignItems={'center'} sx={{ marginTop: '12px' }}>
          <Icon
            iconName={'phone'}
            svgIconProps={{
              sx: {
                height: '24px',
                width: '24px',
                padding: '0px !important'
              }
            }}
          />

          <Typography
            sx={{
              color: 'var(--Blue-Primary-1, #083A50)',
              fontFamily: 'Nunito',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
              marginTop: '-8px !important'
            }}
          >
            {props.SelectedData?.contactId}
          </Typography>
        </Stack>
      </div>

      <Box
        sx={{
          display: 'flex',
          padding: '10px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '10px',
          width: '100%',
          borderRadius: '8px',
          border: '1px solid var(--Disable-Grey-Text-2, #7F888C)',
          marginTop: '16px',
          maxHeight: `calc(100vh - ${getContainerHeight2()})`,
        }}
        className="rounded-10 table-responsive"
      >
        <div style={{ width: '100%' }}>
          <Grid container direction="row">
            <Grid item xs={6} display={'flex'}>
              <Typography
                sx={{
                  color: 'var(--Disable-Grey-Text-2, #7F888C)',
                  fontFamily: 'Nunito',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: 'normal',
                }}
              >
                Bill To
              </Typography>
            </Grid>

            <Grid item xs={6} className='text-end' display={'flex'} justifyContent={'flex-end'}>
              <IconButton size="small" sx={{ padding: '0px !important' }}>
                <Icon
                  iconName={'three-dot-vert'}
                  svgIconProps={{
                    sx: {
                      height: '12px',
                      width: '12px',
                      padding: '0px !important'
                    }
                  }}
                />
              </IconButton>
            </Grid>
          </Grid>

          <Typography
            sx={{
              color: '#525657',
              fontFamily: 'Nunito',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
              marginTop: '4px'
            }}
          >
            {props.SelectedData?.contactName}
          </Typography>

          <Typography
            sx={{
              color: 'var(--Disable-Grey-Text-2, #7F888C)',
              fontFamily: 'Nunito',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
              marginTop: '2px'
            }}
          >
            {props.SelectedData?.contactAddress}
          </Typography>
        </div>
      </Box>
    </Box>
  )

  return (
    <Box className='w-100' sx={{ width: '100%', p: '24px 32px', ...freezeContainer }}>
      <Grid container spacing={0} direction="column">
        <HeaderInfo
          btnPageRef={btnPageRef}
          title={props.title}
          isJobType={false}
          BreadcrumbsItems={BreadcrumbsItems}
          ButtonComponents={ButtonComponents}
        />

        <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
          <Grid container spacing={'24px'} direction="row">
            <Grid
              item
              xs={props.SelectedData?.id === undefined ? 12 : 9}
              sm={props.SelectedData?.id === undefined ? 12 : 9}
              md={props.SelectedData?.id === undefined ? 12 : 9}
              lg={props.SelectedData?.id === undefined ? 12 : 9}
            >
              <div ref={btnTableRef}>
                {ButtonComponent.RefreshButton(
                  props.sortArr,
                  rowsCount,
                  props.columnData,
                  setGoTo,
                  handleReset,
                  props.setFilterBy,
                  props.filterBy,
                  props.setFilter,
                  setNumPage,
                  setRowsCount,
                  props.getData,
                  props.stateX,
                  props.setStateX
                )}
              </div>

              <div
                className="rounded-10 table-responsive"
                style={{
                  maxHeight: `calc(100vh - ${getContainerHeight()})`,
                  marginTop: '16px',
                  padding: '2px 16px',
                  borderRadius: '12px',
                  background: '#F2F2F2'
                }}
              >
                {props.isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    <TableX 
                      uniqueKey={props.menuCode === 'MRCON' ? 'contactId' : 'id'}
                      goTo={goTo}
                      rowsCount={rowsCount}
                      setNumPage={setNumPage}
                      setRowsCount={setRowsCount}
                      getData={props.getData}
                      columnData={props.columnData}
                      dataMap={props.dataMap}
                      SelectedData={props.SelectedData}
                      setSelectedData={props.setSelectedData}
                      setFilter={props.setFilter}
                      setFilterBy={props.setFilterBy}
                      CellDoubleClick={props.CellDoubleClick}
                      stateX={props.stateX}
                      setStateX={props.setStateX}
                      setSortArr={props.setSortArr}
                      setGoTo={setGoTo}
                    />
                  </>
                )}
              </div>

              <div style={{ marginTop: '16px' }}>
                <Paginate
                  page={numPage}
                  limit={rowsCount}
                  totalData={props.totalRows}
                  onChange={onChangePaging}
                  onChangeLimit={onChangeLimit}
                />
              </div>
            </Grid>

            <Grid
              item
              xs={props.SelectedData?.id === undefined ? 0 : 3}
              sm={props.SelectedData?.id === undefined ? 0 : 3}
              md={props.SelectedData?.id === undefined ? 0 : 3}
              lg={props.SelectedData?.id === undefined ? 0 : 3}
              hidden={props.SelectedData.id === undefined ? true : false}
            >
              <div>
                {CustomerLatestTransaction}
              </div>

              <div style={{ marginTop: '24px' }}>
                {CustomerDetail}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      
      {
        isModalDelete && (
          <ModalDelete
            isModal={isModalDelete}
            titleModal={titleModalDelete}
            handleClick={handleClickDelete}
            DeleteData={Delete}
          />
        )
      }
    </Box>
  );
};

export default TempListMaster2;
