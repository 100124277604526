import React from 'react';
import { Grid } from '@mui/material';

import ButtonComponent from '../../../../../../components/Buttons/ButtonComponent';

const ButtonAdditionalMemorial = (props) => {
    return (
        <div className="row">
            <div className='col-12'>
                <Grid container item spacing={1} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
                    {ButtonComponent.ReloadButton(props.ReloadData)}
                    {ButtonComponent.MemorialButton(props.Memorial, 'Memorial')}
                    {ButtonComponent.AdjustButton(props.Adjust, 'Adjust')}
                    {ButtonComponent.AssessmentButton(props.Assessment, 'Report')}
                    {ButtonComponent.ExcelButton(props.PrintExcel, 'Print Excel')}
                    {ButtonComponent.DeleteButton(props.DeleteData, 'Data')}
                </Grid>
            </div>
        </div>
    )
}

export default ButtonAdditionalMemorial