import { Box, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';

import TabTemporaryReceipt from './TabTemporaryReceipt';
import TabReceiptVoucher from './TabReceiptVoucher';

const TabsInvoice = (props) => {
    const handleChange = (event, newValue) => {
        props.setTabValue(newValue);
    };

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <TabContext value={props.tabValue} className='p-0'>
                    <Tabs
                        sx={{ width: '100%', height: 'fit-content', maxHeight: '32px !important' }}
                        value={props.tabValue}
                        onChange={handleChange}
                        className='p-0'
                        textColor="primary"
                        aria-label="full width tabs example"
                        variant="fullWidth"
                        TabIndicatorProps={{
                            sx: {
                                height: 'fit-content !important',
                                maxHeight: '32px !important',
                                backgroundColor: "#0087C2 !important",
                            },
                        }}
                        TabScrollButtonProps={{
                            sx: {
                                backgroundColor: "#0087C2 !important",
                                height: 'fit-content !important',
                                maxHeight: '32px !important',
                            },
                        }}
                    >
                        <Tab
                            sx={{
                                padding: '4px 12px !important',
                                minHeight: '35px',
                                textTransform: "none",
                                backgroundColor: '#D7F0FF',
                                '&.Mui-selected': {
                                    color: '#f2f2f2',
                                    backgroundColor: '#0087C2',
                                },
                            }}
                            value="TR"
                            label={
                                <Typography
                                    sx={{
                                        color: props.tabValue === "TR" ? '#F2FAFF' : '#083A50',
                                        fontFamily: 'Nunito',
                                        fontSize: '14px !important',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                    }}
                                >
                                    Temporary Receipt
                                </Typography>
                            }
                        />
                        <Tab
                            sx={{
                                padding: '4px 12px !important',
                                minHeight: '35px',
                                textTransform: "none",
                                backgroundColor: '#D7F0FF',
                                '&.Mui-selected': {
                                    color: '#f2f2f2',
                                    backgroundColor: '#0087C2',
                                },
                            }}
                            value="RV"
                            label={
                                <Typography
                                    sx={{
                                        color: props.tabValue === "RV" ? '#F2FAFF' : '#083A50',
                                        fontFamily: 'Nunito',
                                        fontSize: '14px !important',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                    }}
                                >
                                    Receipt Voucher
                                </Typography>
                            }
                        />
                    </Tabs>
                    
                    {
                        props.tabValue === "TR" && (
                            <TabPanel value="TR" className='p-0 mt-4'>
                                <TabTemporaryReceipt
                                    calculateTotalTR={props.calculateTotalTR}
                                    Printing={props.Printing}
                                    calculateTotalDifferent={props.calculateTotalDifferent}
                                    TRList={props.TRList}
                                    setTRList={props.setTRList}
                                    checkSelectedInvoice={props.checkSelectedInvoice}
                                    InvoiceList={props.InvoiceList}
                                    setInvoiceList={props.setInvoiceList}
                                    TRIdList={props.TRIdList}
                                    setTRIdList={props.setTRIdList}
                                />
                            </TabPanel>
                        )
                    }

                    {
                        props.tabValue === "RV" && (
                            <TabPanel value="RV" className='p-0 mt-4'>
                                <TabReceiptVoucher
                                    calculateTotalRV={props.calculateTotalRV}
                                    Printing={props.Printing}
                                    calculateTotalDifferent={props.calculateTotalDifferent}
                                    RVList={props.RVList}
                                    setRVList={props.setRVList}
                                    checkSelectedInvoice={props.checkSelectedInvoice}
                                    InvoiceList={props.InvoiceList}
                                    setInvoiceList={props.setInvoiceList}
                                    RVIdList={props.RVIdList}
                                    setRVIdList={props.setRVIdList}
                                />
                            </TabPanel>
                        )
                    }
                </TabContext>
            </Box>
        </>
    )
}

export default TabsInvoice;