import {
    Stack, Typography,
    // IconButton, InputAdornment 
} from '@mui/material';
import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { Icon, Input, Selection, TickBox } from '../ui';
import { defaultDate } from '../../utils/date';

const TableCheck = (props) => {
    const [filterJson, setFilterJson] = useState({})
    const [filterArr, setFilterArr] = useState([])
    const [isAll, setIsAll] = useState(false)

    const filterTable = (key, val, type = 'Standard') => {
        let filter = filterJson
        let temp = {
            field: key,
            data: val
        }
        let arr = []

        if (!filter[key]) {
            filter[key] = temp
            setFilterJson(filter)
        } else {
            filter[key].data = val
            setFilterJson(filter)
        }

        if (filter[key].data.length === 0) {
            delete filter[key]
            setFilterJson(filter)
        }

        for (const key in filter) {
            arr.push(filter[key])
        }

        setFilterArr(arr)
        props.setStateX({ ...props.stateX, [key]: val })

        if (type === 'Immediately') {
            props.setNumPage(1)
            props.setRowsCount(50)
            props.setGoTo(1)
            let arr = []
            let arrFilter = []
            let filter = { ...props.stateX, [key]: val }
            for (const [key, value] of Object.entries(filter)) {
                let realCol = key;
                let realValue = value;

                if (realValue === 'true') {
                    realValue = 'Yes';
                }
                else if (realValue === 'false') {
                    realValue = 'No';
                }
                else if (realValue === 'ACT') {
                    realValue = 'Active';
                }
                else if (realValue === 'DEL') {
                    realValue = 'Deleted';
                }

                realCol = props.columnData.find(el => el.column === key);

                arr.push(realCol.text + ': ' + realValue)

                arrFilter = [
                    ...arrFilter,
                    {
                        field: key,
                        data: value?.trim()
                    }
                ]
            }
            props.setFilterBy(arr)
            props.setFilter(arrFilter)
            if (props.id !== undefined) {
                props.getData(1, props.rowsCount, arrFilter, props.id, props.sortArr)
            }
            else {
                props.getData(1, props.rowsCount, arrFilter, props.sortArr)
            }
        }
    }

    const handleDoubleClick = (el) => {
        props.CellDoubleClick(el)
    }

    document.onkeydown = checkKey;
    function checkKey(e) {
        // console.log(e.keyCode)
        let currIndex = 0
        if (e.keyCode === 38 && props.SelectedData.index > 0) {
            //UP ARROW
            currIndex = props.SelectedData.index
            currIndex -= 1
        } else if (e.keyCode === 40 && props.SelectedData.index < props.rowsCount - 1) {
            //DOWN ARROW
            // alert(props.SelectedData.index)
            currIndex = props.SelectedData.index
            currIndex += 1
        } else if (e.keyCode === 13 && filterArr.length >= 0) {
            //PRESS ENTER
            //THEN DO FILTER
            if (filterArr.length > 0) {
                FilterData();
            }
        } else if (e.keyCode === 13 && props.SelectedData.index !== undefined) {
            handleDoubleClick()
        }

        const copyArr = [...props.dataMap]
        const changeSelectedData = (data) => {
            return data.index === currIndex
        }
        const result = copyArr.filter(changeSelectedData)
        props.setSelectedData(...result);
    }

    const FilterData = () => {
        props.setNumPage(1)
        props.setRowsCount(50)
        props.setGoTo(1)
        let arr = []
        let arrFilter = []
        let filter = props.stateX
        for (const [key, value] of Object.entries(filter)) {
            let realCol = key;
            let realValue = value;

            if (realValue === 'true') {
                realValue = 'Yes';
            }
            else if (realValue === 'false') {
                realValue = 'No';
            }
            else if (realValue === 'ACT') {
                realValue = 'Active';
            }
            else if (realValue === 'DEL') {
                realValue = 'Deleted';
            }

            realCol = props.columnData.find(el => el.column === key);

            arr.push(realCol.text + ': ' + realValue)

            arrFilter = [
                ...arrFilter,
                {
                    field: key,
                    data: value?.trim()
                }
            ]
        }
        props.setFilterBy(arr)
        props.setFilter(arrFilter)
        if (props.id !== undefined) {
            props.getData(1, props.rowsCount, arrFilter, props.id, props.sortArr)
        }
        else {
            props.getData(1, props.rowsCount, arrFilter, props.sortArr)
        }
    }

    const handleCheckAll = (e) => {
        let check = 0;
        let dtCek = [...props.dataCheck];

        setIsAll(e.target.checked);
        if (e.target.checked) {
            props.dataMap.forEach(element => {
                for (let i = 0; i < dtCek.length; i++) {
                    if (element[props.uniqueKey] === dtCek[i][props.uniqueKey]) {
                        check = 1;
                    }
                }

                if (check === 0) {
                    dtCek = [...dtCek, element];
                }
                check = 0;
            });
        }
        else {
            props.dataMap.forEach(element => {
                dtCek = dtCek.filter(dt => dt[props.uniqueKey] !== element[props.uniqueKey]);
            });
        }

        props.setDataCheck(dtCek);
    }

    const handleCheck = (e, el) => {
        let dtCek = []
        if (e.target.checked) {
            dtCek = [...props.dataCheck, el]
        } else {
            dtCek = props.dataCheck.filter(dt => dt[props.uniqueKey] !== el[props.uniqueKey])
            // alert('uncheck')
        }
        props.setDataCheck(dtCek)
        // console.log('data cek', dtCek)
    }

    const SortData = (key) => {
        if (props.sortArr) {
            let temp = {
                attr: key,
                method: 'asc'
            }
            var arr = []
            var cekArr = props.sortArr
            cekArr = cekArr.filter(el => el.attr === key)

            if (cekArr.length > 0) {
                arr = props.sortArr
                arr.forEach(el => {
                    if (el.attr === key) {
                        if (el.method === 'asc') {
                            el.method = 'desc'
                        } else {
                            arr = arr.filter(el => el.attr !== key)
                        }
                    }
                });
            } else {
                arr = [...props.sortArr, temp]
            }
            props.setSortArr(arr)

            let arrFilter = []
            let filter = props.stateX
            for (const [key, value] of Object.entries(filter)) {
                arrFilter = [...arrFilter, {
                    field: key,
                    data: value?.trim()
                }]
            }
            props.getData(1, props.rowsCount, arrFilter, arr)
            props.setNumPage(1)
            props.setGoTo(1)
            // CekSortStatus(key)
        }
    }

    const CekSortStatus = (key) => {
        if (props.sortArr) {
            const arr = props.sortArr.filter(el => el.attr === key)
            if (arr.length > 0) {
                if (arr[0].method === 'asc') {
                    return (
                        <Stack direction={'column'} spacing={'0px'}>
                            <Icon
                                iconName={'arrow-up'}
                                svgIconProps={{
                                    sx: {
                                        width: '8px',
                                        height: '8px',
                                        color: '#0096D8'
                                    }
                                }}
                            />

                            <Icon
                                iconName={'arrow-down'}
                                svgIconProps={{
                                    sx: {
                                        width: '8px',
                                        height: '8px',
                                        color: '#D8D8D8'
                                    }
                                }}
                            />
                        </Stack>
                    )
                }
                else {
                    return (
                        <Stack direction={'column'} spacing={'0px'}>
                            <Icon
                                iconName={'arrow-up'}
                                svgIconProps={{
                                    sx: {
                                        width: '8px',
                                        height: '8px',
                                        color: '#D8D8D8'
                                    }
                                }}
                            />

                            <Icon
                                iconName={'arrow-down'}
                                svgIconProps={{
                                    sx: {
                                        width: '8px',
                                        height: '8px',
                                        color: '#0096D8'
                                    }
                                }}
                            />
                        </Stack>
                    )
                }
            }
            else {
                return (
                    <Stack direction={'column'} spacing={'0px'}>
                        <Icon
                            iconName={'arrow-up'}
                            svgIconProps={{
                                sx: {
                                    width: '8px',
                                    height: '8px',
                                    color: '#D8D8D8'
                                }
                            }}
                        />

                        <Icon
                            iconName={'arrow-down'}
                            svgIconProps={{
                                sx: {
                                    width: '8px',
                                    height: '8px',
                                    color: '#D8D8D8'
                                }
                            }}
                        />
                    </Stack>
                )
            }
        }
    }

    return (
        <Table hover={!props?.static} className='table table-sm' style={{ '--bs-table-bg': '#F2F2F2' }}>
            <thead style={{ position: 'sticky', top: '-4px', 'color': '#F2F2F2', background: '#F2F2F2', zIndex: 1 }}>
                <tr style={{ borderTop: 'none' }}>
                    {
                        props.columnData.length > 0 ?
                            <th>
                                <TickBox
                                    key={0}
                                    checked={isAll}
                                    onChange={(e) => handleCheckAll(e)}
                                    onClick={(e) => handleCheckAll(e)}
                                    baseCheckboxProps={{
                                        sx: {
                                            padding: '0px 0px 0px 8px',
                                        }
                                    }}
                                    size={'small'}
                                />
                            </th>
                            :
                            null
                    }
                    {

                        props.columnData.map((el, index) => {
                            return (
                                <th
                                    key={index}
                                    hidden={el.hidden}
                                    style={{
                                        textAlign: el.textAlign,
                                        "whiteSpace": "nowrap",
                                        fontWeight: 'normal',
                                        maxWidth: 'fit-content',
                                        cursor: 'pointer',
                                        padding: '2px 8px',
                                        background: '#F2F2F2',
                                        fontSize: '12px !important'
                                    }}
                                >
                                    <label
                                        onClick={() => SortData(el.column)}
                                        style={{
                                            fontWeight: 'bold',
                                            cursor: 'pointer',
                                            alignItems: 'center',
                                            fontSize: '12px !important',
                                            maxWidth: 'fit-content'
                                        }}
                                    >
                                        <Stack
                                            direction={'row'}
                                            spacing={'6px'}
                                            sx={{
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                maxWidth: 'fit-content'
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Nunito',
                                                    fontSize: '11px !important',
                                                    fontWeight: '700',
                                                    lineHeight: '16px',
                                                    textAlign: 'left',
                                                    color: '#083A50',
                                                    maxWidth: 'fit-content',
                                                }}
                                            >
                                                {el.text}
                                            </Typography>

                                            {CekSortStatus(el.column)}
                                        </Stack>
                                    </label>
                                </th>
                            )
                        })
                    }
                </tr>
                <tr>
                    <td></td>
                    {
                        props.columnData.map((el, index) => {
                            if (el.text === 'Data Status') {
                                return (
                                    <td
                                        key={index}
                                        hidden={el.hidden}
                                        align={el.textAlign}
                                        style={{
                                            background: '#F2F2F2',
                                            padding: '24px 8px !important',
                                            maxWidth: '150px !important',
                                        }}
                                    >
                                        <Selection
                                            value={props.stateX[el.column]}
                                            fullWidth={true}
                                            onChange={(e) => filterTable(el.column, e.target.value, 'Immediately')}
                                            options={
                                                [
                                                    {
                                                        value: 'ACT',
                                                        label: 'Active'
                                                    },
                                                    {
                                                        value: 'DEL',
                                                        label: 'Deleted'
                                                    }
                                                ]
                                            }
                                            formControlProps={{
                                                sx: {
                                                    width: '100% !important',
                                                }
                                            }}
                                            formLabelProps={{
                                                sx: {
                                                    textAlign: el.textAlign,
                                                }
                                            }}
                                            menuItemProps={{
                                                sx: {
                                                    color: '#0087C2',
                                                    fontFamily: 'Nunito',
                                                    fontWeight: '700',
                                                    fontSize: '12px !important'
                                                }
                                            }}
                                            selectBaseProps={{
                                                sx: {
                                                    // padding: '8px 8px !important', 
                                                    border: '1px solid #0087C2',
                                                    borderRadius: '10px',
                                                    fontSize: '10px !important',
                                                    height: '27px',
                                                    backgroundColor: 'transparent',
                                                }
                                            }}
                                            selectInnerStyle={{
                                                backgroundColor: 'transparent',
                                                // padding: '0px 32px 0px 0px !important',
                                            }}
                                        />
                                    </td>
                                )
                            } else {
                                if (el.dataType === 'boolean' || el.dataType === 'bit') {
                                    return (
                                        <td key={index} hidden={el.hidden} align={el.textAlign} style={{ background: '#F2F2F2', padding: '24px 8px !important' }}>
                                            <Selection
                                                value={props.stateX[el.column]}
                                                fullWidth={true}
                                                onChange={(e) => filterTable(el.column, e.target.value, 'Immediately')}
                                                options={[
                                                    // {
                                                    //     value: 'ALL',
                                                    //     label: 'All'
                                                    // },
                                                    {
                                                        value: 'true',
                                                        label: 'Yes'
                                                    },
                                                    {
                                                        value: 'false',
                                                        label: 'No'
                                                    }
                                                ]}
                                                formControlProps={{
                                                    sx: {
                                                        width: '100% !important',
                                                    }
                                                }}
                                                formLabelProps={{
                                                    sx: {
                                                        textAlign: el.textAlign,
                                                    }
                                                }}
                                                menuItemProps={{
                                                    sx: {
                                                        color: '#0087C2',
                                                        fontFamily: 'Nunito',
                                                        fontWeight: '700',
                                                        fontSize: '12px !important'
                                                    }
                                                }}
                                                selectBaseProps={{
                                                    sx: {
                                                        // padding: '8px 8px !important', 
                                                        border: '1px solid #0087C2',
                                                        fontSize: '10px !important',
                                                        borderRadius: '10px',
                                                        height: '27px',
                                                        backgroundColor: 'transparent',
                                                    }
                                                }}
                                                selectInnerStyle={{
                                                    backgroundColor: 'transparent',
                                                    // padding: '0px 32px 0px 0px !important',
                                                }}
                                            />
                                        </td>
                                    )
                                } else {
                                    return (
                                        <td key={index} hidden={el.hidden} align={el.textAlign} style={{ background: '#F2F2F2', padding: '24px 8px !important' }}>
                                            <Input
                                                className="form-control col-search-form"
                                                value={props.stateX[el.column]}
                                                onChange={(e) => { filterTable(el.column, e.target.value, el.dataType === 'datetime' ? 'Immediately' : 'Standard'); }}
                                                textAlign={el.textAlign}
                                                formControlProps={{
                                                    sx: {
                                                        width: '100% !important',
                                                        minWidth: '65px !important',
                                                        maxWidth: '100% !important',
                                                    }
                                                }}
                                                type={el.dataType !== 'datetime' ? 'text' : 'date'}
                                                inputBaseProps={{
                                                    sx: {
                                                        width: '100% !important',
                                                        padding: '5px 10px !important',
                                                        border: '1px solid #0087C2',
                                                        fontSize: '10px !important',
                                                        borderRadius: '10px',
                                                        maxWidth: '100% !important',
                                                        minHeight: '27px !important',
                                                    },
                                                    // endAdornment: 
                                                    //     <InputAdornment position="end">
                                                    //         <IconButton sx={{ padding: '0px' }} onClick={() => FilterData()}>
                                                    //             <Icon 
                                                    //                 iconName={'search'}
                                                    //                 svgIconProps={{
                                                    //                     fontSize: 'small',
                                                    //                     htmlColor: '#083A50',
                                                    //                     sx: { 
                                                    //                         height: '16px', 
                                                    //                         width: '16px' 
                                                    //                     }
                                                    //                 }}
                                                    //             />
                                                    //         </IconButton>
                                                    //     </InputAdornment>
                                                }}
                                            />
                                        </td>
                                    )
                                }
                            }
                        })
                    }
                </tr>
            </thead>
            <tbody className="text-muted">
                {
                    props.dataMap.length > 0 ?
                        props.dataMap.map((el, index) => {
                            let tempStyle = 'text-dark'
                            if (props.SelectedData && el && props.SelectedData[props.uniqueKey] === el[props.uniqueKey]) {
                                tempStyle = "bg-infoss text-white"
                            } else if (el.rowStatus === 'DEL') {
                                tempStyle = "text-danger"
                            } else if (el.printing > 0) {
                                tempStyle = "text-secondary"
                            }

                            return (
                                <tr
                                    key={index}
                                    onClick={() => { props.setSelectedData(el); }}
                                    className={tempStyle}
                                    onDoubleClick={null}
                                    style={{ fontSize: '12px', alignItems: 'center' }}
                                >
                                    <td>
                                        <TickBox
                                            key={el[props.uniqueKey]}
                                            checked={props.dataCheck.filter(dt => dt[props.uniqueKey] === el[props.uniqueKey]).length > 0 ? true : false}
                                            onChange={(e) => handleCheck(e, el)}
                                            onClick={(e) => handleCheck(e, el)}
                                            baseCheckboxProps={{
                                                sx: {
                                                    padding: '0px 0px 0px 8px'
                                                }
                                            }}
                                            size={'small'}
                                        />
                                    </td>
                                    {
                                        props.columnData.map((headersEl, indexHeaders) => {
                                            let temp = el[headersEl.column]
                                            // let optionsDate = {
                                            //     year: "numeric",
                                            //     month: "short",
                                            //     day: "2-digit",
                                            // }

                                            if (headersEl.dataType === 'datetime' || headersEl.dataType === 'date') {
                                                if (el[headersEl.column] !== "0001-01-01T00:00:00" && new Date(el[headersEl.column]).getFullYear() !== new Date(defaultDate).getFullYear()) {
                                                    let tempDate = new Date(el[headersEl.column]);
                                                    var dateTemp = tempDate.toLocaleDateString("en-US", { day: "2-digit" });
                                                    var monthTemp = tempDate.toLocaleDateString("en-US", { month: "short" });
                                                    var yearTemp = tempDate.toLocaleDateString("en-US", { year: "numeric" });
                                                    temp = dateTemp + ' ' + monthTemp + ' ' + yearTemp;
                                                } else {
                                                    temp = '-'
                                                }
                                            }

                                            if (headersEl.dataType.toLowerCase() === 'currency') {
                                                let tempNumber = new Intl.NumberFormat(JSON.parse(localStorage.getItem("currency")), { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(`${el[headersEl.column]}`);
                                                temp = tempNumber;
                                            }

                                            if (el[headersEl.column] === true) {
                                                temp = 'Yes'
                                            } else if (el[headersEl.column] === false) {
                                                temp = 'No'
                                            }

                                            // if (headersEl.column === 'rowStatus') {
                                            //     if (el.rowStatus === 'DEL') {
                                            //         temp = "DEL"
                                            //     } else {
                                            //         temp = 'ACT'
                                            //     }
                                            // }
                                            return (
                                                <td
                                                    key={indexHeaders}
                                                    hidden={headersEl.hidden}
                                                    align={headersEl.textAlign}
                                                    style={{
                                                        verticalAlign: headersEl.verticalAlign,
                                                        padding: '8px 10px',
                                                        "whiteSpace": "nowrap",
                                                        // maxWidth: '150px'
                                                    }}
                                                >
                                                    {
                                                        headersEl.column === 'rowStatus' ?
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    width: 'fit-content',
                                                                    padding: '4px',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    borderRadius: '32px',
                                                                    background: temp === 'ACT' ? 'var(--Green-Button, #168444)' : 'var(--Red-Alert, #D20C0C)',
                                                                }}
                                                            >
                                                                <Icon
                                                                    iconName={temp === 'ACT' ? 'active' : 'inactive'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#EAEAEA',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                            :
                                                            <Typography
                                                                sx={{
                                                                    color: 'var(--Blue-Primary-1, #083A50)',
                                                                    fontFamily: 'Nunito',
                                                                    fontSize: '11px !important',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '400',
                                                                    lineHeight: 'normal',
                                                                    maxWidth: 'fit-content'
                                                                }}
                                                            >
                                                                {temp}
                                                            </Typography>
                                                    }
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                        :
                        <tr>
                            <td colSpan={20} className="text-center py-3 text-muted">
                                <Typography
                                    sx={{
                                        color: 'var(--Primary-One, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '16px',
                                        fontstyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: 'normal'
                                    }}
                                >
                                    Data Empty
                                </Typography>
                            </td>
                        </tr>
                }
            </tbody>
        </Table>
    )
}

export default TableCheck