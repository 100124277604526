import React, { forwardRef, useEffect, useState } from 'react'
import { Box, FormControlLabel, Grid, Radio, RadioGroup, Stack, MenuItem, IconButton, Backdrop, CardContent, Card, InputAdornment, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import ModalSearchCustomer from './components/ModalSearchCustomer';

import { useFormik } from 'formik';
import ApiService from '../../../../services/ApiService';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Icon, Input, Selection, showToast } from '../../../../components/ui';

const PayableAgingReport = forwardRef(() => {
  const history = useNavigate()

  const date = new Date()
  const dateString = date.toLocaleDateString("sv-se").split('T')[0]

  const [isLoading, setIsLoading] = useState(true)

  const [activeModal, setActiveModal] = useState('')
  const [selectedFirstCustomer, setSelectedFirstCustomer] = useState({})
  const [selectedSecondCustomer, setSelectedSecondCustomer] = useState({})

  const [dataRegUserAccess, setDataRegUserAccess] = useState([]);
  const [contactTypeList, setContactTypeList] = useState([]);

  const BreadcrumbsItems = [
    { label: 'Infoss', link: '/Infoss', style: '' },
    { label: 'Payable Aging Report', link: '/Infoss/PayableAgingReport', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useEffect(() => {
    document.title = 'Payable Aging Report - ' + JSON.parse(localStorage.getItem('branchName'));

    getDataAccess()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const errorToast = (location) => {
    showToast({ type: 'error', message: `Can't get ${location} data! please contact Administrator` })
  }

  const getDataAccess = () => {
    let params = [];
    let pId = 0;
    let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
    let hasAccess = false

    // TODO Change menu code
    const indexMenu = listMenu.findIndex((elm) => elm.code === 'OBOCO')
    if (indexMenu !== -1) {
      pId = listMenu[indexMenu].id
      hasAccess = true
    }

    if (hasAccess) {
      params = [
        ...params,
        { attr: "permissionId", value: "" + pId },
        { attr: "userId", value: "" + JSON.parse(localStorage.getItem("userId")) },
        { attr: "roleId", value: "" + JSON.parse(localStorage.getItem("roleId")) }
      ];

      ApiService.IdentityPostByPage('regUserAccess/regUserAccess', "PAGE", 1, 100, params, [])
        .then((response) => {
          if (response.data.code === 200) {
            setDataRegUserAccess(response.data.data.userAccess);

            getAdditionalData()
          }
        })
        .catch(function (error) {
          console.error("error saat fetch", error);
          history('/Infoss');
        });
    } else {
      history('/Infoss');
    }
  };

  const getAdditionalData = async () => {
    setIsLoading(true)
    try {
      const [resCT] = await Promise.all([
        ApiService.DynamicPostByPage('contactType/contactType', 'PAGE', 1, 999, [])
      ])

      if (resCT.data.code === 200) {
        let data = resCT.data.data.contactType
        data = data.filter((el) => el.id <= 10);
        const excludesData = ['AGENT', 'SHIPPER', 'CONSIGNEE']
        data = data.filter((el) => !excludesData.includes(el?.name?.toUpperCase()))
        setContactTypeList(data)

        formik.setFieldValue('customerType', data[0].id)
      } else {
        errorToast('contact type')
      }
    } catch (error) {
      errorToast('additional')
      console.log(error)
    }
    setIsLoading(false)
  }

  const handlePrint = (fileType, isOver) => {
    setIsLoading(true);
    let isRecap = 1;
    let contactId1 = 0;
    let contactId2 = 0;
    let payload = formik.values;

    if (formik.values.customer !== 'all' && (!selectedFirstCustomer?.id || !selectedSecondCustomer?.id)) {
      setIsLoading(false);
      return showToast({ type: 'error', message: 'Please select customer first.' })
    }

    if (payload.reportType === 'recapitulation') {
      isRecap = 1;
    }
    else {
      isRecap = 0;
    }

    if (payload.customer !== 'all') {
      if (selectedFirstCustomer?.contactId !== undefined) {
        contactId1 = selectedFirstCustomer.contactId;
      }

      if (selectedSecondCustomer?.contactId !== undefined) {
        contactId2 = selectedSecondCustomer.contactId;
      }
    }

    let variable = 'PrintAPA?typeReport=' + fileType;
    let params = [
      {
        "attr": "date",
        "value": '' + payload.date
      },
      {
        "attr": "customerTypeId",
        "value": '' + payload.customerType
      },
      {
        "attr": "contactId1",
        "value": '' + contactId1
      },
      {
        "attr": "contactId2",
        "value": '' + contactId2
      },
      {
        "attr": "isRecap",
        "value": '' + isRecap
      },
      {
        "attr": "isOver180days",
        "value": '' + isOver
      }
    ];

    ApiService.ReportPrint("Reports/Finance/Print/AccountPayable/ApAgingReport/", variable, params)
      .then((response) => {
        if (response.status === 200) {
          let rptFormat = "application/pdf";
          if (fileType === "xls") {
            rptFormat = "application/vnd.ms-excel"
          }
          const file = new Blob([response.data], { type: rptFormat });
          const fileURL = URL.createObjectURL(file);

          setIsLoading(false);
          showToast({ type: 'success', message: 'Print Payable Aging Report Success' });
          window.open(fileURL);
        }
        else {
          setIsLoading(false);
          showToast({ type: 'error', message: 'Can\'t Print Payable Aging Report, ' + response.data.message });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        showToast({ type: 'error', message: 'Can\'t Print Payable Aging Report, ' + error });
      });
  }

  const toggleModal = (modal) => {
    setActiveModal(modal)
  }

  const formik = useFormik({
    initialValues: {
      customCompany: 'company',
      reportType: 'recapitulation',
      date: dateString,
      customerType: 0,
      customer: 'all',
    },
    validationSchema: undefined,
  })

  const ButtonComponents = () => {
    return (
      <div className="row" spacing={'12px'}>
        <div className='col-12' spacing={'12px'}>
          <Grid container item spacing={'12px'} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
            {
              dataRegUserAccess.map((elm, index) => (
                <React.Fragment key={index}>
                  {elm.accessCode === 'PRI' && ButtonComponent.PrintButton(() => handlePrint('pdf', 0), '<6 months')}
                  {elm.accessCode === 'PRI' && ButtonComponent.ExcelButton(() => handlePrint('xls', 0), 'Excel <6 months')}
                  {elm.accessCode === 'PRI' && ButtonComponent.PrintButton(() => handlePrint('pdf', 1), '>6 months')}
                  {elm.accessCode === 'PRI' && ButtonComponent.ExcelButton(() => handlePrint('xls', 1), 'Excel >6 months')}
                </React.Fragment>
              ))
            }
          </Grid>
        </div>
      </div>
    )
  }

  return (
    <>
      <Box className='w-100' sx={{ width: '100%', p: 3 }}>
        <CustomToastContainer />
        <Grid container spacing={0} direction="column">
          <HeaderInfo
            title={'Payable Aging Report'}
            isJobType={false}
            BreadcrumbsItems={BreadcrumbsItems}
            ButtonComponents={ButtonComponents}
          />

          <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
            <div style={{ marginTop: '24px' }}>
              <Grid
                container
                spacing={'32px'}
              >
                <Grid
                  item
                  xl={6}
                  lg={6}
                  sm={6}
                  xs={12}
                >
                  <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                    <CardContent>
                      <Stack direction={'column'} spacing={'16px'}>
                        <Stack spacing={'16px'} direction="row">
                          <div>
                            <Typography
                              sx={{
                                color: 'var(--Primary-One, #083A50)',
                                fontFamily: 'Nunito',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: '19.6px',
                              }}
                            >
                              Custom Company
                            </Typography>

                            <RadioGroup
                              row
                              aria-labelledby="variant-option"
                              name="variant"
                              value={formik.values.customCompany}
                              onChange={(e) => {
                                formik.setFieldValue('customCompany', e.target.value)
                              }}
                            >
                              <FormControlLabel
                                sx={{
                                  color: 'var(--Blue-Primary-1, #083A50)',
                                  fontFamily: 'Nunito',
                                  fontSize: '12px !important',
                                  fontStyle: 'normal',
                                  fontWeight: '400',
                                  lineHeight: '20px',
                                }}
                                value='company'
                                control={<Radio size='small' />}
                                label={
                                  <Typography
                                    sx={{
                                      color: 'var(--Blue-Primary-1, #083A50)',
                                      fontFamily: 'Nunito',
                                      fontSize: '12px !important',
                                      fontStyle: 'normal',
                                      fontWeight: '500',
                                      lineHeight: '20px',
                                    }}
                                  >
                                    Per Company
                                  </Typography>
                                }
                              />

                              <FormControlLabel
                                sx={{
                                  color: 'var(--Blue-Primary-1, #083A50)',
                                  fontFamily: 'Nunito',
                                  fontSize: '12px !important',
                                  fontStyle: 'normal',
                                  fontWeight: '400',
                                  lineHeight: '20px',
                                }}
                                value='regional'
                                control={<Radio size='small' disabled />}
                                label={
                                  <Typography
                                    sx={{
                                      color: 'var(--Blue-Primary-1, #083A50)',
                                      fontFamily: 'Nunito',
                                      fontSize: '12px !important',
                                      fontStyle: 'normal',
                                      fontWeight: '500',
                                      lineHeight: '20px',
                                    }}
                                  >
                                    Per Regional
                                  </Typography>
                                }
                              />
                            </RadioGroup>
                          </div>

                          <div>
                            <Typography
                              sx={{
                                color: 'var(--Primary-One, #083A50)',
                                fontFamily: 'Nunito',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: '19.6px',
                              }}
                            >
                              Report Type
                            </Typography>

                            <RadioGroup
                              row
                              aria-labelledby="variant-option"
                              name="variant"
                              value={formik.values.reportType}
                              onChange={(e) => {
                                formik.setFieldValue('reportType', e.target.value)
                              }}
                            >
                              <FormControlLabel
                                sx={{
                                  color: 'var(--Blue-Primary-1, #083A50)',
                                  fontFamily: 'Nunito',
                                  fontSize: '12px !important',
                                  fontStyle: 'normal',
                                  fontWeight: '400',
                                  lineHeight: '20px',
                                }}
                                value='recapitulation'
                                control={<Radio size='small' />}
                                label={
                                  <Typography
                                    sx={{
                                      color: 'var(--Blue-Primary-1, #083A50)',
                                      fontFamily: 'Nunito',
                                      fontSize: '12px !important',
                                      fontStyle: 'normal',
                                      fontWeight: '500',
                                      lineHeight: '20px',
                                    }}
                                  >
                                    Recapitulation
                                  </Typography>
                                }
                              />

                              <FormControlLabel
                                sx={{
                                  color: 'var(--Blue-Primary-1, #083A50)',
                                  fontFamily: 'Nunito',
                                  fontSize: '12px !important',
                                  fontStyle: 'normal',
                                  fontWeight: '400',
                                  lineHeight: '20px',
                                }}
                                value='detail'
                                control={<Radio size='small' />}
                                label={
                                  <Typography
                                    sx={{
                                      color: 'var(--Blue-Primary-1, #083A50)',
                                      fontFamily: 'Nunito',
                                      fontSize: '12px !important',
                                      fontStyle: 'normal',
                                      fontWeight: '500',
                                      lineHeight: '20px',
                                    }}
                                  >
                                    Detail
                                  </Typography>
                                }
                              />
                            </RadioGroup>
                          </div>
                        </Stack>

                        <Input
                          label="As Per Date"
                          variant="standard"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          type='date'
                          value={formik.values.date}
                          onChange={(e) => { formik.setFieldValue('date', e.target.value) }}
                          formControlProps={{
                            sx: {
                              width: '100%',
                            }
                          }}
                        />
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid
                  item
                  xl={6}
                  lg={6}
                  sm={6}
                  xs={12}
                >
                  <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                    <CardContent>
                      <Stack direction={'column'} spacing={'16px'}>
                        <Stack direction={'row'} spacing={'16px'} fullWidth>
                          <Selection
                            id='0'
                            fullWidth={true}
                            label="Customer Type"
                            placeholder={'Select Customer Type'}
                            value={formik.values.customerType}
                            onChange={(e) => {
                              formik.setFieldValue('customerType', e.target.value)
                              setSelectedFirstCustomer({})
                              setSelectedSecondCustomer({})
                            }}
                          >
                            {contactTypeList.map((ct) => (
                              <MenuItem sx={{ fontSize: '12px' }} key={ct.id} value={ct.id}>{ct.name}</MenuItem>
                            ))}
                          </Selection>

                          <div fullWidth style={{ marginLeft: '24px', width: '75%' }}>
                            <Typography
                              sx={{
                                color: 'var(--Primary-One, #083A50)',
                                fontFamily: 'Nunito',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: '19.6px',
                              }}
                            >
                              Custom Customer
                            </Typography>

                            <RadioGroup
                              row
                              sx={{
                                width: '100%',
                              }}
                              fullWidth
                              aria-labelledby="variant-option"
                              name="variant"
                              value={formik.values.customer}
                              onChange={(e) => {
                                formik.setFieldValue('customer', e.target.value)
                              }}
                            >
                              <FormControlLabel
                                sx={{
                                  color: 'var(--Blue-Primary-1, #083A50)',
                                  fontFamily: 'Nunito',
                                  fontSize: '12px !important',
                                  fontStyle: 'normal',
                                  fontWeight: '400',
                                  lineHeight: '20px',
                                }}
                                value='all'
                                control={<Radio size='small' />}
                                label={
                                  <Typography
                                    sx={{
                                      color: 'var(--Blue-Primary-1, #083A50)',
                                      fontFamily: 'Nunito',
                                      fontSize: '12px !important',
                                      fontStyle: 'normal',
                                      fontWeight: '500',
                                      lineHeight: '20px',
                                    }}
                                  >
                                    All
                                  </Typography>
                                }
                              />

                              <FormControlLabel
                                sx={{
                                  color: 'var(--Blue-Primary-1, #083A50)',
                                  fontFamily: 'Nunito',
                                  fontSize: '12px !important',
                                  fontStyle: 'normal',
                                  fontWeight: '400',
                                  lineHeight: '20px',
                                }}
                                value='custom'
                                control={<Radio size='small' />}
                                label={
                                  <Typography
                                    sx={{
                                      color: 'var(--Blue-Primary-1, #083A50)',
                                      fontFamily: 'Nunito',
                                      fontSize: '12px !important',
                                      fontStyle: 'normal',
                                      fontWeight: '500',
                                      lineHeight: '20px',
                                    }}
                                  >
                                    Custom
                                  </Typography>
                                }
                              />
                            </RadioGroup>
                          </div>
                        </Stack>

                        {formik.values.customer !== 'all' && (
                          <>
                            <Input
                              label="From"
                              variant="standard"
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              value={selectedFirstCustomer?.id ? `${selectedFirstCustomer?.code || 0} - ${selectedFirstCustomer?.contactName}` : ''}
                              type='text'
                              formControlProps={{
                                sx: {
                                  width: '100%',
                                }
                              }}
                              disabled
                              inputBaseProps={{
                                readOnly: true,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => toggleModal('firstCustomer')}
                                      size='small'
                                    >
                                      <Icon
                                        iconName={'search'}
                                        svgIconProps={{
                                          fontSize: 'small',
                                          htmlColor: '#0087C2',
                                          sx: {
                                            height: '12px',
                                            width: '12px'
                                          }
                                        }}
                                      />
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />

                            <Input
                              label="To"
                              variant="standard"
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              value={selectedSecondCustomer?.id ? `${selectedSecondCustomer?.code || 0} - ${selectedSecondCustomer?.contactName}` : ''}
                              type='text'
                              formControlProps={{
                                sx: {
                                  width: '100%',
                                }
                              }}
                              disabled
                              inputBaseProps={{
                                readOnly: true,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => toggleModal('secondCustomer')}
                                      size='small'
                                    >
                                      <Icon
                                        iconName={'search'}
                                        svgIconProps={{
                                          fontSize: 'small',
                                          htmlColor: '#0087C2',
                                          sx: {
                                            height: '12px',
                                            width: '12px'
                                          }
                                        }}
                                      />
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                          </>
                        )}
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        
        {
          isLoading && (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
            >
              <LoadingSpinner />
            </Backdrop>
          )
        }
      </Box>

      {['firstCustomer', 'secondCustomer'].includes(activeModal) && (
        <ModalSearchCustomer
          isModal={['firstCustomer', 'secondCustomer'].includes(activeModal)}
          toggleModal={toggleModal}
          dataSelected={activeModal === 'firstCustomer' ? selectedFirstCustomer : selectedSecondCustomer}
          onSelect={activeModal === 'firstCustomer' ? setSelectedFirstCustomer : setSelectedSecondCustomer}
          errorToast={errorToast}
          formValue={formik.values}
        />
      )}
    </>
  )
})

export default PayableAgingReport