import { FormControlLabel, Grid, IconButton, MenuItem, Radio, RadioGroup, InputAdornment, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useCallback } from 'react';
import ApiService from '../../../../services/ApiService';
import { Icon, Input, Selection, showToast } from '../../../../components/ui';

const MainBooking = (props) => {
    function handleSelect(e, c) {
        let value = [];

        localStorage.setItem("jobTypeId", JSON.stringify(c.props.id));
        props.setJobTypeId(c.props.id);
        props.setJobType(e.target.value);
        value = props.dataJobType.filter(item => item.name === e.target.value);
        props.setJobTypeCode(value[0].code);

        props.emptyState();

        ApiService.PostById(props.paramRegJobType, c.props.id)
            .then((res) => {
                if (res.data.code === 200) {
                    var result = res.data.data.jobType;
                    if (result.portType !== '') {
                        props.setPortType(result.portType);
                    }
                    else {
                        props.setPortType('DOM');
                    }
                    if (result.serviceType !== '') {
                        props.setJobServiceType(result.serviceType);
                    }
                    else {
                        props.setJobServiceType('Domestic');
                    }
                    props.setContactTypeId(result.contactTypeId);
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const handleInputChange = useCallback(event => {
        props.changeRefShipper(event.target.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.changeRefShipper])

    const handleShipmentStatus = (e) => {
        props.setShipmentStatusId(e);

        if (e === '1' || e === 1) {
            props.setEnableMarketing(false);
            props.setMarketId(0)
            props.setMarketName('')
        }
        else {
            props.setEnableMarketing(true);
            props.setMarketId(0)
            props.setMarketName('')
        }
    }

    const handleSearchMarketing = (data) => {
        if (data === 'search') {
            props.setTitleModal('Marketing');
            props.handleClick();
        }
        else if (data === 'delete') {
            props.setMarketId(0)
            props.setMarketName('')
        }
    }

    const mainContainer = () => {
        return (
            <form>
                <Box>
                    <Grid
                        container
                        spacing={'32px'}
                    >
                        <Grid
                            item
                            xl={6}
                            lg={6}
                            sm={6}
                            xs={12}
                        >
                            <Selection
                                id='0'
                                label={'Job Type'}
                                value={props.jobType}
                                fullWidth={true}
                                helperText={props.jobType ? '' : 'This Field is Required'}
                                placeholder={'Select Job Type'}
                                disabled={props.isEdit}
                                onChange={(e, c) => handleSelect(e, c)}
                            >
                                {
                                    props.dataJobType.map((data) => (
                                        <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.code} value={data.name}>
                                            {data.name}
                                        </MenuItem>
                                    ))
                                }
                            </Selection>

                            <div style={{ 'marginTop': props.jobType ? '24px' : '2px' }}>
                                {props.selectPrincipleBy((props.isEdit === true && props.isCopy !== 'true') ? true : false)}
                            </div>

                            <Input
                                label={'Ref. SI From Shipper'}
                                value={props.refShipper}
                                onChange={handleInputChange}
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '24px'
                                    }
                                }}
                                disabled={props.isApproved}
                            />
                        </Grid>

                        <Grid
                            item
                            xl={6}
                            lg={6}
                            sm={6}
                            xs={12}
                        >
                            <Input
                                label={'Booking Date'}
                                value={props.bookingDate}
                                type={'date'}
                                onChange={(e) => props.setBookingDate(e.target.value)}
                                disabled={props.isApproved}
                                formControlProps={{
                                    sx: {
                                        width: '100%'
                                    }
                                }}
                            />

                            <div style={{ marginTop: '24px' }}>
                                <Typography
                                    sx={{
                                        color: 'var(--Primary-One, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '14px',
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        lineHeight: '19.6px',
                                    }}
                                >
                                    Shipment Status
                                </Typography>

                                <RadioGroup
                                    row
                                    sx={{
                                        marginTop: '2px',
                                        gap: '12px'
                                    }}
                                    aria-labelledby="variant-option"
                                    name="variant"
                                    value={props.shipmentStatusId}
                                    disabled={props.isApproved}
                                    onChange={(e) => handleShipmentStatus(e.target.value)}
                                >
                                    {
                                        props.dataStatusShipment.map((data) => (
                                            <FormControlLabel
                                                sx={{
                                                    color: 'var(--Blue-Primary-1, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '12px !important',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '20px',
                                                }}
                                                value={data.id}
                                                key={data.id}
                                                control={<Radio size='small' />}
                                                disabled={props.isApproved}
                                                label={
                                                    <Typography
                                                        sx={{
                                                            color: 'var(--Blue-Primary-1, #083A50)',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '12px !important',
                                                            fontStyle: 'normal',
                                                            fontWeight: '500',
                                                            lineHeight: '20px',
                                                        }}
                                                    >
                                                        {data.name}
                                                    </Typography>
                                                }
                                            />
                                        ))
                                    }
                                </RadioGroup>
                            </div>

                            <Input
                                label="Marketing Name"
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                value={props.marketName}
                                type='text'
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '19.5px'
                                    }
                                }}
                                hidden={!props.enableMarketing || props.marketName !== '' ? false : true}
                                disabled
                                inputBaseProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => { handleSearchMarketing('delete'); }}
                                                disabled={props.enableMarketing}
                                                hidden={props.marketName && !props.isApproved ? false : true}
                                            >
                                                <Icon
                                                    iconName={'close'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>

                                            <IconButton
                                                onClick={() => { handleSearchMarketing('search'); }}
                                                disabled={props.enableMarketing}
                                                hidden={props.isApproved}
                                            >
                                                <Icon
                                                    iconName={'search'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </form>
        )
    }

    return (
        <div>
            {
                props.isApproved ?
                    <fieldset disabled>
                        {mainContainer()}
                    </fieldset>
                    :
                    <fieldset>
                        {mainContainer()}
                    </fieldset>
            }
        </div>
    )
}

export default MainBooking