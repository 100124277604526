import { Stack, Grid, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';

import ModalContainer from '../Modals/ModalContainer';
import { Input, showToast } from '../../../../components/ui';

const TabContainer = (props) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '8px 16px !important',
        },
    }));

    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            // backgroundColor: '#FFFFFF',
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            padding: '0px 16px !important',
            height: '35px !important'
        },
    }));

    const [isModal2, setIsModal2] = useState(false);
    const [titleModal2, setTitleModal2] = useState('');
    const [selectedDetail, setSelectedDetail] = useState({});
    const [detailEdit, setDetailEdit] = useState(false);
    const [detailSequence, setDetailSequence] = useState(0);
    const selectedStyle = { bgcolor: '#CCCCCC', "&.MuiTableRow-hover:hover": { backgroundColor: "#CCCCCC" } };
    const deletedDetailStyle = { bgcolor: (theme) => theme.palette.text.disabled };
    const [isContainer, setIsContainer] = useState(false);

    const [size, setSize] = useState('20\'');
    const [containerTypeId, setContainerTypeId] = useState(1);
    const [containerNo, setContainerNo] = useState('');
    const [sealNo, setSealNo] = useState('');
    const [grossWeight, setGrossWeight] = useState(0);
    const [netWeight, setNetWeight] = useState(0);
    const [cbm, setCBM] = useState(0);
    const [commodity, setCommodity] = useState('');
    const [noOfPieces, setNoOfPieces] = useState(0);
    const [packagingCode, setPackagingCode] = useState('GPL');
    const [partOf, setPartOf] = useState(false);
    const [containerCode, setContainerCode] = useState('GP');
    const [containerName, setContainerName] = useState('General Purpose');
    const [packagingName, setPackagingName] = useState('General Packaging');

    React.useEffect(() => {
        if (props.detailTypeId === 2 || props.detailTypeId === undefined) {
            setIsContainer(true);
        }
        else {
            setIsContainer(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const handleClick2 = () => {
        if (isModal2 === false) {
            setIsModal2(true);
        } else {
            setIsModal2(false);
        }
    };

    const ShowAddContainer = () => {
        setTitleModal2('Add Container');
        setDetailEdit(false);
        if (props.containerData.length > 0) {
            let data = props.containerData.reduce((prev, current) => (prev && prev.sequence > current.sequence) ? prev : current);
            setDetailSequence(data.sequence);
        }
        else {
            setDetailSequence(0);
        }
        handleClick2();
    };

    const ShowEditContainer = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (selectedDetail.rowStatus !== 'DEL') {
                setTitleModal2('Edit Container');
                setDetailEdit(true);

                setSize(selectedDetail.containerSize);
                setContainerTypeId(selectedDetail.containerTypeId);
                setContainerCode(selectedDetail.containerTypeCode);
                setContainerName(selectedDetail.containerTypeName);
                setPackagingName(selectedDetail.packagingName);
                setContainerNo(selectedDetail.containerNo);
                setSealNo(selectedDetail.sealNo);
                setGrossWeight(selectedDetail.grossWeight);
                setNetWeight(selectedDetail.netWeight);
                setCBM(selectedDetail.cbm);
                setCommodity(selectedDetail.commodity);
                setNoOfPieces(selectedDetail.noOfPieces);
                setPackagingCode(selectedDetail.packagingCode);
                setPartOf(selectedDetail.partOf);

                handleClick2();
            }
            else {
                showToast({ type: 'error', message: 'Data Has Been Deleted!' });
            }
        }
    }

    const DeleteContainer = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            let tempSequence = selectedDetail.sequence;

            for (let i = 0; i < props.dataTracking.length; i++) {
                if (props.dataTracking[i].sequenceSeaContainer === tempSequence) {
                    DeleteDataTracking(props.dataTracking[i]);
                }
            }

            const deleteFunction = (dataCost) => {
                return dataCost.sequence !== tempSequence;
            }

            const result = props.containerData.filter(deleteFunction);

            if (result.length > 0) {
                tempSequence = 0;

                result.forEach((el) => {
                    tempSequence += 1;
                    el.sequence = tempSequence;
                })

                setDetailSequence(tempSequence);
            }
            else {
                setDetailSequence(0);
            }

            props.createCopyContainer(result);
            props.getContainerSizeData(result);

            props.setContainerData(result);

            setSelectedDetail({});
            showToast({ type: 'success', message: 'Container Deleted Successfully!' });
        }
    }

    const DeleteDataTracking = (selectData) => {
        let tempSequence = selectData.sequence;

        const deleteFunction = (data) => {
            return data.sequence !== tempSequence;
        }

        const result = props.dataTracking.filter(deleteFunction);

        if (result.length > 0) {
            tempSequence = 0;

            result.forEach((el) => {
                tempSequence += 1;
                el.sequence = tempSequence;
            })
        }

        props.setDataTracking(result);
    }

    return (
        <>
            <Box component="div">
                <Typography
                    sx={{
                        color: 'var(--Blue-Primary-1, #083A50)',
                        fontFamily: 'Nunito',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: 'normal',
                        marginTop: '12px'
                    }}
                >
                    Total Container
                </Typography>

                <Grid container item xs={12}>
                    <Box>
                        <Stack spacing={'32px'} direction="row">
                            <Input
                                label="20'"
                                value={props.data20}
                                textAlign='right'
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '12px !important'
                                    }
                                }}
                            />

                            <Input
                                label="40'"
                                value={props.data40}
                                textAlign='right'
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '12px !important'
                                    }
                                }}
                            />

                            <Input
                                label="45'"
                                value={props.data45}
                                textAlign='right'
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '12px !important'
                                    }
                                }}
                            />

                            <Input
                                label="All"
                                value={props.dataAll}
                                textAlign='right'
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '12px !important'
                                    }
                                }}
                            />
                        </Stack>
                    </Box>
                </Grid>
            </Box>

            <Box component="div" sx={{ marginTop: '24px' }}>
                <Typography
                    sx={{
                        color: 'var(--Blue-Primary-1, #083A50)',
                        fontFamily: 'Nunito',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: 'normal',
                    }}
                >
                    Add Container
                </Typography>

                <TableContainer sx={{ maxHeight: 235, borderRadius: '12px', background: '#FFF', marginTop: '8px' }}>
                    <Table sx={{ minWidth: 650 }} stickyHeader>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell sx={{ textAlign: 'center' }}>Container No.</StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'left' }}>Seal No.</StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'center' }}>Size</StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'center' }}>Type</StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'right' }}>Gross Weight</StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'right' }}>Net Weight</StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'right' }}>CBM</StyledTableCell>
                                <StyledTableCell>Commodity</StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'right' }}>No of Pieces</StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'center' }}>Packaging</StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'center' }}>Part of</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                props.containerData.length > 0 ?
                                    props.containerData.map((el) => {
                                        return (
                                            <TableRow
                                                key={el.sequence}
                                                onClick={() => setSelectedDetail(el)}
                                                onDoubleClick={() => ShowEditContainer()}
                                                sx={selectedDetail.sequence === el.sequence ? selectedStyle : el.rowStatus === 'DEL' ? deletedDetailStyle : {}}
                                                hover={true}
                                            >
                                                <StyledTableBodyCell sx={{ textAlign: 'center' }}>{el.containerNo}</StyledTableBodyCell>
                                                <StyledTableBodyCell sx={{ textAlign: 'left' }}>{el.sealNo}</StyledTableBodyCell>
                                                <StyledTableBodyCell sx={{ textAlign: 'center' }}>{el.containerSize}</StyledTableBodyCell>
                                                <StyledTableBodyCell sx={{ textAlign: 'center' }}>{el.containerTypeCode}</StyledTableBodyCell>
                                                <StyledTableBodyCell sx={{ textAlign: 'right' }}>{el.grossWeight}</StyledTableBodyCell>
                                                <StyledTableBodyCell sx={{ textAlign: 'right' }}>{el.netWeight}</StyledTableBodyCell>
                                                <StyledTableBodyCell sx={{ textAlign: 'right' }}>{el.cbm}</StyledTableBodyCell>
                                                <StyledTableBodyCell>{el.commodity}</StyledTableBodyCell>
                                                <StyledTableBodyCell sx={{ textAlign: 'right' }}>{el.noOfPieces}</StyledTableBodyCell>
                                                <StyledTableBodyCell sx={{ textAlign: 'center' }}>{el.packagingCode}</StyledTableBodyCell>
                                                <StyledTableBodyCell sx={{ textAlign: 'center' }}>{el.partOf === true ? 'Yes' : 'No'}</StyledTableBodyCell>
                                            </TableRow>
                                        )
                                    })
                                    :
                                    <TableRow>
                                        <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                            <Typography
                                                sx={{
                                                    color: 'var(--Primary-One, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '12px',
                                                    fontstyle: 'normal',
                                                    fontWeight: '500',
                                                    lineHeight: 'normal'
                                                }}
                                            >
                                                Data Empty
                                            </Typography>
                                        </StyledTableBodyCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>

                <div style={{ marginTop: '16px' }}>
                    {CustomizeButtonComponent.AddEditDeleteUploadTabsButton(ShowAddContainer, ShowEditContainer, DeleteContainer, null)}
                </div>
            </Box>
            
            {
                isModal2 && (
                    <ModalContainer
                        isModal2={isModal2}
                        handleClick2={handleClick2}
                        titleModal2={titleModal2}
                        isContainer={isContainer}
                        selectContainerType={props.selectContainerType}
                        selectPackagingType={props.selectPackagingType}
                        selectedDetail={selectedDetail}
                        setSelectedDetail={setSelectedDetail}
                        detailEdit={detailEdit}
                        setDetailEdit={setDetailEdit}
                        detailSequence={detailSequence}
                        setDetailSequence={setDetailSequence}
        
                        getContainerSizeData={props.getContainerSizeData}
        
                        ShipmentId={props.ShipmentId}
                        setShipmentId={props.setShipmentId}
        
                        size={size}
                        setSize={setSize}
                        containerTypeId={containerTypeId}
                        setContainerTypeId={setContainerTypeId}
                        containerNo={containerNo}
                        setContainerNo={setContainerNo}
                        sealNo={sealNo}
                        setSealNo={setSealNo}
                        grossWeight={grossWeight}
                        setGrossWeight={setGrossWeight}
                        netWeight={netWeight}
                        setNetWeight={setNetWeight}
                        cbm={cbm}
                        setCBM={setCBM}
                        commodity={commodity}
                        setCommodity={setCommodity}
                        noOfPieces={noOfPieces}
                        setNoOfPieces={setNoOfPieces}
                        packagingCode={packagingCode}
                        setPackagingCode={setPackagingCode}
                        partOf={partOf}
                        setPartOf={setPartOf}
                        containerCode={containerCode}
                        setContainerCode={setContainerCode}
                        containerName={containerName}
                        setContainerName={setContainerName}
                        packagingName={packagingName}
                        setPackagingName={setPackagingName}
        
                        data20={props.data20}
                        setData20={props.setData20}
                        data40={props.data40}
                        setData40={props.setData40}
                        data45={props.data45}
                        setData45={props.setData45}
                        dataAll={props.dataAll}
                        setDataAll={props.setDataAll}
        
                        containerData={props.containerData}
                        setContainerData={props.setContainerData}
        
                        detailTypeId={props.detailTypeId}
        
                        CopyContainer={props.CopyContainer}
                        setCopyContainer={props.setCopyContainer}
                        totalNumberCont={props.totalNumberCont}
                        setTotalNumberCont={props.setTotalNumberCont}
                        createCopyContainer={props.createCopyContainer}
                    />
                )
            }
        </>
    )
}

export default TabContainer;