import React from 'react';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import { Grid } from '@mui/material';

const ButtonCreateEditReceiptPPh23 = ({ check, handleSave }) => {
  return (
    <div className="row" style={{ marginTop: '16px' }}>
      <div className="col-12">
        <Grid
          container
          item
          justifyContent="right"
          direction="row"
          style={{ maxWidth: "100vw", gap: "12px" }}
        >
          {check !== 0 && ButtonComponent.SaveButton(handleSave, false, false, true)}
        </Grid>
      </div>
    </div>
  );
};

export default ButtonCreateEditReceiptPPh23;