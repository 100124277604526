import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react'
import { useState } from 'react';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import ApiService from '../../../../../services/ApiService';
import ModalListOR from '../Modal/ModalListOR';
import { showToast } from '../../../../../components/ui';
import { convertToNominal } from '../../../../../utils/masks.jsx';

const ReceiptPPh23Detail = (props) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#F2FAFF',
      color: 'var(--Primary-One, #083A50)',
      fontFamily: 'Nunito',
      fontSize: '14px',
      fontstyle: 'normal',
      fontWeight: '600',
      lineHeight: 'normal',
      padding: '8px 16px !important',
    },
  }));

  const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      color: 'var(--Blue-Primary-1, #083A50)',
      fontFamily: 'Nunito',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
      padding: '0px 16px !important',
      height: '35px !important'
    },
  }));

  const selectedStyle = { bgcolor: '#CCCCCC', "&.MuiTableRow-hover:hover": { backgroundColor: "#CCCCCC" } };
  const deletedDetailStyle = { bgcolor: (theme) => theme.palette.text.disabled };

  const [isModalDetail, setIsModalDetail] = useState(false);
  const [titleModalDetail, setTitleModalDetail] = useState('');

  const [isLoadingDetail, setIsLoadingDetail] = useState(false);
  const [dataDetail, setDataDetail] = useState([]);
  const [dataMapDetail, setDataMapDetail] = useState([])
  const [columnDataDetail, setColumnDataDetail] = useState([])
  const [SelectedDataDetail, setSelectedDataDetail] = useState({});
  const [totalRowsDetail, setTotalRowsDetail] = useState(50)
  const paramDetail = "officialReceipt/officialReceipt";

  const handleAdd = () => {
    if (props.customerId === 0) {
      showToast({ type: 'error', message: 'Please select Customer!' });
    } else {
      handleClick()
    }
  }

  const handleClick = () => {
    if (isModalDetail === false) {
      setIsModalDetail(true);
      getDataDetail(1, 50)
    } else {
      setIsModalDetail(false);
    }
  }

  const handleDelete = () => {
    if (props.employeeId === 0) {
      showToast({ type: 'error', message: 'Please select Personal Code First!' });
      return
    } else {
      if (props.selectedDetail.sequence) {
        let delRow = props.selectedDetail.sequence
        const tempData = props.dataDetail.filter(dt => dt.sequence !== delRow)
        let tempSequence = 0
        tempData.forEach(row => {
          tempSequence++
          row.sequence = tempSequence
        })
        props.setDataDetail(tempData)
        props.SumTotal(tempData)
      } else {
        showToast({ type: 'error', message: 'Please select data!' });
      }
    }
  }

  const getDataDetail = (pageNumber, pageSize, filters) => {
    setIsLoadingDetail(true);
    let params = [
      {
        "attr": "customerId",
        "value": '' + props.customerId
      },
      {
        "attr": "isPPh23",
        "value": '1'
      }
    ]
    ApiService.FinAccPostByDetail(paramDetail, pageNumber, pageSize, params, filters)
      .then((response) => {
        if (response.data.code === 200) {
          setSelectedDataDetail({})
          setDataDetail(response.data.data.officialReceiptInvoice);

          let temp = response.data.data.officialReceiptInvoice
          let indexed = temp.map((el, index) => {
            let indexedTemp = {
              ...el,
              index
            }
            return indexedTemp
          })

          setDataMapDetail(indexed)
          setColumnDataDetail(response.data.data.columns.invoiceColumns)
          setTotalRowsDetail(response.data.totalRowCount)

          setIsLoadingDetail(false)
        }
        setIsLoadingDetail(false)
      })
      .catch(function (error) {
        setIsLoadingDetail(false)
        console.error('error saat fetch', error)
      })
  }

  const searchDetail = () => {
    if (isModalDetail === false) {
      setIsModalDetail(true);
      setTitleModalDetail('Official Receipt')
      getDataDetail(1, 50)
    } else {
      setIsModalDetail(false);
    }

  }

  const handleDoubleClick = () => {

  }

  return (
    <>
      <Typography
        sx={{
          color: 'var(--Blue-Primary-1, #083A50)',
          fontFamily: 'Nunito',
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: '600',
          lineHeight: 'normal',
          marginTop: '8px'
        }}
      >
        Detail
      </Typography>

      <TableContainer sx={{ maxHeight: 235, marginTop: '8px', background: '#FFF', borderRadius: '12px' }}>
        <Table sx={{ minWidth: 650 }} stickyHeader>
          <TableHead
            sx={{
              '& th:first-of-type': {
                borderRadius: '12px 0 0'
              },
              '& th:last-child': {
                borderRadius: '0 12px 0 0'
              }
            }}
          >
            <TableRow>
              <StyledTableCell>OR No</StyledTableCell>
              <StyledTableCell>Invoice No</StyledTableCell>
              <StyledTableCell>Shipment No</StyledTableCell>
              <StyledTableCell sx={{ textAlign: 'right', }}>PPh23</StyledTableCell>
              <StyledTableCell sx={{ textAlign: 'right', }}>Amount USD</StyledTableCell>
              <StyledTableCell sx={{ textAlign: 'right', }}>Amount IDR</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              props.dataDetail.length > 0 ?
                props.dataDetail.map((el) => {
                  return (
                    <TableRow
                      key={el.sequence}
                      onClick={() => props.setSelectedDetail(el)}
                      onDoubleClick={() => handleDoubleClick()}
                      sx={props.selectedDetail.sequence === el.sequence
                        ? selectedStyle
                        : el.rowStatus === 'DEL'
                          ? deletedDetailStyle
                          : {}}
                    >
                      <StyledTableBodyCell>{el.orNo}</StyledTableBodyCell>
                      <StyledTableBodyCell>{el.invoiceNo}</StyledTableBodyCell>
                      <StyledTableBodyCell>{el.shipmentNo}</StyledTableBodyCell>
                      <StyledTableBodyCell sx={{ textAlign: 'right', }}>{new Intl.NumberFormat().format(el.pph23Amount)}</StyledTableBodyCell>
                      <StyledTableBodyCell sx={{ textAlign: 'right', }}>
                        {convertToNominal(el.amountUSD)}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell sx={{ textAlign: 'right', }}>
                        {convertToNominal(el.amountIDR)}
                      </StyledTableBodyCell>
                    </TableRow>
                  )
                })
                :
                <TableRow sx={{ backgroundColor: '#FFFFFF' }}>
                  <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                    <Typography
                      sx={{
                        color: 'var(--Primary-One, #083A50)',
                        fontFamily: 'Nunito',
                        fontSize: '12px',
                        fontstyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal'
                      }}
                    >
                      Data Empty
                    </Typography>
                  </StyledTableBodyCell>
                </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>

      <div className='d-flex' style={{ marginTop: '16px', marginLeft: '-12px' }}>
        {ButtonComponent.AddButton(handleAdd)}
        {ButtonComponent.DeleteButton(handleDelete)}
      </div>

      {
        isModalDetail && (
          <ModalListOR
            isModal={isModalDetail}
            handleClick={searchDetail}
            titleModal={titleModalDetail}
            setIsModal={setIsModalDetail}

            isLoading={isLoadingDetail}
            data={dataDetail}
            dataMap={dataMapDetail}
            columnData={columnDataDetail}
            SelectedData={SelectedDataDetail}
            totalRows={totalRowsDetail}
            setSelectedData={setSelectedDataDetail}
            getData={getDataDetail}

            setDataDetail={props.setDataDetail}
            dataDetail={props.dataDetail}

            SumTotal={props.SumTotal}
          />
        )
      }
    </>
  )
}

export default ReceiptPPh23Detail