import React, { forwardRef, useEffect, useState } from 'react'
import { Box, FormControlLabel, Grid, Radio, RadioGroup, Stack, IconButton, Backdrop, Card, CardContent, Typography, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import ModalSearchCustomer from './components/ModalSearchCustomer';
import ModalSearchShipment from './components/ModalSearchShipment';
import ModalSearchPeriod from './components/ModalSearchPeriod';

import { useFormik } from 'formik';
import ApiService from '../../../../services/ApiService';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Icon, Input, showToast, TickBox } from '../../../../components/ui';

const BalanceAPDetailReport = forwardRef(() => {
  const history = useNavigate()

  const date = new Date()
  const dateString = date.toLocaleDateString("sv-se").split('T')[0]

  const [isLoading, setIsLoading] = useState(true)

  const [activeModal, setActiveModal] = useState('')
  const [activeData, setActiveData] = useState(0)
  const [selectedPeriod, setSelectedPeriod] = useState({})

  const [dataRegUserAccess, setDataRegUserAccess] = useState([]);

  const BreadcrumbsItems = [
    { label: 'Infoss', link: '/Infoss', style: '' },
    { label: 'Balance AP Detail Report', link: '/Infoss/BalanceAPDetailReport', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useEffect(() => {
    document.title = 'Balance AP Detail Report - ' + JSON.parse(localStorage.getItem('branchName'));

    getDataAccess()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const errorToast = (location) => {
    showToast({ type: 'error', message: `Can't get ${location} data! please contact Administrator` })
  }

  const getDataAccess = () => {
    let params = [];
    let pId = 0;
    let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
    let hasAccess = false

    // TODO Change menu code
    const indexMenu = listMenu.findIndex((elm) => elm.code === 'OBOCO')
    if (indexMenu !== -1) {
      pId = listMenu[indexMenu].id
      hasAccess = true
    }

    if (hasAccess) {
      params = [
        ...params,
        { attr: "permissionId", value: "" + pId },
        { attr: "userId", value: "" + JSON.parse(localStorage.getItem("userId")) },
        { attr: "roleId", value: "" + JSON.parse(localStorage.getItem("roleId")) }
      ];

      ApiService.IdentityPostByPage('regUserAccess/regUserAccess', "PAGE", 1, 100, params, [])
        .then((response) => {
          if (response.data.code === 200) {
            setDataRegUserAccess(response.data.data.userAccess);

            getAdditionalData()
          }
        })
        .catch(function (error) {
          console.error("error saat fetch", error);
          history('/Infoss');
        });
    } else {
      history('/Infoss');
    }
  };

  const getAdditionalData = async () => {
    setIsLoading(true)
    try {
      const [resCT, resJT] = await Promise.all([
        ApiService.DynamicPostByPage('contactType/contactType', 'PAGE', 1, 999, []),
        ApiService.DynamicPostByPage('regJobType/regJobType', 'PAGE', 1, 999, []),
      ])

      if (resCT.data.code === 200) {
        let data = resCT.data.data.contactType
        const excludesData = ['SHIPPER', 'CONSIGNEE']
        data = data.filter((el) => !excludesData.includes(el?.name?.toUpperCase()))
        data = data.filter((el) => el.id < 10)

        const mapData = data.map((elm) => {
          return {
            id: elm.id,
            name: elm.name,
            isActive: true,
            isCustom: false,
            selectedCustomer: {},
          }
        })

        formik.setFieldValue('customerType', mapData)
      } else {
        errorToast('contact type')
      }

      if (resJT.data.code === 200) {
        const data = resJT.data.data.jobType
        const mapData = data.map((elm) => {
          return {
            id: elm.jobTypeId,
            name: elm.name,
            isActive: true,
            isCustom: false,
            selectedShipmentOrder: {},
          }
        })

        formik.setFieldValue('shipmentOrder', mapData)
      } else {
        errorToast('job type')
      }

    } catch (error) {
      errorToast('additional')
      console.log(error)
    }
    setIsLoading(false)
  }

  const handlePrint = () => {
    const payload = formik.values

    if (payload.customPeriod === 'period' && !selectedPeriod?.id) {
      return showToast({ type: 'error', message: 'Please select period.' })
    }

    payload.shipmentOrder.forEach((elm) => {
      if (elm.isCustom && !elm.selectedShipmentOrder?.id) {
        showToast({ type: 'error', message: `Please select shipment order for ${elm.name}.` })
      }
    })

    payload.customerType.forEach((elm) => {
      if (elm.isCustom && !elm.selectedCustomer?.id) {
        showToast({ type: 'error', message: `Please select ${elm.name} customer.` })
      }
    })

    setIsLoading(true);

    let params = [];
    let tempJobType = [];
    let tempContactType = [];
    let variable = 'PrintBAPD';
    let dateFrom = '';
    let dateTo = '';
    let jobTypeData = '';
    let contactTypeData = '';

    const filterFunction = (data) => {
      return data.isActive !== false;
    }

    if (payload.customPeriod === 'period') {
      dateFrom = payload.beginningPeriod;
      dateTo = payload.endingPeriod;
    }
    else {
      dateFrom = payload.transactionStart;
      dateTo = payload.transactionEnd;
    }

    tempJobType = payload.shipmentOrder.filter(filterFunction);
    for (let i = 0; i < tempJobType.length; i++) {
      if (i + 1 < tempJobType.length) {
        jobTypeData = tempJobType[i].id + ', ' + jobTypeData;
      }
      else {
        jobTypeData = jobTypeData + tempJobType[i].id;
      }
    }

    tempContactType = payload.customerType.filter(filterFunction);
    for (let i = 0; i < tempContactType.length; i++) {
      if (i + 1 < tempContactType.length) {
        contactTypeData = tempContactType[i].id + ', ' + contactTypeData;
      }
      else {
        contactTypeData = contactTypeData + tempContactType[i].id;
      }
    }

    params = [
      ...params,
      {
        "attr": "dateFrom",
        "value": dateFrom
      },
      {
        "attr": "dateTo",
        "value": dateTo
      },
      {
        "attr": "jobTypeIdList",
        "value": jobTypeData
      },
      {
        "attr": "contactTypeIdList",
        "value": contactTypeData
      }
    ];

    ApiService.ReportPrint("Reports/Finance/Print/AccountPayable/BalanceAPDetailReport/", variable, params)
      .then((response) => {
        if (response.status === 200) {
          let rptFormat = "application/pdf";
          const file = new Blob([response.data], { type: rptFormat });
          const fileURL = URL.createObjectURL(file);

          setIsLoading(false);
          showToast({ type: 'success', message: 'Print Balance AP Detail Report Success' });
          window.open(fileURL);
        }
        else {
          setIsLoading(false);
          showToast({ type: 'error', message: 'Can\'t Print Balance AP Detail Report, ' + response.data.message });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        showToast({ type: 'error', message: 'Can\'t Print Balance AP Detail Report, ' + error });
      });
    // TODO Print data based on form
  }

  const handleSelectPeriod = (data) => {
    setSelectedPeriod(data)
    formik.setFieldValue('period', data.period)
    formik.setFieldValue('year', data.yearPeriod)

    const startPeriod = new Date(data.beginningPeriod)
    const endPeriod = new Date(data.endDatePeriod)
    formik.setFieldValue('beginningPeriod', startPeriod.toLocaleDateString("sv-se").split('T')[0])
    formik.setFieldValue('endingPeriod', endPeriod.toLocaleDateString("sv-se").split('T')[0])
  }

  const handleSelectCustomer = (data) => {
    formik.setFieldValue(`customerType.${activeData?.index}.selectedCustomer`, data)
  }

  const handleSelectShipment = (data) => {
    formik.setFieldValue(`shipmentOrder.${activeData?.index}.selectedShipmentOrder`, data)
  }

  const toggleModal = (modal, data) => {
    setActiveModal(modal)
    setActiveData(data)
  }

  const formik = useFormik({
    initialValues: {
      customCompany: 'company',
      customPeriod: 'period',
      period: '',
      year: '',
      beginningPeriod: '',
      endingPeriod: '',
      transactionStart: dateString,
      transactionEnd: dateString,
      shipmentOrder: [],
      customerType: [],
    },
    validationSchema: undefined,
  })

  const ButtonComponents = () => {
    return (
      <div className="row" spacing={'12px'}>
        <div className='col-12' spacing={'12px'}>
          <Grid container item spacing={'12px'} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
            {dataRegUserAccess.map((elm, index) => (
              <React.Fragment key={index}>
                {elm.accessCode === 'PRI' && ButtonComponent.PrintButton(() => handlePrint())}
              </React.Fragment>
            ))}
          </Grid>
        </div>
      </div>
    )
  }

  function handleChange(name, value) {
    formik.setFieldValue(name, !value);
  }

  return (
    <>
      <Box className='w-100' sx={{ width: '100%', p: 3, background: '#E4E8EC' }}>
        <CustomToastContainer />
        <Grid container spacing={0} direction="column">
          <HeaderInfo
            title={'Balance AP Detail Report'}
            isJobType={false}
            BreadcrumbsItems={BreadcrumbsItems}
            ButtonComponents={ButtonComponents}
          />

          <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
            <div style={{ marginTop: '24px' }}>
              <Grid
                container
                spacing={'32px'}
              >
                <Grid
                  item
                  xl={6}
                  lg={6}
                  sm={6}
                  xs={12}
                >
                  <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                    <CardContent>
                      <div>
                        <Typography
                          sx={{
                            color: 'var(--Primary-One, #083A50)',
                            fontFamily: 'Nunito',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '19.6px',
                          }}
                        >
                          Custom Company
                        </Typography>

                        <RadioGroup
                          row
                          aria-labelledby="variant-option"
                          name="variant"
                          value={formik.values.customCompany}
                          onChange={(e) => { formik.setFieldValue('customCompany', e.target.value) }}
                        >
                          <FormControlLabel
                            sx={{
                              color: 'var(--Blue-Primary-1, #083A50)',
                              fontFamily: 'Nunito',
                              fontSize: '12px !important',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: '20px',
                            }}
                            value={'company'}
                            key={1}
                            control={<Radio size='small' />}
                            label={
                              <Typography
                                sx={{
                                  color: 'var(--Blue-Primary-1, #083A50)',
                                  fontFamily: 'Nunito',
                                  fontSize: '12px !important',
                                  fontStyle: 'normal',
                                  fontWeight: '500',
                                  lineHeight: '20px',
                                }}
                              >
                                Per Company
                              </Typography>
                            }
                          />

                          <FormControlLabel
                            sx={{
                              color: 'var(--Blue-Primary-1, #083A50)',
                              fontFamily: 'Nunito',
                              fontSize: '12px !important',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: '20px',
                            }}
                            value={'regional'}
                            key={2}
                            control={<Radio size='small' />}
                            label={
                              <Typography
                                sx={{
                                  color: 'var(--Blue-Primary-1, #083A50)',
                                  fontFamily: 'Nunito',
                                  fontSize: '12px !important',
                                  fontStyle: 'normal',
                                  fontWeight: '500',
                                  lineHeight: '20px',
                                }}
                              >
                                Per Regional
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </div>

                      <div style={{ marginTop: '16px' }}>
                        <Typography
                          sx={{
                            color: 'var(--Primary-One, #083A50)',
                            fontFamily: 'Nunito',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '19.6px',
                          }}
                        >
                          Custom Period
                        </Typography>

                        <RadioGroup
                          row
                          aria-labelledby="variant-option"
                          name="variant"
                          value={formik.values.customPeriod}
                          onChange={(e) => { formik.setFieldValue('customPeriod', e.target.value) }}
                        >
                          <FormControlLabel
                            sx={{
                              color: 'var(--Blue-Primary-1, #083A50)',
                              fontFamily: 'Nunito',
                              fontSize: '12px !important',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: '20px',
                            }}
                            value={'period'}
                            key={1}
                            control={<Radio size='small' />}
                            label={
                              <Typography
                                sx={{
                                  color: 'var(--Blue-Primary-1, #083A50)',
                                  fontFamily: 'Nunito',
                                  fontSize: '12px !important',
                                  fontStyle: 'normal',
                                  fontWeight: '500',
                                  lineHeight: '20px',
                                }}
                              >
                                Per Closing Period
                              </Typography>
                            }
                          />

                          <FormControlLabel
                            sx={{
                              color: 'var(--Blue-Primary-1, #083A50)',
                              fontFamily: 'Nunito',
                              fontSize: '12px !important',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: '20px',
                            }}
                            value={'custom'}
                            key={2}
                            control={<Radio size='small' />}
                            label={
                              <Typography
                                sx={{
                                  color: 'var(--Blue-Primary-1, #083A50)',
                                  fontFamily: 'Nunito',
                                  fontSize: '12px !important',
                                  fontStyle: 'normal',
                                  fontWeight: '500',
                                  lineHeight: '20px',
                                }}
                              >
                                Custom Date
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </div>

                      {
                        formik.values.customPeriod === 'period' ?
                          <>
                            <Stack direction={'row'} spacing={'16px'} sx={{ marginTop: '16px' }}>
                              <Input
                                readOnly
                                fullWidth
                                label="Period"
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                value={formik.values?.period || ''}
                                disabled
                              />

                              <Input
                                fullWidth
                                label="Year"
                                variant="standard"
                                disabled
                                value={formik.values?.year || ''}
                                InputLabelProps={{ shrink: true }}
                                formControlProps={{ sx: { width: '100%', } }}
                                inputBaseProps={{
                                  readOnly: true,
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton onClick={() => toggleModal('period')}>
                                        <Icon
                                          iconName={'search'}
                                          svgIconProps={{
                                            fontSize: 'small',
                                            htmlColor: '#0087C2',
                                            sx: {
                                              height: '12px',
                                              width: '12px'
                                            }
                                          }}
                                        />
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }}
                              />
                            </Stack>

                            <Stack direction={'row'} spacing={'16px'} sx={{ marginTop: '16px' }}>
                              <Input
                                disabled
                                type={'date'}
                                variant="standard"
                                label="Beginning Period"
                                InputLabelProps={{ shrink: true }}
                                value={formik.values?.beginningPeriod || ''}
                                formControlProps={{ sx: { width: '100%', } }}
                              />

                              <Input
                                disabled
                                type={'date'}
                                variant="standard"
                                label="Ending Period"
                                InputLabelProps={{ shrink: true }}
                                value={formik.values?.endingPeriod || ''}
                                formControlProps={{ sx: { width: '100%', } }}
                              />
                            </Stack>
                          </>
                          :
                          <Stack direction={'row'} spacing={'16px'} sx={{ marginTop: '16px' }}>
                            <Input
                              type={'date'}
                              variant="standard"
                              label="Transaction Start"
                              InputLabelProps={{ shrink: true }}
                              value={formik.values.transactionStart}
                              formControlProps={{ sx: { width: '100%', } }}
                              onChange={(e) => { formik.setFieldValue('transactionStart', e.target.value) }}
                            />

                            <Input
                              type={'date'}
                              variant="standard"
                              label="Transaction End"
                              InputLabelProps={{ shrink: true }}
                              value={formik.values.transactionEnd}
                              formControlProps={{ sx: { width: '100%', } }}
                              onChange={(e) => { formik.setFieldValue('transactionEnd', e.target.value) }}
                            />
                          </Stack>
                      }
                    </CardContent>
                  </Card>

                  <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px', marginTop: '24px' }}>
                    <CardContent>
                      <Stack direction={'column'} spacing={'12px'}>
                        <Typography
                          sx={{
                            color: 'var(--Primary-One, #083A50)',
                            fontFamily: 'Nunito',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '19.6px',
                          }}
                        >
                          Custom Customer
                        </Typography>

                        {
                          formik.values.customerType.map((cust, index) => (
                            <Grid key={index} container item direction="row">
                              <Grid item xs={4} display={'flex'}>
                                <TickBox
                                  size={'small'}
                                  label={
                                    <Typography
                                      sx={{
                                        color: 'var(--Blue-Primary-1, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '12px !important',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: '20px',
                                      }}
                                    >
                                      {cust.name}
                                    </Typography>
                                  }
                                  name={`customerType.${index}.isActive`}
                                  // baseLabelProps={{ sx: { marginTop: '26px' } }}
                                  checked={formik.values.customerType?.[index]?.isActive}
                                  onChange={() => handleChange(`customerType.${index}.isActive`, formik.values.customerType?.[index]?.isActive)}
                                />
                              </Grid>

                              <Grid item xs={8} alignItems='right' className='text-end' display={'flex'} justifyContent={'flex-end'}>
                                <TickBox
                                  size={'small'}
                                  label={
                                    <Typography
                                      sx={{
                                        color: 'var(--Blue-Primary-1, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '12px !important',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: '20px',
                                      }}
                                    >
                                      Custom
                                    </Typography>
                                  }
                                  name={`customerType.${index}.isCustom`}
                                  // baseLabelProps={{ sx: { marginTop: '26px' } }}
                                  checked={formik.values.customerType?.[index]?.isCustom}
                                  onChange={() => handleChange(`customerType.${index}.isCustom`, formik.values.customerType?.[index]?.isCustom)}
                                />

                                <Input
                                  fullWidth
                                  variant="standard"
                                  placeholder={'All Customer'}
                                  InputLabelProps={{ shrink: true }}
                                  formControlProps={{ sx: { width: '50%', } }}
                                  value={formik.values.customerType?.[index]?.selectedCustomer?.contactName}
                                  disabled={!formik.values.customerType?.[index]?.isActive || !formik.values.customerType?.[index]?.isCustom}
                                  inputBaseProps={{
                                    sx: { marginTop: '-1px !important' },
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={() => toggleModal('customer', { ...cust, index })}
                                          hidden={!formik.values.customerType?.[index]?.isActive || !formik.values.customerType?.[index]?.isCustom}
                                        >
                                          <Icon
                                            iconName={'search'}
                                            svgIconProps={{
                                              fontSize: 'small',
                                              htmlColor: '#0087C2',
                                              sx: {
                                                height: '12px',
                                                width: '12px'
                                              }
                                            }}
                                          />
                                        </IconButton>
                                      </InputAdornment>
                                    )
                                  }}
                                />
                              </Grid>
                            </Grid>
                          ))
                        }
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid
                  item
                  xl={6}
                  lg={6}
                  sm={6}
                  xs={12}
                >
                  <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                    <CardContent>
                      <Stack direction={'column'} spacing={'12px'}>
                        <Typography
                          sx={{
                            color: 'var(--Primary-One, #083A50)',
                            fontFamily: 'Nunito',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '19.6px',
                          }}
                        >
                          Custom Shipment Order
                        </Typography>

                        {
                          formik.values.shipmentOrder.map((so, index) => (
                            <Grid key={index} container item direction="row">
                              <Grid item xs={4} display={'flex'}>
                                <TickBox
                                  size={'small'}
                                  label={
                                    <Typography
                                      sx={{
                                        color: 'var(--Blue-Primary-1, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '12px !important',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: '20px',
                                      }}
                                    >
                                      {so.name}
                                    </Typography>
                                  }
                                  name={`shipmentOrder.${index}.isActive`}
                                  // baseLabelProps={{ sx: { marginTop: '26px' } }}
                                  checked={formik.values.shipmentOrder?.[index]?.isActive}
                                  onChange={() => handleChange(`shipmentOrder.${index}.isActive`, formik.values.shipmentOrder?.[index]?.isActive)}
                                />
                              </Grid>

                              <Grid item xs={8} alignItems='right' className='text-end' display={'flex'} justifyContent={'flex-end'}>
                                <TickBox
                                  size={'small'}
                                  label={
                                    <Typography
                                      sx={{
                                        color: 'var(--Blue-Primary-1, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '12px !important',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: '20px',
                                      }}
                                    >
                                      Custom
                                    </Typography>
                                  }
                                  name={`shipmentOrder.${index}.isCustom`}
                                  // baseLabelProps={{ sx: { marginTop: '26px' } }}
                                  checked={formik.values.shipmentOrder?.[index]?.isCustom}
                                  onChange={() => handleChange(`shipmentOrder.${index}.isCustom`, formik.values.shipmentOrder?.[index]?.isCustom)}
                                />

                                <Input
                                  fullWidth
                                  variant="standard"
                                  placeholder={'All Shipment'}
                                  InputLabelProps={{ shrink: true }}
                                  formControlProps={{ sx: { width: '50%', } }}
                                  value={formik.values.shipmentOrder?.[index]?.selectedShipmentOrder?.shipmentNo || ''}
                                  disabled={!formik.values.shipmentOrder?.[index]?.isActive || !formik.values.shipmentOrder?.[index]?.isCustom}
                                  inputBaseProps={{
                                    sx: { marginTop: '-1px !important' },
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={() => toggleModal('shipmentOrder', { ...so, index })}
                                          hidden={!formik.values.shipmentOrder?.[index]?.isActive || !formik.values.shipmentOrder?.[index]?.isCustom}
                                        >
                                          <Icon
                                            iconName={'search'}
                                            svgIconProps={{
                                              fontSize: 'small',
                                              htmlColor: '#0087C2',
                                              sx: {
                                                height: '12px',
                                                width: '12px'
                                              }
                                            }}
                                          />
                                        </IconButton>
                                      </InputAdornment>
                                    )
                                  }}
                                />
                              </Grid>
                            </Grid>
                          ))
                        }
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Box>
      
      {
        isLoading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <LoadingSpinner />
          </Backdrop>
        )
      }

      {activeModal === 'customer' && (
        <ModalSearchCustomer
          isModal={activeModal === 'customer'}
          toggleModal={toggleModal}
          dataSelected={formik.values.customerType?.[activeData?.index]?.selectedCustomer}
          onSelect={handleSelectCustomer}
          errorToast={errorToast}
          activeData={activeData}
        />
      )}

      {activeModal === 'shipmentOrder' && (
        <ModalSearchShipment
          isModal={activeModal === 'shipmentOrder'}
          toggleModal={toggleModal}
          dataSelected={formik.values.shipmentOrder?.[activeData?.index]?.selectedShipmentOrder}
          onSelect={handleSelectShipment}
          errorToast={errorToast}
          activeData={activeData}
        />
      )}

      {activeModal === 'period' && (
        <ModalSearchPeriod
          isModal={activeModal === 'period'}
          toggleModal={toggleModal}
          dataSelected={selectedPeriod}
          onSelect={handleSelectPeriod}
          errorToast={errorToast}
          formValue={formik.values}
        />
      )}
    </>
  )
})

export default BalanceAPDetailReport