import { Box, MenuItem, Grid, Card, CardContent } from '@mui/material';
import React, { useImperativeHandle } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ApiService, { branchId, companyId, countryId, userId, userCode, username } from '../../../../services/ApiService';

import ButtonCreateEditTemporaryPayment from './Buttons/ButtonCreateEditTemporaryPayment';
import TemporaryPaymentHeader from './Components/TemporaryPaymentHeader';
import TemporaryPaymentListJob from './Components/TemporaryPaymentListJob';
import TemporaryPaymentFor from './Components/TemporaryPaymentFor';
import TemporaryPaymentTotalPayment from './Components/TemporaryPaymentTotalPayment';
import { forwardRef } from 'react';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Selection, showToast } from '../../../../components/ui';
import ButtonInfo from '../../../../components/Components/ButtonInfo';
import { convertToNominal, convertToNumeric } from '../../../../utils/masks.jsx';
import { defaultDate, getDefaultDateFormat, nullDate, setDateFormat, updateDateFormat } from '../../../../utils/date.jsx';

const CreateEditTemporaryPayment = forwardRef((props, ref) => {
    const dataLocal = JSON.parse(localStorage.getItem(username + "-DataTP"));

    const { temporaryPaymentId } = useParams();
    const { sJobTypeId } = useParams();
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);

    const paramRegJobType = 'regJobType/regJobType';
    const [jobType, setJobType] = useState([]);

    const paramTemporaryPayment = 'temporaryPayment/temporaryPayment';
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const paramGL = 'generalLedger/generalLedger';

    const paramJobOwner = 'jobOwner/jobOwner';
    const [jobOwnerData, setJobOwnerData] = useState([]);

    const history = useNavigate();
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);
    const [hasSaveBtn, setHasSaveBtn] = useState(false);

    const [isLock, setIsLock] = useState(false);

    //Temporary Payment Variable
    const [RowStatus, setRowStatus] = useState('ACT');
    const [TicketId, setTicketId] = useState(0);
    const [TPNumber, setTPNumber] = useState(0);
    const [PaymentTo, setPaymentTo] = useState(4);
    const [CustomerId, setCustomerId] = useState(0);
    const [CustomerCode, setCustomerCode] = useState('');
    const [CustomerName, setCustomerName] = useState('');
    const [AccountCode, setAccountCode] = useState('');
    const [AccountName, setAccountName] = useState('');
    const [JobOwnerId, setJobOwnerId] = useState(0);
    const [DueDate, setDueDate] = useState('');
    const [Printing, setPrinting] = useState(0);
    const [PrintDate, setPrintDate] = useState('');
    const [VerifyAcc, setVerifyAcc] = useState('');
    const [verify, setVerify] = useState('No');
    const [verifyAccOn, setVerifyAccOn] = useState('');
    const [paidPV, setPaidPV] = useState(false);
    const [period, setPeriod] = useState(0);
    const [yearPeriod, setyearPeriod] = useState(0);
    const [tpNo2, setTpNo2] = useState('');
    const [deletedRemarks, setDeletedRemarks] = useState('');
    const [wfComplete, setWFComplete] = useState(0);
    const [deleted, setDeleted] = useState(false);
    const [deletedOn, setDeletedOn] = useState('');
    const [RateId, setRateId] = useState(0);
    const [Rate, setRate] = useState(0);
    const [RateReal, setRateReal] = useState(0);
    const [RateDate, setRateDate] = useState('');
    const [isApprovedAcc, setIsApprovedAcc] = useState(false);

    const [ShipmentOrderList, setShipmentOrderList] = useState([]);
    const [ShipmentOrderIdList, setShipmentOrderIdList] = useState([]);

    const [isEdit, setIsEdit] = useState(false);
    const [selectedShipmentOrder, setSelectedShipmentOrder] = useState({});
    const [BankData, setBankData] = useState([]);
    const [CashPaymentUSD, setCashPaymentUSD] = useState(0);
    const [CashPaymentIDR, setCashPaymentIDR] = useState(0);
    const [TotalCashUSD, setTotalCashUSD] = useState(0);
    const [TotalCashIDR, setTotalCashIDR] = useState(0);
    const [TotalBankUSD, setTotalBankUSD] = useState(0);
    const [TotalBankIDR, setTotalBankIDR] = useState(0);

    const [TotalCashUSDReal, setTotalCashUSDReal] = useState(0);
    const [TotalCashIDRReal, setTotalCashIDRReal] = useState(0);
    const [TotalBankUSDReal, setTotalBankUSDReal] = useState(0);
    const [TotalBankIDRReal, setTotalBankIDRReal] = useState(0);

    const [isJobOwner, setIsJobOwner] = useState(true);
    const [isDueDate, setIsDueDate] = useState(false);

    const [tabValue, setTabValue] = useState('Cash');

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');
    const [isPDueDate, setIsPDueDate] = useState(false);
    const [dateDue, setDateDue] = useState('');
    const [documentStatus, setDocumentStatus] = useState('');

    const [isUnapproved, setIsUnapproved] = useState(false);

    const BreadcrumbsItems = [
        { label: 'Infoss', link: '/Infoss', style: '' },
        { label: 'Temporary Payment', link: '/Infoss/TemporaryPayment', style: '' },
        { label: 'Create Temporary Payment', link: '/Infoss/TemporaryPayment/Create/' + sJobTypeId, style: { color: '#0087C2', fontWeight: '700' } },
    ];

    const BreadcrumbsEditItems = [
        { label: 'Infoss', link: '/Infoss', style: '' },
        { label: 'Temporary Payment', link: '/Infoss/TemporaryPayment', style: '' },
        { label: 'Detail Temporary Payment', link: '/Infoss/TemporaryPayment/' + sJobTypeId + '/' + temporaryPaymentId, style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useEffect(() => {
        document.title = 'Temporary Payment - Detail - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        inputLock();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useImperativeHandle(ref, () => ({
        localSave() {
            saveDataLocal();
        }
    }));

    const saveDataLocal = () => {
        let id = temporaryPaymentId ? temporaryPaymentId : 0;
        let datePrint = updateDateFormat(PrintDate, true);
        let dateVerify = updateDateFormat(verifyAccOn, true);
        let dateDelete = updateDateFormat(deletedOn, true);

        var data = {
            "temporaryPayment": {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "rowStatus": RowStatus,
                "id": id,
                "ticketId": TicketId,
                "tpNo": TPNumber,
                "jobTypeId": jobType.id,
                "customerId": CustomerId,
                "customerCode": CustomerCode,
                "customerName": CustomerName,
                "totalCashUSD": TotalCashUSDReal,
                "totalCashIDR": TotalCashIDRReal,
                "totalBankUSD": TotalBankUSDReal,
                "totalBankIDR": TotalBankIDRReal,
                "exRateId": RateId,
                "rate": RateReal,
                "exRateDate": RateDate,
                "verifyAcc": VerifyAcc,
                "verifyAccOn": dateVerify,
                "printing": Printing,
                "printedOn": datePrint,
                "paidPV": paidPV,
                "period": period,
                "yearPeriod": yearPeriod,
                "paymentTo": PaymentTo,
                "tpDueDate": dateDue,
                "jobOwnerId": JobOwnerId,
                "deleted": deleted,
                "deletedOn": dateDelete,
                "tpNo2": tpNo2,
                "accountCode": AccountCode,
                "accountName": AccountName,
                "deletedRemarks": deletedRemarks,
                "wfComplete": wfComplete,
                "isApprovedAcc": isApprovedAcc,
                "idLama": 0,
            },
            "temporaryPaymentJobs": ShipmentOrderList,
        };

        localStorage.setItem(username + "-DataTP", JSON.stringify(data));
    }

    const getLocalData = () => {
        let arrayJob = [];
        let temporaryPaymentData = dataLocal.temporaryPayment;
        let temporaryPaymentJobData = dataLocal.temporaryPaymentJobs;

        if (temporaryPaymentId) {
            setIsEdit(true);
        }

        setRowStatus(temporaryPaymentData.rowStatus);
        setTicketId(temporaryPaymentData.ticketId);
        setTPNumber(temporaryPaymentData.tpNo);
        setPaymentTo(temporaryPaymentData.paymentTo);
        setCustomerId(temporaryPaymentData.customerId);
        setCustomerCode(temporaryPaymentData.customerCode);
        setCustomerName(temporaryPaymentData.customerName);
        setAccountCode(temporaryPaymentData.accountCode);
        setAccountName(temporaryPaymentData.accountName);
        setJobOwnerId(temporaryPaymentData.jobOwnerId);
        setPrinting(temporaryPaymentData.printing);
        setVerifyAcc(temporaryPaymentData.approvedAccBy);
        setVerify(temporaryPaymentData.isApprovedAcc === false ? 'No' : 'Yes');
        setPaidPV(temporaryPaymentData.paidPV);
        setPeriod(temporaryPaymentData.period);
        setyearPeriod(temporaryPaymentData.yearPeriod);
        setTpNo2(temporaryPaymentData.tpNo2);
        setDeletedRemarks(temporaryPaymentData.deletedRemarks);
        setWFComplete(temporaryPaymentData.wfComplete);
        setDeleted(temporaryPaymentData.deleted);
        setRateId(temporaryPaymentData.exRateId);
        setRate(convertToNominal(temporaryPaymentData.rate));
        setRateReal(temporaryPaymentData.rate);
        setIsApprovedAcc(temporaryPaymentData.isApprovedAcc);
        handleDocumentStatus(temporaryPaymentData.rowStatus, temporaryPaymentData.isApprovedAcc);

        setDateFormat(temporaryPaymentData.exRateDate, setRateDate);
        setDateFormat(temporaryPaymentData.deletedOn, setDeletedOn);
        setDateFormat(temporaryPaymentData.approvedAccOn, setVerifyAccOn);
        setDateFormat(temporaryPaymentData.printedOn, setPrintDate);

        let dateDue = getDefaultDateFormat(temporaryPaymentData.tpDueDate);
        if (dateDue !== nullDate && dateDue !== getDefaultDateFormat(defaultDate) && dateDue !== '') {
            setDueDate(dateDue);
            setIsDueDate(true);
        }

        setShipmentOrderList(temporaryPaymentJobData);
        arrayJob = temporaryPaymentJobData;
        let tempArrayId = [];
        arrayJob.forEach(element => {
            tempArrayId = [...tempArrayId, element.shipmentOrderId];
        });
        setShipmentOrderIdList(tempArrayId);
        calculateTotalCash(arrayJob);
        calculateTotalBank(arrayJob);

        localStorage.removeItem(username + '-DataTP');
        setTimeout(function () { setIsLoadingBackDrop(false); }, 1250);
    }

    function handleDocumentStatus(rowStatus = 'ACT', isApproved = false) {
        if (rowStatus !== 'ACT') {
            setDocumentStatus('DELETED');
        }
        else if (isApproved) {
            setDocumentStatus('APPROVED');
        }
        else {
            setDocumentStatus('ONGOING');
        }
    }

    const getData = (id) => {
        let arrayJob = [];
        ApiService.FinAccPostById(paramTemporaryPayment, id)
            .then((response) => {
                if (response.data.code === 200) {
                    let temporaryPaymentData = response.data.data.temporaryPayment;
                    setIsEdit(true);
                    setRowStatus(temporaryPaymentData.rowStatus);
                    setTicketId(temporaryPaymentData.ticketId);
                    setTPNumber(temporaryPaymentData.tpNo);
                    setPaymentTo(temporaryPaymentData.paymentTo);
                    setCustomerId(temporaryPaymentData.customerId);
                    setCustomerCode(temporaryPaymentData.customerCode);
                    setCustomerName(temporaryPaymentData.customerName);
                    setAccountCode(temporaryPaymentData.accountCode);
                    setAccountName(temporaryPaymentData.accountName);
                    setJobOwnerId(temporaryPaymentData.jobOwnerId);
                    setPrinting(temporaryPaymentData.printing);
                    setVerifyAcc(temporaryPaymentData.approvedAccBy);
                    setVerify(temporaryPaymentData.isApprovedAcc === false ? 'No' : 'Yes');
                    handleDocumentStatus(temporaryPaymentData.rowStatus, temporaryPaymentData.isApprovedAcc);
                    setPaidPV(temporaryPaymentData.paidPV);
                    setPeriod(temporaryPaymentData.period);
                    setyearPeriod(temporaryPaymentData.yearPeriod);
                    setTpNo2(temporaryPaymentData.tpNo2);
                    setDeletedRemarks(temporaryPaymentData.deletedRemarks);
                    setWFComplete(temporaryPaymentData.wfComplete);
                    setDeleted(temporaryPaymentData.deleted);
                    setRateId(temporaryPaymentData.exRateId);
                    setIsApprovedAcc(temporaryPaymentData.isApprovedAcc);
                    setRate(convertToNominal(temporaryPaymentData.rate));
                    setRateReal(temporaryPaymentData.rate);

                    setDateFormat(temporaryPaymentData.exRateDate, setRateDate);
                    setDateFormat(temporaryPaymentData.deletedOn, setDeletedOn);
                    setDateFormat(temporaryPaymentData.approvedAccOn, setVerifyAccOn);
                    setDateFormat(temporaryPaymentData.printedOn, setPrintDate);

                    let dateDue = getDefaultDateFormat(temporaryPaymentData.tpDueDate);
                    if (dateDue !== nullDate && dateDue !== getDefaultDateFormat(defaultDate) && dateDue !== '') {
                        setDueDate(dateDue);
                        setIsDueDate(true);
                    }

                    arrayJob = temporaryPaymentData.temporaryPaymentJobs;
                    let tempArrayId = [];

                    arrayJob.forEach(element => {
                        tempArrayId = [...tempArrayId, element.shipmentOrderId];

                        if (element.temporaryPaymentJobBanks.length > 0) {
                            element.temporaryPaymentJobBanks.forEach(el => {
                                let dateDue = getDefaultDateFormat(el.dueDate);
                                if (dateDue === nullDate || dateDue === '') {
                                    el.dueDate = defaultDate;
                                }
                            });
                        }
                    });

                    setShipmentOrderList(arrayJob);
                    setShipmentOrderIdList(tempArrayId);
                }
                else {
                    Back();
                }
            })
            .then(() => {
                calculateTotalCash(arrayJob);
                calculateTotalBank(arrayJob);

                if (!dataLocal) {
                    setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const getDataApproved = () => {
        let data = {};
        ApiService.CustomFinanceCreate(paramTemporaryPayment + '/Create?check=true', data)
            .then((res) => {
                if (res.data.code === 200 && res.data.data !== null) {
                    let data = res.data.data;

                    if (data != null) {
                        setIsUnapproved(true);

                        if (!temporaryPaymentId) {
                            Back();
                        }
                    }
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            });
    }

    const getDataAccess = () => {
        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenu"));
        let hasAccess = false

        const arrMenu = listMenu.find((menu) => menu.name === 'FINANCE')?.children || []

        const indexMenu = arrMenu.findIndex((elm) => elm.code === 'FTEP')
        if (indexMenu !== -1) {
            pId = arrMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        const userAccess = response.data.data.userAccess;
                        setDataRegUserAccess(userAccess);

                        const indexElm = userAccess.findIndex((elm) => ['CRE', 'UPD'].includes(elm.accessCode))
                        if (indexElm !== -1) {
                            setHasSaveBtn(true)
                        }
                        getDataApproved();
                        handleDocumentStatus();
                        getAdditionalData();
                    }
                    else {
                        Back();
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
                })
        } else {
            history('/Infoss');
        }
    }

    function getDateNow() {
        setDateFormat(new Date(), setDueDate);
    };

    const getAdditionalData = async () => {
        setIsLoadingBackDrop(true);

        try {
            const [resJT, resJO] = await Promise.all([
                ApiService.PostById(paramRegJobType, sJobTypeId),
                ApiService.PostByPage(paramJobOwner, 1, 20),
            ])

            if (resJT.data.code === 200) setJobType(resJT.data.data.jobType);

            if (resJO.data.code === 200) {
                setIsJobOwner(false);
                setJobOwnerId(resJO.data.data.jobOwner[0].id);
                setJobOwnerData(resJO.data.data.jobOwner);
            }

            if (dataLocal) {
                setTimeout(function () { getLocalData(); }, 2000);
            } else {
                if (temporaryPaymentId) {
                    getData(temporaryPaymentId);
                } else {
                    GetFormatCurrency();

                    GetExRate(new Date().toISOString().split('T')[0]);
                }
            }

        } catch (error) {
            console.log(error)
            setIsLoadingBackDrop(false);
            showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
        }
    }

    const selectPrincipleBy = (
        <>
            <Selection
                id='0'
                label={'Principle By'}
                fullWidth={true}
                placeholder={'Select Principle By'}
                value={JobOwnerId}
                onChange={(e) => handleCheckJobOwner(e.target.value)}
                disabled={isApprovedAcc}
            >
                {
                    jobOwnerData.map((data, index) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.id}>{data.masterCode}</MenuItem>
                    ))
                }
            </Selection>
        </>
    );

    const GetExRate = (date) => {
        ApiService.PostByExRateDate(date)
            .then((response) => {
                if (response.data.code === 200) {
                    setRateId(response.data.data.exchangeRate.id);
                    setRate(convertToNominal(response.data.data.exchangeRate.exRate1));
                    setRateReal(response.data.data.exchangeRate.exRate1);
                    setDateFormat(response.data.data.exchangeRate.exRateDate, setRateDate);
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const GetFormatCurrency = () => {
        let totalUSD = 0;
        let totalIDR = 0;
        let formatUSD = convertToNominal(`${totalUSD}`);
        let formatIDR = convertToNominal(`${totalIDR}`);

        setTotalCashUSD(formatUSD);
        setTotalCashIDR(formatIDR);
        setTotalBankUSD(formatUSD);
        setTotalBankIDR(formatIDR);

        if (!dataLocal) {
            setIsLoadingBackDrop(false);
        }
    };

    function Save(isDeleted, isPrint = false, result) {
        if (isLoadingBackDrop) return
        if (isDeleted === false) {
            setIsLoadingBackDrop(true);
        }

        let id = temporaryPaymentId ? temporaryPaymentId : 0;
        let datePrint = updateDateFormat(PrintDate, true);
        let dateVerify = updateDateFormat(verifyAccOn, true);
        let dateDelete = updateDateFormat(deletedOn, true);
        let dateDue = updateDateFormat(DueDate, true);
        let arrayShipment = [];
        let totalCashUSD = 0;
        let totalCashIDR = 0;
        let totalBankUSD = 0;
        let totalBankIDR = 0;

        if (isDeleted === false) {
            arrayShipment = ShipmentOrderList;
            totalCashUSD = TotalCashUSDReal;
            totalCashIDR = TotalCashIDRReal;
            totalBankUSD = TotalBankUSDReal;
            totalBankIDR = TotalBankIDRReal;
        } else {
            arrayShipment = result;

            for (let i = 0; i < result.length; i++) {
                totalCashUSD = parseFloat(totalCashUSD) + parseFloat(result[i].cashUSD);
                totalCashIDR = parseFloat(totalCashIDR) + parseFloat(result[i].cashIDR);

                for (let j = 0; j < result[i].temporaryPaymentJobBanks.length; j++) {
                    if (result[i].temporaryPaymentJobBanks[j].amountCrr === 0) {
                        totalBankUSD = parseFloat(totalBankUSD) + parseFloat(result[i].temporaryPaymentJobBanks[j].amount);
                    } else if (result[i].temporaryPaymentJobBanks[j].amountCrr === 1) {
                        totalBankIDR = parseFloat(totalBankIDR) + parseFloat(result[i].temporaryPaymentJobBanks[j].amount);
                    }
                }
            }
        }

        var data = {
            "temporaryPayment": {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "rowStatus": RowStatus,
                "id": id,
                "ticketId": TicketId,
                "tpNo": TPNumber,
                "jobTypeId": jobType.jobTypeId,
                "customerId": CustomerId,
                "customerCode": CustomerCode,
                "customerName": CustomerName,
                "totalCashUSD": totalCashUSD,
                "totalCashIDR": totalCashIDR,
                "totalBankUSD": totalBankUSD,
                "totalBankIDR": totalBankIDR,
                "exRateId": RateId,
                "rate": RateReal,
                "exRateDate": RateDate,
                "approvedAccBy": VerifyAcc,
                "approvedAccOn": dateVerify,
                "printing": Printing,
                "printedOn": datePrint,
                "paidPV": paidPV,
                "period": period,
                "yearPeriod": yearPeriod,
                "paymentTo": PaymentTo,
                "tpDueDate": dateDue,
                "jobOwnerId": JobOwnerId,
                "deleted": deleted,
                "deletedOn": dateDelete,
                "tpNo2": tpNo2,
                "accountCode": AccountCode,
                "accountName": AccountName,
                "deletedRemarks": deletedRemarks,
                "wfComplete": wfComplete,
                "isApprovedAcc": isApprovedAcc,
                "idLama": 0,
            },
            "temporaryPaymentJobs": arrayShipment,
        };

        ApiService.HelpCreateData(data, paramTemporaryPayment, id)
            .then((res) => {
                if (res.data.code === 200) {
                    let message = '';
                    if (id === 0) {
                        ApiService.CreateFinAcc(paramTemporaryPayment, data).then((res) => {
                            if (res.data.code === 200) {
                                if (isDeleted === false) {
                                    setIsLoadingBackDrop(false);
                                    message = 'Create Data Success';

                                    showToast({ type: 'success', message: message });

                                    setTimeout(
                                        function () {
                                            localStorage.setItem('tempId', JSON.stringify(res.data.data.Id));
                                            history('/Infoss/TemporaryPayment/' + sJobTypeId + '/' + JSON.parse(localStorage.getItem("tempId")), { replace: true });
                                            window.location.reload();
                                            localStorage.removeItem('tempId');
                                        },
                                        1250);
                                }
                            } else {
                                if (isDeleted === false) {
                                    setIsLoadingBackDrop(false);
                                    showToast({ type: 'error', message: res.data.message });
                                }
                            }
                        });
                    }
                    else {
                        ApiService.EditFinAcc(paramTemporaryPayment, data).then((res) => {
                            if (res.data.code === 200) {
                                if (isDeleted === false && isPrint === false) {
                                    setIsLoadingBackDrop(false);
                                    message = 'Update Data Success';

                                    showToast({ type: 'success', message: message });

                                    setTimeout(
                                        function () {
                                            history('/Infoss/TemporaryPayment/' + sJobTypeId + '/' + temporaryPaymentId, { replace: true });
                                            window.location.reload();
                                        },
                                        1250);
                                }
                                else if (isPrint) {
                                    Print();
                                }
                            } else {
                                if (isDeleted === false) {
                                    setIsLoadingBackDrop(false);
                                    showToast({ type: 'error', message: res.data.message });
                                }
                            }
                        });
                    }
                }
                else {
                    setIsLoadingBackDrop(false);

                    showToast({ type: 'error', message: 'Failed to Create Help Data!' });
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: 'You Don\'t Have Access, Please Contact The Administrator!' });
            })
    }

    function Back() {
        if (isEdit && !ShipmentOrderList.length) {
            showToast({ type: 'error', message: 'Please Add Temporary Payment Job!' });
        } else {
            history('/Infoss/TemporaryPayment');
        }
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        } else {
            setIsModalConfirmation(false);
            setIsPDueDate(false);
        }
    }

    function getDateNowP() {
        setDateFormat(new Date(), setDateDue);
    };

    function ConfirmationTP() {
        if (titleConfirmation === 'Print') {
            if (isApprovedAcc === false) {
                Save(false, true);
            }
            else {
                Print();
            }
        } else if (titleConfirmation === 'Approve') {
            Approve();
        }
        setIsModalConfirmation(false);
    }

    const PrintData = () => {
        if (temporaryPaymentId) {
            setTitleConfirmation('Print')
            setTitleModalConfirmation('Temporary Payment');
            handleClickConfirmation();

            if (Printing === 0) {
                getDateNowP();
            }
            else {
                setDateFormat(PrintDate, setDateDue);
            }

            if (convertToNumeric(TotalCashIDR) === 0 && convertToNumeric(TotalCashUSD) === 0) {
                setIsPDueDate(true);
            }
        } else {
            showToast({ type: 'error', message: 'Create The Data First!' });
        }
    }

    const Print = () => {
        setIsLoadingBackDrop(true);
        let dueDate = dateDue === '' ? updateDateFormat(new Date()) : dateDue;
        ApiService.FinAccBackDatePrint(paramTemporaryPayment, temporaryPaymentId, dueDate).then((res) => {
            let message = '';
            message = res.data.message;
            if (res.data.code === 200) {
                let count = parseInt(Printing) + 1;
                setPrinting(count);

                PrintPDF();
                setIsDueDate(false);
            } else {
                setIsLoadingBackDrop(false);
                setIsDueDate(false);
                showToast({ type: 'error', message: message });
            }
        });
    };

    const PrintPDF = () => {
        let variable = 'PrintTP?Id=' + temporaryPaymentId;
        ApiService.ReportPrint('Transactions/Finance/TemporaryPaymentReport/', variable)
            .then(response => {
                if (response.status === 200) {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' }
                    );

                    const fileURL = URL.createObjectURL(file);

                    setIsLoadingBackDrop(false);
                    showToast({ type: 'success', message: 'Print Temporary Payment Success!' });
                    window.open(fileURL);
                } else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'Can\'t Print Temporary Payment!' });
                }
            })
            .catch(error => {
                setIsLoadingBackDrop(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Print Temporary Payment!' });
            });
    }

    const CreateGL = (id) => {
        var data = {
            "generalLedger": {
                "source": "TP",
                "refId": id,
                "methodPost": "AUTO",
                "procedureName": '[finance].SP_GeneralLedger_SBSendRequestMessage'
            }
        }

        ApiService.CreateFinAcc(paramGL, data).then((res) => {
            let message = '';
            message = res.data.message;
            if (res.data.code !== 200) {
                showToast({ type: 'error', message: message });
            }
        });
    }

    const ApproveData = () => {
        if (temporaryPaymentId) {
            if (Printing > 0) {
                if (isApprovedAcc === false) {
                    setTitleConfirmation('Approve')
                    setTitleModalConfirmation('Temporary Payment');
                    handleClickConfirmation();
                } else {
                    showToast({ type: 'error', message: 'Data Already Been Approved!' });
                }
            } else {
                showToast({ type: 'error', message: 'Please Print The Data First!' });
            }
        } else {
            showToast({ type: 'error', message: 'Create The Data First!' });
        }
    }

    const Approve = () => {
        setIsLoadingBackDrop(true);
        ApiService.FinAccUpdateStatusApprovedAcc(paramTemporaryPayment, temporaryPaymentId).then((res) => {
            let message = '';
            message = res.data.message;
            if (res.data.code === 200) {
                setIsLoadingBackDrop(false);
                CreateGL(temporaryPaymentId);
                showToast({ type: 'success', message: message });

                setTimeout(
                    function () {
                        history('/Infoss/TemporaryPayment');
                    },
                    1250);

            } else {
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: message });
            }
        })
            .catch(error => {
                setIsLoadingBackDrop(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Approve Temporary Payment!' });
            });
    }

    function AddNew() {
        history('/Infoss/TemporaryPayment/Create/' + sJobTypeId, { replace: true });
        window.location.reload();
    }

    function inputLock() {
        if (temporaryPaymentId) {
            setIsLock(true);
        } else {
            setIsLock(false);
        }
    }

    function calculateTotalCash(result) {
        let totalUSD = 0;
        let totalIDR = 0;

        for (let i = 0; i < result.length; i++) {
            totalUSD = parseFloat(totalUSD) + parseFloat(result[i].cashUSD);
            totalIDR = parseFloat(totalIDR) + parseFloat(result[i].cashIDR);
        }

        setTotalCashUSDReal(totalUSD);
        setTotalCashIDRReal(totalIDR);

        let count3 = convertToNominal(totalUSD);
        let count4 = convertToNominal(totalIDR);

        setTotalCashUSD(count3);
        setTotalCashIDR(count4);
    }

    function calculateTotalBank(result) {
        let totalUSD = 0;
        let totalIDR = 0;

        for (let i = 0; i < result.length; i++) {
            for (let j = 0; j < result[i].temporaryPaymentJobBanks.length; j++) {
                if (result[i].temporaryPaymentJobBanks[j].amountCrr === 0) {
                    totalUSD = parseFloat(totalUSD) + parseFloat(result[i].temporaryPaymentJobBanks[j].amount);
                } else if (result[i].temporaryPaymentJobBanks[j].amountCrr === 1) {
                    totalIDR = parseFloat(totalIDR) + parseFloat(result[i].temporaryPaymentJobBanks[j].amount);
                }
            }
        }

        setTotalBankUSDReal(totalUSD);
        setTotalBankIDRReal(totalIDR);

        let count6 = convertToNominal(totalUSD);
        let count7 = convertToNominal(totalIDR);

        setTotalBankUSD(count6);
        setTotalBankIDR(count7);
    }

    function handleCheckJobOwner(val) {
        setJobOwnerId(val);
        if (isLock === false) {
            if (val !== undefined || val !== 0 || val !== null || val !== '') {
                setIsJobOwner(false);
            } else {
                setIsJobOwner(true);
            }
        } else {
            setIsJobOwner(true);
        }
    }

    const ButtonComponents = () => {
        return (
            <ButtonCreateEditTemporaryPayment
                Save={Save}
                Back={Back}
                Print={PrintData}
                Approve={ApproveData}
                AddNew={AddNew}
                DataRegUserAccess={DataRegUserAccess}
                hasSaveBtn={hasSaveBtn}
                isEdit={isEdit}
                isApprovedAcc={isApprovedAcc}
                isUnapproved={isUnapproved}
            />
        )
    }

    return (
        <>
            <Box sx={{ backgroundColor: '#E4E8EC', width: '100%', p: '24px 32px' }}>
                <CustomToastContainer />
                <Grid container spacing={0} direction="column">
                    <HeaderInfo
                        title={
                            temporaryPaymentId ?
                                'Temporary Payment - ' + jobType.name + ' (' + tpNo2 + ')'
                                :
                                'Temporary Payment - ' + jobType.name + ' ( New Document )'
                        }
                        BreadcrumbsItems={temporaryPaymentId ? BreadcrumbsEditItems : BreadcrumbsItems}
                        type={'Form'}
                        ticketId={TicketId}
                        BackFunction={Back}
                        documentStatus={documentStatus}
                    />

                    <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                        <CardContent>
                            <TemporaryPaymentHeader
                                selectPrincipleBy={selectPrincipleBy}
                                temporaryPaymentId={temporaryPaymentId}
                                isLock={isLock}
                                isJobOwner={isJobOwner}
                                JobOwnerId={JobOwnerId}
                                verifyAccOn={verifyAccOn}
                                getDateNow={getDateNow}
                                TPNumber={tpNo2}
                                PaymentTo={PaymentTo}
                                setPaymentTo={setPaymentTo}
                                CustomerId={CustomerId}
                                setCustomerId={setCustomerId}
                                isApprovedAcc={isApprovedAcc}
                                CustomerCode={CustomerCode}
                                setCustomerCode={setCustomerCode}
                                CustomerName={CustomerName}
                                setCustomerName={setCustomerName}
                                AccountCode={AccountCode}
                                setAccountCode={setAccountCode}
                                AccountName={AccountName}
                                setAccountName={setAccountName}
                                DueDate={DueDate}
                                setDueDate={setDueDate}
                                PrintDate={PrintDate}
                                verify={verify}
                                isEdit={isEdit}
                                Rate={Rate}
                                RateDate={RateDate}
                                GetExRate={GetExRate}
                                isDueDate={isDueDate}
                                setIsDueDate={setIsDueDate}
                                setShipmentOrderList={setShipmentOrderList}
                                setShipmentOrderIdList={setShipmentOrderIdList}
                                setCashPaymentUSD={setCashPaymentUSD}
                                setCashPaymentIDR={setCashPaymentIDR}
                                setBankData={setBankData}
                                calculateTotalBank={calculateTotalBank}
                                calculateTotalCash={calculateTotalCash}
                            />
                        </CardContent>
                    </Card>

                    <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                        <CardContent>
                            <TemporaryPaymentListJob
                                calculateTotalBank={calculateTotalBank}
                                calculateTotalCash={calculateTotalCash}
                                setSelectedShipmentOrder={setSelectedShipmentOrder}
                                temporaryPaymentId={temporaryPaymentId}
                                CustomerId={CustomerId}
                                jobType={jobType}
                                setBankData={setBankData}
                                Rate={RateReal}
                                RateId={RateId}
                                setCashPaymentUSD={setCashPaymentUSD}
                                setCashPaymentIDR={setCashPaymentIDR}
                                ShipmentOrderList={ShipmentOrderList}
                                setShipmentOrderList={setShipmentOrderList}
                                PaymentTo={PaymentTo}
                                Save={Save}
                                isApprovedAcc={isApprovedAcc}
                                ShipmentOrderIdList={ShipmentOrderIdList}
                                setShipmentOrderIdList={setShipmentOrderIdList}
                            />
                        </CardContent>
                    </Card>

                    <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                        <CardContent>
                            <TemporaryPaymentFor
                                tabValue={tabValue}
                                setTabValue={setTabValue}
                                isApprovedAcc={isApprovedAcc}
                                Rate={RateReal}
                                selectedShipmentOrder={selectedShipmentOrder}
                                temporaryPaymentId={temporaryPaymentId}
                                ShipmentOrderList={ShipmentOrderList}
                                setShipmentOrderList={setShipmentOrderList}
                                BankData={BankData}
                                setBankData={setBankData}
                                calculateTotalCash={calculateTotalCash}
                                calculateTotalBank={calculateTotalBank}
                                CashPaymentUSD={CashPaymentUSD}
                                setCashPaymentUSD={setCashPaymentUSD}
                                CashPaymentIDR={CashPaymentIDR}
                                setCashPaymentIDR={setCashPaymentIDR}
                                TotalCashUSD={TotalCashUSD}
                                setTotalCashUSD={setTotalCashUSD}
                                TotalCashIDR={TotalCashIDR}
                                setTotalCashIDR={setTotalCashIDR}
                                TotalBankUSD={TotalBankUSD}
                                setTotalBankUSD={setTotalBankUSD}
                                TotalBankIDR={TotalBankIDR}
                                setTotalBankIDR={setTotalBankIDR}
                            />
                        </CardContent>
                    </Card>

                    <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                        <CardContent>
                            <TemporaryPaymentTotalPayment
                                TotalCashUSD={TotalCashUSD}
                                TotalCashIDR={TotalCashIDR}
                                TotalBankUSD={TotalBankUSD}
                                TotalBankIDR={TotalBankIDR}
                            />
                        </CardContent>
                    </Card>
                </Grid>

                {
                    isModalConfirmation && (
                        <ModalConfirmation
                            isModal={isModalConfirmation}
                            titleModal={titleModalConfirmation}
                            title={titleConfirmation}
                            handleClick={handleClickConfirmation}
                            ConfirmationData={ConfirmationTP}
                            isDueDate={isPDueDate}
                            setIsDueDate={setIsPDueDate}
                            dueDate={dateDue}
                            setDueDate={setDateDue}
                            PrintCount={Printing}
                        />
                    )
                }
            </Box>

            <ButtonInfo
                ButtonComponents={ButtonComponents}
            />

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default CreateEditTemporaryPayment;