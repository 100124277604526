import { Grid, Stack, Button } from '@mui/material';
import React, { useMemo, useState } from 'react'
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import ApiService from '../../../services/ApiService';
import { useEffect } from 'react';
import TableY from '../../../components/Tables/TableY';
import CustomizeButtonComponent from '../../../components/Buttons/CustomizeButtonComponent';
import { Icon, PopUp, showToast } from '../../../components/ui';

function not(a, b) {
    let dataTemp = []
    a.forEach(dt => {
        let cek = true
        b.forEach(dtb => {
            if (dt.accountCode === dtb.accountCode) {
                cek = false
            }
        })

        if (cek) {
            dataTemp.push(dt)
        }
    })
    // return a.filter((value) => b.indexOf(value) === -1);
    return dataTemp
}

const ManageRegCoa = (props) => {
    const [left, setLeft] = React.useState([]);
    const [right, setRight] = React.useState([]);

    const paramReg = "regChartOfAccount/regChartOfAccount";
    const paramMst = "chartOfAccount/chartOfAccount";
    const [isLoading, setIsLoading] = useState(false);
    const [countReg, setCountReg] = useState(0)
    const [countMst, setCountMst] = useState(0)

    const [dataSelectMaster, setDataSelectMaster] = useState([])
    const [dataSelectReg, setDataSelectReg] = useState([])

    useEffect(() => {
        getData(1, 1250)
    }, [])

    const getData = (pageNumber, pageSize) => {
        setIsLoading(true);
        var dataMaster = []
        var dataReg = []
        ApiService.DynamicPostByPage(paramMst, 'PAGE', pageNumber, pageSize, [], [])
            .then((response) => {
                if (response.data.code === 200) {
                    dataMaster = response.data.data.chartOfAccount
                    ApiService.DynamicPostByPage(paramReg, 'PAGE', pageNumber, pageSize, [], [])
                        .then((response) => {
                            if (response.data.code === 200) {
                                dataReg = response.data.data.chartOfAccount
                                let dataTemp = []
                                dataReg.forEach(dt => {
                                    let dataRow = dataMaster.find(row => row.accountCode === dt.accountCode)
                                    dataTemp.push(dataRow)
                                })
                                setRight(dataTemp);
                                setCountReg(dataTemp.length)
                                dataMaster = not(dataMaster, dataTemp)
                                setLeft(dataMaster);
                                setCountMst(dataMaster.length)
                            }
                        })
                        .catch(function (error) {
                            setIsLoading(false)
                            console.log(error)
                            showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error })
                        })
                }
                setIsLoading(false)
            })
            .catch(function (error) {
                setIsLoading(false)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error })
            })
    }

    const Save = () => {
        props.setIsLoadingBackDrop(true);

        let data = {
            "chartOfAccount": right
        }

        ApiService.createData('regChartOfAccount/regChartOfAccount', data)
            .then((response) => {
                if (response.status === 200) {
                    props.getData(1, 50, []);
                    props.handleClick();
                    showToast({ type: 'success', message: 'Register COA Success' })
                    props.setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                setIsLoading(false)
                showToast({ type: 'error', message: 'Error When Save Data, Please Contact The Administrator, ' + error })
                props.setIsLoadingBackDrop(false);
            })
    }

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
        setCountMst(0)
        setCountReg(countReg + countMst)
    };

    const handleCheckedRight = () => {
        let checkLeft = JSON.parse(dataSelectMaster).selectedRows
        let checkRight = JSON.parse(dataSelectReg).selectedRows
        let checkAll = []

        Array.prototype.push.apply(checkAll, checkLeft);
        Array.prototype.push.apply(checkAll, checkRight);

        setRight(right.concat(checkLeft));
        setLeft(not(left, checkLeft));
        setCountMst(countMst - checkLeft.length)
        setCountReg(countReg + checkLeft.length)
    };

    const handleCheckedLeft = () => {
        let checkLeft = JSON.parse(dataSelectMaster).selectedRows
        let checkRight = JSON.parse(dataSelectReg).selectedRows
        let checkAll = []

        Array.prototype.push.apply(checkAll, checkLeft);
        Array.prototype.push.apply(checkAll, checkRight);

        setLeft(left.concat(checkRight));
        setRight(not(right, checkRight));
        setCountMst(countMst + checkRight.length)
        setCountReg(countReg - checkRight.length)
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
        setCountMst(countReg + countMst)
        setCountReg(0)
    };

    const columnsMaster = useMemo(() => [
        {
            Header: 'Master - ' + countMst,
            columns: [
                {
                    Header: 'Account Code',
                    accessor: 'accountCode',
                },
                {
                    Header: 'Account Title',
                    accessor: 'accountTitle'
                }

            ]
        }
    ], [countMst])

    const columnsReg = useMemo(() => [
        {
            Header: 'Reg - ' + countReg,
            columns: [
                {
                    Header: 'Account Code',
                    accessor: 'accountCode'
                },
                {
                    Header: 'Account Title',
                    accessor: 'accountTitle'
                }
            ]
        }
    ], [countReg])

    const mainContainer = (
        <div className='mt-2'>
            <Grid
                container
                spacing={1}
                justifyContent="center"
                alignItems={'center'}
            >
                <Grid item xs={5.5} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div className='rounded-10 table-responsive'>
                        {
                            isLoading ?
                                <LoadingSpinner />
                                :
                                <>
                                    <TableY columns={columnsMaster} data={left} setDataSelect={setDataSelectMaster} />
                                </>
                        }
                    </div>
                </Grid>

                <Grid item xs={1}>
                    <Stack spacing={'16px'} direction="column" alignItems="center">
                        <Button
                            size='small'
                            variant="outlined"
                            className='d-flex align-items-center'
                            sx={{
                                padding: '3px 6px',
                                gap: '4px',
                                marginInlineStart: '12px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '18px !important',
                                minHeight: '28px !important',
                                borderRadius: '8px',
                                '&:hover': {
                                    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                }
                            }}
                            onClick={handleAllRight}
                        >
                            <Icon
                                svgIconProps={{
                                    sx: {
                                        height: '12px',
                                        width: '12px',
                                        transform: 'rotateY(180deg)'
                                    }
                                }}
                                iconName={'double-arrow'}
                            />
                        </Button>

                        <Button
                            size='small'
                            variant="outlined"
                            className='d-flex align-items-center'
                            sx={{
                                padding: '3px 6px',
                                gap: '4px',
                                marginInlineStart: '12px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '18px !important',
                                minHeight: '28px !important',
                                borderRadius: '8px',
                                '&:hover': {
                                    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                }
                            }}
                            onClick={handleCheckedRight}
                        >
                            <Icon
                                svgIconProps={{
                                    sx: {
                                        height: '12px',
                                        width: '12px',
                                        transform: 'rotateY(180deg)'
                                    }
                                }}
                                iconName={'arrow-left'}
                            />
                        </Button>

                        <Button
                            size='small'
                            variant="outlined"
                            className='d-flex align-items-center'
                            sx={{
                                padding: '3px 6px',
                                gap: '4px',
                                marginInlineStart: '12px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '18px !important',
                                minHeight: '28px !important',
                                borderRadius: '8px',
                                '&:hover': {
                                    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                }
                            }}
                            onClick={handleCheckedLeft}
                        >
                            <Icon
                                svgIconProps={{
                                    sx: {
                                        height: '12px',
                                        width: '12px',
                                    }
                                }}
                                iconName={'arrow-left'}
                            />
                        </Button>

                        <Button
                            size='small'
                            variant="outlined"
                            className='d-flex align-items-center'
                            sx={{
                                padding: '3px 6px',
                                gap: '4px',
                                marginInlineStart: '12px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '18px !important',
                                minHeight: '28px !important',
                                borderRadius: '8px',
                                '&:hover': {
                                    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                }
                            }}
                            onClick={handleAllLeft}
                        >
                            <Icon
                                svgIconProps={{
                                    sx: {
                                        height: '12px',
                                        width: '12px',
                                    }
                                }}
                                iconName={'double-arrow'}
                            />
                        </Button>
                    </Stack>
                </Grid>

                <Grid item xs={5.5} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div className='rounded-10 table-responsive'>
                        {
                            isLoading ?
                                <LoadingSpinner />
                                :
                                <>
                                    <TableY columns={columnsReg} data={right} setDataSelect={setDataSelectReg} />
                                </>
                        }
                    </div>
                </Grid>
            </Grid>
        </div>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'account'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={props.titleModal + ' ' + props.title}
                isOpen={props.isModal}
                onClose={props.handleClick}
                draggable={false}
                shrinkable={false}
                disableScrollLock={false}
                maxWidth={'xl'}
                fullWidth={true}
            >
                <div style={{ padding: '0px 6px' }}>
                    {mainContainer}

                    <div style={{ padding: '24px 0px 0px' }}>
                        {CustomizeButtonComponent.SaveCancelModalButton(Save, false, false)}
                    </div>
                </div>
            </PopUp>
        </>
    )
}

export default ManageRegCoa