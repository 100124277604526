import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { MenuItem, Grid, Card, CardContent, Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';

import Tracking from './Components/Tracking';
import ModalBLReport from './Modals/ModalBLReport';
import BillOfLading from './Components/BillOfLading';
import TelexRelease from './Components/TelexRelease';
import ShipmentAdvice from './Components/ShipmentAdvice';
import ModalDeliveryOrder from './Modals/ModalDeliveryOrder';
import ShipmentOrderSea from './Components/ShipmentOrderSea';
import ShipmentOrderAir from './Components/ShipmentOrderAir';
import ShippingInstruction from './Components/ShippingInstruction';
import ShipmentOrderPPJKSea from './Components/ShipmentOrderPPJKSea';
import ShipmentOrderPPJKAir from './Components/ShipmentOrderPPJKAir';
import ShipmentOrderDomestic from './Components/ShipmentOrderDomestic';
import ButtonCreateEditShipment from './Buttons/ButtonCreateEditShipment';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../components/Modals/ModalConfirmation';
import ApiService, { branchId, companyId, countryId, userId, userCode, username } from '../../../services/ApiService';
import HeaderInfo from '../../../components/Components/HeaderInfo';
import CustomToastContainer, { Selection, showToast } from '../../../components/ui';
import ButtonInfo from '../../../components/Components/ButtonInfo';
import { currentDate, defaultDate, getDefaultDateFormat, nullDate, setDateFormat } from '../../../utils/date';
import { convertToNumeric } from '../../../utils/masks.jsx';

const isParallelFetch = true

const CreateEditShipment = forwardRef((props, ref) => {
    const dataLocal = JSON.parse(localStorage.getItem(username + "-DataSO"));

    const { sJobTypeId } = useParams();
    const { shipmentId } = useParams();
    const { bookingId } = useParams();
    const { isToDo } = useParams();

    const [check, setCheck] = useState(0);

    const paramShipmentOrder = 'shipmentOrder/shipmentOrder';

    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);

    const paramRegJobType = 'regJobType/regJobType';
    const [jobType, setJobType] = useState({});
    const [typeJobId, setTypeJobId] = useState(0);
    const [IdJobType, setIdJobType] = useState(0);

    const paramTypeService = 'serviceType/serviceType';
    const [serviceTypeData, setServiceTypeData] = useState([]);

    const paramContainerStatus = 'containerStatus/containerStatus';
    const [containerStatusData, setContainerStatusData] = useState([]);

    const paramJobStatus = 'jobStatus/jobStatus';
    const [jobStatusData, setJobStatusData] = useState([]);

    const paramJobOwner = 'jobOwner/jobOwner';
    const [jobOwnerData, setJobOwnerData] = useState([]);

    const paramShipmentStatus = 'shipmentStatus/shipmentStatus';
    const [shipmentStatusData, setShipmentStatusData] = useState([]);

    const paramContainerType = 'containerType/containerType';
    const [containerTypeData, setContainerTypeData] = useState([]);

    const paramPackagingType = 'packagingType/packagingType';
    const [packagingTypeData, setPackagingTypeData] = useState([]);

    const paramCountry = 'country/country';
    const [currencyData, setCurrencyData] = useState([]);

    const paramTrackingStatus = 'trackingStatus/trackingStatus';
    const [trackingStatusData, setTrackingStatusData] = useState([]);

    const paramBookingConf = 'bookingConfirmation/bookingConfirmation';

    const paramCity = 'city/city';
    const paramBranch = 'branch/branch';
    const paramRegClosingPeriod = 'regClosingPeriod/regClosingPeriod';

    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [isJobClose, setIsJobClose] = useState(false);

    const [contactTypeId, setContactTypeId] = useState();
    const [contactType, setContactType] = useState();
    const [detailTypeId, setDetailTypeId] = useState();
    const [jobClose, setJobClose] = useState(false);

    //Variable for shipment order
    const [JobTypeCode, setJobTypeCode] = useState();
    const [RowStatus, setRowStatus] = useState('ACT');
    const [TicketId, setTicketId] = useState(0);
    const [SubJobNo, setSubJobNo] = useState(0);
    const [JobClosed, setJobClosed] = useState(false);
    const [JobClosedOn, setJobClosedOn] = useState(defaultDate);
    const [Deleted, setDeleted] = useState(false);
    const [DeletedOn, setDeletedOn] = useState(defaultDate);
    const [ApprovedCreditBy, setApprovedCreditBy] = useState('');
    const [ApprovedCreditRemarks, setApprovedCreditRemarks] = useState('');
    const [ApprovedCreditOn, setApprovedCreditOn] = useState(defaultDate);
    const [Requester, setRequester] = useState('');
    const [JobNumber, setJobNumber] = useState(0);

    const [ShipmentId, setShipmentId] = useState(0);
    const [ShipmentNo, setShipmentNo] = useState('');
    const [TotalSub, setTotalSub] = useState(0);
    const [SIReference, setSIReference] = useState('');
    const [SIDate, setSIDate] = useState('');
    const [GoodsRecDate, setGoodsRecDate] = useState('');
    const [BookingConfirmationId, setBookingConfirmationId] = useState(0);
    const [BookingConfirmationNumber, setBookingConfirmationNumber] = useState('');
    const [principleBy, setPrincipleBy] = useState(0);
    //SEA IMPORT
    const [Conversion, setConversion] = useState('Y');
    const [QuotationNo, setQuotationNo] = useState('');

    const [OceanMSTBLNo, setOceanMSTBLNo] = useState('');
    const [VolumeBL, setVolumeBL] = useState(0);
    const [VolumeInvoice, setVolumeInvoice] = useState(0);
    const [SSLineId, setSSLineId] = useState(0);
    const [SSLineName, setSSLineName] = useState('');
    const [EMKLId, setEMKLId] = useState(0);
    const [EMKLName, setEMKLName] = useState('');
    const [DepoId, setDepoId] = useState(0);
    const [DepoName, setDepoName] = useState('');
    //SEA IMPORT
    const [HouseBLNo, setHouseBLNo] = useState('');
    const [SecondBLNo, setSecondBLNo] = useState('');
    const [WareHouseName, setWareHouseName] = useState('');
    const [Kins, setKins] = useState('');
    const [CFName, setCFName] = useState('');

    //DOMESTIC
    const [Truck, setTruck] = useState('');

    ////Variable for load status
    const [LoadStatus, setLoadStatus] = useState('FCL');

    ////Variable for type of service
    const [typeOfServiceId, setTypeOfServiceId] = useState(1);

    ////Variable for container status
    const [ContainerStatus, setContainerStatus] = useState('N');
    const [FreightStatus, setFreightStatus] = useState('N');

    ////Variable for job type
    const [JobTypeId, setJobTypeId] = useState('FCL');

    ////Variable for shipment status
    const [ShipmentStatus, setShipmentStatus] = useState(2);
    const [isMarketing, setIsMarketing] = useState(true);

    ////Variable for marketing section
    const [MarketId, setMarketId] = useState(0);
    const [MarketName, setMarketName] = useState('');
    const [MarketCompanyId, setMarketCompanyId] = useState(0);

    ////Variable for job category
    const [jobCategory, setJobCategory] = useState('Forwarding');

    ////Variable for agent tab
    const [AgentId, setAgentId] = useState(0);
    const [AgentName, setAgentName] = useState('');
    const [AgentAddress, setAgentAddress] = useState('');

    ////Variable for delivery tab
    const [DeliveryId, setDeliveryId] = useState(0);
    const [DeliveryName, setDeliveryName] = useState('');
    const [DeliveryAddress, setDeliveryAddress] = useState('');

    ////Variable for transhipment tab
    const [TranshipmentId, setTranshipmentId] = useState(0);
    const [TranshipmentName, setTranshipmentName] = useState('');
    const [TranshipmentAddress, setTranshipmentAddress] = useState('');

    ////Variable for shipper tab
    const [ShipperId, setShipperId] = useState(0);
    const [ShipperName, setShipperName] = useState('');
    const [ShipperAddress, setShipperAddress] = useState('');
    const [billToShipperId, setBillToShipperId] = useState(0);
    const [billShipperName, setBillShipperName] = useState('');

    ////Variable for credit info shipper to tab
    const [creditTermDays, setCreditTermDays] = useState(0);
    const [creditTermIDR, setCreditTermIDR] = useState(0);
    const [creditTermUSD, setCreditTermUSD] = useState(0);
    const [ApprovedCredit, setApprovedCredit] = useState(false);
    const [outstandingInIDR, setOutstandingInIDR] = useState(0);
    const [outstandingInUSD, setOutstandingInUSD] = useState(0);
    const [freightAmountIDR, setFreightAmountIDR] = useState(0);
    const [freightAmountUSD, setFreightAmountUSD] = useState(0);

    ////Variable for consignee tab
    const [ConsigneeId, setConsigneeId] = useState(0);
    const [ConsigneeName, setConsigneeName] = useState('');
    const [ConsigneeAddress, setConsigneeAddress] = useState('');
    const [billToConsigneeId, setBillToConsigneeId] = useState(0);
    const [billConsigneeName, setBillConsigneeName] = useState('');

    const [TempConsigneeId, setTempConsigneeId] = useState(0);
    const [TempConsigneeName, setTempConsigneeName] = useState('');
    const [TempConsigneeAddress, setTempConsigneeAddress] = useState('');

    ////Variable for notify party tab
    const [NPartyId, setNPartyId] = useState(0);
    const [NPartyName, setNPartyName] = useState('');
    const [NPartyAddress, setNPartyAddress] = useState('');

    ////Variable for booking party tab
    const [BookingPartyId, setBookingPartyId] = useState(0);
    const [BookingPartyName, setBookingPartyName] = useState('');
    const [BookingPartyAddress, setBookingPartyAddress] = useState('');

    ////Variable for vessel & flight tab
    //////Var place of receipt
    const [ReceiptPlaceId, setReceiptPlaceId] = useState(0);
    const [ReceiptPlaceCode, setReceiptPlaceCode] = useState('');
    const [ReceiptPlaceName, setReceiptPlaceName] = useState('');

    //////Var place of delivery
    const [DeliveryPlaceId, setDeliveryPlaceId] = useState(0);
    const [DeliveryPlaceCode, setDeliveryPlaceCode] = useState('');
    const [DeliveryPlaceName, setDeliveryPlaceName] = useState('');

    //SEA
    //////Var port of discharge
    const [DischargePortId, setDischargePortId] = useState(0);
    const [DischargePortCode, setDischargePortCode] = useState('');
    const [DischargePortName, setDischargePortName] = useState('');

    //////Var port of loading
    const [LoadingPortId, setLoadingPortId] = useState(0);
    const [LoadingPortCode, setLoadingPortCode] = useState('');
    const [LoadingPortName, setLoadingPortName] = useState('');

    //AIR
    //////Var departure airport
    const [DepartureAirPortId, setDepartureAirPortId] = useState(0);
    const [DepartureAirPortCode, setDepartureAirPortCode] = useState('');
    const [DepartureAirPortName, setDepartureAirPortName] = useState('');

    /////Var destination airport
    const [DestinationAirPortId, setDestinationAirPortId] = useState(0);
    const [DestinationAirPortCode, setDestinationAirPortCode] = useState('');
    const [DestinationAirportName, setDestinationAirportName] = useState('');

    const [ETA, setETA] = useState('');
    const [ETD, setETD] = useState('');
    const [ATD, setATD] = useState('');
    const [ATA, setATA] = useState('');

    const [vesselData, setVesselData] = useState([]);

    //AIR
    const [flightData, setFlightData] = useState([]);

    //DOMESTIC
    const [VesselFlight, setVesselFlight] = useState('N');
    const [VesselId, setVesselId] = useState(0);
    const [VesselName, setVesselName] = useState('');
    const [Voyage, setVoyage] = useState('');
    const [cityId, setCityId] = useState(0);
    const [PortId, setPortId] = useState(0);
    const [PortCode, setPortCode] = useState('');
    const [PortName, setPortName] = useState('');
    const [FlightId, setFlightId] = useState(0);
    const [FlightCode, setFlightCode] = useState('');
    const [FlightName, setFlightName] = useState('');
    const [FlightNo, setFlightNo] = useState('');

    ////Variable for container tab
    const [data20, setData20] = useState(0);
    const [data40, setData40] = useState(0);
    const [data45, setData45] = useState(0);
    const [dataAll, setDataAll] = useState(0);

    const [containerData, setContainerData] = useState([]);

    ////Variable for freight tab
    //SEA
    //////Var for freight OB/L
    const [freightStatusOBL, setFreightStatusOBL] = useState('P');
    const [freightCollectIdOBL, setFreightCollectIdOBL] = useState(0);
    const [freightCollectCodeOBL, setFreightCollectCodeOBL] = useState('');
    const [freightCollectNameOBL, setFreightCollectNameOBL] = useState('');
    const [payableAgentIdOBL, setPayableAgentIdOBL] = useState(0);
    const [payableAgentNameOBL, setPayableAgentNameOBL] = useState('');
    //SEA IMPORT
    const [OBLCurrency, setOBLCurrency] = useState('USD');
    const [OBLAmount, setOBLAmount] = useState(0);

    //////Var for freight HB/L
    const [freightStatusHBL, setFreightStatusHBL] = useState('P');
    const [freightCollectIdHBL, setFreightCollectIdHBL] = useState(0);
    const [freightCollectCodeHBL, setFreightCollectCodeHBL] = useState('');
    const [freightCollectNameHBL, setFreightCollectNameHBL] = useState('');
    const [payableConsigneeIdHBL, setPayableConsigneeIdHBL] = useState(0);
    const [payableConsigneeNameHBL, setPayableConsigneeNameHBL] = useState('');
    //SEA IMPORT
    const [HBLCurrency, setHBLCurrency] = useState('USD');
    const [HBLAmount, setHBLAmount] = useState(0);

    //AIR
    //////Var for freight MAWB
    const [MAWBStatus, setMAWBStatus] = useState('P');
    const [MAWBCollectId, setMAWBCollectId] = useState(0);
    const [MAWBCollectCode, setMAWBCollectCode] = useState('');
    const [MAWBCollectName, setMAWBCollectName] = useState('');
    const [payableAgentIdMAWB, setPayableAgentIdMAWB] = useState(0);
    const [payableAgentNameMAWB, setPayableAgentNameMAWB] = useState('');
    //AIR IMPORT
    const [MAWBCurrency, setMAWBCurrency] = useState('USD');
    const [MAWBRate, setMAWBRate] = useState(0);

    //////Var for freight HAWB
    const [HAWBStatus, setHAWBStatus] = useState('P');
    const [HAWBCollectId, setHAWBCollectId] = useState(0);
    const [HAWBCollectCode, setHAWBCollectCode] = useState('');
    const [HAWBCollectName, setHAWBCollectName] = useState('');
    const [payableConsigneeIdHAWB, setPayableConsigneeIdHAWB] = useState(0);
    const [payableConsigneeNameHAWB, setPayableConsigneeNameHAWB] = useState('');
    //AIR IMPORT
    const [HAWBCurrency, setHAWBCurrency] = useState('USD');
    const [HAWBRate, setHAWBRate] = useState(0);

    const [currency, setCurrency] = useState('USD');
    const [handlingInf, setHandlingInf] = useState('PLEASE CONTACT CONSIGNEE IMMEDIATELY UPON ARRIVAL');

    ////Variable for description tab
    //SEA
    const [MarkNumberDescription, setMarkNumberDescription] = useState(' ');
    const [NumberTypeDescription, setNumberTypeDescription] = useState(' ');
    const [GoodDescription, setGoodDescription] = useState(' ');
    const [GrossWeightDescription, setGrossWeightDescription] = useState(' ');
    const [MeasurementDescription, setMeasurementDescription] = useState(' ');

    const [TempMarkNumberDescription, setTempMarkNumberDescription] = useState(' ');
    const [TempNumberTypeDescription, setTempNumberTypeDescription] = useState(' ');
    const [TempGoodDescription, setTempGoodDescription] = useState(' ');
    const [TempGrossWeightDescription, setTempGrossWeightDescription] = useState(' ');
    const [TempMeasurementDescription, setTempMeasurementDescription] = useState(' ');

    //AIR
    const [PiecesRCP, setPiecesRCP] = useState('');
    const [GrossWeight, setGrossWeight] = useState(0);
    const [KGLB, setKGLB] = useState('KG');
    const [ChargeWeight, setChargeWeight] = useState(0);
    const [ChargeRate, setChargeRate] = useState(0);
    const [Total, setTotal] = useState('AS ARRANGED');
    const [Commodity, setCommodity] = useState('');
    const [PackagingCode, setPackagingCode] = useState('GPL');
    const [GoodNatureQuantity, setGoodNatureQuantity] = useState('');
    const [Shipmark, setShipmark] = useState('');

    ////Variable for master airway bill
    const [MAWBNo, setMAWBNo] = useState('000 0000 0000');
    const [ChargeableWeight, setChargeableWeight] = useState(0);
    const [WeightHAWB, setWeightHAWB] = useState(0);
    const [CarriageValue, setCarriageValue] = useState('AS PER INV');
    const [CustomValue, setCustomValue] = useState('N C V');
    const [IATAId, setIATAId] = useState(0);
    const [IATAName, setIATAName] = useState('');
    const [BrokerId, setBrokerId] = useState(0);
    const [BrokerName, setBrokerName] = useState('');
    const [airLineName, setAirLineName] = useState('');
    //AIR IMPORT
    const [HAWBNo, setHAWBNo] = useState('');

    ////Variable for con note
    const [ConNoteData, setConNoteData] = useState([]);

    const [isWithEPL, setIsWithEPL] = useState(false);
    const [rateReal, setRateReal] = useState(0)
    const [exRateDate, setExRateDate] = useState('')
    const [exRateId, setExRateId] = useState(0)

    //Variable for bill of lading tab
    const [BLNumber, setBLNumber] = useState('');
    const [placeDateIssue, setPlaceDateIssue] = useState('');
    const [noBLData, setNoBLData] = useState('T');
    const [BLFormId, setBLFormId] = useState(0);
    const [BLFormName, setBLFormName] = useState('');

    const [shipmentOnBoard, setShipmentOnBoard] = useState('');
    const [totalNumberCont, setTotalNumberCont] = useState('');
    const [cargoInsurance, setCargoInsurance] = useState('Not Covered');
    const [freightAmount, setFreightAmount] = useState('AS AGREED');
    const [freightPayable, setFreightPayable] = useState('DESTINATION');
    const [descriptionBoL, setDescriptionBoL] = useState('');

    const [BLAgentId, setBLAgentId] = useState(0);
    const [BLAgentName, setBLAgentName] = useState('');
    const [BLAgentAddress, setBLAgentAddress] = useState('');

    const [BLShipperId, setBLShipperId] = useState(0);
    const [BLShipperName, setBLShipperName] = useState('');
    const [BLShipperAddress, setBLShipperAddress] = useState('');

    const [BLConsigneeId, setBLConsigneeId] = useState(0);
    const [BLConsigneeName, setBLConsigneeName] = useState('');
    const [BLConsigneeAddress, setBLConsigneeAddress] = useState('');

    const [BLNPartyId, setBLNPartyId] = useState(0);
    const [BLNPartyName, setBLNPartyName] = useState('');
    const [BLNPartyAddress, setBLNPartyAddress] = useState('');

    const [BLSNPartyId, setBLSNPartyId] = useState(0);
    const [BLSNPartyName, setBLSNPartyName] = useState('');
    const [BLSNPartyAddress, setBLSNPartyAddress] = useState('');

    const [BLReceiptPlaceName, setBLReceiptPlaceName] = useState('');
    const [BLDischargePortName, setBLDischargePortName] = useState('');
    const [BLLoadingPortName, setBLLoadingPortName] = useState('');
    const [BLDeliveryPlaceName, setBLDeliveryPlaceName] = useState('');

    //AIR
    const [HAWBFee, setHAWBFee] = useState(0);
    const [AmountInsurance, setAmountInsurance] = useState('');

    ////Var for print status
    const [printDraft, setPrintDraft] = useState(0);
    const [printDraftDate, setPrintDraftDate] = useState('');
    const [printFixed, setPrintFixed] = useState(0);
    const [printFixedDate, setPrintFixedDate] = useState('');

    const [DeletedBoL, setDeletedBoL] = useState(false);
    const [DeletedOnBoL, setDeletedOnBoL] = useState(defaultDate);

    //Variable for shipping instruction
    const [Reference, setReference] = useState('');
    const [Attention, setAttention] = useState('');

    const [SIUpdatedCount, setSIUpdatedCount] = useState(0);
    const [lastUpdated, setLastUpdated] = useState('');
    const [PrintingUpdatedCount, setPrintingUpdatedCount] = useState(0);
    const [datePrint, setDatePrint] = useState('');
    const [DeletedSI, setDeletedSI] = useState(false);
    const [DeletedOnSI, setDeletedOnSI] = useState(defaultDate);

    //DATA CONTACT
    const [SICompanyId, setSICompanyId] = useState(0);
    const [SICompanyName, setSICompanyName] = useState('');
    const [SICompanyAddress, setSICompanyAddress] = useState('');

    const [SIAgentId, setSIAgentId] = useState(0);
    const [SIAgentName, setSIAgentName] = useState('');
    const [SIAgentAddress, setSIAgentAddress] = useState('');

    const [SIConsigneeId, setSIConsigneeId] = useState(0);
    const [SIConsigneeName, setSIConsigneeName] = useState('');
    const [SIConsigneeAddress, setSIConsigneeAddress] = useState('');

    const [SINPartyId, setSINPartyId] = useState(0);
    const [SINPartyName, setSINPartyName] = useState('');
    const [SINPartyAddress, setSINPartyAddress] = useState('');

    //SEA EXPORT
    const [ShippingMarksDesc, setShippingMarksDesc] = useState(' ');
    const [NoPackageDesc, setNoPackageDesc] = useState(' ');
    const [SIGoodDesc, setSIGoodDesc] = useState(' ');
    const [WeightMeaDesc, setWeightMeaDesc] = useState(' ');

    //AIR EXPORT
    const [SIPiecesRCP, setSIPiecesRCP] = useState('');
    const [SIGrossWeight, setSIGrossWeight] = useState(0);
    const [SIKGLB, setSIKGLB] = useState('KG');
    const [SIChargeWeight, setSIChargeWeight] = useState(0);
    const [SIChargeRate, setSIChargeRate] = useState(0);
    const [SITotal, setSITotal] = useState('AS ARRANGED');
    const [SIGoodNatureQ, setSIGoodNatureQ] = useState('');
    const [SICommodity, setSICommodity] = useState('');
    const [SIPackageCode, setSIPackageCode] = useState('GPL');
    const [SIShipmark, setSIShipmark] = useState('');

    const [SIOriginalBL, setSIOriginalBL] = useState('T');
    const [SISpecialIns, setSISpecialIns] = useState('PLEASE FAX US D/O ASAP');

    const [freightOBL, setFreightOBL] = useState('P');
    const [SICollectAgentId, setSICollectAgentId] = useState(0);
    const [SICollectAgentName, setSICollectAgentName] = useState('');
    const [SICollectAgentAddress, setSICollectAgentAddress] = useState('');

    //Variable for shipment advice tab
    const [SAReference, setSAReference] = useState('');
    const [SARemarks, setSARemarks] = useState('');
    const [SAPrintAgentId, setSAPrintAgentId] = useState(0);
    const [SAPrintAgentName, setSAPrintAgentName] = useState('');
    const [SAPrintDeliveryId, setSAPrintDeliveryId] = useState(0);
    const [SAPrintDeliveryName, setSAPrintDeliveryName] = useState('');

    const [PrintToAgentOnSA, setPrintToAgentOnSA] = useState('');
    const [PrintToDeliveryOnSA, setPrintToDeliveryOnSA] = useState('');
    const [DeletedSA, setDeletedSA] = useState(false);
    const [DeletedOnSA, setDeletedOnSA] = useState(defaultDate);

    //Variable for telex release tab
    const [TRFullSetStatus, setTRFullSetStatus] = useState('O');
    const [TRReleaseStatus, setTRReleaseStatus] = useState('T');
    const [TRPrintCount, setTRPrintCount] = useState(0);
    const [TRPrintDate, setTRPrintDate] = useState('');

    const [DeletedTR, setDeletedTR] = useState(false);
    const [DeletedOnTR, setDeletedOnTR] = useState(defaultDate);

    //Variable for tracking tab
    const [dataTracking, setDataTracking] = useState([]);

    const history = useNavigate();

    const [isModal, setIsModal] = useState(false);
    const [titleModal, setTitleModal] = useState('');
    const [IntendVessel, setIntendVessel] = useState('');
    const [DOETD, setDOETD] = useState('');
    const [ToDO, setToDO] = useState('');
    const [AttentionDO, setAttentionDO] = useState('');
    const [QuantityDO, setQuantityDO] = useState('');
    const [ContainerDO, setContainerDO] = useState('');
    const [SealDO, setSealDO] = useState('');
    const [CommodityDO, setCommodityDo] = useState('');
    const [RemarksDO, setRemarksDO] = useState('HARAP BERIKAN CONTAINER YANG AV');
    const [OperatorDO, setOperatorDO] = useState('');
    const [GudangName, setGudangName] = useState('');
    const [isNew, setIsNew] = useState(true);

    const [isModal2, setIsModal2] = useState(false);
    const [titleModal2, setTitleModal2] = useState('');
    const [DocumentType, setDocumentType] = useState('1');
    const [carrier, setCarrier] = useState('0');
    const [bolIssue, setBolIssue] = useState('0');
    const [changeDes, setChangeDes] = useState('0');
    const [desCollect, setDesCollect] = useState('0');

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const [isEdit, setIsEdit] = useState(false);

    const [CopyContainer, setCopyContainer] = useState('');

    const [isRemarks, setIsRemarks] = useState(false);
    const [documentStatus, setDocumentStatus] = useState('');

    const [period, setPeriod] = useState(0);
    const [yearPeriod, setYearPeriod] = useState(0);
    const [isClosed, setIsClosed] = useState(false);

    const [TempLoadingPortName, setTempLoadingPortName] = useState('');
    const [TempReceiptPlaceName, setTempReceiptPlaceName] = useState('');
    const [TempDischargePortName, setTempDischargePortName] = useState('');
    const [TempDeliveryPlaceName, setTempDeliveryPlaceName] = useState('');

    const BreadcrumbsItems = [
        { label: 'Infoss', link: '/Infoss', style: '' },
        { label: 'Shipment Order', link: '/Infoss/ShipmentOrder', style: '' },
        { label: 'Create Shipment Order', link: '/Infoss/ShipmentOrder/Create/' + sJobTypeId, style: { color: '#0087C2', fontWeight: '700' } },
    ];

    const BreadcrumbsEditItems = [
        { label: 'Infoss', link: '/Infoss', style: '' },
        { label: 'Shipment Order', link: '/Infoss/ShipmentOrder', style: '' },
        { label: 'Detail Shipment Order', link: '/Infoss/ShipmentOrder/' + sJobTypeId + '/' + shipmentId, style: { color: '#0087C2', fontWeight: '700' } },
    ];

    const simpanLocal = () => {
        let id = 0;

        let mktId = 0;
        let mktName = '';
        let cmpyId = 0;

        let BillToSId = 0;
        let BillToSN = '';
        let BillToCId = 0;
        let BillToCN = '';

        let containerSt = '';
        let freightSt = '';

        let atd = '';
        let ata = '';

        let fCIOBL = 0;
        let fCCOBL = '';
        let fCNOBL = '';
        let pAIOBL = 0;
        let pANOBL = '';

        let fCIHBL = 0;
        let fCCHBL = '';
        let fCNHBL = '';
        let pCIHBL = 0;
        let pCNHBL = '';

        let fCIMAWB = 0;
        let fCCMAWB = '';
        let fCNMAWB = '';
        let pAIMAWB = 0;
        let pANMAWB = '';

        let fCIHAWB = 0;
        let fCCHAWB = '';
        let fCNHAWB = '';
        let pCIHAWB = 0;
        let pCNHAWB = '';

        let pODId = 0;
        let poDC = '';
        let poDN = '';

        let poLId = 0;
        let poLC = '';
        let pOLN = '';

        let aPoDId = 0;
        let aPoDC = '';
        let aPoDN = '';

        let aPoDsId = 0;
        let aPoDsC = '';
        let aPoDsN = '';

        let cAAId = 0;
        let cAAName = '';
        let cAAAddress = '';

        let mawbNoSet = '';
        let hawbNoSet = '';

        let printFxBoL = '';
        let printDfBoL = '';

        let printSI = '';

        let printAgent = '';
        let printDel = '';

        let printTR = '';

        let deletedOnTR = '';
        let deletedOnSA = '';
        let deletedOnSI = '';
        let deletedOnBoL = '';
        let approvedCreditOn = '';
        let deletedOn = '';

        let consigneeId = 0;

        let blAgentId = 0;
        let blShipperId = 0;
        let blConsigneeId = 0;
        let blNPartyId = 0;

        let siConsignneId = 0;
        let siNPartyId = 0;

        let routingData = [];
        let trackingData = [];

        if (shipmentId) {
            id = parseInt(shipmentId);
        }

        if (jobType.serviceType === 'Export') {
            BillToSId = billToShipperId;
            BillToSN = billShipperName;
        }
        else if (jobType.serviceType === 'Import') {
            BillToCId = billToConsigneeId;
            BillToCN = billConsigneeName;
        }

        if (ATD === '') {
            atd = defaultDate;
        }
        else {
            atd = ATD;
        }

        if (ATA === '') {
            ata = defaultDate;
        }
        else {
            ata = ATA;
        }

        if (printDraftDate === '') {
            printDfBoL = defaultDate;
        }
        else {
            printDfBoL = printDraftDate;
        }

        if (printFixedDate === '') {
            printFxBoL = defaultDate;
        }
        else {
            printFxBoL = printFixedDate;
        }

        if (datePrint === '' || datePrint === 0) {
            printSI = defaultDate;
        }
        else {
            printSI = datePrint;
        }

        if (PrintToAgentOnSA === '') {
            printAgent = defaultDate;
        }
        else {
            printAgent = PrintToAgentOnSA;
        }

        if (PrintToDeliveryOnSA === '') {
            printDel = defaultDate;
        }
        else {
            printDel = PrintToDeliveryOnSA;
        }

        if (TRPrintDate === '') {
            printTR = defaultDate;
        }
        else {
            printTR = TRPrintDate;
        }

        if (DeletedOnTR === '') {
            deletedOnTR = '';
        }
        else {
            deletedOnTR = defaultDate;
        }

        if (DeletedOnSA === '') {
            deletedOnSA = '';
        }
        else {
            deletedOnSA = defaultDate;
        }

        if (DeletedOnSI === '') {
            deletedOnSI = '';
        }
        else {
            deletedOnSI = defaultDate;
        }

        if (DeletedOnBoL === '') {
            deletedOnBoL = '';
        }
        else {
            deletedOnBoL = defaultDate;
        }

        if (ApprovedCreditOn === '') {
            approvedCreditOn = '';
        }
        else {
            approvedCreditOn = defaultDate;
        }

        if (DeletedOn === '') {
            deletedOn = '';
        }
        else {
            deletedOn = defaultDate;
        }

        if (jobType.serviceType === 'Export') {
            if (ConsigneeName === TempConsigneeName && ConsigneeAddress === TempConsigneeAddress) {
                consigneeId = ConsigneeId;
            }
            else if (ConsigneeName !== TempConsigneeName || ConsigneeAddress !== TempConsigneeAddress) {
                consigneeId = 0;
            }

            //BL Form
            if (BLAgentName === AgentName && BLAgentAddress === AgentAddress) {
                blAgentId = AgentId;
            }
            else if (BLAgentName !== AgentName || BLAgentAddress !== AgentAddress) {
                blAgentId = 0;
            }

            if (BLShipperName === ShipperName && BLShipperAddress === ShipperAddress) {
                blShipperId = ShipperId;
            }
            else if (BLShipperName !== ShipperName || BLShipperAddress !== ShipperAddress) {
                blShipperId = 0;
            }

            if (BLConsigneeName === ConsigneeName && BLConsigneeAddress === ConsigneeAddress && consigneeId !== 0) {
                blConsigneeId = consigneeId;
            }
            else if (BLConsigneeName !== ConsigneeName || BLConsigneeAddress !== ConsigneeAddress || consigneeId === 0) {
                blConsigneeId = 0;
            }

            if (BLNPartyName === NPartyName && BLNPartyAddress === NPartyAddress) {
                blNPartyId = NPartyId;
            }
            else if (BLNPartyName !== NPartyName || BLNPartyAddress !== NPartyAddress) {
                blNPartyId = 0;
            }

            //SI Form
            if (SIConsigneeName === AgentName && SIConsigneeAddress === AgentAddress) {
                siConsignneId = AgentId;
            }
            else if (SIConsigneeName !== AgentName || SIConsigneeAddress !== AgentAddress) {
                siConsignneId = 0;
            }

            if (SINPartyName === AgentName && SINPartyAddress === AgentAddress) {
                siNPartyId = AgentId;
            }
            else if (SINPartyName !== AgentName || SINPartyAddress !== AgentAddress) {
                siNPartyId = 0;
            }
        }
        else {
            consigneeId = ConsigneeId;
        }

        if (jobType.portType !== 'AIR') {
            containerSt = ContainerStatus;
        }
        else {
            freightSt = FreightStatus;
        }

        if (freightStatusOBL === 'C') {
            fCIOBL = freightCollectIdOBL;
            fCCOBL = freightCollectCodeOBL;
            fCNOBL = freightCollectNameOBL;
            pAIOBL = payableAgentIdOBL;
            pANOBL = payableAgentNameOBL;
        }

        if (freightStatusHBL === 'C') {
            fCIHBL = freightCollectIdHBL;
            fCCHBL = freightCollectCodeHBL;
            fCNHBL = freightCollectNameHBL;
            pCIHBL = payableConsigneeIdHBL;
            pCNHBL = payableConsigneeNameHBL;
        }

        if (MAWBStatus === 'C') {
            fCIMAWB = MAWBCollectId;
            fCCMAWB = MAWBCollectCode;
            fCNMAWB = MAWBCollectName;
            pAIMAWB = payableAgentIdMAWB;
            pANMAWB = payableAgentNameMAWB;
        }

        if (HAWBStatus === 'C') {
            fCIHAWB = HAWBCollectId;
            fCCHAWB = HAWBCollectCode;
            fCNHAWB = HAWBCollectName;
            pCIHAWB = payableConsigneeIdHAWB;
            pCNHAWB = payableConsigneeNameHAWB;
        }

        if (parseInt(ShipmentStatus) === 1) {
            mktId = MarketId;
            mktName = MarketName;
            cmpyId = MarketCompanyId;
        }

        if (freightOBL === 'A') {
            cAAId = SICollectAgentId;
            cAAName = SICollectAgentName;
            cAAAddress = SICollectAgentAddress;
        }

        if (jobType.portType === 'SEA') {
            pODId = DischargePortId;
            poDC = DischargePortCode;
            poDN = DischargePortName;

            poLId = LoadingPortId;
            poLC = LoadingPortCode;
            pOLN = LoadingPortName;
        }
        else if (jobType.portType === 'AIR') {
            aPoDId = DepartureAirPortId;
            aPoDC = DepartureAirPortCode;
            aPoDN = DepartureAirPortName;

            aPoDsId = DestinationAirPortId;
            aPoDsC = DestinationAirPortCode;
            aPoDsN = DestinationAirportName;
        }

        if (jobType.portType === 'AIR') {
            hawbNoSet = HAWBNo;
            mawbNoSet = MAWBNo;
        }

        if (jobType.portType === 'SEA') {
            routingData = vesselData;
            trackingData = dataTracking;
        }
        else if (jobType.portType === 'AIR') {
            routingData = flightData;
            trackingData = dataTracking;
        }
        else {
            let vesId = 0;
            let vesN = '';
            let voy = '';
            let fliId = 0;
            let fliC = '';
            let fliNa = '';
            let fliNo = '';

            if (VesselFlight !== 'N') {
                if (VesselFlight === 'V') {
                    vesId = VesselId;
                    vesN = VesselName;
                    voy = Voyage;
                }
                else if (VesselFlight === 'F') {
                    fliId = FlightId;
                    fliC = FlightCode;
                    fliNa = FlightName;
                    fliNo = FlightNo;
                }

                let data = {
                    "countryId": countryId,
                    "companyId": companyId,
                    "branchId": branchId,
                    "user": userCode,

                    "rowStatus": RowStatus,
                    "shipmentId": ShipmentId,
                    "sequence": 1,
                    "vesselId": vesId,
                    "vesselName": vesN,
                    "vesselType": '',
                    "voyage": voy,
                    "cityId": cityId,
                    "cityCode": '',
                    "cityName": '',
                    "portId": PortId,
                    "portCode": PortCode,
                    "portName": PortName,
                    "flightId": fliId,
                    "flightCode": fliC,
                    "flightName": fliNa,
                    "flightNo": fliNo,
                    "airportFromId": 0,
                    "airPortFromCode": "",
                    "airPortFromName": "",
                    "airportToId": 0,
                    "airPortToCode": "",
                    "airPortToName": "",
                    "cityFromId": 0,
                    "cityFromCode": "",
                    "cityFromName": "",
                    "cityToId": 0,
                    "cityToCode": "",
                    "cityToName": "",
                    "etd": defaultDate,
                    "idLama": 0,
                };

                routingData = [data];

                let check = 0;

                for (let i = 0; i < dataTracking.length; i++) {
                    if ((VesselFlight === 'F' && dataTracking[i].vessel !== '')
                        || (VesselFlight === 'V' && dataTracking[i].flight !== '')) {
                        let tempSequence = dataTracking[i].sequence;

                        const deleteFunction = (dataCost) => {
                            return dataCost.sequence !== tempSequence;
                        }

                        const result = dataTracking.filter(deleteFunction);
                        console.log(result);

                        if (result.length > 0) {
                            tempSequence = 0;

                            result.forEach((el) => {
                                tempSequence += 1;
                                el.sequence = tempSequence;
                            })
                            trackingData = result;
                        }

                        check = 1;
                    }
                }

                if (check === 0) {
                    trackingData = dataTracking;
                }
            }
        }

        var data = {
            "shipmentOrder":
            {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "shipmentNo": ShipmentNo,
                "rowStatus": RowStatus,
                "id": id,
                "ticketId": TicketId,
                "jobNumber": JobNumber,
                "subJobNo": SubJobNo,
                "jobTypeId": parseInt(IdJobType),
                "jobTypeCode": JobTypeCode,

                "jobOwnerId": principleBy,
                "totalSub": TotalSub,
                "siReference": SIReference,
                "siDate": SIDate,
                "loadStatus": LoadStatus,
                "shipmentStatusId": parseInt(ShipmentStatus),
                "serviceNoId": parseInt(typeOfServiceId),
                "marketId": mktId,
                "marketName": mktName,
                "marketCompanyId": cmpyId,
                "jobStatus": JobTypeId,
                "goodsRecDate": GoodsRecDate,
                "bookingConfirmationId": BookingConfirmationId,
                "bookingNo": BookingConfirmationNumber,
                "shipmentCategory": jobCategory,

                "oceanMSTBLNo": OceanMSTBLNo,
                "volumeBL": parseFloat(VolumeBL),
                "volumeInvoice": parseFloat(VolumeInvoice),

                "emklId": EMKLId,
                "emklName": EMKLName,
                "ssLineId": SSLineId,
                "ssLineName": SSLineName,
                "depoId": DepoId,
                "depoName": DepoName,

                "vesselFlight": VesselFlight,
                "truck": Truck,

                //SEA
                "containerStatus": containerSt,
                //AIR
                "freightStatus": freightSt,

                //SEA IMPORT
                "conversion": Conversion,
                "quotationNo": QuotationNo,

                //SEA IMPORT
                "houseBLNo": HouseBLNo,
                "secondBLNo": SecondBLNo,
                "wareHouseName": WareHouseName,
                "kins": Kins,
                "cfName": CFName,

                //Agent Data
                "agentId": AgentId,
                "agentName": AgentName,
                "agentAddress": AgentAddress,

                //Delivery Data
                "deliveryId": DeliveryId,
                "deliveryName": DeliveryName,
                "deliveryAddress": DeliveryAddress,

                //Transhipment Data
                "transhipmentId": TranshipmentId,
                "transhipmentName": TranshipmentName,
                "transhipmentAddress": TranshipmentAddress,

                //Shipper Data
                "shipperId": ShipperId,
                "shipperName": ShipperName,
                "shipperAddress": ShipperAddress,

                //EXPORT
                "billToShipperId": BillToSId,
                "billToShipperName": BillToSN,

                //Consignee Data
                "consigneeId": consigneeId,
                "consigneeName": ConsigneeName,
                "consigneeAddress": ConsigneeAddress,

                "tempConsigneeId": TempConsigneeId,
                "tempConsigneeName": TempConsigneeName,
                "tempConsigneeAddress": TempConsigneeAddress,

                //IMPORT
                "billToConsigneeId": BillToCId,
                "billToConsigneeName": BillToCN,

                "freightAmountInIDR": convertToNumeric(freightAmountIDR),
                "freightAmountInUSD": convertToNumeric(freightAmountUSD),
                "approvedCredit": ApprovedCredit,

                //NParty Data
                "nPartyId": NPartyId,
                "nPartyName": NPartyName,
                "nPartyAddress": NPartyAddress,

                //Booking Party Data
                "bookingPartyId": BookingPartyId,
                "bookingPartyName": BookingPartyName,
                "bookingPartyAddress": BookingPartyAddress,

                //Vessel Tab Data
                "receiptPlaceId": ReceiptPlaceId,
                "receiptPlaceCode": ReceiptPlaceCode,
                "receiptPlaceName": ReceiptPlaceName,

                "deliveryPlaceId": DeliveryPlaceId,
                "deliveryPlaceCode": DeliveryPlaceCode,
                "deliveryPlaceName": DeliveryPlaceName,

                "etd": ETD,
                "eta": ETA,
                "atd": atd,
                "ata": ata,

                //SEA
                "loadingPortId": poLId,
                "loadingPortCode": poLC,
                "loadingPortName": pOLN,

                "dischargePortId": pODId,
                "dischargePortCode": poDC,
                "dischargePortName": poDN,

                //AIR
                "departureAirPortId": aPoDId,
                "departureAirPortCode": aPoDC,
                "departureAirPortName": aPoDN,

                "destinationAirPortId": aPoDsId,
                "destinationAirPortCode": aPoDsC,
                "destinationAirportName": aPoDsN,

                //Freight Tab Data
                //SEA
                "oblStatus": freightStatusOBL,
                "oblCollectId": fCIOBL,
                "oblCollectCode": fCCOBL,
                "oblCollectName": fCNOBL,
                "oblPayableId": pAIOBL,
                "oblPayableName": pANOBL,

                "hblStatus": freightStatusHBL,
                "hblCollectId": fCIHBL,
                "hblCollectCode": fCCHBL,
                "hblCollectName": fCNHBL,
                "hblPayableId": pCIHBL,
                "hblPayableName": pCNHBL,

                //SEA IMPORT
                "oblCurrency": OBLCurrency,
                "oblAmount": OBLAmount,

                "hblCurrency": HBLCurrency,
                "hblAmount": HBLAmount,

                //AIR
                "mawbStatus": MAWBStatus,
                "mawbCollectId": fCIMAWB,
                "mawbCollectCode": fCCMAWB,
                "mawbCollectName": fCNMAWB,
                "mawbPayableId": pAIMAWB,
                "mawbPayableName": pANMAWB,

                "hawbStatus": HAWBStatus,
                "hawbCollectId": fCIHAWB,
                "hawbCollectCode": fCCHAWB,
                "hawbCollectName": fCNHAWB,
                "hawbPayableId": pCIHAWB,
                "hawbPayableName": pCNHAWB,

                //AIR IMPORT
                "mawbRate": parseInt(MAWBRate),
                "mawbCurrency": MAWBCurrency,

                "hawbRate": parseInt(HAWBRate),
                "hawbCurrency": HAWBCurrency,

                "currency": currency,
                "handlingInfo": handlingInf,

                //Description Tab Data
                //SEA
                "goodDescription": GoodDescription,
                "markNumberDescription": MarkNumberDescription,
                "numberTypeDescription": NumberTypeDescription,
                "descriptionOfGoods": GoodDescription,
                "grossWeightDescription": GrossWeightDescription,
                "measurementDescription": MeasurementDescription,

                //AIR
                "piecesRCP": PiecesRCP,
                "grossWeight": GrossWeight,
                "kglb": KGLB,
                "chargeWeight": ChargeWeight,
                "chargeRate": ChargeRate,
                "total": Total,
                "commodity": Commodity,
                "packagingCode": PackagingCode,
                "goodNatureQuantity": GoodNatureQuantity,
                "shipmark": Shipmark,

                //Master Airway Bill Tab Data
                "mawbNo": mawbNoSet,
                "chargeableWeight": parseInt(ChargeableWeight),
                "weightHAWB": parseInt(WeightHAWB),
                "carriageValue": CarriageValue,
                "customValue": CustomValue,
                "iataId": IATAId,
                "iataName": IATAName,
                "brokerId": BrokerId,
                "brokerName": BrokerName,

                //AIR IMPORT
                "hawbNo": hawbNoSet,

                "jobClosed": JobClosed,
                "jobClosedOn": JobClosedOn,
                "deleted": Deleted,
                "deletedOn": deletedOn,
                "approvedCreditBy": ApprovedCreditBy,
                "approvedCreditRemarks": ApprovedCreditRemarks,
                "approvedCreditOn": approvedCreditOn,
                "siCustomerId": 0,
                "requester": Requester,

                "idLama": 0,
            },

            "shipmentOrderRoutings": routingData,

            "shipmentOrderSeaContainers": containerData,

            "shipmentOrderBillOfLading":
            {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "rowStatus": RowStatus,
                "shipmentId": id,

                "blNumber": BLNumber,
                "masterBLId": BLFormId,
                "masterBLName": BLFormName,
                "noOfBL": noBLData,
                "placeDateOfIssue": placeDateIssue,

                "agentId": blAgentId,
                "agentName": BLAgentName,
                "agentAddress": BLAgentAddress,

                "shipperId": blShipperId,
                "shipperName": BLShipperName,
                "shipperAddress": BLShipperAddress,

                "consigneeId": blConsigneeId,
                "consigneeName": BLConsigneeName,
                "consigneeAddress": BLConsigneeAddress,

                "nPartyId": blNPartyId,
                "nPartyName": BLNPartyName,
                "nPartyAddress": BLNPartyAddress,

                "snPartyId": BLSNPartyId,
                "snPartyName": BLSNPartyName,
                "snPartyAddress": BLSNPartyAddress,

                "receiptPlaceName": BLReceiptPlaceName,
                "dischargePortName": BLDischargePortName,
                "loadingPortName": BLLoadingPortName,
                "deliveryPlaceName": BLDeliveryPlaceName,

                "shipmentOnBoard": shipmentOnBoard,
                "totalNoOfContainer": totalNumberCont,

                "hawbFee": HAWBFee,
                "amountInsurance": AmountInsurance,

                "cargoInsurance": cargoInsurance,
                "freightAmount": freightAmount,
                "freightPayableAt": freightPayable,
                "descriptions": descriptionBoL,
                "printDraft": printDraft,
                "printFixed": printFixed,
                "printFixedOn": printFxBoL,
                "firstPrintFixedOn": printDfBoL,
                "deleted": DeletedBoL,
                "deletedOn": deletedOnBoL,

                "idLama": 0,
            },

            "shipmentOrderInstruction":
            {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "rowStatus": RowStatus,
                "shipmentId": id,

                "siReference": Reference,
                "attention": Attention,

                "shipperId": SICompanyId,
                "shipperName": SICompanyName,
                "shipperAddress": SICompanyAddress,

                "consigneeId": siConsignneId,
                "consigneeName": SIConsigneeName,
                "consigneeAddress": SIConsigneeAddress,

                "nPartyId": siNPartyId,
                "nPartyName": SINPartyName,
                "nPartyAddress": SINPartyAddress,

                "goodDescription": SIGoodDesc,
                "shippingMarksDesc": ShippingMarksDesc,
                "noPackageDesc": NoPackageDesc,
                "descriptionOfGoods": SIGoodDesc,
                "weightMeaDesc": WeightMeaDesc,

                "piecesRCP": SIPiecesRCP,
                "grossWeight2": SIGrossWeight,
                "kglb": SIKGLB,
                "chargeWeight": SIChargeWeight,
                "chargeRate": SIChargeRate,
                "total": SITotal,
                "goodNatureQuantity": SIGoodNatureQ,
                "commodity": SICommodity,
                "packagingCode": SIPackageCode,
                "shippingMark": SIShipmark,

                "specialInstruction": SISpecialIns,
                "originalBL": SIOriginalBL,
                "freightAgreed": freightOBL,
                "collectAt": cAAId,
                "collectName": cAAName,
                "collectAddress": cAAAddress,

                "updated": SIUpdatedCount,
                "printing": PrintingUpdatedCount,
                "printedOn": printSI,
                "deleted": DeletedSI,
                "deletedOn": deletedOnSI,

                "idLama": 0,
            },

            "shipmentOrderAdvice":
            {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "rowStatus": RowStatus,
                "shipmentId": id,

                "reference": SAReference,
                "remarks": SARemarks,

                "printToAgentId": SAPrintAgentId,
                "printToAgentName": SAPrintAgentName,

                "printToDeliveryId": SAPrintDeliveryId,
                "printToDeliveryName": SAPrintDeliveryName,

                "printToAgentOn": printAgent,
                "printToDeliveryOn": printDel,
                "deleted": DeletedSA,
                "deletedOn": deletedOnSA,

                "idLama": 0,
            },

            "shipmentOrderTelexRelease":
            {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "rowStatus": RowStatus,
                "shipmentId": id,

                "original": TRFullSetStatus,
                "seaWaybill": TRReleaseStatus,
                "printing": TRPrintCount,
                "printedOn": printTR,
                "deleted": DeletedTR,
                "deletedOn": deletedOnTR,

                "idLama": 0,
            },

            "shipmentOrderTrackings": trackingData,

            "shipmentOrderConNotes": ConNoteData,
        }

        localStorage.setItem(username + "-DataSO", JSON.stringify(data));
    }

    useImperativeHandle(ref, () => ({
        localSave() {
            simpanLocal();
        }
    }));

    useEffect(() => {
        document.title = 'Shipment Order - Detail - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDataLocal = () => {
        if (shipmentId) {
            setIsEdit(true);
        }

        let shipId = 0;
        let consId = 0;
        let billSId = 0;
        let billCId = 0;
        let dataSO = dataLocal.shipmentOrder;

        setJobClose(dataSO.jobClosed);
        setTypeJobId(dataSO.jobTypeId);
        handleDocumentStatus(dataSO.rowStatus);
        if (dataSO.jobClosed === true) {
            setIsJobClose(true);
        }
        else {
            setIsJobClose(false);
        }
        //Variable for shipment order
        setJobTypeCode(dataSO.jobTypeCode);

        setRowStatus(dataSO.rowStatus);
        setTicketId(dataSO.ticketId);
        setSubJobNo(dataSO.subJobNo);
        setJobClosed(dataSO.jobClosed);
        setDeleted(dataSO.deleted);
        setApprovedCreditBy(dataSO.approvedCreditBy);
        setApprovedCreditRemarks(dataSO.approvedCreditRemarks);
        setRequester(dataSO.requester);
        setJobNumber(dataSO.jobNumber);

        setDateFormat(dataSO.jobClosedOn, setJobClosedOn);
        setDateFormat(dataSO.deletedOn, setDeletedOn);
        setDateFormat(dataSO.approvedCreditOn, setApprovedCreditOn);

        setShipmentId(dataSO.id);
        setShipmentNo(dataSO.shipmentNo);
        setTotalSub(dataSO.totalSub);
        setSIReference(dataSO.siReference);

        setSIDate(getDefaultDateFormat(dataSO.siDate));

        setGoodsRecDate(getDefaultDateFormat(dataSO.goodsRecDate));

        setBookingConfirmationId(dataSO.bookingConfirmationId);
        setBookingConfirmationNumber(dataSO.bookingNo);
        setPrincipleBy(dataSO.jobOwnerId);
        //SEA IMPORT
        setConversion(dataSO.conversion);
        setQuotationNo(dataSO.quotationNo);

        setOceanMSTBLNo(dataSO.oceanMSTBLNo);
        setVolumeBL(dataSO.volumeBL);
        setVolumeInvoice(dataSO.volumeInvoice);
        setSSLineId(dataSO.ssLineId);
        setSSLineName(dataSO.ssLineName);
        setEMKLId(dataSO.emklId);
        setEMKLName(dataSO.emklName);
        setDepoId(dataSO.depoId);
        setDepoName(dataSO.depoName);
        //SEA IMPORT
        setHouseBLNo(dataSO.houseBLNo);
        setSecondBLNo(dataSO.secondBLNo);
        setWareHouseName(dataSO.wareHouseName);
        setKins(dataSO.kins);
        setCFName(dataSO.cfName);
        //DOMESTIC
        setTruck(dataSO.truck);

        ////Variable for load status
        setLoadStatus(dataSO.loadStatus);

        ////Variable for type of service
        setTypeOfServiceId(dataSO.serviceNoId);

        ////Variable for container status
        setContainerStatus(dataSO.containerStatus);
        setFreightStatus(dataSO.freightStatus);

        ////Variable for job type
        setJobTypeId(dataSO.jobStatus);

        ////Variable for shipment status
        setShipmentStatus(dataSO.shipmentStatusId);
        handleShipmentStatus(dataSO.shipmentStatusId);

        ////Variable for marketing section
        setMarketId(dataSO.marketId);
        setMarketName(dataSO.marketName);
        setMarketCompanyId(dataSO.marketCompanyId);

        ////Variable for job category
        setJobCategory(dataSO.shipmentCategory);

        ////Variable for agent tab
        setAgentId(dataSO.agentId);
        setAgentName(dataSO.agentName);
        setAgentAddress(dataSO.agentAddress);

        ////Variable for delivery tab
        setDeliveryId(dataSO.deliveryId);
        setDeliveryName(dataSO.deliveryName);
        setDeliveryAddress(dataSO.deliveryAddress);

        ////Variable for transhipment tab
        setTranshipmentId(dataSO.transhipmentId);
        setTranshipmentName(dataSO.transhipmentName);
        setTranshipmentAddress(dataSO.transhipmentAddress);

        ////Variable for shipper tab
        shipId = dataSO.shipperId;
        setShipperId(dataSO.shipperId);
        setShipperName(dataSO.shipperName);
        setShipperAddress(dataSO.shipperAddress);

        ////Variable for consignee tab
        consId = dataSO.consigneeId;
        setConsigneeId(dataSO.consigneeId);
        setConsigneeName(dataSO.consigneeName);
        setConsigneeAddress(dataSO.consigneeAddress);

        setTempConsigneeId(dataSO.tempConsigneeId);
        setTempConsigneeName(dataSO.tempConsigneeName);
        setTempConsigneeAddress(dataSO.tempConsigneeAddress);

        ////Variable for credit info tab
        billSId = dataSO.billToShipperId;
        setBillToShipperId(dataSO.billToShipperId);
        setBillShipperName(dataSO.billToShipperName);

        billCId = dataSO.billToConsigneeId;
        setBillToConsigneeId(dataSO.billToConsigneeId);
        setBillConsigneeName(dataSO.billToConsigneeName);

        setApprovedCredit(dataSO.approvedCredit);
        setFreightAmountIDR(dataSO.freightAmountInIDR);
        setFreightAmountUSD(dataSO.freightAmountInUSD);

        ////Variable for notify party tab
        setNPartyId(dataSO.nPartyId);
        setNPartyName(dataSO.nPartyName);
        setNPartyAddress(dataSO.nPartyAddress);

        ////Variable for booking party tab
        setBookingPartyId(dataSO.bookingPartyId);
        setBookingPartyName(dataSO.bookingPartyName);
        setBookingPartyAddress(dataSO.bookingPartyAddress);

        ////Variable for vessel & flight tab
        //////Var place of receipt
        setReceiptPlaceId(dataSO.receiptPlaceId);
        setReceiptPlaceCode(dataSO.receiptPlaceCode);
        setReceiptPlaceName(dataSO.receiptPlaceName);

        //////Var place of delivery
        setDeliveryPlaceId(dataSO.deliveryPlaceId);
        setDeliveryPlaceCode(dataSO.deliveryPlaceCode);
        setDeliveryPlaceName(dataSO.deliveryPlaceName);

        //SEA
        //////Var port of discharge
        setDischargePortId(dataSO.dischargePortId);
        setDischargePortCode(dataSO.dischargePortCode);
        setDischargePortName(dataSO.dischargePortName);

        //////Var port of loading
        setLoadingPortId(dataSO.loadingPortId);
        setLoadingPortCode(dataSO.loadingPortCode);
        setLoadingPortName(dataSO.loadingPortName);

        //AIR
        //////Var departure airport
        setDepartureAirPortCode(dataSO.departureAirPortCode);
        setDepartureAirPortName(dataSO.departureAirPortName);

        /////Var destination airport
        setDestinationAirPortCode(dataSO.destinationAirPortCode);
        setDestinationAirportName(dataSO.destinationAirportName);

        setDateFormat(dataSO.eta, setETA);
        setDateFormat(dataSO.etd, setETD);
        setDateFormat(dataSO.atd, setATD);
        setDateFormat(dataSO.ata, setATA);

        setVesselData(dataLocal.shipmentOrderRoutings);
        setFlightData(dataLocal.shipmentOrderRoutings);

        setVesselFlight(dataSO.vesselFlight);
        if (dataLocal.shipmentOrderRoutings.length !== 0) {
            setVesselId(dataLocal.shipmentOrderRoutings[0].vesselId);
            setVesselName(dataLocal.shipmentOrderRoutings[0].vesselName);
            setVoyage(dataLocal.shipmentOrderRoutings[0].voyage);
            setCityId(dataLocal.shipmentOrderRoutings[0].cityId);
            setPortId(dataLocal.shipmentOrderRoutings[0].portId);
            setPortCode(dataLocal.shipmentOrderRoutings[0].portCode);
            setPortName(dataLocal.shipmentOrderRoutings[0].portName);
            setFlightId(dataLocal.shipmentOrderRoutings[0].flightId);
            setFlightCode(dataLocal.shipmentOrderRoutings[0].flightCode);
            setFlightName(dataLocal.shipmentOrderRoutings[0].flightName);
            setFlightNo(dataLocal.shipmentOrderRoutings[0].flightNo);
        }

        ////Variable for container tab
        setContainerData(dataLocal.shipmentOrderSeaContainers);
        createCopyContainer(dataLocal.shipmentOrderSeaContainers, true);

        getContainerSizeData(dataLocal.shipmentOrderSeaContainers);

        ////Variable for freight tab
        //SEA
        //////Var for freight OB/L
        setFreightStatusOBL(dataSO.oblStatus);
        if (dataSO.oblStatus === 'P') {
            setFreightCollectIdOBL(dataSO.deliveryPlaceId);
            setFreightCollectCodeOBL(dataSO.deliveryPlaceCode);
            setFreightCollectNameOBL(dataSO.deliveryPlaceName);
            setPayableAgentIdOBL(dataSO.agentId);
            setPayableAgentNameOBL(dataSO.agentName);
        }
        else {
            setFreightCollectIdOBL(dataSO.oblCollectId);
            setFreightCollectCodeOBL(dataSO.oblCollectCode);
            setFreightCollectNameOBL(dataSO.oblCollectName);
            setPayableAgentIdOBL(dataSO.oblPayableId);
            setPayableAgentNameOBL(dataSO.oblPayableName);
        }
        //SEA IMPORT
        setOBLCurrency(dataSO.oblCurrency);
        setOBLAmount(dataSO.oblAmount);

        //////Var for freight HB/L
        setFreightStatusHBL(dataSO.hblStatus);
        if (dataSO.hblStatus === 'P') {
            setFreightCollectIdHBL(dataSO.deliveryPlaceId);
            setFreightCollectCodeHBL(dataSO.deliveryPlaceCode);
            setFreightCollectNameHBL(dataSO.deliveryPlaceName);
            setPayableConsigneeIdHBL(dataSO.consigneeId);
            setPayableConsigneeNameHBL(dataSO.consigneeName);
        }
        else {
            setFreightCollectIdHBL(dataSO.hblCollectId);
            setFreightCollectCodeHBL(dataSO.hblCollectCode);
            setFreightCollectNameHBL(dataSO.hblCollectName);
            setPayableConsigneeIdHBL(dataSO.hblPayableId);
            setPayableConsigneeNameHBL(dataSO.hblPayableName);
        }
        //SEA IMPORT
        setHBLCurrency(dataSO.hblCurrency);
        setHBLAmount(dataSO.hblAmount);

        //AIR
        //////Var for freight MAWB
        setMAWBStatus(dataSO.mawbStatus);
        if (dataSO.mawbStatus === 'P') {
            setMAWBCollectId(dataSO.deliveryPlaceId);
            setMAWBCollectCode(dataSO.deliveryPlaceCode);
            setMAWBCollectName(dataSO.deliveryPlaceName);
            setPayableAgentIdMAWB(dataSO.agentId);
            setPayableAgentNameMAWB(dataSO.agentName);
        }
        else {
            setMAWBCollectId(dataSO.mawbCollectId);
            setMAWBCollectCode(dataSO.mawbCollectCode);
            setMAWBCollectName(dataSO.mawbCollectName);
            setPayableAgentIdMAWB(dataSO.mawbPayableId);
            setPayableAgentNameMAWB(dataSO.mawbPayableName);
        }
        //AIR IMPORT
        setMAWBCurrency(dataSO.mawbCurrency);
        setMAWBRate(dataSO.mawbRate);

        //////Var for freight HAWB
        setHAWBStatus(dataSO.hawbStatus);
        if (dataSO.hawbStatus === 'P') {
            setHAWBCollectId(dataSO.deliveryPlaceId);
            setHAWBCollectCode(dataSO.deliveryPlaceCode);
            setHAWBCollectName(dataSO.deliveryPlaceName);
            setPayableConsigneeIdHAWB(dataSO.consigneeId);
            setPayableConsigneeNameHAWB(dataSO.consigneeName);
        }
        else {
            setHAWBCollectId(dataSO.hawbCollectId);
            setHAWBCollectCode(dataSO.hawbCollectCode);
            setHAWBCollectName(dataSO.hawbCollectName);
            setPayableConsigneeIdHAWB(dataSO.hawbPayableId);
            setPayableConsigneeNameHAWB(dataSO.hawbPayableName);
        }
        //AIR IMPORT
        setHAWBCurrency(dataSO.hawbCurrency);
        setHAWBRate(dataSO.hawbRate);

        setCurrency(dataSO.currency);
        setHandlingInf(dataSO.handlingInfo);

        ////Variable for description tab
        //SEA
        setMarkNumberDescription(dataSO.markNumberDescription);
        setNumberTypeDescription(dataSO.numberTypeDescription);
        setGoodDescription(dataSO.descriptionOfGoods);
        setGrossWeightDescription(dataSO.grossWeightDescription);
        setMeasurementDescription(dataSO.measurementDescription);

        //AIR
        setPiecesRCP(dataSO.piecesRCP);
        setGrossWeight(dataSO.grossWeight);
        setKGLB(dataSO.kglb);
        setChargeWeight(dataSO.chargeWeight);
        setChargeRate(dataSO.chargeRate);
        setTotal(dataSO.total);
        setCommodity(dataSO.commodity);
        setPackagingCode(dataSO.packagingCode);
        setGoodNatureQuantity(dataSO.goodNatureQuantity);
        setShipmark(dataSO.shipmark);

        ////Variable for master airway bill
        setMAWBNo(dataSO.mawbNo);
        setChargeableWeight(dataSO.chargeableWeight);
        setWeightHAWB(dataSO.weightHAWB);
        setCarriageValue(dataSO.carriageValue);
        if (dataLocal.shipmentOrderRoutings.length !== 0) {
            setAirLineName(dataLocal.shipmentOrderRoutings[0].flightName);
        }

        setCustomValue(dataSO.customValue);
        setIATAId(dataSO.iataId);
        setIATAName(dataSO.iataName);
        setBrokerId(dataSO.brokerId);
        setBrokerName(dataSO.brokerName);
        //AIR IMPORT
        setHAWBNo(dataSO.hawbNo);

        ////Variable for con note
        setConNoteData(dataLocal.shipmentOrderConNotes);

        //Variable for bill of lading tab
        if (dataLocal.shipmentOrderBillOfLading !== null) {
            setBLNumber(dataLocal.shipmentOrderBillOfLading.blNumber);

            setDateFormat(dataLocal.shipmentOrderBillOfLading.placeDateOfIssue, setPlaceDateIssue);

            setNoBLData(dataLocal.shipmentOrderBillOfLading.noOfBL);
            setBLFormId(dataLocal.shipmentOrderBillOfLading.masterBLId);
            setBLFormName(dataLocal.shipmentOrderBillOfLading.masterBLName);

            setDateFormat(dataLocal.shipmentOrderBillOfLading.shipmentOnBoard, setShipmentOnBoard);

            setTotalNumberCont(dataLocal.shipmentOrderBillOfLading.totalNoOfContainer);
            setCargoInsurance(dataLocal.shipmentOrderBillOfLading.cargoInsurance);
            setFreightAmount(dataLocal.shipmentOrderBillOfLading.freightAmount);
            setFreightPayable(dataLocal.shipmentOrderBillOfLading.freightPayableAt);
            setDescriptionBoL(dataLocal.shipmentOrderBillOfLading.descriptions);

            //Data Contact
            setBLAgentId(dataLocal.shipmentOrderBillOfLading.agentId);
            setBLAgentName(dataLocal.shipmentOrderBillOfLading.agentName);
            setBLAgentAddress(dataLocal.shipmentOrderBillOfLading.agentAddress);

            setBLShipperId(dataLocal.shipmentOrderBillOfLading.shipperId);
            setBLShipperName(dataLocal.shipmentOrderBillOfLading.shipperName);
            setBLShipperAddress(dataLocal.shipmentOrderBillOfLading.shipperAddress);

            setBLConsigneeId(dataLocal.shipmentOrderBillOfLading.consigneeId);
            setBLConsigneeName(dataLocal.shipmentOrderBillOfLading.consigneeName);
            setBLConsigneeAddress(dataLocal.shipmentOrderBillOfLading.consigneeAddress);

            setBLNPartyId(dataLocal.shipmentOrderBillOfLading.nPartyId);
            setBLNPartyName(dataLocal.shipmentOrderBillOfLading.nPartyName);
            setBLNPartyAddress(dataLocal.shipmentOrderBillOfLading.nPartyAddress);

            setBLSNPartyId(dataLocal.shipmentOrderBillOfLading.snPartyId);
            setBLSNPartyName(dataLocal.shipmentOrderBillOfLading.snPartyName);
            setBLSNPartyAddress(dataLocal.shipmentOrderBillOfLading.snPartyAddress);

            //AIR
            setHAWBFee(dataLocal.shipmentOrderBillOfLading.hawbFee);
            setAmountInsurance(dataLocal.shipmentOrderBillOfLading.amountInsurance);

            ////Var for print status
            setPrintDraft(dataLocal.shipmentOrderBillOfLading.printDraft);

            setDateFormat(dataLocal.shipmentOrderBillOfLading.firstPrintFixedOn, setPrintDraftDate);

            setPrintFixed(dataLocal.shipmentOrderBillOfLading.printFixed);

            setDateFormat(dataLocal.shipmentOrderBillOfLading.printFixedOn, setPrintFixedDate);

            setDeletedBoL(dataLocal.shipmentOrderBillOfLading.deleted);
            setDateFormat(dataLocal.shipmentOrderBillOfLading.deletedOn, setDeletedOnBoL);
        }

        //Variable for shipping instruction
        if (dataLocal.shipmentOrderInstruction !== null) {
            setReference(dataLocal.shipmentOrderInstruction.siReference);
            setAttention(dataLocal.shipmentOrderInstruction.attention);

            setSIUpdatedCount(dataLocal.shipmentOrderInstruction.updated);
            setPrintingUpdatedCount(dataLocal.shipmentOrderInstruction.printing);

            setDateFormat(dataLocal.shipmentOrderInstruction.printedOn, setDatePrint);
            setDateFormat(dataLocal.shipmentOrderInstruction.modifiedOn, setLastUpdated);

            //DATA CONTACT
            setSICompanyId(dataLocal.shipmentOrderInstruction.shipperId);
            setSICompanyName(dataLocal.shipmentOrderInstruction.shipperName);
            setSICompanyAddress(dataLocal.shipmentOrderInstruction.shipperAddress);

            setSIAgentId(dataLocal.shipmentOrderInstruction.consigneeId);
            setSIAgentName(dataLocal.shipmentOrderInstruction.consigneeName);
            setSIAgentAddress(dataLocal.shipmentOrderInstruction.consigneeAddress);

            setSIConsigneeId(dataLocal.shipmentOrderInstruction.consigneeId);
            setSIConsigneeName(dataLocal.shipmentOrderInstruction.consigneeName);
            setSIConsigneeAddress(dataLocal.shipmentOrderInstruction.consigneeAddress);

            setSINPartyId(dataLocal.shipmentOrderInstruction.nPartyId);
            setSINPartyName(dataLocal.shipmentOrderInstruction.nPartyName);
            setSINPartyAddress(dataLocal.shipmentOrderInstruction.nPartyAddress);

            //SEA EXPORT
            setShippingMarksDesc(dataLocal.shipmentOrderInstruction.shippingMarksDesc);
            setNoPackageDesc(dataLocal.shipmentOrderInstruction.noPackageDesc);
            setSIGoodDesc(dataLocal.shipmentOrderInstruction.descriptionOfGoods);
            setWeightMeaDesc(dataLocal.shipmentOrderInstruction.weightMeaDesc);

            //AIR EXPORT
            setSIPiecesRCP(dataLocal.shipmentOrderInstruction.piecesRCP);
            setSIGrossWeight(dataLocal.shipmentOrderInstruction.grossWeight2);
            setSIKGLB(dataLocal.shipmentOrderInstruction.kglb);
            setSIChargeWeight(dataLocal.shipmentOrderInstruction.chargeWeight);
            setSIChargeRate(dataLocal.shipmentOrderInstruction.chargeRate);
            setSITotal(dataLocal.shipmentOrderInstruction.total);
            setSIGoodNatureQ(dataLocal.shipmentOrderInstruction.goodNatureQuantity);
            setSICommodity(dataLocal.shipmentOrderInstruction.commodity);
            setSIPackageCode(dataLocal.shipmentOrderInstruction.packagingCode);
            setSIShipmark(dataLocal.shipmentOrderInstruction.shippingMark);

            setSIOriginalBL(dataLocal.shipmentOrderInstruction.originalBL);
            setSISpecialIns(dataLocal.shipmentOrderInstruction.specialInstruction);

            setFreightOBL(dataLocal.shipmentOrderInstruction.freightAgreed);
            setSICollectAgentId(dataLocal.shipmentOrderInstruction.collectAt);
            setSICollectAgentName(dataLocal.shipmentOrderInstruction.collectName);
            setSICollectAgentAddress(dataLocal.shipmentOrderInstruction.collectAddress);

            setDeletedSI(dataLocal.shipmentOrderInstruction.deleted);

            setDateFormat(dataLocal.shipmentOrderInstruction.deletedOn, setDeletedOnSI);
        }

        //Variable for shipment advice tab
        if (dataLocal.shipmentOrderAdvice !== null) {
            setSAReference(dataLocal.shipmentOrderAdvice.reference);
            setSARemarks(dataLocal.shipmentOrderAdvice.remarks);
            setSAPrintAgentId(dataLocal.shipmentOrderAdvice.printToAgentId);
            setSAPrintAgentName(dataLocal.shipmentOrderAdvice.printToAgentName);
            setSAPrintDeliveryId(dataLocal.shipmentOrderAdvice.printToDeliveryId);
            setSAPrintDeliveryName(dataLocal.shipmentOrderAdvice.printToDeliveryName);

            setDateFormat(dataLocal.shipmentOrderAdvice.printToAgentOn, setPrintToAgentOnSA);
            setDateFormat(dataLocal.shipmentOrderAdvice.printToDeliveryOn, setPrintToDeliveryOnSA);

            setDeletedSA(dataLocal.shipmentOrderAdvice.deleted);
            setDateFormat(dataLocal.shipmentOrderAdvice.deletedOn, setDeletedOnSA);
        }

        //Variable for telex release tab
        if (dataLocal.shipmentOrderTelexRelease) {
            setTRFullSetStatus(dataLocal.shipmentOrderTelexRelease.original);
            setTRReleaseStatus(dataLocal.shipmentOrderTelexRelease.seaWaybill);
            setTRPrintCount(dataLocal.shipmentOrderTelexRelease.printing);
            setDateFormat(dataLocal.shipmentOrderTelexRelease.printedOn, setTRPrintDate);

            setDeletedTR(dataLocal.shipmentOrderTelexRelease.deleted);
            setDateFormat(dataLocal.shipmentOrderTelexRelease.deletedOn, setDeletedOnTR);
        }

        //Variable for tracking tab
        setDataTracking(dataLocal.shipmentOrderTrackings);

        //Variable for delivery order
        if (dataSO.isNew === true) {
            setIsNew(false);
        }

        getDataCreditTerm(jobType, shipId, consId, billSId, billCId, dataSO.jobTypeId);

        ApiService.PostById(paramRegJobType, dataSO.jobTypeId)
            .then((response) => {
                if (response.data.code === 200) {
                    setJobType(response.data.data.jobType);
                    setIdJobType(response.data.data.jobType.jobTypeId);
                }
            })
            .catch(function (error) {
                // console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })

        localStorage.removeItem(username + '-DataSO');
        setTimeout(function () { setIsLoadingBackDrop(false); }, 1250);
    }

    function handleDocumentStatus(rowStatus, isApproved = false) {
        if (rowStatus !== 'ACT') {
            setDocumentStatus('DELETED');
        }
        else if (isApproved) {
            setDocumentStatus('APPROVED');
        }
        else {
            setDocumentStatus('ONGOING');
        }
    }

    const getData = (id, jobType) => {
        let shipId = 0;
        let consId = 0;
        let billSId = 0;
        let billCId = 0;
        ApiService.OperationPostById(paramShipmentOrder, id)
            .then((response) => {
                if (response.data.code === 200) {
                    setIsEdit(true);
                    setJobClose(response.data.data.shipmentOrder.jobClosed);
                    if (response.data.data.shipmentOrder.jobClosed === true) {
                        setIsJobClose(true);
                    }
                    else {
                        setIsJobClose(false);
                    }
                    //Variable for shipment order
                    handleDocumentStatus(response.data.data.shipmentOrder.rowStatus);
                    setJobTypeCode(response.data.data.shipmentOrder.jobTypeCode);

                    setRowStatus(response.data.data.shipmentOrder.rowStatus);
                    setTicketId(response.data.data.shipmentOrder.ticketId);
                    setSubJobNo(response.data.data.shipmentOrder.subJobNo);
                    setJobClosed(response.data.data.shipmentOrder.jobClosed);
                    setDeleted(response.data.data.shipmentOrder.deleted);
                    setApprovedCreditBy(response.data.data.shipmentOrder.approvedCreditBy);
                    setApprovedCreditRemarks(response.data.data.shipmentOrder.approvedCreditRemarks);
                    setRequester(response.data.data.shipmentOrder.requester);
                    setJobNumber(response.data.data.shipmentOrder.jobNumber);

                    setDateFormat(response.data.data.shipmentOrder.jobClosedOn, setJobClosedOn);
                    setDateFormat(response.data.data.shipmentOrder.deletedOn, setDeletedOn);
                    setDateFormat(response.data.data.shipmentOrder.approvedCreditOn, setApprovedCreditOn);

                    setShipmentId(response.data.data.shipmentOrder.id);
                    setShipmentNo(response.data.data.shipmentOrder.shipmentNo);
                    setTotalSub(response.data.data.shipmentOrder.totalSub);
                    setSIReference(response.data.data.shipmentOrder.siReference);

                    setDateFormat(response.data.data.shipmentOrder.siDate, setSIDate);
                    setDateFormat(response.data.data.shipmentOrder.goodsRecDate, setGoodsRecDate);

                    setBookingConfirmationId(response.data.data.shipmentOrder.bookingConfirmationId);
                    setBookingConfirmationNumber(response.data.data.shipmentOrder.bookingNo);
                    setPrincipleBy(response.data.data.shipmentOrder.jobOwnerId);
                    //SEA IMPORT
                    setConversion(response.data.data.shipmentOrder.conversion);
                    setQuotationNo(response.data.data.shipmentOrder.quotationNo);

                    setOceanMSTBLNo(response.data.data.shipmentOrder.oceanMSTBLNo);
                    setVolumeBL(response.data.data.shipmentOrder.volumeBL);
                    setVolumeInvoice(response.data.data.shipmentOrder.volumeInvoice);
                    setSSLineId(response.data.data.shipmentOrder.ssLineId);
                    setSSLineName(response.data.data.shipmentOrder.ssLineName);
                    setEMKLId(response.data.data.shipmentOrder.emklId);
                    setEMKLName(response.data.data.shipmentOrder.emklName);
                    setDepoId(response.data.data.shipmentOrder.depoId);
                    setDepoName(response.data.data.shipmentOrder.depoName);
                    //SEA IMPORT
                    setHouseBLNo(response.data.data.shipmentOrder.houseBLNo);
                    setSecondBLNo(response.data.data.shipmentOrder.secondBLNo);
                    setWareHouseName(response.data.data.shipmentOrder.wareHouseName);
                    setKins(response.data.data.shipmentOrder.kins);
                    setCFName(response.data.data.shipmentOrder.cfName);
                    //DOMESTIC
                    setTruck(response.data.data.shipmentOrder.truck);

                    ////Variable for load status
                    setLoadStatus(response.data.data.shipmentOrder.loadStatus);

                    ////Variable for type of service
                    setTypeOfServiceId(response.data.data.shipmentOrder.serviceNoId);

                    ////Variable for container status
                    setContainerStatus(response.data.data.shipmentOrder.containerStatus);
                    setFreightStatus(response.data.data.shipmentOrder.freightStatus);

                    ////Variable for job type
                    setJobTypeId(response.data.data.shipmentOrder.jobStatus);

                    ////Variable for shipment status
                    setShipmentStatus(response.data.data.shipmentOrder.shipmentStatusId);
                    handleShipmentStatus(response.data.data.shipmentOrder.shipmentStatusId);

                    ////Variable for marketing section
                    setMarketId(response.data.data.shipmentOrder.marketId);
                    setMarketName(response.data.data.shipmentOrder.marketName);
                    setMarketCompanyId(response.data.data.shipmentOrder.marketCompanyId);

                    ////Variable for job category
                    setJobCategory(response.data.data.shipmentOrder.shipmentCategory);

                    ////Variable for agent tab
                    setAgentId(response.data.data.shipmentOrder.agentId);
                    setAgentName(response.data.data.shipmentOrder.agentName);
                    setAgentAddress(response.data.data.shipmentOrder.agentAddress);

                    ////Variable for delivery tab
                    setDeliveryId(response.data.data.shipmentOrder.deliveryId);
                    setDeliveryName(response.data.data.shipmentOrder.deliveryName);
                    setDeliveryAddress(response.data.data.shipmentOrder.deliveryAddress);

                    ////Variable for transhipment tab
                    setTranshipmentId(response.data.data.shipmentOrder.transhipmentId);
                    setTranshipmentName(response.data.data.shipmentOrder.transhipmentName);
                    setTranshipmentAddress(response.data.data.shipmentOrder.transhipmentAddress);

                    ////Variable for shipper tab
                    shipId = response.data.data.shipmentOrder.shipperId;
                    setShipperId(response.data.data.shipmentOrder.shipperId);
                    setShipperName(response.data.data.shipmentOrder.shipperName);
                    setShipperAddress(response.data.data.shipmentOrder.shipperAddress);

                    ////Variable for consignee tab
                    consId = response.data.data.shipmentOrder.consigneeId;
                    setConsigneeId(response.data.data.shipmentOrder.consigneeId);
                    setConsigneeName(response.data.data.shipmentOrder.consigneeName);
                    setConsigneeAddress(response.data.data.shipmentOrder.consigneeAddress);

                    if (response.data.data.shipmentOrder.consigneeId !== 0) {
                        setTempConsigneeId(response.data.data.shipmentOrder.consigneeId);
                        setTempConsigneeName(response.data.data.shipmentOrder.tempConsigneeName);
                        setTempConsigneeAddress(response.data.data.shipmentOrder.tempConsigneeAddress);
                    }

                    ////Variable for credit info tab
                    billSId = response.data.data.shipmentOrder.billToShipperId;
                    setBillToShipperId(response.data.data.shipmentOrder.billToShipperId);
                    setBillShipperName(response.data.data.shipmentOrder.billToShipperName);

                    billCId = response.data.data.shipmentOrder.billToConsigneeId;
                    setBillToConsigneeId(response.data.data.shipmentOrder.billToConsigneeId);
                    setBillConsigneeName(response.data.data.shipmentOrder.billToConsigneeName);

                    setApprovedCredit(response.data.data.shipmentOrder.approvedCredit);
                    setFreightAmountIDR(response.data.data.shipmentOrder.freightAmountInIDR);
                    setFreightAmountUSD(response.data.data.shipmentOrder.freightAmountInUSD);

                    ////Variable for notify party tab
                    setNPartyId(response.data.data.shipmentOrder.nPartyId);
                    setNPartyName(response.data.data.shipmentOrder.nPartyName);
                    setNPartyAddress(response.data.data.shipmentOrder.nPartyAddress);

                    ////Variable for booking party tab
                    setBookingPartyId(response.data.data.shipmentOrder.bookingPartyId);
                    setBookingPartyName(response.data.data.shipmentOrder.bookingPartyName);
                    setBookingPartyAddress(response.data.data.shipmentOrder.bookingPartyAddress);

                    ////Variable for vessel & flight tab
                    //////Var place of receipt
                    setReceiptPlaceId(response.data.data.shipmentOrder.receiptPlaceId);
                    setReceiptPlaceCode(response.data.data.shipmentOrder.receiptPlaceCode);
                    setReceiptPlaceName(response.data.data.shipmentOrder.receiptPlaceName);

                    if (response.data.data.shipmentOrder.receiptPlaceId !== 0) {
                        getDataTempCity(response.data.data.shipmentOrder.receiptPlaceId, setTempReceiptPlaceName);
                    }

                    //////Var place of delivery
                    setDeliveryPlaceId(response.data.data.shipmentOrder.deliveryPlaceId);
                    setDeliveryPlaceCode(response.data.data.shipmentOrder.deliveryPlaceCode);
                    setDeliveryPlaceName(response.data.data.shipmentOrder.deliveryPlaceName);

                    if (response.data.data.shipmentOrder.deliveryPlaceId !== 0) {
                        getDataTempCity(response.data.data.shipmentOrder.deliveryPlaceId, setTempDeliveryPlaceName);
                    }

                    //SEA
                    //////Var port of discharge
                    setDischargePortId(response.data.data.shipmentOrder.dischargePortId);
                    setDischargePortCode(response.data.data.shipmentOrder.dischargePortCode);
                    setDischargePortName(response.data.data.shipmentOrder.dischargePortName);

                    if (response.data.data.shipmentOrder.dischargePortId !== 0) {
                        getDataTempCity(response.data.data.shipmentOrder.dischargePortId, setTempDischargePortName, 'seaPort/seaPort', 'seaPort');
                    }

                    //////Var port of loading
                    setLoadingPortId(response.data.data.shipmentOrder.loadingPortId);
                    setLoadingPortCode(response.data.data.shipmentOrder.loadingPortCode);
                    setLoadingPortName(response.data.data.shipmentOrder.loadingPortName);

                    if (response.data.data.shipmentOrder.loadingPortId !== 0) {
                        getDataTempCity(response.data.data.shipmentOrder.loadingPortId, setTempLoadingPortName, 'seaPort/seaPort', 'seaPort');
                    }

                    //AIR
                    //////Var departure airport
                    setDepartureAirPortCode(response.data.data.shipmentOrder.departureAirPortCode);
                    setDepartureAirPortName(response.data.data.shipmentOrder.departureAirPortName);

                    if (response.data.data.shipmentOrder.departureAirPortId !== 0) {
                        getDataTempCity(response.data.data.shipmentOrder.departureAirPortId, setTempLoadingPortName, 'airPort/airPort', 'airPort');
                    }

                    /////Var destination airport
                    setDestinationAirPortCode(response.data.data.shipmentOrder.destinationAirPortCode);
                    setDestinationAirportName(response.data.data.shipmentOrder.destinationAirportName);

                    if (response.data.data.shipmentOrder.destinationAirPortId !== 0) {
                        getDataTempCity(response.data.data.shipmentOrder.destinationAirPortId, setTempDischargePortName, 'airPort/airPort', 'airPort');
                    }

                    setDateFormat(response.data.data.shipmentOrder.eta, setETA);
                    setDateFormat(response.data.data.shipmentOrder.etd, setETD);
                    setDateFormat(response.data.data.shipmentOrder.atd, setATD);
                    setDateFormat(response.data.data.shipmentOrder.ata, setATA);

                    setVesselData(response.data.data.shipmentOrder.shipmentOrderRoutings);
                    setFlightData(response.data.data.shipmentOrder.shipmentOrderRoutings);

                    setVesselFlight(response.data.data.shipmentOrder.vesselFlight);
                    if (response.data.data.shipmentOrder.shipmentOrderRoutings.length !== 0) {
                        setVesselId(response.data.data.shipmentOrder.shipmentOrderRoutings[0].vesselId);
                        setVesselName(response.data.data.shipmentOrder.shipmentOrderRoutings[0].vesselName);
                        setVoyage(response.data.data.shipmentOrder.shipmentOrderRoutings[0].voyage);
                        setCityId(response.data.data.shipmentOrder.shipmentOrderRoutings[0].cityId);
                        setPortId(response.data.data.shipmentOrder.shipmentOrderRoutings[0].portId);
                        setPortCode(response.data.data.shipmentOrder.shipmentOrderRoutings[0].portCode);
                        setPortName(response.data.data.shipmentOrder.shipmentOrderRoutings[0].portName);
                        setFlightId(response.data.data.shipmentOrder.shipmentOrderRoutings[0].flightId);
                        setFlightCode(response.data.data.shipmentOrder.shipmentOrderRoutings[0].flightCode);
                        setFlightName(response.data.data.shipmentOrder.shipmentOrderRoutings[0].flightName);
                        setFlightNo(response.data.data.shipmentOrder.shipmentOrderRoutings[0].flightNo);
                    }

                    ////Variable for container tab
                    setContainerData(response.data.data.shipmentOrder.shipmentOrderSeaContainers);
                    createCopyContainer(response.data.data.shipmentOrder.shipmentOrderSeaContainers, true);

                    getContainerSizeData(response.data.data.shipmentOrder.shipmentOrderSeaContainers);

                    ////Variable for freight tab
                    //SEA
                    //////Var for freight OB/L
                    setFreightStatusOBL(response.data.data.shipmentOrder.oblStatus);
                    if (response.data.data.shipmentOrder.oblStatus === 'P') {
                        setFreightCollectIdOBL(response.data.data.shipmentOrder.deliveryPlaceId);
                        setFreightCollectCodeOBL(response.data.data.shipmentOrder.deliveryPlaceCode);
                        setFreightCollectNameOBL(response.data.data.shipmentOrder.deliveryPlaceName);
                        setPayableAgentIdOBL(response.data.data.shipmentOrder.agentId);
                        setPayableAgentNameOBL(response.data.data.shipmentOrder.agentName);
                    }
                    else {
                        setFreightCollectIdOBL(response.data.data.shipmentOrder.oblCollectId);
                        setFreightCollectCodeOBL(response.data.data.shipmentOrder.oblCollectCode);
                        setFreightCollectNameOBL(response.data.data.shipmentOrder.oblCollectName);
                        setPayableAgentIdOBL(response.data.data.shipmentOrder.oblPayableId);
                        setPayableAgentNameOBL(response.data.data.shipmentOrder.oblPayableName);
                    }
                    //SEA IMPORT
                    setOBLCurrency(response.data.data.shipmentOrder.oblCurrency);
                    setOBLAmount(response.data.data.shipmentOrder.oblAmount);

                    //////Var for freight HB/L
                    setFreightStatusHBL(response.data.data.shipmentOrder.hblStatus);
                    if (response.data.data.shipmentOrder.hblStatus === 'P') {
                        setFreightCollectIdHBL(response.data.data.shipmentOrder.deliveryPlaceId);
                        setFreightCollectCodeHBL(response.data.data.shipmentOrder.deliveryPlaceCode);
                        setFreightCollectNameHBL(response.data.data.shipmentOrder.deliveryPlaceName);
                        setPayableConsigneeIdHBL(response.data.data.shipmentOrder.consigneeId);
                        setPayableConsigneeNameHBL(response.data.data.shipmentOrder.consigneeName);
                    }
                    else {
                        setFreightCollectIdHBL(response.data.data.shipmentOrder.hblCollectId);
                        setFreightCollectCodeHBL(response.data.data.shipmentOrder.hblCollectCode);
                        setFreightCollectNameHBL(response.data.data.shipmentOrder.hblCollectName);
                        setPayableConsigneeIdHBL(response.data.data.shipmentOrder.hblPayableId);
                        setPayableConsigneeNameHBL(response.data.data.shipmentOrder.hblPayableName);
                    }
                    //SEA IMPORT
                    setHBLCurrency(response.data.data.shipmentOrder.hblCurrency);
                    setHBLAmount(response.data.data.shipmentOrder.hblAmount);

                    //AIR
                    //////Var for freight MAWB
                    setMAWBStatus(response.data.data.shipmentOrder.mawbStatus);
                    if (response.data.data.shipmentOrder.mawbStatus === 'P') {
                        setMAWBCollectId(response.data.data.shipmentOrder.deliveryPlaceId);
                        setMAWBCollectCode(response.data.data.shipmentOrder.deliveryPlaceCode);
                        setMAWBCollectName(response.data.data.shipmentOrder.deliveryPlaceName);
                        setPayableAgentIdMAWB(response.data.data.shipmentOrder.agentId);
                        setPayableAgentNameMAWB(response.data.data.shipmentOrder.agentName);
                    }
                    else {
                        setMAWBCollectId(response.data.data.shipmentOrder.mawbCollectId);
                        setMAWBCollectCode(response.data.data.shipmentOrder.mawbCollectCode);
                        setMAWBCollectName(response.data.data.shipmentOrder.mawbCollectName);
                        setPayableAgentIdMAWB(response.data.data.shipmentOrder.mawbPayableId);
                        setPayableAgentNameMAWB(response.data.data.shipmentOrder.mawbPayableName);
                    }
                    //AIR IMPORT
                    setMAWBCurrency(response.data.data.shipmentOrder.mawbCurrency);
                    setMAWBRate(response.data.data.shipmentOrder.mawbRate);

                    //////Var for freight HAWB
                    setHAWBStatus(response.data.data.shipmentOrder.hawbStatus);
                    if (response.data.data.shipmentOrder.hawbStatus === 'P') {
                        setHAWBCollectId(response.data.data.shipmentOrder.deliveryPlaceId);
                        setHAWBCollectCode(response.data.data.shipmentOrder.deliveryPlaceCode);
                        setHAWBCollectName(response.data.data.shipmentOrder.deliveryPlaceName);
                        setPayableConsigneeIdHAWB(response.data.data.shipmentOrder.consigneeId);
                        setPayableConsigneeNameHAWB(response.data.data.shipmentOrder.consigneeName);
                    }
                    else {
                        setHAWBCollectId(response.data.data.shipmentOrder.hawbCollectId);
                        setHAWBCollectCode(response.data.data.shipmentOrder.hawbCollectCode);
                        setHAWBCollectName(response.data.data.shipmentOrder.hawbCollectName);
                        setPayableConsigneeIdHAWB(response.data.data.shipmentOrder.hawbPayableId);
                        setPayableConsigneeNameHAWB(response.data.data.shipmentOrder.hawbPayableName);
                    }
                    //AIR IMPORT
                    setHAWBCurrency(response.data.data.shipmentOrder.hawbCurrency);
                    setHAWBRate(response.data.data.shipmentOrder.hawbRate);

                    setCurrency(response.data.data.shipmentOrder.currency);
                    setHandlingInf(response.data.data.shipmentOrder.handlingInfo);

                    ////Variable for description tab
                    //SEA
                    setMarkNumberDescription(response.data.data.shipmentOrder.markNumberDescription);
                    setNumberTypeDescription(response.data.data.shipmentOrder.numberTypeDescription);
                    setGoodDescription(response.data.data.shipmentOrder.goodDescription);
                    setGrossWeightDescription(response.data.data.shipmentOrder.grossWeightDescription);
                    setMeasurementDescription(response.data.data.shipmentOrder.measurementDescription);

                    //AIR
                    setPiecesRCP(response.data.data.shipmentOrder.piecesRCP);
                    setGrossWeight(response.data.data.shipmentOrder.grossWeight);
                    setKGLB(response.data.data.shipmentOrder.kglb);
                    setChargeWeight(response.data.data.shipmentOrder.chargeWeight);
                    setChargeRate(response.data.data.shipmentOrder.chargeRate);
                    setTotal(response.data.data.shipmentOrder.total);
                    setCommodity(response.data.data.shipmentOrder.commodity);
                    setPackagingCode(response.data.data.shipmentOrder.packagingCode);
                    setGoodNatureQuantity(response.data.data.shipmentOrder.goodNatureQuantity);
                    setShipmark(response.data.data.shipmentOrder.shipmark);

                    ////Variable for master airway bill
                    setMAWBNo(response.data.data.shipmentOrder.mawbNo);
                    setChargeableWeight(response.data.data.shipmentOrder.chargeableWeight);
                    setWeightHAWB(response.data.data.shipmentOrder.weightHAWB);
                    setCarriageValue(response.data.data.shipmentOrder.carriageValue);
                    if (response.data.data.shipmentOrder.shipmentOrderRoutings.length !== 0) {
                        setAirLineName(response.data.data.shipmentOrder.shipmentOrderRoutings[0].flightName);
                    }

                    setCustomValue(response.data.data.shipmentOrder.customValue);
                    setIATAId(response.data.data.shipmentOrder.iataId);
                    setIATAName(response.data.data.shipmentOrder.iataName);
                    setBrokerId(response.data.data.shipmentOrder.brokerId);
                    setBrokerName(response.data.data.shipmentOrder.brokerName);
                    //AIR IMPORT
                    setHAWBNo(response.data.data.shipmentOrder.hawbNo);

                    ////Variable for con note
                    setConNoteData(response.data.data.shipmentOrder.shipmentOrderConNotes);

                    //Variable for bill of lading tab
                    if (response.data.data.shipmentOrder.shipmentOrderBillOfLading !== null) {
                        if (response.data.data.shipmentOrder.shipmentOrderBillOfLading.blNumber === '') {
                            setBLNumber(response.data.data.shipmentOrder.shipmentNo + '/' + response.data.data.shipmentOrder.deliveryPlaceCode);
                        }
                        else {
                            setBLNumber(response.data.data.shipmentOrder.shipmentOrderBillOfLading.blNumber);
                        }

                        setDateFormat(response.data.data.shipmentOrder.shipmentOrderBillOfLading.placeDateOfIssue, setPlaceDateIssue, true);

                        setNoBLData(response.data.data.shipmentOrder.shipmentOrderBillOfLading.noOfBL);
                        setBLFormId(response.data.data.shipmentOrder.shipmentOrderBillOfLading.masterBLId);
                        setBLFormName(response.data.data.shipmentOrder.shipmentOrderBillOfLading.masterBLName);

                        setBLReceiptPlaceName(response.data.data.shipmentOrder.shipmentOrderBillOfLading.receiptPlaceName);
                        setBLDischargePortName(response.data.data.shipmentOrder.shipmentOrderBillOfLading.dischargePortName);
                        setBLLoadingPortName(response.data.data.shipmentOrder.shipmentOrderBillOfLading.loadingPortName);
                        setBLDeliveryPlaceName(response.data.data.shipmentOrder.shipmentOrderBillOfLading.deliveryPlaceName);

                        setDateFormat(response.data.data.shipmentOrder.shipmentOrderBillOfLading.shipmentOnBoard, setShipmentOnBoard, true);

                        setTotalNumberCont(response.data.data.shipmentOrder.shipmentOrderBillOfLading.totalNoOfContainer);
                        setCargoInsurance(response.data.data.shipmentOrder.shipmentOrderBillOfLading.cargoInsurance);
                        setFreightAmount(response.data.data.shipmentOrder.shipmentOrderBillOfLading.freightAmount);
                        setFreightPayable(response.data.data.shipmentOrder.shipmentOrderBillOfLading.freightPayableAt);
                        setDescriptionBoL(response.data.data.shipmentOrder.shipmentOrderBillOfLading.descriptions);

                        //Data Contact
                        setBLAgentId(response.data.data.shipmentOrder.shipmentOrderBillOfLading.agentId);
                        setBLAgentName(response.data.data.shipmentOrder.shipmentOrderBillOfLading.agentName);
                        setBLAgentAddress(response.data.data.shipmentOrder.shipmentOrderBillOfLading.agentAddress);

                        setBLShipperId(response.data.data.shipmentOrder.shipmentOrderBillOfLading.shipperId);
                        setBLShipperName(response.data.data.shipmentOrder.shipmentOrderBillOfLading.shipperName);
                        setBLShipperAddress(response.data.data.shipmentOrder.shipmentOrderBillOfLading.shipperAddress);

                        setBLConsigneeId(response.data.data.shipmentOrder.shipmentOrderBillOfLading.consigneeId);
                        setBLConsigneeName(response.data.data.shipmentOrder.shipmentOrderBillOfLading.consigneeName);
                        setBLConsigneeAddress(response.data.data.shipmentOrder.shipmentOrderBillOfLading.consigneeAddress);

                        setBLNPartyId(response.data.data.shipmentOrder.shipmentOrderBillOfLading.nPartyId);
                        setBLNPartyName(response.data.data.shipmentOrder.shipmentOrderBillOfLading.nPartyName);
                        setBLNPartyAddress(response.data.data.shipmentOrder.shipmentOrderBillOfLading.nPartyAddress);

                        setBLSNPartyId(response.data.data.shipmentOrder.shipmentOrderBillOfLading.snPartyId);
                        setBLSNPartyName(response.data.data.shipmentOrder.shipmentOrderBillOfLading.snPartyName);
                        setBLSNPartyAddress(response.data.data.shipmentOrder.shipmentOrderBillOfLading.snPartyAddress);

                        //AIR
                        setHAWBFee(response.data.data.shipmentOrder.shipmentOrderBillOfLading.hawbFee);
                        setAmountInsurance(response.data.data.shipmentOrder.shipmentOrderBillOfLading.amountInsurance);

                        ////Var for print status
                        setPrintDraft(response.data.data.shipmentOrder.shipmentOrderBillOfLading.printDraft);
                        setDateFormat(response.data.data.shipmentOrder.shipmentOrderBillOfLading.firstPrintFixedOn, setPrintDraftDate);

                        setPrintFixed(response.data.data.shipmentOrder.shipmentOrderBillOfLading.printFixed);
                        setDateFormat(response.data.data.shipmentOrder.shipmentOrderBillOfLading.printFixedOn, setPrintFixedDate);

                        setDeletedBoL(response.data.data.shipmentOrder.shipmentOrderBillOfLading.deleted);
                        setDateFormat(response.data.data.shipmentOrder.shipmentOrderBillOfLading.deletedOn, setDeletedOnBoL);
                    }
                    else {
                        setBLNumber(response.data.data.shipmentOrder.shipmentNo + '/' + response.data.data.shipmentOrder.deliveryPlaceCode);

                        setBLAgentId(response.data.data.shipmentOrder.agentId);
                        setBLAgentName(response.data.data.shipmentOrder.agentName);
                        setBLAgentAddress(response.data.data.shipmentOrder.agentAddress);

                        setBLShipperId(response.data.data.shipmentOrder.shipperId);
                        setBLShipperName(response.data.data.shipmentOrder.shipperName);
                        setBLShipperAddress(response.data.data.shipmentOrder.shipperAddress);

                        setBLConsigneeId(response.data.data.shipmentOrder.consigneeId);
                        setBLConsigneeName(response.data.data.shipmentOrder.consigneeName);
                        setBLConsigneeAddress(response.data.data.shipmentOrder.consigneeAddress);

                        setBLNPartyId(response.data.data.shipmentOrder.nPartyId);
                        setBLNPartyName(response.data.data.shipmentOrder.nPartyName);
                        setBLNPartyAddress(response.data.data.shipmentOrder.nPartyAddress);

                        setShipmentOnBoard(getDefaultDateFormat(currentDate));
                        setPlaceDateIssue(getDefaultDateFormat(currentDate));
                    }

                    //Variable for shipping instruction
                    if (response.data.data.shipmentOrder.shipmentOrderInstruction !== null) {
                        setReference(response.data.data.shipmentOrder.shipmentOrderInstruction.siReference);
                        setAttention(response.data.data.shipmentOrder.shipmentOrderInstruction.attention);

                        setSIUpdatedCount(response.data.data.shipmentOrder.shipmentOrderInstruction.updated);
                        setPrintingUpdatedCount(response.data.data.shipmentOrder.shipmentOrderInstruction.printing);
                        setDateFormat(response.data.data.shipmentOrder.shipmentOrderInstruction.printedOn, setDatePrint);
                        setDateFormat(response.data.data.shipmentOrder.shipmentOrderInstruction.modifiedOn, setLastUpdated);

                        //DATA CONTACT
                        setSICompanyId(response.data.data.shipmentOrder.shipmentOrderInstruction.shipperId);
                        setSICompanyName(response.data.data.shipmentOrder.shipmentOrderInstruction.shipperName);
                        setSICompanyAddress(response.data.data.shipmentOrder.shipmentOrderInstruction.shipperAddress);

                        setSIAgentId(response.data.data.shipmentOrder.shipmentOrderInstruction.consigneeId);
                        setSIAgentName(response.data.data.shipmentOrder.shipmentOrderInstruction.consigneeName);
                        setSIAgentAddress(response.data.data.shipmentOrder.shipmentOrderInstruction.consigneeAddress);

                        setSIConsigneeId(response.data.data.shipmentOrder.shipmentOrderInstruction.consigneeId);
                        setSIConsigneeName(response.data.data.shipmentOrder.shipmentOrderInstruction.consigneeName);
                        setSIConsigneeAddress(response.data.data.shipmentOrder.shipmentOrderInstruction.consigneeAddress);

                        setSINPartyId(response.data.data.shipmentOrder.shipmentOrderInstruction.nPartyId);
                        setSINPartyName(response.data.data.shipmentOrder.shipmentOrderInstruction.nPartyName);
                        setSINPartyAddress(response.data.data.shipmentOrder.shipmentOrderInstruction.nPartyAddress);

                        //SEA EXPORT
                        setShippingMarksDesc(response.data.data.shipmentOrder.shipmentOrderInstruction.shippingMarksDesc);
                        setNoPackageDesc(response.data.data.shipmentOrder.shipmentOrderInstruction.noPackageDesc);
                        setSIGoodDesc(response.data.data.shipmentOrder.shipmentOrderInstruction.descriptionOfGoods);
                        setWeightMeaDesc(response.data.data.shipmentOrder.shipmentOrderInstruction.weightMeaDesc);

                        //AIR EXPORT
                        setSIPiecesRCP(response.data.data.shipmentOrder.shipmentOrderInstruction.piecesRCP);
                        setSIGrossWeight(response.data.data.shipmentOrder.shipmentOrderInstruction.grossWeight2);
                        setSIKGLB(response.data.data.shipmentOrder.shipmentOrderInstruction.kglb);
                        setSIChargeWeight(response.data.data.shipmentOrder.shipmentOrderInstruction.chargeWeight);
                        setSIChargeRate(response.data.data.shipmentOrder.shipmentOrderInstruction.chargeRate);
                        setSITotal(response.data.data.shipmentOrder.shipmentOrderInstruction.total);
                        setSIGoodNatureQ(response.data.data.shipmentOrder.shipmentOrderInstruction.goodNatureQuantity);
                        setSICommodity(response.data.data.shipmentOrder.shipmentOrderInstruction.commodity);
                        setSIPackageCode(response.data.data.shipmentOrder.shipmentOrderInstruction.packagingCode);
                        setSIShipmark(response.data.data.shipmentOrder.shipmentOrderInstruction.shippingMark);

                        setSIOriginalBL(response.data.data.shipmentOrder.shipmentOrderInstruction.originalBL);
                        setSISpecialIns(response.data.data.shipmentOrder.shipmentOrderInstruction.specialInstruction);

                        setFreightOBL(response.data.data.shipmentOrder.shipmentOrderInstruction.freightAgreed);
                        setSICollectAgentId(response.data.data.shipmentOrder.shipmentOrderInstruction.collectAt);
                        setSICollectAgentName(response.data.data.shipmentOrder.shipmentOrderInstruction.collectName);
                        setSICollectAgentAddress(response.data.data.shipmentOrder.shipmentOrderInstruction.collectAddress);

                        setDeletedSI(response.data.data.shipmentOrder.shipmentOrderInstruction.deleted);
                        setDateFormat(response.data.data.shipmentOrder.shipmentOrderInstruction.deletedOn, setDeletedOnSI);
                    }

                    //Variable for shipment advice tab
                    if (response.data.data.shipmentOrder.shipmentOrderAdvice !== null) {
                        setSAReference(response.data.data.shipmentOrder.shipmentOrderAdvice.reference);
                        setSARemarks(response.data.data.shipmentOrder.shipmentOrderAdvice.remarks);
                        setSAPrintAgentId(response.data.data.shipmentOrder.shipmentOrderAdvice.printToAgentId);
                        setSAPrintAgentName(response.data.data.shipmentOrder.shipmentOrderAdvice.printToAgentName);
                        setSAPrintDeliveryId(response.data.data.shipmentOrder.shipmentOrderAdvice.printToDeliveryId);
                        setSAPrintDeliveryName(response.data.data.shipmentOrder.shipmentOrderAdvice.printToDeliveryName);

                        setDateFormat(response.data.data.shipmentOrder.shipmentOrderAdvice.printToAgentOn, setPrintToAgentOnSA);
                        setDateFormat(response.data.data.shipmentOrder.shipmentOrderAdvice.printToDeliveryOn, setPrintToDeliveryOnSA);

                        setDeletedSA(response.data.data.shipmentOrder.shipmentOrderAdvice.deleted);
                        setDateFormat(response.data.data.shipmentOrder.shipmentOrderAdvice.deletedOn, setDeletedOnSA);
                    }

                    //Variable for telex release tab
                    if (response.data.data.shipmentOrder.shipmentOrderTelexRelease) {
                        setTRFullSetStatus(response.data.data.shipmentOrder.shipmentOrderTelexRelease.original);
                        setTRReleaseStatus(response.data.data.shipmentOrder.shipmentOrderTelexRelease.seaWaybill);
                        setTRPrintCount(response.data.data.shipmentOrder.shipmentOrderTelexRelease.printing);
                        setDateFormat(response.data.data.shipmentOrder.shipmentOrderTelexRelease.printedOn, setTRPrintDate);

                        setDeletedTR(response.data.data.shipmentOrder.shipmentOrderTelexRelease.deleted);
                        setDateFormat(response.data.data.shipmentOrder.shipmentOrderTelexRelease.deletedOn, setDeletedOnTR);
                    }

                    //Variable for tracking tab
                    setDataTracking(response.data.data.shipmentOrder.shipmentOrderTrackings);

                    //Variable for delivery order
                    if (response.data.data.shipmentOrder.isNew === true) {
                        setIsNew(false);
                    }
                }
                else {
                    Back();
                }
            })
            .then(() => {
                getDataCreditTerm(jobType, shipId, consId, billSId, billCId);
            })
            .catch(function (error) {
                // console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    }

    function getDataTempCity(id, setValue, param = 'city/city', type = 'city') {
        ApiService.PostById(param, id)
            .then((res) => {
                if (res.data.code === 200) {
                    if (type === 'city') {
                        setValue(res.data.data.city.name);
                    }
                    else if (type === 'seaPort') {
                        setValue(res.data.data.seaPort.name);
                    }
                }
            })
    }

    function createCopyContainer(data, isEdit) {
        let tempArr = [];
        let temp = '';
        let tempNoPackage = 0;
        let tempPack = '';
        let tempGW = 0;
        let tempNW = 0;
        let tempCBM = 0;
        let tempTotalNumberCont = '';
        let tempAll = 'CONTAINER / SEAL : ';
        if (data.length > 1) {
            for (let i = 0; i < data.length; i++) {
                temp = temp + '\n' + data[i].containerNo + '/' + data[i].containerSize + data[i].containerTypeCode + '/' + data[i].sealNo + '\n' +
                    data[i].noOfPieces + ' ' + data[i].packagingCode + '/' + data[i].grossWeight + ' KGS/ \n' +
                    data[i].netWeight + ' KGS/' + data[i].cbm + 'CBM';
                tempNoPackage = parseInt(tempNoPackage) + parseInt(data[i].noOfPieces);
                tempPack = data[i].packagingName;
                tempGW = parseInt(tempGW) + parseInt(data[i].grossWeight);
                tempNW = parseInt(tempNW) + parseInt(data[i].netWeight);
                tempCBM = parseInt(tempCBM) + parseInt(data[i].cbm);
            }
        }
        else if (data.length === 1) {
            temp = '\n' + data[0].containerNo + '/' + data[0].containerSize + data[0].containerTypeCode + '/' + data[0].sealNo + '\n' +
                data[0].noOfPieces + ' ' + data[0].packagingCode + '/' + data[0].grossWeight + ' KGS/ \n' +
                data[0].netWeight + ' KGS/' + data[0].cbm + ' CBM';
            tempNoPackage = parseInt(tempNoPackage) + parseInt(data[0].noOfPieces);
            tempPack = data[0].packagingName;
            tempGW = parseInt(tempGW) + parseInt(data[0].grossWeight);
            tempNW = parseInt(tempNW) + parseInt(data[0].netWeight);
            tempCBM = parseInt(tempCBM) + parseInt(data[0].cbm);
        }

        data.forEach(element => {
            if (tempArr.length === 0) {
                tempArr = [
                    ...tempArr,
                    {
                        'containerSize': element.containerSize,
                        'amount': 1,
                        'containerTypeCode': element.containerTypeCode,
                    }
                ];
            }
            else {
                let check = 0;
                for (let i = 0; i < tempArr.length; i++) {
                    if (tempArr[i].containerSize === element.containerSize && tempArr[i].containerTypeCode === element.containerTypeCode) {
                        tempArr[i].amount = parseInt(tempArr[i].amount) + parseInt(1);
                        check = 1;
                        break;
                    }
                }

                if (check === 0) {
                    tempArr = [
                        ...tempArr,
                        {
                            'containerSize': element.containerSize,
                            'amount': 1,
                            'containerTypeCode': element.containerTypeCode,
                        }
                    ]
                }
            }
        });

        if (data.length > 0 && !isEdit) {
            let container = '';

            if (tempArr.length > 1) {
                for (let i = 0; i < tempArr.length; i++) {
                    if (parseInt(tempArr[i].amount) > 1) {
                        container = 'Containers';
                    }
                    else {
                        container = 'Container';
                    }

                    tempTotalNumberCont = tempTotalNumberCont + tempArr[i].amount + ' x ' + tempArr[i].containerSize + ' ' + tempArr[i].containerTypeCode + ' ' + container;

                    if (i !== tempArr.length - 1) {
                        tempTotalNumberCont = tempTotalNumberCont + ', ';
                    }
                }
            }
            else {
                if (parseInt(tempArr[0].amount) > 1) {
                    container = 'Containers';
                }
                else {
                    container = 'Container';
                }

                tempTotalNumberCont = tempTotalNumberCont + tempArr[0].amount + ' x ' + tempArr[0].containerSize + ' ' + tempArr[0].containerTypeCode + ' ' + container;
            }

            setTotalNumberCont(tempTotalNumberCont);
        }
        else {
            setTotalNumberCont('');
        }

        setTempMarkNumberDescription(tempAll + temp);
        setTempNumberTypeDescription(tempNoPackage + ' ' + tempPack);
        setTempGrossWeightDescription('G.W. ' + tempGW + ' KGS\nN.W. ' + tempNW + ' KGS');
        setTempMeasurementDescription('MEA ' + tempCBM + ' CBM');
    }

    const getDataAccess = () => {
        if (!dataLocal) {
            setIsLoadingBackDrop(true);
        }

        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'OSHOR')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        let tempArray = [];
        let checked = 0;

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);
                        tempArray = response.data.data.userAccess;

                        tempArray.forEach(element => {
                            if (element.accessCode === 'CRE' || element.accessCode === 'UPD') {
                                checked = 1;
                            }
                        });

                        setCheck(checked);

                        if (hasAccess) {
                            if (isParallelFetch) {
                                getAdditionalData()
                            } else {
                                GetTrackingStatusData(1, 20);
                            }
                            getDateExchRate();
                        }
                    }
                    else {
                        Back();
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
                })
        }
        else {
            history('/Infoss');
        }
    }

    const errorToast = (location) => {
        showToast({ type: 'error', message: `Can't get ${location} data, contact administrator!.` });
    }

    const getAdditionalData = async () => {
        try {
            const [resTS, resST, resCS, resJS, resJO, resSS, resCT, resPT] = await Promise.all([
                ApiService.PostByPage(paramTrackingStatus, 1, 20),
                ApiService.PostByPage(paramTypeService, 1, 20),
                ApiService.PostByPage(paramContainerStatus, 1, 20),
                ApiService.PostByPage(paramJobStatus, 1, 20),
                ApiService.PostByPage(paramJobOwner, 1, 20),
                ApiService.PostByPage(paramShipmentStatus, 1, 20),
                ApiService.PostByPage(paramContainerType, 1, 20),
                ApiService.PostByPage(paramPackagingType, 1, 20),
            ])

            if (resTS.data.code === 200) {
                setTrackingStatusData(resTS.data.data.trackingStatus);
            } else {
                errorToast('tracking status')
                Back()
            }

            if (resST.data.code === 200) {
                setServiceTypeData(resST.data.data.serviceType);
            } else {
                errorToast('service type')
                Back()
            }

            if (resCS.data.code === 200) {
                setContainerStatusData(resCS.data.data.containerStatus);
            } else {
                errorToast('container status')
                Back()
            }

            if (resJS.data.code === 200) {
                setJobStatusData(resJS.data.data.jobStatus);
            } else {
                errorToast('job status')
                Back()
            }

            if (resJO.data.code === 200) {
                const dataJO = resJO.data.data?.jobOwner || []
                if (dataJO.length > 0) {
                    setPrincipleBy(resJO.data.data.jobOwner[0].id);
                }
                setJobOwnerData(dataJO);
            } else {
                errorToast('job owner')
                Back()
            }

            if (resSS.data.code === 200) {
                setShipmentStatusData(resSS.data.data.shipmentStatus);
            } else {
                errorToast('shipment status')
                Back()
            }

            if (resCT.data.code === 200) {
                setContainerTypeData(resCT.data.data.containerType);
            } else {
                errorToast('container type')
                Back()
            }

            if (resPT.data.code === 200) {
                setPackagingTypeData(resPT.data.data.packagingType);
            } else {
                errorToast('packaging type')
                Back()
            }

            GetCurrencyData(1, 300);
            checkCurrentPeriod();
        } catch (error) {
            errorToast('additional')
            Back()
        }
    }

    function getDateNow() {
        var tanggal = getDefaultDateFormat(new Date());
        setSIDate(tanggal);
        setGoodsRecDate(tanggal);
        setPlaceDateIssue(tanggal);
        setShipmentOnBoard(tanggal);
        setLastUpdated(tanggal);
        setDatePrint(tanggal);

        if (ETD === '') {
            setETD(tanggal);
        }

        if (ETA === '') {
            setETA(tanggal);
        }
    };

    const getDataCreditTerm = (jobType, shipperId, consigneeId, billToShipperId = 0, billToConsigneeId = 0, jobTypeId) => {
        let param = 'regContact/regContact';
        // let billTo = false;
        let contTypeId = 0;
        let contId = 0;
        let realContId = 0;
        let contBillId = 0;
        let jobTypes = '';

        if (jobType.serviceType === undefined) {
            ApiService.PostById(paramRegJobType, jobTypeId)
                .then((response) => {
                    if (response.data.code === 200) {
                        jobTypes = response.data.data.jobType.serviceType;
                    }
                    else {
                        Back();
                    }
                })
                .then(() => {
                    if (jobTypes === 'Export') {
                        contTypeId = 2;
                        realContId = shipperId;
                        contBillId = billToShipperId;
                        if (billToShipperId !== 0) {
                            contId = billToShipperId;
                            // billTo = true;
                        }
                        else {
                            contId = shipperId;
                            // billTo = false;
                        }
                    }
                    else if (jobTypes === 'Import') {
                        contTypeId = 3;
                        realContId = consigneeId;
                        contBillId = billToConsigneeId;
                        if (billToConsigneeId !== 0) {
                            contId = billToConsigneeId;
                            // billTo = true;
                        }
                        else {
                            contId = consigneeId;
                            // billTo = false;
                        }
                    }
                    let params = [
                        { 'attr': 'contactTypeId', 'value': '' + contTypeId },
                        { 'attr': 'contactId', 'value': '' + contId },
                        // {'attr': 'isBillTo', 'value': '' + billTo},
                    ]
                    ApiService.DynamicPostByPage(param, 'SEARCH', 1, 1, params, [])
                        .then((response) => {
                            if (response.data.code === 200) {
                                setCreditTermDays(response.data.data.contact[0].creditTermInDays);
                                setCreditTermIDR(response.data.data.contact[0].creditTermInIDR);
                                setCreditTermUSD(response.data.data.contact[0].creditTermInUSD);

                                ApiService.OutstandingInvoicePostBySearch('shipmentOrder/shipmentOrder', realContId, contBillId)
                                    .then((response) => {
                                        if (response.data.code === 200) {
                                            setOutstandingInIDR(response.data.data.shipmentOrderOSInv.outstandingInvInIDR);
                                            setOutstandingInUSD(response.data.data.shipmentOrderOSInv.outstandingInvInUSD);
                                        }
                                        else {
                                            Back();
                                        }
                                    })
                                    .catch(function (error) {
                                        console.error('error saat fetch, ' + error, error);
                                    })
                            }
                            else {
                                Back();
                            }
                        })
                        .catch(function (error) {
                            // console.error('error saat fetch', error);
                            showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
                        })
                })
                .catch(function (error) {
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
                })
        }
        else {
            jobTypes = jobType.serviceType;

            if (jobTypes !== 'Import') {
                contTypeId = 2;
                realContId = shipperId;
                contBillId = billToShipperId;
                if (billToShipperId !== 0) {
                    contId = billToShipperId;
                    // billTo = true;
                }
                else {
                    contId = shipperId;
                    // billTo = false;
                }
            }
            else if (jobTypes === 'Import') {
                contTypeId = 3;
                realContId = consigneeId;
                contBillId = billToConsigneeId;
                if (billToConsigneeId !== 0) {
                    contId = billToConsigneeId;
                    // billTo = true;
                }
                else {
                    contId = consigneeId;
                    // billTo = false;
                }
            }
            let params = [
                { 'attr': 'contactTypeId', 'value': '' + contTypeId },
                { 'attr': 'contactId', 'value': '' + contId },
                // {'attr': 'isBillTo', 'value': '' + billTo},
            ]
            ApiService.DynamicPostByPage(param, 'SEARCH', 1, 1, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        if (response.data.data.contact.length > 0) {
                            setCreditTermDays(response.data.data.contact[0].creditTermInDays);
                            setCreditTermIDR(response.data.data.contact[0].creditTermInIDR);
                            setCreditTermUSD(response.data.data.contact[0].creditTermInUSD);

                            ApiService.OutstandingInvoicePostBySearch('shipmentOrder/shipmentOrder', realContId, contBillId)
                                .then((response) => {
                                    if (response.data.code === 200) {
                                        setOutstandingInIDR(response.data.data.shipmentOrderOSInv.outstandingInvInIDR);
                                        setOutstandingInUSD(response.data.data.shipmentOrderOSInv.outstandingInvInUSD);
                                    }
                                    else {
                                        Back();
                                    }
                                })
                                .catch(function (error) {
                                    console.error('error saat fetch, ' + error, error);
                                })
                        }
                    }
                    else {
                        Back();
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch, ' + error, error);
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
                })
        }
    };

    function getContainerSizeData(containerData) {
        let count20 = 0;
        let count40 = 0;
        let count45 = 0;
        let countAll = 0;

        if (containerData.length > 0) {
            for (let i = 0; i < containerData.length; i++) {
                if (containerData[i].rowStatus !== 'DEL') {
                    if (containerData[i].containerSize === '20\'' || containerData[i].containerSize === '20\'HC') {
                        count20 += 1;
                    }

                    if (containerData[i].containerSize === '40\'' || containerData[i].containerSize === '40\'HC') {
                        count40 += 1;
                    }

                    if (containerData[i].containerSize === '45\'' || containerData[i].containerSize === '45\'HC') {
                        count45 += 1;
                    }

                    countAll += 1;
                }
            }
        }

        setData20(count20);
        setDataAll(countAll);
        setData40(count40);
        setData45(count45);
    }

    const GetBookingData = (bookId) => {
        if (bookId !== null || bookId !== '') {
            setIsLoadingBackDrop(true);
            getDateNow();
            let TypeJob;
            let shipId = 0;
            let consId = 0;
            let jobId = 0;
            let bookingData = [];
            ApiService.OperationPostById(paramBookingConf, bookId)
                .then((response) => {
                    if (response.data.code === 200) {
                        bookingData = response.data.data.bookingConfirmation;
                        jobId = response.data.data.bookingConfirmation.jobTypeId;
                        GetJobType(response.data.data.bookingConfirmation.jobTypeId);
                        setIdJobType(response.data.data.bookingConfirmation.jobTypeId);
                        getDataCompany(bookingData.branchId, bookingData.freight);
                    }
                    else {
                        Back();
                    }
                })
                .then(() => {
                    setJobTypeCode(bookingData.jobTypeCode);
                    setPrincipleBy(bookingData.jobOwnerId);

                    //Shipment Order Data Main
                    setSIReference(bookingData.siReference);
                    setTicketId(bookingData.ticketId);
                    setRequester(bookingData.createdBy);

                    setMarketId(bookingData.marketId);
                    setMarketName(bookingData.marketName);

                    setEMKLId(bookingData.emklId);
                    setEMKLName(bookingData.emklName);
                    setDepoId(bookingData.depoId);
                    setDepoName(bookingData.depoName);
                    setBookingConfirmationNumber(bookingData.reference);
                    setBookingConfirmationId(bookingData.id);
                    if (bookingData.shipmentVolume === 'FCL' || bookingData.shipmentVolume === 'LCL') {
                        setLoadStatus(bookingData.shipmentVolume);
                    }

                    if (bookingData.shipmentVolume === 'LCL' && bookingData.serviceType === 'CC') {
                        setTypeOfServiceId(4);
                    }

                    if (bookingData.shipmentVolume === 'LCL') {
                        setJobTypeId('LCL Co-Load');
                    }

                    setTypeJobId(bookingData.jobTypeId);

                    setShipmentStatus(bookingData.shipmentStatusId);
                    handleShipmentStatus(bookingData.shipmentStatusId);

                    //Agent Data
                    setAgentId(bookingData.agentId);
                    setAgentName(bookingData.agentName);

                    //Shipper Data
                    setShipperId(bookingData.shipperId);
                    setShipperName(bookingData.shipperName);
                    setShipperAddress(bookingData.shipperAddress);
                    shipId = bookingData.shipperId;

                    //Consignee Data
                    setConsigneeId(bookingData.consigneeId);
                    setConsigneeName(bookingData.consigneeName);
                    setConsigneeAddress(bookingData.consigneeAddress);

                    setNPartyId(bookingData.consigneeId);
                    setNPartyName(bookingData.consigneeName);
                    setNPartyAddress(bookingData.consigneeAddress);
                    consId = bookingData.consigneeId

                    //Booking Party Data
                    setBookingPartyId(bookingData.bookingPartyId);
                    setBookingPartyName(bookingData.bookingPartyName);
                    setBookingPartyAddress(bookingData.bookingPartyAddress);

                    //Vessel Data
                    setReceiptPlaceId(bookingData.receiptCityId);
                    setReceiptPlaceCode(bookingData.receiptCityCode);
                    setReceiptPlaceName(bookingData.receiptCityName);
                    setTempReceiptPlaceName(bookingData.receiptCityName);

                    setDischargePortId(bookingData.dischargePortId);
                    setDischargePortCode(bookingData.dischargePortCode);
                    setDischargePortName(bookingData.dischargePortName);
                    if (bookingData.dischargePortId !== 0) {
                        setTempDischargePortName(bookingData.dischargePortName);
                    }

                    setLoadingPortId(bookingData.loadingPortId);
                    setLoadingPortCode(bookingData.loadingPortCode);
                    setLoadingPortName(bookingData.loadingPortName);
                    if (bookingData.loadingPortId !== 0) {
                        setTempLoadingPortName(bookingData.loadingPortName);
                    }

                    setDeliveryPlaceId(bookingData.deliveryCityId);
                    setDeliveryPlaceCode(bookingData.deliveryCityCode);
                    setDeliveryPlaceName(bookingData.deliveryCityName);
                    setTempDeliveryPlaceName(bookingData.deliveryCityName);

                    //AIR
                    //////Var departure airport
                    setDepartureAirPortId(bookingData.departureAirportId);
                    setDepartureAirPortCode(bookingData.departureAirPortCode);
                    setDepartureAirPortName(bookingData.departureAirPortName);
                    if (bookingData.departureAirportId !== 0) {
                        setTempLoadingPortName(bookingData.departureAirPortName);
                    }

                    /////Var destination airport
                    setDestinationAirPortId(bookingData.destinationAirportId);
                    setDestinationAirPortCode(bookingData.destinationAirPortCode);
                    setDestinationAirportName(bookingData.destinationAirportName);
                    if (bookingData.destinationAirportId !== 0) {
                        setTempDischargePortName(bookingData.destinationAirportName);
                    }

                    setETA(getDefaultDateFormat(bookingData.eta));

                    setETD(getDefaultDateFormat(bookingData.etd));

                    //Freight Data
                    setSIAgentId(bookingData.agentId);
                    setSIAgentName(bookingData.agentName);

                    //SHIPMENT INSTRUCTION DATA
                    if (bookingData.freight !== 'string') {
                        setFreightOBL(bookingData.freight);
                    }

                    ApiService.PostById(paramRegJobType, jobId)
                        .then((response) => {
                            if (response.data.code === 200) {
                                TypeJob = response.data.data.jobType;

                                if (TypeJob.portType === 'SEA') {
                                    setSSLineId(bookingData.carrierId);
                                    setSSLineName(bookingData.carrierName);
                                }
                                else if (TypeJob.portType === 'AIR') {
                                    setIATAId(bookingData.carrierId);
                                    setIATAName(bookingData.carrierName);
                                }

                                if (TypeJob.serviceType === 'Export') {
                                    //Agent
                                    setDeliveryId(bookingData.agentId);
                                    setDeliveryName(bookingData.agentName);

                                    setTranshipmentId(bookingData.agentId);
                                    setTranshipmentName(bookingData.agentName);

                                    setBLAgentId(bookingData.agentId);
                                    setBLAgentName(bookingData.agentName);

                                    setSIAgentId(bookingData.agentId);
                                    setSIAgentName(bookingData.agentName);

                                    setSIConsigneeId(bookingData.agentId);
                                    setSIConsigneeName(bookingData.agentName);

                                    setSINPartyId(bookingData.agentId);
                                    setSINPartyName(bookingData.agentName);

                                    if (bookingData.shipperId === 0) {
                                        setShipperId(bookingData.customerId);
                                        setShipperName(bookingData.customerName);
                                        setShipperAddress(bookingData.customerAddress);
                                        shipId = bookingData.customerId;

                                        //Shipper
                                        setBLShipperId(bookingData.customerId);
                                        setBLShipperName(bookingData.customerName);
                                        setBLShipperAddress(bookingData.customerAddress);
                                    }
                                    else {
                                        //Shipper
                                        setBLShipperId(bookingData.shipperId);
                                        setBLShipperName(bookingData.shipperName);
                                        setBLShipperAddress(bookingData.shipperAddress);
                                    }

                                    //Consignnee
                                    setBLConsigneeId(bookingData.consigneeId);
                                    setBLConsigneeName(bookingData.consigneeName);
                                    setBLConsigneeAddress(bookingData.consigneeAddress);

                                    setBLNPartyId(bookingData.consigneeId);
                                    setBLNPartyName(bookingData.consigneeName);
                                    setBLNPartyAddress(bookingData.consigneeAddress);
                                }
                                else if (TypeJob.serviceType === 'Import') {
                                    if (bookingData.consigneeId === 0) {
                                        setConsigneeId(bookingData.customerId);
                                        setConsigneeName(bookingData.customerName);
                                        setConsigneeAddress(bookingData.customerAddress);

                                        setNPartyId(bookingData.customerId);
                                        setNPartyName(bookingData.customerName);
                                        setNPartyAddress(bookingData.customerAddress);
                                        consId = bookingData.customerId
                                    }
                                }

                                getDataCreditTerm(response.data.data.jobType, shipId, consId, 0, 0, jobId);

                                setVesselData([]);

                                if (bookingData.vesselId !== 0 && TypeJob.portType !== 'AIR') {
                                    let data = {
                                        "countryId": countryId,
                                        "companyId": companyId,
                                        "branchId": branchId,
                                        "userId": userId,
                                        "userCode": userCode,

                                        "rowStatus": "ACT",
                                        "shipmentId": ShipmentId,
                                        "sequence": 1,
                                        "vesselId": bookingData.vesselId,
                                        "vesselName": bookingData.vesselName,
                                        "vesselType": 'FEEDER',
                                        "voyage": bookingData.voyageNo,
                                        "cityId": 0,
                                        "cityCode": '',
                                        "cityName": '',
                                        "portId": 0,
                                        "portCode": "",
                                        "portName": "",
                                        "flightId": 0,
                                        "flightCode": "",
                                        "flightName": "",
                                        "flightNo": "",
                                        "airportFromId": 0,
                                        "airPortFromCode": "",
                                        "airPortFromName": "",
                                        "airportToId": 0,
                                        "airPortToCode": "",
                                        "airPortToName": "",
                                        "cityFromId": 0,
                                        "cityFromCode": "",
                                        "cityFromName": "",
                                        "cityToId": 0,
                                        "cityToCode": "",
                                        "cityToName": "",
                                        "etd": getDefaultDateFormat(bookingData.etd),
                                        "idLama": 0,
                                    }
                                    let tempArray = [data];
                                    setVesselData(tempArray);
                                }

                                if (TypeJob.portType !== 'AIR') {
                                    if (TypeJob.portType === 'SEA') {
                                        setFreightCollectIdOBL(bookingData.deliveryCityId);
                                        setFreightCollectCodeOBL(bookingData.deliveryCityCode);
                                        setFreightCollectNameOBL(bookingData.deliveryCityName);
                                        setPayableAgentIdOBL(bookingData.agentId);
                                        setPayableAgentNameOBL(bookingData.agentName);
                                    }

                                    setFreightCollectIdHBL(bookingData.deliveryCityId);
                                    setFreightCollectCodeHBL(bookingData.deliveryCityCode);
                                    setFreightCollectNameHBL(bookingData.deliveryCityName);
                                    setPayableConsigneeIdHBL(bookingData.consigneeId);
                                    setPayableConsigneeNameHBL(bookingData.consigneeName);
                                }
                                else {
                                    setMAWBCollectId(bookingData.deliveryCityId);
                                    setMAWBCollectCode(bookingData.deliveryCityCode);
                                    setMAWBCollectName(bookingData.deliveryCityName);
                                    setPayableAgentIdMAWB(bookingData.agentId);
                                    setPayableAgentNameMAWB(bookingData.agentName);

                                    setHAWBCollectId(bookingData.deliveryCityId);
                                    setHAWBCollectCode(bookingData.deliveryCityCode);
                                    setHAWBCollectName(bookingData.deliveryCityName);
                                    setPayableConsigneeIdHAWB(bookingData.consigneeId);
                                    setPayableConsigneeNameHAWB(bookingData.consigneeName);
                                }

                                if (TypeJob.portType === 'SEA') {
                                    // if(bookingData.bookingConfirmationContainers.length > 0)
                                    // {
                                    //     let Sequence = 0;
                                    //     let tempArray = [];

                                    //     getContainerSizeData(tempArray);
                                    //     setContainerData(tempArray);

                                    //     let Array = bookingData.bookingConfirmationContainers;

                                    //     Array.forEach(element => {
                                    //         let tempI = element.amount;

                                    //         for(let i = 1; i <= tempI; i++)
                                    //         {
                                    //             Sequence = Sequence + 1;
                                    //             let data = {
                                    //                 "countryId": countryId,
                                    //                 "companyId": companyId,
                                    //                 "branchId": branchId,
                                    //                 "userId": userId,
                                    //                 "userCode": userCode,

                                    //                 "rowStatus": "ACT",
                                    //                 "shipmentId": props.ShipmentId,
                                    //                 "sequence": Sequence,
                                    //                 "containerNo": 'DUMY 000000-0',
                                    //                 "sealNo": '',
                                    //                 "containerSize": element.containerSize,
                                    //                 "containerTypeId": element.containerTypeId,
                                    //                 "containerTypeCode": element.containerTypeCode,
                                    //                 "containerTypeName": element.containerTypeName,
                                    //                 "grossWeight": parseInt(0),
                                    //                 "netWeight": parseInt(0),
                                    //                 "cbm": parseInt(0),
                                    //                 "partOf": false,
                                    //                 "commodity": '',
                                    //                 "noOfPieces": parseInt(0),
                                    //                 "packagingCode": 'GPL',
                                    //                 "packagingName": 'General Packaging',
                                    //                 "idLama": 0,
                                    //             }

                                    //             tempArray = [...tempArray, data];
                                    //         }
                                    //     });

                                    //     getContainerSizeData(tempArray);
                                    //     setContainerData(tempArray);
                                    //     createCopyContainer(tempArray, true);
                                    // }
                                }

                                if (bookingData.agentId !== 0) {
                                    ApiService.RegContactPostById('regContact/regContact', 1, bookingData.agentId)
                                        .then((res) => {
                                            if (res.data.code === 200) {
                                                setAgentAddress(res.data.data.contact[0].contactAddress);

                                                //Freight Data
                                                setSIAgentAddress(res.data.data.contact[0].contactAddress);

                                                if (TypeJob.serviceType === 'Export') {
                                                    //Agent
                                                    setDeliveryAddress(res.data.data.contact[0].contactAddress);
                                                    setTranshipmentAddress(res.data.data.contact[0].contactAddress);
                                                    setBLAgentAddress(res.data.data.contact[0].contactAddress);
                                                    setSIAgentAddress(res.data.data.contact[0].contactAddress);
                                                    setSIConsigneeAddress(res.data.data.contact[0].contactAddress);
                                                    setSINPartyAddress(res.data.data.contact[0].contactAddress);
                                                }
                                                setIsLoadingBackDrop(false);
                                            }
                                        })
                                        .catch(function (error) {
                                            setIsLoadingBackDrop(false);
                                            // console.error('error saat fetch', error);
                                        })
                                }
                                else {
                                    setIsLoadingBackDrop(false);
                                }
                            }
                        })
                        .catch(function (error) {
                            console.error('error saat fetch', error);
                            setIsLoadingBackDrop(false);
                            showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
                        })
                })
                .catch(function (error) {
                    console.error('error saat fetch', error);
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
                })
        }
    };

    const getDataCompany = (companyId, freight) => {
        ApiService.PostById(paramBranch, companyId)
            .then((response) => {
                if (response.data.code === 200) {
                    setSICompanyId(response.data.data.branch.id);
                    setSICompanyName(response.data.data.branch.name);
                    setSICompanyAddress(response.data.data.branch.address);
                    if (freight === 'P') {
                        getDataCity(response.data.data.branch.cityId);
                    }
                    else {
                        setFreightPayable('DESTINATION');
                    }
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    const getDataCity = (cityId) => {
        ApiService.PostById(paramCity, cityId)
            .then((response) => {
                if (response.data.code === 200) {
                    setFreightPayable(response.data.data.city.name);
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    const GetJobType = (id) => {
        let typeJob;
        let dataId = 1
        if (id === undefined) {
            dataId = sJobTypeId;
        }
        else {
            dataId = id;
        }
        setTypeJobId(dataId);

        ApiService.PostById(paramRegJobType, dataId)
            .then((response) => {
                if (response.data.code === 200) {
                    setJobType(response.data.data.jobType);
                    setIdJobType(response.data.data.jobType.jobTypeId);
                    typeJob = response.data.data.jobType;
                }
                else {
                    Back();
                }
            })
            .then(() => {
                if (!bookingId) {
                    if (shipmentId) {
                        getData(shipmentId, typeJob);
                    }
                    else {
                        getDateNow();
                        handleDocumentStatus('ACT');
                    }
                }
            })
            .catch(function (error) {
                // console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    //Function for Service Type
    const GetServiceTypeData = (pageNumber, pageSize) => {
        ApiService.PostByPage(paramTypeService, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    setServiceTypeData(response.data.data.serviceType);

                    GetContainerStatusData(1, 20);
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    const selectServiceType = (
        <>
            <Selection
                id='0'
                label={'Type of Service'}
                fullWidth={true}
                placeholder={'Select Type of Service'}
                value={typeOfServiceId}
                onChange={(e) => setTypeOfServiceId(e.target.value)}
                selectBaseProps={{ sx: { marginTop: '3px !important' } }}
            >
                {
                    serviceTypeData.map((data, index) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.id}>{data.name}</MenuItem>
                    ))
                }
            </Selection>
        </>
    );

    //Function for Container Status
    const GetContainerStatusData = (pageNumber, pageSize) => {
        ApiService.PostByPage(paramContainerStatus, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    setContainerStatusData(response.data.data.containerStatus);

                    GetJobStatusData(1, 20);
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    const selectContainerStatus = (contData, setContData, title = 'Container Status') => (
        <>
            <Selection
                id='0'
                label={title}
                fullWidth={true}
                placeholder={'Select ' + title}
                value={contData}
                onChange={(e) => setContData(e.target.value)}
                selectBaseProps={{ sx: { marginTop: '3px !important' } }}
            >
                {
                    containerStatusData.map((data, index) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                    ))
                }
            </Selection>
        </>
    );

    //Function for Job Type
    const GetJobStatusData = (pageNumber, pageSize) => {
        ApiService.PostByPage(paramJobStatus, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    setJobStatusData(response.data.data.jobStatus);

                    GetJobOwnerData(1, 20);
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    const selectJobType = (
        <>
            <Selection
                id='0'
                label={'Job Type'}
                fullWidth={true}
                placeholder={'Select Job Type'}
                value={JobTypeId}
                onChange={(e) => setJobTypeId(e.target.value)}
                selectBaseProps={{ sx: { marginTop: '3px !important' } }}
            >
                {
                    jobStatusData.map((data, index) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.name}>{data.name}</MenuItem>
                    ))
                }
            </Selection>
        </>
    );

    //Function for Principle By
    const GetJobOwnerData = (pageNumber, pageSize) => {
        ApiService.PostByPage(paramJobOwner, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    if (response.data.data.jobOwner.length > 0) {
                        setPrincipleBy(response.data.data.jobOwner[0].id);
                    }
                    setJobOwnerData(response.data.data.jobOwner);

                    GetShipmentStatusData(1, 20);
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    const selectPrincipleBy = (isDisabled = false) => (
        <>
            <Selection
                id='0'
                label={'Principle By'}
                fullWidth={true}
                placeholder={'Select Principle By'}
                value={principleBy}
                onChange={(e) => setPrincipleBy(e.target.value)}
                selectBaseProps={{ sx: { marginTop: '3px !important' } }}
                disabled={isDisabled}
            >
                {
                    jobOwnerData.map((data, index) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.id}>{data.masterCode}</MenuItem>
                    ))
                }
            </Selection>
        </>
    );

    //Function for Shipment Status
    const GetShipmentStatusData = (pageNumber, pageSize) => {
        ApiService.PostByPage(paramShipmentStatus, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    setShipmentStatusData(response.data.data.shipmentStatus);

                    GetContainerTypeData(1, 20);
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    const selectShipmentStatus = (
        <>
            <Selection
                id='0'
                label={'Shipment Status'}
                fullWidth={true}
                placeholder={'Select Shipment Status'}
                value={ShipmentStatus}
                onChange={(e) => handleShipmentStatus(e.target.value)}
                selectBaseProps={{ sx: { marginTop: '3px !important' } }}
            >
                {
                    shipmentStatusData.map((data, index) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.id}>{data.name}</MenuItem>
                    ))
                }
            </Selection>
        </>
    );

    function handleShipmentStatus(val) {
        setShipmentStatus(val);
        if (val === 1) {
            setIsMarketing(false);
        }
        else {
            setMarketId(0);
            setMarketName('');
            setIsMarketing(true);
        }
    }

    //Function for Container Type
    const GetContainerTypeData = (pageNumber, pageSize) => {
        ApiService.PostByPage(paramContainerType, pageNumber, pageSize)
            .then((response) => {
                if (response.status === 200) {
                    setContainerTypeData(response.data.data.containerType);

                    GetPackagingTypeData(1, 20);
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    const selectContainerType = (contData, setContData, setContainerCode, setContainerName) => (
        <>
            <Selection
                id='0'
                label={'Container Type'}
                fullWidth={true}
                placeholder={'Select Container Type'}
                value={contData}
                onChange={(e) => getDataContainerType(e.target.value, setContData, setContainerCode, setContainerName)}
                selectBaseProps={{ sx: { marginTop: '3px !important' } }}
            >
                {
                    containerTypeData.map((data, index) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.id}>{data.code} ({data.name})</MenuItem>
                    ))
                }
            </Selection>
        </>
    );

    function getDataContainerType(id, setContData, setContainerCode, setContainerName) {
        setContData(id);
        let paramContainerType = 'containerType/containerType';
        ApiService.PostById(paramContainerType, id)
            .then((response) => {
                if (response.status === 200) {
                    setContainerCode(response.data.data.containerType.code);
                    setContainerName(response.data.data.containerType.name);
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    //Function for Packaging Type
    const GetPackagingTypeData = (pageNumber, pageSize) => {
        ApiService.PostByPage(paramPackagingType, pageNumber, pageSize)
            .then((response) => {
                if (response.status === 200) {
                    setPackagingTypeData(response.data.data.packagingType);

                    GetCurrencyData(1, 300);
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    const selectPackagingType = (packData, setPackData, setPackagingName, title = 'Packaging') => (
        <>
            <Selection
                id='0'
                label={title}
                fullWidth={true}
                placeholder={'Select ' + title}
                value={packData}
                onChange={(e) => getDataPackagingName(e.target.value, setPackData, setPackagingName)}
                selectBaseProps={{ sx: { marginTop: '3px !important' } }}
            >
                {
                    packagingTypeData.map((data, index) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.code} - {data.name}</MenuItem>
                    ))
                }
            </Selection>
        </>
    );

    function getDataPackagingName(code, setPackData, setPackagingName) {
        setPackData(code);
        packagingTypeData.forEach(element => {
            if (element.code === code) {
                setPackagingName(element.name);
            }
        });
    };

    //Function for Currency
    const GetCurrencyData = (pageNumber, pageSize) => {
        ApiService.CurrencyPostBySearch(paramCountry, pageNumber, pageSize)
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data.data.country;
                    data = data.filter(el => el.currency !== 'IDR');
                    setCurrencyData(data);

                    if (dataLocal) {
                        setIsLoadingBackDrop(true);
                        setTimeout(function () { getDataLocal(); }, 2000);
                    }
                    else {
                        if (bookingId) {
                            GetBookingData(bookingId);
                        }
                        else {
                            GetJobType(sJobTypeId);
                        }
                    }
                }
                else {
                    Back();
                }

                if (!dataLocal) {
                    setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    const selectCurrency = (curData, setCurData, title) => (
        <>
            <Selection
                id='0'
                label={title}
                fullWidth={true}
                placeholder={'Select ' + title}
                value={curData}
                onChange={(e) => setCurData(e.target.value)}
                selectBaseProps={{ sx: { marginTop: '3px !important' } }}
            >
                {
                    currencyData.map((data, index) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.currency}>{data.currency}</MenuItem>
                    ))
                }
            </Selection>
        </>
    );

    //Function for Tracking Status
    const GetTrackingStatusData = (pageNumber, pageSize) => {
        ApiService.PostByPage(paramTrackingStatus, pageNumber, pageSize)
            .then((response) => {
                if (response.status === 200) {
                    setTrackingStatusData(response.data.data.trackingStatus);

                    GetServiceTypeData(1, 20);
                    checkCurrentPeriod();
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    const selectTrackingStatus = (trackData, setTrackData, setStatusName) => (
        <>
            <Selection
                id='0'
                fullWidth={true}
                placeholder={'Select Data'}
                value={trackData}
                onChange={(e) => getDataTrackingStatus(e.target.value, setTrackData, setStatusName)}
                selectBaseProps={{ sx: { marginTop: '3px !important' } }}
            >
                {
                    trackingStatusData.map((data, index) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.id}>{data.name}</MenuItem>
                    ))
                }
            </Selection>
        </>
    );

    function getDataTrackingStatus(id, setTrackData, setStatusName) {
        setTrackData(id);
        let paramTrackingStatus = 'trackingStatus/trackingStatus';
        ApiService.PostById(paramTrackingStatus, id)
            .then((response) => {
                if (response.status === 200) {
                    setStatusName(response.data.data.trackingStatus.name);
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    const checkCurrentPeriod = () => {
        let date = new Date();
        ApiService.RegClosingPeriodPostById(paramRegClosingPeriod, getDefaultDateFormat(new Date()))
            .then((response) => {
                if (response.data.code === 200) {
                    let dataP = response.data.data.closingPeriod;
                    setPeriod(dataP.period);
                    setYearPeriod(dataP.yearPeriod);

                    let monthNumber = date.getMonth() + 1;
                    let yearNumber = date.getFullYear();
                    if (dataP.yearPeriod === yearNumber) {
                        if (dataP.period < monthNumber) {
                            setIsClosed(true);
                        }
                        else {
                            let closeDate = new Date(dataP.closingDate).getDate();
                            let dateNow = date.getDate();
                            if (dateNow < closeDate) {
                                setIsClosed(false);
                            }
                            else {
                                setIsClosed(true);
                            }
                        }
                    }
                    else {
                        setIsClosed(true);
                    }
                }
            })
            .catch((error) => {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    }

    function calculateTypeVessel(result, data) {
        for (let i = 0; i < result.length; i++) {
            if (result[i].vesselType === 'FEEDER' && result[i].vesselType === data) {
                return 1;
            }
            else if (result[i].vesselType === 'MOTHER' && result[i].vesselType === data) {
                return 2;
            }
        }

        return 0;
    }

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        }
        else {
            setIsModal(false);
        }
    };

    const handleClick2 = () => {
        if (isModal2 === false) {
            setIsModal2(true);
        }
        else {
            setIsModal2(false);
        }
    }

    const getDateExchRate = () => {
        ApiService.PostByExRateDate()
            .then((response) => {
                if (response.data.code === 200) {
                    setExRateId(response.data.data.exchangeRate.id);
                    setRateReal(response.data.data.exchangeRate.exRate1);
                    setDateFormat(response.data.data.exchangeRate.exRateDate, setExRateDate);
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    }

    const handlePrintGeneral = (printCode) => {
        Save(false, true, printCode)
    }

    function Save(isSubJob, isPrint, printCode) {
        if (isLoadingBackDrop) return
        setIsLoadingBackDrop(true);

        let id = 0;

        let mktId = 0;
        let mktName = '';
        let cmpyId = 0;

        let consigneeId = 0;

        let blAgentId = 0;
        let blShipperId = 0;
        let blConsigneeId = 0;
        let blNPartyId = 0;

        let siConsignneId = 0;
        let siNPartyId = 0;

        let BillToSId = 0;
        let BillToSN = '';
        let BillToCId = 0;
        let BillToCN = '';

        let containerSt = '';
        let freightSt = '';

        let atd = ATD !== '' ? ATD : defaultDate;
        let ata = ATA !== '' ? ATA : defaultDate;

        let fCIOBL = 0;
        let fCCOBL = '';
        let fCNOBL = '';
        let pAIOBL = 0;
        let pANOBL = '';

        let fCIHBL = 0;
        let fCCHBL = '';
        let fCNHBL = '';
        let pCIHBL = 0;
        let pCNHBL = '';

        let fCIMAWB = 0;
        let fCCMAWB = '';
        let fCNMAWB = '';
        let pAIMAWB = 0;
        let pANMAWB = '';

        let fCIHAWB = 0;
        let fCCHAWB = '';
        let fCNHAWB = '';
        let pCIHAWB = 0;
        let pCNHAWB = '';

        let pODId = 0;
        let poDC = '';
        let poDN = '';

        let poLId = 0;
        let poLC = '';
        let pOLN = '';

        let aPoDId = 0;
        let aPoDC = '';
        let aPoDN = '';

        let aPoDsId = 0;
        let aPoDsC = '';
        let aPoDsN = '';

        let cAAId = 0;
        let cAAName = '';
        let cAAAddress = '';

        let mawbNoSet = '';
        let hawbNoSet = '';

        let printFxBoL = printFixedDate !== '' ? printFixedDate : defaultDate;
        let printDfBoL = printDraftDate !== '' ? printDraftDate : defaultDate;
        let printSI = datePrint !== '' ? datePrint : defaultDate;

        let printAgent = PrintToAgentOnSA !== '' ? PrintToAgentOnSA : defaultDate;
        let printDel = PrintToDeliveryOnSA !== '' ? PrintToDeliveryOnSA : defaultDate;

        let printTR = TRPrintDate !== '' ? TRPrintDate : defaultDate;

        let deletedOnTR = DeletedOnTR !== '' ? DeletedOnTR : defaultDate;
        let deletedOnSA = DeletedOnSA !== '' ? DeletedOnSA : defaultDate;
        let deletedOnSI = DeletedOnSI !== '' ? DeletedOnSI : defaultDate;
        let deletedOnBoL = DeletedOnBoL !== '' ? DeletedOnBoL : defaultDate;
        let placeOfDate = placeDateIssue !== '' ? placeDateIssue : defaultDate;
        let shipOnBoard = shipmentOnBoard !== '' ? shipmentOnBoard : defaultDate;
        let approvedCreditOn = ApprovedCreditOn !== '' ? ApprovedCreditOn : defaultDate;
        let deletedOn = DeletedOn !== '' ? DeletedOn : defaultDate;
        let dateGoodRec = GoodsRecDate !== '' ? GoodsRecDate : defaultDate;
        let jobClosed = JobClosedOn !== '' ? JobClosedOn : defaultDate;

        let firstVessel = '';
        let secondVessel = '';

        let routingData = [];
        let trackingData = [];

        let billOfLading = {};
        let shippingInstruction = {};
        let shipmentAdvice = {};
        let telexRelease = {};

        let subJob = false;

        if (shipmentId) {
            id = parseInt(shipmentId);
        }

        if (isSubJob === true) {
            subJob = isSubJob;
        }
        else {
            subJob = false;
        }

        if (jobType.serviceType === 'Export') {
            BillToSId = billToShipperId;
            BillToSN = billShipperName;
        }
        else if (jobType.serviceType === 'Import') {
            BillToCId = billToConsigneeId;
            BillToCN = billConsigneeName;
        }

        if (jobType.portType !== 'AIR') {
            containerSt = ContainerStatus;
        }
        else {
            freightSt = FreightStatus;
        }

        if (freightStatusOBL === 'C') {
            fCIOBL = freightCollectIdOBL;
            fCCOBL = freightCollectCodeOBL;
            fCNOBL = freightCollectNameOBL;
            pAIOBL = payableAgentIdOBL;
            pANOBL = payableAgentNameOBL;
        }

        if (freightStatusHBL === 'C') {
            fCIHBL = freightCollectIdHBL;
            fCCHBL = freightCollectCodeHBL;
            fCNHBL = freightCollectNameHBL;
            pCIHBL = payableConsigneeIdHBL;
            pCNHBL = payableConsigneeNameHBL;
        }

        if (MAWBStatus === 'C') {
            fCIMAWB = MAWBCollectId;
            fCCMAWB = MAWBCollectCode;
            fCNMAWB = MAWBCollectName;
            pAIMAWB = payableAgentIdMAWB;
            pANMAWB = payableAgentNameMAWB;
        }

        if (HAWBStatus === 'C') {
            fCIHAWB = HAWBCollectId;
            fCCHAWB = HAWBCollectCode;
            fCNHAWB = HAWBCollectName;
            pCIHAWB = payableConsigneeIdHAWB;
            pCNHAWB = payableConsigneeNameHAWB;
        }

        if (parseInt(ShipmentStatus) === 1) {
            mktId = MarketId;
            mktName = MarketName;
            cmpyId = MarketCompanyId;
        }

        if (freightOBL === 'A') {
            cAAId = SICollectAgentId;
            cAAName = SICollectAgentName;
            cAAAddress = SICollectAgentAddress;
        }

        if (jobType.portType === 'SEA') {
            pODId = DischargePortId;
            poDC = DischargePortCode;
            poDN = DischargePortName;

            poLId = LoadingPortId;
            poLC = LoadingPortCode;
            pOLN = LoadingPortName;
        }
        else if (jobType.portType === 'AIR') {
            aPoDId = DepartureAirPortId;
            aPoDC = DepartureAirPortCode;
            aPoDN = DepartureAirPortName;

            aPoDsId = DestinationAirPortId;
            aPoDsC = DestinationAirPortCode;
            aPoDsN = DestinationAirportName;
        }

        if (jobType.portType === 'AIR') {
            hawbNoSet = HAWBNo;
            mawbNoSet = MAWBNo;
        }

        if (jobType.portType === 'SEA') {
            let check = 0;

            routingData = vesselData;
            trackingData = dataTracking;

            const firstFunction = (data) => {
                return data.vesselType === 'FEEDER';
            }
            const tempArray1 = vesselData.filter(firstFunction);
            if (tempArray1.length > 0) {
                firstVessel = tempArray1[0].vesselName + ' V.' + tempArray1[0].voyage;
            }

            vesselData.forEach(element => {
                if (check === 0) {
                    if (element.vesselType === 'CONNECTING') {
                        secondVessel = element.vesselName + ' V.' + element.voyage;
                        check = 1;
                    }
                }
            });

            if (check === 0) {
                const secondFunction = (data) => {
                    return data.vesselType === 'MOTHER';
                }
                const tempArray2 = vesselData.filter(secondFunction);
                if (tempArray2.length > 0) {
                    secondVessel = tempArray2[0].vesselName + ' V.' + tempArray2[0].voyage;
                }
            }
        }
        else if (jobType.portType === 'AIR') {
            routingData = flightData;
            trackingData = dataTracking;
        }
        else {
            let vesId = 0;
            let vesN = '';
            let voy = '';
            let fliId = 0;
            let fliC = '';
            let fliNa = '';
            let fliNo = '';

            if (VesselFlight !== 'N') {
                if (VesselFlight === 'V') {
                    vesId = VesselId;
                    vesN = VesselName;
                    voy = Voyage;
                }
                else if (VesselFlight === 'F') {
                    fliId = FlightId;
                    fliC = FlightCode;
                    fliNa = FlightName;
                    fliNo = FlightNo;
                }

                let data = {
                    "countryId": countryId,
                    "companyId": companyId,
                    "branchId": branchId,
                    "user": userCode,

                    "rowStatus": RowStatus,
                    "shipmentId": ShipmentId,
                    "sequence": 1,
                    "vesselId": vesId,
                    "vesselName": vesN,
                    "vesselType": '',
                    "voyage": voy,
                    "cityId": cityId,
                    "cityCode": '',
                    "cityName": '',
                    "portId": PortId,
                    "portCode": PortCode,
                    "portName": PortName,
                    "flightId": fliId,
                    "flightCode": fliC,
                    "flightName": fliNa,
                    "flightNo": fliNo,
                    "airportFromId": 0,
                    "airPortFromCode": "",
                    "airPortFromName": "",
                    "airportToId": 0,
                    "airPortToCode": "",
                    "airPortToName": "",
                    "cityFromId": 0,
                    "cityFromCode": "",
                    "cityFromName": "",
                    "cityToId": 0,
                    "cityToCode": "",
                    "cityToName": "",
                    "etd": defaultDate,
                    "idLama": 0,
                };

                routingData = [data];

                let check = 0;

                for (let i = 0; i < dataTracking.length; i++) {
                    if ((VesselFlight === 'F' && dataTracking[i].vessel !== '')
                        || (VesselFlight === 'V' && dataTracking[i].flight !== '')) {
                        let tempSequence = dataTracking[i].sequence;

                        const deleteFunction = (dataCost) => {
                            return dataCost.sequence !== tempSequence;
                        }

                        const result = dataTracking.filter(deleteFunction);

                        if (result.length > 0) {
                            tempSequence = 0;

                            result.forEach((el) => {
                                tempSequence += 1;
                                el.sequence = tempSequence;
                            })
                            trackingData = result;
                        }

                        check = 1;
                    }
                }

                if (check === 0) {
                    trackingData = dataTracking;
                }
            }
        }

        let formatTrackingData = trackingData;

        formatTrackingData.forEach(element => {
            var StUpd = getDefaultDateFormat(element.statusUpdateOn);

            if (StUpd === nullDate) {
                element.statusUpdateOn = defaultDate;
            }
        });

        trackingData = formatTrackingData;

        if (jobType.serviceType === 'Export') {
            if (ConsigneeName === TempConsigneeName && ConsigneeAddress === TempConsigneeAddress) {
                consigneeId = ConsigneeId;
            }
            else if (ConsigneeName !== TempConsigneeName || ConsigneeAddress !== TempConsigneeAddress) {
                consigneeId = 0;
            }

            if (getDefaultDateFormat(printFxBoL) === nullDate || getDefaultDateFormat(printFxBoL) === '') {
                printFxBoL = defaultDate;
            }

            if (getDefaultDateFormat(printDfBoL) === nullDate || getDefaultDateFormat(printDfBoL) === '') {
                printDfBoL = defaultDate;
            }

            if (getDefaultDateFormat(deletedOnBoL) === nullDate || getDefaultDateFormat(deletedOnBoL) === '') {
                deletedOnBoL = defaultDate;
            }

            if (getDefaultDateFormat(placeOfDate) === nullDate || getDefaultDateFormat(placeOfDate) === '') {
                placeOfDate = defaultDate;
            }

            if (getDefaultDateFormat(shipOnBoard) === nullDate || getDefaultDateFormat(shipOnBoard) === '') {
                shipOnBoard = defaultDate;
            }

            billOfLading = {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "rowStatus": RowStatus,
                "shipmentId": id,

                "blNumber": BLNumber,
                "masterBLId": BLFormId,
                "masterBLName": BLFormName,
                "noOfBL": noBLData,
                "placeDateOfIssue": placeOfDate,

                "agentId": blAgentId,
                "agentName": BLAgentName,
                "agentAddress": BLAgentAddress,

                "shipperId": blShipperId,
                "shipperName": BLShipperName,
                "shipperAddress": BLShipperAddress,

                "consigneeId": blConsigneeId,
                "consigneeName": BLConsigneeName,
                "consigneeAddress": BLConsigneeAddress,

                "nPartyId": blNPartyId,
                "nPartyName": BLNPartyName,
                "nPartyAddress": BLNPartyAddress,

                "snPartyId": BLSNPartyId,
                "snPartyName": BLSNPartyName,
                "snPartyAddress": BLSNPartyAddress,

                "receiptPlaceName": BLReceiptPlaceName,
                "dischargePortName": BLDischargePortName,
                "loadingPortName": BLLoadingPortName,
                "deliveryPlaceName": BLDeliveryPlaceName,

                "shipmentOnBoard": shipOnBoard,
                "totalNoOfContainer": totalNumberCont,

                "hawbFee": HAWBFee,
                "amountInsurance": AmountInsurance,

                "cargoInsurance": cargoInsurance,
                "freightAmount": freightAmount,
                "freightPayableAt": freightPayable,
                "descriptions": descriptionBoL,
                "printDraft": printDraft,
                "printFixed": printFixed,
                "printFixedOn": printFxBoL,
                "firstPrintFixedOn": printDfBoL,
                "deleted": DeletedBoL,
                "deletedOn": deletedOnBoL,

                "idLama": 0,
            };

            if (getDefaultDateFormat(printSI) === nullDate || getDefaultDateFormat(printSI) === '') {
                printSI = defaultDate;
            }

            if (getDefaultDateFormat(deletedOnSI) === nullDate || getDefaultDateFormat(deletedOnSI) === '') {
                deletedOnSI = defaultDate;
            }

            shippingInstruction = {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "rowStatus": RowStatus,
                "shipmentId": id,

                "siReference": Reference,
                "attention": Attention,

                "shipperId": SICompanyId,
                "shipperName": SICompanyName,
                "shipperAddress": SICompanyAddress,

                "consigneeId": siConsignneId,
                "consigneeName": SIConsigneeName,
                "consigneeAddress": SIConsigneeAddress,

                "nPartyId": siNPartyId,
                "nPartyName": SINPartyName,
                "nPartyAddress": SINPartyAddress,

                "goodDescription": SIGoodDesc,
                "shippingMarksDesc": ShippingMarksDesc,
                "noPackageDesc": NoPackageDesc,
                "descriptionOfGoods": SIGoodDesc,
                "weightMeaDesc": WeightMeaDesc,

                "piecesRCP": SIPiecesRCP,
                "grossWeight2": SIGrossWeight,
                "kglb": SIKGLB,
                "chargeWeight": SIChargeWeight,
                "chargeRate": SIChargeRate,
                "total": SITotal,
                "goodNatureQuantity": SIGoodNatureQ,
                "commodity": SICommodity,
                "packagingCode": SIPackageCode,
                "shippingMark": SIShipmark,

                "specialInstruction": SISpecialIns,
                "originalBL": SIOriginalBL,
                "freightAgreed": freightOBL,
                "collectAt": cAAId,
                "collectName": cAAName,
                "collectAddress": cAAAddress,

                "updated": SIUpdatedCount,
                "printing": PrintingUpdatedCount,
                "printedOn": printSI,
                "deleted": DeletedSI,
                "deletedOn": deletedOnSI,

                "idLama": 0,
            };

            if (getDefaultDateFormat(printTR) === nullDate || getDefaultDateFormat(printTR) === '') {
                printTR = defaultDate;
            }

            if (getDefaultDateFormat(deletedOnTR) === nullDate || getDefaultDateFormat(deletedOnTR) === '') {
                deletedOnTR = defaultDate;
            }

            telexRelease = {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "rowStatus": RowStatus,
                "shipmentId": id,

                "original": TRFullSetStatus,
                "seaWaybill": TRReleaseStatus,
                "printing": printCode === 'TLX' ? TRPrintCount + 1 : TRPrintCount,
                "printedOn": printTR,
                "deleted": DeletedTR,
                "deletedOn": deletedOnTR,

                "idLama": 0,
            };

            if (jobType.portType !== 'AIR') {
                if (getDefaultDateFormat(printDel) === nullDate || getDefaultDateFormat(printDel) === '') {
                    printDel = defaultDate;
                }

                if (getDefaultDateFormat(printAgent) === nullDate || getDefaultDateFormat(printAgent) === '') {
                    printAgent = defaultDate;
                }

                if (getDefaultDateFormat(deletedOnSA) === nullDate || getDefaultDateFormat(deletedOnSA) === '') {
                    deletedOnSA = defaultDate;
                }

                shipmentAdvice = {
                    "countryId": countryId,
                    "companyId": companyId,
                    "branchId": branchId,
                    "userId": userId,
                    "userCode": userCode,

                    "rowStatus": RowStatus,
                    "shipmentId": id,

                    "reference": SAReference,
                    "remarks": SARemarks,

                    "printToAgentId": SAPrintAgentId,
                    "printToAgentName": SAPrintAgentName,

                    "printToDeliveryId": SAPrintDeliveryId,
                    "printToDeliveryName": SAPrintDeliveryName,

                    "printToAgentOn": printAgent,
                    "printToDeliveryOn": printDel,
                    "deleted": DeletedSA,
                    "deletedOn": deletedOnSA,

                    "idLama": 0,
                };
            }

            //BL Form
            if (BLAgentName === AgentName && BLAgentAddress === AgentAddress) {
                blAgentId = AgentId;
            }
            else if (BLAgentName !== AgentName || BLAgentAddress !== AgentAddress) {
                blAgentId = 0;
            }

            if (BLShipperName === ShipperName && BLShipperAddress === ShipperAddress) {
                blShipperId = ShipperId;
            }
            else if (BLShipperName !== ShipperName || BLShipperAddress !== ShipperAddress) {
                blShipperId = 0;
            }

            if (BLConsigneeName === ConsigneeName && BLConsigneeAddress === ConsigneeAddress && consigneeId !== 0) {
                blConsigneeId = consigneeId;
            }
            else if (BLConsigneeName !== ConsigneeName || BLConsigneeAddress !== ConsigneeAddress || consigneeId === 0) {
                blConsigneeId = 0;
            }

            if (BLNPartyName === NPartyName && BLNPartyAddress === NPartyAddress) {
                blNPartyId = NPartyId;
            }
            else if (BLNPartyName !== NPartyName || BLNPartyAddress !== NPartyAddress) {
                blNPartyId = 0;
            }

            //SI Form
            if (SIConsigneeName === AgentName && SIConsigneeAddress === AgentAddress) {
                siConsignneId = AgentId;
            }
            else if (SIConsigneeName !== AgentName || SIConsigneeAddress !== AgentAddress) {
                siConsignneId = 0;
            }

            if (SINPartyName === AgentName && SINPartyAddress === AgentAddress) {
                siNPartyId = AgentId;
            }
            else if (SINPartyName !== AgentName || SINPartyAddress !== AgentAddress) {
                siNPartyId = 0;
            }
        }
        else {
            consigneeId = ConsigneeId;
        }

        var data = {
            "shipmentOrder":
            {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "shipmentNo": ShipmentNo,
                "rowStatus": RowStatus,
                "id": id,
                "ticketId": TicketId,
                "jobNumber": JobNumber,
                "subJobNo": SubJobNo,
                "jobTypeId": parseInt(IdJobType),
                "jobTypeCode": JobTypeCode,

                "jobOwnerId": principleBy,
                "totalSub": TotalSub,
                "siReference": SIReference,
                "siDate": SIDate,
                "loadStatus": LoadStatus,
                "shipmentStatusId": parseInt(ShipmentStatus),
                "serviceNoId": parseInt(typeOfServiceId),
                "marketId": mktId,
                "marketName": mktName,
                "marketCompanyId": cmpyId,
                "jobStatus": JobTypeId,
                "goodsRecDate": dateGoodRec,
                "bookingConfirmationId": BookingConfirmationId,
                "bookingNo": BookingConfirmationNumber,
                "shipmentCategory": jobCategory,

                "oceanMSTBLNo": OceanMSTBLNo,
                "volumeBL": parseFloat(VolumeBL),
                "volumeInvoice": parseFloat(VolumeInvoice),

                "emklId": EMKLId,
                "emklName": EMKLName,
                "ssLineId": SSLineId,
                "ssLineName": SSLineName,
                "depoId": DepoId,
                "depoName": DepoName,

                "vesselFlight": VesselFlight,
                "truck": Truck,

                //SEA
                "containerStatus": containerSt,
                //AIR
                "freightStatus": freightSt,

                //SEA IMPORT
                "conversion": Conversion,
                "quotationNo": QuotationNo,

                //SEA IMPORT
                "houseBLNo": HouseBLNo,
                "secondBLNo": SecondBLNo,
                "wareHouseName": WareHouseName,
                "kins": Kins,
                "cfName": CFName,

                //Agent Data
                "agentId": AgentId,
                "agentName": AgentName,
                "agentAddress": AgentAddress,

                //Delivery Data
                "deliveryId": DeliveryId,
                "deliveryName": DeliveryName,
                "deliveryAddress": DeliveryAddress,

                //Transhipment Data
                "transhipmentId": TranshipmentId,
                "transhipmentName": TranshipmentName,
                "transhipmentAddress": TranshipmentAddress,

                //Shipper Data
                "shipperId": ShipperId,
                "shipperName": ShipperName,
                "shipperAddress": ShipperAddress,

                //EXPORT
                "billToShipperId": BillToSId,
                "billToShipperName": BillToSN,

                //Consignee Data
                "consigneeId": consigneeId,
                "consigneeName": ConsigneeName,
                "consigneeAddress": ConsigneeAddress,

                //IMPORT
                "billToConsigneeId": BillToCId,
                "billToConsigneeName": BillToCN,

                "freightAmountInIDR": convertToNumeric(freightAmountIDR),
                "freightAmountInUSD": convertToNumeric(freightAmountUSD),
                "approvedCredit": ApprovedCredit,

                //NParty Data
                "nPartyId": NPartyId,
                "nPartyName": NPartyName,
                "nPartyAddress": NPartyAddress,

                //Booking Party Data
                "bookingPartyId": BookingPartyId,
                "bookingPartyName": BookingPartyName,
                "bookingPartyAddress": BookingPartyAddress,

                //Vessel Tab Data
                "receiptPlaceId": ReceiptPlaceName && TempReceiptPlaceName && ReceiptPlaceName.toLowerCase().includes(TempReceiptPlaceName.toLowerCase()) ? ReceiptPlaceId : 0,
                "receiptPlaceCode": ReceiptPlaceName && TempReceiptPlaceName && ReceiptPlaceName.toLowerCase().includes(TempReceiptPlaceName.toLowerCase()) ? ReceiptPlaceCode : '',
                "receiptPlaceName": ReceiptPlaceName,

                "deliveryPlaceId": DeliveryPlaceName && TempDeliveryPlaceName && DeliveryPlaceName.toLowerCase().includes(TempDeliveryPlaceName.toLowerCase()) ? DeliveryPlaceId : 0,
                "deliveryPlaceCode": DeliveryPlaceName && TempDeliveryPlaceName && DeliveryPlaceName.toLowerCase().includes(TempDeliveryPlaceName.toLowerCase()) ? DeliveryPlaceCode : '',
                "deliveryPlaceName": DeliveryPlaceName,

                "firstVessel": firstVessel,
                "secondVessel": secondVessel,

                "etd": ETD,
                "eta": ETA,
                "atd": atd,
                "ata": ata,

                //SEA
                "loadingPortId": pOLN && TempLoadingPortName && pOLN.toLowerCase().includes(TempLoadingPortName.toLowerCase()) ? poLId : 0,
                "loadingPortCode": pOLN && TempLoadingPortName && pOLN.toLowerCase().includes(TempLoadingPortName.toLowerCase()) ? poLC : '',
                "loadingPortName": pOLN,

                "dischargePortId": poDN && TempDischargePortName && poDN.toLowerCase().includes(TempDischargePortName.toLowerCase()) ? pODId : 0,
                "dischargePortCode": poDN && TempDischargePortName && poDN.toLowerCase().includes(TempDischargePortName.toLowerCase()) ? poDC : '',
                "dischargePortName": poDN,

                //AIR
                "departureAirPortId": aPoDN && TempLoadingPortName && aPoDN.toLowerCase().includes(TempLoadingPortName.toLowerCase()) ? aPoDId : 0,
                "departureAirPortCode": aPoDN && TempLoadingPortName && aPoDN.toLowerCase().includes(TempLoadingPortName.toLowerCase()) ? aPoDC : '',
                "departureAirPortName": aPoDN,

                "destinationAirPortId": aPoDsN && TempDischargePortName && aPoDsN.toLowerCase().includes(TempDischargePortName.toLowerCase()) ? aPoDsId : 0,
                "destinationAirPortCode": aPoDsN && TempDischargePortName && aPoDsN.toLowerCase().includes(TempDischargePortName.toLowerCase()) ? aPoDsC : '',
                "destinationAirportName": aPoDsN,

                //Freight Tab Data
                //SEA
                "oblStatus": freightStatusOBL,
                "oblCollectId": fCIOBL,
                "oblCollectCode": fCCOBL,
                "oblCollectName": fCNOBL,
                "oblPayableId": pAIOBL,
                "oblPayableName": pANOBL,

                "hblStatus": freightStatusHBL,
                "hblCollectId": fCIHBL,
                "hblCollectCode": fCCHBL,
                "hblCollectName": fCNHBL,
                "hblPayableId": pCIHBL,
                "hblPayableName": pCNHBL,

                //SEA IMPORT
                "oblCurrency": OBLCurrency,
                "oblAmount": OBLAmount,

                "hblCurrency": HBLCurrency,
                "hblAmount": HBLAmount,

                //AIR
                "mawbStatus": MAWBStatus,
                "mawbCollectId": fCIMAWB,
                "mawbCollectCode": fCCMAWB,
                "mawbCollectName": fCNMAWB,
                "mawbPayableId": pAIMAWB,
                "mawbPayableName": pANMAWB,

                "hawbStatus": HAWBStatus,
                "hawbCollectId": fCIHAWB,
                "hawbCollectCode": fCCHAWB,
                "hawbCollectName": fCNHAWB,
                "hawbPayableId": pCIHAWB,
                "hawbPayableName": pCNHAWB,

                //AIR IMPORT
                "mawbRate": parseInt(MAWBRate),
                "mawbCurrency": MAWBCurrency,

                "hawbRate": parseInt(HAWBRate),
                "hawbCurrency": HAWBCurrency,

                "currency": currency,
                "handlingInfo": handlingInf,

                //Description Tab Data
                //SEA
                "goodDescription": GoodDescription,
                "markNumberDescription": MarkNumberDescription,
                "numberTypeDescription": NumberTypeDescription,
                "descriptionOfGoods": GoodDescription,
                "grossWeightDescription": GrossWeightDescription,
                "measurementDescription": MeasurementDescription,

                //AIR
                "piecesRCP": PiecesRCP,
                "grossWeight": GrossWeight,
                "kglb": KGLB,
                "chargeWeight": ChargeWeight,
                "chargeRate": ChargeRate,
                "total": Total,
                "commodity": Commodity,
                "packagingCode": PackagingCode,
                "goodNatureQuantity": GoodNatureQuantity,
                "shipmark": Shipmark,

                //Master Airway Bill Tab Data
                "mawbNo": mawbNoSet,
                "chargeableWeight": parseInt(ChargeableWeight),
                "weightHAWB": parseInt(WeightHAWB),
                "carriageValue": CarriageValue,
                "customValue": CustomValue,
                "iataId": IATAId,
                "iataName": IATAName,
                "brokerId": BrokerId,
                "brokerName": BrokerName,

                //AIR IMPORT
                "hawbNo": hawbNoSet,

                "jobClosed": JobClosed,
                "jobClosedOn": jobClosed,
                "deleted": Deleted,
                "deletedOn": deletedOn,
                "approvedCreditBy": ApprovedCreditBy,
                "approvedCreditRemarks": ApprovedCreditRemarks,
                "approvedCreditOn": approvedCreditOn,
                "siCustomerId": 0,
                "shipVol20Amount": data20,
                "shipVol40Amount": data40,
                "shipVol45Amount": data45,
                "requester": Requester,
                "isWithEPL": isWithEPL,
                "rate": rateReal,
                "exRateDate": exRateDate,
                "exRateId": exRateId,
                "serviceType": jobType?.serviceType,

                "idLama": 0,
            },

            "shipmentOrderRoutings": routingData,

            "shipmentOrderSeaContainers": containerData,

            "shipmentOrderBillOfLading": billOfLading,

            "shipmentOrderInstruction": shippingInstruction,

            "shipmentOrderAdvice": shipmentAdvice,

            "shipmentOrderTelexRelease": telexRelease,

            "shipmentOrderTrackings": trackingData,

            "shipmentOrderConNotes": ConNoteData,
        }

        let message = '';
        let checkInput = 0;

        if (BookingConfirmationId === 0) {
            checkInput = 1;
        }
        else if (principleBy === 0) {
            checkInput = 2;
        }
        else if (parseInt(ShipmentStatus) === 1 && (MarketId === 0 || MarketName === '')) {
            checkInput = 5;
        }
        else if (new Date(data.shipmentOrder.eta) < new Date(data.shipmentOrder.etd)) {
            checkInput = 6;
        }

        if (AgentId === 0) {
            checkInput = 3;
        }
        else if (parseInt(sJobTypeId) % 2 === 0) {
            if (ShipperName === '' && ShipperAddress === '') {
                checkInput = 31;
            }
        }
        else {
            if (ConsigneeName === '' && ConsigneeAddress === '') {
                checkInput = 32;
            }
        }

        if (checkInput === 0) {
            if (checkInput === 0) {
                ApiService.HelpCreateData(data, paramShipmentOrder, id)
                    .then((res) => {
                        if (res.data.code === 200) {
                            if (id === 0) {
                                ApiService.CreateOperation(paramShipmentOrder, data)
                                    .then((res) => {
                                        if (res.data.code === 200) {
                                            message = 'Create Data Success';

                                            if (!isPrint) {
                                                showToast({ type: 'success', message: message });

                                                setTimeout(
                                                    function () {
                                                        setIsLoadingBackDrop(false);
                                                        localStorage.setItem('tempId', JSON.stringify(res.data.data.Id));
                                                        history('/Infoss/ShipmentOrder/' + sJobTypeId + '/' + JSON.parse(localStorage.getItem("tempId")), { replace: true });
                                                        window.location.reload();
                                                        localStorage.removeItem('tempId');
                                                    },
                                                    1250);
                                            }
                                        }
                                        else {
                                            setIsLoadingBackDrop(false);
                                            message = res.data.error;
                                            showToast({ type: 'error', message: message });
                                        }
                                    })
                                    .catch(function (error) {
                                        setIsLoadingBackDrop(false);
                                        showToast({ type: 'error', message: 'Something Went Wrong!, ' + error });
                                    })
                            }
                            else {
                                if (subJob === false) {
                                    ApiService.EditOperation(paramShipmentOrder, data).then((res) => {
                                        if (res.data.code === 200) {
                                            message = 'Update Data Success';

                                            if (!isPrint) {
                                                showToast({ type: 'success', message: message });

                                                setTimeout(
                                                    function () {
                                                        setIsLoadingBackDrop(false);
                                                        history('/Infoss/ShipmentOrder/' + sJobTypeId + '/' + shipmentId, { replace: true });
                                                        window.location.reload();
                                                    },
                                                    1250);
                                            }
                                            else {
                                                if (printCode === 'SI') PrintSI()
                                                else if (printCode === 'SA') PrintSA()
                                                else if (printCode === 'SAD') PrintSAD()
                                                else if (printCode === 'DO') PrintDO()
                                                else if (printCode === 'BL') PrintBLDoc()
                                                else if (printCode === 'TLX') PrintTLX()
                                            }
                                        }
                                        else {
                                            setIsLoadingBackDrop(false);
                                            message = res.data.error;
                                            showToast({ type: 'error', message: message });
                                        }
                                    })
                                        .catch(function (error) {
                                            setIsLoadingBackDrop(false);
                                            showToast({ type: 'error', message: 'Something Went Wrong!, ' + error });
                                        })
                                }
                                else {
                                    ApiService.CreateOperation(paramShipmentOrder, data).then((res) => {
                                        if (res.data.code === 200) {
                                            message = 'Create Sub Job Data Success';
                                            showToast({ type: 'success', message: message });

                                            setTimeout(
                                                function () {
                                                    if (!isPrint) {
                                                        setIsLoadingBackDrop(false);
                                                        localStorage.setItem('tempId', JSON.stringify(res.data.data.Id));
                                                        history('/Infoss/ShipmentOrder/' + sJobTypeId + '/' + JSON.parse(localStorage.getItem("tempId")), { replace: true });
                                                        window.location.reload();
                                                        localStorage.removeItem('tempId');
                                                    }
                                                },
                                                1250);
                                        }
                                        else {
                                            setIsLoadingBackDrop(false);
                                            message = 'Create Sub Job Data Failed!';
                                            showToast({ type: 'error', message: message + ' ' + res.data.message });
                                        }
                                    })
                                        .catch(function (error) {
                                            setIsLoadingBackDrop(false);
                                            showToast({ type: 'error', message: 'Something Went Wrong, ' + error });
                                        })
                                }
                            }
                        }
                        else {
                            setIsLoadingBackDrop(false);
                            showToast({ type: 'error', message: 'Failed to Create Help Data, ' + res.data.error });
                        }
                    })
                    .catch(function (error) {
                        setIsLoadingBackDrop(false);
                        console.log(error)
                        showToast({ type: 'error', message: 'You Don\'t Have Access, Please Contact The Administrator, ' + error });
                    })
            }
            else if (checkInput === 4) {
                setIsLoadingBackDrop(false);
                message = 'Please check the ETD/ETA, Because Closing Accounting Period has been Closed in that Period!';
                showToast({ type: 'error', message: message });
            }
        }
        else {
            setIsLoadingBackDrop(false);
            if (checkInput === 1) {
                message = 'Please Select Booking Confirmation Number';
            }
            else if (checkInput === 2) {
                message = 'Please Select Principle By';
            }
            else if (checkInput === 3) {
                message = 'Invalid Agent, Please Select the Agent';
            }
            else if (checkInput === 31) {
                message = 'Invalid Shipper, Please Input the Shipper';
            }
            else if (checkInput === 32) {
                message = 'Invalid Consignee, Please Select the Consignee';
            }
            else if (checkInput === 5) {
                message = 'Please Select Marketing Name or Change The Shipment Status!'
            }
            else if (checkInput === 6) {
                message = 'ETA Must Be Greater Than or Same As ETD!'
            }
            showToast({ type: 'error', message: message });
        }
    }

    function Back() {
        if (!isToDo) {
            history('/Infoss/ShipmentOrder');
        }
        else {
            history('/Infoss');
            window.location.reload();
        }
    }

    function PrintBL() {
        if (BLFormId !== 0) {
            setTitleModal2('Parameter BL Report');

            if (BLReceiptPlaceName === '') {
                setBLReceiptPlaceName(ReceiptPlaceName);
            }

            if (BLDischargePortName === '') {
                setBLDischargePortName(DischargePortName);
            }

            if (BLLoadingPortName === '') {
                setBLLoadingPortName(LoadingPortName);
            }

            if (BLDeliveryPlaceName === '') {
                setBLDeliveryPlaceName(DeliveryPlaceName);
            }

            handleClick2();
        }
        else {
            showToast({ type: 'error', message: 'Please Select BL Form!' });
        }
    }

    function PrintSI() {
        setIsLoadingBackDrop(true);
        let variable = 'PrintSI?Id=' + shipmentId;
        ApiService.ReportPrint('Transactions/Operation/ShipmentOrderReport/', variable)
            .then(response => {
                if (response.status === 200) {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' }
                    );

                    const fileURL = URL.createObjectURL(file);
                    showToast({ type: 'success', message: 'Print Shipping Instruction Success!' });
                    window.open(fileURL);
                    setTimeout(function () { setIsLoadingBackDrop(false); }, 1250);
                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'Can\'t Print Shipping Instruction!, ' + response.data.message });
                }
            })
            .catch(error => {
                setIsLoadingBackDrop(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Print Shipping Instruction!, ' + error });
            });
    }

    function PrintSA() {
        setIsLoadingBackDrop(true);
        let variable = 'PrintSA?Id=' + shipmentId + '&ToAgent=true';
        ApiService.ReportPrint('Transactions/Operation/ShipmentOrderReport/', variable)
            .then(response => {
                if (response.status === 200) {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' }
                    );

                    const fileURL = URL.createObjectURL(file);
                    showToast({ type: 'success', message: 'Print Shipment Advice Success!' });
                    window.open(fileURL);
                    setTimeout(function () { setIsLoadingBackDrop(false); }, 1250);
                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'Can\'t Print Shipment Advice!, ' + response.data.message });
                }
            })
            .catch(error => {
                setIsLoadingBackDrop(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Print Shipment Advice!, ' + error });
            });
    }

    function PrintSAD() {
        setIsLoadingBackDrop(true);
        let variable = 'PrintSA?Id=' + shipmentId + '&ToAgent=false';
        ApiService.ReportPrint('Transactions/Operation/ShipmentOrderReport/', variable)
            .then(response => {
                if (response.status === 200) {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' }
                    );

                    const fileURL = URL.createObjectURL(file);
                    showToast({ type: 'success', message: 'Print Shipment Advice Success!' });
                    window.open(fileURL);
                    setTimeout(function () { setIsLoadingBackDrop(false); }, 1250);
                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'Can\'t Print Shipment Advice!, ' + response.data.message });
                }
            })
            .catch(error => {
                setIsLoadingBackDrop(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Print Shipment Advice!, ' + error });
            });
    }

    function PrintBLDoc() {
        let variable = 'PrintBL?Id=' + ShipmentId + '&PrintBLType=' + DocumentType + '&AsCarrier=' + carrier
            + '&AddDesc=' + bolIssue + '&LoadStatus=' + changeDes + '&THC=' + desCollect;
        ApiService.ReportPrint('Transactions/Operation/ShipmentOrderReport/', variable)
            .then(response => {
                if (response.status === 200) {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' }
                    );

                    const fileURL = URL.createObjectURL(file);
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'success', message: 'Print Shipment Bill Of Lading Success!' });
                    window.open(fileURL);
                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'Can\'t Print Shipment Bill Of Lading!, ' + response.data.message });
                }
            })
            .catch(error => {
                setIsLoadingBackDrop(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Print Shipment Bill Of Lading!, ' + error });
            });
    }

    function PrintDO() {
        setIsLoadingBackDrop(true);
        var data = {
            "shipmentOrderDeliveryOrder":
            {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "rowStatus": "ACT",
                "shipmentId": parseInt(ShipmentId),
                "toName": ToDO,
                "attention": AttentionDO,
                "commodity": CommodityDO,
                "remarks": RemarksDO,
                "printing": 0,
                "printedOn": defaultDate,
                "quantity": String(QuantityDO),
                "containerNo": ContainerDO,
                "sealNo": SealDO,
                "slotOperator": OperatorDO,
                "doId": 0,
                "deleted": false,
                "deletedOn": defaultDate,
                "isNew": true,

                "idLama": 0,
            }
        }

        ApiService.PrintDO(paramShipmentOrder, data).then((res) => {
            if (res.data.code === 200) {
                let variable = 'PrintDO?Id=' + ShipmentId;
                ApiService.ReportPrint('Transactions/Operation/ShipmentOrderReport/', variable)
                    .then(response => {
                        if (response.status === 200) {
                            const file = new Blob(
                                [response.data],
                                { type: 'application/pdf' }
                            );

                            const fileURL = URL.createObjectURL(file);
                            showToast({ type: 'success', message: 'Print Shipment Delivery Order Success!' });
                            window.open(fileURL);
                            setTimeout(function () { setIsLoadingBackDrop(false); }, 1250);
                            handleClick()
                            emptyState()
                        }
                        else {
                            setIsLoadingBackDrop(false);
                            showToast({ type: 'error', message: 'Can\'t Print Shipment Delivery Order!, ' + response.data.message });
                        }
                    })
                    .catch(error => {
                        setIsLoadingBackDrop(false);
                        console.log(error);
                        showToast({ type: 'error', message: 'Can\'t Print Shipment Delivery Order!, ' + error });
                    });
            }
        });
    }

    const emptyState = () => {
        setToDO('');
        setAttentionDO('');
        setQuantityDO(0);
        setContainerDO('');
        setSealDO('');
        setCommodityDo('');
        setGudangName('');
        setRemarksDO('HARAP BERIKAN CONTAINER YANG AV');
        setOperatorDO('');
    };

    function OpenDOModal() {
        if (jobType.portType === 'AIR' && jobType.serviceType === 'Import') {
            setGudangName('');
        }
        else {
            setIsLoadingBackDrop(true);
            ApiService.OperationPrintDO(paramShipmentOrder, shipmentId)
                .then((res) => {
                    if (res.data.code === 200) {
                        let data = res.data.data.shipmentOrderDeliveryOrder;

                        if (data) {
                            vesselData.forEach((el) => {
                                if (el.vesselType.toLowerCase() === 'feeder') {
                                    setIntendVessel(el.vesselName);
                                    setDOETD(getDefaultDateFormat(el.etd));
                                }
                            })
                            setQuantityDO(data.quantity);
                            setToDO(data.toName);
                            setAttentionDO(data.attention);
                            setContainerDO(data.containerNo);
                            setSealDO(data.sealNo);
                            setCommodityDo(data.commodity);
                            setRemarksDO(data.remarks);
                            setOperatorDO(data.slotOperator);
                        }
                        else {
                            let tempArr = [];
                            let tempString = '';
                            vesselData.forEach((el) => {
                                if (el.vesselType.toLowerCase() === 'feeder') {
                                    setIntendVessel(el.vesselName);
                                    setDOETD(getDefaultDateFormat(el.etd));
                                }
                            })
                            containerData.forEach(element => {
                                if (tempArr.length === 0) {
                                    tempArr = [
                                        ...tempArr,
                                        {
                                            'containerSize': element.containerSize,
                                            'amount': 1,
                                            'containerTypeCode': element.containerTypeCode,
                                        }
                                    ];
                                }
                                else {
                                    let check = 0;
                                    for (let i = 0; i < tempArr.length; i++) {
                                        if (tempArr[i].containerSize === element.containerSize && tempArr[i].containerTypeCode === element.containerTypeCode) {
                                            tempArr[i].amount = parseInt(tempArr[i].amount) + parseInt(1);
                                            check = 1;
                                            break;
                                        }
                                    }

                                    if (check === 0) {
                                        tempArr = [
                                            ...tempArr,
                                            {
                                                'containerSize': element.containerSize,
                                                'amount': 1,
                                                'containerTypeCode': element.containerTypeCode,
                                            }
                                        ]
                                    }
                                }
                            });

                            if (containerData.length > 0) {
                                if (tempArr.length > 1) {
                                    for (let i = 0; i < tempArr.length; i++) {
                                        tempString = tempString + '(' + tempArr[i].amount + ' x ' + tempArr[i].containerSize + ' - ' + tempArr[i].containerTypeCode + ')';
                                        if (i !== tempArr.length - 1) {
                                            tempString = tempString + ', ';
                                        }
                                    }
                                }
                                else {
                                    tempString = tempString + '(' + tempArr[0].amount + ' x ' + tempArr[0].containerSize + ' - ' + tempArr[0].containerTypeCode + ')';
                                }
                            }

                            setQuantityDO(tempString);
                        }
                    }

                    setTitleModal('Print Delivery Order');
                    setIsLoadingBackDrop(false)
                    handleClick();
                })
        }
    }

    function PrintTLX() {
        setIsLoadingBackDrop(true);
        let variable = 'PrintTR?Id=' + shipmentId;
        ApiService.ReportPrint('Transactions/Operation/ShipmentOrderReport/', variable)
            .then(response => {
                if (response.status === 200) {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' }
                    );

                    const fileURL = URL.createObjectURL(file);
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'success', message: 'Print Telex Release Success!' });
                    window.open(fileURL);
                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'Can\'t Print Telex Release!' });
                }
            })
            .catch(error => {
                setIsLoadingBackDrop(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Print Telex Release!' });
            });
    }

    function CloseJob() {
        let message = '';

        ApiService.OperationCloseJob(paramShipmentOrder, shipmentId).then((res) => {
            if (res.data.code === 200) {
                message = 'Close Job Success';
                showToast({ type: 'success', message: message });

                setTimeout(
                    function () {
                        history('/Infoss/ShipmentOrder/' + sJobTypeId + '/' + shipmentId, { replace: true });
                        window.location.reload();
                    },
                    1250);
            }
            else {
                message = 'Close Job Failed';
                showToast({ type: 'error', message: message });
            }
        });
    }

    function OpenJob() {

    }

    function ApproveData() {
        if (shipmentId) {
            setTitleConfirmation('Approval Credit')
            setTitleModalConfirmation('');
            handleClickConfirmation();
            setIsRemarks(true);
        }
        else {
            showToast({ type: 'error', message: 'Create The Data First!' });
        }
    }

    function Approve() {

    }

    function UpdateSub() {
        Save(true);
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationSO() {
        if (titleConfirmation === 'Approval Credit') {
            Approve();
        }
        setIsRemarks(false);
        setIsModalConfirmation(false);
    }

    const ButtonComponents = () => {
        return (
            <ButtonCreateEditShipment
                Save={Save}
                Back={Back}
                PrintBL={PrintBL}
                PrintSI={() => handlePrintGeneral('SI')}
                PrintSA={() => handlePrintGeneral('SA')}
                PrintSAD={() => handlePrintGeneral('SAD')}
                PrintDO={OpenDOModal}
                PrintTLX={() => handlePrintGeneral('TLX')}
                CloseJob={CloseJob}
                OpenJob={OpenJob}
                Approve={ApproveData}
                UpdateSub={UpdateSub}
                jobType={jobType}
                shipmentId={shipmentId}
                jobClose={jobClose}
                isJobClose={isJobClose}
                isEdit={isEdit}
                DataRegUserAccess={DataRegUserAccess}
                check={check}
            />
        )
    }

    return (
        <>
            <Box sx={{ backgroundColor: '#E4E8EC', width: '100%', p: '24px 32px' }}>
                <CustomToastContainer />
                <Grid container spacing={0} direction="column">
                    <HeaderInfo
                        title={
                            shipmentId ?
                                'Shipment Order - ' + jobType.name + ' (' + ShipmentNo + ')'
                                :
                                'Shipment Order - ' + jobType.name + ' ( New Document )'
                        }
                        BreadcrumbsItems={shipmentId ? BreadcrumbsEditItems : BreadcrumbsItems}
                        type={'Form'}
                        ticketId={TicketId}
                        BackFunction={Back}
                        documentStatus={documentStatus}
                    />

                    <fieldset disabled={jobClose}>
                        <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                            <CardContent>
                                {
                                    jobType.portType === 'SEA' ?
                                        <>
                                            {
                                                jobType.code === '10' || jobType.code === '11' ?
                                                    <ShipmentOrderSea
                                                        isMarketing={isMarketing}
                                                        calculateTypeVessel={calculateTypeVessel}
                                                        jobType={jobType}
                                                        typeJobId={typeJobId}
                                                        bookingId={bookingId}
                                                        isEdit={isEdit}
                                                        selectContainerStatus={selectContainerStatus}
                                                        selectJobType={selectJobType}
                                                        selectCurrency={selectCurrency}
                                                        selectPrincipleBy={selectPrincipleBy}
                                                        selectShipmentStatus={selectShipmentStatus}
                                                        selectServiceType={selectServiceType}
                                                        selectContainerType={selectContainerType}
                                                        selectPackagingType={selectPackagingType}

                                                        dataTracking={dataTracking}
                                                        setDataTracking={setDataTracking}
                                                        isJobClose={isJobClose}

                                                        period={period}
                                                        setPeriod={setPeriod}
                                                        yearPeriod={yearPeriod}
                                                        setYearPeriod={setYearPeriod}
                                                        isClosed={isClosed}
                                                        setIsClosed={setIsClosed}

                                                        //Main Data
                                                        ShipmentId={ShipmentId}
                                                        setShipmentId={setShipmentId}
                                                        ShipmentNo={ShipmentNo}
                                                        setShipmentNo={setShipmentNo}
                                                        TotalSub={SubJobNo}
                                                        setTotalSub={setTotalSub}
                                                        SIReference={SIReference}
                                                        setSIReference={setSIReference}
                                                        SIDate={SIDate}
                                                        setSIDate={setSIDate}
                                                        principleBy={principleBy}
                                                        GoodsRecDate={GoodsRecDate}
                                                        setGoodsRecDate={setGoodsRecDate}
                                                        BookingConfirmationId={BookingConfirmationId}
                                                        setBookingConfirmationId={setBookingConfirmationId}
                                                        BookingConfirmationNumber={BookingConfirmationNumber}
                                                        setBookingConfirmationNumber={setBookingConfirmationNumber}
                                                        setTicketId={setTicketId}
                                                        setRequester={setRequester}
                                                        //SEA IMPORT
                                                        Conversion={Conversion}
                                                        setConversion={setConversion}
                                                        QuotationNo={QuotationNo}
                                                        setQuotationNo={setQuotationNo}

                                                        GetBookingData={GetBookingData}

                                                        LoadStatus={LoadStatus}
                                                        setLoadStatus={setLoadStatus}
                                                        ContainerStatus={ContainerStatus}
                                                        setContainerStatus={setContainerStatus}
                                                        ShipmentStatus={ShipmentStatus}
                                                        setShipmentStatus={setShipmentStatus}
                                                        JobTypeId={JobTypeId}
                                                        setJobTypeId={setJobTypeId}
                                                        MarketId={MarketId}
                                                        setMarketId={setMarketId}
                                                        MarketName={MarketName}
                                                        setMarketName={setMarketName}
                                                        MarketCompanyId={MarketCompanyId}
                                                        setMarketCompanyId={setMarketCompanyId}
                                                        jobCategory={jobCategory}
                                                        setJobCategory={setJobCategory}

                                                        //Contact Data
                                                        contactTypeId={contactTypeId}
                                                        setContactTypeId={setContactTypeId}
                                                        contactType={contactType}
                                                        setContactType={setContactType}

                                                        //Agent Data
                                                        AgentId={AgentId}
                                                        setAgentId={setAgentId}
                                                        AgentName={AgentName}
                                                        setAgentName={setAgentName}
                                                        AgentAddress={AgentAddress}
                                                        setAgentAddress={setAgentAddress}
                                                        setSIAgentId={setSIAgentId}
                                                        setSIAgentName={setSIAgentName}
                                                        setSIAgentAddress={setSIAgentAddress}

                                                        //Delivery Data
                                                        DeliveryId={DeliveryId}
                                                        setDeliveryId={setDeliveryId}
                                                        DeliveryName={DeliveryName}
                                                        setDeliveryName={setDeliveryName}
                                                        DeliveryAddress={DeliveryAddress}
                                                        setDeliveryAddress={setDeliveryAddress}

                                                        //Transhipment Data
                                                        TranshipmentId={TranshipmentId}
                                                        setTranshipmentId={setTranshipmentId}
                                                        TranshipmentName={TranshipmentName}
                                                        setTranshipmentName={setTranshipmentName}
                                                        TranshipmentAddress={TranshipmentAddress}
                                                        setTranshipmentAddress={setTranshipmentAddress}

                                                        //Shipper Data
                                                        ShipperId={ShipperId}
                                                        setShipperId={setShipperId}
                                                        ShipperName={ShipperName}
                                                        setShipperName={setShipperName}
                                                        ShipperAddress={ShipperAddress}
                                                        setShipperAddress={setShipperAddress}

                                                        //Consignee Data
                                                        ConsigneeId={ConsigneeId}
                                                        setConsigneeId={setConsigneeId}
                                                        ConsigneeName={ConsigneeName}
                                                        setConsigneeName={setConsigneeName}
                                                        ConsigneeAddress={ConsigneeAddress}
                                                        setConsigneeAddress={setConsigneeAddress}

                                                        TempConsigneeId={TempConsigneeId}
                                                        setTempConsigneeId={setTempConsigneeId}
                                                        TempConsigneeName={TempConsigneeName}
                                                        setTempConsigneeName={setTempConsigneeName}
                                                        TempConsigneeAddress={TempConsigneeAddress}
                                                        setTempConsigneeAddress={setTempConsigneeAddress}

                                                        //NParty Data
                                                        NPartyId={NPartyId}
                                                        setNPartyId={setNPartyId}
                                                        NPartyName={NPartyName}
                                                        setNPartyName={setNPartyName}
                                                        NPartyAddress={NPartyAddress}
                                                        setNPartyAddress={setNPartyAddress}

                                                        //BookingParty Data
                                                        BookingPartyId={BookingPartyId}
                                                        setBookingPartyId={setBookingPartyId}
                                                        BookingPartyName={BookingPartyName}
                                                        setBookingPartyName={setBookingPartyName}
                                                        BookingPartyAddress={BookingPartyAddress}
                                                        setBookingPartyAddress={setBookingPartyAddress}

                                                        //Credit Info Data
                                                        billToShipperId={billToShipperId}
                                                        setBillToShipperId={setBillToShipperId}
                                                        billShipperName={billShipperName}
                                                        setBillShipperName={setBillShipperName}

                                                        billToConsigneeId={billToConsigneeId}
                                                        setBillToConsigneeId={setBillToConsigneeId}
                                                        billConsigneeName={billConsigneeName}
                                                        setBillConsigneeName={setBillConsigneeName}

                                                        creditTermUSD={creditTermUSD}
                                                        setCreditTermUSD={setCreditTermUSD}
                                                        creditTermDays={creditTermDays}
                                                        setCreditTermDays={setCreditTermDays}
                                                        creditTermIDR={creditTermIDR}
                                                        setCreditTermIDR={setCreditTermIDR}
                                                        ApprovedCredit={ApprovedCredit}
                                                        setApprovedCredit={setApprovedCredit}
                                                        outstandingInIDR={outstandingInIDR}
                                                        setOutstandingInIDR={setOutstandingInIDR}
                                                        outstandingInUSD={outstandingInUSD}
                                                        setOutstandingInUSD={setOutstandingInUSD}
                                                        freightAmountIDR={freightAmountIDR}
                                                        setFreightAmountIDR={setFreightAmountIDR}
                                                        freightAmountUSD={freightAmountUSD}
                                                        setFreightAmountUSD={setFreightAmountUSD}

                                                        //Detail Shipping Data
                                                        detailTypeId={detailTypeId}
                                                        setDetailTypeId={setDetailTypeId}

                                                        //Data Tab Vessel
                                                        ReceiptPlaceId={ReceiptPlaceId}
                                                        setReceiptPlaceId={setReceiptPlaceId}
                                                        ReceiptPlaceCode={ReceiptPlaceCode}
                                                        setReceiptPlaceCode={setReceiptPlaceCode}
                                                        ReceiptPlaceName={ReceiptPlaceName}
                                                        setReceiptPlaceName={setReceiptPlaceName}

                                                        DischargePortId={DischargePortId}
                                                        setDischargePortId={setDischargePortId}
                                                        DischargePortCode={DischargePortCode}
                                                        setDischargePortCode={setDischargePortCode}
                                                        DischargePortName={DischargePortName}
                                                        setDischargePortName={setDischargePortName}

                                                        LoadingPortId={LoadingPortId}
                                                        setLoadingPortId={setLoadingPortId}
                                                        LoadingPortCode={LoadingPortCode}
                                                        setLoadingPortCode={setLoadingPortCode}
                                                        LoadingPortName={LoadingPortName}
                                                        setLoadingPortName={setLoadingPortName}

                                                        DeliveryPlaceId={DeliveryPlaceId}
                                                        setDeliveryPlaceId={setDeliveryPlaceId}
                                                        DeliveryPlaceCode={DeliveryPlaceCode}
                                                        setDeliveryPlaceCode={setDeliveryPlaceCode}
                                                        DeliveryPlaceName={DeliveryPlaceName}
                                                        setDeliveryPlaceName={setDeliveryPlaceName}

                                                        TempLoadingPortName={TempLoadingPortName}
                                                        setTempLoadingPortName={setTempLoadingPortName}
                                                        TempReceiptPlaceName={TempReceiptPlaceName}
                                                        setTempReceiptPlaceName={setTempReceiptPlaceName}
                                                        TempDischargePortName={TempDischargePortName}
                                                        setTempDischargePortName={setTempDischargePortName}
                                                        TempDeliveryPlaceName={TempDeliveryPlaceName}
                                                        setTempDeliveryPlaceName={setTempDeliveryPlaceName}

                                                        setBLReceiptPlaceName={setBLReceiptPlaceName}
                                                        setBLDischargePortName={setBLDischargePortName}
                                                        setBLLoadingPortName={setBLLoadingPortName}
                                                        setBLDeliveryPlaceName={setBLDeliveryPlaceName}

                                                        ETA={ETA}
                                                        setETA={setETA}
                                                        ETD={ETD}
                                                        setETD={setETD}
                                                        ATD={ATD}
                                                        setATD={setATD}
                                                        ATA={ATA}
                                                        setATA={setATA}

                                                        vesselData={vesselData}
                                                        setVesselData={setVesselData}

                                                        //Data Tab Container
                                                        data20={data20}
                                                        setData20={setData20}
                                                        data40={data40}
                                                        setData40={setData40}
                                                        data45={data45}
                                                        setData45={setData45}
                                                        dataAll={dataAll}
                                                        setDataAll={setDataAll}

                                                        containerData={containerData}
                                                        setContainerData={setContainerData}
                                                        getContainerSizeData={getContainerSizeData}

                                                        CopyContainer={CopyContainer}
                                                        setCopyContainer={setCopyContainer}
                                                        createCopyContainer={createCopyContainer}
                                                        totalNumberCont={totalNumberCont}
                                                        setTotalNumberCont={setTotalNumberCont}

                                                        //Data Tab Freight
                                                        freightStatusOBL={freightStatusOBL}
                                                        setFreightStatusOBL={setFreightStatusOBL}
                                                        freightCollectIdOBL={freightCollectIdOBL}
                                                        setFreightCollectIdOBL={setFreightCollectIdOBL}
                                                        freightCollectCodeOBL={freightCollectCodeOBL}
                                                        setFreightCollectCodeOBL={setFreightCollectCodeOBL}
                                                        freightCollectNameOBL={freightCollectNameOBL}
                                                        setFreightCollectNameOBL={setFreightCollectNameOBL}
                                                        payableAgentIdOBL={payableAgentIdOBL}
                                                        setPayableAgentIdOBL={setPayableAgentIdOBL}
                                                        payableAgentNameOBL={payableAgentNameOBL}
                                                        setPayableAgentNameOBL={setPayableAgentNameOBL}
                                                        //SEA IMPORT
                                                        OBLCurrency={OBLCurrency}
                                                        setOBLCurrency={setOBLCurrency}
                                                        OBLAmount={OBLAmount}
                                                        setOBLAmount={setOBLAmount}

                                                        freightStatusHBL={freightStatusHBL}
                                                        setFreightStatusHBL={setFreightStatusHBL}
                                                        freightCollectIdHBL={freightCollectIdHBL}
                                                        setFreightCollectIdHBL={setFreightCollectIdHBL}
                                                        freightCollectCodeHBL={freightCollectCodeHBL}
                                                        setFreightCollectCodeHBL={setFreightCollectCodeHBL}
                                                        freightCollectNameHBL={freightCollectNameHBL}
                                                        setFreightCollectNameHBL={setFreightCollectNameHBL}
                                                        payableConsigneeIdHBL={payableConsigneeIdHBL}
                                                        setPayableConsigneeIdHBL={setPayableConsigneeIdHBL}
                                                        payableConsigneeNameHBL={payableConsigneeNameHBL}
                                                        setPayableConsigneeNameHBL={setPayableConsigneeNameHBL}
                                                        //SEA IMPORT
                                                        HBLCurrency={HBLCurrency}
                                                        setHBLCurrency={setHBLCurrency}
                                                        HBLAmount={HBLAmount}
                                                        setHBLAmount={setHBLAmount}

                                                        currency={currency}
                                                        setCurrency={setCurrency}
                                                        handlingInf={handlingInf}
                                                        setHandlingInf={setHandlingInf}

                                                        //Data Tab Description
                                                        MarkNumberDescription={MarkNumberDescription}
                                                        setMarkNumberDescription={setMarkNumberDescription}
                                                        NumberTypeDescription={NumberTypeDescription}
                                                        setNumberTypeDescription={setNumberTypeDescription}
                                                        GoodDescription={GoodDescription}
                                                        setGoodDescription={setGoodDescription}
                                                        GrossWeightDescription={GrossWeightDescription}
                                                        setGrossWeightDescription={setGrossWeightDescription}
                                                        MeasurementDescription={MeasurementDescription}
                                                        setMeasurementDescription={setMeasurementDescription}

                                                        TempMarkNumberDescription={TempMarkNumberDescription}
                                                        setTempMarkNumberDescription={setTempMarkNumberDescription}
                                                        TempNumberTypeDescription={TempNumberTypeDescription}
                                                        setTempNumberTypeDescription={setTempNumberTypeDescription}
                                                        TempGoodDescription={TempGoodDescription}
                                                        setTempGoodDescription={setTempGoodDescription}
                                                        TempGrossWeightDescription={TempGrossWeightDescription}
                                                        setTempGrossWeightDescription={setTempGrossWeightDescription}
                                                        TempMeasurementDescription={TempMeasurementDescription}
                                                        setTempMeasurementDescription={setTempMeasurementDescription}

                                                        setShippingMarksDesc={setShippingMarksDesc}
                                                        setNoPackageDesc={setNoPackageDesc}
                                                        setSIGoodDesc={setSIGoodDesc}
                                                        setWeightMeaDesc={setWeightMeaDesc}

                                                        //Main Data 2
                                                        OceanMSTBLNo={OceanMSTBLNo}
                                                        setOceanMSTBLNo={setOceanMSTBLNo}
                                                        VolumeBL={VolumeBL}
                                                        setVolumeBL={setVolumeBL}
                                                        VolumeInvoice={VolumeInvoice}
                                                        setVolumeInvoice={setVolumeInvoice}
                                                        SSLineId={SSLineId}
                                                        setSSLineId={setSSLineId}
                                                        SSLineName={SSLineName}
                                                        setSSLineName={setSSLineName}
                                                        EMKLId={EMKLId}
                                                        setEMKLId={setEMKLId}
                                                        EMKLName={EMKLName}
                                                        setEMKLName={setEMKLName}
                                                        DepoId={DepoId}
                                                        setDepoId={setDepoId}
                                                        DepoName={DepoName}
                                                        setDepoName={setDepoName}
                                                        //SEA IMPORT
                                                        HouseBLNo={HouseBLNo}
                                                        setHouseBLNo={setHouseBLNo}
                                                        SecondBLNo={SecondBLNo}
                                                        setSecondBLNo={setSecondBLNo}
                                                        WareHouseName={WareHouseName}
                                                        setWareHouseName={setWareHouseName}
                                                        Kins={Kins}
                                                        setKins={setKins}
                                                        CFName={CFName}
                                                        setCFName={setCFName}

                                                        //BL Data
                                                        setBLAgentId={setBLAgentId}
                                                        setBLAgentName={setBLAgentName}
                                                        setBLAgentAddress={setBLAgentAddress}

                                                        setBLShipperId={setBLShipperId}
                                                        setBLShipperName={setBLShipperName}
                                                        setBLShipperAddress={setBLShipperAddress}

                                                        setBLConsigneeId={setBLConsigneeId}
                                                        setBLConsigneeName={setBLConsigneeName}
                                                        setBLConsigneeAddress={setBLConsigneeAddress}

                                                        setBLNPartyId={setBLNPartyId}
                                                        setBLNPartyName={setBLNPartyName}
                                                        setBLNPartyAddress={setBLNPartyAddress}

                                                        //SI Data
                                                        setSIConsigneeId={setSIConsigneeId}
                                                        setSIConsigneeName={setSIConsigneeName}
                                                        setSIConsigneeAddress={setSIConsigneeAddress}

                                                        setSINPartyId={setSINPartyId}
                                                        setSINPartyName={setSINPartyName}
                                                        setSINPartyAddress={setSINPartyAddress}
                                                        isWithEPL={isWithEPL}
                                                        setIsWithEPL={setIsWithEPL}
                                                    />
                                                    :
                                                    <ShipmentOrderPPJKSea
                                                        isMarketing={isMarketing}
                                                        calculateTypeVessel={calculateTypeVessel}
                                                        jobType={jobType}
                                                        typeJobId={typeJobId}
                                                        bookingId={bookingId}
                                                        isEdit={isEdit}
                                                        selectShipmentStatus={selectShipmentStatus}
                                                        selectJobType={selectJobType}
                                                        selectPrincipleBy={selectPrincipleBy}
                                                        selectCurrency={selectCurrency}
                                                        selectContainerType={selectContainerType}
                                                        selectPackagingType={selectPackagingType}

                                                        dataTracking={dataTracking}
                                                        setDataTracking={setDataTracking}
                                                        isJobClose={isJobClose}

                                                        period={period}
                                                        setPeriod={setPeriod}
                                                        yearPeriod={yearPeriod}
                                                        setYearPeriod={setYearPeriod}
                                                        isClosed={isClosed}
                                                        setIsClosed={setIsClosed}

                                                        //Main Data
                                                        ShipmentId={ShipmentId}
                                                        setShipmentId={setShipmentId}
                                                        ShipmentNo={ShipmentNo}
                                                        setShipmentNo={setShipmentNo}
                                                        SIReference={SIReference}
                                                        setSIReference={setSIReference}
                                                        SIDate={SIDate}
                                                        setSIDate={setSIDate}
                                                        principleBy={principleBy}
                                                        GoodsRecDate={GoodsRecDate}
                                                        setGoodsRecDate={setGoodsRecDate}
                                                        BookingConfirmationId={BookingConfirmationId}
                                                        setBookingConfirmationId={setBookingConfirmationId}
                                                        BookingConfirmationNumber={BookingConfirmationNumber}
                                                        setBookingConfirmationNumber={setBookingConfirmationNumber}
                                                        setTicketId={setTicketId}
                                                        setRequester={setRequester}

                                                        GetBookingData={GetBookingData}

                                                        LoadStatus={LoadStatus}
                                                        setLoadStatus={setLoadStatus}
                                                        ShipmentStatus={ShipmentStatus}
                                                        setShipmentStatus={setShipmentStatus}
                                                        JobTypeId={JobTypeId}
                                                        setJobTypeId={setJobTypeId}
                                                        MarketId={MarketId}
                                                        setMarketId={setMarketId}
                                                        MarketName={MarketName}
                                                        setMarketName={setMarketName}
                                                        MarketCompanyId={MarketCompanyId}
                                                        setMarketCompanyId={setMarketCompanyId}

                                                        //Contact Data
                                                        contactTypeId={contactTypeId}
                                                        setContactTypeId={setContactTypeId}
                                                        contactType={contactType}
                                                        setContactType={setContactType}

                                                        //Agent Data
                                                        AgentId={AgentId}
                                                        setAgentId={setAgentId}
                                                        AgentName={AgentName}
                                                        setAgentName={setAgentName}
                                                        AgentAddress={AgentAddress}
                                                        setAgentAddress={setAgentAddress}
                                                        setSIAgentId={setSIAgentId}
                                                        setSIAgentName={setSIAgentName}
                                                        setSIAgentAddress={setSIAgentAddress}

                                                        //Delivery Data
                                                        DeliveryId={DeliveryId}
                                                        setDeliveryId={setDeliveryId}
                                                        DeliveryName={DeliveryName}
                                                        setDeliveryName={setDeliveryName}
                                                        DeliveryAddress={DeliveryAddress}
                                                        setDeliveryAddress={setDeliveryAddress}

                                                        //Transhipment Data
                                                        TranshipmentId={TranshipmentId}
                                                        setTranshipmentId={setTranshipmentId}
                                                        TranshipmentName={TranshipmentName}
                                                        setTranshipmentName={setTranshipmentName}
                                                        TranshipmentAddress={TranshipmentAddress}
                                                        setTranshipmentAddress={setTranshipmentAddress}

                                                        //Shipper Data
                                                        ShipperId={ShipperId}
                                                        setShipperId={setShipperId}
                                                        ShipperName={ShipperName}
                                                        setShipperName={setShipperName}
                                                        ShipperAddress={ShipperAddress}
                                                        setShipperAddress={setShipperAddress}

                                                        //Consignee Data
                                                        ConsigneeId={ConsigneeId}
                                                        setConsigneeId={setConsigneeId}
                                                        ConsigneeName={ConsigneeName}
                                                        setConsigneeName={setConsigneeName}
                                                        ConsigneeAddress={ConsigneeAddress}
                                                        setConsigneeAddress={setConsigneeAddress}

                                                        TempConsigneeId={TempConsigneeId}
                                                        setTempConsigneeId={setTempConsigneeId}
                                                        TempConsigneeName={TempConsigneeName}
                                                        setTempConsigneeName={setTempConsigneeName}
                                                        TempConsigneeAddress={TempConsigneeAddress}
                                                        setTempConsigneeAddress={setTempConsigneeAddress}

                                                        //NParty Data
                                                        NPartyId={NPartyId}
                                                        setNPartyId={setNPartyId}
                                                        NPartyName={NPartyName}
                                                        setNPartyName={setNPartyName}
                                                        NPartyAddress={NPartyAddress}
                                                        setNPartyAddress={setNPartyAddress}

                                                        //BookingParty Data
                                                        BookingPartyId={BookingPartyId}
                                                        setBookingPartyId={setBookingPartyId}
                                                        BookingPartyName={BookingPartyName}
                                                        setBookingPartyName={setBookingPartyName}
                                                        BookingPartyAddress={BookingPartyAddress}
                                                        setBookingPartyAddress={setBookingPartyAddress}

                                                        //Credit Info Data
                                                        billToShipperId={billToShipperId}
                                                        setBillToShipperId={setBillToShipperId}
                                                        billShipperName={billShipperName}
                                                        setBillShipperName={setBillShipperName}

                                                        billToConsigneeId={billToConsigneeId}
                                                        setBillToConsigneeId={setBillToConsigneeId}
                                                        billConsigneeName={billConsigneeName}
                                                        setBillConsigneeName={setBillConsigneeName}

                                                        creditTermUSD={creditTermUSD}
                                                        setCreditTermUSD={setCreditTermUSD}
                                                        creditTermDays={creditTermDays}
                                                        setCreditTermDays={setCreditTermDays}
                                                        creditTermIDR={creditTermIDR}
                                                        setCreditTermIDR={setCreditTermIDR}
                                                        ApprovedCredit={ApprovedCredit}
                                                        setApprovedCredit={setApprovedCredit}
                                                        outstandingInIDR={outstandingInIDR}
                                                        setOutstandingInIDR={setOutstandingInIDR}
                                                        outstandingInUSD={outstandingInUSD}
                                                        setOutstandingInUSD={setOutstandingInUSD}
                                                        freightAmountIDR={freightAmountIDR}
                                                        setFreightAmountIDR={setFreightAmountIDR}
                                                        freightAmountUSD={freightAmountUSD}
                                                        setFreightAmountUSD={setFreightAmountUSD}

                                                        //Detail Shipping Data
                                                        detailTypeId={detailTypeId}
                                                        setDetailTypeId={setDetailTypeId}

                                                        //Data Tab Vessel
                                                        ReceiptPlaceId={ReceiptPlaceId}
                                                        setReceiptPlaceId={setReceiptPlaceId}
                                                        ReceiptPlaceCode={ReceiptPlaceCode}
                                                        setReceiptPlaceCode={setReceiptPlaceCode}
                                                        ReceiptPlaceName={ReceiptPlaceName}
                                                        setReceiptPlaceName={setReceiptPlaceName}

                                                        DischargePortId={DischargePortId}
                                                        setDischargePortId={setDischargePortId}
                                                        DischargePortCode={DischargePortCode}
                                                        setDischargePortCode={setDischargePortCode}
                                                        DischargePortName={DischargePortName}
                                                        setDischargePortName={setDischargePortName}

                                                        LoadingPortId={LoadingPortId}
                                                        setLoadingPortId={setLoadingPortId}
                                                        LoadingPortCode={LoadingPortCode}
                                                        setLoadingPortCode={setLoadingPortCode}
                                                        LoadingPortName={LoadingPortName}
                                                        setLoadingPortName={setLoadingPortName}

                                                        DeliveryPlaceId={DeliveryPlaceId}
                                                        setDeliveryPlaceId={setDeliveryPlaceId}
                                                        DeliveryPlaceCode={DeliveryPlaceCode}
                                                        setDeliveryPlaceCode={setDeliveryPlaceCode}
                                                        DeliveryPlaceName={DeliveryPlaceName}
                                                        setDeliveryPlaceName={setDeliveryPlaceName}

                                                        TempLoadingPortName={TempLoadingPortName}
                                                        setTempLoadingPortName={setTempLoadingPortName}
                                                        TempReceiptPlaceName={TempReceiptPlaceName}
                                                        setTempReceiptPlaceName={setTempReceiptPlaceName}
                                                        TempDischargePortName={TempDischargePortName}
                                                        setTempDischargePortName={setTempDischargePortName}
                                                        TempDeliveryPlaceName={TempDeliveryPlaceName}
                                                        setTempDeliveryPlaceName={setTempDeliveryPlaceName}

                                                        setBLReceiptPlaceName={setBLReceiptPlaceName}
                                                        setBLDischargePortName={setBLDischargePortName}
                                                        setBLLoadingPortName={setBLLoadingPortName}
                                                        setBLDeliveryPlaceName={setBLDeliveryPlaceName}

                                                        ETA={ETA}
                                                        setETA={setETA}
                                                        ETD={ETD}
                                                        setETD={setETD}
                                                        ATD={ATD}
                                                        setATD={setATD}
                                                        ATA={ATA}
                                                        setATA={setATA}

                                                        vesselData={vesselData}
                                                        setVesselData={setVesselData}

                                                        //Data Tab Container
                                                        data20={data20}
                                                        setData20={setData20}
                                                        data40={data40}
                                                        setData40={setData40}
                                                        data45={data45}
                                                        setData45={setData45}
                                                        dataAll={dataAll}
                                                        setDataAll={setDataAll}

                                                        containerData={containerData}
                                                        setContainerData={setContainerData}
                                                        getContainerSizeData={getContainerSizeData}

                                                        CopyContainer={CopyContainer}
                                                        setCopyContainer={setCopyContainer}
                                                        createCopyContainer={createCopyContainer}
                                                        totalNumberCont={totalNumberCont}
                                                        setTotalNumberCont={setTotalNumberCont}

                                                        //Data Tab Freight
                                                        freightStatusOBL={freightStatusOBL}
                                                        setFreightStatusOBL={setFreightStatusOBL}
                                                        freightCollectIdOBL={freightCollectIdOBL}
                                                        setFreightCollectIdOBL={setFreightCollectIdOBL}
                                                        freightCollectCodeOBL={freightCollectCodeOBL}
                                                        setFreightCollectCodeOBL={setFreightCollectCodeOBL}
                                                        freightCollectNameOBL={freightCollectNameOBL}
                                                        setFreightCollectNameOBL={setFreightCollectNameOBL}
                                                        payableAgentIdOBL={payableAgentIdOBL}
                                                        setPayableAgentIdOBL={setPayableAgentIdOBL}
                                                        payableAgentNameOBL={payableAgentNameOBL}
                                                        setPayableAgentNameOBL={setPayableAgentNameOBL}
                                                        //SEA IMPORT
                                                        OBLCurrency={OBLCurrency}
                                                        setOBLCurrency={setOBLCurrency}
                                                        OBLAmount={OBLAmount}
                                                        setOBLAmount={setOBLAmount}

                                                        freightStatusHBL={freightStatusHBL}
                                                        setFreightStatusHBL={setFreightStatusHBL}
                                                        freightCollectIdHBL={freightCollectIdHBL}
                                                        setFreightCollectIdHBL={setFreightCollectIdHBL}
                                                        freightCollectCodeHBL={freightCollectCodeHBL}
                                                        setFreightCollectCodeHBL={setFreightCollectCodeHBL}
                                                        freightCollectNameHBL={freightCollectNameHBL}
                                                        setFreightCollectNameHBL={setFreightCollectNameHBL}
                                                        payableConsigneeIdHBL={payableConsigneeIdHBL}
                                                        setPayableConsigneeIdHBL={setPayableConsigneeIdHBL}
                                                        payableConsigneeNameHBL={payableConsigneeNameHBL}
                                                        setPayableConsigneeNameHBL={setPayableConsigneeNameHBL}
                                                        //SEA IMPORT
                                                        HBLCurrency={HBLCurrency}
                                                        setHBLCurrency={setHBLCurrency}
                                                        HBLAmount={HBLAmount}
                                                        setHBLAmount={setHBLAmount}

                                                        currency={currency}
                                                        setCurrency={setCurrency}
                                                        handlingInf={handlingInf}
                                                        setHandlingInf={setHandlingInf}

                                                        //Data Tab Description
                                                        MarkNumberDescription={MarkNumberDescription}
                                                        setMarkNumberDescription={setMarkNumberDescription}
                                                        NumberTypeDescription={NumberTypeDescription}
                                                        setNumberTypeDescription={setNumberTypeDescription}
                                                        GoodDescription={GoodDescription}
                                                        setGoodDescription={setGoodDescription}
                                                        GrossWeightDescription={GrossWeightDescription}
                                                        setGrossWeightDescription={setGrossWeightDescription}
                                                        MeasurementDescription={MeasurementDescription}
                                                        setMeasurementDescription={setMeasurementDescription}

                                                        TempMarkNumberDescription={TempMarkNumberDescription}
                                                        setTempMarkNumberDescription={setTempMarkNumberDescription}
                                                        TempNumberTypeDescription={TempNumberTypeDescription}
                                                        setTempNumberTypeDescription={setTempNumberTypeDescription}
                                                        TempGoodDescription={TempGoodDescription}
                                                        setTempGoodDescription={setTempGoodDescription}
                                                        TempGrossWeightDescription={TempGrossWeightDescription}
                                                        setTempGrossWeightDescription={setTempGrossWeightDescription}
                                                        TempMeasurementDescription={TempMeasurementDescription}
                                                        setTempMeasurementDescription={setTempMeasurementDescription}

                                                        setShippingMarksDesc={setShippingMarksDesc}
                                                        setNoPackageDesc={setNoPackageDesc}
                                                        setSIGoodDesc={setSIGoodDesc}
                                                        setWeightMeaDesc={setWeightMeaDesc}

                                                        //Main Data 2
                                                        OceanMSTBLNo={OceanMSTBLNo}
                                                        setOceanMSTBLNo={setOceanMSTBLNo}
                                                        VolumeBL={VolumeBL}
                                                        setVolumeBL={setVolumeBL}
                                                        VolumeInvoice={VolumeInvoice}
                                                        setVolumeInvoice={setVolumeInvoice}
                                                        SSLineId={SSLineId}
                                                        setSSLineId={setSSLineId}
                                                        SSLineName={SSLineName}
                                                        setSSLineName={setSSLineName}
                                                        EMKLId={EMKLId}
                                                        setEMKLId={setEMKLId}
                                                        EMKLName={EMKLName}
                                                        setEMKLName={setEMKLName}
                                                        DepoId={DepoId}
                                                        setDepoId={setDepoId}
                                                        DepoName={DepoName}
                                                        setDepoName={setDepoName}
                                                        //SEA IMPORT
                                                        HouseBLNo={HouseBLNo}
                                                        setHouseBLNo={setHouseBLNo}
                                                        SecondBLNo={SecondBLNo}
                                                        setSecondBLNo={setSecondBLNo}
                                                        WareHouseName={WareHouseName}
                                                        setWareHouseName={setWareHouseName}
                                                        Kins={Kins}
                                                        setKins={setKins}
                                                        CFName={CFName}
                                                        setCFName={setCFName}

                                                        //BL Data
                                                        setBLAgentId={setBLAgentId}
                                                        setBLAgentName={setBLAgentName}
                                                        setBLAgentAddress={setBLAgentAddress}

                                                        setBLShipperId={setBLShipperId}
                                                        setBLShipperName={setBLShipperName}
                                                        setBLShipperAddress={setBLShipperAddress}

                                                        setBLConsigneeId={setBLConsigneeId}
                                                        setBLConsigneeName={setBLConsigneeName}
                                                        setBLConsigneeAddress={setBLConsigneeAddress}

                                                        setBLNPartyId={setBLNPartyId}
                                                        setBLNPartyName={setBLNPartyName}
                                                        setBLNPartyAddress={setBLNPartyAddress}

                                                        //SI Data
                                                        setSIConsigneeId={setSIConsigneeId}
                                                        setSIConsigneeName={setSIConsigneeName}
                                                        setSIConsigneeAddress={setSIConsigneeAddress}

                                                        setSINPartyId={setSINPartyId}
                                                        setSINPartyName={setSINPartyName}
                                                        setSINPartyAddress={setSINPartyAddress}
                                                        isWithEPL={isWithEPL}
                                                        setIsWithEPL={setIsWithEPL}
                                                    />
                                            }
                                        </>
                                        :
                                        jobType.portType === 'AIR' ?
                                            <>
                                                {
                                                    jobType.code === '20' || jobType.code === '21' ?
                                                        <ShipmentOrderAir
                                                            isMarketing={isMarketing}
                                                            isJobClose={isJobClose}
                                                            jobType={jobType}
                                                            typeJobId={typeJobId}
                                                            bookingId={bookingId}
                                                            isEdit={isEdit}
                                                            selectContainerStatus={selectContainerStatus}
                                                            selectJobType={selectJobType}
                                                            selectCurrency={selectCurrency}
                                                            selectPrincipleBy={selectPrincipleBy}
                                                            selectShipmentStatus={selectShipmentStatus}
                                                            selectPackagingType={selectPackagingType}

                                                            dataTracking={dataTracking}
                                                            setDataTracking={setDataTracking}

                                                            period={period}
                                                            setPeriod={setPeriod}
                                                            yearPeriod={yearPeriod}
                                                            setYearPeriod={setYearPeriod}
                                                            isClosed={isClosed}
                                                            setIsClosed={setIsClosed}

                                                            //Main Data
                                                            ShipmentId={ShipmentId}
                                                            setShipmentId={setShipmentId}
                                                            ShipmentNo={ShipmentNo}
                                                            setShipmentNo={setShipmentNo}
                                                            TotalSub={SubJobNo}
                                                            setTotalSub={setTotalSub}
                                                            SIReference={SIReference}
                                                            setSIReference={setSIReference}
                                                            SIDate={SIDate}
                                                            setSIDate={setSIDate}
                                                            principleBy={principleBy}
                                                            GoodsRecDate={GoodsRecDate}
                                                            setGoodsRecDate={setGoodsRecDate}
                                                            BookingConfirmationId={BookingConfirmationId}
                                                            setBookingConfirmationId={setBookingConfirmationId}
                                                            BookingConfirmationNumber={BookingConfirmationNumber}
                                                            setBookingConfirmationNumber={setBookingConfirmationNumber}
                                                            GetBookingData={GetBookingData}
                                                            setTicketId={setTicketId}
                                                            setRequester={setRequester}

                                                            LoadStatus={LoadStatus}
                                                            setLoadStatus={setLoadStatus}
                                                            FreightStatus={FreightStatus}
                                                            setFreightStatus={setFreightStatus}
                                                            ShipmentStatus={ShipmentStatus}
                                                            setShipmentStatus={setShipmentStatus}
                                                            JobTypeId={JobTypeId}
                                                            setJobTypeId={setJobTypeId}
                                                            MarketId={MarketId}
                                                            setMarketId={setMarketId}
                                                            MarketName={MarketName}
                                                            setMarketName={setMarketName}
                                                            MarketCompanyId={MarketCompanyId}
                                                            setMarketCompanyId={setMarketCompanyId}

                                                            //Contact Data
                                                            contactTypeId={contactTypeId}
                                                            setContactTypeId={setContactTypeId}
                                                            contactType={contactType}

                                                            //Agent Data
                                                            AgentId={AgentId}
                                                            setAgentId={setAgentId}
                                                            AgentName={AgentName}
                                                            setAgentName={setAgentName}
                                                            AgentAddress={AgentAddress}
                                                            setAgentAddress={setAgentAddress}

                                                            setSIAgentId={setSIAgentId}
                                                            setSIAgentName={setSIAgentName}
                                                            setSIAgentAddress={setSIAgentAddress}

                                                            //Delivery Data
                                                            DeliveryId={DeliveryId}
                                                            setDeliveryId={setDeliveryId}
                                                            DeliveryName={DeliveryName}
                                                            setDeliveryName={setDeliveryName}
                                                            DeliveryAddress={DeliveryAddress}
                                                            setDeliveryAddress={setDeliveryAddress}

                                                            //Shipper Data
                                                            ShipperId={ShipperId}
                                                            setShipperId={setShipperId}
                                                            ShipperName={ShipperName}
                                                            setShipperName={setShipperName}
                                                            ShipperAddress={ShipperAddress}
                                                            setShipperAddress={setShipperAddress}

                                                            //Consignee Data
                                                            ConsigneeId={ConsigneeId}
                                                            setConsigneeId={setConsigneeId}
                                                            ConsigneeName={ConsigneeName}
                                                            setConsigneeName={setConsigneeName}
                                                            ConsigneeAddress={ConsigneeAddress}
                                                            setConsigneeAddress={setConsigneeAddress}

                                                            TempConsigneeId={TempConsigneeId}
                                                            setTempConsigneeId={setTempConsigneeId}
                                                            TempConsigneeName={TempConsigneeName}
                                                            setTempConsigneeName={setTempConsigneeName}
                                                            TempConsigneeAddress={TempConsigneeAddress}
                                                            setTempConsigneeAddress={setTempConsigneeAddress}

                                                            //NParty Data
                                                            NPartyId={NPartyId}
                                                            setNPartyId={setNPartyId}
                                                            NPartyName={NPartyName}
                                                            setNPartyName={setNPartyName}
                                                            NPartyAddress={NPartyAddress}
                                                            setNPartyAddress={setNPartyAddress}

                                                            //BookingParty Data
                                                            BookingPartyId={BookingPartyId}
                                                            setBookingPartyId={setBookingPartyId}
                                                            BookingPartyName={BookingPartyName}
                                                            setBookingPartyName={setBookingPartyName}
                                                            BookingPartyAddress={BookingPartyAddress}
                                                            setBookingPartyAddress={setBookingPartyAddress}

                                                            //Credit Info Data
                                                            billToShipperId={billToShipperId}
                                                            setBillToShipperId={setBillToShipperId}
                                                            billShipperName={billShipperName}
                                                            setBillShipperName={setBillShipperName}

                                                            billToConsigneeId={billToConsigneeId}
                                                            setBillToConsigneeId={setBillToConsigneeId}
                                                            billConsigneeName={billConsigneeName}
                                                            setBillConsigneeName={setBillConsigneeName}

                                                            creditTermUSD={creditTermUSD}
                                                            setCreditTermUSD={setCreditTermUSD}
                                                            creditTermDays={creditTermDays}
                                                            setCreditTermDays={setCreditTermDays}
                                                            creditTermIDR={creditTermIDR}
                                                            setCreditTermIDR={setCreditTermIDR}
                                                            ApprovedCredit={ApprovedCredit}
                                                            setApprovedCredit={setApprovedCredit}
                                                            outstandingInIDR={outstandingInIDR}
                                                            setOutstandingInIDR={setOutstandingInIDR}
                                                            outstandingInUSD={outstandingInUSD}
                                                            setOutstandingInUSD={setOutstandingInUSD}
                                                            freightAmountIDR={freightAmountIDR}
                                                            setFreightAmountIDR={setFreightAmountIDR}
                                                            freightAmountUSD={freightAmountUSD}
                                                            setFreightAmountUSD={setFreightAmountUSD}

                                                            //Detail Shipping Data
                                                            detailTypeId={detailTypeId}
                                                            setDetailTypeId={setDetailTypeId}

                                                            //Data Tab Flight
                                                            ReceiptPlaceId={ReceiptPlaceId}
                                                            setReceiptPlaceId={setReceiptPlaceId}
                                                            ReceiptPlaceCode={ReceiptPlaceCode}
                                                            setReceiptPlaceCode={setReceiptPlaceCode}
                                                            ReceiptPlaceName={ReceiptPlaceName}
                                                            setReceiptPlaceName={setReceiptPlaceName}

                                                            DepartureAirPortId={DepartureAirPortId}
                                                            setDepartureAirPortId={setDepartureAirPortId}
                                                            DepartureAirPortCode={DepartureAirPortCode}
                                                            setDepartureAirPortCode={setDepartureAirPortCode}
                                                            DepartureAirPortName={DepartureAirPortName}
                                                            setDepartureAirPortName={setDepartureAirPortName}

                                                            DestinationAirPortId={DestinationAirPortId}
                                                            setDestinationAirPortId={setDestinationAirPortId}
                                                            DestinationAirPortCode={DestinationAirPortCode}
                                                            setDestinationAirPortCode={setDestinationAirPortCode}
                                                            DestinationAirportName={DestinationAirportName}
                                                            setDestinationAirportName={setDestinationAirportName}

                                                            DeliveryPlaceId={DeliveryPlaceId}
                                                            setDeliveryPlaceId={setDeliveryPlaceId}
                                                            DeliveryPlaceCode={DeliveryPlaceCode}
                                                            setDeliveryPlaceCode={setDeliveryPlaceCode}
                                                            DeliveryPlaceName={DeliveryPlaceName}
                                                            setDeliveryPlaceName={setDeliveryPlaceName}

                                                            TempLoadingPortName={TempLoadingPortName}
                                                            setTempLoadingPortName={setTempLoadingPortName}
                                                            TempReceiptPlaceName={TempReceiptPlaceName}
                                                            setTempReceiptPlaceName={setTempReceiptPlaceName}
                                                            TempDischargePortName={TempDischargePortName}
                                                            setTempDischargePortName={setTempDischargePortName}
                                                            TempDeliveryPlaceName={TempDeliveryPlaceName}
                                                            setTempDeliveryPlaceName={setTempDeliveryPlaceName}

                                                            setBLReceiptPlaceName={setBLReceiptPlaceName}
                                                            setBLDischargePortName={setBLDischargePortName}
                                                            setBLLoadingPortName={setBLLoadingPortName}
                                                            setBLDeliveryPlaceName={setBLDeliveryPlaceName}

                                                            ETA={ETA}
                                                            setETA={setETA}
                                                            ETD={ETD}
                                                            setETD={setETD}
                                                            ATD={ATD}
                                                            setATD={setATD}
                                                            ATA={ATA}
                                                            setATA={setATA}

                                                            flightData={flightData}
                                                            setFlightData={setFlightData}

                                                            //Data Tab Freight
                                                            MAWBStatus={MAWBStatus}
                                                            setMAWBStatus={setMAWBStatus}
                                                            MAWBCollectId={MAWBCollectId}
                                                            setMAWBCollectId={setMAWBCollectId}
                                                            MAWBCollectCode={MAWBCollectCode}
                                                            setMAWBCollectCode={setMAWBCollectCode}
                                                            MAWBCollectName={MAWBCollectName}
                                                            setMAWBCollectName={setMAWBCollectName}
                                                            payableAgentIdMAWB={payableAgentIdMAWB}
                                                            setPayableAgentIdMAWB={setPayableAgentIdMAWB}
                                                            payableAgentNameMAWB={payableAgentNameMAWB}
                                                            setPayableAgentNameMAWB={setPayableAgentNameMAWB}
                                                            //AIR IMPORT
                                                            MAWBCurrency={MAWBCurrency}
                                                            setMAWBCurrency={setMAWBCurrency}
                                                            MAWBRate={MAWBRate}
                                                            setMAWBRate={setMAWBRate}

                                                            HAWBStatus={HAWBStatus}
                                                            setHAWBStatus={setHAWBStatus}
                                                            HAWBCollectId={HAWBCollectId}
                                                            setHAWBCollectId={setHAWBCollectId}
                                                            HAWBCollectCode={HAWBCollectCode}
                                                            setHAWBCollectCode={setHAWBCollectCode}
                                                            HAWBCollectName={HAWBCollectName}
                                                            setHAWBCollectName={setHAWBCollectName}
                                                            payableConsigneeIdHAWB={payableConsigneeIdHAWB}
                                                            setPayableConsigneeIdHAWB={setPayableConsigneeIdHAWB}
                                                            payableConsigneeNameHAWB={payableConsigneeNameHAWB}
                                                            setPayableConsigneeNameHAWB={setPayableConsigneeNameHAWB}
                                                            //AIR IMPORT
                                                            HAWBCurrency={HAWBCurrency}
                                                            setHAWBCurrency={setHAWBCurrency}
                                                            HAWBRate={HAWBRate}
                                                            setHAWBRate={setHAWBRate}

                                                            currency={currency}
                                                            setCurrency={setCurrency}
                                                            handlingInf={handlingInf}
                                                            setHandlingInf={setHandlingInf}

                                                            //Data Tab Description
                                                            PiecesRCP={PiecesRCP}
                                                            setPiecesRCP={setPiecesRCP}
                                                            GrossWeight={GrossWeight}
                                                            setGrossWeight={setGrossWeight}
                                                            KGLB={KGLB}
                                                            setKGLB={setKGLB}
                                                            ChargeWeight={ChargeWeight}
                                                            setChargeWeight={setChargeWeight}
                                                            ChargeRate={ChargeRate}
                                                            setChargeRate={setChargeRate}
                                                            Total={Total}
                                                            setTotal={setTotal}
                                                            Commodity={Commodity}
                                                            setCommodity={setCommodity}
                                                            PackagingCode={PackagingCode}
                                                            setPackagingCode={setPackagingCode}
                                                            GoodNatureQuantity={GoodNatureQuantity}
                                                            setGoodNatureQuantity={setGoodNatureQuantity}
                                                            Shipmark={Shipmark}
                                                            setShipmark={setShipmark}

                                                            //Data Tab Master Airway Bill
                                                            MAWBNo={MAWBNo}
                                                            setMAWBNo={setMAWBNo}
                                                            ChargeableWeight={ChargeableWeight}
                                                            setChargeableWeight={setChargeableWeight}
                                                            WeightHAWB={WeightHAWB}
                                                            setWeightHAWB={setWeightHAWB}
                                                            CarriageValue={CarriageValue}
                                                            setCarriageValue={setCarriageValue}
                                                            CustomValue={CustomValue}
                                                            setCustomValue={setCustomValue}
                                                            IATAId={IATAId}
                                                            setIATAId={setIATAId}
                                                            IATAName={IATAName}
                                                            setIATAName={setIATAName}
                                                            BrokerId={BrokerId}
                                                            setBrokerId={setBrokerId}
                                                            BrokerName={BrokerName}
                                                            setBrokerName={setBrokerName}
                                                            airLineName={airLineName}
                                                            setAirLineName={setAirLineName}
                                                            //AIR IMPORT
                                                            HAWBNo={HAWBNo}
                                                            setHAWBNo={setHAWBNo}

                                                            //BL Data
                                                            setBLAgentId={setBLAgentId}
                                                            setBLAgentName={setBLAgentName}
                                                            setBLAgentAddress={setBLAgentAddress}

                                                            setBLShipperId={setBLShipperId}
                                                            setBLShipperName={setBLShipperName}
                                                            setBLShipperAddress={setBLShipperAddress}

                                                            setBLConsigneeId={setBLConsigneeId}
                                                            setBLConsigneeName={setBLConsigneeName}
                                                            setBLConsigneeAddress={setBLConsigneeAddress}

                                                            setBLNPartyId={setBLNPartyId}
                                                            setBLNPartyName={setBLNPartyName}
                                                            setBLNPartyAddress={setBLNPartyAddress}

                                                            //SI Data
                                                            setSIConsigneeId={setSIConsigneeId}
                                                            setSIConsigneeName={setSIConsigneeName}
                                                            setSIConsigneeAddress={setSIConsigneeAddress}

                                                            setSINPartyId={setSINPartyId}
                                                            setSINPartyName={setSINPartyName}
                                                            setSINPartyAddress={setSINPartyAddress}
                                                            isWithEPL={isWithEPL}
                                                            setIsWithEPL={setIsWithEPL}
                                                        />
                                                        :
                                                        <ShipmentOrderPPJKAir
                                                            isMarketing={isMarketing}
                                                            isEdit={isEdit}
                                                            isJobClose={isJobClose}
                                                            jobType={jobType}
                                                            typeJobId={typeJobId}
                                                            bookingId={bookingId}
                                                            selectContainerStatus={selectContainerStatus}
                                                            selectJobType={selectJobType}
                                                            selectCurrency={selectCurrency}
                                                            selectPrincipleBy={selectPrincipleBy}
                                                            selectShipmentStatus={selectShipmentStatus}
                                                            selectPackagingType={selectPackagingType}

                                                            dataTracking={dataTracking}
                                                            setDataTracking={setDataTracking}

                                                            period={period}
                                                            setPeriod={setPeriod}
                                                            yearPeriod={yearPeriod}
                                                            setYearPeriod={setYearPeriod}
                                                            isClosed={isClosed}
                                                            setIsClosed={setIsClosed}

                                                            //Main Data
                                                            ShipmentId={ShipmentId}
                                                            setShipmentId={setShipmentId}
                                                            ShipmentNo={ShipmentNo}
                                                            setShipmentNo={setShipmentNo}
                                                            SIReference={SIReference}
                                                            setSIReference={setSIReference}
                                                            SIDate={SIDate}
                                                            setSIDate={setSIDate}
                                                            principleBy={principleBy}
                                                            GoodsRecDate={GoodsRecDate}
                                                            setGoodsRecDate={setGoodsRecDate}
                                                            BookingConfirmationId={BookingConfirmationId}
                                                            setBookingConfirmationId={setBookingConfirmationId}
                                                            BookingConfirmationNumber={BookingConfirmationNumber}
                                                            setBookingConfirmationNumber={setBookingConfirmationNumber}
                                                            setTicketId={setTicketId}
                                                            setRequester={setRequester}

                                                            GetBookingData={GetBookingData}

                                                            ShipmentStatus={ShipmentStatus}
                                                            setShipmentStatus={setShipmentStatus}
                                                            JobTypeId={JobTypeId}
                                                            setJobTypeId={setJobTypeId}
                                                            MarketId={MarketId}
                                                            setMarketId={setMarketId}
                                                            MarketName={MarketName}
                                                            setMarketName={setMarketName}
                                                            MarketCompanyId={MarketCompanyId}
                                                            setMarketCompanyId={setMarketCompanyId}

                                                            //Contact Data
                                                            contactTypeId={contactTypeId}
                                                            setContactTypeId={setContactTypeId}
                                                            contactType={contactType}

                                                            //Agent Data
                                                            AgentId={AgentId}
                                                            setAgentId={setAgentId}
                                                            AgentName={AgentName}
                                                            setAgentName={setAgentName}
                                                            AgentAddress={AgentAddress}
                                                            setAgentAddress={setAgentAddress}

                                                            setSIAgentId={setSIAgentId}
                                                            setSIAgentName={setSIAgentName}
                                                            setSIAgentAddress={setSIAgentAddress}

                                                            //Delivery Data
                                                            DeliveryId={DeliveryId}
                                                            setDeliveryId={setDeliveryId}
                                                            DeliveryName={DeliveryName}
                                                            setDeliveryName={setDeliveryName}
                                                            DeliveryAddress={DeliveryAddress}
                                                            setDeliveryAddress={setDeliveryAddress}

                                                            //Shipper Data
                                                            ShipperId={ShipperId}
                                                            setShipperId={setShipperId}
                                                            ShipperName={ShipperName}
                                                            setShipperName={setShipperName}
                                                            ShipperAddress={ShipperAddress}
                                                            setShipperAddress={setShipperAddress}

                                                            //Consignee Data
                                                            ConsigneeId={ConsigneeId}
                                                            setConsigneeId={setConsigneeId}
                                                            ConsigneeName={ConsigneeName}
                                                            setConsigneeName={setConsigneeName}
                                                            ConsigneeAddress={ConsigneeAddress}
                                                            setConsigneeAddress={setConsigneeAddress}

                                                            TempConsigneeId={TempConsigneeId}
                                                            setTempConsigneeId={setTempConsigneeId}
                                                            TempConsigneeName={TempConsigneeName}
                                                            setTempConsigneeName={setTempConsigneeName}
                                                            TempConsigneeAddress={TempConsigneeAddress}
                                                            setTempConsigneeAddress={setTempConsigneeAddress}

                                                            //NParty Data
                                                            NPartyId={NPartyId}
                                                            setNPartyId={setNPartyId}
                                                            NPartyName={NPartyName}
                                                            setNPartyName={setNPartyName}
                                                            NPartyAddress={NPartyAddress}
                                                            setNPartyAddress={setNPartyAddress}

                                                            //BookingParty Data
                                                            BookingPartyId={BookingPartyId}
                                                            setBookingPartyId={setBookingPartyId}
                                                            BookingPartyName={BookingPartyName}
                                                            setBookingPartyName={setBookingPartyName}
                                                            BookingPartyAddress={BookingPartyAddress}
                                                            setBookingPartyAddress={setBookingPartyAddress}

                                                            //Credit Info Data
                                                            billToShipperId={billToShipperId}
                                                            setBillToShipperId={setBillToShipperId}
                                                            billShipperName={billShipperName}
                                                            setBillShipperName={setBillShipperName}

                                                            billToConsigneeId={billToConsigneeId}
                                                            setBillToConsigneeId={setBillToConsigneeId}
                                                            billConsigneeName={billConsigneeName}
                                                            setBillConsigneeName={setBillConsigneeName}

                                                            creditTermUSD={creditTermUSD}
                                                            setCreditTermUSD={setCreditTermUSD}
                                                            creditTermDays={creditTermDays}
                                                            setCreditTermDays={setCreditTermDays}
                                                            creditTermIDR={creditTermIDR}
                                                            setCreditTermIDR={setCreditTermIDR}
                                                            ApprovedCredit={ApprovedCredit}
                                                            setApprovedCredit={setApprovedCredit}
                                                            outstandingInIDR={outstandingInIDR}
                                                            setOutstandingInIDR={setOutstandingInIDR}
                                                            outstandingInUSD={outstandingInUSD}
                                                            setOutstandingInUSD={setOutstandingInUSD}
                                                            freightAmountIDR={freightAmountIDR}
                                                            setFreightAmountIDR={setFreightAmountIDR}
                                                            freightAmountUSD={freightAmountUSD}
                                                            setFreightAmountUSD={setFreightAmountUSD}

                                                            //Detail Shipping Data
                                                            detailTypeId={detailTypeId}
                                                            setDetailTypeId={setDetailTypeId}

                                                            //Data Tab Flight
                                                            ReceiptPlaceId={ReceiptPlaceId}
                                                            setReceiptPlaceId={setReceiptPlaceId}
                                                            ReceiptPlaceCode={ReceiptPlaceCode}
                                                            setReceiptPlaceCode={setReceiptPlaceCode}
                                                            ReceiptPlaceName={ReceiptPlaceName}
                                                            setReceiptPlaceName={setReceiptPlaceName}

                                                            DepartureAirPortId={DepartureAirPortId}
                                                            setDepartureAirPortId={setDepartureAirPortId}
                                                            DepartureAirPortCode={DepartureAirPortCode}
                                                            setDepartureAirPortCode={setDepartureAirPortCode}
                                                            DepartureAirPortName={DepartureAirPortName}
                                                            setDepartureAirPortName={setDepartureAirPortName}

                                                            DestinationAirPortId={DestinationAirPortId}
                                                            setDestinationAirPortId={setDestinationAirPortId}
                                                            DestinationAirPortCode={DestinationAirPortCode}
                                                            setDestinationAirPortCode={setDestinationAirPortCode}
                                                            DestinationAirportName={DestinationAirportName}
                                                            setDestinationAirportName={setDestinationAirportName}

                                                            DeliveryPlaceId={DeliveryPlaceId}
                                                            setDeliveryPlaceId={setDeliveryPlaceId}
                                                            DeliveryPlaceCode={DeliveryPlaceCode}
                                                            setDeliveryPlaceCode={setDeliveryPlaceCode}
                                                            DeliveryPlaceName={DeliveryPlaceName}
                                                            setDeliveryPlaceName={setDeliveryPlaceName}

                                                            TempLoadingPortName={TempLoadingPortName}
                                                            setTempLoadingPortName={setTempLoadingPortName}
                                                            TempReceiptPlaceName={TempReceiptPlaceName}
                                                            setTempReceiptPlaceName={setTempReceiptPlaceName}
                                                            TempDischargePortName={TempDischargePortName}
                                                            setTempDischargePortName={setTempDischargePortName}
                                                            TempDeliveryPlaceName={TempDeliveryPlaceName}
                                                            setTempDeliveryPlaceName={setTempDeliveryPlaceName}

                                                            setBLReceiptPlaceName={setBLReceiptPlaceName}
                                                            setBLDischargePortName={setBLDischargePortName}
                                                            setBLLoadingPortName={setBLLoadingPortName}
                                                            setBLDeliveryPlaceName={setBLDeliveryPlaceName}

                                                            ETA={ETA}
                                                            setETA={setETA}
                                                            ETD={ETD}
                                                            setETD={setETD}
                                                            ATD={ATD}
                                                            setATD={setATD}
                                                            ATA={ATA}
                                                            setATA={setATA}

                                                            flightData={flightData}
                                                            setFlightData={setFlightData}

                                                            //Data Tab Freight
                                                            MAWBStatus={MAWBStatus}
                                                            setMAWBStatus={setMAWBStatus}
                                                            MAWBCollectId={MAWBCollectId}
                                                            setMAWBCollectId={setMAWBCollectId}
                                                            MAWBCollectCode={MAWBCollectCode}
                                                            setMAWBCollectCode={setMAWBCollectCode}
                                                            MAWBCollectName={MAWBCollectName}
                                                            setMAWBCollectName={setMAWBCollectName}
                                                            payableAgentIdMAWB={payableAgentIdMAWB}
                                                            setPayableAgentIdMAWB={setPayableAgentIdMAWB}
                                                            payableAgentNameMAWB={payableAgentNameMAWB}
                                                            setPayableAgentNameMAWB={setPayableAgentNameMAWB}
                                                            //AIR IMPORT
                                                            MAWBCurrency={MAWBCurrency}
                                                            setMAWBCurrency={setMAWBCurrency}
                                                            MAWBRate={MAWBRate}
                                                            setMAWBRate={setMAWBRate}

                                                            HAWBStatus={HAWBStatus}
                                                            setHAWBStatus={setHAWBStatus}
                                                            HAWBCollectId={HAWBCollectId}
                                                            setHAWBCollectId={setHAWBCollectId}
                                                            HAWBCollectCode={HAWBCollectCode}
                                                            setHAWBCollectCode={setHAWBCollectCode}
                                                            HAWBCollectName={HAWBCollectName}
                                                            setHAWBCollectName={setHAWBCollectName}
                                                            payableConsigneeIdHAWB={payableConsigneeIdHAWB}
                                                            setPayableConsigneeIdHAWB={setPayableConsigneeIdHAWB}
                                                            payableConsigneeNameHAWB={payableConsigneeNameHAWB}
                                                            setPayableConsigneeNameHAWB={setPayableConsigneeNameHAWB}
                                                            //AIR IMPORT
                                                            HAWBCurrency={HAWBCurrency}
                                                            setHAWBCurrency={setHAWBCurrency}
                                                            HAWBRate={HAWBRate}
                                                            setHAWBRate={setHAWBRate}

                                                            currency={currency}
                                                            setCurrency={setCurrency}
                                                            handlingInf={handlingInf}
                                                            setHandlingInf={setHandlingInf}

                                                            //Data Tab Description
                                                            PiecesRCP={PiecesRCP}
                                                            setPiecesRCP={setPiecesRCP}
                                                            GrossWeight={GrossWeight}
                                                            setGrossWeight={setGrossWeight}
                                                            KGLB={KGLB}
                                                            setKGLB={setKGLB}
                                                            ChargeWeight={ChargeWeight}
                                                            setChargeWeight={setChargeWeight}
                                                            ChargeRate={ChargeRate}
                                                            setChargeRate={setChargeRate}
                                                            Total={Total}
                                                            setTotal={setTotal}
                                                            Commodity={Commodity}
                                                            setCommodity={setCommodity}
                                                            PackagingCode={PackagingCode}
                                                            setPackagingCode={setPackagingCode}
                                                            GoodNatureQuantity={GoodNatureQuantity}
                                                            setGoodNatureQuantity={setGoodNatureQuantity}
                                                            Shipmark={Shipmark}
                                                            setShipmark={setShipmark}

                                                            //Data Tab Master Airway Bill
                                                            MAWBNo={MAWBNo}
                                                            setMAWBNo={setMAWBNo}
                                                            ChargeableWeight={ChargeableWeight}
                                                            setChargeableWeight={setChargeableWeight}
                                                            WeightHAWB={WeightHAWB}
                                                            setWeightHAWB={setWeightHAWB}
                                                            CarriageValue={CarriageValue}
                                                            setCarriageValue={setCarriageValue}
                                                            CustomValue={CustomValue}
                                                            setCustomValue={setCustomValue}
                                                            IATAId={IATAId}
                                                            setIATAId={setIATAId}
                                                            IATAName={IATAName}
                                                            setIATAName={setIATAName}
                                                            BrokerId={BrokerId}
                                                            setBrokerId={setBrokerId}
                                                            BrokerName={BrokerName}
                                                            setBrokerName={setBrokerName}
                                                            airLineName={airLineName}
                                                            setAirLineName={setAirLineName}
                                                            //AIR IMPORT
                                                            HAWBNo={HAWBNo}
                                                            setHAWBNo={setHAWBNo}

                                                            //BL Data
                                                            setBLAgentId={setBLAgentId}
                                                            setBLAgentName={setBLAgentName}
                                                            setBLAgentAddress={setBLAgentAddress}

                                                            setBLShipperId={setBLShipperId}
                                                            setBLShipperName={setBLShipperName}
                                                            setBLShipperAddress={setBLShipperAddress}

                                                            setBLConsigneeId={setBLConsigneeId}
                                                            setBLConsigneeName={setBLConsigneeName}
                                                            setBLConsigneeAddress={setBLConsigneeAddress}

                                                            setBLNPartyId={setBLNPartyId}
                                                            setBLNPartyName={setBLNPartyName}
                                                            setBLNPartyAddress={setBLNPartyAddress}

                                                            //SI Data
                                                            setSIConsigneeId={setSIConsigneeId}
                                                            setSIConsigneeName={setSIConsigneeName}
                                                            setSIConsigneeAddress={setSIConsigneeAddress}

                                                            setSINPartyId={setSINPartyId}
                                                            setSINPartyName={setSINPartyName}
                                                            setSINPartyAddress={setSINPartyAddress}
                                                            isWithEPL={isWithEPL}
                                                            setIsWithEPL={setIsWithEPL}
                                                        />
                                                }
                                            </>
                                            :
                                            <ShipmentOrderDomestic
                                                isMarketing={isMarketing}
                                                isEdit={isEdit}
                                                isJobClose={isJobClose}
                                                jobType={jobType}
                                                typeJobId={typeJobId}
                                                bookingId={bookingId}
                                                selectShipmentStatus={selectShipmentStatus}
                                                selectJobType={selectJobType}
                                                selectPrincipleBy={selectPrincipleBy}
                                                principleBy={principleBy}
                                                selectContainerType={selectContainerType}
                                                selectPackagingType={selectPackagingType}

                                                dataTracking={dataTracking}
                                                setDataTracking={setDataTracking}

                                                period={period}
                                                setPeriod={setPeriod}
                                                yearPeriod={yearPeriod}
                                                setYearPeriod={setYearPeriod}
                                                isClosed={isClosed}
                                                setIsClosed={setIsClosed}

                                                //Main Data
                                                ShipmentId={ShipmentId}
                                                setShipmentId={setShipmentId}
                                                ShipmentNo={ShipmentNo}
                                                setShipmentNo={setShipmentNo}
                                                SIReference={SIReference}
                                                setSIReference={setSIReference}
                                                SIDate={SIDate}
                                                setSIDate={setSIDate}
                                                GoodsRecDate={GoodsRecDate}
                                                setGoodsRecDate={setGoodsRecDate}
                                                BookingConfirmationId={BookingConfirmationId}
                                                setBookingConfirmationId={setBookingConfirmationId}
                                                BookingConfirmationNumber={BookingConfirmationNumber}
                                                setBookingConfirmationNumber={setBookingConfirmationNumber}
                                                setTicketId={setTicketId}
                                                setRequester={setRequester}

                                                GetBookingData={GetBookingData}

                                                LoadStatus={LoadStatus}
                                                setLoadStatus={setLoadStatus}
                                                ShipmentStatus={ShipmentStatus}
                                                setShipmentStatus={setShipmentStatus}
                                                JobTypeId={JobTypeId}
                                                setJobTypeId={setJobTypeId}
                                                MarketId={MarketId}
                                                setMarketId={setMarketId}
                                                MarketName={MarketName}
                                                setMarketName={setMarketName}
                                                MarketCompanyId={MarketCompanyId}
                                                setMarketCompanyId={setMarketCompanyId}

                                                //Contact Data
                                                contactTypeId={contactTypeId}
                                                setContactTypeId={setContactTypeId}
                                                contactType={contactType}
                                                setContactType={setContactType}

                                                //Agent Data
                                                AgentId={AgentId}
                                                setAgentId={setAgentId}
                                                AgentName={AgentName}
                                                setAgentName={setAgentName}
                                                AgentAddress={AgentAddress}
                                                setAgentAddress={setAgentAddress}
                                                setSIAgentId={setSIAgentId}
                                                setSIAgentName={setSIAgentName}
                                                setSIAgentAddress={setSIAgentAddress}

                                                //Shipper Data
                                                ShipperId={ShipperId}
                                                setShipperId={setShipperId}
                                                ShipperName={ShipperName}
                                                setShipperName={setShipperName}
                                                ShipperAddress={ShipperAddress}
                                                setShipperAddress={setShipperAddress}

                                                //Consignee Data
                                                ConsigneeId={ConsigneeId}
                                                setConsigneeId={setConsigneeId}
                                                ConsigneeName={ConsigneeName}
                                                setConsigneeName={setConsigneeName}
                                                ConsigneeAddress={ConsigneeAddress}
                                                setConsigneeAddress={setConsigneeAddress}

                                                TempConsigneeId={TempConsigneeId}
                                                setTempConsigneeId={setTempConsigneeId}
                                                TempConsigneeName={TempConsigneeName}
                                                setTempConsigneeName={setTempConsigneeName}
                                                TempConsigneeAddress={TempConsigneeAddress}
                                                setTempConsigneeAddress={setTempConsigneeAddress}

                                                //NParty Data
                                                NPartyId={NPartyId}
                                                setNPartyId={setNPartyId}
                                                NPartyName={NPartyName}
                                                setNPartyName={setNPartyName}
                                                NPartyAddress={NPartyAddress}
                                                setNPartyAddress={setNPartyAddress}

                                                //BookingParty Data
                                                BookingPartyId={BookingPartyId}
                                                setBookingPartyId={setBookingPartyId}
                                                BookingPartyName={BookingPartyName}
                                                setBookingPartyName={setBookingPartyName}
                                                BookingPartyAddress={BookingPartyAddress}
                                                setBookingPartyAddress={setBookingPartyAddress}

                                                //Credit Info Data
                                                billToShipperId={billToShipperId}
                                                setBillToShipperId={setBillToShipperId}
                                                billShipperName={billShipperName}
                                                setBillShipperName={setBillShipperName}

                                                creditTermUSD={creditTermUSD}
                                                setCreditTermUSD={setCreditTermUSD}
                                                creditTermDays={creditTermDays}
                                                setCreditTermDays={setCreditTermDays}
                                                creditTermIDR={creditTermIDR}
                                                setCreditTermIDR={setCreditTermIDR}
                                                ApprovedCredit={ApprovedCredit}
                                                setApprovedCredit={setApprovedCredit}
                                                outstandingInIDR={outstandingInIDR}
                                                setOutstandingInIDR={setOutstandingInIDR}
                                                outstandingInUSD={outstandingInUSD}
                                                setOutstandingInUSD={setOutstandingInUSD}
                                                freightAmountIDR={freightAmountIDR}
                                                setFreightAmountIDR={setFreightAmountIDR}
                                                freightAmountUSD={freightAmountUSD}
                                                setFreightAmountUSD={setFreightAmountUSD}

                                                //Detail Shipping Data
                                                detailTypeId={detailTypeId}
                                                setDetailTypeId={setDetailTypeId}

                                                //Data Tab Vessel
                                                ReceiptPlaceId={ReceiptPlaceId}
                                                setReceiptPlaceId={setReceiptPlaceId}
                                                ReceiptPlaceCode={ReceiptPlaceCode}
                                                setReceiptPlaceCode={setReceiptPlaceCode}
                                                ReceiptPlaceName={ReceiptPlaceName}
                                                setReceiptPlaceName={setReceiptPlaceName}

                                                DeliveryPlaceId={DeliveryPlaceId}
                                                setDeliveryPlaceId={setDeliveryPlaceId}
                                                DeliveryPlaceCode={DeliveryPlaceCode}
                                                setDeliveryPlaceCode={setDeliveryPlaceCode}
                                                DeliveryPlaceName={DeliveryPlaceName}
                                                setDeliveryPlaceName={setDeliveryPlaceName}

                                                TempReceiptPlaceName={TempReceiptPlaceName}
                                                setTempReceiptPlaceName={setTempReceiptPlaceName}
                                                TempDeliveryPlaceName={TempDeliveryPlaceName}
                                                setTempDeliveryPlaceName={setTempDeliveryPlaceName}

                                                setBLReceiptPlaceName={setBLReceiptPlaceName}
                                                setBLDischargePortName={setBLDischargePortName}
                                                setBLLoadingPortName={setBLLoadingPortName}
                                                setBLDeliveryPlaceName={setBLDeliveryPlaceName}

                                                ETD={ETD}
                                                setETD={setETD}
                                                ATD={ATD}
                                                setATD={setATD}
                                                ETA={ETA}
                                                setETA={setETA}
                                                ATA={ATA}
                                                setATA={setATA}

                                                VesselFlight={VesselFlight}
                                                setVesselFlight={setVesselFlight}
                                                VesselId={VesselId}
                                                setVesselId={setVesselId}
                                                VesselName={VesselName}
                                                setVesselName={setVesselName}
                                                Voyage={Voyage}
                                                setVoyage={setVoyage}
                                                cityId={cityId}
                                                setCityId={setCityId}
                                                PortId={PortId}
                                                setPortId={setPortId}
                                                PortCode={PortCode}
                                                setPortCode={setPortCode}
                                                PortName={PortName}
                                                setPortName={setPortName}
                                                FlightId={FlightId}
                                                setFlightId={setFlightId}
                                                FlightCode={FlightCode}
                                                setFlightCode={setFlightCode}
                                                FlightName={FlightName}
                                                setFlightName={setFlightName}
                                                FlightNo={FlightNo}
                                                setFlightNo={setFlightNo}

                                                //Data Tab Container
                                                data20={data20}
                                                setData20={setData20}
                                                data40={data40}
                                                setData40={setData40}
                                                data45={data45}
                                                setData45={setData45}
                                                dataAll={dataAll}
                                                setDataAll={setDataAll}

                                                containerData={containerData}
                                                setContainerData={setContainerData}
                                                getContainerSizeData={getContainerSizeData}

                                                CopyContainer={CopyContainer}
                                                setCopyContainer={setCopyContainer}
                                                createCopyContainer={createCopyContainer}
                                                totalNumberCont={totalNumberCont}
                                                setTotalNumberCont={setTotalNumberCont}

                                                //Data Tab Freight
                                                freightStatusHBL={freightStatusHBL}
                                                setFreightStatusHBL={setFreightStatusHBL}
                                                freightCollectIdHBL={freightCollectIdHBL}
                                                setFreightCollectIdHBL={setFreightCollectIdHBL}
                                                freightCollectCodeHBL={freightCollectCodeHBL}
                                                setFreightCollectCodeHBL={setFreightCollectCodeHBL}
                                                freightCollectNameHBL={freightCollectNameHBL}
                                                setFreightCollectNameHBL={setFreightCollectNameHBL}
                                                payableConsigneeIdHBL={payableConsigneeIdHBL}
                                                setPayableConsigneeIdHBL={setPayableConsigneeIdHBL}
                                                payableConsigneeNameHBL={payableConsigneeNameHBL}
                                                setPayableConsigneeNameHBL={setPayableConsigneeNameHBL}

                                                handlingInf={handlingInf}
                                                setHandlingInf={setHandlingInf}

                                                //Data Tab Description
                                                MarkNumberDescription={MarkNumberDescription}
                                                setMarkNumberDescription={setMarkNumberDescription}
                                                NumberTypeDescription={NumberTypeDescription}
                                                setNumberTypeDescription={setNumberTypeDescription}
                                                GoodDescription={GoodDescription}
                                                setGoodDescription={setGoodDescription}
                                                GrossWeightDescription={GrossWeightDescription}
                                                setGrossWeightDescription={setGrossWeightDescription}
                                                MeasurementDescription={MeasurementDescription}
                                                setMeasurementDescription={setMeasurementDescription}

                                                TempMarkNumberDescription={TempMarkNumberDescription}
                                                setTempMarkNumberDescription={setTempMarkNumberDescription}
                                                TempNumberTypeDescription={TempNumberTypeDescription}
                                                setTempNumberTypeDescription={setTempNumberTypeDescription}
                                                TempGoodDescription={TempGoodDescription}
                                                setTempGoodDescription={setTempGoodDescription}
                                                TempGrossWeightDescription={TempGrossWeightDescription}
                                                setTempGrossWeightDescription={setTempGrossWeightDescription}
                                                TempMeasurementDescription={TempMeasurementDescription}
                                                setTempMeasurementDescription={setTempMeasurementDescription}

                                                setShippingMarksDesc={setShippingMarksDesc}
                                                setNoPackageDesc={setNoPackageDesc}
                                                setSIGoodDesc={setSIGoodDesc}
                                                setWeightMeaDesc={setWeightMeaDesc}

                                                //Domestic
                                                //Data Tab Con Note
                                                ConNoteData={ConNoteData}
                                                setConNoteData={setConNoteData}

                                                //Main Data 2
                                                OceanMSTBLNo={OceanMSTBLNo}
                                                setOceanMSTBLNo={setOceanMSTBLNo}
                                                VolumeBL={VolumeBL}
                                                setVolumeBL={setVolumeBL}
                                                VolumeInvoice={VolumeInvoice}
                                                setVolumeInvoice={setVolumeInvoice}
                                                SSLineId={SSLineId}
                                                setSSLineId={setSSLineId}
                                                SSLineName={SSLineName}
                                                setSSLineName={setSSLineName}
                                                EMKLId={EMKLId}
                                                setEMKLId={setEMKLId}
                                                EMKLName={EMKLName}
                                                setEMKLName={setEMKLName}
                                                IATAId={IATAId}
                                                setIATAId={setIATAId}
                                                IATAName={IATAName}
                                                setIATAName={setIATAName}
                                                BrokerId={BrokerId}
                                                setBrokerId={setBrokerId}
                                                BrokerName={BrokerName}
                                                setBrokerName={setBrokerName}
                                                CarriageValue={CarriageValue}
                                                setCarriageValue={setCarriageValue}
                                                CustomValue={CustomValue}
                                                setCustomValue={setCustomValue}

                                                //Domistic
                                                Truck={Truck}
                                                setTruck={setTruck}

                                                //BL Data
                                                setBLAgentId={setBLAgentId}
                                                setBLAgentName={setBLAgentName}
                                                setBLAgentAddress={setBLAgentAddress}

                                                setBLShipperId={setBLShipperId}
                                                setBLShipperName={setBLShipperName}
                                                setBLShipperAddress={setBLShipperAddress}

                                                setBLConsigneeId={setBLConsigneeId}
                                                setBLConsigneeName={setBLConsigneeName}
                                                setBLConsigneeAddress={setBLConsigneeAddress}

                                                setBLNPartyId={setBLNPartyId}
                                                setBLNPartyName={setBLNPartyName}
                                                setBLNPartyAddress={setBLNPartyAddress}

                                                //SI Data
                                                setSIConsigneeId={setSIConsigneeId}
                                                setSIConsigneeName={setSIConsigneeName}
                                                setSIConsigneeAddress={setSIConsigneeAddress}

                                                setSINPartyId={setSINPartyId}
                                                setSINPartyName={setSINPartyName}
                                                setSINPartyAddress={setSINPartyAddress}
                                                isWithEPL={isWithEPL}
                                                setIsWithEPL={setIsWithEPL}
                                            />
                                }
                            </CardContent>
                        </Card>

                        {
                            jobType.serviceType === 'Export' && (jobType.portType === 'SEA' || jobType.portType === 'AIR') ?
                                <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                                    <CardContent>
                                        <BillOfLading
                                            jobType={jobType}
                                            isJobClose={isJobClose}

                                            //Main Data
                                            BLNumber={BLNumber}
                                            setBLNumber={setBLNumber}
                                            placeDateIssue={placeDateIssue}
                                            setPlaceDateIssue={setPlaceDateIssue}
                                            noBLData={noBLData}
                                            setNoBLData={setNoBLData}
                                            BLFormId={BLFormId}
                                            setBLFormId={setBLFormId}
                                            BLFormName={BLFormName}
                                            setBLFormName={setBLFormName}

                                            shipmentOnBoard={shipmentOnBoard}
                                            setShipmentOnBoard={setShipmentOnBoard}
                                            totalNumberCont={totalNumberCont}
                                            setTotalNumberCont={setTotalNumberCont}
                                            cargoInsurance={cargoInsurance}
                                            setCargoInsurance={setCargoInsurance}
                                            freightAmount={freightAmount}
                                            setFreightAmount={setFreightAmount}
                                            freightPayable={freightPayable}
                                            setFreightPayable={setFreightPayable}
                                            descriptionBoL={descriptionBoL}
                                            setDescriptionBoL={setDescriptionBoL}

                                            //AIR
                                            HAWBFee={HAWBFee}
                                            setHAWBFee={setHAWBFee}
                                            AmountInsurance={AmountInsurance}
                                            setAmountInsurance={setAmountInsurance}

                                            ////Var for print status
                                            printDraft={printDraft}
                                            setPrintDraft={setPrintDraft}
                                            printDraftDate={printDraftDate}
                                            setPrintDraftDate={setPrintDraftDate}
                                            printFixed={printFixed}
                                            setPrintFixed={setPrintFixed}
                                            printFixedDate={printFixedDate}
                                            setPrintFixedDate={setPrintFixedDate}

                                            //Contact Data
                                            contactTypeId={contactTypeId}
                                            setContactTypeId={setContactTypeId}
                                            contactType={contactType}

                                            AgentId={BLAgentId}
                                            setAgentId={setBLAgentId}
                                            AgentName={BLAgentName}
                                            setAgentName={setBLAgentName}
                                            AgentAddress={BLAgentAddress}
                                            setAgentAddress={setBLAgentAddress}

                                            ShipperId={BLShipperId}
                                            setShipperId={setBLShipperId}
                                            ShipperName={BLShipperName}
                                            setShipperName={setBLShipperName}
                                            ShipperAddress={BLShipperAddress}
                                            setShipperAddress={setBLShipperAddress}

                                            ConsigneeId={BLConsigneeId}
                                            setConsigneeId={setBLConsigneeId}
                                            ConsigneeName={BLConsigneeName}
                                            setConsigneeName={setBLConsigneeName}
                                            ConsigneeAddress={BLConsigneeAddress}
                                            setConsigneeAddress={setBLConsigneeAddress}

                                            NPartyId={BLNPartyId}
                                            setNPartyId={setBLNPartyId}
                                            NPartyName={BLNPartyName}
                                            setNPartyName={setBLNPartyName}
                                            NPartyAddress={BLNPartyAddress}
                                            setNPartyAddress={setBLNPartyAddress}

                                            SNPartyId={BLSNPartyId}
                                            setSNPartyId={setBLSNPartyId}
                                            SNPartyName={BLSNPartyName}
                                            setSNPartyName={setBLSNPartyName}
                                            SNPartyAddress={BLSNPartyAddress}
                                            setSNPartyAddress={setBLSNPartyAddress}

                                            RefAgentId={AgentId}
                                            RefAgentName={AgentName}
                                            RefAgentAddress={AgentAddress}

                                            RefShipperId={ShipperId}
                                            RefShipperName={ShipperName}
                                            RefShipperAddress={ShipperAddress}

                                            RefConsigneeId={ConsigneeId}
                                            RefConsigneeName={ConsigneeName}
                                            RefConsigneeAddress={ConsigneeAddress}

                                            RefNPartyId={NPartyId}
                                            RefNPartyName={NPartyName}
                                            RefNPartyAddress={NPartyAddress}
                                        />
                                    </CardContent>
                                </Card>
                                :
                                <></>
                        }

                        {
                            jobType.serviceType === 'Export' && (jobType.portType === 'SEA' || jobType.portType === 'AIR') ?
                                <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                                    <CardContent>
                                        <ShippingInstruction
                                            jobType={jobType}
                                            selectPackagingType={selectPackagingType}
                                            isJobClose={isJobClose}

                                            Reference={Reference}
                                            setReference={setReference}
                                            Attention={Attention}
                                            setAttention={setAttention}

                                            SIUpdatedCount={SIUpdatedCount}
                                            setSIUpdatedCount={setSIUpdatedCount}
                                            lastUpdated={lastUpdated}
                                            setLastUpdated={setLastUpdated}
                                            PrintingUpdatedCount={PrintingUpdatedCount}
                                            setPrintingUpdatedCount={setPrintingUpdatedCount}
                                            datePrint={datePrint}
                                            setDatePrint={setDatePrint}

                                            //DATA CONTACT
                                            SICompanyId={SICompanyId}
                                            setSICompanyId={setSICompanyId}
                                            SICompanyName={SICompanyName}
                                            setSICompanyName={setSICompanyName}
                                            SICompanyAddress={SICompanyAddress}
                                            setSICompanyAddress={setSICompanyAddress}

                                            SIAgentId={SIAgentId}
                                            SIAgentName={SIAgentName}
                                            SIAgentAddress={SIAgentAddress}

                                            ConsigneeId={SIConsigneeId}
                                            setConsigneeId={setSIConsigneeId}
                                            ConsigneeName={SIConsigneeName}
                                            setConsigneeName={setSIConsigneeName}
                                            ConsigneeAddress={SIConsigneeAddress}
                                            setConsigneeAddress={setSIConsigneeAddress}

                                            NPartyId={SINPartyId}
                                            setNPartyId={setSINPartyId}
                                            NPartyName={SINPartyName}
                                            setNPartyName={setSINPartyName}
                                            NPartyAddress={SINPartyAddress}
                                            setNPartyAddress={setSINPartyAddress}

                                            //SEA EXPORT
                                            ShippingMarksDesc={ShippingMarksDesc}
                                            setShippingMarksDesc={setShippingMarksDesc}
                                            NoPackageDesc={NoPackageDesc}
                                            setNoPackageDesc={setNoPackageDesc}
                                            SIGoodDesc={SIGoodDesc}
                                            setSIGoodDesc={setSIGoodDesc}
                                            WeightMeaDesc={WeightMeaDesc}
                                            setWeightMeaDesc={setWeightMeaDesc}

                                            //AIR EXPORT
                                            SIPiecesRCP={SIPiecesRCP}
                                            setSIPiecesRCP={setSIPiecesRCP}
                                            SIGrossWeight={SIGrossWeight}
                                            setSIGrossWeight={setSIGrossWeight}
                                            SIKGLB={SIKGLB}
                                            setSIKGLB={setSIKGLB}
                                            SIChargeWeight={SIChargeWeight}
                                            setSIChargeWeight={setSIChargeWeight}
                                            SIChargeRate={SIChargeRate}
                                            setSIChargeRate={setSIChargeRate}
                                            SITotal={SITotal}
                                            setSITotal={setSITotal}
                                            SIGoodNatureQ={SIGoodNatureQ}
                                            setSIGoodNatureQ={setSIGoodNatureQ}
                                            SICommodity={SICommodity}
                                            setSICommodity={setSICommodity}
                                            SIPackageCode={SIPackageCode}
                                            setSIPackageCode={setSIPackageCode}
                                            SIShipmark={SIShipmark}
                                            setSIShipmark={setSIShipmark}

                                            SIOriginalBL={SIOriginalBL}
                                            setSIOriginalBL={setSIOriginalBL}
                                            SISpecialIns={SISpecialIns}
                                            setSISpecialIns={setSISpecialIns}

                                            freightOBL={freightOBL}
                                            setFreightOBL={setFreightOBL}
                                            SICollectAgentId={SICollectAgentId}
                                            setSICollectAgentId={setSICollectAgentId}
                                            SICollectAgentName={SICollectAgentName}
                                            setSICollectAgentName={setSICollectAgentName}
                                            SICollectAgentAddress={SICollectAgentAddress}
                                            setSICollectAgentAddress={setSICollectAgentAddress}
                                        />
                                    </CardContent>
                                </Card>
                                :
                                <></>
                        }

                        {
                            jobType.jobTypeId === 1 || jobType.jobTypeId === 5 ?
                                <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                                    <CardContent>
                                        <ShipmentAdvice
                                            jobType={jobType}
                                            isJobClose={isJobClose}

                                            //Main Data
                                            SAReference={SAReference}
                                            setSAReference={setSAReference}
                                            SARemarks={SARemarks}
                                            setSARemarks={setSARemarks}
                                            SAPrintAgentId={SAPrintAgentId}
                                            setSAPrintAgentId={setSAPrintAgentId}
                                            SAPrintAgentName={SAPrintAgentName}
                                            setSAPrintAgentName={setSAPrintAgentName}
                                            SAPrintDeliveryId={SAPrintDeliveryId}
                                            setSAPrintDeliveryId={setSAPrintDeliveryId}
                                            SAPrintDeliveryName={SAPrintDeliveryName}
                                            setSAPrintDeliveryName={setSAPrintDeliveryName}
                                        />
                                    </CardContent>
                                </Card>
                                :
                                <></>
                        }

                        {
                            jobType.serviceType === 'Export' && (jobType.portType === 'SEA' || jobType.portType === 'AIR') ?
                                <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                                    <CardContent>
                                        <TelexRelease
                                            jobType={jobType}
                                            isJobClose={isJobClose}

                                            //Main Data
                                            TRFullSetStatus={TRFullSetStatus}
                                            setTRFullSetStatus={setTRFullSetStatus}
                                            TRReleaseStatus={TRReleaseStatus}
                                            setTRReleaseStatus={setTRReleaseStatus}
                                            TRPrintCount={TRPrintCount}
                                            setTRPrintCount={setTRPrintCount}
                                            TRPrintDate={TRPrintDate}
                                            setTRPrintDate={setTRPrintDate}
                                        />
                                    </CardContent>
                                </Card>
                                :
                                <></>
                        }

                        {
                            shipmentId ?
                                <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                                    <CardContent>
                                        <Tracking
                                            jobType={jobType}
                                            selectTrackingStatus={selectTrackingStatus}
                                            isJobClose={isJobClose}

                                            ShipmentId={ShipmentId}
                                            ShipmentNo={ShipmentNo}
                                            ETD={ETD}
                                            ETA={ETA}
                                            ReceiptPlaceName={ReceiptPlaceName}
                                            DeliveryPlaceName={DeliveryPlaceName}
                                            DischargePortName={DischargePortName}
                                            LoadingPortName={LoadingPortName}
                                            DepartureAirPortName={DepartureAirPortName}
                                            DestinationAirportName={DestinationAirportName}
                                            OceanMSTBLNo={OceanMSTBLNo}
                                            HouseBLNo={HouseBLNo}
                                            MAWBNo={MAWBNo}
                                            HAWBNo={HAWBNo}

                                            containerData={containerData}
                                            vesselData={vesselData}
                                            ConNoteData={ConNoteData}
                                            flightData={flightData}
                                            dataTracking={dataTracking}
                                            setDataTracking={setDataTracking}

                                            VesselFlight={VesselFlight}
                                            setVesselFlight={setVesselFlight}
                                            VesselId={VesselId}
                                            setVesselId={setVesselId}
                                            VesselName={VesselName}
                                            setVesselName={setVesselName}
                                            Voyage={Voyage}
                                            setVoyage={setVoyage}
                                            cityIdD={cityId}
                                            setCityIdD={setCityId}
                                            PortIdD={PortId}
                                            setPortIdD={setPortId}
                                            PortCodeD={PortCode}
                                            setPortCodeD={setPortCode}
                                            PortNameD={PortName}
                                            setPortNameD={setPortName}
                                            FlightId={FlightId}
                                            setFlightId={setFlightId}
                                            FlightCode={FlightCode}
                                            setFlightCode={setFlightCode}
                                            FlightName={FlightName}
                                            setFlightName={setFlightName}
                                            FlightNo={FlightNo}
                                            setFlightNo={setFlightNo}
                                        />
                                    </CardContent>
                                </Card>
                                :
                                <></>
                        }
                    </fieldset>
                </Grid>

                {
                    isModal && (
                        <ModalDeliveryOrder
                            isModal={isModal}
                            handleClick={handleClick}
                            titleModal={titleModal}
                            SaveData={() => handlePrintGeneral('DO')}

                            emptyState={emptyState}
                            isLoadingBackDrop={isLoadingBackDrop}
                            setIsLoadingBackDrop={setIsLoadingBackDrop}
                            Company={SICompanyName}
                            Reference={ShipmentNo}
                            Discharge={DischargePortName}
                            Delivery={DeliveryPlaceName}
                            Shipper={ShipperName}
                            ShipmentId={shipmentId}

                            IntendVessel={IntendVessel}
                            DOETD={DOETD}
                            ToDO={ToDO}
                            setToDO={setToDO}
                            AttentionDO={AttentionDO}
                            setAttentionDO={setAttentionDO}
                            QuantityDO={QuantityDO}
                            setQuantityDO={setQuantityDO}
                            ContainerDO={ContainerDO}
                            setContainerDO={setContainerDO}
                            SealDO={SealDO}
                            setSealDO={setSealDO}
                            CommodityDO={CommodityDO}
                            setCommodityDo={setCommodityDo}
                            RemarksDO={RemarksDO}
                            setRemarksDO={setRemarksDO}
                            OperatorDO={OperatorDO}
                            setOperatorDO={setOperatorDO}
                            GudangName={GudangName}
                            setGudangName={setGudangName}
                            isNew={isNew}
                            jobType={jobType}
                            selectPrincipleBy={selectPrincipleBy}
                        />
                    )
                }

                {
                    isModal2 && (
                        <ModalBLReport
                            isModal={isModal2}
                            handleClick={handleClick2}
                            titleModal={titleModal2}
                            SaveData={() => handlePrintGeneral('BL')}

                            setIsLoadingBackDrop={setIsLoadingBackDrop}
                            BLNumber={BLNumber}
                            BLName={BLFormName}
                            Reference={ShipmentNo}
                            ShipmentId={shipmentId}
                            DocumentType={DocumentType}
                            setDocumentType={setDocumentType}

                            carrier={carrier}
                            setCarrier={setCarrier}
                            bolIssue={bolIssue}
                            setBolIssue={setBolIssue}
                            changeDes={changeDes}
                            setChangeDes={setChangeDes}
                            desCollect={desCollect}
                            setDesCollect={setDesCollect}

                            AgentName={BLAgentName}
                            setAgentName={setBLAgentName}
                            ShipperName={BLShipperName}
                            setShipperName={setBLShipperName}
                            ConsigneeName={BLConsigneeName}
                            setConsigneeName={setBLConsigneeName}
                            NPartyName={BLNPartyName}
                            setNPartyName={setBLNPartyName}
                            AgentAddress={BLAgentAddress}
                            setAgentAddress={setBLAgentAddress}
                            ShipperAddress={BLShipperAddress}
                            setShipperAddress={setBLShipperAddress}
                            ConsigneeAddress={BLConsigneeAddress}
                            setConsigneeAddress={setBLConsigneeAddress}
                            NPartyAddress={BLNPartyAddress}
                            setNPartyAddress={setBLNPartyAddress}
                            SNPartyName={BLSNPartyName}
                            setSNPartyName={setBLSNPartyName}
                            SNPartyAddress={BLSNPartyAddress}
                            setSNPartyAddress={setBLSNPartyAddress}

                            ReceiptPlaceName={BLReceiptPlaceName}
                            setReceiptPlaceName={setBLReceiptPlaceName}
                            DischargePortName={BLDischargePortName}
                            setDischargePortName={setBLDischargePortName}
                            LoadingPortName={BLLoadingPortName}
                            setLoadingPortName={setBLLoadingPortName}
                            DeliveryPlaceName={BLDeliveryPlaceName}
                            setDeliveryPlaceName={setBLDeliveryPlaceName}
                        />
                    )
                }

                {
                    isModalConfirmation && (
                        <ModalConfirmation
                            isModal={isModalConfirmation}
                            titleModal={titleModalConfirmation}
                            title={titleConfirmation}
                            handleClick={handleClickConfirmation}
                            ConfirmationData={ConfirmationSO}

                            isRemarks={isRemarks}
                            Remarks={ApprovedCreditRemarks}
                            setRemarks={setApprovedCreditRemarks}
                        />
                    )
                }
            </Box>

            <ButtonInfo
                ButtonComponents={ButtonComponents}
            />

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: 1000000 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default CreateEditShipment;