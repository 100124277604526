import React, { useState } from 'react';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import { Grid } from '@mui/material';

const ButtonCreateEditShipment = ({
    check, Save, isJobClose, DataRegUserAccess, isEdit,
    jobType, PrintBL, PrintSI, PrintSA, PrintSAD, PrintDO, PrintTLX,
    shipmentId, CloseJob, jobClose, OpenJob, Approve, UpdateSub
}) => {
    const [isPrintSA, setIsPrintSA] = useState('To Agent');

    const handlePrintSA = (value) => {
        setIsPrintSA(value);
    };

    const renderButtons = (accessCode) => {
        switch (accessCode) {
            case 'PRI':
                if (isEdit && (jobType.portType === 'SEA' || jobType.portType === 'AIR')) {
                    return (
                        <>
                            {jobType.serviceType === 'Export' && (
                                <>
                                    {ButtonComponent.PrintButton(PrintBL, 'BL', true)}
                                    {ButtonComponent.PrintButton(PrintSI, 'SI', true)}
                                    {CustomizeButtonComponent.DropDownButtonPrintSA(isPrintSA, handlePrintSA, PrintSA, PrintSAD)}
                                </>
                            )}
                            {ButtonComponent.PrintButton(PrintDO, 'DO', true)}
                            {jobType.serviceType === 'Export' && ButtonComponent.PrintButton(PrintTLX, 'TLX', true)}
                        </>
                    );
                }
                break;
            case 'CJB':
                if (isEdit && (jobType.portType === 'SEA' || jobType.portType === 'AIR')) {
                    return !shipmentId || !jobClose
                        ? ButtonComponent.CloseOpenJobButton(CloseJob, 'Close', !shipmentId, true)
                        : null;
                }
                break;
            case 'OJB':
                return isEdit && jobClose !== false ? ButtonComponent.CloseOpenJobButton(OpenJob, 'Open', false, true) : null;
            case 'APC':
                return isEdit ? ButtonComponent.ApproveButton(Approve, 'Approval Credit', true) : null;
            default:
                return null;
        }
    };

    return (
        <div className="row" style={{ marginTop: '16px' }}>
            <div className="col-12">
                <Grid
                    container
                    item
                    justifyContent="right"
                    direction="row"
                    style={{ maxWidth: '100vw', gap: '12px' }}
                >
                    {check !== 0 && ButtonComponent.SaveButton(Save, isJobClose, false, true)}

                    {DataRegUserAccess.map(({ accessId, accessCode }) => (
                        <React.Fragment key={accessId}>
                            {renderButtons(accessCode)}
                        </React.Fragment>
                    ))}

                    {isEdit && jobType.name !== 'Domestic' &&
                        ButtonComponent.UpdateSubOrderButton(UpdateSub, 'Update As Sub Order', !isEdit, true)
                    }
                </Grid>
            </div>
        </div>
    );
};

export default ButtonCreateEditShipment;