import { usePagination, useRowSelect, useSortBy } from "react-table/dist/react-table.development";
import { useTable, useFilters, useGlobalFilter } from "react-table";
import FilterListIcon from '@mui/icons-material/FilterList';
import { DefaultFilterForColumn } from "./Filter";
import { Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { Icon, Input, Selection } from "../ui";

export default function TableY({ columns, data, setDataSelect }) 
{
    // Table component logic and UI come here
    // Use the useTable Hook to send the columns and data to build the table
    const { 
        getTableProps, getTableBodyProps, headerGroups, prepareRow, page, canPreviousPage, canNextPage, pageCount,
        nextPage, previousPage, setPageSize, selectedFlatRows, state: { pageIndex, pageSize, }, 
    } = useTable(
            {
                columns,
                data,
                defaultColumn: { Filter: DefaultFilterForColumn },
            }, 
        useFilters, useGlobalFilter, useSortBy, usePagination, useRowSelect, hooks => {
            hooks.visibleColumns.push(columns => [
                {
                    id: 'selection',
                    Header: ({ getToggleAllPageRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
                        </div>
                    ),
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
            ])
        });


    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }, ref) => {
            const defaultRef = React.useRef()
            const resolvedRef = ref || defaultRef

            React.useEffect(() => {
                resolvedRef.current.indeterminate = indeterminate
            }, [resolvedRef, indeterminate])

            return (
                <>
                    <input type="checkbox" ref={resolvedRef} {...rest} />
                </>
            )
        }
    )
    
    const getSizing = (target) => {
        return SIZING[target][0];
    }

    const SIZING = {
        fontSize: ['12px', '14px', '16px'],
        itemSize: ['26px', '32px', '40px'],
        limitSize: ['110px', '120px', '130px']
    }
    
    return (
        <div style={{ overflow: 'none !important' }}>
            <div
                className="rounded-10 table-responsive"
                style={{ 
                    height: '500px', 
                    maxHeight: '500px', 
                    marginTop: '16px',
                    padding: '2px 16px',
                    borderRadius: '12px',
                }}
            >
                <table {...getTableProps()} className="table is-bordered is-striped">
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr  {...headerGroup.getHeaderGroupProps()}>
                                {
                                    headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            <span>
                                                {
                                                    column.isSorted? 
                                                        column.isSortedDesc? 
                                                            <FilterListIcon />
                                                        : 
                                                            <FilterListIcon sx={{ transform: "rotate(180deg)" }} />
                                                    : 
                                                        ''
                                                }
                                            </span>
                                            {column.render("Header")}
                                            
                                            <div>
                                                {
                                                    column.canFilter? 
                                                        column.render("Filter")
                                                    : 
                                                        null
                                                }
                                            </div>
                                        </th>
                                    ))
                                }
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()} style={{fontSize:'12px'}}>
                        {
                            page.length > 0?
                                page.map((row, i) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                            })}
                                        </tr>
                                    );
                                })
                            :
                                <tr>
                                    <td colSpan={20} className="text-center py-3 text-muted">
                                        <Typography
                                            sx={{
                                                color: 'var(--Primary-One, #083A50)',
                                                fontFamily: 'Nunito',
                                                fontSize: '16px',
                                                fontstyle: 'normal',
                                                fontWeight: '500',
                                                lineHeight: 'normal'
                                            }}
                                        >
                                            Data Empty
                                        </Typography>
                                    </td>
                                </tr>
                        }
                    </tbody>
                </table>
            </div>
            
            <div style={{ marginTop: '12px' }}>
                <Grid container item direction="row" alignItems={'center'}>
                    <Grid item xs={6} display={'flex'}>
                        <Selection
                            formControlProps={{ sx: { width: getSizing('limitSize') } }}
                            selectBaseProps={{
                                sx: {
                                    border: '1.5px solid #989898',
                                    height: getSizing('itemSize'),
                                    fontSize: getSizing('fontSize'),
                                }
                            }}
                            selectInnerStyle={{
                                padding: '8px',
                                paddingRight: '36px',
                            }}
                            value={pageSize}
                            onChange={(e) => setPageSize(e.target.value)}
                            options={[
                                {
                                    label: '10 Rows',
                                    value: 10
                                },
                                {
                                    label: '50 Rows',
                                    value: 50
                                },
                                {
                                    label: '100 Rows',
                                    value: 100
                                }
                            ]}
                        />
                    </Grid>

                    <Grid item xs={6} className='text-end' display={'flex'} justifyContent={'flex-end'}>
                        <Stack direction={'row'} spacing={'8px'} alignItems={'center'}>
                            <Stack direction={'row'} spacing={'4px'} alignItems={'center'}>
                                <Button
                                    size='small' 
                                    variant="outlined"
                                    className='d-flex align-items-center' 
                                    sx={{ 
                                        padding: '3px 6px', 
                                        gap: '4px', 
                                        marginInlineStart: '12px', 
                                        alignItems: 'center', 
                                        justifyContent: 'center',
                                        height: '18px !important',
                                        minHeight: '28px !important',
                                        borderRadius: '8px',
                                        '&:hover':{
                                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                        }
                                    }}
                                    onClick={() => previousPage()}
                                    disabled={!canPreviousPage}
                                >
                                    <Icon
                                        svgIconProps={{ 
                                            sx: { 
                                                height: '12px', 
                                                width: '12px', 
                                            } 
                                        }} 
                                        iconName = {'arrow-left'} 
                                    />
                                </Button>

                                <Button
                                    size='small' 
                                    variant="outlined"
                                    className='d-flex align-items-center' 
                                    sx={{ 
                                        padding: '3px 6px', 
                                        gap: '4px', 
                                        marginInlineStart: '12px', 
                                        alignItems: 'center', 
                                        justifyContent: 'center',
                                        height: '18px !important',
                                        minHeight: '28px !important',
                                        borderRadius: '8px',
                                        '&:hover':{
                                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                        }
                                    }}
                                    onClick={() => nextPage()}
                                    disabled={!canNextPage}
                                >
                                    <Icon
                                        svgIconProps={{ 
                                            sx: { 
                                                height: '12px', 
                                                width: '12px', 
                                                transform: 'rotateY(180deg)'
                                            } 
                                        }} 
                                        iconName = {'arrow-left'} 
                                    />
                                </Button>
                            </Stack>

                            <Input
                                type={'number'}
                                defaultValue={pageIndex + 1}
                                inputBaseProps={{
                                    min: 1,
                                    max: pageCount,
                                    sx: {
                                        marginInlineStart: '4px',
                                        width: '185px',
                                        padding: '10px 8px',
                                        height: getSizing('itemSize'),
                                        fontSize: 14,
                                        minHeight: getSizing('itemSize'),
                                        marginTop: '0px !important',
                                        border: '1.5px solid #989898',
                                        '& .MuiInputBase-input': {
                                            width: 'auto',
                                            borderBottom: '1px solid #2B4957',
                                            mx: '8px',
                                            fontWeight: 700,
                                            textAlign: 'center',
                                            fontSize: getSizing('fontSize')
                                        }
                                    },
                                    startAdornment: (
                                        <Typography
                                            sx={{
                                            fontSize: 14,
                                            whiteSpace: 'nowrap',
                                            fontWeight: 700
                                            }}
                                        >
                                            Go To page
                                        </Typography>
                                    ),
                                    endAdornment: (
                                        <Typography
                                            sx={{
                                            fontSize: 14,
                                            whiteSpace: 'nowrap',
                                            fontWeight: 700
                                            }}
                                        >
                                            of {pageCount}
                                        </Typography>
                                    )
                                }}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </div>

            <pre>
                <code>
                    {
                        setDataSelect(JSON.stringify(
                            {
                                // selectedRowIds: selectedRowIds,
                                'selectedRows': 
                                selectedFlatRows.map(
                                    d => d.original
                                ),
                            },
                            null,
                            2
                        ))
                    }
                </code>
            </pre>
        </div>
    );
}