import React from 'react'
import ButtonComponent from '../../../../components/Buttons/ButtonComponent'
import TableEPL from '../TableEPL/TableEPL'
import { Grid, Stack } from '@mui/material'
import { showToast } from '../../../../components/ui'

const TabCostDiscountShipper = (props) => {
  const title = 'Cost Discount Shipper'
  const contactTypeName = 'Discount Shipper'
  const contactTypeId = 2
  const income = false

  const handleAdd = () => {
    if (props.shipmentNo !== '') {
      props.handleAdd(title, contactTypeName, contactTypeId, income)
    }
    else {
      showToast({ type: 'error', message: 'Please Select Shipment Number First!' });
    }
  }

  const handleEdit = () => {
    if (props.selectedDetailCostDiscountShipper.sequence === undefined) {
      showToast({ type: 'error', message: 'Please Select Data!' });
    } else {
      props.handleEdit(title, contactTypeName, contactTypeId, income)
    }
  }

  const handleConvert = () => {
    if (props.selectedDetailCostDiscountShipper.sequence === undefined) {
      showToast({ type: 'error', message: 'Please Select Data!' });
    } else {
      props.handleConvert(title, contactTypeName, contactTypeId, income)
    }
  }

  const handleDoubleClick = () => {
    handleEdit()
  }

  const handleDelete = () => {
    if (props.selectedDetailCostDiscountShipper.bookingDetailId === 0) {
      props.handleDelete(contactTypeId, income)
    }
    else {
      showToast({ type: 'error', message: 'Can\'t Delete This Data!' });
    }
  }

  return (
    <div className='rounded-10'>
      <TableEPL
        key={6}
        isReadOnly={props.isReadOnly}
        dataList={props.CostDiscountShipperList}
        ignoreAccess={props.ignoreAccess}
        setDataList={props.setCostDiscountShipperList}
        setSelectedDetail={props.setSelectedDetailCostDiscountShipper}
        selectedDetail={props.selectedDetailCostDiscountShipper}
        handleDoubleClick={handleDoubleClick}
        contactTypeName={contactTypeName}
        ttlUsd={props.estCostUSDDiscountShipper}
        ttlIdr={props.estCostIDRDiscountShipper}
        totalSData={props.totalCostSDiscountShipper}
        totalNData={props.totalCostNDiscountShipper}
        key1={6}
        key2={6}
        type={2}
        openClose={props.openCloseCRS}
        setOpenClose={props.setOpenCloseCRS}
        openClose2={props.openCloseCRS2}
        setOpenClose2={props.setOpenCloseCRS2}
        dataDetail={props.dataDetail}
        setDataDetail={props.setDataDetail}
        countSum={props.countSum}
        countTotalData={props.countTotalData}
      />

      <Grid container item direction="row" className='mt-3'>
        <Grid item xs={12}>
          <Stack direction={'row'} spacing={'10px'}>
            {ButtonComponent.AddButton(handleAdd, '', props.isReadOnly)}
            {ButtonComponent.EditButton(handleEdit, '', props.isReadOnly)}
            {ButtonComponent.DeleteButton(handleDelete, '', props.isReadOnly)}
            {ButtonComponent.ConvertButton(handleConvert, props.isReadOnly, true)}
          </Stack>
        </Grid>
      </Grid>
    </div>
  )
}

export default React.memo(TabCostDiscountShipper)