import Grid from '@mui/material/Grid';
import React from 'react'

import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import { Button, Typography } from '@mui/material';
import { Icon } from '../../../../components/ui';

const ButtonToDo = (props) => {
    return (
        <div className="row" spacing={'12px'}>
            <div className='col-12' spacing={'12px'}>
                <Grid container item spacing={1} direction="row" style={{'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px'}}>
                    {ButtonComponent.ReloadButton(props.ReloadData)}
                    
                    <Button
                        size='small' 
                        variant="outlined"
                        className='d-flex align-items-center' 
                        sx={{ 
                            padding: '3px 6px', 
                            gap: '4px', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                            marginInlineStart: '12px', 
                            height: '18px !important',
                            minHeight: '28px !important',
                            borderRadius: '8px',
                            '&:hover':{
                                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                            }
                        }}
                        onClick={() => props.switchTitle()}
                    >
                        <Icon
                            svgIconProps={{ 
                                sx: { 
                                    height: '12px', 
                                    width: '12px', 
                                } 
                            }} 
                            iconName = {'documents'} 
                        />
                        <Typography
                            sx={{
                                fontFamily: 'Nunito',
                                marginTop: '2px !important',
                                fontSize: '12px',
                                fontWeight: '700',
                                lineHeight: '20px',
                                letterSpacing: '0em',
                                textAlign: 'center',
                                color: '#0087C2',
                            }}      
                        >
                            {props.title2}
                        </Typography>
                    </Button>
                </Grid>
            </div>
        </div>
    )
}

export default ButtonToDo