import { MenuItem, InputAdornment, IconButton, Stack, Grid, Box } from '@mui/material';
import React from 'react';
import { Icon, Input, Selection } from '../../../../components/ui';

const TabFreightDomestic = (props) => {
    const dataFreightStatus = [
        { id: 1, code: 'P', name: 'Prepaid' },
        { id: 2, code: 'C', name: 'Collect' },
    ];

    const AddFreightCollectHBL = (data) => {
        if (data === 'search') {
            props.setTitleModal('Freight Collect');
            props.setTipe(2);
            props.handleClick('Freight Collect');
        }
        else if (data === 'delete') {
            props.setFreightCollectIdHBL(0);
            props.setFreightCollectCodeHBL('');
            props.setFreightCollectNameHBL('');
        }
    };

    return (
        <>
            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Box component="div" sx={{ marginTop: '12px' }}>
                        <Selection
                            id='0'
                            label={'Freight HB/L'}
                            fullWidth={true}
                            placeholder={'Select Freight HB/L'}
                            value={props.freightStatusHBL}
                            onChange={(e) => props.setFreightStatusHBL(e.target.value)}
                        >
                            {
                                dataFreightStatus.map((data, index) => (
                                    <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                ))
                            }
                        </Selection>

                        <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                            <Input
                                label="Freight Collect at"
                                value={props.freightCollectCodeHBL}
                                disabled
                                formControlProps={{
                                    sx: {
                                        marginTop: '24px'
                                    }
                                }}
                            />

                            <Input
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                value={props.freightCollectNameHBL}
                                type='text'
                                label={''}
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                                inputBaseProps={{
                                    sx: {
                                        marginTop: '23px !important',
                                    },
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => AddFreightCollectHBL('delete')}
                                                hidden={props.freightStatusHBL === 'C' && props.freightCollectNameHBL ? false : true}
                                            >
                                                <Icon
                                                    iconName={'close'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>

                                            <IconButton
                                                onClick={() => AddFreightCollectHBL('search')}
                                                hidden={props.freightStatusHBL === 'C' ? false : true}
                                            >
                                                <Icon
                                                    iconName={'search'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                disabled
                            />
                        </Stack>

                        <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                            <Input
                                label="Payable By"
                                value={props.freightStatusHBL === 'C' ? props.payableConsigneeIdHBL : '0'}
                                disabled
                                formControlProps={{
                                    sx: {
                                        marginTop: '24px'
                                    }
                                }}
                            />

                            <Input
                                label=""
                                value={props.freightStatusHBL === 'C' ? props.payableConsigneeNameHBL : ''}
                                disabled
                                formControlProps={{
                                    sx: {
                                        marginTop: '24px',
                                        width: '100%'
                                    }
                                }}
                                inputBaseProps={{
                                    sx: {
                                        marginTop: '23px !important',
                                    },
                                }}
                            />
                        </Stack>
                    </Box>
                </Grid>

                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Input
                        label="Handling Information"
                        value={props.handlingInf}
                        onChange={(e) => props.setHandlingInf(e.target.value)}
                        multiline
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '12px !important'
                            }
                        }}
                        inputBaseProps={{
                            rows: 9,
                            sx: {
                                padding: '8px',
                            },
                        }}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default TabFreightDomestic;