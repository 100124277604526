import { Box, FormControlLabel, Grid, Radio, RadioGroup, Stack, MenuItem, IconButton, Backdrop, CardContent, Card, InputAdornment, Typography } from '@mui/material';
import React, { forwardRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

import ApiService from '../../../../services/ApiService';
import ModalSearchPeriod from './components/ModalSearchPeriod';
import ModalSearchCustomer from './components/ModalSearchCustomer';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Icon, Input, Selection, showToast } from '../../../../components/ui';

const ReceivableSubsidiaryLedgerReport = forwardRef(() => {
  const history = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  const [activeModal, setActiveModal] = useState('')
  const [selectedFirstCustomer, setSelectedFirstCustomer] = useState({})
  const [selectedSecondCustomer, setSelectedSecondCustomer] = useState({})
  const [selectedPeriod, setSelectedPeriod] = useState({})

  const [dataRegUserAccess, setDataRegUserAccess] = useState([]);
  const [contactTypeList, setContactTypeList] = useState([]);

  const BreadcrumbsItems = [
    { label: 'Infoss', link: '/Infoss', style: '' },
    { label: 'Subsidiary Ledger Account Receivable Report', link: '/Infoss/ReceivableSubsidiaryLedgerReport', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useEffect(() => {
    document.title = 'Subsidiary Ledger Account Receivable Report - ' + JSON.parse(localStorage.getItem('branchName'));

    getDataAccess()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const errorToast = (location) => {
    showToast({ type: 'error', message: `Can't get ${location} data! please contact Administrator` })
  }

  const getDataAccess = () => {
    let params = [];
    let pId = 0;
    let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
    let hasAccess = false

    // TODO Change menu code
    const indexMenu = listMenu.findIndex((elm) => elm.code === 'REXPEJKP')
    if (indexMenu !== -1) {
      pId = listMenu[indexMenu].id
      hasAccess = true
    }

    if (hasAccess) {
      params = [
        ...params,
        { attr: "permissionId", value: "" + pId },
        { attr: "userId", value: "" + JSON.parse(localStorage.getItem("userId")) },
        { attr: "roleId", value: "" + JSON.parse(localStorage.getItem("roleId")) }
      ];

      ApiService.IdentityPostByPage('regUserAccess/regUserAccess', "PAGE", 1, 100, params, [])
        .then((response) => {
          if (response.data.code === 200) {
            setDataRegUserAccess(response.data.data.userAccess);

            getAdditionalData()
          }
        })
        .catch(function (error) {
          console.error("error saat fetch", error);
          history('/Infoss');
        });
    } else {
      history('/Infoss');
    }
  };

  const getAdditionalData = async () => {
    setIsLoading(true)
    try {
      const [resCT] = await Promise.all([
        ApiService.DynamicPostByPage('contactType/contactType', 'PAGE', 1, 999, [])
      ])

      if (resCT.data.code === 200) {
        let data = resCT.data.data.contactType
        const includesData = ['AGENT', 'SHIPPER', 'CONSIGNEE']
        data = data.filter((el) => includesData.includes(el?.name?.toUpperCase()))
        setContactTypeList(data)


        formik.setFieldValue('customerType', data[0].id)
      } else {
        errorToast('contact type')
      }
    } catch (error) {
      errorToast('additional')
      console.log(error)
    }
    setIsLoading(false)
  }

  const handlePrint = (fileType) => {
    let payload = formik.values;
    if (!selectedPeriod?.id) {
      return showToast({ type: 'error', message: 'Please Select Period!' })
    }

    if (payload.customer !== 'all' && (!selectedFirstCustomer?.contactId || !selectedSecondCustomer?.contactId)) {
      return showToast({ type: 'error', message: 'Please select customer first.' })
    }

    setIsLoading(true);
    let customerId1 = 0;
    let customerId2 = 0;

    if (payload.customer !== 'all') {
      customerId1 = selectedFirstCustomer.contactId;
      customerId2 = selectedSecondCustomer.contactId;
    }

    let variable = 'PrintSubsidiaryAR?typeReport=' + fileType;
    let params = [
      {
        "attr": "StartDate",
        "value": payload.beginningPeriod
      },
      {
        "attr": "EndDate",
        "value": payload.endingPeriod
      },
      {
        "attr": "ContactTypeId",
        "value": '' + payload.customerType
      },
      {
        "attr": "CustomerFromId",
        "value": '' + customerId1
      },
      {
        "attr": "CustomerToId",
        "value": '' + customerId2
      }
    ];

    ApiService.ReportPrint("Reports/Finance/Print/AccountReceivable/SubsidiaryARReport/", variable, params)
      .then((response) => {
        if (response.status === 200) {
          let rptFormat = "application/pdf";
          if (fileType === "xls") {
            rptFormat = "application/vnd.ms-excel"
          }
          const file = new Blob([response.data], { type: rptFormat });
          const fileURL = URL.createObjectURL(file);

          setIsLoading(false);
          showToast({ type: 'success', message: 'Print Receivable Subsidiary Ledger Report Success' });
          window.open(fileURL);
        }
        else {
          setIsLoading(false);
          showToast({ type: 'error', message: 'Can\'t Print Receivable Subsidiary Ledger Report, ' + response.data.message });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        showToast({ type: 'error', message: 'Can\'t Print Receivable Subsidiary Ledger Report, ' + error });
      });
  }

  const handleSelectPeriod = (data) => {
    setSelectedPeriod(data)
    formik.setFieldValue('period', data.period)
    formik.setFieldValue('year', data.yearPeriod)

    const startPeriod = new Date(data.beginningPeriod)
    const endPeriod = new Date(data.endDatePeriod)
    formik.setFieldValue('beginningPeriod', startPeriod.toLocaleDateString("sv-se").split('T')[0])
    formik.setFieldValue('endingPeriod', endPeriod.toLocaleDateString("sv-se").split('T')[0])
  }

  const toggleModal = (modal) => {
    setActiveModal(modal)
  }

  const formik = useFormik({
    initialValues: {
      period: '',
      year: '',
      beginningPeriod: '',
      endingPeriod: '',
      customerType: 0,
      customer: 'all',
    },
    validationSchema: undefined,
  })

  const ButtonComponents = () => {
    return (
      <div className="row" spacing={'12px'}>
        <div className='col-12' spacing={'12px'}>
          <Grid container item spacing={'12px'} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
            {dataRegUserAccess.map((elm, index) => (
              <React.Fragment key={index}>
                {elm.accessCode === 'PRI' && ButtonComponent.PrintButton(() => handlePrint('pdf'))}
                {elm.accessCode === 'PRI' && ButtonComponent.ExcelButton(() => handlePrint('xls'), 'Excel')}
              </React.Fragment>
            ))}
          </Grid>
        </div>
      </div>
    )
  }

  return (
    <>
      <Box className='w-100' sx={{ width: '100%', p: 3 }}>
        <CustomToastContainer />
        <Grid container spacing={0} direction="column">
          <HeaderInfo
            title={'Subsidiary Ledger Account Receivable Report'}
            isJobType={false}
            BreadcrumbsItems={BreadcrumbsItems}
            ButtonComponents={ButtonComponents}
          />

          <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
            <div style={{ marginTop: '24px' }}>
              <Grid
                container
                spacing={'32px'}
              >
                <Grid
                  item
                  xl={6}
                  lg={6}
                  sm={6}
                  xs={12}
                >
                  <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                    <CardContent>
                      <Stack spacing={'16px'} direction="row">
                        <Input
                          readOnly
                          fullWidth
                          label="Period"
                          variant="standard"
                          InputLabelProps={{ shrink: true }}
                          value={formik.values?.period || ''}
                          disabled
                        />

                        <Input
                          fullWidth
                          label="Year"
                          variant="standard"
                          value={formik.values?.year || ''}
                          InputLabelProps={{ shrink: true }}
                          formControlProps={{ sx: { width: '100%', } }}
                          disabled
                          inputBaseProps={{
                            readOnly: true,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={() => toggleModal('period')}>
                                  <Icon
                                    iconName={'search'}
                                    svgIconProps={{
                                      fontSize: 'small',
                                      htmlColor: '#0087C2',
                                      sx: {
                                        height: '12px',
                                        width: '12px'
                                      }
                                    }}
                                  />
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Stack>

                      <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                        <Input
                          disabled
                          fullWidth
                          type='date'
                          variant="standard"
                          label="Beginning Period"
                          InputLabelProps={{ shrink: true }}
                          value={formik.values?.beginningPeriod || ''}
                          formControlProps={{ sx: { width: '100%', } }}
                        />

                        <Input
                          disabled
                          fullWidth
                          type='date'
                          variant="standard"
                          label="Ending Period"
                          InputLabelProps={{ shrink: true }}
                          value={formik.values?.endingPeriod || ''}
                          formControlProps={{ sx: { width: '100%', } }}
                        />
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={6}>
                  <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                    <CardContent>
                      <Selection
                        id='0'
                        fullWidth={true}
                        label="Customer Type"
                        value={formik.values.customerType}
                        placeholder={'Select Customer Type'}
                        formControlProps={{ sx: { width: '50%', } }}
                        onChange={(e) => {
                          formik.setFieldValue('customerType', e.target.value)
                          setSelectedFirstCustomer({})
                          setSelectedSecondCustomer({})
                        }}
                      >
                        {
                          contactTypeList.map((ct) => (
                            <MenuItem sx={{ fontSize: '12px' }} key={ct.id} value={ct.id}>{ct.name}</MenuItem>
                          ))
                        }
                      </Selection>

                      <div style={{ marginTop: '16px' }}>
                        <Typography
                          sx={{
                            color: 'var(--Primary-One, #083A50)',
                            fontFamily: 'Nunito',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '19.6px',
                          }}
                        >
                          Custom Customer
                        </Typography>

                        <RadioGroup
                          row
                          aria-labelledby="variant-option"
                          name="variant"
                          value={formik.values.customer}
                          onChange={(e) => { formik.setFieldValue('customer', e.target.value) }}
                        >
                          <FormControlLabel
                            sx={{
                              color: 'var(--Blue-Primary-1, #083A50)',
                              fontFamily: 'Nunito',
                              fontSize: '12px !important',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: '20px',
                            }}
                            value={'all'}
                            key={1}
                            control={<Radio size='small' />}
                            label={
                              <Typography
                                sx={{
                                  color: 'var(--Blue-Primary-1, #083A50)',
                                  fontFamily: 'Nunito',
                                  fontSize: '12px !important',
                                  fontStyle: 'normal',
                                  fontWeight: '500',
                                  lineHeight: '20px',
                                }}
                              >
                                All
                              </Typography>
                            }
                          />

                          <FormControlLabel
                            sx={{
                              color: 'var(--Blue-Primary-1, #083A50)',
                              fontFamily: 'Nunito',
                              fontSize: '12px !important',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: '20px',
                            }}
                            value={'custom'}
                            key={2}
                            control={<Radio size='small' />}
                            label={
                              <Typography
                                sx={{
                                  color: 'var(--Blue-Primary-1, #083A50)',
                                  fontFamily: 'Nunito',
                                  fontSize: '12px !important',
                                  fontStyle: 'normal',
                                  fontWeight: '500',
                                  lineHeight: '20px',
                                }}
                              >
                                Custom
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </div>

                      {
                        formik.values.customer !== 'all' && (
                          <>
                            <Input
                              fullWidth
                              label="From"
                              variant="standard"
                              placeholder='Search Customer'
                              InputLabelProps={{ shrink: true }}
                              disabled
                              formControlProps={{ sx: { width: '100%', marginTop: '16px !important' } }}
                              value={selectedFirstCustomer?.id ? `${selectedFirstCustomer?.code || 0} - ${selectedFirstCustomer?.contactName}` : ''}
                              inputBaseProps={{
                                readOnly: true,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={() => toggleModal('firstCustomer')}>
                                      <Icon
                                        iconName={'search'}
                                        svgIconProps={{
                                          fontSize: 'small',
                                          htmlColor: '#0087C2',
                                          sx: {
                                            height: '12px',
                                            width: '12px'
                                          }
                                        }}
                                      />
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />

                            <Input
                              fullWidth
                              label="To"
                              variant="standard"
                              placeholder='Search Customer'
                              InputLabelProps={{ shrink: true }}
                              disabled
                              formControlProps={{ sx: { width: '100%', marginTop: '16px' } }}
                              value={selectedSecondCustomer?.id ? `${selectedSecondCustomer?.code || 0} - ${selectedSecondCustomer?.contactName}` : ''}
                              inputBaseProps={{
                                readOnly: true,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={() => toggleModal('secondCustomer')}>
                                      <Icon
                                        iconName={'search'}
                                        svgIconProps={{
                                          fontSize: 'small',
                                          htmlColor: '#0087C2',
                                          sx: {
                                            height: '12px',
                                            width: '12px'
                                          }
                                        }}
                                      />
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                          </>
                        )
                      }
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Box>

      {
        isLoading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <LoadingSpinner />
          </Backdrop>
        )
      }

      {['firstCustomer', 'secondCustomer'].includes(activeModal) && (
        <ModalSearchCustomer
          isModal={['firstCustomer', 'secondCustomer'].includes(activeModal)}
          toggleModal={toggleModal}
          dataSelected={activeModal === 'firstCustomer' ? selectedFirstCustomer : selectedSecondCustomer}
          onSelect={activeModal === 'firstCustomer' ? setSelectedFirstCustomer : setSelectedSecondCustomer}
          errorToast={errorToast}
          formValue={formik.values}
        />
      )}

      {activeModal === 'period' && (
        <ModalSearchPeriod
          isModal={activeModal === 'period'}
          toggleModal={toggleModal}
          dataSelected={selectedPeriod}
          onSelect={handleSelectPeriod}
          errorToast={errorToast}
          formValue={formik.values}
        />
      )}
    </>
  )
})

export default ReceivableSubsidiaryLedgerReport