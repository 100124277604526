import { Grid, Stack, IconButton, InputAdornment, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Box } from '@mui/system';

import ApiService from '../../../../../services/ApiService';
import SelectDataCOA from '../Selects/SelectDataCOA';
import TabsPaymentMethod from '../Tabs/TabsPaymentMethod';
import { Icon, Input, showToast } from '../../../../../components/ui';

const OfficialReceiptPayment = (props) => {
    const [tabValue, setTabValue] = useState('Cash');
    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);

    const paramC = "regChartOfAccount/regChartOfAccount";
    const [isLoadingC, setIsLoadingC] = useState(false);
    const [dataC, setDataC] = useState([]);
    const [dataMapC, setDataMapC] = useState([]);
    const [SelectedDataC, setSelectedDataC] = useState({});
    const [totalRowsC, setTotalRowsC] = useState(50);
    const [columnDataC, setColumnDataC] = useState([]);

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataCOA(1, 50);
        }
        else {
            setIsModal(false);
        }
    };

    const searchCOA = (data) => {
        if (data === 'search') {
            setTitleModal('Chart of Account (COA)');
            handleClick();
        }
        else if (data === 'delete') {
            props.setAccountCode('');
            props.setAccountName('');
        }
    };

    const getDataCOA = (pageNumber, pageSize, filters) => {
        setIsLoadingC(true);
        ApiService.COAPostBySearch(paramC, pageNumber, pageSize, [], filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataC({})
                    setDataC(response.data.data.chartOfAccount);

                    let temp = response.data.data.chartOfAccount
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapC(indexed)
                    setColumnDataC(response.data.data.columns)
                    setTotalRowsC(response.data.totalRowCount)

                    setIsLoadingC(false)
                }
                setIsLoadingC(false)
            })
            .catch(function (error) {
                setIsLoadingC(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    };

    return (
        <div>
            <form>
                <Box>
                    <Typography
                        sx={{
                            color: 'var(--Primary-One, #083A50)',
                            fontFamily: 'Nunito',
                            fontSize: '18px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '19.6px',
                            marginTop: '8px'
                        }}
                    >
                        Payment Method
                    </Typography>

                    <Box sx={{ marginTop: '24px' }}>
                        <div>
                            <TabsPaymentMethod
                                tabValue={tabValue}
                                setTabValue={setTabValue}
                                officialReceiptId={props.officialReceiptId}
                                Printing={props.Printing}
                                BankData={props.BankData}
                                setBankData={props.setBankData}
                                calculateTotalCash={props.calculateTotalCash}
                                calculateTotalBank={props.calculateTotalBank}
                                calculateTotalDifferent={props.calculateTotalDifferent}
                                CashPaymentUSD={props.CashPaymentUSD}
                                setCashPaymentUSD={props.setCashPaymentUSD}
                                CashPaymentIDR={props.CashPaymentIDR}
                                setCashPaymentIDR={props.setCashPaymentIDR}
                                TotalCashPaymentIDR={props.TotalCashPaymentIDR}
                                setTotalCashPaymentIDR={props.setTotalCashPaymentIDR}
                                TotalCashUSD={props.TotalCashUSD}
                                setTotalCashUSD={props.setTotalCashUSD}
                                TotalCashIDR={props.TotalCashIDR}
                                setTotalCashIDR={props.setTotalCashIDR}
                                TotalCashInIDR={props.TotalCashInIDR}
                                setTotalCashInIDR={props.setTotalCashInIDR}
                                TotalBankUSD={props.TotalBankUSD}
                                setTotalBankUSD={props.setTotalBankUSD}
                                TotalBankIDR={props.TotalBankIDR}
                                setTotalBankIDR={props.setTotalBankIDR}
                                TotalBankInIDR={props.TotalBankInIDR}
                                setTotalBankInIDR={props.setTotalBankInIDR}
                                InvoiceList={props.InvoiceList}
                                setInvoiceList={props.setInvoiceList}
                                checkSelectedInvoice={props.checkSelectedInvoice}
                                RateCount={props.RateCount}
                                dueDateLast={props.dueDateLast}
                                setDueDateLast={props.setDueDateLast}
                                TempDiffUSD={props.TempDiffUSD}
                                TempDiffIDR={props.TempDiffIDR}
                            />
                        </div>

                        <div style={{ margin: '24px 0px' }}>
                            <hr
                                style={{
                                    strokeWidth: '1.5px',
                                    borderTop: '3px dashed #7F888C',
                                    stroke: 'var(--Disable-Grey-Text-2, #7F888C)',
                                }}
                            />
                        </div>

                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={4}
                                lg={4}
                                sm={4}
                                xs={4}
                            >
                                <Grid className='text-end'>
                                    <Typography
                                        sx={{
                                            color: 'var(--Primary-One, #083A50)',
                                            textAlign: 'right',
                                            fontFamily: 'Nunito',
                                            fontSize: '16px',
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            lineHeight: '19.6px',
                                        }}
                                    >
                                        Total Cash Payment
                                    </Typography>
                                </Grid>

                                <Input
                                    label='USD'
                                    textAlign='right'
                                    value={props.TotalCashUSD}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '12px !important'
                                        }
                                    }}
                                />

                                <Input
                                    label='IDR'
                                    textAlign='right'
                                    value={props.TotalCashIDR}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />

                                <Input
                                    label='In IDR'
                                    textAlign='right'
                                    value={props.TotalCashInIDR}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                xl={4}
                                lg={4}
                                sm={4}
                                xs={4}
                            >
                                <Grid className='text-end'>
                                    <Typography
                                        sx={{
                                            color: 'var(--Primary-One, #083A50)',
                                            textAlign: 'right',
                                            fontFamily: 'Nunito',
                                            fontSize: '16px',
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            lineHeight: '19.6px',
                                        }}
                                    >
                                        Total Bank Payment
                                    </Typography>
                                </Grid>

                                <Input
                                    label='USD'
                                    textAlign='right'
                                    value={props.TotalBankUSD}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '12px !important'
                                        }
                                    }}
                                />

                                <Input
                                    label='IDR'
                                    textAlign='right'
                                    value={props.TotalBankIDR}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />

                                <Input
                                    label='In IDR'
                                    textAlign='right'
                                    value={props.TotalBankInIDR}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                xl={4}
                                lg={4}
                                sm={4}
                                xs={4}
                            >
                                <Grid className='text-end'>
                                    <Typography
                                        sx={{
                                            color: 'var(--Primary-One, #083A50)',
                                            textAlign: 'right',
                                            fontFamily: 'Nunito',
                                            fontSize: '16px',
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            lineHeight: '19.6px',
                                        }}
                                    >
                                        Different
                                    </Typography>
                                </Grid>

                                <Input
                                    label='USD'
                                    textAlign='right'
                                    value={props.DifferenceUSD}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '12px !important'
                                        }
                                    }}
                                />

                                <Input
                                    label='IDR'
                                    textAlign='right'
                                    value={props.DifferenceIDR}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />

                                <Input
                                    label='In IDR'
                                    textAlign='right'
                                    value={props.TotalDifferenceIDR}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Stack spacing={'16px'} direction="row" sx={{ marginTop: '24px' }}>
                            <Input
                                label='Account Code'
                                value={props.AccountCode}
                                disabled
                                formControlProps={{
                                    sx: {
                                        marginTop: '24px'
                                    }
                                }}
                            />

                            <Input
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                value={props.AccountName}
                                type='text'
                                label={''}
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                                inputBaseProps={{
                                    sx: {
                                        marginTop: '23px !important',
                                    },
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => searchCOA('delete')}
                                                disabled={props.isJobOwner}
                                                hidden={props.AccountName && props.Printing === 0 ? false : true}
                                            >
                                                <Icon
                                                    iconName={'close'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>

                                            <IconButton onClick={() => searchCOA('search')} hidden={props.Printing > 0}>
                                                <Icon
                                                    iconName={'search'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                disabled
                            />
                        </Stack>
                    </Box>
                </Box>
            </form>
            
            {
                isModal && (
                    <SelectDataCOA
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}
                        getData={getDataCOA}
                        isLoading={isLoadingC}
                        totalRows={totalRowsC}
                        setTotalRows={setTotalRowsC}
                        SelectedData={SelectedDataC}
                        setSelectedData={setSelectedDataC}
                        data={dataC}
                        columnData={columnDataC}
                        dataMap={dataMapC}
                        setAccountCode={props.setAccountCode}
                        setAccountName={props.setAccountName}
                    />
                )
            }
        </div>
    )
}

export default OfficialReceiptPayment;