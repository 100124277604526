import { Card, CardContent, Tab, Tabs, Typography }
from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import React from 'react'

import TabVessel from './TabVessel';
import TabContainer from './TabContainer';
import TabFreightSea from './TabFreightSea';
import TabFreightAir from './TabFreightAir';
import TabFreightDomestic from './TabFreightDomestic';
import TabDescription from './TabDescription';
import TabFlight from './TabFlight';
import TabMasterAirWayBill from './TabMasterAirWayBill';
import TabConNote from './TabConNote';

const TabsDetailShipping = (props) => {
    const handleChange = (event, newValue) => {
        props.setTabValue(newValue);
    };

    return (
        <>
            <Card
                component="div"
                className='p-0'
                style={{
                    display: 'flex',
                    width: '100%',
                    padding: '16px 0px',
                    flexDirection: 'column',
                    gap: '16px',
                    borderRadius: '12px',
                    background: '#FAFAFA',
                }}
            >
                <CardContent>
                    <>
                        {
                            props.jobType.portType === 'SEA' ?
                                <TabContext value={props.tabValue} className='p-0'>
                                    <Tabs
                                        sx={{ width: '100%' }}
                                        value={props.tabValue}
                                        onChange={handleChange}
                                        className="p-0"
                                        aria-label="full width tabs example"
                                        variant="fullWidth"
                                        TabIndicatorProps={{
                                            sx: {
                                                height: 'fit-content !important',
                                                maxHeight: '32px !important',
                                                backgroundColor: "#0087C2 !important",
                                            },
                                        }}
                                        TabScrollButtonProps={{
                                            sx: {
                                                backgroundColor: "#0087C2 !important",
                                                height: 'fit-content !important',
                                                maxHeight: '32px !important',
                                            },
                                        }}
                                    >
                                        <Tab
                                            sx={{
                                                padding: '4px 12px !important',
                                                minHeight: '35px',
                                                textTransform: "none",
                                                backgroundColor: '#D7F0FF',
                                                '&.Mui-selected': {
                                                    color: '#f2f2f2',
                                                    backgroundColor: '#0087C2',
                                                },
                                            }}
                                            value="Vessel"
                                            label={
                                                <Typography
                                                    sx={{
                                                        color: props.tabValue === "Vessel" ? '#F2FAFF' : '#083A50',
                                                        fontFamily: 'Nunito',
                                                        fontSize: '14px !important',
                                                        fontStyle: 'normal',
                                                        fontWeight: '600',
                                                    }}
                                                >
                                                    Vessel
                                                </Typography>
                                            }
                                        />
                                        <Tab
                                            sx={{
                                                padding: '4px 12px !important',
                                                minHeight: '35px',
                                                textTransform: "none",
                                                backgroundColor: '#D7F0FF',
                                                '&.Mui-selected': {
                                                    color: '#f2f2f2',
                                                    backgroundColor: '#0087C2',
                                                },
                                            }}
                                            value="Container"
                                            label={
                                                <Typography
                                                    sx={{
                                                        color: props.tabValue === "Container" ? '#F2FAFF' : ' #083A50',
                                                        fontFamily: 'Nunito',
                                                        fontSize: '14px !important',
                                                        fontStyle: 'normal',
                                                        fontWeight: '600',
                                                    }}
                                                >
                                                    Container
                                                </Typography>
                                            }
                                        />
                                        <Tab
                                            sx={{
                                                padding: '4px 12px !important',
                                                minHeight: '35px',
                                                textTransform: "none",
                                                backgroundColor: '#D7F0FF',
                                                '&.Mui-selected': {
                                                    color: '#f2f2f2',
                                                    backgroundColor: '#0087C2',
                                                },
                                            }}
                                            value="Freight"
                                            label={
                                                <Typography
                                                    sx={{
                                                        color: props.tabValue === "Freight" ? '#F2FAFF' : '#083A50',
                                                        fontFamily: 'Nunito',
                                                        fontSize: '14px !important',
                                                        fontStyle: 'normal',
                                                        fontWeight: '600',
                                                    }}
                                                >
                                                    Freight
                                                </Typography>
                                            }
                                        />
                                        <Tab
                                            sx={{
                                                padding: '4px 12px !important',
                                                minHeight: '35px',
                                                textTransform: "none",
                                                backgroundColor: '#D7F0FF',
                                                '&.Mui-selected': {
                                                    color: '#f2f2f2',
                                                    backgroundColor: '#0087C2',
                                                },
                                            }}
                                            value="Description"
                                            label={
                                                <Typography
                                                    sx={{
                                                        color: props.tabValue === "Description" ? '#F2FAFF' : '#083A50',
                                                        fontFamily: 'Nunito',
                                                        fontSize: '14px !important',
                                                        fontStyle: 'normal',
                                                        fontWeight: '600',
                                                    }}
                                                >
                                                    Description
                                                </Typography>
                                            }
                                        />
                                    </Tabs>

                                    {
                                        props.tabValue === 'Vessel' && (
                                            <TabPanel value="Vessel" className='p-0'>
                                                <TabVessel
                                                    jobType={props.jobType}
                                                    calculateTypeVessel={props.calculateTypeVessel}
                                                    handleClick={props.handleClick}
                                                    setTitleModal={props.setTitleModal}
                                                    setTipe={props.setTipe}

                                                    ShipmentId={props.ShipmentId}
                                                    setShipmentId={props.setShipmentId}

                                                    ReceiptPlaceId={props.ReceiptPlaceId}
                                                    ReceiptPlaceCode={props.ReceiptPlaceCode}
                                                    ReceiptPlaceName={props.ReceiptPlaceName}
                                                    setReceiptPlaceId={props.setReceiptPlaceId}
                                                    setReceiptPlaceCode={props.setReceiptPlaceCode}
                                                    setReceiptPlaceName={props.setReceiptPlaceName}

                                                    DischargePortId={props.DischargePortId}
                                                    DischargePortCode={props.DischargePortCode}
                                                    DischargePortName={props.DischargePortName}
                                                    setDischargePortId={props.setDischargePortId}
                                                    setDischargePortCode={props.setDischargePortCode}
                                                    setDischargePortName={props.setDischargePortName}

                                                    LoadingPortId={props.LoadingPortId}
                                                    LoadingPortCode={props.LoadingPortCode}
                                                    LoadingPortName={props.LoadingPortName}
                                                    setLoadingPortId={props.setLoadingPortId}
                                                    setLoadingPortCode={props.setLoadingPortCode}
                                                    setLoadingPortName={props.setLoadingPortName}

                                                    DeliveryPlaceId={props.DeliveryPlaceId}
                                                    DeliveryPlaceCode={props.DeliveryPlaceCode}
                                                    DeliveryPlaceName={props.DeliveryPlaceName}
                                                    setDeliveryPlaceId={props.setDeliveryPlaceId}
                                                    setDeliveryPlaceCode={props.setDeliveryPlaceCode}
                                                    setDeliveryPlaceName={props.setDeliveryPlaceName}

                                                    TempLoadingPortName={props.TempLoadingPortName}
                                                    setTempLoadingPortName={props.setTempLoadingPortName}
                                                    TempReceiptPlaceName={props.TempReceiptPlaceName}
                                                    setTempReceiptPlaceName={props.setTempReceiptPlaceName}
                                                    TempDischargePortName={props.TempDischargePortName}
                                                    setTempDischargePortName={props.setTempDischargePortName}
                                                    TempDeliveryPlaceName={props.TempDeliveryPlaceName}
                                                    setTempDeliveryPlaceName={props.setTempDeliveryPlaceName}

                                                    ETA={props.ETA}
                                                    setETA={props.setETA}
                                                    ETD={props.ETD}
                                                    setETD={props.setETD}
                                                    ATD={props.ATD}
                                                    setATD={props.setATD}
                                                    ATA={props.ATA}
                                                    setATA={props.setATA}

                                                    period={props.period}
                                                    setPeriod={props.setPeriod}
                                                    yearPeriod={props.yearPeriod}
                                                    setYearPeriod={props.setYearPeriod}
                                                    isClosed={props.isClosed}
                                                    setIsClosed={props.setIsClosed}

                                                    vesselData={props.vesselData}
                                                    setVesselData={props.setVesselData}

                                                    dataTracking={props.dataTracking}
                                                    setDataTracking={props.setDataTracking}

                                                    detailTypeId={props.detailTypeId}
                                                    setDetailTypeId={props.setDetailTypeId}
                                                />
                                            </TabPanel>
                                        )
                                    }

                                    {
                                        props.tabValue === 'Container' && (
                                            <TabPanel value="Container" className='p-0'>
                                                <TabContainer
                                                    jobType={props.jobType}
                                                    handleClick={props.handleClick}
                                                    setTitleModal={props.setTitleModal}
                                                    setTipe={props.setTipe}
                                                    selectContainerType={props.selectContainerType}
                                                    selectPackagingType={props.selectPackagingType}

                                                    CopyContainer={props.CopyContainer}
                                                    setCopyContainer={props.setCopyContainer}
                                                    totalNumberCont={props.totalNumberCont}
                                                    setTotalNumberCont={props.setTotalNumberCont}

                                                    detailTypeId={props.detailTypeId}
                                                    setDetailTypeId={props.setDetailTypeId}

                                                    ShipmentId={props.ShipmentId}
                                                    setShipmentId={props.setShipmentId}

                                                    data20={props.data20}
                                                    setData20={props.setData20}
                                                    data40={props.data40}
                                                    setData40={props.setData40}
                                                    data45={props.data45}
                                                    setData45={props.setData45}
                                                    dataAll={props.dataAll}
                                                    setDataAll={props.setDataAll}

                                                    getContainerSizeData={props.getContainerSizeData}
                                                    createCopyContainer={props.createCopyContainer}

                                                    containerData={props.containerData}
                                                    setContainerData={props.setContainerData}

                                                    dataTracking={props.dataTracking}
                                                    setDataTracking={props.setDataTracking}
                                                />
                                            </TabPanel>
                                        )
                                    }

                                    {
                                        props.tabValue === 'Freight' && (
                                            <TabPanel value="Freight" className='p-0'>
                                                <TabFreightSea
                                                    jobType={props.jobType}
                                                    handleClick={props.handleClick}
                                                    setTitleModal={props.setTitleModal}
                                                    setTipe={props.setTipe}
                                                    selectCurrency={props.selectCurrency}

                                                    freightStatusOBL={props.freightStatusOBL}
                                                    setFreightStatusOBL={props.setFreightStatusOBL}
                                                    freightCollectIdOBL={props.freightCollectIdOBL}
                                                    setFreightCollectIdOBL={props.setFreightCollectIdOBL}
                                                    freightCollectCodeOBL={props.freightCollectCodeOBL}
                                                    setFreightCollectCodeOBL={props.setFreightCollectCodeOBL}
                                                    freightCollectNameOBL={props.freightCollectNameOBL}
                                                    setFreightCollectNameOBL={props.setFreightCollectNameOBL}
                                                    payableAgentIdOBL={props.payableAgentIdOBL}
                                                    setPayableAgentIdOBL={props.setPayableAgentIdOBL}
                                                    payableAgentNameOBL={props.payableAgentNameOBL}
                                                    setPayableAgentNameOBL={props.setPayableAgentNameOBL}
                                                    //SEA IMPORT
                                                    OBLCurrency={props.OBLCurrency}
                                                    setOBLCurrency={props.setOBLCurrency}
                                                    OBLAmount={props.OBLAmount}
                                                    setOBLAmount={props.setOBLAmount}

                                                    freightStatusHBL={props.freightStatusHBL}
                                                    setFreightStatusHBL={props.setFreightStatusHBL}
                                                    freightCollectIdHBL={props.freightCollectIdHBL}
                                                    setFreightCollectIdHBL={props.setFreightCollectIdHBL}
                                                    freightCollectCodeHBL={props.freightCollectCodeHBL}
                                                    setFreightCollectCodeHBL={props.setFreightCollectCodeHBL}
                                                    freightCollectNameHBL={props.freightCollectNameHBL}
                                                    setFreightCollectNameHBL={props.setFreightCollectNameHBL}
                                                    payableConsigneeIdHBL={props.payableConsigneeIdHBL}
                                                    setPayableConsigneeIdHBL={props.setPayableConsigneeIdHBL}
                                                    payableConsigneeNameHBL={props.payableConsigneeNameHBL}
                                                    setPayableConsigneeNameHBL={props.setPayableConsigneeNameHBL}
                                                    //SEA IMPORT
                                                    HBLCurrency={props.HBLCurrency}
                                                    setHBLCurrency={props.setHBLCurrency}
                                                    HBLAmount={props.HBLAmount}
                                                    setHBLAmount={props.setHBLAmount}

                                                    currency={props.currency}
                                                    setCurrency={props.setCurrency}
                                                    handlingInf={props.handlingInf}
                                                    setHandlingInf={props.setHandlingInf}

                                                    detailTypeId={props.detailTypeId}
                                                    setDetailTypeId={props.setDetailTypeId}
                                                />
                                            </TabPanel>
                                        )
                                    }

                                    {
                                        props.tabValue === 'Description' && (
                                            <TabPanel value="Description" className='p-0'>
                                                <TabDescription
                                                    jobType={props.jobType}
                                                    handleClick={props.handleClick}
                                                    setTitleModal={props.setTitleModal}
                                                    setTipe={props.setTipe}

                                                    containerData={props.containerData}
                                                    MarkNumberDescription={props.MarkNumberDescription}
                                                    setMarkNumberDescription={props.setMarkNumberDescription}
                                                    NumberTypeDescription={props.NumberTypeDescription}
                                                    setNumberTypeDescription={props.setNumberTypeDescription}
                                                    GoodDescription={props.GoodDescription}
                                                    setGoodDescription={props.setGoodDescription}
                                                    GrossWeightDescription={props.GrossWeightDescription}
                                                    setGrossWeightDescription={props.setGrossWeightDescription}
                                                    MeasurementDescription={props.MeasurementDescription}
                                                    setMeasurementDescription={props.setMeasurementDescription}

                                                    TempMarkNumberDescription={props.TempMarkNumberDescription}
                                                    setTempMarkNumberDescription={props.setTempMarkNumberDescription}
                                                    TempNumberTypeDescription={props.TempNumberTypeDescription}
                                                    setTempNumberTypeDescription={props.setTempNumberTypeDescription}
                                                    TempGoodDescription={props.TempGoodDescription}
                                                    setTempGoodDescription={props.setTempGoodDescription}
                                                    TempGrossWeightDescription={props.TempGrossWeightDescription}
                                                    setTempGrossWeightDescription={props.setTempGrossWeightDescription}
                                                    TempMeasurementDescription={props.TempMeasurementDescription}
                                                    setTempMeasurementDescription={props.setTempMeasurementDescription}

                                                    setShippingMarksDesc={props.setShippingMarksDesc}
                                                    setNoPackageDesc={props.setNoPackageDesc}
                                                    setSIGoodDesc={props.setSIGoodDesc}
                                                    setWeightMeaDesc={props.setWeightMeaDesc}

                                                    CopyContainer={props.CopyContainer}
                                                    setCopyContainer={props.setCopyContainer}

                                                    detailTypeId={props.detailTypeId}
                                                    setDetailTypeId={props.setDetailTypeId}
                                                />
                                            </TabPanel>
                                        )
                                    }
                                </TabContext>
                                :
                                props.jobType.portType === 'AIR' ?
                                    <TabContext value={props.tabValue} className='p-0'>
                                        <Tabs
                                            sx={{ width: '100%' }}
                                            value={props.tabValue}
                                            onChange={handleChange}
                                            className='p-0'
                                            textColor="primary"
                                            aria-label="full width tabs example"
                                            variant="fullWidth"
                                            TabIndicatorProps={{
                                                sx: {
                                                    height: 'fit-content !important',
                                                    maxHeight: '32px !important',
                                                    backgroundColor: "#0087C2 !important",
                                                },
                                            }}
                                            TabScrollButtonProps={{
                                                sx: {
                                                    backgroundColor: "#0087C2 !important",
                                                    height: 'fit-content !important',
                                                    maxHeight: '32px !important',
                                                },
                                            }}
                                        >
                                            <Tab
                                                sx={{
                                                    padding: '4px 12px !important',
                                                    minHeight: '35px',
                                                    textTransform: "none",
                                                    backgroundColor: '#D7F0FF',
                                                    '&.Mui-selected': {
                                                        color: '#f2f2f2',
                                                        backgroundColor: '#0087C2',
                                                    },
                                                }}
                                                value="Flight"
                                                label={
                                                    <Typography
                                                        sx={{
                                                            color: props.tabValue === "Flight" ? '#F2FAFF' : '#083A50',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '14px !important',
                                                            fontStyle: 'normal',
                                                            fontWeight: '600',
                                                        }}
                                                    >
                                                        Flight
                                                    </Typography>
                                                }
                                            />
                                            <Tab
                                                sx={{
                                                    padding: '4px 12px !important',
                                                    minHeight: '35px',
                                                    textTransform: "none",
                                                    backgroundColor: '#D7F0FF',
                                                    '&.Mui-selected': {
                                                        color: '#f2f2f2',
                                                        backgroundColor: '#0087C2',
                                                    },
                                                }}
                                                value="Freight"
                                                label={
                                                    <Typography
                                                        sx={{
                                                            color: props.tabValue === "Freight" ? '#F2FAFF' : '#083A50',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '14px !important',
                                                            fontStyle: 'normal',
                                                            fontWeight: '600',
                                                        }}
                                                    >
                                                        Freight
                                                    </Typography>
                                                }
                                            />
                                            <Tab
                                                sx={{
                                                    padding: '4px 12px !important',
                                                    minHeight: '35px',
                                                    textTransform: "none",
                                                    backgroundColor: '#D7F0FF',
                                                    '&.Mui-selected': {
                                                        color: '#f2f2f2',
                                                        backgroundColor: '#0087C2',
                                                    },
                                                }}
                                                value="Description"
                                                label={
                                                    <Typography
                                                        sx={{
                                                            color: props.tabValue === "Description" ? '#F2FAFF' : '#083A50',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '14px !important',
                                                            fontStyle: 'normal',
                                                            fontWeight: '600',
                                                        }}
                                                    >
                                                        Description
                                                    </Typography>
                                                }
                                            />
                                            <Tab
                                                sx={{
                                                    padding: '4px 12px !important',
                                                    minHeight: '35px',
                                                    textTransform: "none",
                                                    backgroundColor: '#D7F0FF',
                                                    '&.Mui-selected': {
                                                        color: '#f2f2f2',
                                                        backgroundColor: '#0087C2',
                                                    },
                                                }}
                                                value="Master Airway Bill"
                                                label={
                                                    <Typography
                                                        sx={{
                                                            color: props.tabValue === "Master Airway Bill" ? '#F2FAFF' : '#083A50',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '14px !important',
                                                            fontStyle: 'normal',
                                                            fontWeight: '600',
                                                        }}
                                                    >
                                                        Master Airway Bill
                                                    </Typography>
                                                }
                                            />
                                        </Tabs>
                                        {
                                            props.tabValue === 'Flight' && (
                                                <TabPanel value="Flight" className='p-0'>
                                                    <TabFlight
                                                        jobType={props.jobType}
                                                        handleClick={props.handleClick}
                                                        setTitleModal={props.setTitleModal}
                                                        setTipe={props.setTipe}

                                                        ShipmentId={props.ShipmentId}
                                                        setShipmentId={props.setShipmentId}

                                                        setAirLineName={props.setAirLineName}

                                                        ReceiptPlaceId={props.ReceiptPlaceId}
                                                        setReceiptPlaceId={props.setReceiptPlaceId}
                                                        ReceiptPlaceCode={props.ReceiptPlaceCode}
                                                        setReceiptPlaceCode={props.setReceiptPlaceCode}
                                                        ReceiptPlaceName={props.ReceiptPlaceName}
                                                        setReceiptPlaceName={props.setReceiptPlaceName}

                                                        DepartureAirPortId={props.DepartureAirPortId}
                                                        setDepartureAirPortId={props.setDepartureAirPortId}
                                                        DepartureAirPortCode={props.DepartureAirPortCode}
                                                        setDepartureAirPortCode={props.setDepartureAirPortCode}
                                                        DepartureAirPortName={props.DepartureAirPortName}
                                                        setDepartureAirPortName={props.setDepartureAirPortName}

                                                        DestinationAirPortId={props.DestinationAirPortId}
                                                        setDestinationAirPortId={props.setDestinationAirPortId}
                                                        DestinationAirPortCode={props.DestinationAirPortCode}
                                                        setDestinationAirPortCode={props.setDestinationAirPortCode}
                                                        DestinationAirportName={props.DestinationAirportName}
                                                        setDestinationAirportName={props.setDestinationAirportName}

                                                        DeliveryPlaceId={props.DeliveryPlaceId}
                                                        setDeliveryPlaceId={props.setDeliveryPlaceId}
                                                        DeliveryPlaceCode={props.DeliveryPlaceCode}
                                                        setDeliveryPlaceCode={props.setDeliveryPlaceCode}
                                                        DeliveryPlaceName={props.DeliveryPlaceName}
                                                        setDeliveryPlaceName={props.setDeliveryPlaceName}

                                                        TempLoadingPortName={props.TempLoadingPortName}
                                                        setTempLoadingPortName={props.setTempLoadingPortName}
                                                        TempReceiptPlaceName={props.TempReceiptPlaceName}
                                                        setTempReceiptPlaceName={props.setTempReceiptPlaceName}
                                                        TempDischargePortName={props.TempDischargePortName}
                                                        setTempDischargePortName={props.setTempDischargePortName}
                                                        TempDeliveryPlaceName={props.TempDeliveryPlaceName}
                                                        setTempDeliveryPlaceName={props.setTempDeliveryPlaceName}

                                                        ETA={props.ETA}
                                                        setETA={props.setETA}
                                                        ETD={props.ETD}
                                                        setETD={props.setETD}
                                                        ATD={props.ATD}
                                                        setATD={props.setATD}
                                                        ATA={props.ATA}
                                                        setATA={props.setATA}

                                                        period={props.period}
                                                        setPeriod={props.setPeriod}
                                                        yearPeriod={props.yearPeriod}
                                                        setYearPeriod={props.setYearPeriod}
                                                        isClosed={props.isClosed}
                                                        setIsClosed={props.setIsClosed}

                                                        setMAWBCollectId={props.setMAWBCollectId}
                                                        setMAWBCollectCode={props.setMAWBCollectCode}
                                                        setMAWBCollectName={props.setMAWBCollectName}

                                                        setHAWBCollectId={props.setHAWBCollectId}
                                                        setHAWBCollectCode={props.setHAWBCollectCode}
                                                        setHAWBCollectName={props.setHAWBCollectName}

                                                        flightData={props.flightData}
                                                        setFlightData={props.setFlightData}

                                                        dataTracking={props.dataTracking}
                                                        setDataTracking={props.setDataTracking}

                                                        detailTypeId={props.detailTypeId}
                                                        setDetailTypeId={props.setDetailTypeId}
                                                    />
                                                </TabPanel>
                                            )
                                        }

                                        {
                                            props.tabValue === 'Freight' && (
                                                <TabPanel value="Freight" className='p-0'>
                                                    <TabFreightAir
                                                        jobType={props.jobType}
                                                        handleClick={props.handleClick}
                                                        setTitleModal={props.setTitleModal}
                                                        setTipe={props.setTipe}
                                                        selectCurrency={props.selectCurrency}

                                                        MAWBStatus={props.MAWBStatus}
                                                        setMAWBStatus={props.setMAWBStatus}
                                                        MAWBCollectId={props.MAWBCollectId}
                                                        setMAWBCollectId={props.setMAWBCollectId}
                                                        MAWBCollectCode={props.MAWBCollectCode}
                                                        setMAWBCollectCode={props.setMAWBCollectCode}
                                                        MAWBCollectName={props.MAWBCollectName}
                                                        setMAWBCollectName={props.setMAWBCollectName}
                                                        payableAgentIdMAWB={props.payableAgentIdMAWB}
                                                        setPayableAgentIdMAWB={props.setPayableAgentIdMAWB}
                                                        payableAgentNameMAWB={props.payableAgentNameMAWB}
                                                        setPayableAgentNameMAWB={props.setPayableAgentNameMAWB}
                                                        //AIR IMPORT
                                                        MAWBCurrency={props.MAWBCurrency}
                                                        setMAWBCurrency={props.setMAWBCurrency}
                                                        MAWBRate={props.MAWBRate}
                                                        setMAWBRate={props.setMAWBRate}

                                                        HAWBStatus={props.HAWBStatus}
                                                        setHAWBStatus={props.setHAWBStatus}
                                                        HAWBCollectId={props.HAWBCollectId}
                                                        setHAWBCollectId={props.setHAWBCollectId}
                                                        HAWBCollectCode={props.HAWBCollectCode}
                                                        setHAWBCollectCode={props.setHAWBCollectCode}
                                                        HAWBCollectName={props.HAWBCollectName}
                                                        setHAWBCollectName={props.setHAWBCollectName}
                                                        payableConsigneeIdHAWB={props.payableConsigneeIdHAWB}
                                                        setPayableConsigneeIdHAWB={props.setPayableConsigneeIdHAWB}
                                                        payableConsigneeNameHAWB={props.payableConsigneeNameHAWB}
                                                        setPayableConsigneeNameHAWB={props.setPayableConsigneeNameHAWB}
                                                        //AIR IMPORT
                                                        HAWBCurrency={props.HAWBCurrency}
                                                        setHAWBCurrency={props.setHAWBCurrency}
                                                        HAWBRate={props.HAWBRate}
                                                        setHAWBRate={props.setHAWBRate}

                                                        currency={props.currency}
                                                        setCurrency={props.setCurrency}
                                                        handlingInf={props.handlingInf}
                                                        setHandlingInf={props.setHandlingInf}

                                                        detailTypeId={props.detailTypeId}
                                                        setDetailTypeId={props.setDetailTypeId}
                                                    />
                                                </TabPanel>
                                            )
                                        }

                                        {
                                            props.tabValue === 'Description' && (
                                                <TabPanel value="Description" className='p-0'>
                                                    <TabDescription
                                                        jobType={props.jobType}
                                                        handleClick={props.handleClick}
                                                        setTitleModal={props.setTitleModal}
                                                        setTipe={props.setTipe}
                                                        selectPackagingType={props.selectPackagingType}

                                                        PiecesRCP={props.PiecesRCP}
                                                        setPiecesRCP={props.setPiecesRCP}
                                                        GrossWeight={props.GrossWeight}
                                                        setGrossWeight={props.setGrossWeight}
                                                        KGLB={props.KGLB}
                                                        setKGLB={props.setKGLB}
                                                        ChargeWeight={props.ChargeWeight}
                                                        setChargeWeight={props.setChargeWeight}
                                                        ChargeRate={props.ChargeRate}
                                                        setChargeRate={props.setChargeRate}
                                                        Total={props.Total}
                                                        setTotal={props.setTotal}
                                                        Commodity={props.Commodity}
                                                        setCommodity={props.setCommodity}
                                                        PackagingCode={props.PackagingCode}
                                                        setPackagingCode={props.setPackagingCode}
                                                        GoodNatureQuantity={props.GoodNatureQuantity}
                                                        setGoodNatureQuantity={props.setGoodNatureQuantity}
                                                        Shipmark={props.Shipmark}
                                                        setShipmark={props.setShipmark}

                                                        detailTypeId={props.detailTypeId}
                                                        setDetailTypeId={props.setDetailTypeId}
                                                    />
                                                </TabPanel>
                                            )
                                        }

                                        {
                                            props.tabValue === 'Master Airway Bill' && (
                                                <TabPanel value="Master Airway Bill" className='p-0'>
                                                    <TabMasterAirWayBill
                                                        jobType={props.jobType}
                                                        handleClick={props.handleClick}
                                                        setTitleModal={props.setTitleModal}
                                                        setTipe={props.setTipe}

                                                        handleClickC={props.handleClickC}
                                                        setTitleModalC={props.setTitleModalC}
                                                        setTipeC={props.setTipeC}
                                                        setTipe2={props.setTipe2}

                                                        MAWBNo={props.MAWBNo}
                                                        setMAWBNo={props.setMAWBNo}
                                                        ChargeableWeight={props.ChargeableWeight}
                                                        setChargeableWeight={props.setChargeableWeight}
                                                        WeightHAWB={props.WeightHAWB}
                                                        setWeightHAWB={props.setWeightHAWB}
                                                        CarriageValue={props.CarriageValue}
                                                        setCarriageValue={props.setCarriageValue}
                                                        CustomValue={props.CustomValue}
                                                        setCustomValue={props.setCustomValue}
                                                        IATAId={props.IATAId}
                                                        setIATAId={props.setIATAId}
                                                        IATAName={props.IATAName}
                                                        setIATAName={props.setIATAName}
                                                        BrokerId={props.BrokerId}
                                                        setBrokerId={props.setBrokerId}
                                                        BrokerName={props.BrokerName}
                                                        setBrokerName={props.setBrokerName}
                                                        airLineName={props.airLineName}
                                                        setAirLineName={props.setAirLineName}
                                                        //AIR IMPORT
                                                        HAWBNo={props.HAWBNo}
                                                        setHAWBNo={props.setHAWBNo}

                                                        detailTypeId={props.detailTypeId}
                                                        setDetailTypeId={props.setDetailTypeId}
                                                    />
                                                </TabPanel>
                                            )
                                        }
                                    </TabContext>
                                    :
                                    <TabContext value={props.tabValue} className='p-0'>
                                        <Tabs
                                            sx={{ width: '100%' }}
                                            value={props.tabValue}
                                            onChange={handleChange}
                                            className='p-0'
                                            textColor="primary"
                                            aria-label="full width tabs example"
                                            variant="fullWidth"
                                            TabIndicatorProps={{
                                                sx: {
                                                    height: 'fit-content !important',
                                                    maxHeight: '32px !important',
                                                    backgroundColor: "#0087C2 !important",
                                                },
                                            }}
                                            TabScrollButtonProps={{
                                                sx: {
                                                    backgroundColor: "#0087C2 !important",
                                                    height: 'fit-content !important',
                                                    maxHeight: '32px !important',
                                                },
                                            }}
                                        >
                                            <Tab
                                                sx={{
                                                    padding: '4px 12px !important',
                                                    minHeight: '35px',
                                                    textTransform: "none",
                                                    backgroundColor: '#D7F0FF',
                                                    '&.Mui-selected': {
                                                        color: '#f2f2f2',
                                                        backgroundColor: '#0087C2',
                                                    },
                                                }}
                                                value="Vessel"
                                                label={
                                                    <Typography
                                                        sx={{
                                                            color: props.tabValue === "Vessel" ? '#F2FAFF' : '#083A50',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '14px !important',
                                                            fontStyle: 'normal',
                                                            fontWeight: '600',
                                                        }}
                                                    >
                                                        Vessel
                                                    </Typography>
                                                }
                                            />
                                            <Tab
                                                sx={{
                                                    padding: '4px 12px !important',
                                                    minHeight: '35px',
                                                    textTransform: "none",
                                                    backgroundColor: '#D7F0FF',
                                                    '&.Mui-selected': {
                                                        color: '#f2f2f2',
                                                        backgroundColor: '#0087C2',
                                                    },
                                                }}
                                                value="Container"
                                                label={
                                                    <Typography
                                                        sx={{
                                                            color: props.tabValue === "Container" ? '#F2FAFF' : '#083A50',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '14px !important',
                                                            fontStyle: 'normal',
                                                            fontWeight: '600',
                                                        }}
                                                    >
                                                        Container
                                                    </Typography>
                                                }
                                            />
                                            <Tab
                                                sx={{
                                                    padding: '4px 12px !important',
                                                    minHeight: '35px',
                                                    textTransform: "none",
                                                    backgroundColor: '#D7F0FF',
                                                    '&.Mui-selected': {
                                                        color: '#f2f2f2',
                                                        backgroundColor: '#0087C2',
                                                    },
                                                }}
                                                value="Freight"
                                                label={
                                                    <Typography
                                                        sx={{
                                                            color: props.tabValue === "Freight" ? '#F2FAFF' : '#083A50',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '14px !important',
                                                            fontStyle: 'normal',
                                                            fontWeight: '600',
                                                        }}
                                                    >
                                                        Freight
                                                    </Typography>
                                                }
                                            />
                                            <Tab
                                                sx={{
                                                    padding: '4px 12px !important',
                                                    minHeight: '35px',
                                                    textTransform: "none",
                                                    backgroundColor: '#D7F0FF',
                                                    '&.Mui-selected': {
                                                        color: '#f2f2f2',
                                                        backgroundColor: '#0087C2',
                                                    },
                                                }}
                                                value="Description"
                                                label={
                                                    <Typography
                                                        sx={{
                                                            color: props.tabValue === "Description" ? '#F2FAFF' : '#083A50',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '14px !important',
                                                            fontStyle: 'normal',
                                                            fontWeight: '600',
                                                        }}
                                                    >
                                                        Description
                                                    </Typography>
                                                }
                                            />
                                            <Tab
                                                sx={{
                                                    padding: '4px 12px !important',
                                                    minHeight: '35px',
                                                    textTransform: "none",
                                                    backgroundColor: '#D7F0FF',
                                                    '&.Mui-selected': {
                                                        color: '#f2f2f2',
                                                        backgroundColor: '#0087C2',
                                                    },
                                                }}
                                                value='Con Note'
                                                label={
                                                    <Typography
                                                        sx={{
                                                            color: props.tabValue === "Con Note" ? '#F2FAFF' : '#083A50',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '14px !important',
                                                            fontStyle: 'normal',
                                                            fontWeight: '600',
                                                        }}
                                                    >
                                                        Con. Note
                                                    </Typography>
                                                }
                                            />
                                        </Tabs>

                                        {
                                            props.tabValue === 'Vessel' && (
                                                <TabPanel value="Vessel" className='p-0'>
                                                    <TabVessel
                                                        jobType={props.jobType}
                                                        handleClick={props.handleClick}
                                                        setTitleModal={props.setTitleModal}
                                                        setTipe={props.setTipe}

                                                        ShipmentId={props.ShipmentId}
                                                        setShipmentId={props.setShipmentId}

                                                        ReceiptPlaceId={props.ReceiptPlaceId}
                                                        setReceiptPlaceId={props.setReceiptPlaceId}
                                                        ReceiptPlaceCode={props.ReceiptPlaceCode}
                                                        setReceiptPlaceCode={props.setReceiptPlaceCode}
                                                        ReceiptPlaceName={props.ReceiptPlaceName}
                                                        setReceiptPlaceName={props.setReceiptPlaceName}

                                                        DeliveryPlaceId={props.DeliveryPlaceId}
                                                        setDeliveryPlaceId={props.setDeliveryPlaceId}
                                                        DeliveryPlaceCode={props.DeliveryPlaceCode}
                                                        setDeliveryPlaceCode={props.setDeliveryPlaceCode}
                                                        DeliveryPlaceName={props.DeliveryPlaceName}
                                                        setDeliveryPlaceName={props.setDeliveryPlaceName}

                                                        TempLoadingPortName={props.TempLoadingPortName}
                                                        setTempLoadingPortName={props.setTempLoadingPortName}
                                                        TempReceiptPlaceName={props.TempReceiptPlaceName}
                                                        setTempReceiptPlaceName={props.setTempReceiptPlaceName}
                                                        TempDischargePortName={props.TempDischargePortName}
                                                        setTempDischargePortName={props.setTempDischargePortName}
                                                        TempDeliveryPlaceName={props.TempDeliveryPlaceName}
                                                        setTempDeliveryPlaceName={props.setTempDeliveryPlaceName}

                                                        ETD={props.ETD}
                                                        setETD={props.setETD}
                                                        ATD={props.ATD}
                                                        setATD={props.setATD}

                                                        ETA={props.ETA}
                                                        setETA={props.setETA}
                                                        ATA={props.ATA}
                                                        setATA={props.setATA}

                                                        period={props.period}
                                                        setPeriod={props.setPeriod}
                                                        yearPeriod={props.yearPeriod}
                                                        setYearPeriod={props.setYearPeriod}
                                                        isClosed={props.isClosed}
                                                        setIsClosed={props.setIsClosed}

                                                        VesselFlight={props.VesselFlight}
                                                        setVesselFlight={props.setVesselFlight}

                                                        dataTracking={props.dataTracking}
                                                        setDataTracking={props.setDataTracking}

                                                        VesselId={props.VesselId}
                                                        setVesselId={props.setVesselId}
                                                        VesselName={props.VesselName}
                                                        setVesselName={props.setVesselName}
                                                        Voyage={props.Voyage}
                                                        setVoyage={props.setVoyage}
                                                        cityId={props.cityId}
                                                        setCityId={props.setCityId}
                                                        PortId={props.PortId}
                                                        setPortId={props.setPortId}
                                                        PortCode={props.PortCode}
                                                        setPortCode={props.setPortCode}
                                                        PortName={props.PortName}
                                                        setPortName={props.setPortName}
                                                        FlightId={props.FlightId}
                                                        setFlightId={props.setFlightId}
                                                        FlightCode={props.FlightCode}
                                                        setFlightCode={props.setFlightCode}
                                                        FlightName={props.FlightName}
                                                        setFlightName={props.setFlightName}
                                                        FlightNo={props.FlightNo}
                                                        setFlightNo={props.setFlightNo}

                                                        setFreightCollectIdHBL={props.setFreightCollectIdHBL}
                                                        setFreightCollectCodeHBL={props.setFreightCollectCodeHBL}
                                                        setFreightCollectNameHBL={props.setFreightCollectNameHBL}

                                                        detailTypeId={props.detailTypeId}
                                                        setDetailTypeId={props.setDetailTypeId}
                                                    />
                                                </TabPanel>
                                            )
                                        }

                                        {
                                            props.tabValue === 'Container' && (
                                                <TabPanel value="Container" className='p-0'>
                                                    <TabContainer
                                                        jobType={props.jobType}
                                                        handleClick={props.handleClick}
                                                        setTitleModal={props.setTitleModal}
                                                        setTipe={props.setTipe}
                                                        selectContainerType={props.selectContainerType}
                                                        selectPackagingType={props.selectPackagingType}

                                                        CopyContainer={props.CopyContainer}
                                                        setCopyContainer={props.setCopyContainer}
                                                        totalNumberCont={props.totalNumberCont}
                                                        setTotalNumberCont={props.setTotalNumberCont}

                                                        ShipmentId={props.ShipmentId}
                                                        setShipmentId={props.setShipmentId}

                                                        detailTypeId={props.detailTypeId}
                                                        setDetailTypeId={props.setDetailTypeId}

                                                        data20={props.data20}
                                                        setData20={props.setData20}
                                                        data40={props.data40}
                                                        setData40={props.setData40}
                                                        data45={props.data45}
                                                        setData45={props.setData45}
                                                        dataAll={props.dataAll}
                                                        setDataAll={props.setDataAll}

                                                        containerData={props.containerData}
                                                        setContainerData={props.setContainerData}
                                                        getContainerSizeData={props.getContainerSizeData}
                                                        createCopyContainer={props.createCopyContainer}

                                                        dataTracking={props.dataTracking}
                                                        setDataTracking={props.setDataTracking}
                                                    />
                                                </TabPanel>
                                            )
                                        }

                                        {
                                            props.tabValue === 'Freight' && (
                                                <TabPanel value="Freight" className='p-0'>
                                                    <TabFreightDomestic
                                                        jobType={props.jobType}
                                                        handleClick={props.handleClick}
                                                        setTitleModal={props.setTitleModal}
                                                        setTipe={props.setTipe}

                                                        freightStatusHBL={props.freightStatusHBL}
                                                        setFreightStatusHBL={props.setFreightStatusHBL}
                                                        freightCollectIdHBL={props.freightCollectIdHBL}
                                                        setFreightCollectIdHBL={props.setFreightCollectIdHBL}
                                                        freightCollectCodeHBL={props.freightCollectCodeHBL}
                                                        setFreightCollectCodeHBL={props.setFreightCollectCodeHBL}
                                                        freightCollectNameHBL={props.freightCollectNameHBL}
                                                        setFreightCollectNameHBL={props.setFreightCollectNameHBL}
                                                        payableConsigneeIdHBL={props.payableConsigneeIdHBL}
                                                        setPayableConsigneeIdHBL={props.setPayableConsigneeIdHBL}
                                                        payableConsigneeNameHBL={props.payableConsigneeNameHBL}
                                                        setPayableConsigneeNameHBL={props.setPayableConsigneeNameHBL}

                                                        handlingInf={props.handlingInf}
                                                        setHandlingInf={props.setHandlingInf}

                                                        detailTypeId={props.detailTypeId}
                                                        setDetailTypeId={props.setDetailTypeId}
                                                    />
                                                </TabPanel>
                                            )
                                        }

                                        {
                                            props.tabValue === 'Description' && (
                                                <TabPanel value="Description" className='p-0'>
                                                    <TabDescription
                                                        jobType={props.jobType}
                                                        handleClick={props.handleClick}
                                                        setTitleModal={props.setTitleModal}
                                                        setTipe={props.setTipe}

                                                        containerData={props.containerData}
                                                        MarkNumberDescription={props.MarkNumberDescription}
                                                        setMarkNumberDescription={props.setMarkNumberDescription}
                                                        NumberTypeDescription={props.NumberTypeDescription}
                                                        setNumberTypeDescription={props.setNumberTypeDescription}
                                                        GoodDescription={props.GoodDescription}
                                                        setGoodDescription={props.setGoodDescription}
                                                        GrossWeightDescription={props.GrossWeightDescription}
                                                        setGrossWeightDescription={props.setGrossWeightDescription}
                                                        MeasurementDescription={props.MeasurementDescription}
                                                        setMeasurementDescription={props.setMeasurementDescription}

                                                        TempMarkNumberDescription={props.TempMarkNumberDescription}
                                                        setTempMarkNumberDescription={props.setTempMarkNumberDescription}
                                                        TempNumberTypeDescription={props.TempNumberTypeDescription}
                                                        setTempNumberTypeDescription={props.setTempNumberTypeDescription}
                                                        TempGoodDescription={props.TempGoodDescription}
                                                        setTempGoodDescription={props.setTempGoodDescription}
                                                        TempGrossWeightDescription={props.TempGrossWeightDescription}
                                                        setTempGrossWeightDescription={props.setTempGrossWeightDescription}
                                                        TempMeasurementDescription={props.TempMeasurementDescription}
                                                        setTempMeasurementDescription={props.setTempMeasurementDescription}

                                                        setShippingMarksDesc={props.setShippingMarksDesc}
                                                        setNoPackageDesc={props.setNoPackageDesc}
                                                        setSIGoodDesc={props.setSIGoodDesc}
                                                        setWeightMeaDesc={props.setWeightMeaDesc}

                                                        detailTypeId={props.detailTypeId}
                                                        setDetailTypeId={props.setDetailTypeId}
                                                    />
                                                </TabPanel>
                                            )
                                        }

                                        {
                                            props.tabValue === 'Con Note' && (
                                                <TabPanel value='Con Note' className='p-0'>
                                                    <TabConNote
                                                        jobType={props.jobType}
                                                        handleClick={props.handleClick}
                                                        setTitleModal={props.setTitleModal}
                                                        setTipe={props.setTipe}

                                                        ShipmentId={props.ShipmentId}
                                                        setShipmentId={props.setShipmentId}

                                                        ConNoteData={props.ConNoteData}
                                                        setConNoteData={props.setConNoteData}

                                                        dataTracking={props.dataTracking}
                                                        setDataTracking={props.setDataTracking}
                                                    />
                                                </TabPanel>
                                            )
                                        }
                                    </TabContext>
                        }
                    </>
                </CardContent>
            </Card>
        </>
    )
}

export default TabsDetailShipping;