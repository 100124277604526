import { Stack } from '@mui/material'
import React from 'react'
import { Input } from '../../../../../components/ui'
import { formatCurrency } from '../../../../../services/ApiService'
import DollarInput, { convertMask } from '../../../../../components/Masks/DollarInput'
import RupiahInput, { convertMaskIDR } from '../../../../../components/Masks/RupiahInput'

const ReceiptPPh23Total = (props) => {
  return (
    <>
      <Stack spacing={'64px'} direction="row" sx={{ marginTop: '24px' }}>
        <Input
          label="Total Amount IDR"
          value={props.totalIDR}
          textAlign='right'
          disabled
          fullWidth
          formControlProps={{
            sx: {
              width: '100%',
            }
          }}
        />

        <Input
          label="Total Amount USD"
          value={props.totalUSD}
          textAlign='right'
          disabled
          fullWidth
          formControlProps={{
            sx: {
              width: '100%',
            }
          }}
        />

        <Input
          label="Total PPh23"
          value={props.totalPPh23}
          textAlign='right'
          disabled
          fullWidth
          formControlProps={{
            sx: {
              width: '100%',
            }
          }}
        />

        <Input
          label="Different Amount In IDR"
          value={props.differentAmount}
          onChange={(e) => { props.setDifferentAmount(e.target.value); props.setDifferentAmountReal(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1)) }}
          textAlign='right'
          // disabled
          fullWidth
          formControlProps={{
            sx: {
              width: '100%',
            }
          }}
          inputBaseProps={{
            inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
          }}
        />
      </Stack>

    </>
  )
}

export default ReceiptPPh23Total