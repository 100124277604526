import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react';
import { Avatar, Box, Stack, Grid, Backdrop, Skeleton, Card, CardContent, Typography, Button } from '@mui/material';
import { ToastContainer } from 'react-toastify';

import ModalProfile from './Modals/ModalProfile';
import ModalChangePassword from './Modals/ModalChangePassword';
import { userCode, username } from '../../../services/ApiService';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import { Icon } from '../../../components/ui';

const countryName = JSON.parse(localStorage.getItem("countryName"))
const companyName = JSON.parse(localStorage.getItem("companyName"))
const branchName = JSON.parse(localStorage.getItem("branchName"))
const roleName = JSON.parse(localStorage.getItem("roleName"))
export const dataProfile = JSON.parse(localStorage.getItem("dataProfile"))

const Profile = forwardRef((props, ref) => {
  const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
  const [titleModal, setTitleModal] = useState('');
  const [isModal, setIsModal] = useState(false);

  const [isModalPass, setIsModalPass] = useState(false);
  const [CPass, setCPass] = useState('');
  const [NPass, setNPass] = useState('');
  const [RPass, setRPass] = useState('');

  useEffect(() => {
    document.title = 'Profile - ' + userCode + ' - ' + JSON.parse(localStorage.getItem('branchName'))
    setTimeout(function () { setIsLoadingBackDrop(false); }, 1250);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useImperativeHandle(ref, () => ({
    localSave() {

    }
  }));

  const handleClick = () => {
    if (isModal === false) {
      setIsModal(true);
    } else {
      setIsModal(false);
    }
  };

  const handleChangeProfile = () => {
    setTitleModal('User Role')
    handleClick()
  }

  const handleClickPass = () => {
    if (isModalPass === false) {
      setIsModalPass(true);
    } else {
      setIsModalPass(false);
      emptyStatePass();
    }
  };

  const handleChangePassword = () => {
    handleClickPass()
  }

  const emptyStatePass = () => {
    setCPass('');
    setNPass('');
    setRPass('');
  }

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    name = name.toUpperCase();
    let nama = name;
    let pjg = name.split(' ').length;

    if (pjg > 1) {
      nama = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
    }
    else {
      nama = `${name.split(' ')[0][0]}`
    }

    return {
      sx: {
        bgcolor: stringToColor(name),
        // bgcolor: '#45454d',
      },
      children: nama
    };
  }

  return (
    <>
      <ToastContainer />
      <Box sx={{ backgroundColor: '#E4E8EC', width: '100%', p: '24px 32px' }}>
        <Card component="div" style={{ marginTop: '16px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
          <CardContent>
            <Stack direction={'row'} spacing={8} className='mt-3'>
              <div>
                {
                  isLoadingBackDrop ?
                    <Skeleton variant="circular" width={180} height={180} />
                    :
                    <Avatar style={{ width: 180, height: 180, fontSize: 100 }} {...stringAvatar(username)} />
                }
              </div>

              <div style={{ width: '100%' }}>
                <Grid container sx={{ width: '100%', alignItems: 'center' }} spacing={'48px'}>
                  <Grid
                    item
                    xl={7}
                    lg={7}
                    sm={7}
                    xs={7}
                  >
                    <Grid container>
                      <Grid
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={6}
                        display={'flex'}
                        sx={{ alignItems: 'center' }}
                      >
                        {
                          isLoadingBackDrop ?
                            <Skeleton width={200} />
                            :
                            <>
                              <Typography
                                sx={{
                                  color: 'var(--Blue-Primary-1, #083A50)',
                                  fontFamily: 'Nunito',
                                  fontSize: '18px',
                                  fontStyle: 'normal',
                                  fontWeight: '600',
                                  lineHeight: '20px',
                                }}
                              >
                                User Role {userCode}'s
                              </Typography>
                            </>
                        }
                      </Grid>

                      <Grid
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={6}
                        alignItems='right'
                        className='text-end'
                        display={'flex'}
                        justifyContent={'flex-end'}
                      >
                        {
                          isLoadingBackDrop ?
                            <Skeleton width={145} />
                            :
                            <div
                              style={{
                                display: 'flex',
                                padding: '4px 8px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '10px',
                                borderRadius: '8px',
                                border: '2px solid var(--Green-Button, #168444)',
                                background: '#96F4BD',
                              }}
                            >
                              <Typography
                                sx={{
                                  color: 'var(--Green-Button, #168444)',
                                  fontFamily: 'Nunito',
                                  fontSize: '14px',
                                  fontStyle: 'normal',
                                  fontWeight: '700',
                                  lineHeight: '20px',
                                }}
                              >
                                Active User Role
                              </Typography>
                            </div>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <div style={{ width: '100%', marginTop: '16px' }}>
                  <Grid container sx={{ width: '100%' }}>
                    <Grid
                      item
                      xl={7}
                      lg={7}
                      sm={7}
                      xs={7}
                    >
                      <Grid container>
                        <Grid
                          item
                          xl={2}
                          lg={2}
                          sm={2}
                          xs={2}
                        >
                          <div className='text-end'>
                            {
                              isLoadingBackDrop ?
                                <Skeleton width={100} />
                                :
                                <Typography
                                  sx={{
                                    color: 'var(--Blue-Primary-1, #083A50)',
                                    fontFamily: 'Nunito',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '20px',
                                  }}
                                >
                                  Country:
                                </Typography>
                            }
                          </div>

                          <div className='text-end' style={{ marginTop: '12px' }}>
                            {
                              isLoadingBackDrop ?
                                <Skeleton width={100} />
                                :
                                <Typography
                                  sx={{
                                    color: 'var(--Blue-Primary-1, #083A50)',
                                    fontFamily: 'Nunito',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '20px',
                                  }}
                                >
                                  Company:
                                </Typography>
                            }
                          </div>

                          <div className='text-end' style={{ marginTop: '12px' }}>
                            {
                              isLoadingBackDrop ?
                                <Skeleton width={100} />
                                :
                                <Typography
                                  sx={{
                                    color: 'var(--Blue-Primary-1, #083A50)',
                                    fontFamily: 'Nunito',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '20px',
                                  }}
                                >
                                  Branch:
                                </Typography>
                            }
                          </div>

                          <div className='text-end' style={{ marginTop: '12px' }}>
                            {
                              isLoadingBackDrop ?
                                <Skeleton width={100} />
                                :
                                <Typography
                                  sx={{
                                    color: 'var(--Blue-Primary-1, #083A50)',
                                    fontFamily: 'Nunito',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '20px',
                                  }}
                                >
                                  Role:
                                </Typography>
                            }
                          </div>
                        </Grid>

                        <Grid
                          item
                          xl={10}
                          lg={10}
                          sm={10}
                          xs={10}
                        >
                          <div className='text-start' style={{ marginLeft: '48px' }}>
                            {
                              isLoadingBackDrop ?
                                <Skeleton width={300} />
                                :
                                <Typography
                                  sx={{
                                    color: 'var(--Blue-Primary-1, #083A50)',
                                    fontFamily: 'Nunito',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '20px',
                                  }}
                                >
                                  {countryName}
                                </Typography>
                            }
                          </div>

                          <div className='text-start' style={{ marginLeft: '48px', marginTop: '12px' }}>
                            {
                              isLoadingBackDrop ?
                                <Skeleton width={300} />
                                :
                                <Typography
                                  sx={{
                                    color: 'var(--Blue-Primary-1, #083A50)',
                                    fontFamily: 'Nunito',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '20px',
                                  }}
                                >
                                  {companyName}
                                </Typography>
                            }
                          </div>

                          <div className='text-start' style={{ marginLeft: '48px', marginTop: '12px' }}>
                            {
                              isLoadingBackDrop ?
                                <Skeleton width={300} />
                                :
                                <Typography
                                  sx={{
                                    color: 'var(--Blue-Primary-1, #083A50)',
                                    fontFamily: 'Nunito',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '20px',
                                  }}
                                >
                                  {branchName}
                                </Typography>
                            }
                          </div>

                          <div className='text-start' style={{ marginLeft: '48px', marginTop: '12px' }}>
                            {
                              isLoadingBackDrop ?
                                <Skeleton width={300} />
                                :
                                <Typography
                                  sx={{
                                    color: 'var(--Blue-Primary-1, #083A50)',
                                    fontFamily: 'Nunito',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '20px',
                                  }}
                                >
                                  {roleName}
                                </Typography>
                            }
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Stack>

            <Box display="flex" alignItems="center" sx={{ marginTop: '18px', paddingBottom: '-16px !important' }}>
              <Grid container item direction="row">
                <Grid item xs={12} className='text-end' display={'flex'} justifyContent={'flex-end'} sx={{ gap: '10px' }}>
                  <Stack direction={'column'}>
                    <Typography
                      sx={{
                        color: 'var(--Blue-Primary-1, #083A50)',
                        fontFamily: 'Nunito',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: '20px',
                      }}
                    >
                      Version: 1.0.2
                    </Typography>

                    <Typography
                      sx={{
                        color: 'var(--Blue-Primary-1, #083A50)',
                        fontFamily: 'Nunito',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: '20px',
                      }}
                    >
                      {/* Last Updated: {new Date().toLocaleString('us-US', { year: 'numeric', month: 'long', day: '2-digit' })} at {new Date().toLocaleTimeString('id-ID', { hour: '2-digit', minute: '2-digit', hour12: false })} */}
                      Last Updated: August 20, 2024 at 08.13
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>

        <Grid
          container
          className='w-100'
        >
          <Grid
            item
            xl={6}
            lg={6}
            sm={6}
            xs={6}
            display={'flex'}
            sx={{ alignItems: 'center' }}
          >
            <Stack sx={{ marginTop: '18px' }} direction='row' spacing={'24px'}>
              {
                isLoadingBackDrop ?
                  <Skeleton width={225} height={40} />
                  :
                  <Button
                    size='small'
                    variant="outlined"
                    className='d-flex align-items-center'
                    sx={{
                      padding: '4px 12px !important',
                      gap: '8px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '32px !important',
                      minHeight: '28px !important',
                      borderRadius: '8px',
                      background: 'var(--Blue-Button-Primary-1, #0087C2)',
                      '&:hover': {
                        backgroundColor: 'var(--Blue-Button-Primary-1, #0087C2) !important',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                      }
                    }}
                    onClick={handleChangePassword}
                  >
                    <Icon
                      iconName={'password'}
                      svgIconProps={{
                        htmlColor: '#F2FAFF',
                        sx: {
                          height: '16px',
                          width: '16px'
                        }
                      }}
                    />

                    <Typography
                      sx={{
                        color: 'var(--Blue-Primary-2, #F2FAFF)',
                        fontFamily: 'Nunito',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: '20px',
                      }}
                    >
                      Change Password
                    </Typography>
                  </Button>
              }

              {
                isLoadingBackDrop ?
                  <Skeleton width={225} height={40} />
                  :
                  <Button
                    size='small'
                    variant="outlined"
                    className='d-flex align-items-center'
                    sx={{
                      padding: '4px 12px !important',
                      gap: '8px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '32px !important',
                      minHeight: '28px !important',
                      borderRadius: '8px',
                      background: 'var(--Blue-Button-Primary-1, #0087C2)',
                      '&:hover': {
                        backgroundColor: 'var(--Blue-Button-Primary-1, #0087C2) !important',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                      }
                    }}
                    onClick={handleChangeProfile}
                  >
                    <Icon
                      iconName={'change-user'}
                      svgIconProps={{
                        htmlColor: '#F2FAFF',
                        sx: {
                          height: '16px',
                          width: '16px',
                          marginTop: '4px'
                        }
                      }}
                    />

                    <Typography
                      sx={{
                        color: 'var(--Blue-Primary-2, #F2FAFF)',
                        fontFamily: 'Nunito',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: '20px',
                      }}
                    >
                      Change User Role
                    </Typography>
                  </Button>
              }
            </Stack>
          </Grid>

          <Grid
            item
            xl={6}
            lg={6}
            sm={6}
            xs={6}
            alignItems='right'
            className='text-end'
            display={'flex'}
            justifyContent={'flex-end'}
          >
            {
              isLoadingBackDrop ?
                <Skeleton width={145} height={40} sx={{ marginTop: '18px', }} />
                :
                <Button
                  size='small'
                  variant="outlined"
                  className='d-flex align-items-center'
                  sx={{
                    padding: '4px 12px !important',
                    gap: '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: 'var(--Blue-Button-Primary-1, #DB3838)',
                    marginTop: '18px',
                    '&:hover': {
                      backgroundColor: 'var(--Blue-Button-Primary-1, #DB3838) !important',
                      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                  }}
                >
                  <Icon
                    iconName={'log-out'}
                    svgIconProps={{
                      htmlColor: '#F2FAFF',
                      sx: {
                        height: '16px',
                        width: '16px',
                      }
                    }}
                  />

                  <Typography
                    sx={{
                      color: 'var(--Blue-Primary-2, #F2FAFF)',
                      fontFamily: 'Nunito',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: '20px',
                    }}
                  >
                    Log Out
                  </Typography>
                </Button>
            }
          </Grid>
        </Grid>
      </Box>

      {isModal && (
        <ModalProfile
          isModal={isModal} handleClick={handleClick}
          titleModal={titleModal} setIsModal={setIsModal}
          dataProfile={dataProfile}
        />
      )
      }

      {isModalPass && (
        <ModalChangePassword
          isModal={isModalPass} handleClick={handleClickPass} setIsModal={setIsModalPass}

          CPass={CPass} setCPass={setCPass}
          NPass={NPass} setNPass={setNPass}
          RPass={RPass} setRPass={setRPass}
        />
      )
      }

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingBackDrop}
      >
        <LoadingSpinner />
      </Backdrop>
    </>
  )
})

export default Profile