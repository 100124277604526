import React, { forwardRef, useState, useEffect, useRef } from 'react'
import { Backdrop, Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router';

import CustomerServiceReport from './CustomerServiceReport';
import GeneralButton from './components/GeneralButton';
import FilterSection from './components/FilterSection';

import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import ButtonComponent from '../../../components/Buttons/ButtonComponent';
import TableX from '../../../components/Tables/TableX';
import ApiService from '../../../services/ApiService';

import freezeContainer from '../../../Styles/freezeContainer';
import HeaderInfo from '../../../components/Components/HeaderInfo';
import CustomToastContainer, { Paginate, showToast } from '../../../components/ui';
import { updateDateFormat } from '../../../utils/date';

const CustomerServiceList = forwardRef((props, ref) => {
  const date = updateDateFormat(new Date());
  const history = useNavigate();
  const btnPageRef = useRef(null)
  const btnTableRef = useRef(null)

  const [dataRegUserAccess, setDataRegUserAccess] = useState([]);

  const [isLoading, setIsLoading] = useState(true)
  const [isOpenReport, setIsOpenReport] = useState(false)

  const [dataTable, setDataTable] = useState([])
  const [columnData, setColumnData] = useState([])
  const [selectedData, setSelectedData] = useState({});

  // Filter
  const [goTo, setGoTo] = useState(1)
  const [stateX, setStateX] = useState({})
  const [filter, setFilter] = useState([])
  const [numPage, setNumPage] = useState(1)
  const [sortArr, setSortArr] = useState([])
  const [filterBy, setFilterBy] = useState([])
  const [rowsCount, setRowsCount] = useState(50)
  const [totalRows, setTotalRows] = useState(0)

  const [jobTypeList, setJobTypeList] = useState([])
  const [principleList, setPrincipleList] = useState([])

  const localJobTypeId = localStorage.getItem("jobTypeId")

  const [filterData, setFilterData] = useState({
    jobType: localJobTypeId || 1,
    principle: 'all',
    range: 'all',
    start: date,
    end: date,
  })

  const BreadcrumbsItems = [
    { label: 'Infoss', link: '/Infoss', style: '' },
    { label: 'Customer Service', link: '/Infoss/CustomerService', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useEffect(() => {
    document.title = 'Customer Service - ' + JSON.parse(localStorage.getItem('branchName'));
    getDataAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const errorToast = (location) => {
    showToast({ type: 'error', message: `Can't get ${location} data! please contact Administrator` })
  }

  const getDataAccess = () => {
    let params = [];
    let pId = 0;
    let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
    let hasAccess = false

    const indexMenu = listMenu.findIndex((elm) => elm.code === 'OSHOR')
    if (indexMenu !== -1) {
      pId = listMenu[indexMenu].id
      hasAccess = true
    }

    if (hasAccess) {
      params = [
        ...params,
        { 'attr': 'permissionId', 'value': '' + pId },
        { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
        { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
      ]
      ApiService.IdentityPostByPage('regUserAccess/regUserAccess', 'PAGE', 1, 100, params, [])
        .then((res) => {
          if (res.data.code === 200) {
            setDataRegUserAccess(res.data.data.userAccess);
            getData(1, 50)
            getAdditionalData()
          } else if (res.data.code === 500) {
            showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + res.data.message })
            setIsLoading(false);
          }
        }).catch((err) => {
          console.error('Error: ', err)
          history('/Infoss')
        })
    } else {
      history('/Infoss');
    }
  }

  const getData = async (pageNumber, pageSize, tableFilter, pageFilter, orderBy) => {
    setIsLoading(true)

    const pgFilter = pageFilter || filterData
    let params = []
    if (pgFilter.jobType) {
      params.push({ 'attr': 'jobTypeId', 'value': String(pgFilter.jobType) })
    }
    if (pgFilter.principle !== 'all') {
      params.push({ 'attr': 'jobOwnerId', 'value': String(pgFilter.principle) })
    }
    if (pgFilter.range !== 'all') {
      params.push({ 'attr': 'etd', 'value': pgFilter.start })
      params.push({ 'attr': 'eta', 'value': pgFilter.end })
    }

    try {
      const res = await ApiService.OperationDynamicPostByPage('shipmentOrder/shipmentOrder', 'PAGE', pageNumber, pageSize, params, tableFilter, orderBy)
      if (res.data.code === 200) {
        setDataTable(res.data.data.shipmentOrder)

        setTotalRows(res.data.totalRowCount)
        setColumnData(res.data.data.columns.headerColumns)
      } else if (res.data.code === 500) {
        showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + res.data.message })
      } else {
        errorToast('shipment order')
      }
    } catch (error) {
      console.log(error)
      errorToast('shipment order')
    }
    setIsLoading(false)
  }

  const getAdditionalData = async () => {
    try {
      const [resJT, resJO] = await Promise.all([
        ApiService.PostByPage('regJobType/regJobType', 1, 999, []),
        ApiService.PostByPage('jobOwner/jobOwner', 1, 999, []),
      ])

      if (resJT?.data?.code === 200) {
        const tempData = resJT.data.data.jobType
        const mapData = tempData.map((jt) => {
          return { value: jt.id, label: jt.name }
        })

        setJobTypeList(mapData)
      } else if (resJT.data.code === 500) {
        showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + resJT.data.message })
      } else {
        errorToast('job type')
      }

      if (resJO?.data?.code === 200) {
        const tempData = resJO.data.data.jobOwner
        const mapData = tempData.map((jo) => {
          return { value: jo.id, label: jo.name }
        })

        setPrincipleList(mapData)
      } else if (resJO.data.code === 500) {
        showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + resJO.data.message })
      } else {
        errorToast('principal')
      }

    } catch (error) {
      errorToast('additional')
    }
  }

  const changeFilter = (key, value) => {
    const rangeFilter = {}
    if (key === 'range') {
      rangeFilter.start = date
      rangeFilter.end = date
    }

    const mapFilter = {
      ...filterData,
      ...rangeFilter,
      [key]: value,
    }

    setFilterData(mapFilter)

    if (['jobType', 'principle'].includes(key)) {
      getData(1, rowsCount, filter, mapFilter, [])
    }
  }

  const exportData = (fileType) => {
    console.log('Export to: ', fileType)
  }

  const reloadData = () => {
    setSelectedData({})
    setNumPage(1)
    setGoTo(1)
    getData(1, rowsCount, filter, filterData, [])
  }

  const resetData = () => {
    setFilterData({
      jobType: localJobTypeId || 1,
      principle: 'all',
      range: 'all',
      start: date,
      end: date,
    })

    setGoTo(1);
    setNumPage(1);
    setRowsCount(50);
    setTotalRows(0);
    setFilter([]);
    setFilterBy([]);
    setStateX({})
    setSortArr([])
    setSelectedData({})

    getData(1, 50)
  }

  const cellDoubleClick = (row) => {
    setIsOpenReport(!isOpenReport)
  }

  const handleModal = () => {
    setIsOpenReport(!isOpenReport)
  }

  const getContainerHeight = () => {
    const hTitle = 64
    const hHeader = 144
    const hPadding = 10 * 2
    const hBtnPage = btnPageRef?.current?.clientHeight || 28
    const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

    const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable

    return `${totalHeight}px`
  }

  const ButtonComponents = () => {
    return (
      <>
        <GeneralButton
          reloadData={reloadData}
          exportData={exportData}
          findData={cellDoubleClick}
          dataRegUserAccess={dataRegUserAccess}
        />

        <Grid item xs={12} sx={{ marginTop: '16px' }}>
          <FilterSection
            filterData={filterData}
            changeFilter={changeFilter}
            jobTypeList={jobTypeList}
            principleList={principleList}
          />
        </Grid>
      </>
    )
  }

  const onChangePaging = (value) => {
    setNumPage(value);
    getData(value, rowsCount, filter);
  }

  const onChangeLimit = (value) => {
    setNumPage(1);
    setRowsCount(value);
    getData(1, value, filter);
  }

  return (
    <>
      <Box className='w-100' sx={{ width: '100%', p: '24px 32px', ...freezeContainer }}>
        <CustomToastContainer />
        <Grid container spacing={0} direction="column">
          <HeaderInfo btnPageRef={btnPageRef}
            title={'Customer Service'}
            isJobType={false}
            BreadcrumbsItems={BreadcrumbsItems}
            ButtonComponents={ButtonComponents}
          />

          <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
            <div ref={btnTableRef}>
              {
                ButtonComponent.RefreshButton(
                  sortArr,
                  rowsCount,
                  columnData,
                  setGoTo,
                  resetData,
                  setFilterBy,
                  filterBy,
                  setFilter,
                  setNumPage,
                  setRowsCount,
                  getData,
                  stateX,
                  setStateX
                )
              }
            </div>

            <div
              className="rounded-10 table-responsive"
              style={{
                maxHeight: `calc(100vh - ${getContainerHeight()})`,
                marginTop: '16px',
                padding: '2px 16px',
                borderRadius: '12px',
                background: '#F2F2F2'
              }}
            >
              <TableX
                uniqueKey={'id'}
                goTo={goTo}
                rowsCount={rowsCount}
                setNumPage={setNumPage}
                setRowsCount={setRowsCount}
                getData={getData}
                columnData={columnData}
                dataMap={dataTable}
                SelectedData={selectedData}
                setSelectedData={setSelectedData}
                setFilter={setFilter}
                setFilterBy={setFilterBy}
                CellDoubleClick={cellDoubleClick}
                stateX={stateX}
                setStateX={setStateX}
                sortArr={sortArr}
                setSortArr={setSortArr}
                setGoTo={setGoTo}
              />
            </div>

            <div style={{ marginTop: '16px' }}>
              <Paginate
                page={numPage}
                limit={rowsCount}
                totalData={totalRows}
                onChange={onChangePaging}
                onChangeLimit={onChangeLimit}
              />
            </div>
          </Grid>
        </Grid>

        {
          isLoading && (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
            >
              <LoadingSpinner color="inherit" />
            </Backdrop>
          )
        }
      </Box>

      {isOpenReport && (
        <CustomerServiceReport
          isOpen={isOpenReport}
          closeModal={handleModal}
          selectedData={selectedData}
          exportData={exportData}
          errorToast={errorToast}
          jobTypeList={jobTypeList}
        />
      )}
    </>
  )
})

export default CustomerServiceList