import React, { useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import { useNavigate } from 'react-router-dom';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import { Grid, Box, MenuItem, Card, CardContent, Stack, InputAdornment, IconButton } from '@mui/material';

import ApiService from '../../../../services/ApiService';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import ButtonCustomerPaymentView from './Buttons/ButtonCustomerPaymentView';
import SelectDataPeriod from './Selects/SelectDataPeriod';
import SelectDataContact from './Selects/SelectDataContact';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Icon, Input, Selection, showToast } from '../../../../components/ui';

const CustomerPaymentView = forwardRef((props, ref) => {
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const history = useNavigate();
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [isCompany, setIsCompany] = useState('C');
    const CustomCompanyData = [
        { id: 1, code: 'C', name: 'Per Company' },
        { id: 2, code: 'R', name: 'Per Regional' },
    ];
    const [isRecap, setIsRecap] = useState(true);
    const ReportTypeData = [
        { id: 1, code: true, name: 'Recapitulation' },
        { id: 2, code: false, name: 'Detail' }
    ];
    const [isPeriod, setIsPeriod] = useState(true);
    const CustomPeriodData = [
        { id: 1, code: true, name: 'Per Closing Period' },
        { id: 2, code: false, name: 'Custom Date' },
    ]
    const [period, setPeriod] = useState('');
    const [yearPeriod, setYearPeriod] = useState('');
    const [beginningPeriod, setBeginningPeriod] = useState('');
    const [closingPeriod, setClosingPeriod] = useState('');
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [contactTypeId, setContactTypeId] = useState(4);
    const ContactTypeData = [
        { id: 1, code: 4, name: 'SSLine' },
        { id: 2, code: 5, name: 'IATA' },
        { id: 3, code: 6, name: 'EMKL' },
        { id: 4, code: 7, name: 'Depo' },
        { id: 5, code: 8, name: 'Discount Shipper' },
        { id: 6, code: 9, name: 'Discount Consignee' },
    ]
    const [isCustomCustomer, setIsCustomCustomer] = useState(false);
    const CustomCustomerData = [
        { id: 1, code: false, name: 'All Per Customer' },
        { id: 2, code: true, name: 'Custom Per Customer' },
    ]
    const [contactId, setContactId] = useState(0);
    const [contactCode, setContactCode] = useState('');
    const [contactName, setContactName] = useState('');

    const paramP = "regClosingPeriod/regClosingPeriod";
    const [isLoadingP, setIsLoadingP] = useState(false);
    const [isModalP, setIsModalP] = useState(false);
    const [dataP, setDataP] = useState([]);
    const [titleModalP, setTitleModalP] = useState('');
    const [columnDataP, setColumnDataP] = useState([]);
    const [SelectedDataP, setSelectedDataP] = useState({});
    const [totalRowsP, setTotalRowsP] = useState(50);
    const [dataMapP, setDataMapP] = useState([]);

    const paramC = "regContact/regContact";
    const [isLoadingC, setIsLoadingC] = useState(false);
    const [isModalC, setIsModalC] = useState(false);
    const [dataC, setDataC] = useState([]);
    const [titleModalC, setTitleModalC] = useState('');
    const [columnDataC, setColumnDataC] = useState([]);
    const [SelectedDataC, setSelectedDataC] = useState({});
    const [totalRowsC, setTotalRowsC] = useState(50);
    const [dataMapC, setDataMapC] = useState([]);

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const BreadcrumbsItems = [
        { label: 'Infoss', link: '/Infoss', style: '' },
        { label: 'Customer Payment', link: '/Infoss/CustomerPaymentReport', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'Customer Payment - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDataAccess = () => {
        setIsLoadingBackDrop(true);
        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'OSHOR')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);

                        setIsLoadingBackDrop(false);
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Infoss');
                })
        }
        else {
            history('/Infoss');
        }
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationCOSC() {
        if (titleConfirmation === 'Print') {
            PrintPDF();
        }
        setIsModalConfirmation(false);
    }

    const checkInput = () => {
        let check = 0;

        if (isPeriod === true && period === '') {
            check = 1;
        }
        else if (isCustomCustomer === true && contactCode === '') {
            check = 2;
        }

        if (check === 1) {
            showToast({ type: 'error', message: 'Please Select Data Period!' })
        }
        else if (check === 2) {
            showToast({ type: 'error', message: 'Please Select Data Customer!' })
        }

        return check;
    }

    const PrintData = () => {
        if (checkInput() === 0) {
            setTitleConfirmation('Print')
            setTitleModalConfirmation('Customer Payment');
            handleClickConfirmation();
        }
    };

    function PrintPDF() {
        setIsLoadingBackDrop(true);

        let dateStart = '';
        let dateEnd = '';
        if (isPeriod === true) {
            dateStart = beginningPeriod;
            dateEnd = closingPeriod;
        }
        else if (isPeriod === false) {
            dateStart = dateFrom;
            dateEnd = dateTo;
        }

        let variable = "PrintCustomerPayment?IsRecap=" + isRecap;
        let params = [
            {
                "attr": "StartDate",
                "value": '' + dateStart
            },
            {
                "attr": "EndDate",
                "value": '' + dateEnd
            },
            {
                "attr": "CustomerTypeId",
                "value": '' + contactTypeId
            },
            {
                "attr": "CustomerId",
                "value": '' + contactId
            }
        ]
        ApiService.ReportPrint("Reports/Finance/Print/ControlReport/CustomerPaymentReport/", variable, params)
            .then((response) => {
                if (response.status === 200) {
                    let rptFormat = "application/pdf";

                    const file = new Blob([response.data], { type: rptFormat });
                    const fileURL = URL.createObjectURL(file);

                    setIsLoadingBackDrop(false);
                    showToast({ type: 'success', message: 'Print Customer Payment Success' });
                    window.open(fileURL);
                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'Can\'t Print Customer Payment, ' + response.data.message });
                }
            })
            .catch((error) => {
                setIsLoadingBackDrop(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Print Customer Payment, ' + error });
            });
    }

    function handleIsPeriod(value) {
        setIsPeriod(value);
        if (value === true) {
            setDateFrom('');
            setDateTo('');
        }
        else {
            EmptyPeriod();
            getDateNow();
        }
    }

    function EmptyPeriod() {
        setPeriod('');
        setYearPeriod('');
        setBeginningPeriod('');
        setClosingPeriod('');
    }

    function handleClickPeriod() {
        setTitleModalP('Closing Period');
        if (isModalP === false) {
            getDataPeriod(1, 50);
            setIsModalP(true);
        }
        else {
            setIsModalP(false);
        }
    }

    const getDataPeriod = (pageNumber, pageSize, filters, orderBy) => {
        setIsLoadingP(true);
        ApiService.DynamicPostByPage(paramP, 'SEARCH', pageNumber, pageSize, [], filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataP({})
                    setDataP(response.data.data.closingPeriod);
                    let temp = response.data.data.closingPeriod
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapP(indexed)
                    setColumnDataP(response.data.data.columns)
                    setTotalRowsP(response.data.totalRowCount)
                    setIsLoadingP(false)
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error)
                setIsLoadingP(false)
            })
    }

    function getDateNow() {
        let dateNow = new Date()
        var formatDateNow = dateNow.toLocaleDateString("sv-se").split('T')[0];
        setDateFrom(formatDateNow);
        setDateTo(formatDateNow);
    }

    function handleDate(source, value) {
        if (source === 'From') {
            if (value > dateTo) {
                showToast({ type: 'error', message: 'Period From Can\'t Be Greater Than Period To' });
            }
            else {
                setDateFrom(value);
            }
        }
        else {
            if (value < dateFrom) {
                showToast({ type: 'error', message: 'Period To Can\'t Be Smaller Than Period From' });
            }
            else {
                setDateTo(value);
            }
        }
    }

    function handleContactTypeId(value) {
        setContactTypeId(value);
        EmptyContact();
    }

    function handleIsCustomCustomer(value) {
        setIsCustomCustomer(value);
        EmptyContact();
    }

    function EmptyContact() {
        setContactId(0);
        setContactCode('');
        setContactName('');
    }

    function handleClickContact() {
        let contactName = '';

        ContactTypeData.forEach(element => {
            if (element.code === contactTypeId) {
                contactName = element.name;
            }
        });

        setTitleModalC('Contact ' + contactName);
        if (isModalC === false) {
            getDataContact(1, 50);
            setIsModalC(true);
        }
        else {
            setIsModalC(false);
        }
    }

    const getDataContact = (pageNumber, pageSize, filters, orderBy) => {
        setIsLoadingC(true);
        ApiService.ContactPostByPage(paramC, contactTypeId, pageNumber, pageSize, filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataC({})
                    setDataC(response.data.data.contact);
                    let temp = response.data.data.contact
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapC(indexed)
                    setColumnDataC(response.data.data.columns)
                    setTotalRowsC(response.data.totalRowCount)
                    setIsLoadingC(false)
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error)
                setIsLoadingC(false)
            })
    }

    const ButtonComponents = () => {
        return (
            <ButtonCustomerPaymentView
                PrintData={PrintData}
                DataRegUserAccess={DataRegUserAccess}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: 3 }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    title={'Customer Payment'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div style={{ marginTop: '24px' }}>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Grid
                                            container
                                            spacing={'16px'}
                                        >
                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Selection
                                                    id='0'
                                                    fullWidth={true}
                                                    value={isCompany}
                                                    label="Custom Company"
                                                    placeholder={'Select Custom Company'}
                                                    onChange={(e) => setIsCompany(e.target.value)}
                                                >
                                                    {
                                                        CustomCompanyData.map((data) => (
                                                            <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                                                {data.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Selection>
                                            </Grid>

                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Selection
                                                    id='0'
                                                    value={isRecap}
                                                    fullWidth={true}
                                                    label="Report Type"
                                                    placeholder={'Select Report Type'}
                                                    onChange={(e) => setIsRecap(e.target.value)}
                                                >
                                                    {
                                                        ReportTypeData.map((data) => (
                                                            <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                                                {data.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Selection>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>

                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px', marginTop: '24px' }}>
                                    <CardContent>
                                        <Selection
                                            id='0'
                                            value={isPeriod}
                                            fullWidth={true}
                                            label="Custom Period"
                                            placeholder={'Select Custom Period'}
                                            onChange={(e) => handleIsPeriod(e.target.value)}
                                        >
                                            {
                                                CustomPeriodData.map((data) => (
                                                    <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                                        {data.name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Selection>

                                        <div hidden={!isPeriod}>
                                            <Stack spacing={'16px'} direction="row" sx={{ marginTop: "16px" }}>
                                                <Input
                                                    readOnly
                                                    label="Period"
                                                    value={period}
                                                    disabled
                                                />

                                                <Input
                                                    fullWidth
                                                    value={yearPeriod}
                                                    label="Year Period"
                                                    InputLabelProps={{ shrink: true }}
                                                    disabled
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                        }
                                                    }}
                                                    inputBaseProps={{
                                                        readOnly: true,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={() => EmptyPeriod()}
                                                                    hidden={period ? false : true}
                                                                >
                                                                    <Icon
                                                                        iconName={'close'}
                                                                        svgIconProps={{
                                                                            fontSize: 'small',
                                                                            htmlColor: '#0087C2',
                                                                            sx: {
                                                                                height: '12px',
                                                                                width: '12px'
                                                                            }
                                                                        }}
                                                                    />
                                                                </IconButton>

                                                                <IconButton onClick={() => handleClickPeriod()}>
                                                                    <Icon
                                                                        iconName={'search'}
                                                                        svgIconProps={{
                                                                            fontSize: 'small',
                                                                            htmlColor: '#0087C2',
                                                                            sx: {
                                                                                height: '12px',
                                                                                width: '12px'
                                                                            }
                                                                        }}
                                                                    />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Stack>

                                            <Grid
                                                container
                                                spacing={'16px'}
                                            >
                                                <Grid
                                                    item
                                                    xl={6}
                                                    lg={6}
                                                    sm={6}
                                                    xs={12}
                                                >
                                                    <Input
                                                        disabled
                                                        value={beginningPeriod}
                                                        label="Beginning Period"
                                                        formControlProps={{
                                                            sx: {
                                                                width: '100%',
                                                                marginTop: '16px !important'
                                                            }
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid
                                                    item
                                                    xl={6}
                                                    lg={6}
                                                    sm={6}
                                                    xs={12}
                                                >
                                                    <Input
                                                        disabled
                                                        value={closingPeriod}
                                                        label="Ending Period"
                                                        formControlProps={{
                                                            sx: {
                                                                width: '100%',
                                                                marginTop: '16px !important'
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>

                                        <Grid
                                            container
                                            spacing={'16px'}
                                            hidden={isPeriod}
                                        >
                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Input
                                                    type='date'
                                                    value={dateFrom}
                                                    label="Transaction Date From"
                                                    onChange={(e) => handleDate("From", e.target.value)}
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                            marginTop: '16px !important'
                                                        }
                                                    }}
                                                />
                                            </Grid>

                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Input
                                                    type='date'
                                                    value={dateTo}
                                                    label="Transaction Date To"
                                                    onChange={(e) => handleDate("To", e.target.value)}
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                            marginTop: '16px !important'
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Grid
                                            container
                                            spacing={'16px'}
                                        >
                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Selection
                                                    id='0'
                                                    fullWidth={true}
                                                    value={contactTypeId}
                                                    label="Customer Type"
                                                    placeholder={'Select Customer Type'}
                                                    onChange={(e) => handleContactTypeId(e.target.value)}
                                                >
                                                    {
                                                        ContactTypeData.map((data) => (
                                                            <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                                                {data.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Selection>
                                            </Grid>

                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Selection
                                                    id='0'
                                                    fullWidth={true}
                                                    label="Custom Customer"
                                                    value={isCustomCustomer}
                                                    placeholder={'Select Custom Customer'}
                                                    onChange={(e) => handleIsCustomCustomer(e.target.value)}
                                                >
                                                    {
                                                        CustomCustomerData.map((data) => (
                                                            <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                                                {data.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Selection>
                                            </Grid>
                                        </Grid>

                                        <Stack spacing={2} direction="row" className='mt-3'>
                                            <Input
                                                readOnly
                                                value={contactCode}
                                                label="Customer Code"
                                                disabled
                                            />

                                            <Input
                                                fullWidth
                                                value={contactName}
                                                label="Customer Name"
                                                disabled
                                                InputLabelProps={{ shrink: true }}
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                    }
                                                }}
                                                inputBaseProps={{
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => EmptyContact()}
                                                                hidden={contactId !== 0 ? false : true}
                                                            >
                                                                <Icon
                                                                    iconName={'close'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#0087C2',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>

                                                            <IconButton
                                                                onClick={() => handleClickContact()}
                                                                hidden={!isCustomCustomer}
                                                            >
                                                                <Icon
                                                                    iconName={'search'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#0087C2',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }

            {
                isModalP && (
                    <SelectDataPeriod
                        isModal={isModalP}
                        setIsModal={setIsModalP}
                        titleModal={titleModalP}
                        handleClick={handleClickPeriod}
                        isLoading={isLoadingP}
                        data={dataP}
                        getData={getDataPeriod}
                        columnData={columnDataP}
                        dataMap={dataMapP}
                        totalRows={totalRowsP}
                        setTotalRows={setTotalRowsP}
                        SelectedData={SelectedDataP}
                        setSelectedData={setSelectedDataP}

                        setPeriod={setPeriod}
                        setYearPeriod={setYearPeriod}
                        setBeginningPeriod={setBeginningPeriod}
                        setClosingPeriod={setClosingPeriod}
                    />
                )
            }

            {
                isModalC && (
                    <SelectDataContact
                        isModal={isModalC}
                        setIsModal={setIsModalC}
                        titleModal={titleModalC}
                        handleClick={handleClickContact}
                        isLoading={isLoadingC}
                        data={dataC}
                        getData={getDataContact}
                        columnData={columnDataC}
                        dataMap={dataMapC}
                        totalRows={totalRowsC}
                        setTotalRows={setTotalRowsC}
                        SelectedData={SelectedDataC}
                        setSelectedData={setSelectedDataC}

                        setContactId={setContactId}
                        setContactCode={setContactCode}
                        setContactName={setContactName}
                    />
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationCOSC}
                    />
                )
            }
        </Box>
    )
})

export default CustomerPaymentView