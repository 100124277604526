import { Grid, Stack, MenuItem, IconButton, InputAdornment, Typography, } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';

import ApiService from '../../../../../services/ApiService';
import TableInvoice from '../Tables/TableInvoice';
import TabsInvoice from '../Tabs/TabsInvoice';
import SelectDataContact from '../Selects/SelectDataContact';
import { Icon, Input, Selection, showToast } from '../../../../../components/ui';

const OfficialReceiptDetail = (props) => {
    const [tabValue, setTabValue] = useState('TR');
    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);

    const paramC = "invoice/invoice";
    const [isLoadingC, setIsLoadingC] = useState(false);
    const [dataC, setDataC] = useState([]);
    const [SelectedDataC, setSelectedDataC] = useState({});
    const [totalRowsC, setTotalRowsC] = useState(50);
    const [dataMapC, setDataMapC] = useState([])
    const [columnDataC, setColumnDataC] = useState([])

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataContact(1, 50);
        }
        else {
            setIsModal(false);
        }
    };

    const searchCustomer = (data) => {
        if (data === 'search') {
            setTitleModal('Customer');
            handleClick();
        }
        else if (data === 'delete') {
            props.setCustomerId(0);
            props.setCustomerName('');
            props.setIsNPWP(false);

            props.setInvoiceList([]);
            props.setInvoiceIdList([]);
            props.calculateTotalInvoice([]);
            props.calculateTotalTR([]);
            props.calculateTotalRV([]);
            props.calculateTotalCash([]);
            props.calculateTotalBank([]);
            props.calculateTotalDifferent([]);
        }
    };

    const DataPaymentBy = [
        { id: 1, code: 2, name: 'Shipper' },
        { id: 2, code: 3, name: 'Consignee' },
        { id: 3, code: 1, name: 'Agent' },
        { id: 4, code: 10, name: 'Shipper Trucking' },
    ];

    function handlePaymentCode(val) {
        props.setPaymentByCode(val.toString());
        props.setCustomerId(0);
        props.setCustomerName('');
        props.resetDataList();
    }

    const getDataContact = (pageNumber, pageSize, filters) => {
        setIsLoadingC(true);
        var type = props.PaymentByCode;
        if (type != null && type !== '') {
            let params = [];
            params = [
                ...params,
                {
                    'attr': 'customerTypeId',
                    'value': '' + type,
                    'isDistinct': true
                },
                {
                    'attr': 'customerId',
                    'value': '',
                    'isDistinct': true
                },
                { 'attr': 'debetCredit', 'value': 'D' },
                { 'attr': 'deleteType', 'value': '0' },
                { 'attr': 'ORId', 'value': '0' },
                { 'attr': 'isPosted', 'value': 'true' },
                { 'attr': 'paid', 'value': 'false' }
            ];

            ApiService.OperationDynamicPostByPage(paramC, 'SEACON', pageNumber, pageSize, params, filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataC({})
                        setDataC(response.data.data.invoices);

                        let temp = response.data.data.invoices
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMapC(indexed)
                        setColumnDataC(response.data.data.columns.headerColumns)
                        setTotalRowsC(response.data.totalRowCount)

                        setIsLoadingC(false)
                    }
                    setIsLoadingC(false)
                })
                .catch(function (error) {
                    setIsLoadingC(false);
                    showToast({ type: 'error', message: error.toString() });
                })
        }
        else {
            setIsLoadingC(false);
            showToast({ type: 'error', message: 'Please Contact The Administrator!' });
        }
    };

    return (
        <div>
            <form>
                <Box>
                    <Box>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={2}
                                lg={2}
                                sm={2}
                                xs={12}
                                hidden={!props.isEdit}
                            >
                                <Input
                                    label="OR Number"
                                    value={props.ORNumber}
                                    textAlign='right'
                                    disabled
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />

                                <Input
                                    label="Print Date"
                                    value={props.PrintDate}
                                    type={'date'}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />

                                <Input
                                    label="Verify"
                                    value={props.VerifyAccDate}
                                    type={'date'}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                xl={props.isEdit ? 8 : 10}
                                lg={props.isEdit ? 8 : 10}
                                sm={props.isEdit ? 8 : 10}
                                xs={12}
                            >
                                <Selection
                                    id='0'
                                    label={'Payment By'}
                                    fullWidth={true}
                                    placeholder={'Select Payment By'}
                                    value={props.PaymentByCode}
                                    onChange={(e) => handlePaymentCode(e.target.value)}
                                    disabled={props.isEdit}
                                    formControlProps={{
                                        sx: {
                                            // marginTop: '16px !important'
                                        }
                                    }}
                                >
                                    {
                                        DataPaymentBy.map((data, index) => (
                                            <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                        ))
                                    }
                                </Selection>

                                <div>
                                    {props.selectPrincipleBy}
                                </div>

                                <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                                    <Input
                                        label='Customer'
                                        value={props.CustomerId}
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                marginTop: '24px'
                                            }
                                        }}
                                    />

                                    <Input
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        value={props.CustomerName}
                                        type='text'
                                        label={''}
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                            }
                                        }}
                                        inputBaseProps={{
                                            sx: {
                                                marginTop: '23px !important',
                                            },
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => searchCustomer('delete')}
                                                        disabled={props.isJobOwner || props.isEdit}
                                                        hidden={props.CustomerName && props.Printing === 0 ? false : true}
                                                    >
                                                        <Icon
                                                            iconName={'close'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>

                                                    <IconButton
                                                        onClick={() => searchCustomer('search')}
                                                        disabled={props.isJobOwner || props.isEdit}
                                                        hidden={props.Printing > 0}
                                                    >
                                                        <Icon
                                                            iconName={'search'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        disabled
                                    />
                                </Stack>
                            </Grid>

                            <Grid
                                item
                                xl={2}
                                lg={2}
                                sm={2}
                                xs={12}
                            >
                                <Input
                                    label='Rate'
                                    value={props.RateCount}
                                    textAlign='right'
                                    disabled
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />

                                <Input
                                    label='Rate Date'
                                    value={props.RateDate}
                                    type={'date'}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <div>
                            <TableInvoice
                                calculateTotalInvoice={props.calculateTotalInvoice}
                                calculateTotalPPh23={props.calculateTotalPPh23}
                                calculateTotalCash={props.calculateTotalCash}
                                calculateTotalBank={props.calculateTotalBank}
                                calculateTotalRV={props.calculateTotalRV}
                                calculateTotalTR={props.calculateTotalTR}
                                calculateTotalDifferent={props.calculateTotalDifferent}
                                isNPWP={props.isNPWP}
                                InvoiceList={props.InvoiceList}
                                setInvoiceList={props.setInvoiceList}
                                TotalPPh={props.TotalPPh}
                                setTotalPPh={props.setTotalPPh}
                                PPH23List={props.PPH23List}
                                setPPH23List={props.setPPH23List}
                                PaymentByCode={props.PaymentByCode}
                                CustomerId={props.CustomerId}
                                setBankData={props.setBankData}
                                setCheckSelectedInvoice={props.setCheckSelectedInvoice}
                                setTRList={props.setTRList}
                                setRVList={props.setRVList}
                                Printing={props.Printing}
                                InvoiceUSD={props.InvoiceUSD}
                                setInvoiceUSD={props.setInvoiceUSD}
                                InvoiceIDR={props.InvoiceIDR}
                                setInvoiceIDR={props.setInvoiceIDR}
                                TotalInvoiceIDR={props.TotalInvoiceIDR}
                                setTotalInvoiceIDR={props.setTotalInvoiceIDR}
                                officialReceiptId={props.officialReceiptId}
                                RateCount={props.RateCount}
                                setTotalCashUSD={props.setTotalCashUSD}
                                setTotalCashIDR={props.setTotalCashIDR}
                                setTotalCashInIDR={props.setTotalCashInIDR}
                                setTotalBankUSD={props.setTotalBankUSD}
                                setTotalBankIDR={props.setTotalBankIDR}
                                setTotalBankInIDR={props.setTotalBankInIDR}
                                setCashPaymentUSD={props.setCashPaymentUSD}
                                setCashPaymentIDR={props.setCashPaymentIDR}
                                setTotalCashPaymentIDR={props.setTotalCashPaymentIDR}
                                invoiceIdList={props.invoiceIdList}
                                setInvoiceIdList={props.setInvoiceIdList}
                                TRIdList={props.TRIdList}
                                setTRIdList={props.setTRIdList}
                                RVIdList={props.RVIdList}
                                setRVIdList={props.setRVIdList}
                                dueDateLast={props.dueDateLast}
                                setDueDateLast={props.setDueDateLast}
                            />
                        </div>

                        <div style={{ marginTop: '24px' }}>
                            <Grid
                                container
                                spacing={'32px'}
                            >
                                <Grid
                                    item
                                    xl={7}
                                    lg={7}
                                    sm={7}
                                    xs={16}
                                >
                                    <TabsInvoice
                                        tabValue={tabValue}
                                        setTabValue={setTabValue}
                                        InvoiceList={props.InvoiceList}
                                        setInvoiceList={props.setInvoiceList}
                                        TRList={props.TRList}
                                        setTRList={props.setTRList}
                                        TRInUSD={props.TRInUSD}
                                        TRInIDR={props.TRInIDR}
                                        TotalTRInIDR={props.TotalTRInIDR}
                                        RVList={props.RVList}
                                        setRVList={props.setRVList}
                                        Printing={props.Printing}
                                        RVInUSD={props.RVInUSD}
                                        RVInIDR={props.RVInIDR}
                                        TotalRVInIDR={props.TotalRVInIDR}
                                        checkSelectedInvoice={props.checkSelectedInvoice}
                                        calculateTotalRV={props.calculateTotalRV}
                                        calculateTotalTR={props.calculateTotalTR}
                                        calculateTotalDifferent={props.calculateTotalDifferent}
                                        TRIdList={props.TRIdList}
                                        setTRIdList={props.setTRIdList}
                                        RVIdList={props.RVIdList}
                                        setRVIdList={props.setRVIdList}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xl={5}
                                    lg={5}
                                    sm={5}
                                    xs={16}
                                >
                                    <Grid className='text-start'>
                                        <Typography
                                            sx={{
                                                color: 'var(--Blue-Primary-1, #083A50)',
                                                fontFamily: 'Nunito',
                                                fontSize: '16px',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                lineHeight: 'normal',
                                            }}
                                        >
                                            Total Transaction
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        container
                                        spacing={'16px'}
                                    >
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            sm={6}
                                            xs={6}
                                        >
                                            <Grid className='text-end'>
                                                <Typography
                                                    sx={{
                                                        color: 'var(--Primary-One, #083A50)',
                                                        textAlign: 'left',
                                                        fontFamily: 'Nunito',
                                                        fontSize: '14px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '600',
                                                        lineHeight: '19.6px',
                                                    }}
                                                >
                                                    Temporary Receipt
                                                </Typography>
                                            </Grid>

                                            <Input
                                                label='USD'
                                                value={props.TRInUSD}
                                                textAlign='right'
                                                disabled
                                                fullWidth
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                    }
                                                }}
                                            />

                                            <Input
                                                label='IDR'
                                                value={props.TRInIDR}
                                                textAlign='right'
                                                disabled
                                                fullWidth
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                        marginTop: '16px !important'
                                                    }
                                                }}
                                            />

                                            <Input
                                                label='In IDR'
                                                value={props.TotalTRInIDR}
                                                textAlign='right'
                                                disabled
                                                fullWidth
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                        marginTop: '16px !important'
                                                    }
                                                }}
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            sm={6}
                                            xs={6}
                                        >
                                            <Grid className='text-end'>
                                                <Typography
                                                    sx={{
                                                        color: 'var(--Primary-One, #083A50)',
                                                        textAlign: 'left',
                                                        fontFamily: 'Nunito',
                                                        fontSize: '14px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '600',
                                                        lineHeight: '19.6px',
                                                    }}
                                                >
                                                    Receipt Voucher
                                                </Typography>
                                            </Grid>

                                            <Input
                                                label='USD'
                                                value={props.RVInUSD}
                                                textAlign='right'
                                                disabled
                                                fullWidth
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                    }
                                                }}
                                            />

                                            <Input
                                                label='IDR'
                                                value={props.RVInIDR}
                                                textAlign='right'
                                                disabled
                                                fullWidth
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                        marginTop: '16px !important'
                                                    }
                                                }}
                                            />

                                            <Input
                                                label='In IDR'
                                                value={props.TotalRVInIDR}
                                                textAlign='right'
                                                disabled
                                                fullWidth
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                        marginTop: '16px !important'
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Box>
                </Box>
            </form>
            
            {
                isModal && (
                    <SelectDataContact
                        resetDataList={props.resetDataList}
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}
                        tipe={props.PaymentByCode}
                        getData={getDataContact}
                        isLoading={isLoadingC}
                        totalRows={totalRowsC}
                        setTotalRows={setTotalRowsC}
                        SelectedData={SelectedDataC}
                        setSelectedData={setSelectedDataC}
                        data={dataC}
                        columnData={columnDataC}
                        dataMap={dataMapC}
                        setCustomerId={props.setCustomerId}
                        setCustomerName={props.setCustomerName}
                        setIsNPWP={props.setIsNPWP}
                    />
                )
            }
        </div>
    )
}

export default OfficialReceiptDetail;