import React from 'react';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import { Grid } from '@mui/material';

const ButtonCreateEditInvoice = ({
    isViewOnly,
    check,
    Save,
    isJobClose,
    DataRegUserAccess,
    isEdit,
    AddNew,
    Print,
    RePrint,
    fakturType,
    ChangeInvHeader
}) => {

    const renderButtonByAccessCode = (accessCode) => {
        switch (accessCode) {
            case 'CRE':
                return isEdit ? ButtonComponent.AddButton(AddNew, 'New', false, false, true) : null;
            case 'PRI':
                return isEdit && fakturType === undefined ? ButtonComponent.PrintButton(Print, '', true) : null;
            case 'RPR':
                return isEdit && fakturType === undefined ? ButtonComponent.ReprintButton(RePrint, 'Approval', true) : null;
            default:
                return null;
        }
    };

    return (
        <>
            <div className="row" style={{ marginTop: '16px' }}>
                <div className="col-12">
                    <Grid
                        container
                        item
                        justifyContent="right"
                        direction="row"
                        style={{ maxWidth: "100vw", gap: '12px' }}
                    >
                        {!isViewOnly && (
                            <>
                                {check !== 0 && ButtonComponent.SaveButton(Save, isJobClose, false, true)}

                                {DataRegUserAccess.map((data) => (
                                    <React.Fragment key={data.accessId}>
                                        {renderButtonByAccessCode(data.accessCode)}
                                    </React.Fragment>
                                ))}

                                {isEdit && ButtonComponent.ChangeButton(ChangeInvHeader, 'Inv. Header', true)}
                            </>
                        )}
                    </Grid>
                </div>
            </div>
        </>
    );
};

export default ButtonCreateEditInvoice;