import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';
import ModalBank from '../Modals/ModalBank';
import { defaultDate, getDefaultDateFormat, nullDate, setDateFormat } from '../../../../../utils/date';
import { convertToNominal } from '../../../../../utils/masks.jsx';
import { showToast } from '../../../../../components/ui/index.jsx';

const TabBank = (props) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '8px 16px !important',
        },
    }));

    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            // backgroundColor: '#FFFFFF',
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            padding: '0px 16px !important',
            height: '35px !important'
        },
    }));

    const [isModal, setIsModal] = useState(false);
    const [titleModal, setTitleModal] = useState('');
    const [selectedDetail, setSelectedDetail] = useState({});
    const [detailEdit, setDetailEdit] = useState(false);
    const [detailSequence, setDetailSequence] = useState(0);
    const selectedStyle = { bgcolor: '#CCCCCC', "&.MuiTableRow-hover:hover": { backgroundColor: "#CCCCCC" } };
    const deletedDetailStyle = { bgcolor: (theme) => theme.palette.text.disabled };

    const [AccountCode, setAccountCode] = useState('');
    const [AccountName, setAccountName] = useState('');
    const [CheckBG, setCheckBG] = useState('');
    const [Remarks, setRemarks] = useState('');
    const [Currency, setCurrency] = useState(1);
    const [Amount, setAmount] = useState(0);
    const [DueDate, setDueDate] = useState('');
    const [isDueDate, setIsDueDate] = useState(false);
    const [StatusDueDate, setStatusDueDate] = useState(false);

    useEffect(() => {
        setSelectedDetail({});
    }, [props.BankData]);

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        }
        else {
            setIsModal(false);
        }
    };

    function getDateNow() {
        setDateFormat(new Date(), setDueDate);
    };

    const ShowAddBank = () => {
        if (props.selectedShipmentOrder.sequence !== undefined) {
            if (props.checkMethod(props.ShipmentOrderList) !== 1) {
                setTitleModal('Add Payment Method Bank');
                setDetailEdit(false);
                setRemarks('Shipment No. ' + props.selectedShipmentOrder.shipmentNo);
                if (props.BankData.length > 0) {
                    let data = props.BankData.reduce((prev, current) => (prev && prev.sequence > current.sequence) ? prev : current);
                    setDetailSequence(data.sequence);
                }
                else {
                    setDetailSequence(0);
                }
                handleClick();
            }
            else {
                showToast({ type: 'error', message: 'Please Input Payment Method on Cash Only!' });
            }
        }
        else {
            showToast({ type: 'error', message: 'Please Select Shipment First!' });
        }
    };

    const ShowEditBank = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (selectedDetail.rowStatus !== 'DEL') {
                setTitleModal('Edit Payment Method Bank');
                setDetailEdit(true);

                setAccountCode(selectedDetail.coaCode);
                setAccountName(selectedDetail.coaName);
                setCheckBG(selectedDetail.checkBG);
                setRemarks(selectedDetail.remarks);
                setCurrency(parseInt(selectedDetail.amountCrr));
                setAmount(parseInt(selectedDetail.amount));
                let tglDue = getDefaultDateFormat(selectedDetail.dueDate);
                if (tglDue !== nullDate && tglDue !== getDefaultDateFormat(defaultDate)) {
                    setDueDate(tglDue);
                    setIsDueDate(true);
                }
                setStatusDueDate(selectedDetail.statusDueDate);

                handleClick();
            }
            else {
                showToast({ type: 'error', message: 'Data Has Been Deleted!' });
            }
        }
    }

    const DeleteBank = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            let tempSequence = selectedDetail.sequence;

            const deleteFunction = (data) => {
                return data.sequence !== tempSequence;
            }

            const result = props.BankData.filter(deleteFunction);

            if (result.length > 0) {
                tempSequence = 0;

                result.forEach((el) => {
                    tempSequence += 1;
                    el.sequence = tempSequence;
                })

                setDetailSequence(tempSequence);
            }
            else {
                setDetailSequence(0);
            }

            props.setBankData(result);

            const newArray = props.ShipmentOrderList;
            newArray.forEach(el => {
                let tempCountIDR = 0;
                let tempCountUSD = 0;

                if (el.shipmentOrderId === props.selectedShipmentOrder.shipmentOrderId) {
                    el.temporaryPaymentJobBanks = result;
                }

                el.temporaryPaymentJobBanks.forEach(element => {
                    if (element.amountCrr === 0) {
                        tempCountUSD = parseFloat(tempCountUSD) + parseFloat(element.amount);
                    }
                    else if (element.amountCrr === 1) {
                        tempCountIDR = parseFloat(tempCountIDR) + parseFloat(element.amount);
                    }
                });

                el.bankUSD = tempCountUSD;
                el.bankIDR = tempCountIDR;
            });
            props.setShipmentOrderList(newArray);

            setSelectedDetail({});
            props.calculateTotalBank(newArray);
        }
    }

    const handleDoubleClick = () => {
        ShowEditBank();
    }

    return (
        <>
            <div>
                <TableContainer sx={{ maxHeight: 235, background: '#FFF', borderRadius: '12px' }}>
                    <Table sx={{ minWidth: 650 }} stickyHeader>
                        <TableHead
                            sx={{
                                '& th:first-of-type': {
                                    borderRadius: '12px 0 0'
                                },
                                '& th:last-child': {
                                    borderRadius: '0 12px 0 0'
                                }
                            }}
                        >
                            <TableRow>
                                <StyledTableCell sx={{ textAlign: 'center' }}>Code</StyledTableCell>
                                <StyledTableCell>BG No</StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'right' }}>Amount</StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'center' }}>Due Date</StyledTableCell>
                                <StyledTableCell>Description</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                props.BankData.length > 0 ?
                                    props.BankData.map((el) => {
                                        return (
                                            <TableRow
                                                key={el.sequence}
                                                onClick={() => setSelectedDetail(el)}
                                                onDoubleClick={() => handleDoubleClick()}
                                                sx={selectedDetail.sequence === el.sequence ? selectedStyle : el.rowStatus === 'DEL' ? deletedDetailStyle : {}}
                                                hover={true}
                                            >
                                                <StyledTableBodyCell style={{ textAlign: 'center' }}>
                                                    <Tooltip title={el.coaName}>
                                                        <span>{el.coaCode}</span>
                                                    </Tooltip>
                                                </StyledTableBodyCell>
                                                <StyledTableBodyCell>{el.checkBG}</StyledTableBodyCell>
                                                <StyledTableBodyCell sx={{ textAlign: 'right' }}>{convertToNominal(el.amount)}</StyledTableBodyCell>
                                                <StyledTableBodyCell sx={{ textAlign: 'center' }}>{getDefaultDateFormat(el.dueDate) !== getDefaultDateFormat(defaultDate) && getDefaultDateFormat(el.dueDate) !== nullDate ? new Date(el.dueDate).toLocaleDateString("id", { day: '2-digit', month: '2-digit', year: 'numeric' }).split('T')[0] : '-'}</StyledTableBodyCell>
                                                <StyledTableBodyCell>{el.remarks}</StyledTableBodyCell>
                                            </TableRow>
                                        )
                                    })
                                    :
                                    <TableRow sx={{ backgroundColor: '#FFFFFF' }}>
                                        <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                            <Typography
                                                sx={{
                                                    color: 'var(--Primary-One, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '12px',
                                                    fontstyle: 'normal',
                                                    fontWeight: '500',
                                                    lineHeight: 'normal'
                                                }}
                                            >
                                                Data Empty
                                            </Typography>
                                        </StyledTableBodyCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>

                <div style={{ marginTop: '16px' }}>
                    {CustomizeButtonComponent.AddEditDeleteTabsButton(ShowAddBank, ShowEditBank, DeleteBank, props.isApprovedAcc)}
                </div>
            </div>
            
            {
                isModal && (
                    <ModalBank
                        calculateTotalBank={props.calculateTotalBank}
                        temporaryReceiptId={props.temporaryReceiptId}
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        selectedDetail={selectedDetail}
                        setSelectedDetail={setSelectedDetail}
                        detailEdit={detailEdit}
                        setDetailEdit={setDetailEdit}
                        detailSequence={detailSequence}
                        setDetailSequence={setDetailSequence}
                        getDateNow={getDateNow}
                        isApprovedAcc={props.isApprovedAcc}
                        selectedShipmentOrder={props.selectedShipmentOrder}
                        Rate={props.Rate}
                        ShipmentOrderList={props.ShipmentOrderList}
                        setShipmentOrderList={props.setShipmentOrderList}
                        BankData={props.BankData}
                        setBankData={props.setBankData}
                        TotalBankUSD={props.TotalBankUSD}
                        setTotalBankUSD={props.setTotalBankUSD}
                        TotalBankIDR={props.TotalBankIDR}
                        setTotalBankIDR={props.setTotalBankIDR}
                        AccountCode={AccountCode}
                        setAccountCode={setAccountCode}
                        AccountName={AccountName}
                        setAccountName={setAccountName}
                        CheckBG={CheckBG}
                        setCheckBG={setCheckBG}
                        Remarks={Remarks}
                        setRemarks={setRemarks}
                        Currency={Currency}
                        setCurrency={setCurrency}
                        Amount={Amount}
                        setAmount={setAmount}
                        DueDate={DueDate}
                        setDueDate={setDueDate}
                        isDueDate={isDueDate}
                        setIsDueDate={setIsDueDate}
                        StatusDueDate={StatusDueDate}
                    />
                )
            }
        </>
    )
}

export default TabBank;