import React from 'react';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import Grid from '@mui/material/Grid';

const ButtonDailyClosingProcess = ({ Process }) => {
    return (
        <div className="row">
            <div className='col-12'>
                <Grid container item spacing={1} direction="row" style={{ 'maxWidth': '100vw', margin: '0px' }}>
                    {ButtonComponent.ProcessButton(Process, false, false)}
                </Grid>
            </div>
        </div>
    );
};

export default ButtonDailyClosingProcess;