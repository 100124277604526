import { IconButton, Stack, Grid, InputAdornment } from '@mui/material';
import React from 'react'
import ApiService, { formatCurrency } from '../../../../services/ApiService';
import ModalShipmentOrder from '../ModalEPL/ModalShipmentOrder';
import { useState } from 'react';
import { Icon, Input, showToast } from '../../../../components/ui';
import DollarInput from '../../../../components/Masks/DollarInput';
import RupiahInput from '../../../../components/Masks/RupiahInput';

const EplHeader = (props) => {
    const paramS = 'shipmentorder/shipmentorder'
    const [isLoadingS, setIsLoadingS] = useState(false);
    const [dataS, setDataS] = useState([]);
    const [dataMapS, setDataMapS] = useState([])
    const [columnDataS, setColumnDataS] = useState([])
    const [SelectedDataS, setSelectedDataS] = useState({});
    const [totalRowsS, setTotalRowsS] = useState(50)

    const getDataS = (pageNumber, pageSize, filters) => {
        setIsLoadingS(true);
        if (props.jobId !== '0') {
            let params = []
            params = [...params,
            {
                'attr': "jobTypeId",
                'value': '' + props.jobId
            },
            {
                'attr': "jobClosed",
                'value': "false"
            },
            {
                'attr': 'eplId',
                'value': '' + 0,
            }
            ]
            ApiService.OperationDynamicPostByPage(paramS, 'SEARCH', pageNumber, pageSize, params, filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataS({})
                        setDataS(response.data.data.shipmentOrder);

                        let temp = response.data.data.shipmentOrder
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMapS(indexed)
                        setColumnDataS(response.data.data.columns.headerColumns)
                        setTotalRowsS(response.data.totalRowCount)

                        setIsLoadingS(false)
                    }
                    setIsLoadingS(false)
                })
                .catch(function (error) {
                    setIsLoadingS(false)
                    console.error('error saat fetch', error)
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' })
                })
        }
    }

    const searchShipment = () => {
        if (props.isModal === false) {
            props.setIsModal(true);
            getDataS(1, 50, []);
        }
        else {
            props.setIsModal(false);
        }
    }

    return (
        <div>
            <section>
                <Grid
                    container
                    spacing={'32px'}
                >
                    <Grid
                        item
                        xl={4}
                        lg={4}
                        sm={4}
                        xs={12}
                    >
                        <Input
                            label="Shipment Order Number"
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={props.shipmentNo}
                            type='text'
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                }
                            }}
                            inputBaseProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {
                                            !props.shipmentOrderId ?
                                                <IconButton
                                                    onClick={searchShipment}
                                                    disabled={props.isReadOnly || props.isEdit}
                                                    hidden={props.isReadOnly || props.isEdit}
                                                >
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                                :
                                                <IconButton hidden>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                        }
                                    </InputAdornment>
                                )
                            }}
                            disabled
                        />

                        <Input
                            label={
                                props.jobId === '2' || props.jobId === '4' || props.jobId === '6' || props.jobId === '8' ?
                                    'ETA'
                                    :
                                    'ETD'
                            }
                            value={
                                props.jobId === '2' || props.jobId === '4' || props.jobId === '6' || props.jobId === '8' ?
                                    props.eta
                                    :
                                    props.etd
                            }
                            type={'date'}
                            disabled
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px'
                                }
                            }}
                        />

                        <div style={{ marginTop: '16px' }}>
                            {props.selectPrincipleBy}
                        </div>
                    </Grid>

                    <Grid
                        item
                        xl={4}
                        lg={4}
                        sm={4}
                        xs={12}
                    >
                        {
                            props.jobId === '2' || props.jobId === '4' || props.jobId === '6' || props.jobId === '8' ?
                                <Input
                                    label={'Consignee'}
                                    value={props.consigneeName}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />
                                :
                                <Input
                                    label={'Shipper'}
                                    value={props.shipperName}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />
                        }

                        <Input
                            label={'Agent'}
                            value={props.agentName}
                            disabled
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px'
                                }
                            }}
                        />

                        <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                            <Input
                                label={'Close EPL'}
                                value={props.closeEPL === true ? 'Closed' : 'Open'}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px'
                                    }
                                }}
                            />

                            {props.selectShipmentStatus}
                        </Stack>
                    </Grid>

                    <Grid
                        item
                        xl={4}
                        lg={4}
                        sm={4}
                        xs={12}
                    >
                        <Input
                            textAlign='right'
                            label={'Exchange Rate'}
                            value={props.rate}
                            disabled
                            formControlProps={{
                                sx: {
                                    width: '100%'
                                }
                            }}
                            inputBaseProps={{
                                inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                            }}
                        />

                        <Input
                            label={'Exchange Rate Date'}
                            value={props.exRateDate}
                            type={'date'}
                            disabled
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px'
                                }
                            }}
                        />

                        <Input
                            label={'Job Entry Date'}
                            value={props.jobEntryDate}
                            type={'date'}
                            disabled
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px'
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </section>

            {
                props.isModal && (
                    <ModalShipmentOrder
                        isLoadingBackDrop={props.isLoadingBackDrop}
                        setIsLoadingBackDrop={props.setIsLoadingBackDrop}
                        getDateExchRate={props.getDateExchRate}
                        setShipmentId={props.setShipmentId}
                        setJobOwnerId={props.setJobOwnerId}
                        setMarketId={props.setMarketId}
                        setMarketName={props.setMarketName}
                        setMarketCompanyId={props.setMarketCompanyId}
                        setShipperId={props.setShipperId}
                        shipperName={props.shipperName}
                        setShipperName={props.setShipperName}
                        setAgentId={props.setAgentId}
                        agentName={props.agentName}
                        setAgentName={props.setAgentName}
                        setShipmentStatus={props.setShipmentStatus}
                        setShipmentNo={props.setShipmentNo}

                        isModal={props.isModal}
                        handleClick={props.handleClick}
                        jobId={props.jobId}
                        setTicketId={props.setTicketId}
                        setEtd={props.setEtd}
                        setEta={props.setEta}

                        setIncShipperList={props.setIncShipperList}
                        setIncConsigneeList={props.setIncConsigneeList}
                        setIncAgentList={props.setIncAgentList}
                        setCostSSLineList={props.setCostSSLineList}
                        setCostIATAList={props.setCostIATAList}
                        setCostEMKLList={props.setCostEMKLList}
                        setCostDiscountList={props.setCostDiscountList}
                        setCostDiscountShipperList={props.setCostDiscountShipperList}
                        setCostDiscountConsigneeList={props.setCostDiscountConsigneeList}
                        setCostAgentList={props.setCostAgentList}
                        setCostDepoList={props.setCostDepoList}
                        setCostTruckingList={props.setCostTruckingList}
                        setJobEntryDate={props.setJobEntryDate}

                        setRequester={props.setRequester}
                        setJobTypeCode={props.setJobTypeCode}
                        setJobNumber={props.setJobNumber}
                        setSubJobNo={props.setSubJobNo}
                        setShipperAddress={props.setShipperAddress}
                        setConsigneeId={props.setConsigneeId}
                        setConsigneeName={props.setConsigneeName}
                        setConsigneeAddress={props.setConsigneeAddress}
                        setAgentAddress={props.setAgentAddress}

                        countSum={props.countSum}
                        setDataDetail={props.setDataDetail}
                        countTotalData={props.countTotalData}

                        volume20={props.volume20}
                        setVolume20={props.setVolume20}
                        isVolume20={props.isVolume20}
                        setIsVolume20={props.setIsVolume20}

                        volume40={props.volume40}
                        setVolume40={props.setVolume40}
                        isVolume40={props.isVolume40}
                        setIsVolume40={props.setIsVolume40}

                        volume45={props.volume45}
                        setVolume45={props.setVolume45}
                        isVolume45={props.isVolume45}
                        setIsVolume45={props.setIsVolume45}

                        volumeCBM={props.volumeCBM}
                        setVolumeCBM={props.setVolumeCBM}
                        isVolumeCBM={props.isVolumeCBM}
                        setIsVolumeCBM={props.setIsVolumeCBM}

                        containerSize={props.containerSize}
                        setContainerSize={props.setContainerSize}
                        isContainer={props.isContainer}
                        setIsContainer={props.setIsContainer}

                        bookingConfirmationId={props.bookingConfirmationId}
                        setBookingConfirmationId={props.setBookingConfirmationId}
                        bookingConfirmationNumber={props.bookingConfirmationNumber}
                        setBookingConfirmationNumber={props.setBookingConfirmationNumber}
                        getDataShipment={props.getDataShipment}

                        getData={getDataS}
                        isLoading={isLoadingS}
                        setIsLoading={setIsLoadingS}
                        data={dataS}
                        setData={setDataS}
                        dataMap={dataMapS}
                        setDataMap={setDataMapS}
                        columnData={columnDataS}
                        setColumnData={setColumnDataS}
                        SelectedData={SelectedDataS}
                        setSelectedData={setSelectedDataS}
                        totalRows={totalRowsS}
                        setTotalRows={setTotalRowsS}
                    />
                )
            }
        </div>
    )
}

export default EplHeader