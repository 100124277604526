import { Grid, Box, Backdrop, Typography, Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import freezeContainer from "../../../Styles/freezeContainer";

import ApiService, { branchId, companyId, countryId, userCode, userId } from '../../../services/ApiService';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import TableX from '../../../components/Tables/TableX';
import ManageRegCoa from './ManageRegCoa';
import ButtonComponent from '../../../components/Buttons/ButtonComponent';
import ModalRegCoa from './ModalRegCoa';
import ModalSearch from './ModalSearch';
import ModalDelete from '../../../components/Modals/ModalDelete';
import HeaderInfo from '../../../components/Components/HeaderInfo';
import CustomToastContainer, { Icon, Paginate, showToast } from '../../../components/ui';

const ListRegCoa = forwardRef((props, ref) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '16px',
            fontstyle: 'normal',
            fontWeight: '700',
            lineHeight: 'normal',
            padding: '16px 16px !important',
        },
    }));

    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            padding: '12px 16px !important',
        },
    }));
    const selectedStyle = { bgcolor: '#CCCCCC', "&.MuiTableRow-hover:hover": { backgroundColor: "#CCCCCC" } };
    const deletedDetailStyle = { bgcolor: (theme) => theme.palette.text.disabled };

    const btnPageRef = useRef(null)
    const btnTableRef = useRef(null)

    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const [titleModal, setTitleModal] = useState('');
    const title = 'Reg. Chart of Account'
    const [isModal, setIsModal] = useState(false);
    const [isModalChild, setIsModalChild] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingChild, setIsLoadingChild] = useState(false);

    const history = useNavigate()
    const param = "regChartOfAccount/regChartOfAccount";

    const [dataMap, setDataMap] = useState([])
    const [columnData, setColumnData] = useState([])
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50)

    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [filter, setFilter] = useState([])
    const [filterBy, setFilterBy] = useState([])

    const [dataChild, setDataChild] = useState([]);
    const [selectedDetail, setSelectedDetail] = useState({})
    const [isSave, setIsSave] = useState(true)

    const [accountCode, setAccountCode] = useState('')
    const [accountTitle, setAccountTitle] = useState('')
    const [accountGroup, setAccountGroup] = useState('')
    const [accountType, setAccountType] = useState('')
    const [parentId, setParentId] = useState('')

    const [titleModalChild, setTitleModalChild] = useState('');
    const [isRef, setIsRef] = useState(true)
    const [showSearch, setShowSearch] = useState(false)
    const [titleModalSearch, setTitleModalSearch] = useState('')
    const [refId, setRefId] = useState(0)
    const [isEdit, setIsEdit] = useState(false)
    const [detailSequence, setDetailSequence] = useState(0)
    const [accountNumber, setAccountNumber] = useState('')
    const [subAccountTitle, setSubAccountTitle] = useState('')
    const paramSubledger = 'regChartOfAccountSubledger/regChartOfAccountSubledger'
    const [stateX, setStateX] = useState({});

    const paramRegUserAccess = "regUserAccess/regUserAccess";
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);
    const [sortArr, setSortArr] = useState([])
    const [goTo, setGoTo] = useState(1);

    const [isModalDelete, setIsModalDelete] = useState(false);
    const [titleModalDelete, setTitleModalDelete] = useState("");

    let countRefresh = 0;

    const BreadcrumbsItems = [
        { label: 'Infoss', link: '/Infoss', style: '' },
        { label: 'Reg. Chart of Account', link: '/Infoss/' + title.replace(/ /g, ''), style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = title + ' - ' + JSON.parse(localStorage.getItem('branchName'))
        if (countRefresh === 0) {
            ApiService.refreshToken();
            countRefresh++;
            getDataAccess();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = (pageNumber, pageSize, filters, orderBy) => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        } else {
            setIsLoadingBackDrop(true);
        }
        const params = []
        ApiService.DynamicPostByPage(param, 'PAGE', pageNumber, pageSize, params, filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})

                    let temp = response.data.data.chartOfAccount
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    setColumnData(response.data.data.columns)
                    setTotalRows(response.data.totalRowCount)

                    setIsLoading(false)
                    setIsLoadingBackDrop(false)
                }
                else {
                    setIsLoadingBackDrop(false)
                }
                setIsLoading(false)
            })
            .catch(function (error) {
                setIsLoading(false)
                setIsLoadingBackDrop(false)
                console.error('error saat fetch', error)
                history('/Infoss');
            })
    }

    const getDetail = (el, pageNumber, pageSize, filters) => {
        setIsLoadingChild(true);
        const params = [
            {
                "attr": "regCOAId",
                "value": '' + el.id
            }
        ]
        ApiService.DynamicPostByPage(paramSubledger, 'PAGE', pageNumber, pageSize, params, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDetail({})
                    setDataChild(response.data.data.chartOfAccountSubledger);

                    setIsLoadingChild(false)
                }
                else {
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + response.data.message })
                }
                setIsLoadingChild(false)
            })
            .catch(function (error) {
                setIsLoadingChild(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error })
            })
    }

    const handleGetData = (el) => {
        if (!isSave) {
            showToast({ type: 'error', message: 'Data Not Saved Yet' })
            setSelectedData({})
        }
        else {
            let check = 0;
            for (let i = 0; i < DataRegUserAccess.length; i++) {
                if (DataRegUserAccess[i].accessCode === 'REI') {
                    check = 1;
                }
            }

            if (check === 0) {
                showToast({ type: 'error', message: 'You Don\'t Have Access to Read This Data' })
                setAccountCode('')
                setAccountTitle('')
                setAccountGroup('')
                setAccountType('')
                setParentId('')
            }
            else {
                if (el.id !== undefined) {
                    getDetail(el, 1, 1250, [])
                    setAccountCode(el.accountCode)
                    setAccountTitle(el.accountTitle)
                    setAccountGroup(el.accountGroup)
                    setAccountType(el.accountType)
                    setParentId(el.id)
                }
                else {
                    setAccountCode('')
                    setAccountTitle('')
                    setAccountGroup('')
                    setAccountType('')
                    setParentId('')
                }
            }
        }
    }

    const toggleManage = () => {
        handleClick();
        setTitleModal('Manage');
    }

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        }
        else {
            setIsModal(false);
        }
    };

    const handleClickChild = () => {
        if (isModalChild === false) {
            setIsModalChild(true);
        }
        else {
            setIsModalChild(false);
        }
    };

    function CellDoubleClick(row) {

    }

    function CellDoubleClickChild(row) {
        ShowEdit()
    }

    const handleReset = () => {
        setGoTo(1);
        setSortArr([]);
        setFilter([])
        setFilterBy([])
        getData(1, 50)
        setStateX({})
    }

    const ShowAdd = () => {
        if (accountCode !== '') {
            handleClickChild()
            setTitleModalChild('Create Sub Ledger')
            cekIsRef()
            setIsEdit(false)
            if (dataChild.length > 0) {
                let data = dataChild.reduce((prev, current) => (prev && prev.sequence > current.sequence) ? prev : current);
                setDetailSequence(data.sequence);
            }
            else {
                setDetailSequence(0);
            }
            setAccountNumber('')
            setSubAccountTitle('')
            setRefId(0)
        }
        else {
            showToast({ type: 'error', message: 'Please Select Data' })
        }
    }

    const ShowEdit = () => {
        if (accountCode !== '') {
            if (selectedDetail.id !== undefined) {
                handleClickChild()
                setTitleModalChild('Edit Subledger')
                cekIsRef()
                setIsEdit(true)
                if (dataChild.length > 0) {
                    let data = dataChild.reduce((prev, current) => (prev && prev.sequence > current.sequence) ? prev : current);
                    setDetailSequence(data.sequence);
                }
                else {
                    setDetailSequence(0);
                }
                setAccountGroup(selectedDetail.accountGroup)
                setAccountType(selectedDetail.accountType)
                setAccountNumber(selectedDetail.accountNumber)
                setSubAccountTitle(selectedDetail.accountTitle)
            }
            else {
                showToast({ type: 'error', message: 'Please Select Data' })
            }
        }
        else {
            showToast({ type: 'error', message: 'Please Select Data' })
        }
    }

    const cekIsRef = () => {
        if (accountType === 'BANK') {
            setIsRef(false)
        }
        else {
            setIsRef(true)
        }
    }

    const getDataAccess = () => {
        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'MRCOA')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                {
                    attr: "permissionId",
                    value: "" + pId
                },
                {
                    attr: "userId",
                    value: "" + JSON.parse(localStorage.getItem("userId"))
                },
                {
                    attr: "roleId",
                    value: "" + JSON.parse(localStorage.getItem("roleId"))
                }
            ];
            ApiService.IdentityPostByPage(paramRegUserAccess, "PAGE", 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);

                        getData(1, 50);
                    }
                })
                .catch(function (error) {
                    console.error("error saat fetch", error);
                    history('/Infoss');
                });
        }
        else {
            history('/Infoss');
        }
    };

    const handleClickDelete = () => {
        if (isModalDelete === false) {
            setIsModalDelete(true);
        }
        else {
            setIsModalDelete(false);
        }
    };

    const DeleteData = () => {
        if (selectedDetail.sequence === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' })
        }
        else {
            if (selectedDetail.approved) {
                showToast({ type: 'error', message: 'Can not delete data, already approved!' })
            }
            else {
                setTitleModalDelete(title);
                handleClickDelete();
            }
        }
    };

    const Delete = () => {
        if (selectedDetail.sequence !== undefined) {
            let delRow = selectedDetail.sequence
            const tempData = dataChild.filter(dt => dt.sequence !== delRow)
            let tempSequence = 0
            tempData.forEach(row => {
                tempSequence++
                row.sequence = tempSequence
            })

            if (tempData.length > 0) {
                setDataChild(tempData)
            }
            else {
                let tempArray = [];
                let obj = {
                    "rowStatus": "ACT",
                    "countryId": countryId,
                    "companyId": companyId,
                    "branchId": branchId,
                    "userId": userId,
                    "regCOAId": '' + parentId,
                    "id": 0,
                    "sequence": 0,
                    "accountNumber": '',
                    "accountTitle": '',
                    "accountGroup": '',
                    "accountType": '',
                    "refId": 0,
                    "userCode": userCode
                }
                tempArray = [...tempArray, obj];

                setDataChild(tempArray);
            }
            setIsSave(false)
            handleClickDelete();
            showToast({ type: 'success', message: 'Delete Data Subledger Success' })
        }
        else {
            showToast({ type: 'error', message: 'Please Select Data!' })
        }
    }

    const Save = () => {
        const data = { "chartOfAccountSubledger": dataChild }

        ApiService.createData(paramSubledger, data)
            .then((res) => {
                if (res.data.code === 200) {
                    showToast({ type: 'success', message: 'Success Save Data Subledger' })
                    setIsSave(true)
                    setSelectedData({})
                }
            })
            .catch(function (error) {
                setIsLoadingChild(false)
                console.error('error saat save', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error })
            })
    }

    const handleSearch = () => {
        if (showSearch === false) {
            setShowSearch(true);
        }
        else {
            setShowSearch(false);
        }
    }

    const getContainerHeight = () => {
        const hTitle = 64
        const hHeader = 116
        const hPagination = 0
        const hPadding = 10 * 2
        const hBtnPage = btnPageRef?.current?.clientHeight || 28
        const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

        const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable + hPagination

        return `${totalHeight}px`
    }

    const ButtonComponents = () => {
        return (
            <div className="row">
                <div className='col-12'>
                    <Grid container item spacing={1} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
                        <Button
                            size='small'
                            variant="outlined"
                            className='d-flex align-items-center'
                            sx={{
                                padding: '6px 8px',
                                gap: '4px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '24px',
                                borderRadius: '8px',
                                '&:hover': {
                                    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                }
                            }}
                            onClick={toggleManage}
                        >
                            <Icon
                                svgIconProps={{
                                    sx: {
                                        height: '12px',
                                        width: '12px',
                                        transform: 'rotateY(180deg)'
                                    }
                                }}
                                iconName={'config'}
                            />
                            <Typography
                                sx={{
                                    fontFamily: 'Nunito',
                                    marginTop: '0.5px !important',
                                    fontSize: '12px',
                                    fontWeight: '700',
                                    lineHeight: '20px',
                                    letterSpacing: '0em',
                                    textAlign: 'center',
                                    color: '#0087C2',
                                }}
                            >
                                Manage Chart of Account
                            </Typography>
                        </Button>
                    </Grid>
                </div>
            </div>
        )
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        getData(value, rowsCount, filter);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        getData(1, value, filter);
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: '24px 32px', ...freezeContainer }}>
            <Grid container spacing={0} direction="column">
                <CustomToastContainer />

                <HeaderInfo
                    btnPageRef={btnPageRef}
                    title={'Reg. Chart of Account'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                    <Grid container spacing={'32px'} direction="row">
                        <Grid item xs={7}>
                            <div ref={btnTableRef}>
                                {
                                    ButtonComponent.RefreshButton(
                                        sortArr,
                                        rowsCount,
                                        columnData,
                                        setGoTo,
                                        handleReset,
                                        setFilterBy,
                                        filterBy,
                                        setFilter,
                                        setNumPage,
                                        setRowsCount,
                                        getData,
                                        stateX,
                                        setStateX
                                    )
                                }
                            </div>

                            <div
                                className="rounded-10 table-responsive"
                                style={{
                                    maxHeight: `calc(100vh - ${getContainerHeight()})`,
                                    marginTop: '16px',
                                    padding: '2px 16px',
                                    borderRadius: '12px',
                                    background: '#F2F2F2'
                                }}
                            >
                                {
                                    isLoading ?
                                        <LoadingSpinner />
                                        :
                                        <>
                                            <div className='mt-1'>
                                                <TableX
                                                    uniqueKey={'id'}
                                                    rowsCount={rowsCount}
                                                    setNumPage={setNumPage}
                                                    setRowsCount={setRowsCount}
                                                    getData={getData}
                                                    columnData={columnData}
                                                    dataMap={dataMap}
                                                    SelectedData={SelectedData}
                                                    setSelectedData={setSelectedData}
                                                    setFilter={setFilter}
                                                    setFilterBy={setFilterBy}
                                                    goTo={goTo}
                                                    CellDoubleClick={CellDoubleClick}
                                                    handleGetData={handleGetData}
                                                    isModal={isModalChild}
                                                    stateX={stateX}
                                                    setStateX={setStateX}
                                                    sortArr={sortArr}
                                                    setSortArr={setSortArr}
                                                    setGoTo={setGoTo}
                                                />
                                            </div>
                                        </>
                                }
                            </div>

                            <div style={{ marginTop: '16px' }}>
                                <Paginate
                                    page={numPage}
                                    size={'small'}
                                    limit={rowsCount}
                                    totalData={totalRows}
                                    onChange={onChangePaging}
                                    onChangeLimit={onChangeLimit}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={5}>
                            <div
                                className="rounded-10 table-responsive"
                                style={{
                                    maxHeight: `calc(100vh - ${getContainerHeight()})`,
                                    marginTop: '16px',
                                    padding: '16px 16px',
                                    borderRadius: '12px',
                                    background: '#F2F2F2'
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: 'var(--Blue-Primary-1, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '20px',
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        lineHeight: 'normal',
                                        marginTop: '8px'
                                    }}
                                >
                                    {accountCode}-{accountTitle}
                                </Typography>

                                <div className="row">
                                    <div className='col-12'>
                                        <Grid container item spacing={1} direction="row" style={{ 'maxWidth': '100vw', marginTop: '12px', marginLeft: '0px', columnGap: '8px', rowGap: '10px' }}>
                                            {
                                                DataRegUserAccess.map((data) => {
                                                    return (
                                                        <React.Fragment key={data.accessId}>
                                                            {
                                                                data.accessCode === 'CRE' ?
                                                                    <>
                                                                        {ButtonComponent.SaveButton(Save, isSave)}
                                                                        {ButtonComponent.AddButton(ShowAdd)}
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }

                                                            {
                                                                data.accessCode === 'UPD' ?
                                                                    <>
                                                                        {ButtonComponent.EditButton(ShowEdit)}
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }

                                                            {
                                                                data.accessCode === 'DEL' ?
                                                                    <>
                                                                        {ButtonComponent.DeleteButton(DeleteData)}
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </div>
                                </div>

                                <div style={{ marginTop: '16px' }}>
                                    {
                                        isLoadingChild ?
                                            <LoadingSpinner />
                                            :
                                            <TableContainer sx={{ maxHeight: 235, borderRadius: '12px', background: '#FFF' }}>
                                                <Table sx={{ minWidth: 650 }} stickyHeader>
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell>No</StyledTableCell>
                                                            <StyledTableCell>Account Number</StyledTableCell>
                                                            <StyledTableCell>Account Title</StyledTableCell>
                                                            <StyledTableCell>Account Group</StyledTableCell>
                                                            <StyledTableCell>Account Type</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            dataChild.length > 0 && dataChild[0].sequence !== 0 ?
                                                                dataChild.map((el) => {
                                                                    return (
                                                                        <TableRow
                                                                            key={el.sequence}
                                                                            onClick={() => setSelectedDetail(el)}
                                                                            onDoubleClick={() => CellDoubleClickChild(el)}
                                                                            sx={selectedDetail.sequence === el.sequence ? selectedStyle : el.rowStatus === 'DEL' ? deletedDetailStyle : {}}
                                                                        >
                                                                            <StyledTableBodyCell>{el.sequence}</StyledTableBodyCell>
                                                                            <StyledTableBodyCell>{el.accountNumber}</StyledTableBodyCell>
                                                                            <StyledTableBodyCell>{el.accountTitle}</StyledTableBodyCell>
                                                                            <StyledTableBodyCell>{el.accountGroup}</StyledTableBodyCell>
                                                                            <StyledTableBodyCell>{el.accountType}</StyledTableBodyCell>
                                                                        </TableRow>
                                                                    )
                                                                })
                                                                :
                                                                <TableRow>
                                                                    <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                                                        <Typography
                                                                            sx={{
                                                                                color: 'var(--Primary-One, #083A50)',
                                                                                fontFamily: 'Nunito',
                                                                                fontSize: '16px',
                                                                                fontstyle: 'normal',
                                                                                fontWeight: '700',
                                                                                lineHeight: 'normal'
                                                                            }}
                                                                        >
                                                                            Data Empty
                                                                        </Typography>
                                                                    </StyledTableBodyCell>
                                                                </TableRow>
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                    }
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {isModal && (
                <ManageRegCoa
                    isModal={isModal}
                    handleClick={handleClick}
                    titleModal={titleModal}

                    title={title}
                    param={param}
                    getData={getData}
                    setIsLoadingBackDrop={setIsLoadingBackDrop}
                />
            )}

            {isModalChild && (
                <ModalRegCoa
                    isModal={isModalChild}
                    handleClick={handleClickChild}
                    titleModal={titleModalChild}
                    param={param}
                    setIsSave={setIsSave}
                    selectedDetail={selectedDetail}
                    isRef={isRef}
                    setShowSearch={setShowSearch}
                    showSearch={showSearch}
                    setTitleModalSearch={setTitleModalSearch}
                    refId={refId}
                    isEdit={isEdit}
                    setRefId={setRefId}
                    dataChild={dataChild}
                    setDataChild={setDataChild}
                    detailSequence={detailSequence}
                    accountCode={accountCode}
                    accountGroup={accountGroup}
                    accountTitle={accountTitle}
                    accountType={accountType}
                    parentId={parentId}
                    setAccountNumber={setAccountNumber}
                    accountNumber={accountNumber}
                    subAccountTitle={subAccountTitle}
                    setSubAccountTitle={setSubAccountTitle}
                />
            )}

            {showSearch && (
                <ModalSearch
                    isModal={showSearch}
                    handleClick={handleSearch}
                    titleModalSearch={titleModalSearch}

                    setRefId={setRefId}
                />
            )}

            {isModalDelete && (
                <ModalDelete
                    isModal={isModalDelete}
                    titleModal={titleModalDelete}
                    handleClick={handleClickDelete}
                    DeleteData={Delete}
                />
            )}

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: 10000 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </Box>
    )
})

export default ListRegCoa