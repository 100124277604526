import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import { Grid, Box } from '@mui/material';

import ApiService from '../../../../services/ApiService';
import TableZ from '../../../../components/Tables/TableZ';
import freezeContainer from '../../../../Styles/freezeContainer';
import ModalDelete from '../../../../components/Modals/ModalDelete';
import ButtonTemporaryReceipt from './Buttons/ButtonTemporaryReceipt';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import TableCheck from '../../../../components/Tables/TableCheck';
import TableX from '../../../../components/Tables/TableX';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Paginate, showToast } from '../../../../components/ui';
import DocumentInfo from '../../../../components/Components/DocumentInfo';
import ModalUsageData from '../../../../components/Modals/ModalUsageData';
import { setDateFormat, updateDateFormat } from '../../../../utils/date';
import { newWindowConfig } from '../../../../utils/masks.jsx';

const TemporaryReceiptList = forwardRef((props, ref) => {
    const btnPageRef = useRef(null)
    const btnTableRef = useRef(null)

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const history = useNavigate();
    const paramTemporaryReceipt = 'temporaryReceipt/temporaryReceipt';
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const paramGL = 'generalLedger/generalLedger';
    const paramRegClosingPeriod = 'regClosingPeriod/regClosingPeriod';
    const [rowsCount, setRowsCount] = useState(50);
    const [numPage, setNumPage] = useState(1);

    const [dataMap, setDataMap] = useState([]);
    const [jobType, setJobType] = useState('' + JSON.parse(localStorage.getItem("jobTypeId")) === '0' ? '1' : '' + JSON.parse(localStorage.getItem("jobTypeId")));
    const [columnData, setColumnData] = useState([]);
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50);
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [dataJobType, setDataJobType] = useState([]);

    const [isModalDelete, setIsModalDelete] = useState(false);
    const [titleModalDelete, setTitleModalDelete] = useState('');

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');
    const [dataSelected, setDataSelected] = useState({});
    const [Printing, setPrinting] = useState(0);
    const [isDueDate, setIsDueDate] = useState(false);
    const [dueDate, setDueDate] = useState('');

    const [DropDownTitle, setDropDownTitle] = useState('All');

    const [isApprove, setIsApprove] = useState(false);
    const [isUnApprove, setIsUnApprove] = useState(false);
    const [dataCheck, setDataCheck] = useState([]);

    const [accessLog, setAccessLog] = useState(false);

    const [isModalUsage, setIsModalUsage] = useState(false);
    const [DocsLog, setDocsLog] = useState([]);

    const BreadcrumbsItems = [
        { label: 'Infoss', link: '/Infoss', style: '' },
        { label: 'Temporary Receipt', link: '/Infoss/TemporaryReceipt', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    const dropDownList = [
        { label: 'All', icon: 'search-doc' },
        { label: 'Active', icon: 'documents' },
        { label: 'Posted', icon: 'posted' },
        { label: 'Deleted', icon: 'status-deleted' },
    ]

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        localStorage.setItem('isUnApprove', JSON.stringify(false));
        localStorage.setItem('isApprove', JSON.stringify(false));
        document.title = 'Temporary Receipt - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = (pageNumber, pageSize, filters, orderBy, data) => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        }
        else {
            setIsLoadingBackDrop(true);
        }

        let check = 0;
        let params = [];
        let filter = [];

        if (JSON.parse(localStorage.getItem('isApprove')) === true) {
            check = 1;
            params = [...params,
            {
                "attr": "printing",
                "value": "1",
                "mathSign": ">="
            },
            {
                "attr": "isApprovedAcc",
                "value": "false",
            }
            ]
            filter = [
                ...filters,
                { 'field': 'rowStatus', 'data': 'ACT' },
            ];
        }
        else if (JSON.parse(localStorage.getItem('isUnApprove')) === true) {
            check = 1;
            params = [...params,
            {
                "attr": "isApprovedAcc",
                "value": "true",
            }
            ]
            filter = [
                ...filters,
                { 'field': 'rowStatus', 'data': 'ACT' },
            ];
        }
        else {
            check = 0;
        }

        params = [...params,
        {
            "attr": "jobTypeId",
            "value": '' + jobType
        },
        {
            "attr": "IsWithJob",
            "value": "true"
        },
        {
            "attr": "Id",
            "value": "0",
            "isDistinct": true
        },
        {
            "attr": "ShipmentOrderId",
            "value": "0",
            "isDistinct": true
        }
        ]

        if (check === 0) {
            if (data) {
                if (data === 'All') {
                    filter = [...filters]
                }
                else if (data === 'Deleted') {
                    filter = [...filters, { 'field': 'rowStatus', 'data': 'DEL' }];
                }
                else if (data === 'Active') {
                    filter = [
                        ...filters,
                        { 'field': 'rowStatus', 'data': 'ACT' },
                    ];
                    params = [
                        ...params,
                        { 'attr': 'isPosted', 'value': 'false' },
                    ]
                }
                else if (data === 'Posted') {
                    filter = [
                        ...filters,
                        { 'field': 'rowStatus', 'data': 'ACT' },
                    ];
                    params = [
                        ...params,
                        { 'attr': 'isPosted', 'value': 'true' },
                    ]
                }
            }
            else {
                if (DropDownTitle === 'All') {
                    filter = [...filters]
                }
                else if (DropDownTitle === 'Deleted') {
                    filter = [...filters, { 'field': 'rowStatus', 'data': 'DEL' }];
                }
                else if (DropDownTitle === 'Active') {
                    filter = [
                        ...filters,
                        { 'field': 'rowStatus', 'data': 'ACT' },
                    ];
                    params = [
                        ...params,
                        { 'attr': 'isPosted', 'value': 'false' },
                    ]
                }
                else if (DropDownTitle === 'Posted') {
                    filter = [
                        ...filters,
                        { 'field': 'rowStatus', 'data': 'ACT' },
                    ];
                    params = [
                        ...params,
                        { 'attr': 'isPosted', 'value': 'true' },
                    ]
                }
            }
        }

        ApiService.ListFinAccPostByPage(paramTemporaryReceipt, 'PAGE', pageNumber, pageSize, params, filter, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})

                    let temp = response.data.data.temporaryReceipts
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    localStorage.setItem('finAccTemp', JSON.stringify(indexed));
                    setDataMap(indexed)
                    setColumnData(response.data.data.columns.headerColumns)
                    setTotalRows(response.data.totalRowCount)

                    if (isLoadingBackDrop === false) {
                        setIsLoading(false);
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
                    if (isLoadingBackDrop === false) {
                        setIsLoading(false)
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }
                }

                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                }

                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const getDataApproved = () => {
        let data = {};
        ApiService.CustomFinanceCreate(paramTemporaryReceipt + '/Create?check=true', data)
            .then((res) => {
                if (res.data.code === 200) {
                    if (res.data.data !== null) {
                        let data = res.data.data;
                        let array = [];
                        let sequence = 1;

                        if (data != null) {
                            if (data.paymentVouchers?.length > 0) {
                                data.paymentVouchers.forEach(element => {
                                    let object = {
                                        'usedOn': 'Payment Voucher',
                                        'transactionNumber': element.reference,
                                        'id': element.id,
                                        'sequence': sequence,
                                    }

                                    sequence = sequence + 1;
                                    array = [...array, object];
                                });
                            }

                            if (data.receiptVouchers?.length > 0) {
                                data.receiptVouchers.forEach(element => {
                                    let object = {
                                        'usedOn': 'Receipt Voucher',
                                        'transactionNumber': element.reference,
                                        'id': element.id,
                                        'sequence': sequence,
                                    }

                                    sequence = sequence + 1;
                                    array = [...array, object];
                                });
                            }

                            if (data.temporaryPayments?.length > 0) {
                                data.temporaryPayments.forEach(element => {
                                    let object = {
                                        'usedOn': 'Temporary Payment',
                                        'transactionNumber': element.tpNo2,
                                        'id': element.id,
                                        'jobTypeId': element.jobTypeId,
                                        'sequence': sequence,
                                    }

                                    sequence = sequence + 1;
                                    array = [...array, object];
                                });
                            }

                            if (data.temporaryReceipts?.length > 0) {
                                data.temporaryReceipts.forEach(element => {
                                    let object = {
                                        'usedOn': 'Temporary Receipt',
                                        'transactionNumber': element.trNo2,
                                        'id': element.id,
                                        'isDP': element.isDP,
                                        'jobTypeId': element.jobTypeId,
                                        'sequence': sequence,
                                    }

                                    sequence = sequence + 1;
                                    array = [...array, object];
                                });
                            }

                            if (data.officialReceipts?.length > 0) {
                                data.officialReceipts.forEach(element => {
                                    let object = {
                                        'usedOn': 'Official Receipt',
                                        'transactionNumber': element.reference,
                                        'id': element.id,
                                        'sequence': sequence,
                                    }

                                    sequence = sequence + 1;
                                    array = [...array, object];
                                });
                            }

                            setDocsLog(array);
                        }
                    }
                    else {
                        setDocsLog([]);
                    }
                }
            })
            .catch(function (error) {
                if (isLoadingBackDrop === false) {
                    setIsLoading(false)
                }
                else {
                    setIsLoadingBackDrop(false);
                }

                console.error('error saat fetch', error)
                // Alert.ErrorAlert(error.toString(), true)
                showToast({ type: 'error', message: error.toString() });
            });
    }

    const getDataAccess = () => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        }
        else {
            setIsLoadingBackDrop(true);
        }

        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'FTER')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        let data = response.data.data.userAccess;
                        setDataRegUserAccess(response.data.data.userAccess);

                        data.forEach(element => {
                            if (element.accessCode === 'LOG') {
                                setAccessLog(true);
                            }
                        });

                        GetDataJobType();
                    }
                    else if (response.data.code === 500) {
                        showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
                        if (isLoadingBackDrop === false) {
                            setIsLoading(false)
                        }
                        else {
                            setIsLoadingBackDrop(false);
                        }
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Infoss');
                })
        }
        else {
            history('/Infoss');
        }
    }

    const getData2 = (pageNumber, pageSize, filters, jobType) => {
        setIsLoading(true);

        let check = 0;
        let params = [];
        let filter = [];

        if (JSON.parse(localStorage.getItem('isApprove')) === true) {
            check = 1;
            params = [...params,
            {
                "attr": "printing",
                "value": "1",
                "mathSign": ">="
            },
            {
                "attr": "isApprovedAcc",
                "value": "false",
            }
            ]
            filter = [
                ...filters,
                { 'field': 'rowStatus', 'data': 'ACT' },
            ];
        }
        else if (JSON.parse(localStorage.getItem('isUnApprove')) === true) {
            check = 1;
            params = [...params,
            {
                "attr": "isApprovedAcc",
                "value": "true",
            }
            ]
            filter = [
                ...filters,
                { 'field': 'rowStatus', 'data': 'ACT' },
            ];
        }
        else {
            check = 0;
        }

        params = [...params,
        {
            "attr": "jobTypeId",
            "value": '' + jobType
        },
        {
            "attr": "IsWithJob",
            "value": "true"
        },
        {
            "attr": "Id",
            "value": "0",
            "isDistinct": true
        },
        {
            "attr": "ShipmentOrderId",
            "value": "0",
            "isDistinct": true
        }
        ]

        if (check === 0) {
            if (DropDownTitle === 'All') {
                filter = [...filters]
            }
            else if (DropDownTitle === 'Deleted') {
                filter = [...filters, { 'field': 'rowStatus', 'data': 'DEL' }];
            }
            else if (DropDownTitle === 'Active') {
                filter = [
                    ...filters,
                    { 'field': 'rowStatus', 'data': 'ACT' },
                ];
                params = [
                    ...params,
                    { 'attr': 'isPosted', 'value': 'false' },
                ]
            }
            else if (DropDownTitle === 'Posted') {
                filter = [
                    ...filters,
                    { 'field': 'rowStatus', 'data': 'ACT' },
                ];
                params = [
                    ...params,
                    { 'attr': 'isPosted', 'value': 'true' },
                ]
            }
        }

        ApiService.ListFinAccPostByPage(paramTemporaryReceipt, 'PAGE', pageNumber, pageSize, params, filter)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})

                    let temp = response.data.data.temporaryReceipts
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    localStorage.setItem('finAccTemp', JSON.stringify(indexed));
                    setDataMap(indexed)
                    setColumnData(response.data.data.columns.headerColumns)
                    setTotalRows(response.data.totalRowCount)

                    setIsLoading(false)
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
                    setIsLoading(false)
                }

                setIsLoading(false)
            })
            .catch(function (error) {
                setIsLoading(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const GetDataJobType = () => {
        ApiService.PostByPage('regJobType/regJobType', 1, 50)
            .then((response) => {
                if (response.data.code === 200) {
                    setDataJobType(response.data.data.jobType);

                    getDataApproved();
                    getData(1, 50, []);
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
                    if (isLoadingBackDrop === false) {
                        setIsLoading(false)
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error)
            })
    }

    const handleClick = () => {
        if (isModalDelete === false) {
            setIsModalDelete(true);
        }
        else {
            setIsModalDelete(false);
        }
    }

    const handleChange = (e) => {
        localStorage.setItem("jobTypeId", JSON.stringify(e.target.value));
        setJobType(e.target.value);
        emptyState();
        getData2(1, 50, filter, e.target.value);
    };

    const handleReset = () => {
        emptyState();
        getDataApproved();
        getData(1, 50, []);
    };

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
            setDataSelected(SelectedData);
        }
        else {
            setIsModalConfirmation(false);
            setIsDueDate(false);
        }
    }

    function getDateNow() {
        setDateFormat(new Date(), setDueDate);
    };

    function ConfirmationTR() {
        if (titleConfirmation === 'Print') {
            Print();
        }
        else if (titleConfirmation === 'Reprint') {
            RePrint();
        }
        else if (titleConfirmation === 'Approve') {
            Approve();
        }
        else if (titleConfirmation === 'Un Approve') {
            UnApprove();
        }
        setIsModalConfirmation(false);
    }

    const AddTR = () => {
        history('/Infoss/TemporaryReceipt/Create/' + jobType);
    }

    const EditTR = () => {
        if (!SelectedData?.trId) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (SelectedData.isDP === false) {
                window.open('/Infoss/TemporaryReceipt/' + jobType + '/' + SelectedData.trId, newWindowConfig);
            }
            else {
                window.open('/Infoss/DownPayment/' + SelectedData.trId, newWindowConfig);
            }
        }
    }

    const ReloadData = () => {
        showToast({ type: 'success', message: 'Reload Data!' });
        emptyState();
        getDataApproved();
        getData(1, 50, []);
    }

    const DeleteData = () => {
        if (!SelectedData?.trId) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (SelectedData.rowStatus === 'ACT') {
                if (SelectedData.isApprovedAcc) {
                    showToast({ type: 'error', message: 'Data Already Approved!' });
                }
                else {
                    setTitleModalDelete('Temporary Receipt');
                    handleClick();
                }
            }
            else {
                showToast({ type: 'error', message: 'Data Already Deleted!' });
            }
        }
    }

    const DeleteTR = () => {
        ApiService.FinAccDelete(paramTemporaryReceipt, SelectedData.trId).then((res) => {
            if (res.data.code === 200) {
                getDataApproved();
                getData(numPage, rowsCount, filter);
                showToast({ type: 'success', message: 'Delete Data Success!' });
                setIsModalDelete(false);
            }
            else {
                showToast({ type: 'error', message: 'Delete Data Failed!' });
                setIsModalDelete(false);
            }
        });
    }

    const OpenLogData = () => {
        if (!SelectedData?.trId) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (SelectedData.isPosted === true) {
                setIsLoadingBackDrop(true);
                ApiService.RegClosingPeriodPostById(paramRegClosingPeriod, updateDateFormat(SelectedData.printedOn))
                    .then((response) => {
                        let data = response.data.data.closingPeriod;
                        history('/Infoss/TransactionLogs/TemporaryReceipt/' + SelectedData.trId + '/' + data.period + '/' + data.yearPeriod);
                    })
                    .catch(function (error) {
                        setIsLoadingBackDrop(false);
                        // console.error('error saat fetch', error);
                    })
            }
            else {
                showToast({ type: 'error', message: 'Please Approve This Temporary Receipt First!' });
            }
        }
    }

    const PrintData = () => {
        if (!SelectedData?.trId) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (SelectedData.rowStatus === 'ACT') {
                if (SelectedData.rePrintApproved !== true) {
                    showToast({ type: 'error', message: 'Can\'t Print Data, Need Reprint Approval!' });
                }
                else {
                    setTitleConfirmation('Print')
                    setTitleModalConfirmation('Temporary Receipt');
                    handleClickConfirmation();

                    if (SelectedData?.printing === 0) {
                        getDateNow();
                    }
                    else {
                        setDateFormat(SelectedData?.printedOn, setDueDate);
                    }

                    if (SelectedData?.totalCashUSD === 0 && SelectedData?.totalCashIDR === 0) {
                        setIsDueDate(true);
                        if (SelectedData?.printing === 0) {
                            setPrinting(0);
                        }
                        else {
                            setPrinting(SelectedData?.printing);
                        }
                    }
                }
            }
            else {
                showToast({ type: 'error', message: 'Data Already Deleted!' });
            }
        }
    }

    const Print = () => {
        setIsLoadingBackDrop(true);
        let dateDue = dueDate === '' ? updateDateFormat(new Date()) : dueDate;
        ApiService.FinAccBackDatePrint(paramTemporaryReceipt, dataSelected.trId, dateDue).then((res) => {
            let message = '';
            message = res.data.message;
            if (res.data.code === 200) {
                getDataApproved();
                getData(numPage, rowsCount, filter);

                PrintPDF();
                setIsDueDate(false);
            }
            else {
                setIsLoadingBackDrop(false);
                setIsDueDate(false);
                showToast({ type: 'error', message: message });
            }
        });
    };

    const PrintPDF = () => {
        let variable = 'PrintTR?Id=' + dataSelected.trId;
        ApiService.ReportPrint('Transactions/Finance/TemporaryReceiptReport/', variable)
            .then(response => {
                if (response.status === 200) {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' }
                    );

                    const fileURL = URL.createObjectURL(file);

                    setIsLoadingBackDrop(false);
                    showToast({ type: 'success', message: 'Print Temporary Receipt Success!' });
                    window.open(fileURL);
                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'Can\'t Print Temporary Receipt!' });
                }
            })
            .catch(error => {
                setIsLoadingBackDrop(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Print Temporary Receipt!' });
            });
    }

    const CreateGL = (trId) => {
        var data = {
            "generalLedger": {
                "source": "TR",
                "refId": trId,
                "methodPost": "AUTO",
                "procedureName": '[finance].SP_GeneralLedger_SBSendRequestMessage'
            }
        }

        ApiService.CreateFinAcc(paramGL, data).then((res) => {
            let message = '';
            message = res.data.message;
            if (res.data.code !== 200) {
                showToast({ type: 'error', message: message });
            }
        });
    }

    const RePrintData = () => {
        if (!SelectedData?.trId) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (SelectedData.rowStatus === 'ACT') {
                setTitleConfirmation('Reprint')
                setTitleModalConfirmation('Temporary Receipt');
                handleClickConfirmation();
            }
            else {
                showToast({ type: 'error', message: 'Data Already Deleted!' });
            }
        }
    }

    const RePrint = () => {
        ApiService.FinAccUpdateStatusRePrint(paramTemporaryReceipt, dataSelected.trId).then((res) => {
            let message = '';
            message = res.data.message;
            if (res.data.code === 200) {
                getDataApproved();
                getData(numPage, rowsCount, filter);
                showToast({ type: 'success', message: message });
            }
            else {
                showToast({ type: 'error', message: message });
            }
        });
    };

    const AddNewDP = () => {
        history('/Infoss/DownPayment/Create');
    }

    const handleApprove = () => {
        if (isApprove === false) {
            document.title = 'Approve Temporary Receipt - ' + JSON.parse(localStorage.getItem('branchName'));
            localStorage.setItem('isApprove', JSON.stringify(true));
            setIsApprove(true);
            setIsUnApprove(false);
        }
        else {
            document.title = 'Temporary Receipt - ' + JSON.parse(localStorage.getItem('branchName'));
            localStorage.setItem('isApprove', JSON.stringify(false));
            setIsApprove(false);
            setIsUnApprove(false);
        }

        handleReset();
    }

    const ApproveData = () => {
        if (dataCheck.length <= 0) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            setTitleConfirmation('Approve')
            setTitleModalConfirmation('Temporary Receipt');
            handleClickConfirmation();
        }
    }

    const Approve = () => {
        let check = 0;
        setIsLoadingBackDrop(true);
        const uniqueDataCheck = dataCheck.filter((item, index, self) =>
            index === self.findIndex((t) => t.trId === item.trId)
        );

        const sortedUniqueDataCheck = uniqueDataCheck.sort((a, b) => {
            const dateA = new Date(a.printedOn);
            const dateB = new Date(b.printedOn);

            return dateA - dateB;
        });

        sortedUniqueDataCheck.forEach(element => {
            if (check === 0) {
                ApiService.FinAccUpdateStatusApprovedAcc(paramTemporaryReceipt, element.trId)
                    .then((res) => {
                        let message = '';
                        message = res.data.message;
                        if (res.data.code === 200) {
                            if (element.isApprovedAcc === false) {
                                CreateGL(element.trId);
                            }

                            if (check === 0 && sortedUniqueDataCheck[sortedUniqueDataCheck.length - 1].trId === element.trId) {
                                setDataCheck([]);
                                getDataApproved();
                                setIsLoadingBackDrop(false);
                                getData(numPage, rowsCount, filter);
                                showToast({ type: 'success', message: 'Approve Temporary Receipt Success!' });
                            }

                        }
                        else {
                            check = 1;
                            setDataCheck([]);
                            getDataApproved();
                            setIsLoadingBackDrop(false);
                            getData(numPage, rowsCount, filter);
                            showToast({ type: 'error', message: message });
                        }
                    })
                    .catch(error => {
                        check = 1;
                        setDataCheck([]);
                        getDataApproved();
                        setIsLoadingBackDrop(false);
                        getData(numPage, rowsCount, filter);
                        showToast({ type: 'error', message: 'Can\'t Approve Temporary Receipt!, ' + error });
                    });
            }
        });
    }

    const handleUnApprove = () => {
        if (isUnApprove === false) {
            document.title = 'Un Approve Temporary Receipt - ' + JSON.parse(localStorage.getItem('branchName'));
            localStorage.setItem('isUnApprove', JSON.stringify(true));
            setIsUnApprove(true);
            setIsApprove(false);
        }
        else {
            document.title = 'Temporary Receipt - ' + JSON.parse(localStorage.getItem('branchName'));
            localStorage.setItem('isUnApprove', JSON.stringify(false));
            setIsUnApprove(false);
            setIsApprove(false);
        }

        handleReset();
    }

    const UnApproveData = () => {
        if (dataCheck.length <= 0) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            setTitleConfirmation('Un Approve')
            setTitleModalConfirmation('Temporary Receipt');
            handleClickConfirmation();
        }
    }

    const UnApprove = () => {
        let check = 0;
        setIsLoadingBackDrop(true);
        const uniqueDataCheck = dataCheck.filter((item, index, self) =>
            index === self.findIndex((t) => t.trId === item.trId)
        );

        const sortedUniqueDataCheck = uniqueDataCheck.sort((a, b) => {
            const dateA = new Date(a.printedOn);
            const dateB = new Date(b.printedOn);

            return dateA - dateB;
        });

        sortedUniqueDataCheck.forEach(element => {
            if (check === 0) {
                ApiService.FinAccUpdateStatusUnApprovedAcc(paramTemporaryReceipt, element.trId)
                    .then((res) => {
                        let message = '';
                        message = res.data.message;
                        if (res.data.code === 200) {
                            CreateGL(res.data.data.ContraId);

                            if (check === 0 && sortedUniqueDataCheck[sortedUniqueDataCheck.length - 1].trId === element.trId) {
                                setDataCheck([]);
                                getDataApproved();
                                setIsLoadingBackDrop(false);
                                getData(numPage, rowsCount, filter);
                                showToast({ type: 'success', message: 'Un-Approve Temporary Receipt Success!' });
                            }
                        }
                        else {
                            check = 1;
                            setDataCheck([]);
                            getDataApproved();
                            setIsLoadingBackDrop(false);
                            getData(numPage, rowsCount, filter);
                            showToast({ type: 'error', message: message });
                        }
                    })
                    .catch(error => {
                        check = 1;
                        setDataCheck([]);
                        getDataApproved();
                        setIsLoadingBackDrop(false);
                        getData(numPage, rowsCount, filter);
                        showToast({ type: 'error', message: 'Can\'t Un-Approve Temporary Receipt!' });
                    });
            }
        });
    }

    const emptyState = () => {
        setNumPage(1);
        setRowsCount(50);
        setTotalRows(50);
        setFilter([]);
        setFilterBy([]);
        setGoTo(1);
        setSortArr([]);
        setStateX({});
        setDataCheck([]);
    };

    function CellDoubleClick(row) {
        EditTR();
    };

    function HandleStatus(data) {
        setDropDownTitle(data);
        getDataApproved();
        getData(numPage, rowsCount, filter, sortArr, data);
    };

    const handleClickUsage = () => {
        if (isModalUsage === false) {
            setIsModalUsage(true);
        }
        else {
            setIsModalUsage(false);
        }
    }

    const getContainerHeight = () => {
        const hTitle = 64
        const hHeader = 116
        const hDocInfo = DocsLog.length > 0 ? 72 : 0
        const hPadding = 10 * 2
        const hBtnPage = btnPageRef?.current?.clientHeight || 28
        const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

        const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable + hDocInfo

        return `${totalHeight}px`
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        getDataApproved();
        getData(value, rowsCount, filter);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        getDataApproved();
        getData(1, value, filter);
    }

    const ButtonComponents = () => {
        return (
            <ButtonTemporaryReceipt
                AddTR={AddTR}
                EditTR={EditTR}
                ReloadData={ReloadData}
                DataRegUserAccess={DataRegUserAccess}
                DeleteData={DeleteData}
                Print={PrintData}
                RePrint={RePrintData}
                AddNewDP={AddNewDP}
                OpenLogData={OpenLogData}
                Approve={ApproveData}
                UnApprove={UnApproveData}
                DropDownTitle={DropDownTitle}
                HandleStatus={HandleStatus}
                DropDownList={dropDownList}
                isApprove={isApprove}
                handleApprove={handleApprove}
                isUnApprove={isUnApprove}
                handleUnApprove={handleUnApprove}
                isDisabled={DocsLog.length > 0 ? true : false}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: '24px 32px', ...freezeContainer }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo btnPageRef={btnPageRef}
                    title={!isApprove && !isUnApprove ? 'Temporary Receipt' : isApprove ? 'Approve Temporary Receipt' : 'Un Approve Temporary Receipt'}
                    isJobType={true}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                    jobTypeId={jobType}
                    handleSelect={handleChange}
                    dataJobType={dataJobType}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <DocumentInfo isShow={DocsLog.length > 0 ? true : false} showDocuments={handleClickUsage} />

                    <div ref={btnTableRef}>
                        {
                            ButtonComponent.RefreshButton(
                                sortArr,
                                rowsCount,
                                columnData,
                                setGoTo,
                                handleReset,
                                setFilterBy,
                                filterBy,
                                setFilter,
                                setNumPage,
                                setRowsCount,
                                getData,
                                stateX,
                                setStateX
                            )
                        }
                    </div>

                    <div
                        className="rounded-10 table-responsive"
                        style={{
                            maxHeight: `calc(100vh - ${getContainerHeight()})`,
                            marginTop: '16px',
                            padding: '2px 16px',
                            borderRadius: '12px',
                            background: '#F2F2F2'
                        }}
                    >
                        {
                            isLoading ?
                                <LoadingSpinner />
                                :
                                <>
                                    <div className='mt-1'>
                                        {
                                            !isApprove && !isUnApprove ?
                                                <>
                                                    {
                                                        accessLog ?
                                                            <TableZ
                                                                rowsCount={rowsCount}
                                                                source={'TR'}
                                                                isLoadingBackDrop={isLoadingBackDrop}
                                                                setIsLoadingBackDrop={setIsLoadingBackDrop}
                                                                setNumPage={setNumPage}
                                                                setRowsCount={setRowsCount}
                                                                getData={getData}
                                                                columnData={columnData}
                                                                dataMap={dataMap}
                                                                setDataMap={setDataMap}
                                                                SelectedData={SelectedData}
                                                                setSelectedData={setSelectedData}
                                                                setFilter={setFilter}
                                                                setFilterBy={setFilterBy}
                                                                CellDoubleClick={CellDoubleClick}
                                                                localVar={'finAccTemp'}
                                                                stateX={stateX}
                                                                setStateX={setStateX}
                                                                sortArr={sortArr}
                                                                setSortArr={setSortArr}
                                                                goTo={goTo}
                                                                setGoTo={setGoTo}
                                                            />
                                                            :
                                                            <TableX
                                                                uniqueKey={'id'}
                                                                rowsCount={rowsCount}
                                                                setRowsCount={setRowsCount}
                                                                setNumPage={setNumPage}
                                                                getData={getData}
                                                                columnData={columnData}
                                                                dataMap={dataMap}
                                                                SelectedData={SelectedData}
                                                                setSelectedData={setSelectedData}
                                                                setFilter={setFilter}
                                                                setFilterBy={setFilterBy}
                                                                CellDoubleClick={CellDoubleClick}
                                                                stateX={stateX}
                                                                setStateX={setStateX}
                                                                sortArr={sortArr}
                                                                setSortArr={setSortArr}
                                                                goTo={goTo}
                                                                setGoTo={setGoTo}
                                                            />
                                                    }
                                                </>
                                                :
                                                <TableCheck
                                                    uniqueKey={'id'}
                                                    rowsCount={rowsCount}
                                                    setNumPage={setNumPage}
                                                    setRowsCount={setRowsCount}
                                                    getData={getData}
                                                    columnData={columnData}
                                                    dataMap={dataMap}
                                                    SelectedData={SelectedData}
                                                    setSelectedData={setSelectedData}
                                                    setFilter={setFilter}
                                                    setFilterBy={setFilterBy}
                                                    dataCheck={dataCheck}
                                                    setDataCheck={setDataCheck}
                                                    stateX={stateX}
                                                    setStateX={setStateX}
                                                    sortArr={sortArr}
                                                    setSortArr={setSortArr}
                                                    setGoTo={setGoTo}
                                                />
                                        }
                                    </div>
                                </>
                        }
                    </div>

                    <div style={{ marginTop: '16px' }}>
                        <Paginate
                            page={numPage}
                            limit={rowsCount}
                            totalData={totalRows}
                            onChange={onChangePaging}
                            onChangeLimit={onChangeLimit}
                        />
                    </div>
                </Grid>
            </Grid>

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }

            {
                isModalDelete && (
                    <ModalDelete
                        isModal={isModalDelete}
                        titleModal={titleModalDelete}
                        handleClick={handleClick}
                        DeleteData={DeleteTR}
                    />
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationTR}
                        isDueDate={isDueDate}
                        setIsDueDate={setIsDueDate}
                        PrintCount={Printing}
                        dueDate={dueDate}
                        setDueDate={setDueDate}
                    />
                )
            }

            {
                isModalUsage && (
                    <ModalUsageData
                        isModal={isModalUsage}
                        titleModal={'Un-Posted Documents'}
                        isDesc={true}
                        description={'This Documents Has Been Printed But Hasn\'t Been Approved Yet:'}
                        handleClick={handleClickUsage}
                        usageDataList={DocsLog}
                    />
                )
            }
        </Box>
    )
})

export default TemporaryReceiptList