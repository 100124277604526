import { Box, Card, CardContent, Grid } from '@mui/material';
import React, { forwardRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useImperativeHandle } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';

import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ApiService, { branchId, companyId, countryId, userId, userCode, username } from '../../../../services/ApiService';
import ButtonCreateEditInBound from './Buttons/ButtonCreateEditInBound';
import InBoundHeader from './Components/InBoundHeader';
import InBoundDetail from './Components/InBoundDetail';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import ButtonInfo from '../../../../components/Components/ButtonInfo';
import CustomToastContainer, { showToast } from '../../../../components/ui';

const CreateEditInBound = forwardRef((props, ref) => {
    const dataLocal = JSON.parse(localStorage.getItem(username + "-DataInBound"));

    const { Id } = useParams();
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);

    const paramTransactionInbound = "TransactionInBound/TransactionInBound"
    const paramInboundCategory = "InboundCategory/InboundCategory"
    const paramRegUserAccess = 'regUserAccess/regUserAccess';

    const history = useNavigate();

    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);
    const [check, setCheck] = useState(0);

    //Variable
    const [RowStatus, setRowStatus] = useState('ACT');

    const [InBoundDate, setInBoundDate] = useState(new Date().toISOString().split('T')[0]);
    const [InBoundNo, setInBoundNo] = useState('');
    const [squareMeter, setSquareMeter] = useState(0)
    const [inboundCategoryId, setInboundCategoryId] = useState(0);
    const [inboundCategoryName, setInboundCategoryName] = useState('');
    const [productCategoryName, setProductCategoryName] = useState('');
    const [productCategoryId, setProductCategoryId] = useState(0);
    const [siteName, setSiteName] = useState('');
    const [siteId, setSiteId] = useState(0);
    const [warehouseId, setWarehouseId] = useState(0);
    const [warehouseName, setWarehouseName] = useState('');
    const [warehouseLocationId, setWarehouseLocationId] = useState(0);
    const [warehouseLocationName, setWarehouseLocationName] = useState('');
    const [warehouseLocationX, setWarehouseLocationX] = useState('');
    const [warehouseLocationY, setWarehouseLocationY] = useState('');
    const [warehouseLocationZ, setWarehouseLocationZ] = useState('');
    const [shipmentOrderId, setShipmentOrderId] = useState(0);
    const [shipmentOrderNo, setShipmentOrderNo] = useState('');
    const [shipperConsigneeId, setShipperConsigneeId] = useState(0);
    const [shipperConsigneeName, setShipperConsigneeName] = useState('');
    const [approved, setApproved] = useState(false);
    const [listInBoundCategory, setListInBoundCategory] = useState([]);

    const [InboundDetailList, setInBoundDetailList] = useState([]);

    const [isEdit, setIsEdit] = useState(false);
    const [documentStatus, setDocumentStatus] = useState('');

    const BreadcrumbsItems = [
        { label: 'Infoss', link: '/Infoss', style: '' },
        { label: 'Transaction InBound', link: '/Infoss/TransactionInBound', style: '' },
        { label: 'Create Transaction InBound', link: '/Infoss/TransactionInBound/Create', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    const BreadcrumbsEditItems = [
        { label: 'Infoss', link: '/Infoss', style: '' },
        { label: 'Transaction InBound', link: '/Infoss/TransactionInBound', style: '' },
        { label: 'Detail Transaction InBound', link: '/Infoss/TransactionInBound/' + Id, style: { color: '#0087C2', fontWeight: '700' } },
    ];

    let countErr = 0;

    useEffect(() => {
        document.title = 'Transaction InBound - ' + JSON.parse(localStorage.getItem('branchName'));
        setIsLoadingBackDrop(true)
        getDataAccess();

        // const unloadCallback = (event) => {
        // event.preventDefault();
        // event.returnValue = "";
        // return "";
        // };

        // window.addEventListener("beforeunload", unloadCallback);
        // return () => window.removeEventListener("beforeunload", unloadCallback);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleDocumentStatus(rowStatus = 'ACT', isApproved = false) {
        if (rowStatus !== 'ACT') {
            setDocumentStatus('DELETED');
        }
        else if (isApproved) {
            setDocumentStatus('APPROVED');
        }
        else {
            setDocumentStatus('ONGOING');
        }
    }

    useImperativeHandle(ref, () => ({
        localSave() {
            saveLocalData();
        }
    }));

    const saveLocalData = () => {
        let id = 0;

        if (Id) {
            id = parseInt(Id);
        }

        ApiService.PostByIdWarehouse(paramInboundCategory, 1, 999, [])
            .then((response) => {
                if (response.data.code === 200) {
                    setListInBoundCategory(response.data.data.inboundCategory);
                }
                setIsLoadingBackDrop(false)
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false)
                showToast({ type: 'error', message: error.toString() });
            })
        var data = {
            "transactionInBound": {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "rowStatus": RowStatus,
                "id": id,
                "inBoundNo": InBoundNo,
                "inBoundCategoryId": inboundCategoryId,
                "inBoundCategoryName": inboundCategoryName,
                "productCategoryId": productCategoryId,
                "productCategoryName": productCategoryName,
                "inBoundDate": InBoundDate,
                "shipmentOrderId": shipmentOrderId,
                "shipmentOrderNo": shipmentOrderNo,
                "shipperId": shipperConsigneeId,
                "shipperName": shipperConsigneeName,
                "sitesId": siteId,
                "sitesName": siteName,
                "warehousesId": warehouseId,
                "warehousesName": warehouseName,
                "warehouseLocationId": warehouseLocationId,
                "warehouseLocationName": warehouseLocationName,
                "squareMeter": squareMeter,
                "approved": approved
            },
            "transactionInBoundDetails": InboundDetailList,
        };

        localStorage.setItem(username + "-DataInBound", JSON.stringify(data));
    }

    const getLocalData = () => {
        if (!dataLocal) {
            return;
        }
        let inbound = dataLocal.transactionInBound;
        let inboundDetail = dataLocal.transactionInBoundDetails;

        setRowStatus(inbound.rowStatus);
        setInBoundNo(inbound.inBoundNo);
        setInboundCategoryId(inbound.inboundCategoryId);
        setInboundCategoryName(inbound.inboundCategoryName);
        setProductCategoryId(inbound.productCategoryId);
        setProductCategoryName(inbound.productCategoryName);
        setInBoundDate(inbound.inBoundDate);
        setShipmentOrderId(inbound.shipmentOrderId);
        setShipmentOrderNo(inbound.shipmentOrderNo);
        setShipperConsigneeId(inbound.shipperId);
        setShipperConsigneeName(inbound.shipperName);
        setSiteId(inbound.sitesId);
        setSiteName(inbound.sitesName);
        setWarehouseId(inbound.warehouseId);
        setWarehouseName(inbound.warehouseName);
        setWarehouseLocationId(inbound.warehouseLocationId);
        setWarehouseLocationName(inbound.warehouseLocationName);
        setSquareMeter(inbound.squareMeter);
        setApproved(inbound.approved);
        handleDocumentStatus(inbound.rowStatus, inbound.approved)

        if (Id) {
            setIsEdit(true);
        }

        setInBoundDetailList(inboundDetail);

        localStorage.removeItem(username + '-DataInBound');
        setTimeout(function () { setIsLoadingBackDrop(false); }, 1000);
    }

    const getDataInboundCategory = () => {
        setIsLoadingBackDrop(true);
        ApiService.PostByPageWarehouse(paramInboundCategory, 1, 999, [])
            .then((response) => {
                if (response.data.code === 200) {
                    setListInBoundCategory(response.data.data.inboundCategory);
                }
                setIsLoadingBackDrop(false)
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false)
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const getData = () => {
        if (Id) {
            ApiService.PostByIdWarehouse(paramTransactionInbound, Id)
                .then((res) => {
                    if (res.data.code === 200) {
                        const data = res.data.data.transactionInBound;
                        setIsEdit(true)
                        setInBoundDate((new Date(data.inBoundDate)).toLocaleDateString("sv-se").split('T')[0]);
                        setInBoundNo(data.inBoundNo);
                        setInboundCategoryId(data.inBoundCategoryId);
                        setInboundCategoryName(data.inBoundCategoryName);
                        setProductCategoryId(data.productCategoryId);
                        setProductCategoryName(data.productCategoryName);
                        handleDocumentStatus(data.rowStatus, data.approved)
                        setShipmentOrderId(data.shipmentOrderId);
                        setShipmentOrderNo(data.shipmentOrderNo);
                        setShipperConsigneeId(data.shipperId);
                        setShipperConsigneeName(data.shipperName);
                        setWarehouseId(data.warehousesId);
                        setWarehouseName(data.warehousesName);
                        setWarehouseLocationId(data.warehouseLocationId);
                        setWarehouseLocationName(data.warehouseLocationName);
                        setSquareMeter(data.squareMeter);
                        setSiteId(data.sitesId);
                        setSiteName(data.sitesName);
                        setApproved(data.approved);
                        setInBoundDetailList(data.transactionInBoundDetails);
                        setIsLoadingBackDrop(false);
                        getDataInboundCategory();
                    }
                })
                .catch((error) => {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: error.toString() });
                })
        }
        else {
            setIsLoadingBackDrop(false)
            getDataInboundCategory();
        }
        // else{
        //     setWarehouseLocationName("STAGE");
        //     setWarehouseLocationId("1");
        //     setIsLoadingBackDrop(false);
        // }
    }

    const getDataAccess = () => {
        if (!dataLocal) {
            setIsLoadingBackDrop(true);
        }

        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'TWHTI')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        let tempArray = [];
        let checked = 0;

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ];

            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);
                        tempArray = response.data.data.userAccess;

                        for (let i = 0; i < tempArray.length; i++) {
                            if (Id) {
                                if (tempArray[i].accessCode === 'UPD' && tempArray[i].hidden === false) {
                                    checked = 1;
                                    break;
                                }
                            }
                            else {
                                if (tempArray[i].accessCode === 'CRE') {
                                    checked = 1;
                                    break;
                                }
                            }
                        };

                        setCheck(checked);

                        if (hasAccess) {
                            handleDocumentStatus();
                            setCheck(checked);
                            getData();
                            getLocalData();
                        }
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    countErr++
                    if (countErr < 3) {
                        getDataAccess()
                    }
                    else {
                        ApiService.logOut()
                        history('/')
                    }
                })
        }
        else {
            history('/Infoss');
        }
    }


    function Save() {
        setIsLoadingBackDrop(true);

        let check = 0;
        let id = 0;

        if (Id) {
            id = parseInt(Id);
        }

        var data = {
            "transactionInBound": {
                "rowStatus": RowStatus,
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "id": id,
                "inBoundNo": InBoundNo,
                "inBoundCategoryId": inboundCategoryId,
                "inBoundCategoryName": inboundCategoryName,
                "productCategoryId": productCategoryId,
                "productCategoryName": productCategoryName,
                "inBoundDate": InBoundDate,
                "shipmentOrderId": shipmentOrderId,
                "shipmentOrderNo": shipmentOrderNo,
                "shipperId": shipperConsigneeId,
                "shipperName": shipperConsigneeName,
                "sitesId": siteId,
                "sitesName": siteName,
                "warehousesId": warehouseId,
                "warehousesName": warehouseName,
                "warehouseLocationId": warehouseLocationId,
                "warehouseLocationName": warehouseLocationName,
                "squareMeter": squareMeter,
                "userId": userId,
                "userCode": userCode
            },
            "transactionInBoundDetails": InboundDetailList,
        };

        let message = '';

        if (data.transactionInBound.inBoundCategoryId === 0) {
            check = 1;
        }
        else if (data.transactionInBound.productCategoryId === 0) {
            check = 2;
        }
        else if (data.transactionInBound.sitesId === 0) {
            check = 3;
        }
        else if (data.transactionInBound.warehousesId === 0) {
            check = 4;
        }
        else if (data.transactionInBound.warehouseLocationId === 0) {
            check = 5;
        }
        else if (data.transactionInBound.squareMeter === 0 || data.transactionInBound.squareMeter === '') {
            check = 6;
        }
        else if (data.transactionInBoundDetails.length <= 0) {
            check = 7;
        }

        if (check === 0) {
            if (id === 0) {
                ApiService.createDataWarehouse(paramTransactionInbound, data).then((res) => {
                    if (res.data.code === 200) {
                        setIsLoadingBackDrop(false);
                        showToast({ type: 'success', message: message });
                        setTimeout(
                            function () {
                                setIsLoadingBackDrop(false);
                                history('/Infoss/TransactionOutBound');
                            },
                            1250);
                    }
                    else {
                        setIsLoadingBackDrop(false);
                        message = 'Create Data Failed';
                        showToast({ type: 'error', message: message });
                        setTimeout(
                            function () {
                                setIsLoadingBackDrop(false);
                            },
                            1250);
                    }
                })
                    .catch((err) => {
                        setIsLoadingBackDrop(false);
                        showToast({ type: 'error', message: err.toString() });
                    })
            }
            else {
                ApiService.updateDataWarehouse(paramTransactionInbound, data).then((res) => {
                    if (res.data.code === 200) {
                        setIsLoadingBackDrop(false);
                        showToast({ type: 'success', message: message });
                        setTimeout(
                            function () {
                                setIsLoadingBackDrop(false);
                                history('/Infoss/TransactionOutBound');
                            },
                            1250);
                    }
                    else {
                        setIsLoadingBackDrop(false);
                        message = 'Updated Data Failed';
                        showToast({ type: 'error', message: message });
                        setTimeout(
                            function () {
                                setIsLoadingBackDrop(false);
                            },
                            1250);
                    }
                })
                    .catch((err) => {
                        setIsLoadingBackDrop(false);
                        showToast({ type: 'error', message: err.toString() });
                    })
            }
        }
        else {
            setIsLoadingBackDrop(false);
            if (check === 1) {
                message = 'Please Select InBound Category!'
            }
            else if (check === 2) {
                message = 'Please Select Product Category!'
            }
            else if (check === 3) {
                message = 'Please Select Site!'
            }
            else if (check === 4) {
                message = 'Please Select Warehouse!'
            }
            else if (check === 5) {
                message = 'Please Select Warehouse Location!'
            }
            else if (check === 6) {
                message = 'Please Input Square Meter!'
            }
            else if (check === 7) {
                message = 'Please Input At Least 1 InBound Detail!'
            }

            showToast({ type: 'error', message: message });
        }
    }

    function Back() {
        history('/Infoss/TransactionInBound');
    }

    function PrintQR() {
        setIsLoadingBackDrop(true);
        ApiService.PrintQRWarehouse(paramTransactionInbound, InBoundNo, Id)
            .then((response) => {
                if (response.status === 200) {
                    // const blob = new Blob([response.data], { type: 'application/pdf' });
                    // const url = window.URL.createObjectURL(blob);
                    // const iframe = document.createElement('iframe');
                    // iframe.style.display = 'none';
                    // iframe.src = url;
                    // document.body.appendChild(iframe);
                    // iframe.contentWindow.print();
                    // setIsLoadingBackDrop(false);

                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' }
                    );

                    const fileURL = URL.createObjectURL(file);

                    setIsLoadingBackDrop(false);
                    showToast({ type: 'success', message: 'Print InBound Document Success' });
                    window.open(fileURL);
                } else {
                    setIsLoadingBackDrop(false);
                    throw new Error("Failed, " + response.status);
                }
            })
            .catch((error) => {
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: error.toString() });
            });
    }

    const Approve = () => {
        setIsLoadingBackDrop(true);
        ApiService.ApproveWarehouse(paramTransactionInbound, Id)
            .then((res) => {
                setIsLoadingBackDrop(false);
                if (res.data.code === 200) {
                    showToast({ type: 'success', message: res.data.message });
                    getData();
                } else {
                    showToast({ type: 'error', message: res.message });
                }
            })
            .catch((err) => {
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: err.toString() });
            })
    }

    const PutAway = () => {
        setIsLoadingBackDrop(true)
        ApiService.PutAwayWarehouse(paramTransactionInbound, Id)
            .then((res) => {
                setIsLoadingBackDrop(false)
                if (res.data.code === 200) {
                    showToast({ type: 'success', message: res.data.message });
                    getData();
                } else {
                    showToast({ type: 'error', message: res.data.message });
                }
            })
            .catch((err) => {
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: err.toString() });
            })
    }

    const ButtonComponents = () => {
        return (
            <ButtonCreateEditInBound
                Save={Save}
                Back={Back}
                Print={PrintQR}
                DataRegUserAccess={DataRegUserAccess}
                check={check}
                Approve={Approve}
                Approved={approved}
                PutAway={PutAway}
                Id={Id}
            />
        )
    }

    return (
        <>
            <Box sx={{ backgroundColor: '#E4E8EC', width: '100%', p: '24px 32px' }}>
                <CustomToastContainer />
                <Grid container spacing={0} direction="column">
                    <HeaderInfo
                        title={
                            Id ?
                                'Transaction InBound (' + InBoundNo + ')'
                                :
                                'Transaction InBound (New Document)'
                        }
                        BreadcrumbsItems={Id ? BreadcrumbsEditItems : BreadcrumbsItems}
                        type={'Form'}
                        ticketId={0}
                        BackFunction={Back}
                        documentStatus={documentStatus}
                    />

                    <fieldset>
                        <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                            <CardContent>
                                <InBoundHeader
                                    Id={Id}
                                    isEdit={isEdit}
                                    setIsLoadingBackDrop={setIsLoadingBackDrop}
                                    InBoundDate={InBoundDate}
                                    setInBoundDate={setInBoundDate}
                                    InBoundNo={InBoundNo}
                                    setInBoundNo={setInBoundNo}
                                    squareMeter={squareMeter}
                                    setSquareMeter={setSquareMeter}
                                    inboundCategoryId={inboundCategoryId}
                                    setInboundCategoryId={setInboundCategoryId}
                                    inboundCategoryName={inboundCategoryName}
                                    setInboundCategoryName={setInboundCategoryName}
                                    productCategoryName={productCategoryName}
                                    setProductCategoryName={setProductCategoryName}
                                    productCategoryId={productCategoryId}
                                    setProductCategoryId={setProductCategoryId}
                                    siteName={siteName}
                                    setSiteName={setSiteName}
                                    siteId={siteId}
                                    setSiteId={setSiteId}
                                    warehouseId={warehouseId}
                                    setWarehouseId={setWarehouseId}
                                    warehouseName={warehouseName}
                                    setWarehouseName={setWarehouseName}
                                    warehouseLocationId={warehouseLocationId}
                                    setWarehouseLocationId={setWarehouseLocationId}
                                    warehouseLocationName={warehouseLocationName}
                                    setWarehouseLocationName={setWarehouseLocationName}
                                    warehouseLocationX={warehouseLocationX}
                                    setWarehouseLocationX={setWarehouseLocationX}
                                    warehouseLocationY={warehouseLocationY}
                                    setWarehouseLocationY={setWarehouseLocationY}
                                    warehouseLocationZ={warehouseLocationZ}
                                    setWarehouseLocationZ={setWarehouseLocationZ}
                                    shipmentOrderId={shipmentOrderId}
                                    setShipmentOrderId={setShipmentOrderId}
                                    shipmentOrderNo={shipmentOrderNo}
                                    setShipmentOrderNo={setShipmentOrderNo}
                                    shipperConsigneeId={shipperConsigneeId}
                                    setShipperConsigneeId={setShipperConsigneeId}
                                    shipperConsigneeName={shipperConsigneeName}
                                    setShipperConsigneeName={setShipperConsigneeName}
                                    approved={approved}
                                    listInBoundCategory={listInBoundCategory}
                                    InboundDetailList={InboundDetailList}
                                    setInBoundDetailList={setInBoundDetailList}
                                />
                            </CardContent>
                        </Card>

                        <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                            <CardContent>
                                <InBoundDetail
                                    Id={Id}
                                    approved={approved}
                                    InboundDetailList={InboundDetailList}
                                    setInBoundDetailList={setInBoundDetailList}
                                    warehouseLocationId={warehouseLocationId}
                                    warehouseLocationName={warehouseLocationName}
                                    warehouseLocationX={warehouseLocationX}
                                    warehouseLocationY={warehouseLocationY}
                                    warehouseLocationZ={warehouseLocationZ}
                                    productCategoryId={productCategoryId}
                                />
                            </CardContent>
                        </Card>
                    </fieldset>
                </Grid>
            </Box>

            <ButtonInfo
                ButtonComponents={ButtonComponents}
            />

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default CreateEditInBound;