import { 
  Avatar, Backdrop, Box, 
  Stack, Typography, Slide, Fade, 
  // Skeleton, Button,
} from '@mui/material'
import React, { forwardRef, useImperativeHandle, useEffect, useState } from 'react'
// import { useNavigate } from 'react-router-dom'

import BackgroundNotFound from '../../../Assets/Background/background404.jpg';
import moonIcon from '../../../Assets/Icon/moon.icon.svg';
import ellipseIcon from '../../../Assets/Icon/ellipse.icon.svg';
import fourIcon from '../../../Assets/Icon/four.icon.svg';
import rectangleIcon from '../../../Assets/Icon/rectangle.icon.svg';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import infossLogo from '../../../Assets/Icon/infossLogo.svg';
import '../../../Styles/notFound.css';
import PageNotFoundAnimation from './PageNotFoundAnimation';

const NotFoundPage = forwardRef((props, ref) => {
  // const history = useNavigate();
  const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);

  useEffect(() => {
    if(props?.isOutside)
    {
      document.title = 'Page Not Found'
    }
    else
    {
      document.title = 'Page Not Found - ' + JSON.parse(localStorage.getItem('branchName'))
    }
    setTimeout(function () { setIsLoadingBackDrop(false); }, 1250);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    localSave() { return }
  }));

  return (
    <Box 
      sx={{ 
        backgroundImage: `url(${BackgroundNotFound})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center', 
      }}
    >
      <Stack
        sx={{
          p: 3,
          pt: 0,
          height: props?.isOutside ? '100vh' : `calc(100vh - 64px)`,
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden'
        }}
      >
        <Box sx={{ position: 'relative' }}>
          {
            isLoadingBackDrop?
              <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                {/* <Skeleton width={575} height={450} sx={{ mb: 3 }} /> */}
              </Box>
            :
              <>
                <Stack
                  sx = {{
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <Stack direction={'row'} spacing={-5}>
                      <div style={{paddingTop: '109px', zIndex: 4, transform: 'rotate(345deg)'}}>
                        <Fade in={true} timeout={1200}>
                          <Avatar 
                            src={fourIcon}
                            sx={{ width: '197.994px', height: '254.545px' }}
                            variant='square'
                            alt='.'
                            className='image-numberPos'
                          />
                        </Fade>
                      </div>

                      <Slide direction="down" in={true} timeout={800} mountOnEnter>
                        <Avatar 
                          src={moonIcon}
                          sx={{ width: '430px', height: '430px', zIndex: 2 }}
                          variant='square'
                          alt='.'
                          className='image-moon'
                        />
                      </Slide>

                      <div style={{paddingTop: '109px', zIndex: 4, transform: 'rotate(15deg)'}}>
                        <Fade in={true} timeout={1200}>
                          <Avatar 
                            src={fourIcon}
                            sx={{ width: '197.994px', height: '254.545px' }}
                            variant='square'
                            alt='.'
                            className='image-numberPre'
                          />
                        </Fade>
                      </div>
                    </Stack>
                  </div>
                  
                  <div style={{ marginTop: '-130px' }}>
                    <Stack
                      sx = {{
                        alignItems: 'center',
                      }}
                    >
                      <Avatar 
                        src={ellipseIcon}
                        sx={{ width: '481px', height: '66px', zIndex: 1 }}
                        variant='square'
                        alt='.'
                      />

                      <div style={{ marginTop: '-21px' }}>
                        <Avatar 
                          src={rectangleIcon}
                          sx={{ width: '481px', height: '69px', zIndex: 3 }}
                          variant='square'
                          alt='.'
                        />
                      </div>
                    </Stack>
                  </div>
                </Stack>
              </>
          }
        </Box>
        
        <div style={{ marginTop: '22px' }}>
          {
            isLoadingBackDrop?
            <></>
              // <Skeleton sx={{ mt: 1 }} width={450} height={30}/>
            :
              <PageNotFoundAnimation />
          }
        </div>

        <Typography
          sx={{
            color: '#D5E9F2',
            textAlign: 'center',
            textShadow: '0px 4px 4px rgba(0, 0, 0, 0.32)',
            fontFamily: 'Inter',
            fontSize: '32px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
            marginTop: '22px',
            zIndex: 5,
            cursor: 'default'
          }}
        >
          {
            isLoadingBackDrop?
            <></>
              // <Skeleton sx={{ mt: 1 }} width={450} height={30}/>
            :
              <>
                So sorry,
                <br></br>
                we couldn’t find what you were looking for...
              </>
          }
        </Typography>

        <Box sx={{ position: 'relative' }}>
          {
            isLoadingBackDrop?
              <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                {/* <Skeleton width={575} height={450} sx={{ mb: 3 }} /> */}
              </Box>
            :
              <Avatar 
                src={infossLogo}
                sx={{ width: '229px', height: '47px', marginTop: '22px' }}
                variant='square'
                alt='.'
              />
          }
        </Box>
        
        {
            isLoadingBackDrop?
            <></>
              // <Skeleton sx={{ mt: 2 }} width={130} height={50} />
            :
              null
              // <Button
              //   onClick={() => history('/Infoss')}
              //   variant='contained'
              //   size='small'
              //   sx={{ mt: 3 }}
              // >
              //   <KeyboardBackspace /> Back to Home
              // </Button>
          }
      </Stack>
            
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingBackDrop}
      >
        <LoadingSpinner />
      </Backdrop>
    </Box>
  )
})

export default NotFoundPage