import React, { useState, useEffect } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Stack, Typography } from '@mui/material'

import { calculateBC, formatCurrency } from '../utils/calculateAmount';
import { Icon, Input } from '../../../../components/ui';
import { updateDateFormat } from '../../../../utils/date';

const OperationalMarketingSection = ({
  shipmentData,
  bookingConfirmationData,
  eplData,
  paymentRequestData,
  temporaryReceiptData,
  invoiceData,
  paymentVoucherData,
  officialReceiptData,
  jobTypeList,
}) => {
  const bcAmount = calculateBC(bookingConfirmationData?.bookingConfirmationDetails)

  const [transportation, setTransportation] = useState('')
  const [service, setService] = useState('')

  const [invoiceAmount, setInvoiceAmount] = useState({ idr: 0, usd: 0 })
  const [paymentRequestAmount, setPaymentRequestAmount] = useState({ idr: 0, usd: 0 })
  const [temporaryReceiptAmount, setTemporaryReceiptAmount] = useState({ idr: 0, usd: 0 })
  const [paymentVoucherAmount, setPaymentVoucherAmount] = useState({ idr: 0, usd: 0 })
  const [officialReceiptAmount, setOfficialReceiptAmount] = useState({ idr: 0, usd: 0 })

  useEffect(() => {
    extractJobType()

    getInvoiceAmount()
    getPaymentRequestAmount()
    getPaymentVoucherAmount()
    getOfficialReceiptAmount()
    getTemporaryReceiptAmount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const extractJobType = () => {
    const activeJT = jobTypeList.find((job) => job.value === shipmentData.jobTypeId)?.label || ''

    if (activeJT.toUpperCase().indexOf('AIR') > -1) {
      setTransportation('air')
    } else if (activeJT.toUpperCase().indexOf('SEA') > -1) {
      setTransportation('water')
    } else {
      setTransportation('land')
    }

    if (activeJT.toUpperCase().indexOf('EXPORT') > -1) {
      setService('export')
    } else if (activeJT.toUpperCase().indexOf('IMPORT') > -1) {
      setService('import')
    } else {
      setService('domestic')
    }
  }

  const dateFormatter = (date) => {
    if (!date) return '-'

    return updateDateFormat(new Date());
  }

  const getFlight = (target) => {
    const routings = shipmentData?.shipmentOrderRoutings || []
    if (!routings.length) return '-'

    let routing;
    if (target === 'first') {
      routing = routings[0]
    } else {
      const arrLength = routings.length
      routing = routings[arrLength - 1]
    }

    return routing?.flightNo || '-'
  }

  const getVessel = (target) => {
    const routings = shipmentData?.shipmentOrderRoutings || []
    if (!routings.length) return '-'

    let routing = routings.find((route) => route.vesselType === target)
    return routing?.vesselName || '-'
  }

  const getPaymentRequestAmount = () => {
    let prAmount = {
      idr: 0,
      usd: 0,
    }

    paymentRequestData.forEach((pr) => {
      prAmount.idr += (pr.paymentIDR + pr.totalPpnIDR)
      prAmount.usd += (pr.paymentUSD + pr.totalPpnUSD)
    })

    setPaymentRequestAmount(prAmount)
  }

  const getTemporaryReceiptAmount = () => {
    let trAmount = {
      idr: 0,
      usd: 0,
    }

    temporaryReceiptData.forEach((tr) => {
      trAmount.idr += (tr.cashIDR + tr.bankIDR)
      trAmount.usd += (tr.cashUSD + tr.bankUSD)
    })

    setTemporaryReceiptAmount(trAmount)
  }

  const getInvoiceAmount = () => {
    let invAmount = {
      idr: 0,
      usd: 0,
    }

    invoiceData.forEach((inv) => {
      invAmount.idr += (inv.totalPaymentIDR)
      invAmount.usd += (inv.totalPaymentUSD)
    })

    setInvoiceAmount(invAmount)
  }

  const getOfficialReceiptAmount = () => {
    let orAmount = {
      idr: 0,
      usd: 0,
    }

    officialReceiptData.forEach((or) => {
      orAmount.idr += (or.cashIDR + or.bankIDR)
      orAmount.usd += (or.cashUSD + or.bankUSD)
    })

    setOfficialReceiptAmount(orAmount)
  }

  const getPaymentVoucherAmount = () => {
    let pvAmount = {
      idr: 0,
      usd: 0,
    }

    paymentVoucherData.forEach((pv) => {
      pvAmount.idr += (pv.amountIDR + pv.amountTaxIDR)
      pvAmount.usd += (pv.amountUSD + pv.amountTaxUSD)
    })

    setPaymentVoucherAmount(pvAmount)
  }

  const detailUSDIDR = (title, usd, idr) => {
    return (
      <div style={{ marginTop: '16px' }}>
        <Typography
          sx={{
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '19.6px',
          }}
        >
          {title}
        </Typography>
        <Stack direction={'row'} spacing={'32px'} sx={{ marginTop: '3px' }} alignItems={'center'}>
          <Stack direction={'row'} spacing={'16px'} sx={{ marginTop: '3px' }} alignItems={'center'}>
            <Typography
              sx={{
                color: 'var(--Primary-One, #083A50)',
                fontFamily: 'Nunito',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: '19.6px',
              }}
            >
              USD
            </Typography>
            <Input
              value={usd}
              disabled
              textAlign='right'
              formControlProps={{
                sx: {
                  width: '100%',
                  marginTop: '16px'
                }
              }}
            />
          </Stack>

          <Stack direction={'row'} spacing={'16px'} sx={{ marginTop: '3px' }} alignItems={'center'}>
            <Typography
              sx={{
                color: 'var(--Primary-One, #083A50)',
                fontFamily: 'Nunito',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: '19.6px',
              }}
            >
              IDR
            </Typography>
            <Input
              value={idr}
              disabled
              textAlign='right'
              formControlProps={{
                sx: {
                  width: '100%',
                  marginTop: '16px'
                }
              }}
            />
          </Stack>
        </Stack>
      </div>
    )
  }

  return (
    <Accordion
      defaultExpanded={true}
      style={{ borderRadius: '12px', padding: '0px 24px', border: '1px solid var(--Disable-Grey-Text-1, #A5ACBE)' }}
    >
      <AccordionSummary
        expandIcon={
          <Icon
            iconName={'arrow-down'}
            svgIconProps={{
              htmlColor: '#0087C2',
              sx: {
                height: '24px',
                width: '24px'
              }
            }}
          />
        }
        aria-controls="oprmkt-content"
        id="oprmkt-header"
      >
        <Typography
          sx={{
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '20px',
          }}
        >
          Operational & Marketing
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Grid
          container
          spacing={'48px'}
        >
          <Grid
            item
            xl={6}
            lg={6}
            sm={6}
            xs={12}
          >
            <Input
              label={'Booking Confirmation'}
              value={shipmentData?.bookingNo || '-'}
              disabled
              formControlProps={{
                sx: {
                  width: '100%',
                }
              }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={'48px'}
        >
          <Grid
            item
            xl={6}
            lg={6}
            sm={6}
            xs={12}
          >
            <Input
              label={transportation !== 'air' ? 'Feeder Vessel/ETD' : 'First Flight/ETD'}
              value={transportation !== 'air' ? getVessel('Feeder') + ' / ' + dateFormatter(shipmentData?.etd) : getFlight('first') + ' / ' + dateFormatter(shipmentData?.etd)}
              type={'date'}
              disabled
              formControlProps={{
                sx: {
                  width: '100%',
                  marginTop: '16px'
                }
              }}
            />

            <Input
              label={(['export', 'domestic'].includes(service) && transportation !== 'air') ? 'Port of Discharge' : transportation === 'water' ? 'Place of Receipt' : null}
              value={(['export', 'domestic'].includes(service) && transportation !== 'air') ? shipmentData?.dischargePortName || '-' : transportation === 'water' ? shipmentData?.receiptPlaceName || '-' : null}
              disabled
              hidden={(['export', 'domestic'].includes(service) && transportation !== 'air') ? false : transportation === 'water' ? false : true}
              formControlProps={{
                sx: {
                  width: '100%',
                  marginTop: '16px'
                }
              }}
            />

            <Input
              label={'AirPort of Destination'}
              value={shipmentData?.destinationAirportName || '-'}
              disabled
              hidden={(service === 'export' && transportation === 'air') ? false : true}
              formControlProps={{
                sx: {
                  width: '100%',
                  marginTop: '16px'
                }
              }}
            />

            <Input
              label={transportation !== 'air' ? 'SSLine' : 'AirLine'}
              value={transportation !== 'air' ? shipmentData?.ssLineName || '-' : shipmentData?.shipmentOrderRoutings?.[0]?.flightName || '-'}
              disabled
              formControlProps={{
                sx: {
                  width: '100%',
                  marginTop: '16px'
                }
              }}
            />

            <Input
              label={'Shipping Instruction'}
              value={shipmentData?.shipmentOrderInstruction?.siReference || '-'}
              disabled
              formControlProps={{
                sx: {
                  width: '100%',
                  marginTop: '16px'
                }
              }}
            />

            <Input
              label={'Marketing'}
              value={shipmentData?.marketName || '-'}
              disabled
              formControlProps={{
                sx: {
                  width: '100%',
                  marginTop: '16px'
                }
              }}
            />

            {
              detailUSDIDR('Booking Confirmation', bcAmount?.usd || 0, bcAmount?.idr || 0)
            }
          </Grid>

          <Grid
            item
            xl={6}
            lg={6}
            sm={6}
            xs={12}
          >
            <Input
              label={transportation !== 'air' ? 'Mother Vessel/ETA' : 'Last Flight/ETA'}
              value={transportation !== 'air' ? getVessel('Mother') + ' / ' + dateFormatter(shipmentData?.eta) : getFlight('last') + ' / ' + dateFormatter(shipmentData?.eta)}
              type={'date'}
              disabled
              formControlProps={{
                sx: {
                  width: '100%',
                  marginTop: '16px'
                }
              }}
            />

            <Input
              label={(['export', 'domestic'].includes(service) && transportation !== 'air') ? 'Place of Delivery' : transportation === 'water' ? 'Port of Loading' : null}
              value={(['export', 'domestic'].includes(service) && transportation !== 'air') ? shipmentData?.deliveryPlaceName || '-' : transportation === 'water' ? shipmentData?.loadingPortName || '-' : null}
              disabled
              hidden={(['export', 'domestic'].includes(service) && transportation !== 'air') ? false : transportation === 'water' ? false : true}
              formControlProps={{
                sx: {
                  width: '100%',
                  marginTop: '16px'
                }
              }}
            />

            <Input
              label={'Place of Delivery'}
              value={shipmentData?.deliveryPlaceName || '-'}
              disabled
              hidden={(service === 'export' && transportation === 'air') ? false : true}
              formControlProps={{
                sx: {
                  width: '100%',
                  marginTop: '16px'
                }
              }}
            />

            <Input
              label={service !== 'import' ? 'BLNumber' : transportation === 'air' ? 'HAWB Number' : 'House BL Number'}
              value={service !== 'import' ? shipmentData?.blNumber || '-' : transportation === 'air' ? shipmentData?.hawbNo || '-' : shipmentData?.houseBLNo || '-'}
              disabled
              formControlProps={{
                sx: {
                  width: '100%',
                  marginTop: '16px'
                }
              }}
            />

            <Input
              label={'Shipment Advice'}
              value={shipmentData?.shipmentOrderAdvice?.reference || '-'}
              disabled
              formControlProps={{
                sx: {
                  width: '100%',
                  marginTop: '16px'
                }
              }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={'48px'}
        >
          <Grid
            item
            xl={6}
            lg={6}
            sm={6}
            xs={12}
          >
            {
              detailUSDIDR('Estimate JPL', formatCurrency(eplData?.estTotalUSD || 0, 'usd'), formatCurrency(eplData?.estTotalIDR || 0, 'idr'))
            }

            {
              detailUSDIDR('Payment Request', formatCurrency(paymentRequestAmount.usd, 'usd'), formatCurrency(paymentRequestAmount.idr, 'idr'))
            }

            {
              detailUSDIDR('Payment Voucher', formatCurrency(paymentVoucherAmount.usd, 'usd'), formatCurrency(paymentVoucherAmount.idr, 'idr'))
            }

            {
              detailUSDIDR('Profit Loss Actual (OR - PV)', formatCurrency(officialReceiptAmount.usd - paymentVoucherAmount.usd, 'usd'), formatCurrency(officialReceiptAmount.idr - paymentVoucherAmount.idr, 'idr'))
            }
          </Grid>

          <Grid
            item
            xl={6}
            lg={6}
            sm={6}
            xs={12}
          >
            {
              detailUSDIDR('Temporary Receipt', formatCurrency(temporaryReceiptAmount.usd, 'usd'), formatCurrency(temporaryReceiptAmount.idr, 'usd'))
            }

            {
              detailUSDIDR('Invoices', formatCurrency(invoiceAmount.usd, 'usd'), formatCurrency(invoiceAmount.idr, 'idr'))
            }

            {
              detailUSDIDR('Official Receipt', formatCurrency(officialReceiptAmount.usd, 'usd'), formatCurrency(officialReceiptAmount.idr, 'idr'))
            }
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default OperationalMarketingSection