import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Grid, Stack, IconButton, Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import Backdrop from '@mui/material/Backdrop';

import ApiService from '../../services/ApiService';
import Paging from '../../components/Tables/Paging';
import ButtonDataLogs from './Buttons/ButtonDataLogs';
import TableBalance from '../../components/Tables/TableBalance';
import TableNoFilter from '../../components/Tables/TableNoFilter';
import LoadingSpinner from '../../components/Components/LoadingSpinner';
import CustomToastContainer, { showToast } from '../../components/ui';

const ListLogTransactions = forwardRef((props, ref) => {
  const { dataId } = useParams();
  const { transactionType } = useParams();
  const { period } = useParams();
  const { yearPeriod } = useParams();

  const paramGL = 'generalLedger/generalLedger';
  const paramClosingBalance = 'closingBalance/closingBalance';
  const history = useNavigate();

  const [transactionNumber, setTransactionNumber] = useState('');

  const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
  const [isLoadingGL, setIsLoadingGL] = useState(false);
  const [isLoadingCB, setIsLoadingCB] = useState(false);
  const [openGL, setOpenGL] = useState(true);
  const [openCB, setOpenCB] = useState(false);

  const [dataGL, setDataGL] = useState([]);
  const [columnDataGL, setColumnDataGL] = useState([]);
  const [numPageGL, setNumPageGL] = useState(1);
  const [rowsCountGL, setRowsCountGL] = useState(50);
  const [totalRowsGL, setTotalRowsGL] = useState(50);
  const [dataMapGL, setDataMapGL] = useState([]);
  const [SelectedDataGL, setSelectedDataGL] = useState({});
  const [sortArrGL, setSortArrGL] = useState([])
  const [goToGL, setGoToGL] = useState(1);

  const [DataCB, setDataCB] = useState([]);

  let countRefresh = 0;
  useImperativeHandle(ref, () => ({

    localSave() {
    }

  }));
  useEffect(() => {
    if (countRefresh === 0) {
      document.title = 'Transaction Logs - ' + transactionType + ' - ' + JSON.parse(localStorage.getItem('branchName'))
      ApiService.refreshToken();
      countRefresh++;
      getDataTransaction();
      getDataGL(1, 50);
      getDataCB(1, 50);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDataTransaction = () => {
    let param = transactionType + '/' + transactionType;

    if (transactionType === 'Invoice' || transactionType === 'PaymentRequest') {
      ApiService.OperationPostById(param, dataId)
        .then((response) => {
          if (response.data.code === 200) {
            if (transactionType === 'Invoice') {
              setTransactionNumber(response.data.data.invoice.reference);
            }
            else if (transactionType === 'PaymentRequest') {
              setTransactionNumber(response.data.data.paymentRequest.reference);
            }
          }
          else if (response.data.code === 500) {
            showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });
            setIsLoadingBackDrop(false);
          }
        })
        .catch(function (error) {
          showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + error });
        })
    }
    else {
      ApiService.FinAccPostById(param, dataId)
        .then((response) => {
          if (response.data.code === 200) {
            if (transactionType === 'OfficialReceipt') {
              setTransactionNumber(response.data.data.officialReceipt.orNo2);
            }
            else if (transactionType === 'PaymentVoucher') {
              setTransactionNumber(response.data.data.paymentVoucher.pvNo2);
            }
            else if (transactionType === 'ReceiptVoucher') {
              setTransactionNumber(response.data.data.receiptVoucher.rvNo2);
            }
            else if (transactionType === 'TemporaryPayment') {
              setTransactionNumber(response.data.data.temporaryPayment.tpNo2);
            }
            else if (transactionType === 'TemporaryReceipt') {
              setTransactionNumber(response.data.data.temporaryReceipt.trNo2);
            }
            else if (transactionType === 'Memorial') {
              setTransactionNumber(response.data.data.memorial.memorialNo);
            }
          }
          else if (response.data.code === 500) {
            showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });
            setIsLoadingBackDrop(false);
          }
        })
        .catch(function (error) {
          showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + error });
        })
    }
  }

  const getDataGL = (pageNumber, pageSize, filter, orderBy) => {
    let source;
    if (isLoadingBackDrop === false) {
      setIsLoadingGL(true);
    }

    if (transactionType === 'Invoice') {
      source = 'INV';
    }
    else if (transactionType === 'PaymentRequest') {
      source = 'PR';
    }
    else if (transactionType === 'OfficialReceipt') {
      source = 'OR';
    }
    else if (transactionType === 'PaymentVoucher') {
      source = 'PV';
    }
    else if (transactionType === 'ReceiptVoucher') {
      source = 'RV';
    }
    else if (transactionType === 'TemporaryPayment') {
      source = 'TP';
    }
    else if (transactionType === 'TemporaryReceipt') {
      source = 'TR';
    }
    else if (transactionType === 'Memorial') {
      source = 'ME';
    }

    let params = [
      {
        "attr": "refId",
        "value": "" + dataId
      },
      {
        'attr': 'source',
        'value': source
      }
    ];

    ApiService.FinAccPostByPage(paramGL, 'PAGE', pageNumber, pageSize, params, filter, orderBy)
      .then((response) => {
        if (response.data.code === 200) {
          setSelectedDataGL({})
          setDataGL(response.data.data.generalLedger);

          let temp = response.data.data.generalLedger
          let indexed = temp.map((el, index) => {
            let indexedTemp = {
              ...el,
              index
            }
            return indexedTemp
          })

          setDataMapGL(indexed)
          setColumnDataGL(response.data.data.columns)
          setTotalRowsGL(response.data.totalRowCount)

          if (isLoadingBackDrop === false) {
            setIsLoadingGL(false);
          }
        }
        else if (response.data.code === 500) {
          showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });

          if (isLoadingBackDrop === false) {
            setIsLoadingGL(false);
          }
        }
      })
      .catch(function (error) {
        if (isLoadingBackDrop === false) {
          setIsLoadingGL(false);
        }
        console.error('error saat fetch', error);
        showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + error });
      })
  }

  const getDataCB = (pageNumber, pageSize, filter, orderBy) => {
    let source;

    if (isLoadingBackDrop === false) {
      setIsLoadingCB(true);
    }
    else {
      setIsLoadingBackDrop(true);
    }

    if (transactionType === 'Invoice') {
      source = 'INV';
    }
    else if (transactionType === 'PaymentRequest') {
      source = 'PR';
    }
    else if (transactionType === 'OfficialReceipt') {
      source = 'OR';
    }
    else if (transactionType === 'PaymentVoucher') {
      source = 'PV';
    }
    else if (transactionType === 'ReceiptVoucher') {
      source = 'RV';
    }
    else if (transactionType === 'TemporaryPayment') {
      source = 'TP';
    }
    else if (transactionType === 'TemporaryReceipt') {
      source = 'TR';
    }
    else if (transactionType === 'Memorial') {
      source = 'ME';
    }

    let params = [
      {
        "attr": "refId",
        "value": "" + dataId
      },
      {
        'attr': 'source',
        'value': source
      },
      {
        'attr': 'period',
        'value': period
      },
      {
        'attr': 'yearPeriod',
        'value': yearPeriod
      }
    ];

    ApiService.FinAccPostByPage(paramClosingBalance, 'LOG', pageNumber, pageSize, params, filter, orderBy)
      .then((response) => {
        if (response.data.code === 200) {
          let temp = response.data.data.closingBalances
          let indexed = temp.map((el, index) => {
            let indexedTemp = {
              ...el,
              index
            }
            return indexedTemp
          })

          setDataCB(indexed);

          if (isLoadingBackDrop === false) {
            setIsLoadingCB(false);
          }
          else {
            setIsLoadingBackDrop(false);
          }
        }
        else if (response.data.code === 500) {
          showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });

          if (isLoadingBackDrop === false) {
            setIsLoadingCB(false);
          }
          else {
            setIsLoadingBackDrop(false);
          }
        }
      })
      .catch(function (error) {
        if (isLoadingBackDrop === false) {
          setIsLoadingCB(false);
        }
        else {
          setIsLoadingBackDrop(false);
        }

        console.error('error saat fetch', error)
        showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + error });
      })
  }

  const Back = () => {
    history('/Infoss/' + transactionType);
  }

  const ReloadData = () => {
    showToast({ type: 'success', message: 'Reload Data' });
    emptyState();
    getDataGL(1, 50);
    getDataCB(1, 50);
  }

  const emptyState = () => {
    setGoToGL(1);
    setNumPageGL(1);
    setRowsCountGL(50);
    setTotalRowsGL(50);
    setSortArrGL([]);
  };

  const GLTable = () => {
    return (
      <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px', 'marginTop': '-10px' }}>
        <IconButton
          aria-label="expand row"
          size="large"
          onClick={() => setOpenGL(!openGL)}
          disableFocusRipple={true}
          disableRipple={true}
        >
          {
            openGL ?
              <Stack direction='row' spacing={1} justifyContent="center" alignItems="center">
                <KeyboardArrowUpIcon />
                <h5 style={{ 'color': 'black' }}>General Ledger</h5>
              </Stack>
              :
              <Stack direction='row' spacing={1} justifyContent="center" alignItems="center">
                <KeyboardArrowDownIcon />
                <h5 style={{ 'color': 'black' }}>General Ledger</h5>
              </Stack>
          }
        </IconButton>
        <Collapse in={openGL} timeout="auto" unmountOnExit>
          <Box>
            <div className='rounded-10 p-2 table-responsive' style={{ 'maxHeight': '500px', 'marginTop': '-10px' }}>
              {
                isLoadingGL ?
                  <LoadingSpinner />
                  :
                  <>
                    <div className='mt-1'>
                      <TableNoFilter
                        rowsCount={rowsCountGL}
                        setNumPage={setNumPageGL}
                        setRowsCount={setRowsCountGL}
                        getData={getDataGL}
                        columnData={columnDataGL}
                        dataMap={dataMapGL}
                        SelectedData={SelectedDataGL}
                        setSelectedData={setSelectedDataGL}
                        sortArr={sortArrGL}
                        setSortArr={setSortArrGL}
                        setGoTo={setGoToGL}
                      />
                    </div>
                  </>
              }
            </div>

            <Paging
              setRowsCount={setRowsCountGL}
              setNumPage={setNumPageGL}
              getData={getDataGL}
              rowsCount={rowsCountGL}
              numPage={numPageGL}
              data={dataGL}
              totalRows={totalRowsGL}
              sortArr={sortArrGL}
              goTo={goToGL}
              setGoTo={setGoToGL}
            />
          </Box>
        </Collapse>
      </Grid>
    )
  }

  const CBTable = () => {
    return (
      <Grid item xs={12} style={{ maxWidth: "97vw", borderRadius: "5px" }}>
        <IconButton
          aria-label="expand row"
          size="large"
          onClick={() => setOpenCB(!openCB)}
          disableFocusRipple={true}
          disableRipple={true}
        >
          {
            openCB ?
              <Stack direction='row' spacing={1} justifyContent="center" alignItems="center">
                <KeyboardArrowUpIcon />
                <h5 style={{ 'color': 'black' }}>Balance</h5>
              </Stack>
              :
              <Stack direction='row' spacing={1} justifyContent="center" alignItems="center">
                <KeyboardArrowDownIcon />
                <h5 style={{ 'color': 'black' }}>Balance</h5>
              </Stack>
          }
        </IconButton>
        <Collapse in={openCB} timeout="auto" unmountOnExit>
          <Box>
            <div
              className="rounded-10 p-2"
              style={{ maxHeight: "525px", marginTop: "-12px" }}
            >
              {
                isLoadingCB ?
                  <LoadingSpinner />
                  :
                  <>
                    <div className="mt-1">
                      <TableBalance Data={DataCB} isLoadingBackDrop={isLoadingCB} maxHeight={'525px'} />
                    </div>
                  </>
              }
            </div>
          </Box>
        </Collapse>
      </Grid>
    )
  }

  return (
    <Box className='w-100' sx={{ width: '100%', p: 3 }}>
      <CustomToastContainer />
      <Grid container spacing={0} direction="column">
        <Grid item xs={12}>
          <Grid container item direction="row">
            <Grid item xs={12}>
              <div className="mt-1">
                <h5>Transaction Logs - {transactionNumber}</h5>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <div className='mt-2'>
          <ButtonDataLogs Back={Back} ReloadData={ReloadData} />
        </div>

        <div>
          <GLTable />
        </div>

        <div className='mt-4'>
          <CBTable />
        </div>
      </Grid>

      {
        isLoadingBackDrop && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoadingBackDrop}
          >
            <LoadingSpinner />
          </Backdrop>
        )
      }
    </Box>
  )
})

export default ListLogTransactions