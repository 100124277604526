import { Grid, IconButton, MenuItem, Stack, InputAdornment } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { formatCurrency } from '../../../../../services/ApiService';
import DollarInput, { convertMask } from '../../../../../components/Masks/DollarInput';
import RupiahInput, { convertMaskIDR } from '../../../../../components/Masks/RupiahInput';

import ApiService, { branchId, companyId, countryId, userId, userCode } from '../../../../../services/ApiService';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';
import SelectDataCOA from '../Selects/SelectDataCOA';
import { Icon, Input, PopUp, Selection, showToast } from '../../../../../components/ui';
import { updateDateFormat, defaultDate } from '../../../../../utils/date';
import { convertToNumeric } from '../../../../../utils/masks.jsx';

const ModalBank = (props) => {
    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);

    const paramC = "regChartOfAccount/regChartOfAccount";
    const [isLoadingC, setIsLoadingC] = useState(false);
    const [dataC, setDataC] = useState([]);
    const [dataMapC, setDataMapC] = useState([]);
    const [SelectedDataC, setSelectedDataC] = useState({});
    const [totalRowsC, setTotalRowsC] = useState(50);
    const [columnDataC, setColumnDataC] = useState([]);

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataCOA(1, 50);
        }
        else {
            setIsModal(false);
        }
    };

    const searchAccount = (data) => {
        if (data === 'search') {
            setTitleModal('Chart of Account (COA)');
            handleClick();
        }
        else if (data === 'delete') {
            props.setAccountCode('');
            props.setAccountName('');
        }
    };

    const handleClose = () => {
        emptyState();
        props.handleClick();
    };

    const emptyState = () => {
        props.setAccountCode('');
        props.setAccountName('');
        props.setCheckBG('');
        props.setRemarks('');
        props.setCurrency(1);
        props.setAmount(0);
        props.setDueDate('');
        props.setIsDueDate(false);
    };

    const handleCurrency = (value) => {
        props.setCurrency(value);

        if (value === 0) {
            if (props.TempDiffUSD > 0) {
                props.setAmount(props.TempDiffUSD);
            }
        }
        else {
            if (props.TempDiffIDR > 0) {
                props.setAmount(props.TempDiffIDR);
            }
        }
    }

    const BankTypeData = [
        { id: 1, code: 0, name: 'USD' },
        { id: 2, code: 1, name: 'IDR' },
    ];

    const handleSave = () => {
        if (!props.AccountCode) {
            showToast({ type: 'error', message: 'Please Select Chart of Account!' });
        }
        else {
            if (props.Amount <= 0) {
                showToast({ type: 'error', message: 'Please Input Amount!' });
            }
            else {
                let Sequence = 1;
                let orId = 0;
                let dueDate = updateDateFormat(props.DueDate, true);

                if (props.detailEdit) {
                    Sequence = props.selectedDetail.sequence;
                }
                else {
                    if (props.detailSequence > 0) Sequence = props.detailSequence + 1;
                }

                if (props.officialReceiptId !== undefined || props.officialReceiptId !== null) {
                    orId = props.officialReceiptId;

                    if (orId === undefined) {
                        orId = 0;
                    }
                }
                else {
                    orId = 0;
                }

                let data = {
                    "countryId": countryId,
                    "companyId": companyId,
                    "branchId": branchId,
                    "userId": userId,
                    "userCode": userCode,

                    "rowStatus": "ACT",
                    "orId": orId,
                    "orInvoiceId": props.checkSelectedInvoice.id,
                    "invoiceId": props.checkSelectedInvoice.invoiceId,
                    "invoiceNo": props.checkSelectedInvoice.invoiceNo,
                    "sequence": Sequence,
                    "coaCode": props.AccountCode,
                    "coaName": props.AccountName,
                    "checkBG": props.CheckBG,
                    "remarks": props.Remarks,
                    "amountCrr": parseInt(props.Currency),
                    "amount": convertToNumeric(props.Amount),
                    "dueDate": dueDate,
                    "statusDueDate": props.StatusDueDate,

                    "idLama": 0,
                }

                if (!props.detailEdit) {
                    props.setBankData([...props.BankData, data]);

                    let tempDate = defaultDate;
                    const newArray = props.InvoiceList.slice();
                    newArray.forEach(el => {
                        if (el.invoiceId === props.checkSelectedInvoice.invoiceId) {
                            el.officialReceiptInvoiceBanks = [...props.BankData, data];
                        }

                        if (el.officialReceiptInvoiceBanks.length > 0) {
                            el.officialReceiptInvoiceBanks.forEach(element => {
                                if (new Date(tempDate) < new Date(element.dueDate)) {
                                    tempDate = element.dueDate;
                                }
                            });
                        }
                    });
                    props.setDueDateLast(tempDate);

                    props.setInvoiceList(newArray);

                    props.calculateTotalBank(newArray);
                    props.calculateTotalDifferent(newArray);
                }
                else {
                    const newArr = props.BankData.slice();
                    newArr.forEach(el => {
                        if (el.sequence === data.sequence) {
                            el.coaCode = data.coaCode;
                            el.coaName = data.coaName;
                            el.checkBG = data.checkBG;
                            el.remarks = data.remarks;
                            el.amountCrr = data.amountCrr;
                            el.amount = data.amount;
                            el.dueDate = data.dueDate;
                        }
                    })
                    props.setBankData(newArr);

                    let tempDate = defaultDate;
                    const newArray = props.InvoiceList.slice();
                    newArray.forEach(el => {
                        if (el.invoiceId === props.checkSelectedInvoice.invoiceId) {
                            el.officialReceiptInvoiceBanks = newArr;
                        }

                        if (el.officialReceiptInvoiceBanks.length > 0) {
                            el.officialReceiptInvoiceBanks.forEach(element => {
                                if (new Date(tempDate) < new Date(element.dueDate)) {
                                    tempDate = element.dueDate;
                                }
                            });
                        }
                    });
                    props.setDueDateLast(tempDate);
                    props.setInvoiceList(newArray);
                    props.calculateTotalBank(newArray);
                    props.calculateTotalDifferent(newArray);
                }

                emptyState();
                handleClose();
                props.setDetailEdit(false);
                props.setSelectedDetail({});
            }
        }
    }

    const getDataCOA = (pageNumber, pageSize, filters) => {
        setIsLoadingC(true);
        let params = [];
        ApiService.COAPostBySearch(paramC, pageNumber, pageSize, params, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataC({})
                    setDataC(response.data.data.chartOfAccount);

                    let temp = response.data.data.chartOfAccount
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapC(indexed)
                    setColumnDataC(response.data.data.columns)
                    setTotalRowsC(response.data.totalRowCount)

                    setIsLoadingC(false)
                }
                setIsLoadingC(false)
            })
            .catch(function (error) {
                setIsLoadingC(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const mainContainer = (
        <>
            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Stack direction={'row'} spacing={'16px'}>
                        <Input
                            label={'Account Code'}
                            value={props.AccountCode}
                            disabled
                            fullWidth
                            formControlProps={{
                                sx: {
                                    width: '50%',
                                }
                            }}
                        />

                        <Input
                            value={props.AccountName}
                            disabled
                            fullWidth
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                }
                            }}
                            inputBaseProps={{
                                sx: {
                                    marginTop: '23px !important',
                                },
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => searchAccount('delete')}
                                            hidden={props.AccountName && props.Printing === 0 ? false : true}
                                            disabled={props.isViewOnly}
                                        >
                                            <Icon
                                                iconName={'close'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>

                                        <IconButton
                                            onClick={() => searchAccount('search')}
                                            disabled={props.isViewOnly}
                                            hidden={props.Printing > 0}
                                        >
                                            <Icon
                                                iconName={'search'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Stack>
                </Grid>

                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Input
                        label="Check BG"
                        value={props.CheckBG}
                        onChange={(e) => props.setCheckBG(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                        disabled={props.Printing > 0}
                    />
                </Grid>
            </Grid>

            <Input
                label={'Remarks'}
                value={props.Remarks}
                onChange={(e) => props.setRemarks(e.target.value)}
                multiline
                fullWidth
                disabled={props.Printing > 0}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                inputBaseProps={{
                    rows: 4,
                    sx: {
                        padding: '8px',
                    },
                }}
            />

            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={8}
                >
                    <Selection
                        id='0'
                        label={'Currency'}
                        fullWidth={true}
                        placeholder={'Select Currency'}
                        value={props.Currency}
                        onChange={(e) => handleCurrency(e.target.value)}
                        formControlProps={{
                            sx: {
                                marginTop: '16px !important'
                            }
                        }}
                        disabled={props.isViewOnly || props.Printing > 0}
                    >
                        {
                            BankTypeData.map((data, index) => (
                                <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                            ))
                        }
                    </Selection>
                </Grid>

                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={8}
                >
                    <Input
                        label="Amount"
                        textAlign='right'
                        fullWidth
                        value={props.Amount}
                        disabled={props.Printing > 0}
                        onChange={(e) => { props.setAmount(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1)) }}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        inputBaseProps={{
                            inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={8}
                >
                    <Input
                        label='Due Date'
                        type={'date'}
                        value={props.DueDate}
                        onChange={(e) => props.setDueDate(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        disabled={props.Printing > 0}
                    />
                </Grid>
            </Grid>
        </>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={props.titleModal}
                isOpen={props.isModal}
                onClose={handleClose}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={'lg'}
                fullWidth={true}
                topButton={false}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}

                    <div style={{ marginTop: '48px' }}>
                        {CustomizeButtonComponent.SaveCancelModalButton(handleSave, false, (props.Printing > 0 ? true : false))}
                    </div>
                </div>
            </PopUp>
            
            {
                isModal && (
                    <SelectDataCOA
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}
                        getData={getDataCOA}
                        isLoading={isLoadingC}
                        totalRows={totalRowsC}
                        setTotalRows={setTotalRowsC}
                        SelectedData={SelectedDataC}
                        setSelectedData={setSelectedDataC}
                        data={dataC}
                        columnData={columnDataC}
                        dataMap={dataMapC}
                        setAccountCode={props.setAccountCode}
                        setAccountName={props.setAccountName}
                    />
                )
            }
        </>
    )
}

export default ModalBank;