import React, { useState, useRef } from 'react';
import '../../../Styles/notFound.css';

const YourComponent = () => {
  const text = "PAGE NOT FOUND";
  const letters = text.split("");
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const letterRefs = useRef([]);

  const handleMouseMove = (e) => {
    setMousePosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };

  const isMouseWithinRadius = (letterIndex) => {
    if (isMouseOver) {
      const letterRef = letterRefs.current[letterIndex];
      if (letterRef) {
        const rect = letterRef.getBoundingClientRect();
        const centerX = rect.left + rect.width / 2;
        const centerY = rect.top + rect.height / 2;
        const distance = Math.sqrt((mousePosition.x - centerX) ** 2 + (mousePosition.y - centerY) ** 2);
        return distance <= 55;
      }
    }
    return false;
  };

  return (
    <div
      className="highlight"
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {letters.map((letter, index) => (
        <span
          key={index}
          ref={(element) => (letterRefs.current[index] = element)}
          className={`highlight-letter ${isMouseWithinRadius(index) ? 'highlighted' : ''}`}
        >
          {letter}
        </span>
      ))}
    </div>
  );
};

export default YourComponent;
