import React, { useState, useEffect } from 'react';
import ApiService from '../../../services/ApiService';
import { useNavigate } from 'react-router-dom';
import { Grid, Stack, Box, Backdrop } from '@mui/material';
import ButtonComponent from '../../../components/Buttons/ButtonComponent';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import TableX from '../../../components/Tables/TableX';
import Paging from '../../../components/Tables/Paging';
import ButtonRegColumnSettings from './Buttons/ButtonRegColumnSettings';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import CustomToastContainer, { showToast } from '../../../components/ui';

const RegColumnSettingsList = forwardRef((props, ref) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
  const history = useNavigate();
  const param = 'permission/permission';
  const paramUR = 'regUserRole/regUserRole';
  const [data, setData] = useState([]);
  const [rowsCount, setRowsCount] = useState(50);
  const [numPage, setNumPage] = useState(1);
  const [filter, setFilter] = useState([]);
  const [filterBy, setFilterBy] = useState([]);
  const [stateX, setStateX] = useState({});
  const [sortArr, setSortArr] = useState([])
  const [goTo, setGoTo] = useState(1);

  const [isLoading2, setIsLoading2] = useState(false);
  const paramRegUserBranch = 'regUserBranch/regUserBranch';
  // const [SettingsId, setSettingsId] = useState(0);
  const [data2, setData2] = useState([]);
  const [rowsCount2, setRowsCount2] = useState(50)
  const [numPage2, setNumPage2] = useState(1)
  const [dataMap2, setDataMap2] = useState([])
  const [columnData2, setColumnData2] = useState([])
  const [SelectedData2, setSelectedData2] = useState({});
  const [totalRows2, setTotalRows2] = useState(50);
  const [filter2, setFilter2] = useState([]);
  const [filterBy2, setFilterBy2] = useState([]);
  const [stateX2, setStateX2] = useState({});
  const [sortArr2, setSortArr2] = useState([])
  const [goTo2, setGoTo2] = useState(1);

  const [dataMap, setDataMap] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [SelectedData, setSelectedData] = useState({});
  const [totalRows, setTotalRows] = useState(50);

  const paramRegUserAccess = "regUserAccess/regUserAccess";
  const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

  let countRefresh = 0;

  useImperativeHandle(ref, () => ({
    localSave() {

    }
  }));

  useEffect(() => {
    if (countRefresh === 0) {
      document.title = 'Reg Column Settings - ' + JSON.parse(localStorage.getItem('branchName'))
      ApiService.refreshToken();
      countRefresh++;

      getDataAccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = (pageNumber, pageSize, filters) => {
    if (isLoadingBackDrop === false) {
      setIsLoading(true);
    } else {
      setIsLoadingBackDrop(true);
    }

    let params = [];
    ApiService.IdentityPostByPage(param, 'RUA', pageNumber, pageSize, params, filters)
      .then((response) => {
        if (response.data.code === 200) {
          setSelectedData({})
          setData(response.data.data.permission);

          let temp = response.data.data.permission
          let indexed = temp.map((el, index) => {
            let indexedTemp = {
              ...el,
              index
            }
            return indexedTemp
          })

          setDataMap(indexed)
          setColumnData(response.data.data.columns)
          setTotalRows(response.data.totalRowCount)

          setIsLoading(false)
          setIsLoadingBackDrop(false)
        }
        else {
          setIsLoadingBackDrop(false)
          showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + response.data.message });
        }
        setIsLoading(false)
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsLoadingBackDrop(false)
        showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
      })
  }

  const getData2 = (pageNumber, pageSize, filters) => {
    let params = [{ 'attr': 'permissionId', 'value': '' + JSON.parse(localStorage.getItem("pId")) }];
    setIsLoading2(true);
    ApiService.IdentityPostByPage(paramRegUserBranch, 'PAGE', pageNumber, pageSize, params, filters)
      .then((response) => {
        if (response.data.code === 200) {
          setSelectedData2({})
          setData2(response.data.data.userBranch);

          let temp = response.data.data.userBranch
          let indexed = temp.map((el, index) => {
            let indexedTemp = {
              ...el,
              index
            }
            return indexedTemp
          })

          setDataMap2(indexed)
          setColumnData2(response.data.data.columns)
          setTotalRows2(response.data.totalRowCount)

          setIsLoading2(false)
        }
        setIsLoading2(false)
      })
      .catch(function (error) {
        setIsLoading2(false)
        console.error('error saat fetch', error)
        showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
      })
  }

  const getDataRegUserRole = (uId, bId, cId) => {
    // setIsLoadingM(true);
    let params = [];
    params = [
      ...params,
      { 'attr': 'userId', 'value': '' + uId },
      { 'attr': 'branchId', 'value': '' + bId },
      { 'attr': 'companyId', 'value': '' + cId }
    ]
    ApiService.IdentityPostByPage(paramUR, 'PAGE', 1, 100, params, [])
      .then((response) => {
        if (response.data.code === 200) {
          // setDataRegUserRole(response.data.data.userRole);
        }
        // setIsLoadingM(false);
      })
      .catch(function (error) {
        // console.error('error saat fetch', error);
        // setIsLoadingM(false);
        showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
      })
  }

  const getDataAccess = () => {
    let params = [];
    let pId = 0;
    let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
    let hasAccess = false

    const indexMenu = listMenu.findIndex((elm) => elm.code === 'IRUR')
    if (indexMenu !== -1) {
      pId = listMenu[indexMenu].id
      hasAccess = true
    }

    if (hasAccess) {
      params = [
        ...params,
        { attr: "permissionId", value: "" + pId },
        {
          attr: "userId",
          value: "" + JSON.parse(localStorage.getItem("userId"))
        },
        { attr: "roleId", value: "" + JSON.parse(localStorage.getItem("roleId")) }
      ];
      ApiService.IdentityPostByPage(
        paramRegUserAccess,
        "PAGE",
        1,
        100,
        params,
        []
      )
        .then((response) => {
          if (response.data.code === 200) {
            setDataRegUserAccess(response.data.data.userAccess);

            getData(1, 50);
          }
        })
        .catch(function (error) {
          console.error("error saat fetch", error);
          history('/Infoss');
        });
    }
    else {
      history('/Infoss');
    }
  };

  const handleReset = () => {
    emptyState();

    setNumPage2(1);
    setRowsCount2(50);
    setColumnData2([]);
    setTotalRows2(50);
    setFilter2([]);
    setFilterBy2([]);
    setData2([]);
    setDataMap2([]);
    localStorage.removeItem('uId');

    getData(1, 50);
  };

  const handleReset2 = () => {
    setNumPage2(1);
    setRowsCount2(50);
    setTotalRows2(50);
    setFilter2([]);
    setFilterBy2([]);
    getData2(1, 50, []);
  }

  const emptyState = () => {
    setNumPage(1);
    setRowsCount(50);
    setGoTo(1);
    setTotalRows(50);
    setFilter([]);
    setFilterBy([]);
    setStateX({});
    setSortArr([]);
  };

  const ReloadData = () => {
    showToast({ type: 'info', message: 'Reloading Data' })
    emptyState();

    setNumPage2(1);
    setRowsCount2(50);
    setColumnData2([]);
    setTotalRows2(50);
    setFilter2([]);
    setFilterBy2([]);
    setData2([]);
    setDataMap2([]);
    localStorage.removeItem('uId');

    getData(1, 50, []);
  };

  function Add() {
    if (SelectedData2.id === undefined) {
      showToast({ type: 'error', message: 'Please Select Data!' });
    }
    else {
      let check = 0;
      for (let i = 0; i < DataRegUserAccess.length; i++) {
        if (DataRegUserAccess[i].accessCode === 'REI') {
          check = 1;
        }
      }

      if (check === 1) {
        getDataRegUserRole(JSON.parse(localStorage.getItem("uId")), SelectedData2.branchId, SelectedData2.companyId);
        handleClick();
      }
      else {
        showToast({ type: 'error', message: 'You Don\'t Have Access To This Menu' });
      }
    }
  }

  function handleGetData(data) {
    setSelectedData(data);
    localStorage.setItem("pId", JSON.stringify(data.id));
    getData2(1, 50);
  }

  function CellDoubleClick(row) {
    handleGetData(row);
  };

  function CellDoubleClick2(row) {
    Add();
  };

  const handleClick = () => {

  };

  const MainContainer1 = () => {
    return (
      <>
        <Grid item xs={12} style={{ 'width': '42vw', 'borderRadius': '5px' }}>
          <div className='border rounded-10 table-responsive' style={{ maxHeight: '550px' }}>
            {
              isLoading ?
                <LoadingSpinner />
                :
                <>
                  <Box className='p-4'>
                    {
                      ButtonComponent.RefreshButton(
                        sortArr,
                        rowsCount,
                        columnData,
                        setGoTo,
                        handleReset,
                        setFilterBy,
                        filterBy,
                        setFilter,
                        setNumPage,
                        setRowsCount,
                        getData,
                        stateX,
                        setStateX
                      )
                    }

                    <div className='mt-1'>
                      <TableX
                        uniqueKey={'id'}
                        goTo={goTo}
                        rowsCount={rowsCount}
                        setNumPage={setNumPage}
                        setRowsCount={setRowsCount}
                        getData={getData}
                        columnData={columnData}
                        dataMap={dataMap}
                        SelectedData={SelectedData}
                        setSelectedData={setSelectedData}
                        setFilter={setFilter}
                        setFilterBy={setFilterBy}
                        handleGetData={handleGetData}
                        CellDoubleClick={CellDoubleClick}
                        stateX={stateX}
                        setStateX={setStateX}
                        sortArr={sortArr}
                        setSortArr={setSortArr}
                        setGoTo={setGoTo}
                      />
                    </div>
                  </Box>
                </>
            }
          </div>
          <Paging
            setRowsCount={setRowsCount}
            setNumPage={setNumPage}
            getData={getData}
            rowsCount={rowsCount}
            numPage={numPage}
            data={data}
            totalRows={totalRows}
            filter={filter}
            sortArr={sortArr}
            goTo={goTo}
            setGoTo={setGoTo}
          />
        </Grid>
      </>
    )
  }

  const MainContainer2 = () => {
    return (
      <>
        <Grid item xs={12} style={{ 'width': '42vw', 'borderRadius': '5px' }}>
          <div className='border rounded-10 table-responsive' style={{ maxHeight: '550px' }}>
            {
              isLoading2 ?
                <LoadingSpinner />
                :
                <>
                  <Box className='p-4'>
                    {
                      ButtonComponent.RefreshButton(
                        sortArr,
                        rowsCount,
                        columnData,
                        setGoTo,
                        handleReset2,
                        setFilterBy2,
                        filterBy2,
                        setFilter2,
                        setNumPage2,
                        setRowsCount2,
                        getData2,
                        stateX2,
                        setStateX2
                      )
                    }

                    <div className='mt-1'>
                      <TableX
                        uniqueKey={'id'}
                        goTo={goTo}
                        rowsCount={rowsCount2}
                        setNumPage={setNumPage2}
                        setRowsCount={setRowsCount2}
                        getData={getData2}
                        columnData={columnData2}
                        dataMap={dataMap2}
                        SelectedData={SelectedData2}
                        setSelectedData={setSelectedData2}
                        setFilter={setFilter2}
                        setFilterBy={setFilterBy2}
                        CellDoubleClick={CellDoubleClick2}
                        stateX={stateX2}
                        setStateX={setStateX2}
                        sortArr={sortArr2}
                        setSortArr={setSortArr2}
                        setGoTo={setGoTo2}
                      />
                    </div>
                  </Box>
                </>
            }
          </div>
          <Paging
            setRowsCount={setRowsCount2}
            setNumPage={setNumPage2}
            getData={getData2}
            rowsCount={rowsCount2}
            numPage={numPage2}
            data={data2}
            totalRows={totalRows2}
            filter={filter2}
            sortArr={sortArr2}
            goTo={goTo2}
            setGoTo={setGoTo2}
          />
        </Grid>
      </>
    )
  }

  return (
    <>
      <Box className='w-100' sx={{ width: '100%', p: 3 }}>
        <CustomToastContainer />
        <Grid container spacing={0} direction="column">
          <Grid item xs={12}>
            <Stack spacing={2} direction="row">
              <div className='mt-1'>
                <h5>Column Settings</h5>
              </div>
            </Stack>
          </Grid>

          <div className='mt-2'>
            <ButtonRegColumnSettings
              Add={Add} ReloadData={ReloadData}
              DataRegUserAccess={DataRegUserAccess}
            />
          </div>

          <div className='mt-2'>
            <Stack spacing={2} direction='row'>
              <MainContainer1 />
              <MainContainer2 />
            </Stack>
          </div>
        </Grid>

        {
          isLoadingBackDrop && (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoadingBackDrop}
            >
              <LoadingSpinner />
            </Backdrop>
          )
        }
      </Box>
    </>
  )
})

export default RegColumnSettingsList
