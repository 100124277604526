export const defaultDate = '1785-12-06T10:51:29.426Z';
export const nullDate = '1-01-01';
export const currentDate = new Date();

export function getDefaultDateFormat(date) {
    return new Date(date).toLocaleDateString("sv-se").split('T')[0];
}

export function setDateFormat(date, setDate, isDefault = false, isToday = false) {
    if (date === '') {
        setDate(getDefaultDateFormat(defaultDate));
    }
    else {
        if (getDefaultDateFormat(date) !== getDefaultDateFormat(defaultDate) && getDefaultDateFormat(date) !== nullDate) {
            setDate(getDefaultDateFormat(date));
        }
        else {
            if (!isDefault && !isToday) {
                setDate('');
            }
            else {
                if (isDefault) {
                    setDate(getDefaultDateFormat(defaultDate));
                }

                if (isToday) {
                    setDate(getDefaultDateFormat(new Date()));
                }
            }
        }
    }
}

export function updateDateFormat(date, isDefault = false, isToday = false) {
    if (date === '') {
        return getDefaultDateFormat(defaultDate);
    }
    else {
        if (getDefaultDateFormat(date) !== getDefaultDateFormat(defaultDate) && getDefaultDateFormat(date) !== nullDate) {
            return getDefaultDateFormat(date);
        }
        else {
            if (!isDefault && !isToday) {
                return '';
            }
            else {
                if (isDefault) {
                    return getDefaultDateFormat(defaultDate);
                }

                if (isToday) {
                    return getDefaultDateFormat(new Date());
                }
            }
        }
    }
}