import { Collapse, Grid, IconButton, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';

import TableNoFilter from './TableNoFilter';
import ApiService from '../../services/ApiService';
import TableBalance from './TableBalance';
import { Icon, showToast } from '../ui';
import { defaultDate } from '../../utils/date';

const BodyComponent = (props) => {
  const [openChild, setOpenChild] = useState(props.el.isDelivered);
  const transactionNo = props.source !== 'TR' && props.source !== 'TP'? props.el.reference : props.source === 'TR'? props.el.trNo2 : props.el.tpNo2;
  const screenWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  const customScreenWidth = screenWidth - (screenWidth * 7.5 / 100);
  const maxHeight = '250px';
  
  const [openGL, setOpenGL] = useState(true);
  const [openCB, setOpenCB] = useState(false);

  const [dataMapGL, setDataMapGL] = useState([]);
  const [sortArrGL, setSortArrGL] = useState([]);
  const [rowsCountGL, setRowsCountGL] = useState(50);
  const [columnDataGL, setColumnDataGL] = useState([]);
  const [SelectedDataGL, setSelectedDataGL] = useState({});

  const [DataCB, setDataCB] = useState([]);

  function handleOpen(rData) 
  {
    let id = 0;

    if(props.source === 'INV')
    {
      id = rData.id;
    }
    else if(props.source === 'PR')
    {
      id = rData.id;
    }
    else if(props.source === 'OR')
    {
      id = rData.orId;
    }
    else if(props.source === 'PV')
    {
      id = rData.pvId;
    }
    else if(props.source === 'RV')
    {
      id = rData.rvId;
    }
    else if(props.source === 'TP')
    {
      id = rData.tpId;
    }
    else if(props.source === 'TR')
    {
      id = rData.trId;
    }
    else if(props.source === 'ME')
    {
      id = rData.id;
    }

    if (!openChild === true) 
    {
      props.setIsLoadingBackDrop(true);
      let params = [
        {
          "attr": "refId",
          "value": "" + id
        },
        {
          'attr': 'source',
          'value': '' + props.source
        }
      ];

      ApiService.FinAccPostByPage('generalLedger/generalLedger', 'PAGE', 1, 50, params, [])
        .then((response) => {
          if (response.data.code === 200) 
          {
            setSelectedDataGL({})

            let temp = response.data.data.generalLedger
            let indexed = temp.map((el, index) => {
              let indexedTemp = {
                ...el,
                index
              }
              return indexedTemp
            })

            setDataMapGL(indexed)
            setColumnDataGL(response.data.data.columns)

            let date = new Date(rData.printedOn);
            let formatDate = date.toLocaleDateString("en-US");
            ApiService.RegClosingPeriodPostById('regClosingPeriod/regClosingPeriod', formatDate)
              .then((response) => {
                if(response.data.code === 200)
                {
                  let data = response.data.data.closingPeriod;
                
                  let params = [
                    {
                      "attr": "refId",
                      "value": "" + id
                    },
                    {
                      'attr': 'source',
                      'value': '' + props.source
                    },
                    {
                      'attr': 'period',
                      'value': '' + data.period
                    },
                    {
                      'attr': 'yearPeriod',
                      'value': '' + data.yearPeriod
                    }
                  ];
          
                  ApiService.FinAccPostByPage('closingBalance/closingBalance', 'LOG', 1, 50, params, [])
                  .then((response) => {
                    if (response.data.code === 200) {
                      let temp = response.data.data.closingBalances
                      let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                          ...el,
                          index
                        }
                        return indexedTemp
                      })
            
                      setDataCB(indexed);

                      setOpenChild(!openChild);

                      let data = JSON.parse(localStorage.getItem(props.localVar));
                      handleChild(openChild, rData, data);
                      localStorage.setItem(props.localVar, JSON.stringify(data));
                      props.setDataMap(data);

                      props.setIsLoadingBackDrop(false);
                    }
                    else if(response.data.code === 500)
                    {
                      showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });

                      setOpenChild(!openChild);

                      let data = JSON.parse(localStorage.getItem(props.localVar));
                      handleChild(openChild, rData, data);
                      localStorage.setItem(props.localVar, JSON.stringify(data));
                      props.setDataMap(data);

                      props.setIsLoadingBackDrop(false);
                    }
                  })
                  .catch(function (error) {
                    console.error('error saat fetch', error)
                    // Alert.ErrorAlert(error.toString(), true)
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error.toString() });

                    setOpenChild(!openChild);
          
                    let data = JSON.parse(localStorage.getItem(props.localVar));
                    handleChild(openChild, rData, data);
                    localStorage.setItem(props.localVar, JSON.stringify(data));
                    props.setDataMap(data);
          
                    props.setIsLoadingBackDrop(false);
                  })
                }
              })
              .catch(function (error) {
                // console.error('error saat fetch', error);

                setOpenChild(!openChild);
      
                let data = JSON.parse(localStorage.getItem(props.localVar));
                handleChild(openChild, rData, data);
                localStorage.setItem(props.localVar, JSON.stringify(data));
                props.setDataMap(data);
      
                props.setIsLoadingBackDrop(false);
              })
          }
          else if (response.data.code === 500) 
          {
            showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });
            
            setOpenChild(!openChild);

            let data = JSON.parse(localStorage.getItem(props.localVar));
            handleChild(openChild, rData, data);
            localStorage.setItem(props.localVar, JSON.stringify(data));
            props.setDataMap(data);

            props.setIsLoadingBackDrop(false);
          }
        })
        .catch(function (error) {
          console.error('error saat fetch', error);
          showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error.toString() });

          setOpenChild(!openChild);

          let data = JSON.parse(localStorage.getItem(props.localVar));
          handleChild(openChild, rData, data);
          localStorage.setItem(props.localVar, JSON.stringify(data));
          props.setDataMap(data);

          props.setIsLoadingBackDrop(false);
        })
    }
    else 
    {
      props.setIsLoadingBackDrop(false);

      let data = JSON.parse(localStorage.getItem(props.localVar));
      handleChild(openChild, rData, data);
      localStorage.setItem(props.localVar, JSON.stringify(data));
      props.setDataMap(data);

      setOpenChild(!openChild);
    }
  }

  function handleChild(openChild, rData, data) 
  {
    data.forEach(element => {
      if (element.id === rData.id) 
      {
        element.isDelivered = !openChild;
      }
    });
  }

  return (
    <>
      <tr
        key={props.index}
        onClick={() => { props.handleClick(props.el); }}
        className={props.tempStyle}
        onDoubleClick={() => props.handleDoubleClick(props.el)}
        style={{ 'cursor': 'pointer', 'fontSize': '12px', }}
      >
        {
          props.el.isPosted && props.el.rowStatus !== 'DEL' ?
            <td style={{ verticalAlign: 'middle' }}>
              <IconButton
                aria-label="expand row"
                size='small'
                onClick={ () => handleOpen(props.el) }
              >
                {
                  openChild ?
                    <Icon 
                      iconName={'arrow-up'}
                      svgIconProps={{
                        sx: {
                          width: '12px',
                          height: '12px',
                        }
                      }}
                    />
                  :
                    <Icon
                      iconName={'arrow-down'}
                      svgIconProps={{
                        sx: {
                          width: '12px',
                          height: '12px',
                        }
                      }}
                    />
                }
              </IconButton>
            </td>
          :
            <td />
        }

        {
          props.columnData.map((headersEl, indexHeaders) => {
            let temp = props.el[headersEl.column];

            // let optionsDate = {
            //     year: "numeric",
            //     month: "short",
            //     day: "2-digit",
            // }

            if (headersEl.dataType === 'datetime' || headersEl.dataType === 'date') {
                if (props.el[headersEl.column] !== "0001-01-01T00:00:00" && new Date(props.el[headersEl.column]).getFullYear() !== new Date(defaultDate).getFullYear()) {
                    let tempDate = new Date(props.el[headersEl.column]);
                    var dateTemp = tempDate.toLocaleDateString("en-US", { day: "2-digit" });
                    var monthTemp = tempDate.toLocaleDateString("en-US", { month: "short" });
                    var yearTemp = tempDate.toLocaleDateString("en-US", { year: "numeric" });
                    temp = dateTemp + ' ' + monthTemp + ' ' + yearTemp;
                } else {
                    temp = '-'
                }
            }

            if (headersEl.dataType === 'currency') 
            {
              let tempNumber = new Intl.NumberFormat(JSON.parse(localStorage.getItem("currency")), { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(`${props.el[headersEl.column]}`);
              temp = tempNumber;
            }

            if (props.el[headersEl.column] === true) 
            {
              temp = 'Yes'
            }
            else if (props.el[headersEl.column] === false) 
            {
              temp = 'No'
            }

            return (
              <td 
                key={indexHeaders} 
                hidden={headersEl.hidden} 
                align={headersEl.textAlign} 
                style={{ 
                  verticalAlign: headersEl.verticalAlign, 
                  padding: '8px 10px',
                  "whiteSpace": "nowrap",
                  // maxWidth: '150px'
                }}
              >
                {
                  headersEl.column === 'rowStatus'?
                    <div
                      style={{
                        display: 'flex',
                        width: 'fit-content',
                        padding: '4px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '32px',
                        background: temp === 'ACT'? 'var(--Green-Button, #168444)' : 'var(--Red-Alert, #D20C0C)',
                      }}
                    >
                      <Icon 
                        iconName={temp === 'ACT'? 'active' : 'inactive'}
                        svgIconProps={{
                          fontSize: 'small',
                          htmlColor: '#EAEAEA',
                          sx: { 
                            height: '12px', 
                            width: '12px' 
                          }
                        }}
                      />
                    </div>
                  :
                    <Typography
                      sx={{
                        color: 'var(--Blue-Primary-1, #083A50)',
                        fontFamily: 'Nunito',
                        fontSize: '11px !important',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        maxWidth: 'fit-content'
                      }}
                    >
                      {temp}
                    </Typography>
                }
              </td>
            )
          })
        }
      </tr>

      {
        openChild ?
          <tr className='row-hover-disabled'>
            <td colSpan="100%" style={{ borderTopWidth: '1.75px', borderBottomWidth: '1.75px' }}>
              <Grid style={{ 'width': customScreenWidth + 'px', 'borderRadius': '5px', marginLeft: '4.25px' }}>
                <div className='mt-2'>
                  <IconButton
                    aria-label="expand row"
                    size="large"
                    onClick={() => setOpenGL(!openGL)}
                    disableFocusRipple = {true}
                    disableRipple = {true}
                    className='mt-1'
                  >
                    {
                      openGL?
                        <Stack direction='row' spacing = {1} justifyContent="center" alignItems="center">
                          <Icon 
                            iconName={'arrow-up'}
                            svgIconProps={{
                              sx: {
                                width: '12px',
                                height: '12px',
                              }
                            }}
                          />
                          <p style = {{'color': 'black', 'fontSize': '1.2rem', 'fontWeight': '425' }}>General Ledger - {transactionNo}</p>
                        </Stack>
                      :
                        <Stack direction='row' spacing = {1} justifyContent="center" alignItems="center">
                          <Icon 
                            iconName={'arrow-down'}
                            svgIconProps={{
                              sx: {
                                width: '12px',
                                height: '12px',
                              }
                            }}
                          />
                          <p style = {{'color': 'black', 'fontSize': '1.2rem', 'fontWeight': '425' }}>General Ledger - {transactionNo}</p>
                        </Stack>
                    }
                  </IconButton>

                  <Collapse in={openGL} timeout="auto" unmountOnExit style={{transitionDuration: '0ms !important'}}>
                    <Grid item xs={12} style={{ 'borderRadius': '5px' }}>
                      <div className='rounded-10 table-responsive' style={{ 'maxHeight': '350px', 'marginTop': '-10px', marginLeft: '30px' }}>
                        <div className='mt-4'>
                          <TableNoFilter
                            rowsCount={rowsCountGL}
                            setNumPage={1} setRowsCount={setRowsCountGL} getData={null}
                            columnData={columnDataGL} dataMap={dataMapGL} SelectedData={SelectedDataGL}
                            setSelectedData={setSelectedDataGL} sortArr={sortArrGL} setSortArr={setSortArrGL}
                            setGoTo={1}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Collapse>
                </div>

                <hr 
                  className='mt-3 mb-3' 
                  style={{ 
                    marginLeft: '30px',
                    borderWidth: '1.75px',
                    color: 'inherit !important',
                    marginTop: '0px !important',
                    marginRight: '0px !important',
                    marginBottom: '0px !important',
                    borderColor: 'inherit !important',
                    backgroundColor: 'inherit !important',
                  }} 
                />

                <div className='mb-3 mt-3'>
                  <IconButton
                    aria-label="expand row"
                    size="large"
                    onClick={() => setOpenCB(!openCB)}
                    disableFocusRipple = {true}
                    disableRipple = {true}
                  >
                    {
                      openCB?
                        <Stack direction='row' spacing = {1} justifyContent="center" alignItems="center">
                          <Icon 
                            iconName={'arrow-up'}
                            svgIconProps={{
                              sx: {
                                width: '12px',
                                height: '12px',
                              }
                            }}
                          />
                          <p style = {{'color': 'black', 'fontSize': '1.2rem', 'fontWeight': '425' }}>Balance - {transactionNo}</p>
                        </Stack>
                      :
                        <Stack direction='row' spacing = {1} justifyContent="center" alignItems="center">
                          <Icon 
                            iconName={'arrow-down'}
                            svgIconProps={{
                              sx: {
                                width: '12px',
                                height: '12px',
                              }
                            }}
                          />
                          <p style = {{'color': 'black', 'fontSize': '1.2rem', 'fontWeight': '425' }}>Balance - {transactionNo}</p>
                        </Stack>
                    }
                  </IconButton>

                  <Collapse in={openCB} timeout="auto" unmountOnExit>
                    <div className="rounded-10 mt-2 mb-1" style={{ maxHeight: maxHeight, marginLeft: '23.5px' }}>
                      <div className="mt-2">
                        <TableBalance Data = {DataCB} isLoadingBackDrop = {null} maxHeight = {maxHeight} />
                      </div>
                    </div>
                  </Collapse>
                </div>
              </Grid>
            </td>
          </tr>
        :
          <></>
      }
    </>
  )
}

export default BodyComponent