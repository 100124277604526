import React from 'react';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import { Grid } from '@mui/material';

const ButtonCreateEditMemorial = ({
    check,
    Save,
    verifyAcc,
    DataRegUserAccess,
    isEdit,
    Print,
    PrintExcel,
    AddNew
}) => {

    const renderButtonByAccessCode = (accessCode) => {
        switch (accessCode) {
            case 'PRI':
                return isEdit && (
                    <>
                        {ButtonComponent.PrintButton(Print, '', true)}
                        {ButtonComponent.ExcelButton(PrintExcel, 'Print Excel', false, true)}
                    </>
                );
            case 'CRE':
                return isEdit && ButtonComponent.AddButton(AddNew, 'New', false, false, true);
            default:
                return null;
        }
    };

    return (
        <div className="row" style={{ marginTop: '16px' }}>
            <div className="col-12">
                <Grid
                    container
                    item
                    justifyContent="right"
                    direction="row"
                    style={{ maxWidth: "100vw", gap: "12px" }}
                >
                    {check !== 0 && ButtonComponent.SaveButton(Save, false, verifyAcc, true)}

                    {DataRegUserAccess.map((data) => (
                        <React.Fragment key={data.accessId}>
                            {renderButtonByAccessCode(data.accessCode)}
                        </React.Fragment>
                    ))}
                </Grid>
            </div>
        </div>
    );
};

export default ButtonCreateEditMemorial;