import { Box, Stack, Grid, MenuItem, Typography } from '@mui/material';
import ButtonComponent from './ButtonComponent';
import { Icon, Selection } from '../ui';
import React from 'react';

class CustomizeButtonComponent
{
    AddDeleteTabsButton(Add, Delete, isDisabledAdd, isDisabledDelete)
    {
        return (
            <Stack spacing={'10px'} direction='row'>
                {ButtonComponent.AddButton(Add, '', isDisabledAdd)}
                {ButtonComponent.DeleteButton(Delete, '', isDisabledDelete)}
            </Stack>
        )
    }

    AddEditDeleteTabsButton(Add, Edit, Delete, isDisabled)
    {
        return (
            <Stack spacing={'10px'} direction='row'>
                {ButtonComponent.AddButton(Add, '', isDisabled)}
                {ButtonComponent.EditButton(Edit, '', isDisabled)}
                {ButtonComponent.DeleteButton(Delete, '', isDisabled)}
            </Stack>
        )
    }

    AddEditDeleteUploadTabsButton(Add, Edit, Delete, Upload, isDisabled)
    {
        return (
            <Stack spacing={'10px'} direction='row'>
                {ButtonComponent.AddButton(Add, '', isDisabled)}
                {ButtonComponent.EditButton(Edit, '', isDisabled)}
                {ButtonComponent.DeleteButton(Delete, '', isDisabled)}
                {/* {ButtonComponent.UploadButton(Upload, isDisabled)} */}
            </Stack>
        )
    }

    AddDeletePPH23Button(Add, Delete, PPH23, disabled)
    {
        return(
            <Stack spacing={'10px'} direction='row'>
                {ButtonComponent.AddButton(Add, '', disabled)}
                {ButtonComponent.DeleteButton(Delete, '', disabled)}
                {ButtonComponent.PPH23Button(PPH23, disabled)}
            </Stack>
        )
    }

    SaveCancelModalButton(handleSave, isDisabledSave, isHiddenSave)
    {
        return (
            <Box display="flex" alignItems="center">
                <Grid container item direction="row">
                    <Grid item xs={12} className='text-end' display={'flex'} justifyContent={'flex-end'} sx={{ gap: '10px' }}>
                        {ButtonComponent.SaveButton(handleSave, isDisabledSave, isHiddenSave, true)}
                    </Grid>
                </Grid>
            </Box>
        )
    }

    PrintCancelModalButton(handlePrint, handleClose)
    {
        return (
            <Box display="flex" alignItems="center" justifyContent="left" className = 'mt-2'>
                <Stack spacing={'10px'} direction="row">
                    {ButtonComponent.PrintButton(handlePrint)}
                    {ButtonComponent.CancelButton(handleClose)}
                </Stack>
            </Box>
        )
    }
    
    SelectModalButton(Select)
    {
        return (
            <Box display="flex" alignItems="center">
                <Grid container item direction="row">
                    <Grid item xs={12} className='text-end' display={'flex'} justifyContent={'flex-end'} sx={{ gap: '10px' }}>
                        {ButtonComponent.SelectButton(Select, true)}
                    </Grid>
                </Grid>
            </Box>
        )
    }

    SaveCancelMasterModal(handleSave, isDisabled = false)
    {
        return (
            <Box display="flex" alignItems="center" justifyContent="right" className='mt-4 text-end'>
                <Stack spacing={'10px'} direction="row">
                    {ButtonComponent.SaveButton(handleSave, isDisabled, false, true)}
                </Stack>
            </Box>
        )
    }

    DropDownButtonPrintSA(type, action, PrintSA, PrintSAD)
    {
        return (
            <>
                <Selection
                    id='0'
                    label={''} 
                    value={type}
                    onChange={(e) => action(e.target.value)}
                    selectBaseProps={{
                        SelectDisplayProps: {
                            style: {
                                padding: '0px 32px 0px 0px', 
                            },
                        },
                        sx: {
                            marginTop: '0px !important',
                            padding: '10px 24px !important',
                            maxHeight: '32px !important',
                            border: '1px solid rgba(0, 135, 194, 0.5)',
                            fontFamily: 'Nunito',
                            fontSize: '14px',
                            fontWeight: '700',
                            textAlign: 'left',
                            alignItems: 'center', 
                            justifyContent: 'center',
                            backgroundColor: '#B3E3FF',
                            '&.Mui-focused': {
                                backgroundColor: 'var(--Blue-Button-Primary-1, #B3E3FF) !important',
                                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                            },
                            '&:hover':{
                                backgroundColor: 'var(--Blue-Button-Primary-1, #B3E3FF) !important',
                                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                            }
                        },
                    }}
                >
                    <MenuItem id={1} key={1} value={'To Agent'} onClick={() => PrintSA()}>
                        <Stack direction={'row'} alignItems={'center'} spacing={'4px'}>
                            <Icon 
                                svgIconProps={{ 
                                    htmlColor: '#0087C2',
                                    sx: { 
                                        height: '16px', 
                                        width: '16px' 
                                    } 
                                }} 
                                iconName = {'printer'} 
                            />

                            <Typography
                                sx={{
                                    fontFamily: 'Nunito',
                                    marginTop: '2px !important',
                                    fontSize: '12px',
                                    fontWeight: '700',
                                    lineHeight: '20px',
                                    letterSpacing: '0em',
                                    textAlign: 'center',
                                    color: '#0087C2',
                                }}      
                            >
                                Print SA - To Agent
                            </Typography>
                        </Stack>
                    </MenuItem>
                    
                    <MenuItem id={2} key={2} value={'To Delivery Agent'} onClick={() => PrintSAD()}>
                        <Stack direction={'row'} alignItems={'center'} spacing={'4px'}>
                            <Icon 
                                svgIconProps={{ 
                                    htmlColor: '#0087C2',
                                    sx: { 
                                        height: '16px', 
                                        width: '16px' 
                                    } 
                                }} 
                                iconName = {'printer'} 
                            />

                            <Typography
                                sx={{
                                    fontFamily: 'Nunito',
                                    marginTop: '2px !important',
                                    fontSize: '12px',
                                    fontWeight: '600',
                                    lineHeight: '20px',
                                    letterSpacing: '0em',
                                    textAlign: 'center',
                                    color: '#0087C2',
                                }}      
                            >
                                Print SA - To Delivery Agent
                            </Typography>
                        </Stack>
                    </MenuItem>
                </Selection>
            </>
        )
    }

    DropDownButtonStatus(
        action, 
        data, 
        options = [
            { label: 'All', icon: 'search-doc' },
            { label: 'Active', icon: 'documents' },
            { label: 'Deleted', icon: 'status-deleted' },
        ],
        labels = 'Status'
    )
    {
        return (
            <>
                <Selection
                    id='0'
                    label={''}
                    value={data}
                    onChange={(e) => action(e.target.value)}
                    selectBaseProps={{
                        SelectDisplayProps: {
                            style: {
                                padding: '0px 32px 0px 0px', 
                            },
                        },
                        sx: {
                            marginTop: '0px !important',
                            padding: '3px 6px', 
                            height: '28px',
                            border: '1px solid rgba(0, 135, 194, 0.5)',
                            fontFamily: 'Nunito',
                            fontSize: '12px',
                            fontWeight: '700',
                            textAlign: 'left',
                            alignItems: 'center', 
                            justifyContent: 'center',
                            color: '#0087C2',
                            background: 'transparent',
                            '&.Mui-focused': {
                                background: '',
                                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                            },
                            '&:hover':{
                                background: '',
                                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                            }
                        },
                    }}
                    selectInnerStyle = {{
                        style: {
                            padding: '0px'
                        }
                    }}
                >
                    {
                        options.map((option, index) => (
                            <MenuItem id={index} key={index} value={option.value? option.value : option.label} onClick={option.function? option.functionName : undefined}>
                                <Stack direction={'row'} alignItems={'center'} spacing={'4px'}>
                                    {
                                        option.icon !== ''?
                                            <Icon 
                                                svgIconProps={{ 
                                                    htmlColor: '#0087C2',
                                                    sx: { 
                                                        height: '12px', 
                                                        width: '12px' 
                                                    } 
                                                }} 
                                                iconName = {option.icon} 
                                            />
                                        :
                                            <></>
                                    }
                                    <Typography
                                        sx={{
                                            fontFamily: 'Nunito',
                                            marginTop: '2px !important',
                                            fontSize: '12px',
                                            fontWeight: '600',
                                            lineHeight: '20px',
                                            letterSpacing: '0em',
                                            textAlign: 'center',
                                            color: '#0087C2',
                                        }}      
                                    >
                                        {labels} - {option.label}
                                    </Typography>
                                </Stack>
                            </MenuItem>
                        ))
                    }
                </Selection>
            </>
        )
    }
    
    CustomDropDown(data, options, isList = false) {
        return (
            <Selection
                id='0'
                label={''}
                value={data}
                selectBaseProps={{
                    SelectDisplayProps: {
                        style: {
                            padding: '0px 32px 0px 0px', 
                        },
                    },
                    sx: {
                        marginTop: '0px !important',
                        padding: '3px 6px', 
                        height: isList? '28px' : '32px',
                        border: '1px solid rgba(0, 135, 194, 0.5)',
                        fontFamily: 'Nunito',
                        fontSize: '12px',
                        fontWeight: '700',
                        textAlign: 'left',
                        alignItems: 'center', 
                        justifyContent: 'center',
                        color: '#0087C2',
                        background: 'transparent',
                        '&.Mui-focused': {
                            background: '',
                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                        },
                        '&:hover':{
                            background: '',
                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                        }
                    },
                }}
                selectInnerStyle = {{
                    style: {
                        padding: '0px'
                    }
                }}
            >
                {
                    options.map((option, index) => (
                        <MenuItem 
                            id={index} 
                            key={index} 
                            value={
                                option.value? 
                                    option.value 
                                : 
                                    option.label
                            } 
                            onClick={
                                option.function? 
                                    option.functionName 
                                : 
                                    undefined
                            }
                            sx={{ fontSize: '12px' }}
                            hidden={
                                option.hidden?
                                    true
                                :
                                    false
                            }
                        >
                            <Stack direction={'row'} alignItems={'center'} spacing={'4px'}>
                                {
                                    option.icon !== ''?
                                        <Icon 
                                            svgIconProps={{ 
                                                htmlColor: '#0087C2',
                                                sx: { 
                                                    height: '12px', 
                                                    width: '12px' 
                                                } 
                                            }} 
                                            iconName = {option.icon} 
                                        />
                                    :
                                        <></>
                                }
                                <Typography
                                    sx={{
                                        fontFamily: 'Nunito',
                                        marginTop: '2px !important',
                                        fontSize: '12px',
                                        fontWeight: '700',
                                        lineHeight: '20px',
                                        letterSpacing: '0em',
                                        textAlign: 'center',
                                        color: '#0087C2',
                                    }}      
                                >
                                    {option.label}
                                </Typography>
                            </Stack>
                        </MenuItem>
                    ))
                }
            </Selection>
        )
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CustomizeButtonComponent()