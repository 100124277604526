import React, { useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import { useNavigate } from 'react-router-dom';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import { Grid, Box, Stack, InputAdornment, IconButton, MenuItem, Card, CardContent } from '@mui/material';

import ApiService from '../../../../services/ApiService';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import ButtonDailyReportBankView from './Buttons/ButtonDailyReportBankView';
import SelectDataBank from './Selects/SelectDataBank';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Icon, Input, Selection, showToast } from '../../../../components/ui';
import { setDateFormat } from '../../../../utils/date';

const DailyReportBankView = forwardRef((props, ref) => {
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const history = useNavigate();
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [DateNow, setDateNow] = useState('');
    const CustomCompanyList = [
        { id: 1, name: 'Per Company' },
        { id: 2, name: 'Regional Company' }
    ];
    const [CustomCompany, setCustomCompany] = useState(1);
    const [AccountCode, setAccountCode] = useState('');
    const [BankCode, setBankCode] = useState(0);
    const [BankInt, setBankInt] = useState('');
    const [BankName, setBankName] = useState('');

    const [titleModalBank, setTitleModalBank] = useState('');
    const [isModalBank, setIsModalBank] = useState(false);

    const [isLoadingBank, setIsLoadingBank] = useState(false);
    const [dataBank, setDataBank] = useState([]);
    const [SelectedDataBank, setSelectedDataBank] = useState({});
    const [totalRowsBank, setTotalRowsBank] = useState(50);
    const [dataMapBank, setDataMapBank] = useState([])
    const [columnDataBank, setColumnDataBank] = useState([])

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const BreadcrumbsItems = [
        { label: 'Infoss', link: '/Infoss', style: '' },
        { label: 'Daily Report Bank', link: '/Infoss/DailyReportBankReport', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'Daily Report Bank - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const selectCustomCompany = (CustomCompany, setCustomCompany) => {
        return (
            <Selection
                id='0'
                fullWidth={true}
                label="Custom Company"
                value={CustomCompany}
                placeholder={'Select Custom Company'}
                onChange={(e) => setCustomCompany(e.target.value)}
                disabled
            >
                {
                    CustomCompanyList.map((data) => (
                        <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.id}>
                            {data.name}
                        </MenuItem>
                    ))
                }
            </Selection>
        )
    }

    function getDateNow() {
        setIsLoadingBackDrop(false);
        setDateFormat(new Date(), setDateNow);
    }

    function handleDateNow(value) {
        setDateNow(value);
    }

    const handleClickBank = () => {
        if (isModalBank === false) {
            setIsModalBank(true);
            getDataBank(1, 50);
        }
        else {
            setIsModalBank(false);
        }
    };

    function searchBank(value) {
        if (value === 'search') {
            setTitleModalBank('Bank');
            handleClickBank();
        }
        else {
            setAccountCode('');
            setBankCode('');
            setBankInt('');
            setBankName('');
        }
    }

    const getDataAccess = () => {
        setIsLoadingBackDrop(true);
        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'OSHOR')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);

                        getDateNow();
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Infoss');
                })
        }
        else {
            history('/Infoss');
        }
    }

    const getDataBank = (pageNumber, pageSize, filters) => {
        setIsLoadingBank(true);
        let param = 'bank/bank';
        let params = [{ "attr": "isWithCOA", "value": "1" }];

        ApiService.DynamicPostByPage(param, 'SEARCH', pageNumber, pageSize, params, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataBank({})
                    setDataBank(response.data.data.bank);

                    let temp = response.data.data.bank
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapBank(indexed)
                    setColumnDataBank(response.data.data.columns)
                    setTotalRowsBank(response.data.totalRowCount)

                    setIsLoadingBank(false)
                }
                setIsLoadingBank(false)
            })
            .catch(function (error) {
                setIsLoadingBank(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Failed to fetch data!, ' + error })
            })
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationDRB() {
        if (titleConfirmation === 'Print PDF') {
            PrintPDF();
        }
        else if (titleConfirmation === 'Print Excel') {
            PrintExl();
        }
        setIsModalConfirmation(false);
    }

    const checkInput = () => {
        let check = 0;

        if (BankCode === 0) {
            check = 1;
        }

        if (check === 1) {
            showToast({ type: 'error', message: 'Please Select Data Bank!' });
        }

        return check;
    }

    const PrintData = () => {
        if (checkInput() === 0) {
            setTitleConfirmation('Print PDF')
            setTitleModalConfirmation('Daily Report Bank');
            handleClickConfirmation();
        }
    };

    const PrintExcel = () => {
        if (checkInput() === 0) {
            setTitleConfirmation('Print Excel')
            setTitleModalConfirmation('Daily Report Bank');
            handleClickConfirmation();
        }
    }

    function PrintPDF() {
        setIsLoadingBackDrop(true);

        let variable = "PrintDRB?typeReport=pdf";
        let params = [
            {
                "attr": "accountCode",
                "value": AccountCode
            },
            {
                "attr": "closingDate",
                "value": DateNow
            }
        ];
        ApiService.ReportPrint("Reports/Finance/Print/Cashier/DailyReportBankReport/", variable, params)
            .then((response) => {
                if (response.status === 200) {
                    let rptFormat = "application/pdf";

                    const file = new Blob([response.data], { type: rptFormat });
                    const fileURL = URL.createObjectURL(file);

                    setIsLoadingBackDrop(false);
                    showToast({ type: 'success', message: 'Print Daily Report Bank Success' });
                    window.open(fileURL);
                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'Can\'t Print Daily Report Bank, ' + response.data.message });
                }
            })
            .catch((error) => {
                setIsLoadingBackDrop(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Print Daily Report Bank, ' + error });
            });
    }

    function PrintExl() {
        setIsLoadingBackDrop(true);

        let variable = "PrintDRB?typeReport=xls";
        let params = [
            {
                "attr": "accountCode",
                "value": AccountCode
            },
            {
                "attr": "closingDate",
                "value": DateNow
            }
        ];
        ApiService.ReportPrint("Reports/Finance/Print/Cashier/DailyReportBankReport/", variable, params)
            .then((response) => {
                if (response.status === 200) {
                    let rptFormat = "application/vnd.ms-excel";

                    const file = new Blob([response.data], { type: rptFormat });
                    const fileURL = URL.createObjectURL(file);

                    setIsLoadingBackDrop(false);
                    showToast({ type: 'success', message: 'Print Daily Report Bank Report Success' });
                    window.open(fileURL);
                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'Can\'t Print Daily Report Bank Report, ' + response.data.message });
                }
            })
            .catch((error) => {
                setIsLoadingBackDrop(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Print Daily Report Bank Report, ' + error });
            });
    }

    const ButtonComponents = () => {
        return (
            <ButtonDailyReportBankView
                PrintData={PrintData}
                PrintExcel={PrintExcel}
                DataRegUserAccess={DataRegUserAccess}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: 3 }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    title={'Daily Report Bank'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div style={{ marginTop: '24px' }}>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={7}
                                lg={7}
                                sm={7}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Stack spacing={'16px'} direction="row">
                                            {selectCustomCompany(CustomCompany, setCustomCompany)}

                                            <Input
                                                label='As Per Date'
                                                variant="standard"
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                value={DateNow}
                                                onChange={(e) => handleDateNow(e.target.value)}
                                                type='date'
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                    }
                                                }}
                                            />
                                        </Stack>

                                        <Stack spacing={'16px'} direction="row" sx={{ marginTop: '24px' }}>
                                            <Input
                                                label='Bank'
                                                value={BankCode}
                                                inputBaseProps={{
                                                    readOnly: true,
                                                }}
                                                disabled
                                            />

                                            <Input
                                                value={BankInt}
                                                inputBaseProps={{
                                                    sx: {
                                                        marginTop: '23px !important'
                                                    },
                                                    readOnly: true,
                                                }}
                                                disabled
                                            />

                                            <Input
                                                variant="standard"
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                value={BankName}
                                                type='text'
                                                disabled
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                    }
                                                }}
                                                inputBaseProps={{
                                                    sx: {
                                                        marginTop: '23px !important'
                                                    },
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => searchBank('delete')}
                                                                hidden={BankName ? false : true}
                                                            >
                                                                <Icon
                                                                    iconName={'close'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#0087C2',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>

                                                            <IconButton
                                                                onClick={() => searchBank('search')}
                                                                size='small'
                                                            >
                                                                <Icon
                                                                    iconName={'search'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#0087C2',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            {
                isModalBank && (
                    <SelectDataBank
                        isModal={isModalBank}
                        setIsModal={setIsModalBank}
                        handleClick={handleClickBank}
                        titleModal={titleModalBank}

                        getData={getDataBank}
                        isLoading={isLoadingBank}
                        totalRows={totalRowsBank}
                        setTotalRows={setTotalRowsBank}
                        SelectedData={SelectedDataBank}
                        setSelectedData={setSelectedDataBank}
                        data={dataBank}
                        columnData={columnDataBank}
                        dataMap={dataMapBank}

                        setAccountCode={setAccountCode}
                        setBankCode={setBankCode}
                        setBankInt={setBankInt}
                        setBankName={setBankName}
                    />
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationDRB}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </Box>
    )
})

export default DailyReportBankView