import React from "react";
import ButtonComponent from "../../../../components/Buttons/ButtonComponent";
import Grid from '@mui/material/Grid';

const CreateEditEplButton = ({
  check,
  isReadOnly,
  Save,
  DataRegUserAccess,
  isEdit,
  Print
}) => {

  const renderButtonByAccessCode = (accessCode) => {
    switch (accessCode) {
      case 'PRI':
        return isEdit ? ButtonComponent.PrintButton(Print, "EPL", true) : null;
      default:
        return null;
    }
  };

  return (
    <div className="row" style={{ marginTop: '16px' }}>
      <div className="col-12">
        <Grid
          container
          item
          justifyContent="right"
          direction="row"
          style={{ maxWidth: "100vw", gap: '12px' }}
        >
          {check !== 0 && !isReadOnly && ButtonComponent.SaveButton(Save, false, false, true)}

          {!isReadOnly && DataRegUserAccess.map((data) => (
            <React.Fragment key={data.accessId}>
              {renderButtonByAccessCode(data.accessCode)}
            </React.Fragment>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default CreateEditEplButton;