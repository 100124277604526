import React, { useState, useEffect } from 'react'
import { Backdrop, Box, Grid } from '@mui/material'

import OperationalMarketingSection from './components/OperationalMarketingSection';
import AccountingFinanceSection from './components/AccountingFinanceSection';

import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import ApiService from '../../../services/ApiService';
import { Icon, PopUp } from '../../../components/ui';
import ButtonComponent from '../../../components/Buttons/ButtonComponent';

const CustomerServiceReport = ({ isOpen, closeModal, exportData, selectedData, errorToast, jobTypeList }) => {
  const [isLoading, setIsLoading] = useState(true)

  const [shipmentData, setShipmentData] = useState()
  const [bookingConfirmationData, setBookingConfirmationData] = useState()
  const [eplData, setEplData] = useState()
  const [paymentRequestData, setPaymentRequestData] = useState([])
  const [temporaryReceiptData, setTemporaryReceiptData] = useState([])
  const [invoiceData, setInvoiceData] = useState([])
  const [paymentVoucherData, setPaymentVoucherData] = useState([])
  const [officialReceiptData, setOfficialReceiptData] = useState([])

  const [tableINV, setTableINV] = useState({ data: [], columns: [], totalRows: 25 })
  const [tableOR, setTableOR] = useState({ data: [], columns: [], totalRows: 25 })
  const [tablePR, setTablePR] = useState({ data: [], columns: [], totalRows: 25 })
  const [tablePV, setTablePV] = useState({ data: [], columns: [], totalRows: 25 })
  const [tableTR, setTableTR] = useState({ data: [], columns: [], totalRows: 25 })

  useEffect(() => {
    if (isOpen) {
      getDataShipment()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const getDataShipment = async () => {
    setIsLoading(true)
    try {
      const res = await ApiService.OperationPostById('shipmentOrder/shipmentOrder', selectedData?.id)
      if (res.data.code === 200) {
        const shipment = res.data.data.shipmentOrder
        setShipmentData(shipment)
        getAdditionalData(shipment)
      } else {
        errorToast('Shipment')
        closeModal()
      }
    } catch (error) {
      console.log(error)
      errorToast('Shipment')
      closeModal()
    }
  }

  const getPayloadParams = (withOrder, withInv, withJob) => {
    let params = []
    if (withOrder) {
      params.push({ "attr": "shipmentOrderId", "value": String(selectedData?.id) })
    } else {
      params.push({ "attr": "shipmentId", "value": String(selectedData?.id) })
    }

    if (withInv) params.push({ "attr": "IsWithInv", "value": "true" })
    if (withJob) params.push({ "attr": "IsWithJob", "value": 'true' })

    return params
  }

  const getAdditionalData = async (shipment) => {
    try {
      const [resBC, resEPL, resTR, resPR, resINV, resPV, resOR] = await Promise.all([
        ApiService.OperationPostById('bookingConfirmation/bookingConfirmation', shipment?.bookingConfirmationId),
        ApiService.OperationDynamicPostByPage('estimateProfitLoss/estimateProfitLoss', 'PAGE', 1, 25, getPayloadParams()),
        ApiService.FinAccPostByPage('temporaryReceipt/temporaryReceipt', 'SEARCH', 1, 25, getPayloadParams(true, false, true)),
        ApiService.OperationDynamicPostByPage('paymentRequest/paymentRequest', 'SEARCH', 1, 25, getPayloadParams()),
        ApiService.OperationDynamicPostByPage('invoice/invoice', 'SEARCH', 1, 25, getPayloadParams()),
        ApiService.FinAccPostByPage('paymentVoucher/paymentVoucher', 'PAGE', 1, 25, getPayloadParams(true)),
        ApiService.FinAccPostByPage('officialReceipt/officialReceipt', 'SEARCH', 1, 25, getPayloadParams(false, true))
      ])

      if (resBC?.data?.code === 200) {
        const booking = resBC.data.data.bookingConfirmation
        setBookingConfirmationData(booking)
      }

      if (resEPL?.data?.code === 200) {
        const epl = resEPL.data.data.estimateProfitLoss
        if (epl.length) {
          setEplData(epl[0])
        }
      } else {
        errorToast('Estimate Profit Loss')
      }

      if (resTR?.data?.code === 200) {
        const data = resTR.data.data.temporaryReceipts
        const columns = resTR.data.data.columns.headerColumns
        const totalRows = resTR.data.totalRowCount

        setTemporaryReceiptData(data)
        setTableTR({ data, columns, totalRows })
      } else {
        errorToast('Temporary Receipt')
      }

      if (resPR?.data?.code === 200) {
        const data = resPR.data.data.paymentRequests
        const columns = resPR.data.data.columns.headerColumns
        const totalRows = resPR.data.totalRowCount

        setPaymentRequestData(data)
        setTablePR({ data, columns, totalRows })
      } else {
        errorToast('Payment Request')
      }

      if (resINV?.data?.code === 200) {
        const data = resINV.data.data.invoices
        const columns = resINV.data.data.columns.headerColumns
        const totalRows = resINV.data.totalRowCount

        setInvoiceData(data)
        setTableINV({ data, columns, totalRows })
      } else {
        errorToast('Invoice')
      }

      if (resPV?.data?.code === 200) {
        const data = resPV.data.data.paymentVoucher
        const columns = resPV.data.data.columns.headerColumns
        const totalRows = resPV.data.totalRowCount

        setPaymentVoucherData(data)
        setTablePV({ data, columns, totalRows })
      } else {
        errorToast('Payment Voucher')
      }

      if (resOR?.data?.code === 200) {
        const data = resOR.data.data.officialReceipt
        const columns = resOR.data.data.columns.headerColumns
        const totalRows = resOR.data.totalRowCount

        setOfficialReceiptData(data)
        setTableOR({ data, columns, totalRows })
      } else {
        errorToast('Official Receipt')
      }

    } catch (error) {
      errorToast('Additional')
    }
    setIsLoading(false)
  }

  const mainContainer = (
    <div minHeight={'500px'}>
      {!isLoading && (
        <>
          <div style={{ marginTop: '12px' }}>
            <OperationalMarketingSection
              eplData={eplData}
              invoiceData={invoiceData}
              shipmentData={shipmentData}
              paymentRequestData={paymentRequestData}
              paymentVoucherData={paymentVoucherData}
              officialReceiptData={officialReceiptData}
              temporaryReceiptData={temporaryReceiptData}
              bookingConfirmationData={bookingConfirmationData}
              jobTypeList={jobTypeList}
            />
          </div>

          <div style={{ marginTop: '24px' }}>
            <AccountingFinanceSection
              shipmentData={shipmentData}
              tableINV={tableINV}
              tableOR={tableOR}
              tablePR={tablePR}
              tablePV={tablePV}
              tableTR={tableTR}
            />
          </div>
        </>
      )}
    </div>
  )

  return (
    <>
      <PopUp
        id='modal-id'
        icon={
          <Icon
            iconName={'approve-doc'}
            svgIconProps={{
              htmlColor: '#0087C2',
              sx: {
                height: '24px',
                width: '24px'
              }
            }}
          />
        }
        title={'Customer Service Report'}
        isOpen={isOpen}
        onClose={closeModal}
        draggable={true}
        shrinkable={true}
        disableScrollLock={true}
        maxWidth={'xl'}
        fullWidth={true}
        topButton={false}
      >
        <div style={{ padding: '12px 6px 0px' }}>
          {mainContainer}

          <div style={{ marginTop: '48px' }}>
            <Box display="flex" alignItems="center">
              <Grid container item direction="row">
                <Grid item xs={12} className='text-end' display={'flex'} justifyContent={'flex-end'}>
                  {ButtonComponent.PrintButton(() => exportData('pdf'), 'PDF', true)}
                  {ButtonComponent.ExcelButton(() => exportData('excel'), 'Print Excel', false, true)}
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </PopUp>

      {
        isLoading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: 100000 }}
            open={isLoading}
          >
            <LoadingSpinner color="inherit" />
          </Backdrop>
        )
      }
    </>
  )
}

export default CustomerServiceReport