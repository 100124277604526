import React from 'react'
import PropTypes from 'prop-types';
import {
  Pagination, PaginationItem, Stack,
  Typography, alpha, styled,
  // useTheme 
} from '@mui/material'

import Input from '../Input/Input'
import Selection from '../Selection/Selection'

const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
  '&:not(.MuiPaginationItem-ellipsis)': {
    background: alpha('#56CCF2', .25),
    color: theme.palette.primary.main,
    '&:hover': {
      background: alpha('#56CCF2', .40),
    },
  },
  '&.MuiPaginationItem-rounded': {
    borderRadius: '8px',
  },
  '&.Mui-selected': {
    background: alpha(theme.palette.primary.main, .25),
    border: `1.5px solid ${theme.palette.primary.main}`,
    color: '#083A50',
    cursor: 'default',
    pointerEvents: 'none',
  },
  '&.Mui-disabled': {
    background: '#CACED8',
    color: theme.palette.paper.main
  }
}))

const SIZING = {
  fontSize: ['12px', '14px', '16px'],
  itemSize: ['26px', '32px', '40px'],
  limitSize: ['110px', '120px', '130px']
}

const Paginate = ({
  direction = 'row',
  page = 1,
  limit = 50,
  totalData = 100,
  shape = 'rounded',
  size = 'medium',
  onChange,
  onChangeLimit,
  disabled,

  // *** Custom Props
  showGoToPage = true,
}) => {
  const pageCount = Math.ceil(totalData / limit)
  // const theme = useTheme()

  const getSizing = (target) => {
    switch (size) {
      case 'small':
        return SIZING[target][0];
      case 'medium':
        return SIZING[target][1];
      case 'large':
        return SIZING[target][2];
      default:
        return SIZING[target][1];
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const targetPage = parseInt(event.target.value, 10);
      if (!isNaN(targetPage) && targetPage >= 1 && targetPage <= pageCount) {
        onChange(targetPage);
      }
    }
  };

  return (
    <Stack
      direction={direction}
      gap='10px'
      justifyContent='space-between'
      flexWrap='wrap'
      alignItems={['row', 'row-reverse'].includes(direction) ? 'center' : 'flex-start'}
    >
      <Stack direction={'row'} spacing={'16px'} alignItems={'center'}>
        <Selection
          formControlProps={{ sx: { width: getSizing('limitSize') } }}
          selectBaseProps={{
            sx: {
              border: '1.5px solid #989898',
              height: getSizing('itemSize'),
              fontSize: getSizing('fontSize'),
            }
          }}
          selectInnerStyle={{
            padding: '8px',
            paddingRight: '36px',
          }}
          value={limit}
          onChange={(e) => onChangeLimit(e.target.value)}
          options={[
            {
              label: '50 Rows',
              value: 50
            },
            {
              label: '100 Rows',
              value: 100
            },
            {
              label: '150 Rows',
              value: 150
            }
          ]}
        />
        <Typography
          sx={{
            fontSize: getSizing('fontSize'),
            color: '#989898'
          }}
        >
          {`Rows Total ${new Intl.NumberFormat('ID-id').format(totalData)}`}
        </Typography>
      </Stack>

      <Pagination
        disabled={disabled}
        shape={shape}
        size={size}
        count={pageCount}
        page={page}
        onChange={(e, targetPage) => onChange(targetPage)}
        showFirstButton
        showLastButton
        renderItem={(item) => {
          return (
            <>
              <StyledPaginationItem
                key={item.page}
                {...item}
              />
              {(item.type === 'last' && showGoToPage) && (
                <Input
                  type={'number'}
                  inputBaseProps={{
                    min: 1,
                    max: pageCount,
                    onKeyDown: handleKeyDown,
                    sx: {
                      marginInlineStart: '4px',
                      width: '185px',
                      padding: '10px 8px',
                      height: getSizing('itemSize'),
                      fontSize: 14,
                      minHeight: getSizing('itemSize'),
                      marginTop: '0px !important',
                      border: '1.5px solid #989898',
                      '& .MuiInputBase-input': {
                        width: 'auto',
                        borderBottom: '1px solid #2B4957',
                        mx: '8px',
                        fontWeight: 700,
                        textAlign: 'center',
                        fontSize: getSizing('fontSize')
                      }
                    },
                    startAdornment: (
                      <Typography
                        sx={{
                          fontSize: 14,
                          whiteSpace: 'nowrap',
                          fontWeight: 700
                        }}
                      >
                        Go To page
                      </Typography>
                    ),
                    endAdornment: (
                      <Typography
                        sx={{
                          fontSize: 14,
                          whiteSpace: 'nowrap',
                          fontWeight: 700
                        }}
                      >
                        of {new Intl.NumberFormat('ID-id').format(pageCount)}
                      </Typography>
                    )
                  }}
                />
              )}
            </>
          );
        }}
      />
    </Stack>
  )
}

Paginate.propTypes = {
  direction: PropTypes.string,
  page: PropTypes.number.isRequired,
  limit: PropTypes.number,
  totalData: PropTypes.number,
  shape: PropTypes.string,
  size: PropTypes.string,
  showGoToPage: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onChangeLimit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default Paginate