import React, { useState, useEffect, useImperativeHandle, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import TableX from '../../../../components/Tables/TableX';
import ApiService from '../../../../services/ApiService';
import ButtonListReceiptPPh23 from './Component/ButtonListReceiptPPh23';
import { forwardRef } from 'react';
import Backdrop from '@mui/material/Backdrop';
import ModalDelete from '../../../../components/Modals/ModalDelete';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import freezeContainer from '../../../../Styles/freezeContainer';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Paginate, showToast } from '../../../../components/ui';
import { newWindowConfig } from '../../../../utils/masks.jsx';

const ListReceiptPPh23 = forwardRef((props, ref) => {
  const btnPageRef = useRef(null)
  const btnTableRef = useRef(null)

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
  const history = useNavigate()
  const param = 'officialReceiptPph23/officialReceiptPph23';
  const paramRegUserAccess = 'regUserAccess/regUserAccess';
  const [DataRegUserAccess, setDataRegUserAccess] = useState([]);
  const [rowsCount, setRowsCount] = useState(50)
  const [numPage, setNumPage] = useState(1)

  const [dataMap, setDataMap] = useState([])
  const [columnData, setColumnData] = useState([])
  const [SelectedData, setSelectedData] = useState({});
  const [totalRows, setTotalRows] = useState(50)
  const [filter, setFilter] = useState([])
  const [filterBy, setFilterBy] = useState([])
  const [stateX, setStateX] = useState({});
  const [sortArr, setSortArr] = useState([]);
  const [goTo, setGoTo] = useState(1);

  const [dataSelected, setDataSelected] = useState({});

  const [isModalDelete, setIsModalDelete] = useState(false);
  const [titleModalDelete, setTitleModalDelete] = useState("");

  const [isModalConfirmation, setIsModalConfirmation] = useState(false);
  const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
  const [titleConfirmation, setTitleConfirmation] = useState('');

  const [dropDownTitle, setDropDownTitle] = useState('All');

  const BreadcrumbsItems = [
    { label: 'Infoss', link: '/Infoss', style: '' },
    { label: 'Receipt PPh 23', link: '/Infoss/OfficialReceiptPph23', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useImperativeHandle(ref, () => ({
    localSave() {

    }
  }));

  useEffect(() => {
    document.title = 'Receipt PPh23 - ' + JSON.parse(localStorage.getItem('branchName'));
    getDataAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = (pageNumber, pageSize, filters, orderBy, data) => {
    if (isLoadingBackDrop === false) {
      setIsLoading(true);
    }
    else {
      setIsLoadingBackDrop(true);
    }

    let filter = [];

    if (data) {
      if (data === 'All') {
        filter = [...filters]
      }
      else if (data === 'Deleted') {
        filter = [...filters, { 'field': 'rowStatus', 'data': 'DEL' }]
      }
      else if (data === 'Active') {
        filter = [...filters, { 'field': 'rowStatus', 'data': 'ACT' }]
      }
    }
    else {
      if (dropDownTitle === 'All') {
        filter = [...filters]
      }
      else if (dropDownTitle === 'Deleted') {
        filter = [...filters, { 'field': 'rowStatus', 'data': 'DEL' }]
      }
      else if (dropDownTitle === 'Active') {
        filter = [...filters, { 'field': 'rowStatus', 'data': 'ACT' }]
      }
    }

    ApiService.ListFinAccPostByPage(param, 'PAGE', pageNumber, pageSize, [], filter, orderBy)
      .then((response) => {
        if (response.data.code === 200) {
          setSelectedData({})

          let temp = response.data.data.officialReceiptPph23
          let indexed = temp.map((el, index) => {
            let indexedTemp = {
              ...el,
              index
            }
            return indexedTemp
          })

          setDataMap(indexed)
          setColumnData(response.data.data.columns.headerColumns)
          setTotalRows(response.data.totalRowCount)

          if (isLoadingBackDrop === false) {
            setIsLoading(false);
          }
          else {
            setIsLoadingBackDrop(false);
          }
        }
        else if (response.data.code === 500) {
          showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' })
          if (isLoadingBackDrop === false) {
            setIsLoading(false);
          }
          else {
            setIsLoadingBackDrop(false);
          }
        }

        if (isLoadingBackDrop === false) {
          setIsLoading(false);
        }
        else {
          setIsLoadingBackDrop(false);
        }
      })
      .catch(function (error) {
        if (isLoadingBackDrop === false) {
          setIsLoading(false);
        }
        else {
          setIsLoadingBackDrop(false);
        }

        console.error('error saat fetch', error)
        showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator' + error });
      })
  }

  const getDataAccess = () => {
    if (isLoadingBackDrop === false) {
      setIsLoading(true);
    }
    else {
      setIsLoadingBackDrop(true);
    }

    let params = [];
    let pId = 0;
    let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
    let hasAccess = false

    const indexMenu = listMenu.findIndex((elm) => elm.code === 'FORP')
    if (indexMenu !== -1) {
      pId = listMenu[indexMenu].id
      hasAccess = true
    }

    if (hasAccess) {
      params = [
        ...params,
        { 'attr': 'permissionId', 'value': '' + pId },
        { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
        { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
      ]
      ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
        .then((response) => {
          if (response.data.code === 200) {
            setDataRegUserAccess(response.data.data.userAccess);

            getData(1, 50, []);
          }
          else if (response.data.code === 500) {
            showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message })
            if (isLoadingBackDrop === false) {
              setIsLoading(false);
            }
            else {
              setIsLoadingBackDrop(false);
            }
          }
        })
        .catch(function (error) {
          console.error('error saat fetch', error)
          history('/Infoss');
        })
    }
    else {
      history('/Infoss');
    }
  }

  const emptyState = () => {
    setNumPage(1);
    setRowsCount(50);
    setTotalRows(50);
    setFilter([]);
    setFilterBy([]);
    setGoTo(1);
    setSortArr([]);
    setStateX({});
  };

  const handleReset = () => {
    setFilter([])
    setFilterBy([])
    emptyState();
    getData(1, 50, []);
  }

  const handleClickDelete = () => {
    if (isModalDelete === false) {
      setIsModalDelete(true);
      setDataSelected(SelectedData);
    } else {
      setIsModalDelete(false);
    }
  };

  const handleClickConfirmation = () => {
    if (isModalConfirmation === false) {
      setIsModalConfirmation(true);
      setDataSelected(SelectedData);
    }
    else {
      setIsModalConfirmation(false);
    }
  }

  function ConfirmationReceiptPPH23() {
    if (titleConfirmation === 'Print') {
      Print();
    }
    setIsModalConfirmation(false);
  }

  const Add = () => {
    window.open('/Infoss/officialReceiptPph23/Create', newWindowConfig);
  }

  const Edit = () => {
    if (!SelectedData?.id) {
      showToast({ type: 'error', message: 'Please Select Data!' })
    } else {
      window.open('/Infoss/officialReceiptPph23/' + SelectedData.id, newWindowConfig);
    }
  }

  const ReloadData = () => {
    showToast({ type: 'success', message: 'Reload Data' })
    setFilter([])
    emptyState();
    getData(1, 50, []);
  }

  const DeleteData = () => {
    if (!SelectedData?.id) {
      showToast({ type: 'error', message: 'Please Select Data!' })
    } else {
      if (SelectedData.rowStatus === 'ACT') {
        setTitleModalDelete("Official Receipt PPh23");
        handleClickDelete();
      }
      else {
        showToast({ type: 'error', message: 'Data Already Deleted' })
      }
    }
  }

  const DeletePPH = () => {
    ApiService.FinAccDelete(param, dataSelected.id).then((res) => {
      if (res.data.code === 200) {
        getData(numPage, rowsCount, filter);
        showToast({ type: 'success', message: 'Delete Data Success' })
        setIsModalDelete(false);
      } else {
        showToast({ type: 'error', message: 'Delete Data Failed' })
        setIsModalDelete(false);
      }
    });
  };

  const PrintData = () => {
    if (!SelectedData?.id) {
      showToast({ type: 'error', message: 'Please Select Data!' })
    }
    else {
      if (SelectedData.rowStatus === 'ACT') {
        setTitleConfirmation('Print')
        setTitleModalConfirmation('Official Receipt PPh23');
        handleClickConfirmation();
      }
      else {
        showToast({ type: 'error', message: 'Data Already Deleted' })
      }
    }
  }

  const Print = () => {

  }

  function CellDoubleClick(row) {
    Edit()
  }

  function handleStatus(data) {
    setDropDownTitle(data);
    getData(numPage, rowsCount, filter, sortArr, data);
  }

  const getContainerHeight = () => {
    const hTitle = 64
    const hHeader = 116
    const hPadding = 10 * 2
    const hBtnPage = btnPageRef?.current?.clientHeight || 28
    const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

    const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable

    return `${totalHeight}px`
  }

  const onChangePaging = (value) => {
    setNumPage(value);
    getData(value, rowsCount, filter);
  }

  const onChangeLimit = (value) => {
    setNumPage(1);
    setRowsCount(value);
    getData(1, value, filter);
  }

  const ButtonComponents = () => {
    return (
      <ButtonListReceiptPPh23
        Add={Add}
        Edit={Edit}
        ReloadData={ReloadData}
        DeleteData={DeleteData}
        PrintData={PrintData}
        DataRegUserAccess={DataRegUserAccess}
        HandleStatus={handleStatus}
        DropDownTitle={dropDownTitle}
      />
    )
  }

  return (
    <Box className='w-100' sx={{ width: '100%', p: '24px 32px', ...freezeContainer }}>
      <CustomToastContainer />

      <Grid container spacing={0} direction="column">
        <HeaderInfo btnPageRef={btnPageRef}
          title={'Receipt PPh 23'}
          isJobType={false}
          BreadcrumbsItems={BreadcrumbsItems}
          ButtonComponents={ButtonComponents}
        />

        <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
          <div ref={btnTableRef}>
            {
              ButtonComponent.RefreshButton(
                sortArr,
                rowsCount,
                columnData,
                setGoTo,
                handleReset,
                setFilterBy,
                filterBy,
                setFilter,
                setNumPage,
                setRowsCount,
                getData,
                stateX,
                setStateX
              )
            }
          </div>

          <div
            className="rounded-10 table-responsive"
            style={{
              maxHeight: `calc(100vh - ${getContainerHeight()})`,
              marginTop: '16px',
              padding: '2px 16px',
              borderRadius: '12px',
              background: '#F2F2F2'
            }}
          >
            {
              isLoading ?
                <LoadingSpinner />
                :
                <>
                  <div className='mt-1'>
                    <TableX
                      uniqueKey={'id'}
                      goTo={goTo}
                      rowsCount={rowsCount}
                      setNumPage={setNumPage}
                      setRowsCount={setRowsCount}
                      getData={getData}
                      columnData={columnData}
                      dataMap={dataMap}
                      SelectedData={SelectedData}
                      setSelectedData={setSelectedData}
                      setFilter={setFilter}
                      setFilterBy={setFilterBy}
                      CellDoubleClick={CellDoubleClick}
                      stateX={stateX}
                      setStateX={setStateX}
                      sortArr={sortArr}
                      setSortArr={setSortArr}
                      setGoTo={setGoTo}
                    />
                  </div>
                </>
            }
          </div>

          <div style={{ marginTop: '16px' }}>
            <Paginate
              page={numPage}
              limit={rowsCount}
              totalData={totalRows}
              onChange={onChangePaging}
              onChangeLimit={onChangeLimit}
            />
          </div>
        </Grid>
      </Grid>

      {
        isModalDelete && (
          <ModalDelete
            isModal={isModalDelete}
            titleModal={titleModalDelete}
            handleClick={handleClickDelete}
            DeleteData={DeletePPH}
          />
        )
      }

      {
        isModalConfirmation && (
          <ModalConfirmation
            isModal={isModalConfirmation}
            titleModal={titleModalConfirmation}
            title={titleConfirmation}
            handleClick={handleClickConfirmation}
            ConfirmationData={ConfirmationReceiptPPH23}
          />
        )
      }

      {
        isLoadingBackDrop && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoadingBackDrop}
          >
            <LoadingSpinner />
          </Backdrop>
        )
      }
    </Box>
  )
})

export default ListReceiptPPh23