import { Grid, Typography } from '@mui/material';
import React, { forwardRef, useImperativeHandle } from 'react';
import ButtonService from '../ButtonService';
import { formatCurrency, userCode, username } from '../../../services/ApiService';

import { useFormik } from 'formik';
import * as Yup from 'yup'
import CreateEditModalMaster from '../CreateEditModalMaster';
import { Input, showToast, TickBox } from '../../../components/ui';
import DollarInput, { convertMask } from '../../../components/Masks/DollarInput';
import RupiahInput, { convertMaskIDR } from '../../../components/Masks/RupiahInput';
import { defaultDate } from '../../../utils/date';

const CreateEditAccount = forwardRef((props, ref) => {
    const { isModal, handleClick, titleModal, title, param, selectedData, getData } = props

    const simpanLocal = () => {
        if (isModal) {
            const data = {
                "account": {
                    ...formik.values,
                    rowStatus: formik?.values?.rowStatus || 'ACT',
                    titleModal,
                    user: userCode,
                    title: 'Account'
                }
            }
            localStorage.setItem(username + "-DataAccount", JSON.stringify(data));
        }
    }

    useImperativeHandle(ref, () => ({

        localSave() {
            simpanLocal()
        }

    }));

    const validationSchema = Yup.object().shape({
        name: Yup.string().trim().required('This field is required.'),
        quantity: Yup.boolean(),
        cbm: Yup.boolean(),
        minCharge_USD: Yup.number().nullable(),
        minCharge_IDR: Yup.number().nullable(),
        charge_USD: Yup.number().nullable(),
        charge_IDR: Yup.number().nullable(),
        remarks: Yup.string().nullable(),
    })

    const formik = useFormik({
        initialValues: selectedData?.id ? { ...selectedData } : {
            name: '',
            quantity: false,
            cbm: false,
            minCharge_USD: '',
            minCharge_IDR: '',
            charge_USD: '',
            charge_IDR: '',
            remarks: '',
        },
        validationSchema,
        onSubmit: (values) => Save(values)
    })

    const Save = (payload) => {
        if (payload?.name === '') {
            return showToast({ type: 'error', message: 'Account Name is Required!' })
        }

        const data =
        {
            "account": {
                "rowStatus": payload?.rowStatus || "ACT",
                "id": payload?.id,
                "name": payload?.name,
                "quantity": payload?.quantity,
                "cbm": payload?.cbm,
                "minCharge_USD": payload?.minCharge_USD,
                "minCharge_IDR": payload?.minCharge_IDR,
                "charge_USD": payload?.charge_USD,
                "charge_IDR": payload?.charge_IDR,
                "remarks": payload?.remarks,
                "deleted": false,
                "deletedOn": defaultDate,
                "user": userCode
            }
        }

        ButtonService.Save(titleModal, title, param, data, () => {
            getData(1, 50, []);
            handleClick();
        });
    }

    function handleChange(name, value) {
        formik.setFieldValue(name, !value);
    }

    const mainContainer = (
        <form onSubmit={formik.handleSubmit} style={{ marginTop: '12px' }}>
            <Input
                name="name"
                label="Account Name"
                value={formik.values.name}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                onChange={(e) => formik.setFieldValue('name', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        // marginTop: '16px'
                    }
                }}
            />

            <Grid container spacing={'32px'}>
                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <div style={{ marginTop: '16px' }}>
                        <TickBox
                            size={'small'}
                            label={
                                <Typography
                                    sx={{
                                        color: 'var(--Blue-Primary-1, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '12px !important',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: '20px',
                                    }}
                                >
                                    Quantity
                                </Typography>
                            }
                            checked={formik.values.quantity}
                            onChange={() => handleChange('quantity', formik.values.quantity)}
                        />
                    </div>

                    <Input
                        textAlign='right'
                        name="minCharge_USD"
                        label="Min Charge USD"
                        onBlur={formik.handleBlur}
                        value={formik.values.minCharge_USD}
                        onChange={(e) => formik.setFieldValue('minCharge_USD', formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                        helperText={formik.touched.minCharge_USD && formik.errors.minCharge_USD}
                        error={formik.touched.minCharge_USD && Boolean(formik.errors.minCharge_USD)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        inputBaseProps={{
                            inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                        }}
                    />

                    <Input
                        textAlign='right'
                        name="charge_USD"
                        label="Charge USD"
                        onBlur={formik.handleBlur}
                        value={formik.values.charge_USD}
                        onChange={(e) => formik.setFieldValue('charge_USD', formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                        helperText={formik.touched.charge_USD && formik.errors.charge_USD}
                        error={formik.touched.charge_USD && Boolean(formik.errors.charge_USD)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        inputBaseProps={{
                            inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <div style={{ marginTop: '16px' }}>
                        <TickBox
                            size={'small'}
                            label={
                                <Typography
                                    sx={{
                                        color: 'var(--Blue-Primary-1, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '12px !important',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: '20px',
                                    }}
                                >
                                    CBM
                                </Typography>
                            }
                            checked={formik.values.cbm}
                            onChange={() => handleChange('cbm', formik.values.cbm)}
                        />
                    </div>

                    <Input
                        textAlign='right'
                        name="minCharge_IDR"
                        label="Min Charge IDR"
                        onBlur={formik.handleBlur}
                        value={formik.values.minCharge_IDR}
                        onChange={(e) => formik.setFieldValue('minCharge_IDR', formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                        helperText={formik.touched.minCharge_IDR && formik.errors.minCharge_IDR}
                        error={formik.touched.minCharge_IDR && Boolean(formik.errors.minCharge_IDR)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        inputBaseProps={{
                            inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                        }}
                    />

                    <Input
                        textAlign='right'
                        name="charge_IDR"
                        label="Charge IDR"
                        onBlur={formik.handleBlur}
                        value={formik.values.charge_IDR}
                        onChange={(e) => formik.setFieldValue('charge_IDR', formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                        helperText={formik.touched.charge_IDR && formik.errors.charge_IDR}
                        error={formik.touched.charge_IDR && Boolean(formik.errors.charge_IDR)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        inputBaseProps={{
                            inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                        }}
                    />
                </Grid>
            </Grid>

            <Input
                id='remarks'
                name="remarks"
                label="Remarks"
                value={formik.values.remarks}
                multiline
                fullWidth
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                inputBaseProps={{
                    rows: 4,
                    sx: {
                        padding: '8px',
                    },
                }}
                onChange={(e) => formik.setFieldValue('remarks', e.target.value)}
            />
        </form>
    )

    return (
        <>
            {
                isModal && (
                    <CreateEditModalMaster
                        isModal={isModal}
                        titleModal={titleModal}
                        title={title}
                        mainContainer={mainContainer}
                        handleClick={handleClick}
                        handleSave={() => Save(formik.values)}
                    />
                )
            }
        </>

    )
})
export default CreateEditAccount