import { Grid, MenuItem } from '@mui/material';
import React from 'react';
import { branchId, companyId, countryId, userId, userCode } from '../../../../services/ApiService';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';

import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import { Icon, Input, PopUp, Selection, showToast } from '../../../../components/ui';

const ContainerMask = React.forwardRef(({ maskOptions, ...inputProps }, ref) => {
    return (
        <MaskedInput
            {...inputProps}
            mask={[
                /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/,
                " ",
                /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,
                "-",
                /\d/
            ]}
            keepCharPositions
        />
    );
});

ContainerMask.propTypes = {
    inputRef: PropTypes.func.isRequired
};

const ModalContainer = (props) => {
    const handleClose = () => {
        emptyState();
        props.handleClick2();
    };

    const emptyState = () => {
        props.setSize('20\'');
        props.setContainerTypeId(1);
        props.setContainerNo('');
        props.setSealNo('');
        props.setGrossWeight(0);
        props.setNetWeight(0);
        props.setCBM(0);
        props.setCommodity('');
        props.setNoOfPieces(0);
        props.setPackagingCode('GPL');
        props.setPartOf(false);
        props.setContainerCode('GP');
        props.setContainerName('General Purpose');
        props.setPackagingName('General Packaging');
    };

    const handleSave = () => {
        if (!props.containerNo) {
            showToast({ message: 'Please Input Container Number', type: 'error' });
        } else if (props.containerNo.split('_').join('').length !== 13) {
            showToast({ message: 'Please Enter the Correct Container Number', type: 'error' });
        } else {

            let Sequence = 1;

            if (props.detailEdit) {
                Sequence = props.selectedDetail.sequence;
            } else {
                if (props.detailSequence > 0) Sequence = props.detailSequence + 1;
            }

            let data = {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "rowStatus": "ACT",
                "shipmentId": props.ShipmentId,
                "sequence": Sequence,
                "containerNo": props.containerNo,
                "sealNo": props.sealNo,
                "containerSize": props.size,
                "containerTypeId": props.containerTypeId,
                "containerTypeCode": props.containerCode,
                "containerTypeName": props.containerName,
                "grossWeight": parseInt(props.grossWeight),
                "netWeight": parseInt(props.netWeight),
                "cbm": parseInt(props.cbm),
                "partOf": props.partOf,
                "commodity": props.commodity,
                "noOfPieces": parseInt(props.noOfPieces),
                "packagingCode": props.packagingCode,
                "packagingName": props.packagingName,
                "idLama": 0,
            }

            if (!props.detailEdit) {
                props.setContainerData([...props.containerData, data]);

                props.getContainerSizeData([...props.containerData, data]);

                props.createCopyContainer([...props.containerData, data]);
            } else {
                const newArr = props.containerData.slice();
                newArr.forEach(el => {
                    if (el.sequence === data.sequence) {
                        el.containerSize = data.containerSize;
                        el.containerTypeId = data.containerTypeId;
                        el.containerTypeCode = data.containerTypeCode;
                        el.containerTypeName = data.containerTypeName;
                        el.containerNo = data.containerNo;
                        el.sealNo = data.sealNo;
                        el.grossWeight = data.grossWeight;
                        el.netWeight = data.netWeight;
                        el.cbm = data.cbm;
                        el.commodity = data.commodity;
                        el.noOfPieces = data.noOfPieces;
                        el.packagingCode = data.packagingCode;
                        el.packagingName = data.packagingName;
                        el.partOf = data.partOf;
                    }
                })
                props.setContainerData(newArr);
                props.getContainerSizeData(newArr);

                props.createCopyContainer(newArr);
            }

            emptyState();
            handleClose();
            props.setDetailEdit(false);
            props.setSelectedDetail({});
        }
    }

    const containerSizeData = [
        { id: 1, name: '20\'' },
        { id: 2, name: '20\'HC' },
        { id: 3, name: '40\'' },
        { id: 4, name: '40\'HC' },
        { id: 5, name: '45\'' },
        { id: 6, name: '45\'HC' },
    ];

    const partOfData = [
        { id: 1, code: false, name: 'No' },
        { id: 2, code: true, name: 'Yes' },
    ];

    const mainContainer = (
        <>
            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Selection
                        id='0'
                        label={'Size'}
                        fullWidth={true}
                        placeholder={'Select Size'}
                        value={props.size}
                        onChange={(e) => props.setSize(e.target.value)}
                        hidden={props.TabType === 'Inc Agent' || props.TabType === 'Cost Agent' ? true : false}
                        disabled={props.isViewOnly}
                    >
                        {
                            containerSizeData.map((data, index) => (
                                <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.name}>{data.name}</MenuItem>
                            ))
                        }
                    </Selection>

                    <div style={{ marginTop: '16px' }}>
                        {props.selectContainerType(props.containerTypeId, props.setContainerTypeId, props.setContainerCode, props.setContainerName)}
                    </div>
                </Grid>

                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Input
                        label="Container No."
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                        inputBaseProps={{
                            inputComponent: ContainerMask,
                            value: props.containerNo,
                            onChange: (e) => {
                                let text = e.target.value
                                if (text) { text = text.toUpperCase() }
                                props.setContainerNo(text)
                            }
                        }}
                    />

                    <Input
                        label="Seal No."
                        value={props.sealNo}
                        onChange={(e) => props.setSealNo(e.target.value)}
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px !important'
                            }
                        }}
                    />
                </Grid>
            </Grid>

            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={12}
                >
                    <Input
                        label="Gross Weight"
                        textAlign='right'
                        value={props.grossWeight}
                        onChange={(e) => props.setGrossWeight(e.target.value)}
                        type={'number'}
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px !important'
                            }
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={12}
                >
                    <Input
                        label="Net Weight"
                        textAlign='right'
                        value={props.netWeight}
                        onChange={(e) => props.setNetWeight(e.target.value)}
                        type={'number'}
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px !important'
                            }
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={12}
                >
                    <Input
                        label="CBM"
                        textAlign='right'
                        value={props.cbm}
                        onChange={(e) => props.setCBM(e.target.value)}
                        type={'number'}
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px !important'
                            }
                        }}
                    />
                </Grid>
            </Grid>

            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={8}
                    lg={8}
                    sm={8}
                    xs={16}
                >
                    <Input
                        label="Commodity"
                        value={props.commodity}
                        onChange={(e) => props.setCommodity(e.target.value)}
                        multiline
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px !important'
                            }
                        }}
                        inputBaseProps={{
                            rows: 4,
                            sx: {
                                padding: '8px',
                            },
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={12}
                >
                    <Input
                        label="No Of Pieces"
                        textAlign='right'
                        value={props.noOfPieces}
                        onChange={(e) => props.setNoOfPieces(e.target.value)}
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px !important'
                            }
                        }}
                    />
                </Grid>
            </Grid>

            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={8}
                    lg={8}
                    sm={8}
                    xs={16}
                >
                    <div style={{ marginTop: '16px' }}>
                        {props.selectPackagingType(props.packagingCode, props.setPackagingCode, props.setPackagingName)}
                    </div>
                </Grid>

                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={12}
                >
                    <Selection
                        id='0'
                        label={'Part Of'}
                        fullWidth={true}
                        placeholder={'Select Part Of'}
                        value={props.partOf}
                        onChange={(e) => props.setPartOf(e.target.value)}
                        formControlProps={{
                            sx: {
                                marginTop: '16px !important'
                            }
                        }}
                        hidden={props.TabType === 'Inc Agent' || props.TabType === 'Cost Agent' ? true : false}
                        disabled={props.isViewOnly}
                    >
                        {
                            partOfData.map((data, index) => (
                                <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                            ))
                        }
                    </Selection>
                </Grid>
            </Grid>
        </>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={props.titleModal2}
                isOpen={props.isModal2}
                onClose={handleClose}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={'lg'}
                fullWidth={true}
                topButton={false}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}

                    <div style={{ marginTop: '48px' }}>
                        {CustomizeButtonComponent.SaveCancelModalButton(handleSave, false, false)}
                    </div>
                </div>
            </PopUp>
        </>
    )
}

export default ModalContainer;