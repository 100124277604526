import { IconButton, InputAdornment, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

import CustomizeButtonComponent from '../../../components/Buttons/CustomizeButtonComponent';
import { branchId, companyId, countryId, userCode } from '../../../services/ApiService';
import { Icon, Input, PopUp, showToast } from '../../../components/ui';

const ModalRegCoa = (props) => {
    const Save = () => {
        if (!props.accountNumber) {
            showToast({ type: 'error', message: 'Please input account number' })
        }
        else {
            if (!props.subAccountTitle) {
                showToast({ type: 'error', message: 'Please input account title' })
            }
            else {
                let Sequence = 1

                if (props.isEdit) {
                    Sequence = props.selectedDetail.sequence
                }
                else {
                    if (props.detailSequence > 0) {
                        Sequence = props.detailSequence + 1
                    }
                }

                let data = {
                    "rowStatus": "ACT",
                    "countryId": countryId,
                    "companyId": companyId,
                    "branchId": branchId,
                    "userId": 0,
                    "regCOAId": '' + props.parentId,
                    "id": 0,
                    "sequence": Sequence,
                    "accountNumber": props.accountNumber,
                    "accountTitle": props.subAccountTitle,
                    "accountGroup": props.accountGroup,
                    "accountType": props.accountType,
                    "refId": props.refId,
                    "userCode": userCode
                }

                if (!props.isEdit) {
                    props.setDataChild([...props.dataChild, data])
                }
                else {
                    const newArr = props.dataChild.slice()
                    newArr.forEach(el => {
                        if (el.sequence === data.sequence) {
                            el.accountNumber = data.accountNumber
                            el.accountTitle = data.accountTitle
                            el.refId = data.refId
                        }
                    })
                    props.setDataChild(newArr)
                }
                props.setIsSave(false)
                props.handleClick();
            }
        }
    }

    const handleSearch = () => {
        if (props.showSearch === false) {
            props.setShowSearch(true);
        }
        else {
            props.setShowSearch(false);
        }
    }

    const Search = () => {
        handleSearch()
        props.setTitleModalSearch('Bank')
    }

    const mainContainer = (
        <div>
            <div className='mt-3'>
                <Typography
                    sx={{
                        color: 'var(--Blue-Primary-1, #083A50)',
                        fontFamily: 'Nunito',
                        fontSize: '18px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: 'normal',
                    }}
                >
                    {props.accountCode} - {props.accountTitle}
                </Typography>
                <Typography
                    sx={{
                        color: 'var(--Blue-Primary-1, #083A50)',
                        fontFamily: 'Nunito',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                        marginTop: '8px'
                    }}
                >
                    Group : {props.accountGroup}, Type : {props.accountType}
                </Typography>
            </div>

            <Box className='mt-3'>
                <Input
                    label={'Account Number'}
                    value={props.accountNumber}
                    onChange={(e) => props.setAccountNumber(e.target.value)}
                    fullWidth
                    formControlProps={{
                        sx: {
                            width: '100%',
                        }
                    }}
                />

                <Input
                    label={'Account Title'}
                    value={props.subAccountTitle}
                    onChange={(e) => props.setSubAccountTitle(e.target.value)}
                    fullWidth
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px !important'
                        }
                    }}
                />

                <Input
                    label="Ref Id"
                    value={props.refId}
                    onChange={(e) => props.setRefId(e.target.value)}
                    disabled
                    fullWidth
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px !important'
                        }
                    }}
                    inputBaseProps={{
                        readOnly: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={Search} hidden={props.isRef}>
                                    <Icon
                                        iconName={'search'}
                                        svgIconProps={{
                                            fontSize: 'small',
                                            htmlColor: '#0087C2',
                                            sx: {
                                                height: '12px',
                                                width: '12px'
                                            }
                                        }}
                                    />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </Box>
        </div>
    )

    return (
        <div>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'account'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={props.titleModal}
                isOpen={props.isModal}
                onClose={props.handleClick}
                draggable={false}
                shrinkable={false}
                disableScrollLock={false}
                maxWidth={'sm'}
                fullWidth={true}
            >
                <div style={{ padding: '0px 6px' }}>
                    {mainContainer}

                    <div style={{ padding: '24px 0px 0px' }}>
                        {CustomizeButtonComponent.SaveCancelMasterModal(Save)}
                    </div>
                </div>
            </PopUp>
        </div>
    )
}

export default ModalRegCoa