import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { Grid, Box, Select, MenuItem, FormControl, Stack, Breadcrumbs, Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import ApiService from '../../../services/ApiService';
import LiveClock from '../../../components/Clocks/LiveClock';
import BalanceSheet from './Content/BalanceSheet/BalanceSheet';
import ProfitAndLoss from './Content/ProfitAndLoss/ProfitAndLoss';
import CustomToastContainer, { Icon, Input, showToast } from '../../../components/ui';

const DashBoard = forwardRef((props, ref) => {
  let countErr = 0;
  const history = useNavigate();

  const [SelectData, setSelectData] = useState(JSON.parse(localStorage.getItem('dashboardMenu')));
  const [period, setPeriod] = useState(new Date());
  const [periodNow, setPeriodNow] = useState(new Date());
  const [date, setDate] = useState(new Date());

  const paramBalanceSheet = 'recapitulation/financeDashboard/financeDashboard/BSReadPage';
  const [isLoadingBackDropBS, setIsLoadingBackDropBS] = useState(false);
  const [dataAsset, setDataAsset] = useState([]);
  const [sumAssetIDR, setSumAssetIDR] = useState(0);
  const [sumAssetUSD, setSumAssetUSD] = useState(0);
  const [dataLiabilities, setDataLiabilities] = useState([]);
  const [sumLiabiIDR, setSumLiabiIDR] = useState(0);
  const [sumLiabiUSD, setSumLiabiUSD] = useState(0);
  const [check, setCheck] = useState(1);

  const varIncomeStatement = 'incomeStatement';
  const paramDashboard = 'recapitulation/financeDashboard/financeDashboard/ISReadPage';
  const [isLoadingBackDropIS, setIsLoadingBackDropIS] = useState(false);
  const [DataChart, setDataChart] = useState([]);
  const [DataPercent, setDataPercent] = useState([]);
  const [DataPercentSize, setDataPercentSize] = useState(0);
  const [IncomeStatementTitle, setIncomeStatementTitle] = useState('');
  const [DataIncomeStatement, setDataIncomeStatement] = useState([]);
  const [DataIncomeStatementSummary, setDataIncomeStatementSummary] = useState([]);

  const BreadcrumbsItems = [
    { label: 'Infoss', link: '/Infoss', style: '' },
    { label: 'Dashboard', link: '/Infoss/Dashboard', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useImperativeHandle(ref, () => ({
    localSave() {

    }
  }));

  useEffect(() => {
    ApiService.refreshToken();
    getDefaultMonth();
  }, [])

  const getDefaultMonth = () => {
    const dateNow = new Date();
    const year = dateNow.getFullYear()
    let month = dateNow.getMonth() + 1

    if (month < 10) {
      month = `0${month}`
    }

    setPeriod(`${year}-${month}`);
    setPeriodNow(dateNow);
    localStorage.setItem("bsPeriod", JSON.stringify(dateNow));
  }

  function setDataPeriod(data) {
    let dateNow = new Date(data);
    setPeriodNow(dateNow);
    localStorage.setItem("bsPeriod", JSON.stringify(dateNow));

    const year = dateNow.getFullYear()
    let month = dateNow.getMonth() + 1

    if (month < 10) {
      month = `0${month}`
    }

    setPeriod(`${year}-${month}`);
    getDataBS();
    getDataIncomeStatement();
  }

  const getDataIncomeStatement = () => {
    setIsLoadingBackDropIS(true);

    ApiService.refreshToken();
    let period = new Date(JSON.parse(localStorage.getItem("bsPeriod")));
    let params = [
      {
        'attr': 'period',
        'value': '' + (period.getMonth() + 1)
      },
      {
        'attr': 'yearPeriod',
        'value': '' + period.getFullYear()
      }
    ];

    ApiService.ReportPostByPage(paramDashboard, 'PAGE', params)
      .then((response) => {
        if (response.data.code === 200) {
          let data = response.data.data;
          let dataSum = [];
          let tempArray4 = [];

          data.forEach(element => {
            if (element.category === 'MART') {
              let tempObj = {
                'id': 999,
                'name': '',
                'sumIDR': 0,
                'dataMartGroups': [],
                'incomeStatements': []
              };
              let tempArray = [];
              let tempArray1 = [];
              let tempArray2 = [];
              let tempArray3 = [];

              tempArray1 = element.data.incomeStatement;
              tempArray = element.data.summary;

              tempArray.forEach(el => {
                if (el.name === 'Ebit') {
                  tempObj.name = el.name.toUpperCase();
                  tempObj.sumIDRDisplay = el.amount;
                }
                else {
                  tempArray2 = [...tempArray2, el];
                }
              });

              for (let i = 0; i < tempArray1.length; i++) {
                if (tempArray1[i].code === 'ISNO') {
                  tempArray3 = [...tempArray3, tempArray1[i]];
                  tempArray3 = [...tempArray3, tempObj];
                }
                else {
                  tempArray3 = [...tempArray3, tempArray1[i]];
                }
              }

              setIncomeStatementTitle(element.name.toUpperCase());
              setDataIncomeStatementSummary(tempArray2);
              setDataIncomeStatement(tempArray3);
              localStorage.setItem(varIncomeStatement, JSON.stringify(tempArray3));
            }
            else if (element.category === 'CHART') {
              if (element.data.datasets) {
                tempArray4 = [...tempArray4, element];
              }
            }
            else if (element.category === 'SUM') {
              if (element.hidden === false) {
                element.name = element.name.toUpperCase();
                dataSum = [...dataSum, element];
              }
            }
          });

          setDataPercent(dataSum);

          let temp = 0;
          temp = parseFloat(12) / parseFloat(dataSum.length);
          setDataPercentSize(temp);

          localStorage.setItem('chartIncomeStatement', JSON.stringify(tempArray4));
          setDataChart(tempArray4);

          setIsLoadingBackDropIS(false);
        }
        setIsLoadingBackDropIS(false);
      })
      .catch(function (error) {
        setIsLoadingBackDropIS(false);

        if (countErr === 3) {
          ApiService.logOut();
          history("/");
        }
        console.error('error saat fetch', error);
        showToast({ type: 'error', message: error.toString() });
      })
  }

  const getDataBS = () => {
    setIsLoadingBackDropBS(true);

    ApiService.refreshToken();
    let period = new Date(JSON.parse(localStorage.getItem("bsPeriod")));
    let params = [
      {
        'attr': 'period',
        'value': '' + (period.getMonth() + 1)
      },
      {
        'attr': 'yearPeriod',
        'value': '' + period.getFullYear()
      }
    ];

    ApiService.ReportPostByPage(paramBalanceSheet, 'PAGE', params)
      .then((response) => {
        if (response.data.code === 200 || response.data.code === 500) {
          let data = response.data.data[0].data.balanceSheet;
          localStorage.setItem('dataAsset', JSON.stringify(data[0].dataMartGroups));
          setDataAsset(data[0].dataMartGroups);
          setSumAssetIDR(data[0].sumIDR);
          setSumAssetUSD(data[0].sumUSD);

          localStorage.setItem('dataLiability', JSON.stringify(data[1].dataMartGroups));
          setDataLiabilities(data[1].dataMartGroups);
          setSumLiabiIDR(data[1].sumIDR);
          setSumLiabiUSD(data[1].sumUSD);

          setCheck(0);
        }

        setIsLoadingBackDropBS(false);
      })
      .catch(function (error) {
        setIsLoadingBackDropBS(false);

        if (countErr === 3) {
          ApiService.logOut();
          history("/");
        }
        console.error('error saat fetch', error);
        showToast({ type: 'error', message: error.toString() });
      })
  }

  return (
    <Box className='w-100' sx={{ width: '100%', p: '24px 32px', backgroundColor: '#E4E8EC' }}>
      <CustomToastContainer />
      <Grid container spacing={0} direction="column">
        <Box
          sx={{
            padding: '0px 16px 8px',
            background: '#F2F2F2',
            borderRadius: '12px',
            boxShadow: '0px 8px 8px 0px #0000001A',
            height: '115px'
          }}
        >
          <Grid container spacing={0} direction="column">
            <Grid item xs={12}>
              <Grid container item direction="row">
                <Grid item xs={12} sx={{ marginTop: '4px' }}>
                  <Stack direction={'column'} spacing={'3px'}>
                    <Breadcrumbs
                      separator={
                        <Icon
                          iconName={'arrow-left'}
                          svgIconProps={{
                            sx: {
                              width: '6px',
                              height: '6px',
                              transform: 'rotateY(180deg)',
                              alignItems: 'center',
                              padding: '0px',
                              margin: '12px 0px 8px'
                            }
                          }}
                        />
                      }
                      sx={{
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                      aria-label="breadcrumb"
                    >
                      {
                        BreadcrumbsItems.map((element, index) => {
                          return (
                            <Link
                              key={index}
                              underline="hover"
                              color="inherit"
                              href={element.link}
                              sx={{
                                fontFamily: 'Nunito',
                                fontSize: '10px',
                                fontWeight: '400',
                                letterSpacing: '0em',
                                ...element.style
                              }}
                            >
                              {element.label}
                            </Link>
                          )
                        })
                      }
                    </Breadcrumbs>

                    <div
                      style={{
                        height: '1px',
                        border: '1px solid #DDDDDD',
                        width: 'auto'
                      }}
                    />

                    <Grid container direction="row" sx={{ padding: '0px !important', alignItems: 'center', alignContent: 'center', marginTop: '3px !important' }}>
                      <Grid item xs={9}>
                        <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center', alignContent: 'center' }}>
                          <Typography
                            sx={{
                              fontFamily: 'Nunito',
                              fontSize: '16px',
                              fontWeight: '700',
                              letterSpacing: '0em',
                              textAlign: 'left',
                              color: '#083A50'
                            }}
                          >
                            Finance Dashboard -
                          </Typography>

                          <FormControl variant="standard">
                            <Select
                              labelId="lblJobType"
                              id="LblJobType"
                              value={SelectData}
                              defaultValue='0'
                              disableUnderline
                              onChange={(e) => { setSelectData(e.target.value); localStorage.setItem('dashboardMenu', JSON.stringify(e.target.value)) }}
                              sx={{ height: 40, textAlign: 'left', fontWeight: '500', fontSize: '17.5px', marginTop: '1.5px' }}
                            >
                              <MenuItem sx={{ fontSize: '12px', 'textAlign': 'center' }} id={0} value={0}>
                                Income Statement
                              </MenuItem>
                              <MenuItem sx={{ fontSize: '12px', 'textAlign': 'center' }} id={1} value={1}>
                                Balance Sheet
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Stack>

                        <Box style={{ width: '100%' }} alignItems="center">
                          <Grid container item alignItems="center">
                            <Grid item xs={9} className='text-start' alignItems="center">
                              {
                                date.toLocaleDateString("en-US", { year: "numeric", month: "long", }) === periodNow.toLocaleDateString("en-US", { year: "numeric", month: "long", }) ?
                                  <Stack direction={'row'} spacing={1} alignItems="center">
                                    <Typography
                                      sx={{
                                        color: 'var(--Primary-One, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '14px',
                                        fontstyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: 'normal'
                                      }}
                                    >
                                      {date.toLocaleDateString("en-US", { year: "numeric", month: "long", day: "2-digit", })}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        color: 'var(--Primary-One, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '14px',
                                        fontstyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: 'normal'
                                      }}
                                    >
                                      {' | '}
                                    </Typography>
                                    <LiveClock />
                                    <Typography
                                      sx={{
                                        color: 'var(--Primary-One, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '14px',
                                        fontstyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: 'normal'
                                      }}
                                    >
                                      Last Updated At: {date.toLocaleTimeString("sv-se", { hour: "2-digit", minute: "2-digit", second: "2-digit" })}
                                    </Typography>
                                  </Stack>
                                  :
                                  <></>
                              }
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>

                      <Grid item xs={3} className='text-end'>
                        <Input
                          label={'Select Period'}
                          name="period"
                          value={period}
                          onChange={(e) => { setDataPeriod(e.target.value) }}
                          type={'month'}
                          formLabelProps={{
                            sx: {
                              fontSize: '14px !important',
                            }
                          }}
                          formControlProps={{
                            sx: {
                              width: '100%',
                            }
                          }}
                          inputBaseProps={{
                            sx: {
                              borderRadius: '8px',
                              height: '32px',
                              fontSize: '14px !important',
                              textAlign: 'left',
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Grid item xs={12} style={{ width: "100%", borderRadius: "5px", marginTop: '12px' }}>
          {
            SelectData === 0 ?
              <ProfitAndLoss
                period={period}
                setPeriod={setPeriod}
                getDataIncomeStatement={getDataIncomeStatement}

                isLoadingBackDrop={isLoadingBackDropIS}
                setIsLoadingBackDrop={setIsLoadingBackDropIS}
                varIncomeStatement={varIncomeStatement}
                paramDashboard={paramDashboard}
                DataPercent={DataPercent}
                setDataPercent={setDataPercent}
                DataPercentSize={DataPercentSize}
                setDataPercentSize={setDataPercentSize}
                IncomeStatementTitle={IncomeStatementTitle}
                setIncomeStatementTitle={setIncomeStatementTitle}
                DataIncomeStatement={DataIncomeStatement}
                setDataIncomeStatement={setDataIncomeStatement}
                DataIncomeStatementSummary={DataIncomeStatementSummary}
                setDataIncomeStatementSummary={setDataIncomeStatementSummary}
                DataChart={DataChart}
                setDataChart={setDataChart}
                setDate={setDate}
              />
              :
              <BalanceSheet
                period={period}
                setPeriod={setPeriod}
                getData={getDataBS}

                isLoadingBackDrop={isLoadingBackDropBS}
                setIsLoadingBackDrop={setIsLoadingBackDropBS}
                dataAsset={dataAsset}
                setDataAsset={setDataAsset}
                sumAssetIDR={sumAssetIDR}
                setSumAssetIDR={setSumAssetIDR}
                sumAssetUSD={sumAssetUSD}
                setSumAssetUSD={setSumAssetUSD}
                dataLiabilities={dataLiabilities}
                setDataLiabilities={setDataLiabilities}
                sumLiabiIDR={sumLiabiIDR}
                setSumLiabiIDR={setSumLiabiIDR}
                sumLiabiUSD={sumLiabiUSD}
                setSumLiabiUSD={setSumLiabiUSD}
                check={check}
                setCheck={setCheck}
                paramBalanceSheet={paramBalanceSheet}
                setDate={setDate}
              />
          }
        </Grid>
      </Grid>
    </Box>
  )
})

export default DashBoard