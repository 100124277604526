import { Grid } from '@mui/material';
import React from 'react'
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';
import { useState } from 'react';
import TableY from '../../../../../components/Tables/TableY';
import { useMemo } from 'react';
import { Icon, PopUp } from '../../../../../components/ui';

function not(a, b) {
    let dataTemp = []
    a.forEach(dt => {
        let cek = true
        b.forEach(dtb => {
            if (dt.accountCode === dtb.accountCode) {
                cek = false
            }
        })

        if (cek) {
            dataTemp.push(dt)
        }
    })

    return dataTemp
}

const ModalAccountGL = (props) => {
    const [dataSelect, setDataSelect] = useState([])

    const columns = useMemo(() => [
        {
            Header: 'Account',
            columns: [
                {
                    Header: 'Account Code',
                    accessor: 'accountCode',
                },
                {
                    Header: 'Account Title',
                    accessor: 'accountTitle'
                },
                {
                    Header: 'Account Group',
                    accessor: 'accountGroup'
                },
                {
                    Header: 'Account Type',
                    accessor: 'accountType'
                }

            ]
        }
    ], [])

    const Save = () => {
        let check = JSON.parse(dataSelect).selectedRows
        var dataNew = not(props.dataCoa, check)
        let dataAcc = props.dataAccount.concat(check)

        props.setDataAccount(dataAcc)
        props.setDataCoa(dataNew)
        props.handleClick()
    }

    const mainContainer = (
        <>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                    <TableY columns={columns} data={props.dataCoa} setDataSelect={setDataSelect} />
                </Grid>
            </Grid>
        </>
    )

    return (
        <PopUp
            id='modal-id'
            icon={
                <Icon
                    iconName={'account'}
                    svgIconProps={{
                        htmlColor: '#0087C2',
                        sx: {
                            height: '24px',
                            width: '24px'
                        }
                    }}
                />
            }
            title={'Select Chart Of Account'}
            isOpen={props.isModal}
            onClose={props.handleClick}
            draggable={false}
            shrinkable={false}
            disableScrollLock={false}
            maxWidth={'lg'}
            fullWidth={true}
        >
            <div style={{ padding: '0px 6px' }}>
                {mainContainer}

                <div style={{ padding: '24px 0px 0px' }}>
                    {CustomizeButtonComponent.SelectModalButton(Save)}
                </div>
            </div>
        </PopUp>
    )
}

export default ModalAccountGL