import PropTypes from 'prop-types';
import { createNumberMask } from "text-mask-addons"
import { convertMask } from '../components/Masks/DollarInput';
import { convertMaskIDR } from '../components/Masks/RupiahInput';
import { formatCurrency } from '../services/ApiService';

const currencyPropTypes = {
  maskOptions: PropTypes.shape({
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    includeThousandsSeparator: PropTypes.bool,
    thousandsSeparatorSymbol: PropTypes.string,
    allowDecimal: PropTypes.bool,
    decimalSymbol: PropTypes.string,
    decimalLimit: PropTypes.string,
    requireDecimal: PropTypes.bool,
    allowNegative: PropTypes.bool,
    allowLeadingZeroes: PropTypes.bool,
    // integerLimit: PropTypes.number,
  })
}

const TaxIdentificationNumber = () => {
  const mask = [
    /\d/, /\d/,
    ".",
    /\d/, /\d/, /\d/,
    ".",
    /\d/, /\d/, /\d/,
    ".",
    /\d/,
    "-",
    /\d/, /\d/, /\d/,
    ".",
    /\d/, /\d/, /\d/,
  ]

  return mask
}

const ContainerNumber = () => {
  const mask = [
    /[a-zA-Z]/, /[[a-zA-Z]/, /[[a-zA-Z]/, /[[a-zA-Z]/,
    " ",
    /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,
    "-",
    /\d/
  ]

  return mask
}

const DollarMask = (props) => {
  const maskOptions = props?.maskOptions || {}
  const defaultMaskOptions = {
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 5, // how many digits allowed after the decimal
    // integerLimit: 7, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
  }

  const mask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  })

  return mask
}

const RupiahMask = (props) => {
  const maskOptions = props?.maskOptions || {}
  const defaultMaskOptions = {
    prefix: 'Rp ',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 5, // how many digits allowed after the decimal
    // integerLimit: 7, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
  }

  const mask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  })

  return mask
}

DollarMask.propTypes = currencyPropTypes
RupiahMask.propTypes = currencyPropTypes

const convertToNumeric = (val) => { return formatCurrency === 'USD'? convertMask(val) : convertMaskIDR(val); }

const convertToNominal = (val) => { return new Intl.NumberFormat(JSON.parse(localStorage.getItem("currency")), { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(val); }

const newWindowConfig = '_blank';

export {
  TaxIdentificationNumber,
  ContainerNumber,
  DollarMask,
  RupiahMask,
  convertToNominal,
  convertToNumeric,
  newWindowConfig
}