import { Grid, Box, MenuItem, Card, CardContent } from '@mui/material';
import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';

import ApiService from '../../../../services/ApiService';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import ButtonShipmentOrderReportView from './Buttons/ButtonShipmentOrderReportView';
import CustomToastContainer, { Input, Selection, showToast } from '../../../../components/ui';
import HeaderInfo from '../../../../components/Components/HeaderInfo';

const CargoManifestView = forwardRef((props, ref) => {
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const history = useNavigate();
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const paramJobType = 'regJobType/regJobType';
    const [dataJobType, setDataJobType] = useState([]);
    const [jobType, setJobType] = useState(0);

    const paramJobOwner = 'jobOwner/jobOwner';
    const [jobOwnerList, setJobOwnerList] = useState([]);
    const [PrincipleBy, setPrincipleBy] = useState(0);

    const [PeriodFrom, setPeriodFrom] = useState('');
    const [PeriodTo, setPeriodTo] = useState('');

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const BreadcrumbsItems = [
        { label: 'Infoss', link: '/Infoss', style: '' },
        { label: 'Shipment Order Report', link: '/Infoss/ShipmentOrderListReport', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'Shipment Order Report - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const GetDataJobType = () => {
        let tempArray = [{ id: 0, code: 0, name: 'All Jobs' }];
        ApiService.PostByPage(paramJobType, 1, 50)
            .then((response) => {
                if (response.data.code === 200) {
                    let arrayTemp = response.data.data.jobType;

                    arrayTemp.forEach(element => {
                        tempArray = [...tempArray, element];
                    });
                    setDataJobType(tempArray);

                    GetJobOwnerList(1, 20);
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    }

    const selectJobType = (jobType, setJobType) => {
        return (
            <Selection
                id='0'
                label={'Job Type'}
                fullWidth={true}
                placeholder={'Select Job Type'}
                value={jobType}
                onChange={(e) => handleSelectJobType(setJobType, e.target.value)}
            >
                {
                    dataJobType.map((data) => (
                        <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.code} value={data.id}>
                            {data.name}
                        </MenuItem>
                    ))
                }
            </Selection>
        )
    }

    const GetJobOwnerList = (pageNumber, pageSize) => {
        let tempArray = [{ id: 0, masterCode: 'All Principle' }];
        ApiService.PostByPage(paramJobOwner, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    let arrayTemp = response.data.data.jobOwner;
                    arrayTemp.forEach(element => {
                        tempArray = [...tempArray, element];
                    });
                    setJobOwnerList(tempArray);

                    getDateNow();
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    const selectPrincipleBy = (PrincipleBy, setPrincipleBy) => (
        <>
            <Selection
                id='0'
                label={'Principle By'}
                fullWidth={true}
                placeholder={'Select Principle By'}
                value={PrincipleBy}
                onChange={(e) => setPrincipleBy(e.target.value)}
            >
                {
                    jobOwnerList.map((data, index) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.id}>{data.masterCode}</MenuItem>
                    ))
                }
            </Selection>
        </>
    );

    function handleSelectJobType(setJobType, value) {
        setJobType(value);
    }

    function getDateNow() {
        setIsLoadingBackDrop(false);
        let dateNow = new Date()
        var formatDate = dateNow.toLocaleDateString("sv-se").split('T')[0];
        setPeriodFrom(formatDate);

        let dateNext = new Date();
        dateNext.setDate(dateNext.getDate() + 1);
        var formatDateNext = dateNext.toLocaleDateString("sv-se").split('T')[0];
        setPeriodTo(formatDateNext);
    }

    function handlePeriodFrom(value) {
        if (PeriodTo < value) {
            showToast({ type: 'error', message: 'Period From Can\'t be Greater Than Period To' });
        }
        else {
            setPeriodFrom(value);
        }
    }

    function handlePeriodTo(value) {
        if (PeriodFrom > value) {
            showToast({ type: 'error', message: 'Period To Can\'t be Smaller Than Period From' });
        }
        else {
            setPeriodTo(value);
        }
    }

    const getDataAccess = () => {
        setIsLoadingBackDrop(true);
        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'OSHOR')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);

                        GetDataJobType();
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Infoss');
                })
        }
        else {
            history('/Infoss');
        }
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationCM() {
        if (titleConfirmation === 'Print PDF') {
            PrintPDF();
        }
        else if (titleConfirmation === 'Print Excel') {
            PrintExl();
        }
        setIsModalConfirmation(false);
    }

    const PrintData = () => {
        setTitleConfirmation('Print PDF')
        setTitleModalConfirmation('Shipment Order Report');
        handleClickConfirmation();
    };

    const PrintExcel = () => {
        setTitleConfirmation('Print Excel')
        setTitleModalConfirmation('Shipment Order Report');
        handleClickConfirmation();
    }

    function PrintPDF() {
        setIsLoadingBackDrop(true)

        let isAllJob = false;
        if (jobType === 0) {
            isAllJob = true;
        }

        let variable = "PrintSOL?jobIdList=" + jobType + "&jobOwnerId=" + PrincipleBy + "&from=" + PeriodFrom + "&to=" + PeriodTo + "&isAllJobs=" + isAllJob + "&typeReport=pdf";
        ApiService.ReportPrint("Reports/Operation/Print/ShipmentOrderListReport/", variable)
            .then((response) => {
                if (response.status === 200) {
                    let rptFormat = "application/pdf";

                    const file = new Blob([response.data], { type: rptFormat });
                    const fileURL = URL.createObjectURL(file);

                    setIsLoadingBackDrop(false);
                    showToast({ type: 'success', message: 'Print PDF Shipment Order Report Success' });
                    window.open(fileURL);
                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'Can\'t Print PDF Shipment Order Report, ' + response.data.message });
                }
            })
            .catch((error) => {
                setIsLoadingBackDrop(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Print PDF Shipment Order Report, ' + error });
            });
    }

    function PrintExl() {
        setIsLoadingBackDrop(true)

        let isAllJob = false;
        if (jobType === 0) {
            isAllJob = true;
        }

        let variable = "PrintSOL?jobIdList=" + jobType + "&jobOwnerId=" + PrincipleBy + "&from=" + PeriodFrom + "&to=" + PeriodTo + "&isAllJobs=" + isAllJob + "&typeReport=xls";
        ApiService.ReportPrint("recapitulation/shipmentOrderListReport/", variable)
            .then((response) => {
                if (response.status === 200) {
                    let rptFormat = "application/vnd.ms-excel";

                    const file = new Blob([response.data], { type: rptFormat });
                    const fileURL = URL.createObjectURL(file);

                    setIsLoadingBackDrop(false);
                    showToast({ type: 'success', message: 'Print Excel Shipment Order Report Success' });
                    window.open(fileURL);
                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'Can\'t Print Excel Shipment Order Report, ' + response.data.message });
                }
            })
            .catch((error) => {
                setIsLoadingBackDrop(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Print Excel Shipment Order Report, ' + error });
            });
    }

    const ButtonComponents = () => {
        return (
            <ButtonShipmentOrderReportView
                PrintData={PrintData}
                PrintExcel={PrintExcel}
                DataRegUserAccess={DataRegUserAccess}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: 3 }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    title={'Shipment Order Report'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div className="mt-3">
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Grid
                                            container
                                            spacing={'16px'}
                                        >
                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Input
                                                    label='Periode From'
                                                    value={PeriodFrom}
                                                    onChange={(e) => handlePeriodFrom(e.target.value)}
                                                    type={'date'}
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                        }
                                                    }}
                                                />

                                                <div style={{ marginTop: '16px' }}>
                                                    {
                                                        selectPrincipleBy(PrincipleBy, setPrincipleBy)
                                                    }
                                                </div>
                                            </Grid>

                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Input
                                                    label='Periode To'
                                                    value={PeriodTo}
                                                    onChange={(e) => handlePeriodTo(e.target.value)}
                                                    type={'date'}
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                        }
                                                    }}
                                                />

                                                <div style={{ marginTop: '16px' }}>
                                                    {
                                                        selectJobType(jobType, setJobType)
                                                    }
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationCM}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </Box>
    )
})

export default CargoManifestView