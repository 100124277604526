import React from 'react';
import { Grid } from '@mui/material';
import ButtonComponent from '../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../components/Buttons/CustomizeButtonComponent';

const GeneralButton = ({
  ReloadData,
  DataRegUserAccess,
  toggleAdd,
  toggleEdit,
  DeleteData,
  PrintData,
  ExportPDF,
  ExportExcel,
  HandleStatus,
  menuCode,
  dropDownList
}) => {

  const PrintOptions = [
    { label: 'Export As', icon: 'printer', function: true, functionName: null, hidden: true },
    { label: 'PDF', icon: 'pdf-export', function: true, functionName: ExportPDF },
    { label: 'Excel', icon: 'excel', function: true, functionName: ExportExcel }
  ];

  const renderButton = (data) => {
    switch (data.accessCode) {
      case 'CRE':
        return ButtonComponent.AddButton(toggleAdd, 'New');
      case 'REI':
        return ButtonComponent.EditButton(toggleEdit, 'Data');
      case 'DEL':
        return ButtonComponent.DeleteButton(DeleteData, 'Data');
      case 'PRI':
        return ButtonComponent.PrintButton(PrintData);
      default:
        return null;
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <Grid
          container
          item
          spacing={1}
          direction="row"
          style={{ maxWidth: '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}
        >
          {ButtonComponent.ReloadButton(ReloadData)}
          {DataRegUserAccess.map((data) => (
            <React.Fragment key={data.accessId}>
              {renderButton(data)}
            </React.Fragment>
          ))}
          {CustomizeButtonComponent.CustomDropDown('Export As', PrintOptions, true)}
          {menuCode === 'MRCOAE' && (
            CustomizeButtonComponent.DropDownButtonStatus(
              HandleStatus,
              JSON.parse(localStorage.getItem('filterRCOAE')),
              dropDownList,
              'Entry For'
            )
          )}
        </Grid>
      </div>
    </div>
  );
};

export default GeneralButton;