import { Box, Grid, Breadcrumbs, Typography, Link, Stack, IconButton } from '@mui/material';
import React from 'react';
import QRCode from 'react-qr-code';
import { Icon } from '../ui';
import JobType from '../Pickers/JobTypePicker';
import { baseUrlTracking } from '../../services/ApiService';

const HeaderInfo = ({ 
    title, type = 'List', ticketId = 0, BackFunction = null, documentStatus = '',
    isJobType = false, jobTypeId = 0, handleSelect = null, dataJobType = [], 
    ButtonComponents = null, BreadcrumbsItems = [], btnPageRef
}) => {  
    const renderDocumentStatus = () => {
        if (!documentStatus) return null;

        const statusStyles = {
            ONGOING: {
                borderColor: '#FFA800',
                backgroundColor: '#FFE288',
                color: '#083A50',
            },
            REJECT: {
                borderColor: '#D20C0C',
                backgroundColor: '#F88',
                color: '#D20C0C',
            },
            APPROVED: {
                borderColor: '#007330',
                backgroundColor: '#B1FF81',
                color: '#007330',
            },
            PAID: {
                borderColor: '#007330',
                backgroundColor: '#B1FF81',
                color: '#007330',
            },
            PREVIEW: {
                borderColor: '#FC7F17',
                backgroundColor: '#FFC527',
                color: '#FC7F17',
            },
            SAVED: {
                borderColor: 'var(--Orange-Outline-1, #FEA51E)',
                backgroundColor: 'rgba(254, 165, 30, 0.15)',
                color: 'var(--Orange-Outline-1, #FEA51E)',
            },
            DEFAULT: {
                borderColor: '#585858',
                backgroundColor: '#CDCDCD',
                color: '#585858',
            },
        };

        const { borderColor, backgroundColor, color } = statusStyles[documentStatus] || statusStyles.DEFAULT;

        return (
            <Box
                sx={{
                    display: 'flex',
                    height: '24px',
                    padding: '0px 16px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                    borderRadius: '8px',
                    border: `1.5px solid ${borderColor}`,
                    backgroundColor,
                    marginTop: '2px',
                }}
            >
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        fontSize: '12px',
                        fontWeight: '800',
                        lineHeight: 'normal',
                        color,
                        cursor: 'default',
                    }}
                >
                    {documentStatus}
                </Typography>
            </Box>
        );
    };

    return (
        <Box 
            sx={{ 
                padding: '0px 16px 8px', 
                background: '#F2F2F2',
                borderRadius: '12px',
                boxShadow: '0px 8px 8px 0px #0000001A',
                height: type === 'Form' && ticketId !== 0 ? '100px' : 'fit-content',
            }}
        >
            <Grid container spacing={0} direction="column">
                <Grid item xs={12}>
                    <Grid container item direction="row" sx={{ maxHeight: type !== 'Form' ? '62px' : 'fit-content', alignItems: 'center'}}>
                        <Grid item xs={isJobType || ticketId !== 0 ? 8 : 12} sx={{ marginTop: type === 'Form' && ticketId !== 0 ? '1px' : '4px' }}>
                            <Stack direction="column" spacing="3px">
                                <Breadcrumbs 
                                    separator={
                                        <Icon 
                                            iconName="arrow-right"
                                            svgIconProps={{
                                                sx: {
                                                    width: '6px',
                                                    height: '6px',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    margin: '12px 0px 8px',
                                                },
                                            }} 
                                        />
                                    } 
                                    sx={{
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                    aria-label="breadcrumb"
                                >
                                    {
                                        BreadcrumbsItems.map((element, index) => (
                                            <Link 
                                                key={index}
                                                underline="hover"
                                                color="inherit"
                                                href={element.link}
                                                sx={{ 
                                                    fontFamily: 'Nunito',
                                                    fontSize: '10px',
                                                    fontWeight: '400',
                                                    letterSpacing: '0em',
                                                    ...element.style,
                                                }}
                                            >
                                                {element.label}
                                            </Link>
                                        ))
                                    }
                                </Breadcrumbs>

                                <Box sx={{ height: '1px', border: '1px solid #DDDDDD', width: 'auto' }} />

                                {
                                    type === 'List' ? 
                                        (
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Nunito',
                                                    fontSize: '16px',
                                                    fontWeight: '700',
                                                    letterSpacing: '0em',
                                                    textAlign: 'left',
                                                    color: '#083A50',
                                                    marginTop: '4px !important',
                                                }}
                                            >
                                                {title}
                                            </Typography>
                                        ) 
                                    : 
                                        (
                                            <Stack direction="row" spacing="16px" sx={{ marginTop: '6px !important', alignItems: 'center' }}>
                                                <IconButton 
                                                    sx={{ 
                                                        background: '#0087C2', 
                                                        height: '20px', 
                                                        width: '20px',
                                                        marginTop: '2px !important',
                                                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
                                                        '&:hover': {
                                                            background: '#0087C2',
                                                            boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.20)',
                                                        },
                                                    }} 
                                                    onClick={BackFunction}
                                                >
                                                    <Icon
                                                        iconName="arrow-left"
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#F2F2F2',
                                                            sx: { height: '12px', width: '12px' },
                                                        }}
                                                    />
                                                </IconButton>

                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Nunito',
                                                        fontSize: '16px',
                                                        fontWeight: '700',
                                                        letterSpacing: '0em',
                                                        textAlign: 'left',
                                                        color: '#083A50',
                                                        marginTop: '4px !important',
                                                        cursor: 'default',
                                                    }}
                                                >
                                                    {title}
                                                </Typography>

                                                {renderDocumentStatus()}
                                            </Stack>
                                        )
                                }
                            </Stack>
                        </Grid>

                        {
                            (isJobType || ticketId !== 0) && (
                                <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    {
                                        isJobType ? 
                                            (
                                                <Box sx={{ marginTop: '8px' }}>
                                                    <JobType
                                                        jobTypeId={jobTypeId}
                                                        handleSelect={handleSelect}
                                                        dataJobType={dataJobType}
                                                    />
                                                </Box>
                                            ) 
                                        : 
                                            (
                                                ticketId !== 0 && (
                                                    <Stack direction="column" sx={{ alignItems: 'center', justifyContent: 'center', width: 'fit-content' }}>
                                                        <QRCode
                                                            style={{ maxHeight: "70px", width: "fit-content", marginTop: '8px', padding: '0px' }}
                                                            value={`${baseUrlTracking}${ticketId}`}
                                                        />
                                                        <Typography
                                                            sx={{
                                                                color: 'var(--Blue-Secondary-1, #2B4957)',
                                                                fontFamily: 'Nunito',
                                                                fontSize: '8px',
                                                                fontWeight: '400',
                                                                lineHeight: 'normal',
                                                                cursor: 'default',
                                                                marginTop: '5px',
                                                            }}
                                                        >
                                                            Scan Here For Shipment Tracking
                                                        </Typography>
                                                    </Stack>
                                                )
                                            )
                                    }
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>

                {
                    type === 'List' && ButtonComponents && (
                        <Box ref={btnPageRef} sx={{ marginTop: '8px', height: 'fit-content !important' }}>
                            <ButtonComponents />
                        </Box>
                    )
                }
            </Grid>
        </Box>
    );
};

export default HeaderInfo;