import { Grid } from '@mui/material';
import React from 'react';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';

const ButtonListReceiptPPh23 = ({
  ReloadData,
  DataRegUserAccess,
  Add,
  Edit,
  DeleteData,
  DropDownTitle,
  PrintData,
  UnVerify,
  Adjustment,
  HandleStatus
}) => {

  const renderButton = (data) => {
    switch (data.accessCode) {
      case 'CRE':
        return ButtonComponent.AddButton(Add, 'New');
      case 'REI':
        return ButtonComponent.EditButton(Edit, 'Data');
      case 'DEL':
        return DropDownTitle !== 'Deleted' ? ButtonComponent.DeleteButton(DeleteData, 'Data') : null;
      case 'PRI':
        return DropDownTitle !== 'Deleted' ? ButtonComponent.PrintButton(PrintData, 'Rekap') : null;
      default:
        return null;
    }
  };

  return (
    <div className="row">
      <div className='col-12'>
        <Grid container item spacing={1} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
          {ButtonComponent.ReloadButton(ReloadData)}

          {DataRegUserAccess.map((data) => (
            <React.Fragment key={data.accessId}>
              {renderButton(data)}
            </React.Fragment>
          ))}

          {ButtonComponent.UnVerifyButton(UnVerify, 'Un-Verify')}
          {ButtonComponent.AdjustButton(Adjustment, 'Adjustment')}
          {CustomizeButtonComponent.DropDownButtonStatus(HandleStatus, DropDownTitle)}
        </Grid>
      </div>
    </div>
  );
};

export default ButtonListReceiptPPh23;