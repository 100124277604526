import React, { forwardRef, useEffect, useState } from 'react'
import { Box, FormControlLabel, Grid, Radio, RadioGroup, MenuItem, IconButton, Backdrop, CardContent, Card, Typography, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import ModalSearchCustomer from './components/ModalSearchCustomer';

import { useFormik } from 'formik';
import ApiService from '../../../../services/ApiService';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Icon, Input, Selection, showToast } from '../../../../components/ui';

const RekapMemorialReport = forwardRef(() => {
  const history = useNavigate()

  const date = new Date()
  const dateString = date.toLocaleDateString("sv-se").split('T')[0]

  const [isLoading, setIsLoading] = useState(true)

  const [activeModal, setActiveModal] = useState('')
  const [selectedCustomer, setSelectedCustomer] = useState({})

  const [dataRegUserAccess, setDataRegUserAccess] = useState([]);
  const [contactTypeList, setContactTypeList] = useState([]);
  const [memorialTypeList, setMemorialTypeList] = useState([]);

  const BreadcrumbsItems = [
    { label: 'Infoss', link: '/Infoss', style: '' },
    { label: 'Rekap Memorial Report', link: '/Infoss/RekapMemorialReport', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useEffect(() => {
    document.title = 'Rekap Memorial Report - ' + JSON.parse(localStorage.getItem('branchName'));

    getDataAccess()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const errorToast = (location) => {
    showToast({ type: 'error', message: `Can't get ${location} data! please contact Administrator` })
  }

  const getDataAccess = () => {
    let params = [];
    let pId = 0;
    let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
    let hasAccess = false

    // TODO Change menu code
    const indexMenu = listMenu.findIndex((elm) => elm.code === 'OBOCO')
    if (indexMenu !== -1) {
      pId = listMenu[indexMenu].id
      hasAccess = true
    }

    if (hasAccess) {
      params = [
        ...params,
        { attr: "permissionId", value: "" + pId },
        { attr: "userId", value: "" + JSON.parse(localStorage.getItem("userId")) },
        { attr: "roleId", value: "" + JSON.parse(localStorage.getItem("roleId")) }
      ];

      ApiService.IdentityPostByPage('regUserAccess/regUserAccess', "PAGE", 1, 100, params, [])
        .then((response) => {
          if (response.data.code === 200) {
            setDataRegUserAccess(response.data.data.userAccess);

            getAdditionalData()
          }
        })
        .catch(function (error) {
          setIsLoading(false)
          console.error("error saat fetch", error);
          history('/Infoss');
        });
    } else {
      setIsLoading(false)
      history('/Infoss');
    }
  };

  const getAdditionalData = async () => {
    setIsLoading(true)
    try {
      const [resMT, resCT] = await Promise.all([
        ApiService.DynamicPostByPage('memorialType/memorialType', 'PAGE', 1, 999, []),
        ApiService.DynamicPostByPage('contactType/contactType', 'PAGE', 1, 999, [])
      ])

      if (resMT.data.code === 200) {
        const excludesData = ['COST', 'ADJUSTMENT']
        const data = resMT.data.data.memorialType.filter((el) => excludesData.includes(el?.name?.toUpperCase()))
        setMemorialTypeList(data)

        formik.setFieldValue('memorialType', data[0]?.id)
      } else {
        errorToast('memorial type')
        setIsLoading(false)
      }

      if (resCT.data.code === 200) {
        let data = resCT.data.data.contactType
        data = data.filter(data => data.id <= 10)
        setContactTypeList(data)
        setIsLoading(false)
      } else {
        setIsLoading(false)
        errorToast('contact type')
      }
    } catch (error) {
      setIsLoading(false)
      errorToast('additional')
      console.log(error)
    }
  }

  const handlePrint = (fileType) => {
    const payload = formik.values

    setIsLoading(true);
    let contactId = 0;
    let contactType = '';
    let memoType = 0;

    if (payload.memorialType === 2) {
      if (payload?.isCustomCustomer && !selectedCustomer?.contactId) {
        return showToast({ type: 'error', message: 'Please select customer.' })
      }

      if (!payload?.date) {
        return showToast({ type: 'error', message: 'Please select correct date.' })
      }

      if (payload.isCustomCustomer === true) {
        contactId = selectedCustomer.contactId;
      }

      if (payload.customerType !== 0) {
        contactTypeList.forEach(element => {
          if (element.id === payload.customerType) {
            contactType = element.name;
          }
        });
      }

      memoType = 0;
    }
    else if (payload.memorialType === 4) {
      memoType = 1;
    }

    let variable = 'PrintRekapMemorial?typeReport=' + fileType + '&typeMemo=' + memoType;
    let params = [
      {
        "attr": "EntryDate",
        "value": '' + payload.date
      },
      {
        "attr": "CustomerType",
        "value": '' + contactType
      },
      {
        "attr": "CustomerId",
        "value": '' + contactId
      }
    ];
    ApiService.ReportPrint("Reports/Finance/Print/Other/RekapMemorialReport/", variable, params)
      .then((response) => {
        if (response.status === 200) {
          let rptFormat = "application/pdf";
          if (fileType === "xls") {
            rptFormat = "application/vnd.ms-excel"
          }
          const file = new Blob([response.data], { type: rptFormat });
          const fileURL = URL.createObjectURL(file);

          setIsLoading(false);
          showToast({ type: 'success', message: 'Print Rekap Memorial Report Success' });
          window.open(fileURL);
        }
        else {
          setIsLoading(false);
          showToast({ type: 'error', message: 'Can\'t Print Rekap Memorial Report, ' + response.data.message });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        showToast({ type: 'error', message: 'Can\'t Print Rekap Memorial Report, ' + error });
      });
  }

  const isAdjustment = () => {
    let isAdjustmentType = false

    const selectedMemorial = formik.values.memorialType
    const memorialData = memorialTypeList.find((x) => x.id === selectedMemorial)
    if (memorialData?.name?.toUpperCase() === 'ADJUSTMENT') {
      isAdjustmentType = true
    }

    return isAdjustmentType
  }

  const toggleModal = (modal) => {
    setActiveModal(modal)
  }

  const formik = useFormik({
    initialValues: {
      memorialType: 0,
      customerType: 0,
      isCustomCustomer: false,
      date: dateString,
    },
    validationSchema: undefined,
  })

  const ButtonComponents = () => {
    return (
      <div className="row" spacing={'12px'}>
        <div className='col-12' spacing={'12px'}>
          <Grid container item spacing={'12px'} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
            {dataRegUserAccess.map((elm, index) => (
              <React.Fragment key={index}>
                {elm.accessCode === 'PRI' && ButtonComponent.PrintButton(() => handlePrint('pdf'))}
                {elm.accessCode === 'PRI' && ButtonComponent.ExcelButton(() => handlePrint('xls'), 'Excel')}
              </React.Fragment>
            ))}
          </Grid>
        </div>
      </div>
    )
  }
  return (
    <>
      <Box className='w-100' sx={{ width: '100%', p: 3 }}>
        <CustomToastContainer />
        <Grid container spacing={0} direction="column">
          <HeaderInfo
            title={'Rekap Memorial Report'}
            isJobType={false}
            BreadcrumbsItems={BreadcrumbsItems}
            ButtonComponents={ButtonComponents}
          />

          <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
            <div style={{ marginTop: '24px' }}>
              <Grid
                container
                spacing={'32px'}
              >
                <Grid
                  item
                  xl={6}
                  lg={6}
                  sm={6}
                  xs={12}
                >
                  <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                    <CardContent>
                      <Selection
                        id='0'
                        fullWidth={true}
                        label="Memorial Type"
                        value={formik.values.memorialType}
                        placeholder={'Select Memorial Type'}
                        onChange={(e) => formik.setFieldValue('memorialType', e.target.value)}
                      >
                        {
                          memorialTypeList.map((mt) => (
                            <MenuItem sx={{ fontSize: '12px' }} key={mt.id} value={mt.id}>{mt.name}</MenuItem>
                          ))
                        }
                      </Selection>

                      {
                        formik.values.memorialType === 2 && (
                          <Selection
                            id='0'
                            fullWidth={true}
                            label="Customer Type"
                            value={formik.values.customerType}
                            placeholder={'Select Customer Type'}
                            formControlProps={{ sx: { marginTop: '16px' } }}
                            onChange={(e) => {
                              formik.setFieldValue('customerType', e.target.value)
                              formik.setFieldValue('isCustomCustomer', false)
                              setSelectedCustomer({})
                            }}
                          >
                            <MenuItem sx={{ fontSize: '12px' }} value={0}>All Customer Type</MenuItem>
                            {
                              contactTypeList.map((ct) => (
                                <MenuItem sx={{ fontSize: '12px' }} key={ct.id} value={ct.id}>{ct.name}</MenuItem>
                              ))
                            }
                          </Selection>
                        )
                      }

                      {
                        formik.values.customerType !== 0 && formik.values.memorialType === 2 && (
                          <>
                            <div style={{ marginTop: '16px' }}>
                              <Typography
                                sx={{
                                  color: 'var(--Primary-One, #083A50)',
                                  fontFamily: 'Nunito',
                                  fontSize: '14px',
                                  fontStyle: 'normal',
                                  fontWeight: '600',
                                  lineHeight: '19.6px',
                                }}
                              >
                                Customer
                              </Typography>

                              <RadioGroup
                                row
                                name="variant"
                                disabled={isAdjustment()}
                                aria-labelledby="variant-option"
                                value={formik.values.isCustomCustomer}
                                onChange={(e) => {
                                  formik.setFieldValue('isCustomCustomer', (e.target.value === 'true'))
                                  setSelectedCustomer({})
                                }}
                              >
                                <FormControlLabel
                                  sx={{
                                    color: 'var(--Blue-Primary-1, #083A50)',
                                    fontFamily: 'Nunito',
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '20px',
                                  }}
                                  value={false}
                                  key={1}
                                  control={<Radio size='small' />}
                                  label={
                                    <Typography
                                      sx={{
                                        color: 'var(--Blue-Primary-1, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '12px !important',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: '20px',
                                      }}
                                    >
                                      All
                                    </Typography>
                                  }
                                />

                                <FormControlLabel
                                  sx={{
                                    color: 'var(--Blue-Primary-1, #083A50)',
                                    fontFamily: 'Nunito',
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '20px',
                                  }}
                                  value={true}
                                  key={2}
                                  control={<Radio size='small' />}
                                  label={
                                    <Typography
                                      sx={{
                                        color: 'var(--Blue-Primary-1, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '12px !important',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: '20px',
                                      }}
                                    >
                                      Custom
                                    </Typography>
                                  }
                                />
                              </RadioGroup>
                            </div>

                            {
                              formik.values.isCustomCustomer && (
                                <Input
                                  fullWidth
                                  label='To'
                                  variant="standard"
                                  placeholder='Search Customer'
                                  disabled
                                  InputLabelProps={{ shrink: true }}
                                  formControlProps={{ sx: { width: '100%', marginTop: '16px' } }}
                                  value={selectedCustomer?.id ? `${selectedCustomer?.code || 0} - ${selectedCustomer?.contactName}` : ''}
                                  inputBaseProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={() => toggleModal('customer')}
                                          disabled={isAdjustment()}
                                        >
                                          <Icon
                                            iconName={'search'}
                                            svgIconProps={{
                                              fontSize: 'small',
                                              htmlColor: '#0087C2',
                                              sx: {
                                                height: '12px',
                                                width: '12px'
                                              }
                                            }}
                                          />
                                        </IconButton>
                                      </InputAdornment>
                                    )
                                  }}
                                />
                              )
                            }
                          </>
                        )
                      }

                      <Input
                        fullWidth
                        type='date'
                        variant="standard"
                        label="As Per Date"
                        InputLabelProps={{ shrink: true }}
                        value={formik.values?.date || ''}
                        formControlProps={{ sx: { width: '100%', marginTop: '16px' } }}
                        onChange={(e) => { formik.setFieldValue('date', (e.target.value)) }}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Box>

      {
        isLoading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <LoadingSpinner />
          </Backdrop>
        )
      }

      {activeModal === 'customer' && (
        <ModalSearchCustomer
          isModal={activeModal === 'customer'}
          toggleModal={toggleModal}
          dataSelected={selectedCustomer}
          onSelect={setSelectedCustomer}
          errorToast={errorToast}
          formValue={formik.values}
        />
      )}
    </>
  )
})

export default RekapMemorialReport