import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material'

import TableReport from './TableReport';
import { Icon } from '../../../../components/ui';

const AccountingFinanceSection = ({ shipmentData, tableINV, tableOR, tablePR, tablePV, tableTR }) => {
  return (
    <Accordion
      style={{ borderRadius: '12px', padding: '0px 24px', border: '1px solid var(--Disable-Grey-Text-1, #A5ACBE)' }}
    >
      <AccordionSummary
        expandIcon={
          <Icon
            iconName={'arrow-down'}
            svgIconProps={{
              htmlColor: '#0087C2',
              sx: {
                height: '24px',
                width: '24px'
              }
            }}
          />
        }
        aria-controls="accfin-content"
        id="accfin-header"
      >
        <Typography
          sx={{
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '20px',
          }}
        >
          Accounting & Finance
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Grid container spacing={'48px'}>
          <Grid item xs={6}>
            <TableReport
              shipmentData={shipmentData}
              title={'Invoices'}
              params={'invoice/invoice'}
              tableData={tableINV}
              module={'Operation'}
              target={'inv'}
            />
          </Grid>

          <Grid item xs={6}>
            <TableReport
              shipmentData={shipmentData}
              title={'Official Receipt'}
              params={'officialReceipt/officialReceipt'}
              tableData={tableOR}
              module={'Finance'}
              target={'or'}
            />
          </Grid>

          <Grid item xs={6}>
            <TableReport
              shipmentData={shipmentData}
              title={'Payment Request'}
              params={'paymentRequest/paymentRequest'}
              tableData={tablePR}
              module={'Operation'}
              target={'pr'}
            />
          </Grid>

          <Grid item xs={6}>
            <TableReport
              shipmentData={shipmentData}
              title={'Payment Voucher'}
              params={'paymentVoucher/paymentVoucher'}
              tableData={tablePV}
              module={'Finance'}
              target={'pv'}
            />
          </Grid>

          <Grid item xs={6}>
            <TableReport
              shipmentData={shipmentData}
              title={'Temporary Receipt'}
              params={'temporaryReceipt/temporaryReceipt'}
              tableData={tableTR}
              module={'Finance'}
              target={'tr'}
            />
          </Grid>
        </Grid>

      </AccordionDetails>
    </Accordion>
  )
}

export default AccountingFinanceSection