import React, { useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import { useNavigate } from 'react-router-dom';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import { Grid, Box, MenuItem, Card, CardContent, InputAdornment, IconButton } from '@mui/material';

import ApiService from '../../../../services/ApiService';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import ButtonIncomeAndCostVarianceView from './Buttons/ButtonIncomeAndCostVarianceView';
import SelectDataShipment from './Selects/SelectDataShipment';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Icon, Input, Selection, showToast } from '../../../../components/ui';
import { setDateFormat } from '../../../../utils/date';

const IncomeAndCostVarianceView = forwardRef((props, ref) => {
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const history = useNavigate();
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [isCompany, setIsCompany] = useState('C');
    const CustomCompanyData = [
        { id: 1, code: 'C', name: 'Per Company' },
        { id: 2, code: 'R', name: 'Per Regional' },
    ];
    const paramJobType = 'jobType/jobType';
    const [jobTypeData, setJobTypeData] = useState([]);
    const [jobTypeId, setJobTypeId] = useState(1);
    const [isAll, setIsAll] = useState(true);
    const CustomSalesData = [
        { id: 1, code: true, name: 'All Shipment Order' },
        { id: 2, code: false, name: 'Custom Shipment Order' },
    ]
    const [shipmentId, setShipmentId] = useState(0);
    const [shipmentNo, setShipmentNo] = useState('');

    const paramS = 'shipmentOrder/shipmentOrder';
    const [isLoadingS, setIsLoadingS] = useState(false);
    const [isModalS, setIsModalS] = useState(false);
    const [dataS, setDataS] = useState([]);
    const [titleModalS, setTitleModalS] = useState('');
    const [columnDataS, setColumnDataS] = useState([]);
    const [SelectedDataS, setSelectedDataS] = useState({});
    const [totalRowsS, setTotalRowsS] = useState(50);
    const [dataMapS, setDataMapS] = useState([]);

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const BreadcrumbsItems = [
        { label: 'Infoss', link: '/Infoss', style: '' },
        { label: 'Income And Cost Variance', link: '/Infoss/IncomeAndCostVarianceReport', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'Income And Cost Variance - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDataAccess = () => {
        setIsLoadingBackDrop(true);
        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'OSHOR')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);

                        getDataJobType(1, 100);
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Infoss');
                })
        }
        else {
            history('/Infoss');
        }
    }

    const getDataJobType = (pageNumber, pageSize, filters, orderBy) => {
        ApiService.DynamicPostByPage(paramJobType, 'SEARCH', pageNumber, pageSize, [], filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setJobTypeData(response.data.data.jobType);

                }
                getDateNow();
            })
            .catch(function (error) {
                console.error('error saat fetch', error)
            })
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationCOSC() {
        if (titleConfirmation === 'Print') {
            PrintPDF();
        }
        setIsModalConfirmation(false);
    }

    const checkInput = () => {
        let check = 0;

        if (isAll === false && shipmentId === 0) {
            check = 1;
        }

        if (check === 1) {
            showToast({ type: 'error', message: 'Please Select Data Shipment Order!' });
        }

        return check;
    }

    const PrintData = () => {
        if (checkInput() === 0) {
            setTitleConfirmation('Print')
            setTitleModalConfirmation('Income And Cost Variance');
            handleClickConfirmation();
        }
    };

    function PrintPDF() {
        setIsLoadingBackDrop(true);

        let variable = "PrintCAR?tanggal=" + isCompany + "&typeReport=pdf";
        ApiService.ReportPrint("recapitulation/recapitulationCashAdvanceReport/", variable)
            .then((response) => {
                if (response.status === 200) {
                    let rptFormat = "application/pdf";

                    const file = new Blob([response.data], { type: rptFormat });
                    const fileURL = URL.createObjectURL(file);

                    setIsLoadingBackDrop(false);
                    showToast({ type: 'success', message: 'Print Income And Cost Variance Success' });
                    window.open(fileURL);
                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'Can\'t Print Income And Cost Variance, ' + response.data.message });
                }
            })
            .catch((error) => {
                setIsLoadingBackDrop(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Print Income And Cost Variance, ' + error });
            });
    }

    function getDateNow() {
        setIsLoadingBackDrop(false);
        setDateFormat(new Date(), setDateFrom);
        setDateFormat(new Date(), setDateTo);
    }

    function handleDate(source, value) {
        if (source === 'From') {
            if (value > dateTo) {
                showToast({ type: 'error', message: 'Period From Can\'t Be Greater Than Period To' });
            }
            else {
                setDateFrom(value);
            }
        }
        else {
            if (value < dateFrom) {
                showToast({ type: 'error', message: 'Period To Can\'t Be Smaller Than Period From' });
            }
            else {
                setDateTo(value);
            }
        }
    }

    function handleIsAll(value) {
        setIsAll(value);
        EmptyShipment();
    }

    function EmptyShipment() {
        setShipmentId(0);
        setShipmentNo('');
    }

    const handleClickShipment = () => {
        setTitleModalS('Shipment Order');
        if (isModalS === false) {
            getDataShipment(1, 50);
            setIsModalS(true);
        }
        else {
            setIsModalS(false);
        }
    }

    const getDataShipment = (pageNumber, pageSize, filters, orderBy) => {
        setIsLoadingS(true);
        let params = [{ 'attr': 'jobTypeId', 'value': '' + jobTypeId }]
        ApiService.OperationDynamicPostByPage(paramS, 'SEARCH', pageNumber, pageSize, params, filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataS({})
                    setDataS(response.data.data.shipmentOrder);
                    let temp = response.data.data.shipmentOrder
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapS(indexed)
                    setColumnDataS(response.data.data.columns.headerColumns)
                    setTotalRowsS(response.data.totalRowCount)
                    setIsLoadingS(false)
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error)
                setIsLoadingS(false)
            })
    }

    const ButtonComponents = () => {
        return (
            <ButtonIncomeAndCostVarianceView
                PrintData={PrintData}
                DataRegUserAccess={DataRegUserAccess}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: 3 }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    title={'Income And Cost Variance'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div style={{ marginTop: '24px' }}>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Selection
                                            id='0'
                                            fullWidth={true}
                                            value={isCompany}
                                            label={'Custom Company'}
                                            placeholder={'Select Custom Company'}
                                            onChange={(e) => setIsCompany(e.target.value)}
                                        >
                                            {
                                                CustomCompanyData.map((data) => (
                                                    <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                                        {data.name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Selection>

                                        <Grid
                                            container
                                            spacing={'16px'}
                                        >
                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Input
                                                    type='date'
                                                    value={dateFrom}
                                                    label="Period From Date"
                                                    onChange={(e) => handleDate("From", e.target.value)}
                                                    formControlProps={{ sx: { width: '100%', marginTop: '16px' } }}
                                                />
                                            </Grid>

                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Input
                                                    type='date'
                                                    value={dateTo}
                                                    label="Period To Date"
                                                    onChange={(e) => handleDate("To", e.target.value)}
                                                    formControlProps={{ sx: { width: '100%', marginTop: '16px' } }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Grid
                                            container
                                            spacing={'16px'}
                                        >
                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Selection
                                                    id='0'
                                                    fullWidth={true}
                                                    value={jobTypeId}
                                                    label={'Shipment Status'}
                                                    placeholder={'Select Shipment Status'}
                                                    onChange={(e) => setJobTypeId(e.target.value)}
                                                >
                                                    {
                                                        jobTypeData.map((data) => (
                                                            <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.id}>
                                                                {data.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Selection>
                                            </Grid>

                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Selection
                                                    id='0'
                                                    fullWidth={true}
                                                    value={isAll}
                                                    label={'Shipment Order Change'}
                                                    placeholder={'Select Shipment Order Change'}
                                                    onChange={(e) => handleIsAll(e.target.value)}
                                                >
                                                    {
                                                        CustomSalesData.map((data) => (
                                                            <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                                                {data.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Selection>
                                            </Grid>
                                        </Grid>

                                        <Input
                                            variant="standard"
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            disabled
                                            value={shipmentNo}
                                            label="Shipment No"
                                            type='text'
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px'
                                                }
                                            }}
                                            inputBaseProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => EmptyShipment()}
                                                            hidden={shipmentId ? false : true}
                                                        >
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>

                                                        <IconButton
                                                            onClick={() => handleClickShipment()}
                                                            hidden={isAll}
                                                        >
                                                            <Icon
                                                                iconName={'search'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }

            {
                isModalS && (
                    <SelectDataShipment
                        isModal={isModalS}
                        titleModal={titleModalS}
                        handleClick={handleClickShipment}
                        isLoading={isLoadingS}
                        data={dataS}
                        getData={getDataShipment}
                        columnData={columnDataS}
                        dataMap={dataMapS}
                        totalRows={totalRowsS}
                        SelectedData={SelectedDataS}
                        setSelectedData={setSelectedDataS}

                        setShipmentId={setShipmentId}
                        setShipmentNo={setShipmentNo}
                    />
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationCOSC}
                    />
                )
            }
        </Box>
    )
})

export default IncomeAndCostVarianceView