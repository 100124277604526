import React, { useEffect, useImperativeHandle, useState, forwardRef } from "react";
import { Box, Grid, CardContent, Card } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import Backdrop from '@mui/material/Backdrop';

import PaymentPPh23Header from "./Content/PaymentPPh23Header";
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ButtonCreateEditPaymentPPh23 from "./Component/ButtonCreateEditPaymentPPh23";
import ApiService, { branchId, companyId, countryId, userCode, userId, username } from "../../../../services/ApiService";
import PaymentPPh23Detail from "./Content/PaymentPPh23Detail";
import HeaderInfo from "../../../../components/Components/HeaderInfo";
import ButtonInfo from '../../../../components/Components/ButtonInfo';
import { convertToNominal } from '../../../../utils/masks.jsx';
import { defaultDate } from "../../../../utils/date";
import CustomToastContainer, { showToast } from "../../../../components/ui";

const CreateEditPaymentPPh23 = forwardRef((props, ref) => {
  const dataLocal = JSON.parse(localStorage.getItem(username + "-DataPaymentPPH23"));

  const { pvPphId } = useParams();
  const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
  const [check, setCheck] = useState(0);

  const param = "paymentVoucherPph23/paymentVoucherPph23";
  const [dataJasa, setDataJasa] = useState([]);
  const history = useNavigate();

  const [reference, setReference] = useState("");
  const [paymentNo, setPaymentNo] = useState("");
  const [pvId, setPvId] = useState(0);
  const [pvNo, setPvNo] = useState("");
  const [pvRef, setPvRef] = useState("");
  const [jasaLain, setJasaLain] = useState("");
  const [jasaLainId, setJasaLainId] = useState(0);
  const [jasaLainAmount, setJasaLainAmount] = useState(0);
  const [remarks, setRemarks] = useState("");
  const [nameWP, setNameWP] = useState("");
  const [npwp, setNpwp] = useState("");
  const [address, setAddress] = useState("");
  const [approvedByPosition, setApprovedByPosition] = useState("");
  const [approvedBy, setApprovedBy] = useState("");
  const [buktiPotong, setBuktiPotong] = useState("");
  const [printedOn, setPrintedOn] = useState("");
  const [totalPph23Amount, setTotalPph23Amount] = useState(0);
  const [pph23Amount, setPph23Amount] = useState(convertToNominal(0));
  const [jenisPenghasilan, setJenisPenghasilan] = useState('D');
  const [jumlahPenghasilan, setJumlahPenghasilan] = useState(convertToNominal(0));
  const [tarifTambahan, setTarifTambahan] = useState(0);
  const [tarif, setTarif] = useState(15);
  const [pphPotongan, setPPhPotongan] = useState(convertToNominal(0));
  const [isEdit, setIsEdit] = useState(false);

  const paramRegUserAccess = 'regUserAccess/regUserAccess';
  const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

  let checked = 0;
  const [documentStatus, setDocumentStatus] = useState('');

  const BreadcrumbsItems = [
    { label: 'Infoss', link: '/Infoss', style: '' },
    { label: 'Payment Voucher PPh23', link: '/Infoss/paymentVoucherPph23', style: '' },
    { label: 'Create Payment Voucher PPh23', link: '/Infoss/paymentVoucherPph23/Create', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  const BreadcrumbsEditItems = [
    { label: 'Infoss', link: '/Infoss', style: '' },
    { label: 'Payment Voucher PPh23', link: '/Infoss/paymentVoucherPph23', style: '' },
    { label: 'Detail Payment Voucher PPh23', link: '/Infoss/paymentVoucherPph23/' + pvPphId, style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useEffect(() => {
    document.title = 'Payment Voucher PPh23 - Detail - ' + JSON.parse(localStorage.getItem('branchName'))
    getDataAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    localSave() {
      saveLocalData();
    }
  }));

  function handleDocumentStatus(rowStatus = 'ACT', isApproved = false) {
    if (rowStatus !== 'ACT') {
      setDocumentStatus('DELETED');
    }
    else if (isApproved) {
      setDocumentStatus('APPROVED');
    }
    else {
      setDocumentStatus('ONGOING');
    }
  }

  const saveLocalData = () => {
    let id = 0;
    if (pvPphId) {
      id = pvPphId;
    }
    const payload = {
      paymentVoucherPph23: {
        rowStatus: "ACT",
        countryId: countryId,
        companyId: companyId,
        branchId: branchId,
        id: id,
        no: 0,
        no2: paymentNo,
        reference: reference,
        currRefNo: 0,
        currRefNo2: "",
        pvId: pvId,
        isSettle: false,
        settledOn: defaultDate,
        jasaLain: jasaLain,
        jasaLain_Id: jasaLainId,
        jasaLainAmount: jasaLainAmount,
        sewaAmount: 0,
        remarks: remarks,
        printing: 0,
        printiedOn: printedOn,
        deleted: false,
        deletedby: "",
        deletedOn: defaultDate,
        approvedBy: approvedBy,
        rePrintApproved: false,
        rePrintApprovedOn: defaultDate,
        rePrintApprovedBy: 0,
        idLama: 0,
        jasaKonsultasiAmount: 0,
        jasaManajemenAmount: 0,
        jasaTeknikAmount: 0,
        nameWP: nameWP,
        npwp: npwp,
        address: address,
        approvedByPosition: approvedByPosition,
        pvpPh23Type: "",
        prId: 0,
        prDetailId: 0,
        jasaLain6_Id: 0,
        jasaLainAmount6: 0,
        jasaLain2_Id: 0,
        jasaLainAmount2: 0,
        jasaLain3_Id: 0,
        jasaLainAmount3: 0,
        jasaLain4_Id: 0,
        jasaLainAmount4: 0,
        jasaLain5_Id: 0,
        jasaLainAmount5: 0,
        buktiPotong: buktiPotong,
        printPvYear: 0,
        printPvMonth: 0,
        verify: false,
        verifiedOn: defaultDate,
        dividenAmount: 0,
        bungaAmount: 0,
        royaltiAmount: 0,
        hadiahAmount: 0,
        nonNPWP: false,
        userId: userId,
        userCode: userCode
      }
    };

    localStorage.setItem(username + "-DataPaymentPPH23", JSON.stringify(payload));
  }

  const getLocalData = () => {
    if (pvPphId) {
      setIsEdit(true);
    }
    localStorage.removeItem(username + '-DataPaymentPPH23');
    setTimeout(function () { setIsLoadingBackDrop(false); }, 1250);
  }

  const getDataAccess = () => {
    let params = [];
    let pId = 0;
    let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
    let hasAccess = false
    let tempArray = [];

    const indexMenu = listMenu.findIndex((elm) => elm.code === 'FPVP')
    if (indexMenu !== -1) {
      pId = listMenu[indexMenu].id
      hasAccess = true
    }

    if (hasAccess) {
      params = [
        ...params,
        { 'attr': 'permissionId', 'value': '' + pId },
        { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
        { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
      ]
      ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
        .then((response) => {
          if (response.data.code === 200) {
            tempArray = response.data.data.userAccess;
            setDataRegUserAccess(response.data.data.userAccess);

            tempArray.forEach(element => {
              if (element.accessCode === 'CRE' || element.accessCode === 'UPD') {
                checked = 1;
              }
            });

            setCheck(checked);

            if (dataLocal) {
              setIsLoadingBackDrop(true);
              setTimeout(function () { getLocalData(); }, 2000);
            }
            else {
              getDataJasa(1, 50);
              handleDocumentStatus();
            }
          }
          else {
            handleBack();
          }
        })
        .catch(function (error) {
          console.error('error saat fetch', error)
          showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
        })
    }
    else {
      history('/Infoss');
    }
  }

  const getDataJasa = (pageNumber, pageSize) => {
    if (!dataLocal) {
      setIsLoadingBackDrop(true);
    }

    ApiService.PostByPage("jasaLainPph23/jasaLainPph23", pageNumber, pageSize)
      .then((response) => {
        if (response.data.code === 200) {
          setDataJasa(response.data.data.jasaLainPph23);

          if (!dataLocal) {
            setIsLoadingBackDrop(false);
          }
        }
        else {
          handleBack();
        }
      })
      .catch(function (error) {
        console.error("error saat fetch", error);
      });
  };

  const handleSave = () => {
    let id = 0;
    if (pvPphId) {
      id = pvPphId;
    }
    const payload = {
      paymentVoucherPph23: {
        countryId: countryId,
        companyId: companyId,
        branchId: branchId,
        userCode: userCode,
        userId: userId,

        rowStatus: "ACT",
        id: id,
        no: paymentNo,
        reference: reference,
        currRefNo: 0,
        currRefNo2: "",
        pvId: pvId,
        isSettle: false,
        settledOn: defaultDate,
        jasaLain: jasaLain,
        jasaLain_Id: jasaLainId,
        jasaLainAmount: jasaLainAmount,
        sewaAmount: 0,
        remarks: remarks,
        printing: 0,
        printiedOn: printedOn,
        deleted: false,
        deletedby: "",
        deletedOn: defaultDate,
        approvedBy: approvedBy,
        rePrintApproved: false,
        rePrintApprovedOn: defaultDate,
        rePrintApprovedBy: 0,
        idLama: 0,
        jasaKonsultasiAmount: 0,
        jasaManajemenAmount: 0,
        jasaTeknikAmount: 0,
        nameWP: nameWP,
        npwp: npwp,
        address: address,
        approvedByPosition: approvedByPosition,
        pvpPh23Type: "",
        prId: 0,
        prDetailId: 0,
        jasaLain6_Id: 0,
        jasaLainAmount6: 0,
        jasaLain2_Id: 0,
        jasaLainAmount2: 0,
        jasaLain3_Id: 0,
        jasaLainAmount3: 0,
        jasaLain4_Id: 0,
        jasaLainAmount4: 0,
        jasaLain5_Id: 0,
        jasaLainAmount5: 0,
        buktiPotong: buktiPotong,
        printPvYear: 0,
        printPvMonth: 0,
        verify: false,
        verifiedOn: defaultDate,
        dividenAmount: 0,
        bungaAmount: 0,
        royaltiAmount: 0,
        hadiahAmount: 0,
        nonNPWP: false,
      }
    };

    ApiService.HelpCreateData(payload, param, id)
      .then((res) => {
        if (res.data.code === 200) {
          let message = "";
          if (!pvPphId) {
            ApiService.CreateFinAcc(param, payload)
              .then((res) => {
                if (res.data.code === 200) {
                  message = "Success create data";
                  showToast({ type: 'success', message: message });

                  setTimeout(
                    function () {
                      localStorage.setItem('tempId', JSON.stringify(res.data.data.Id));
                      history('/Infoss/paymentVoucherPph23/' + JSON.parse(localStorage.getItem("tempId")), { replace: true });
                      window.location.reload();
                      localStorage.removeItem('tempId');
                    },
                    1250);
                }
                else {
                  showToast({ type: 'error', message: res.data.message });
                }
              })
          }
          else {
            ApiService.EditFinAcc(param, payload)
              .then((res) => {
                if (res.data.code === 200) {
                  message = "Success update data";
                  showToast({ type: 'success', message: message });

                  setTimeout(
                    function () {
                      history('/Infoss/paymentVoucherPph23/' + pvPphId, { replace: true });
                      window.location.reload();
                    },
                    1250);
                }
                else {
                  showToast({ type: 'error', message: res.data.message });
                }
              })
          }
        }
        else {
          setIsLoadingBackDrop(false);
          showToast({ type: 'error', message: 'Failed to Create Help Data, ' + res.data.message });
        }
      })
      .catch(function (error) {
        setIsLoadingBackDrop(false);
        showToast({ type: 'error', message: 'Error When Create Data, Please Contact The Administrator, ' + error });
      })
  };

  const handleBack = () => {
    history("/infoss/paymentVoucherPph23");
  };

  const ButtonComponents = () => {
    return (
      <ButtonCreateEditPaymentPPh23
        Save={handleSave}
        Back={handleBack}
        DataRegUserAccess={DataRegUserAccess}
        check={check}
        isEdit={isEdit}
      />
    )
  }

  return (
    <>
      <Box sx={{ backgroundColor: '#E4E8EC', width: '100%', p: '24px 32px' }}>
        <CustomToastContainer />
        <Grid container spacing={0} direction="column">
          <HeaderInfo
            title={
              pvPphId ?
                'Payment Voucher PPh 23 (' + reference + ')'
                :
                'Payment Voucher PPh 23 ( New Document )'
            }
            BreadcrumbsItems={pvPphId ? BreadcrumbsEditItems : BreadcrumbsItems}
            type={'Form'}
            ticketId={0}
            BackFunction={handleBack}
            documentStatus={documentStatus}
          />

          <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
            <CardContent>
              <PaymentPPh23Header
                reference={reference}
                setReference={setReference}
                paymentNo={paymentNo}
                setPaymentNo={setPaymentNo}
                pvId={pvId}
                setPvId={setPvId}
                pvNo={pvNo}
                setPvNo={setPvNo}
                pvRef={pvRef}
                setPvRef={setPvRef}
                nameWP={nameWP}
                setNameWP={setNameWP}
                npwp={npwp}
                setNpwp={setNpwp}
                address={address}
                setAddress={setAddress}
                pph23Amount={pph23Amount}
                setPph23Amount={setPph23Amount}
                totalPph23Amount={totalPph23Amount}
                setTotalPph23Amount={setTotalPph23Amount}
                setJenisPenghasilan={setJenisPenghasilan}
                setJumlahPenghasilan={setJumlahPenghasilan}
                setTarifTambahan={setTarifTambahan}
                setTarif={setTarif}
                setPPhPotongan={setPPhPotongan}
                setJasaLain={setJasaLain}
                setJasaLainId={setJasaLainId}
                setJasaLainAmount={setJasaLainAmount}
                setIsLoadingBackDrop={setIsLoadingBackDrop}
              />
            </CardContent>
          </Card>

          <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
            <CardContent>
              <PaymentPPh23Detail
                dataJasa={dataJasa}

                approvedByPosition={approvedByPosition}
                setApprovedByPosition={setApprovedByPosition}
                approvedBy={approvedBy}
                setApprovedBy={setApprovedBy}
                buktiPotong={buktiPotong}
                setBuktiPotong={setBuktiPotong}
                printedOn={printedOn}
                setPrintedOn={setPrintedOn}
                jasaLain={jasaLain}
                setJasaLain={setJasaLain}
                jasaLainId={jasaLainId}
                setJasaLainId={setJasaLainId}
                jasaLainAmount={jasaLainAmount}
                setJasaLainAmount={setJasaLainAmount}
                remarks={remarks}
                setRemarks={setRemarks}
                pph23Amount={pph23Amount}
                totalPph23Amount={totalPph23Amount}
                jenisPenghasilan={jenisPenghasilan}
                setJenisPenghasilan={setJenisPenghasilan}
                jumlahPenghasilan={jumlahPenghasilan}
                setJumlahPenghasilan={setJumlahPenghasilan}
                tarifTambahan={tarifTambahan}
                setTarifTambahan={setTarifTambahan}
                tarif={tarif}
                setTarif={setTarif}
                pphPotongan={pphPotongan}
                setPPhPotongan={setPPhPotongan}
              />
            </CardContent>
          </Card>
        </Grid>
      </Box>

      <ButtonInfo
        ButtonComponents={ButtonComponents}
      />

      {
        isLoadingBackDrop && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoadingBackDrop}
          >
            <LoadingSpinner />
          </Backdrop>
        )
      }
    </>
  );
})

export default CreateEditPaymentPPh23;
