import { Backdrop } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useState } from 'react';
import jwtDecode from 'jwt-decode';

import { dataProfile } from '../Profile';
import '../../../../Styles/scrollbar.css';
import { getDataMenu } from '../../Login/Login';
import ApiService, { userId } from '../../../../services/ApiService';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import { Icon, PopUp, showToast } from '../../../../components/ui';

const ModalProfile = (props) => {
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(false);
    const [selectedData, setSelectedData] = useState({})

    const dataGridColumn = [
        { field: 'countryName', headerName: 'Country Name', flex: 1, headerClassName: 'data-grid--header', },
        { field: 'companyName', headerName: 'Company Name', flex: 3, headerClassName: 'data-grid--header', },
        { field: 'branchName', headerName: 'Branch Name', flex: 3, headerClassName: 'data-grid--header', },
        // { field: 'roleCode', headerName: 'Role Code', flex: 1, headerClassName: 'data-grid--header', },
        { field: 'roleName', headerName: 'Role Name', flex: 2, headerClassName: 'data-grid--header', },
    ]

    function Close() {
        props.handleClick();
    }

    function Select() {
        setIsLoadingBackDrop(true);
        if (selectedData.index === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' });

            setTimeout(
                function () {
                    setIsLoadingBackDrop(false);
                },
                1250);
        }
        else {
            const companyId = selectedData.companyId;
            const countryId = selectedData.countryId;
            const branchId = selectedData.branchId;
            const companyName = selectedData.companyName;
            const countryName = selectedData.countryName;
            const branchName = selectedData.branchName;
            const roleId = selectedData.roleId;
            const roleLevel = selectedData.roleLevel;
            const roleName = selectedData.roleName;
            const groupId = selectedData.groupId;
            const refreshToken = JSON.parse(localStorage.getItem("refreshToken"));
            //set JWT token to local
            localStorage.setItem("companyId", JSON.stringify(companyId));
            localStorage.setItem("countryId", JSON.stringify(countryId));
            localStorage.setItem("branchId", JSON.stringify(branchId));
            localStorage.setItem("companyName", JSON.stringify(companyName));
            localStorage.setItem("countryName", JSON.stringify(countryName));
            localStorage.setItem("branchName", JSON.stringify(branchName));
            localStorage.setItem("roleId", JSON.stringify(roleId));
            localStorage.setItem("roleLevel", JSON.stringify(roleLevel));
            localStorage.setItem("roleName", JSON.stringify(roleName));
            localStorage.setItem("groupId", JSON.stringify(groupId));

            //generate menu
            var datamenu = dataProfile.filter(el => el.companyId === companyId
                && el.countryId === countryId && el.branchId === branchId
                && el.roleId === roleId && el.groupId === groupId);
            if (datamenu) {
                datamenu = datamenu[0].permissions;
            }
            getDataMenu(datamenu)

            //get new token
            const data = {
                "groupId": groupId,
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "roleId": roleId,
                "refreshToken": refreshToken,
            }
            ApiService.ChangeProfile(data).then((res) => {
                if (res.data.code === 200) {
                    const token = res.data.data.token
                    const refToken = res.data.data.refreshToken
                    const parentCompanyId = res.data.data.companyIdPusat;
                    const decoded = jwtDecode(res.data.data.token);
                    localStorage.setItem("token", JSON.stringify(token));
                    localStorage.setItem("refreshToken", JSON.stringify(refToken));
                    localStorage.setItem("expiredToken", JSON.stringify(decoded.exp));
                    localStorage.setItem("parentCompanyId", JSON.stringify(parentCompanyId));
                    showToast({ type: 'success', message: 'Success Change Profile!' });

                    setTimeout(
                        function () {
                            ApiService.refreshToken();
                            Close();
                            window.location.reload();
                            setIsLoadingBackDrop(false);
                        },
                        1250);
                }
                else if (res.data.code === 500) {
                    showToast({ type: 'error', message: 'Failed to Change Profile, Please Contact Administrator, ' + res.data.message });

                    setTimeout(
                        function () {
                            setIsLoadingBackDrop(false);
                            Close();
                        },
                        1250);
                }
            }).catch(function (error) {
                console.error('error', error)
                showToast({ type: 'error', message: 'Failed to Change Profile, Please Contact Administrator, ' + error });
            })
        }
    }

    document.onkeydown = checkKey;
    function checkKey(e) {
        let currIndex = 0;
        if (e.keyCode === 38 && selectedData.index > 0) {
            //UP ARROW
            currIndex = selectedData.index
            currIndex -= 1
        }
        else if (e.keyCode === 40 && selectedData.index < props.rowsCount - 2 && selectedData.index !== 0) {
            //DOWN ARROW
            currIndex = selectedData.index
            currIndex += 1
        }
        else if (e.keyCode === 13 && selectedData.index !== undefined) {
            handleDoubleClick()
        }

        const copyArr = props?.dataMap ? [...props?.dataMap] : []
        const changeSelectedData = (data) => {
            return data.index === currIndex
        }

        const result = copyArr.filter(changeSelectedData)
        if (props?.setSelectedData) {
            props.setSelectedData(...result);
        }
    }

    const handleDoubleClick = () => {
        Select()
    }

    const handleRowClick = (params) => {
        setSelectedData(params.row);
    }

    const mainContainer = (
        <div style={{ marginTop: '16px' }}>
            <DataGrid
                rows={props.dataProfile}
                columns={dataGridColumn}
                autoHeight={true}
                onRowClick={handleRowClick}
                onCellDoubleClick={handleDoubleClick}
                density="compact"
                className='scrollbar'
                sx={{
                    '& .data-grid--header': {
                        backgroundColor: '#f0f7ff',
                        color: '#000000',
                    },
                    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                        "margin-top": "1em",
                        "margin-bottom": "1em"
                    },
                    '.scrollbar': {
                        overflowY: 'auto',

                        '-ms-overflow-style': 'none',
                        scrollbarWidth: 'none',
                    },
                    '.scrollbar::-webkit-scrollbar': {
                        display: 'none',
                    }
                }}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                pageSizeOptions={[10, 20, 50]}
            />
        </div>
    );

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'account'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={'Select ' + props.titleModal}
                isOpen={props.isModal}
                onClose={props.handleClick}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={'lg'}
                fullWidth={true}
                topButton={false}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}

                    <div style={{ marginTop: '48px' }}>
                        {CustomizeButtonComponent.SelectModalButton(Select)}
                    </div>
                </div>
            </PopUp>
            
            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
}

export default ModalProfile