import React, { forwardRef, useEffect, useState, useRef } from 'react'
import { Box, Card, CardContent, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Stack, Typography } from '@mui/material';

import ApiService from '../../../../services/ApiService';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';

import { useFormik } from 'formik';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import TableX from '../../../../components/Tables/TableX';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Input, Paginate, Selection, showToast, TickBox } from '../../../../components/ui';

const TandaTerimaReport = forwardRef(() => {
  const btnPageRef = useRef(null)
  const btnTableRef = useRef(null)

  const date = new Date()

  const [rowsCount, setRowsCount] = useState(50);
  const [numPage, setNumPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [selectedData, setSelectedData] = useState({})
  const [totalRows, setTotalRows] = useState(50);
  const [filter, setFilter] = useState([]);
  const [filterBy, setFilterBy] = useState([]);
  const [stateX, setStateX] = useState({});
  const [sortArr, setSortArr] = useState([]);
  const [goTo, setGoTo] = useState(1);

  const [jobTypeList, setJobTypeList] = useState([])

  const [isLoading, setIsLoading] = useState(false)

  const BreadcrumbsItems = [
    { label: 'Infoss', link: '/Infoss', style: '' },
    { label: 'Cargo Manifest', link: '/Infoss/CargoManifest', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useEffect(() => {
    document.title = 'Tanda Terima - ' + JSON.parse(localStorage.getItem('branchName'));

    getAdditionalData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const errorToast = (location) => {
    showToast({ type: 'error', message: `Can't get ${location} data! please contact Administrator` })
  }

  const getAdditionalData = async () => {
    try {
      const [resRJT] = await Promise.all([
        ApiService.PostByPage('regJobType/regJobType', 1, 999, []),
      ])

      if (resRJT?.data?.code === 200) {
        const tempData = resRJT.data.data.jobType
        const mapData = tempData.map((rjt) => {
          return { value: rjt.id, label: rjt.name }
        })

        formik.setFieldValue('jobType', mapData[0].value)

        setJobTypeList(mapData)
      } else {
        errorToast('job type')
      }

    } catch (error) {
      errorToast('additional')
    }
  }

  const handlePrint = (fileType) => {
    console.log(fileType, formik.values)
    // TODO Print data based on form
  }

  const getData = async (pageNumber = 1, pageSize = 10, filters, orderBy, data) => {
    if (!formik.values.jobType) return showToast({ type: 'error', message: 'Please select shipment first.' })


    const params = [{ 'attr': 'jobTypeId', 'value': String(formik.values.jobType) }]
    if (formik.values.range !== 'all') {
      if (!formik.values.eta || !formik.values.etd) {
        return showToast({ type: 'error', message: 'Please enter valid eta / etd date.' })
      } else {
        params.push({ 'attr': 'etd', 'value': new Date(formik.values.etd).toLocaleDateString("sv-se").split('T')[0] })
        params.push({ 'attr': 'eta', 'value': new Date(formik.values.eta).toLocaleDateString("sv-se").split('T')[0] })
      }
    }

    setIsLoading(true)

    try {
      const res = await ApiService.OperationDynamicPostByPage('shipmentOrder/shipmentOrder', 'PAGE', pageNumber, pageSize, params, filters, orderBy)
      setSelectedData({})

      let temp = res.data.data.shipmentOrder
      let indexed = temp.map((el, index) => {
        let indexedTemp = {
          ...el,
          index
        }
        return indexedTemp
      })

      setTableData(indexed)
      setColumnData(res.data.data.columns.headerColumns)
      setTotalRows(res.data.totalRowCount)
    } catch (error) {
      errorToast('shipment order')
      console.log(error)
    }
    setIsLoading(false)
  }

  const cellDoubleClick = (row) => {
    setSelectedData(row)
  }

  const handleReset = () => {
    emptyState();
    getData(1, 50, []);
  };

  const emptyState = () => {
    setNumPage(1);
    setRowsCount(50);
    setTotalRows(50);
    setGoTo(1);
    setFilter([]);
    setFilterBy([]);
    setStateX({})
    setSortArr([])
  };

  const formik = useFormik({
    initialValues: {
      jobType: '',
      range: 'all',
      etd: date.toLocaleDateString("sv-se").split('T')[0],
      eta: date.toLocaleDateString("sv-se").split('T')[0],
      oriBOL: false,
      oriHBL: false,
      oriINV: false,
      oriOR: false,
      peb: false,
      cbText1: false,
      cbText2: false,
      cbText3: false,
      cbText4: false,
      cbText5: false,
      cbText6: false,
      cbText7: false,
      cbText8: false,
      cbText9: false,
      tcbTxt10: false,
      text1: '',
      text2: '',
      text3: '',
      text4: '',
      text5: '',
      text6: '',
      text7: '',
      text8: '',
      text9: '',
      text10: '',
      copies1: '',
      copies2: '',
      copies3: '',
      copies4: '',
      oriCOF: false,
      submittedBy: '',
      receivedBy: '',
    },
    validationSchema: undefined,
  })

  const getContainerHeight = () => {
    const hTitle = 64
    const hHeader = 116
    const hPagination = filter.length > 0 ? 235 : 150
    const hPadding = 10 * 2
    const hBtnPage = btnPageRef?.current?.clientHeight || 28
    const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

    const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable + hPagination

    return `${totalHeight}px`
  }

  const ButtonComponents = () => {
    return (
      <div className="row" spacing={'12px'}>
        <div className='col-12' spacing={'12px'}>
          <Grid container item spacing={'12px'} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
            {ButtonComponent.ReloadButton(() => getData())}
            {ButtonComponent.PrintButton(() => handlePrint('pdf'), 'PDF')}
          </Grid>
        </div>
      </div>
    )
  }

  const onChangePaging = (value) => {
    setNumPage(value);
    getData(value, rowsCount, filter);
  }

  const onChangeLimit = (value) => {
    setNumPage(1);
    setRowsCount(value);
    getData(1, value, filter);
  }

  function handleChange(name, value) {
    formik.setFieldValue(name, !value);
  }

  return (
    <>
      <Box className='w-100' sx={{ width: '100%', p: 3, background: '#E4E8EC' }}>
        <CustomToastContainer />
        <Grid container spacing={0} direction="column">
          <HeaderInfo btnPageRef={btnPageRef}
            title={'Tanda Terima Report'}
            isJobType={false}
            BreadcrumbsItems={BreadcrumbsItems}
            ButtonComponents={ButtonComponents}
          />

          <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
            <div className="mt-3">
              <Grid
                container
                spacing={'32px'}
              >
                <Grid
                  item
                  xl={6}
                  lg={6}
                  sm={6}
                  xs={6}
                >
                  <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                    <CardContent>
                      <Selection
                        id='0'
                        fullWidth={true}
                        label={'Shipment'}
                        value={formik.values.jobType}
                        placeholder={'Select Shipment'}
                        onChange={(e) => formik.setFieldValue('jobType', e.target.value)}
                      >
                        {
                          jobTypeList.map((jt) => (
                            <MenuItem sx={{ fontSize: '12px' }} key={jt.value} value={jt.value}>{jt.label}</MenuItem>
                          ))
                        }
                      </Selection>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid
                  item
                  xl={6}
                  lg={6}
                  sm={6}
                  xs={6}
                >
                  <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                    <CardContent>
                      <div>
                        <Typography
                          sx={{
                            color: 'var(--Primary-One, #083A50)',
                            fontFamily: 'Nunito',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: '19.6px',
                          }}
                        >
                          ETA/ETD Range
                        </Typography>

                        <RadioGroup
                          row
                          sx={{
                            marginTop: '4px',
                            gap: '20px'
                          }}
                          name="variant"
                          value={formik.values.range}
                          aria-labelledby="variant-option"
                          onChange={(e) => { formik.setFieldValue('range', e.target.value) }}
                        >
                          <FormControlLabel
                            sx={{
                              color: 'var(--Blue-Primary-1, #083A50)',
                              fontFamily: 'Nunito',
                              fontSize: '16px',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: '20px',
                            }}
                            value={'all'}
                            key={1}
                            control={<Radio size='medium' />}
                            label={'All'}
                          />

                          <FormControlLabel
                            sx={{
                              color: 'var(--Blue-Primary-1, #083A50)',
                              fontFamily: 'Nunito',
                              fontSize: '16px',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: '20px',
                            }}
                            value={'custom'}
                            key={2}
                            control={<Radio size='medium' />}
                            label={'Custom'}
                          />
                        </RadioGroup>
                      </div>

                      <Stack spacing={'32px'} direction={'row'} sx={{ marginTop: '16px' }}>
                        <Input
                          fullWidth
                          type='date'
                          label="ETD"
                          variant="standard"
                          InputLabelProps={{ shrink: true }}
                          value={formik.values?.etd || ''}
                          disabled={formik.values.range === 'all'}
                          formControlProps={{ sx: { width: '100%' } }}
                          onChange={(e) => { formik.setFieldValue('etd', (e.target.value)) }}
                        />

                        <Input
                          fullWidth
                          type='date'
                          label="ETA"
                          variant="standard"
                          InputLabelProps={{ shrink: true }}
                          value={formik.values?.eta || ''}
                          disabled={formik.values.range === 'all'}
                          formControlProps={{ sx: { width: '100%' } }}
                          onChange={(e) => { formik.setFieldValue('eta', (e.target.value)) }}
                        />
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
            <div ref={btnTableRef}>
              {
                ButtonComponent.RefreshButton(
                  sortArr,
                  rowsCount,
                  columnData,
                  setGoTo,
                  handleReset,
                  setFilterBy,
                  filterBy,
                  setFilter,
                  setNumPage,
                  setRowsCount,
                  getData,
                  stateX,
                  setStateX
                )
              }
            </div>

            <div
              className="rounded-10 table-responsive"
              style={{
                maxHeight: `calc(100vh - ${getContainerHeight()})`,
                marginTop: '32px',
                padding: '2px 16px',
                borderRadius: '12px',
                background: '#F2F2F2'
              }}
            >
              {
                isLoading ?
                  <LoadingSpinner />
                  :
                  <>
                    <div className='mt-1'>
                      <TableX 
                        uniqueKey={'id'}
                        rowsCount={rowsCount}
                        goTo={goTo}
                        CellDoubleClick={cellDoubleClick}
                        setNumPage={setNumPage}
                        setRowsCount={setRowsCount}
                        getData={getData}
                        columnData={columnData}
                        dataMap={tableData}
                        SelectedData={selectedData}
                        setSelectedData={setSelectedData}
                        setFilterBy={setFilterBy}
                        setFilter={setFilter}
                        setGoTo={setGoTo}
                        stateX={stateX}
                        setStateX={setStateX}
                        sortArr={sortArr}
                        setSortArr={setSortArr}
                      />
                    </div>
                  </>
              }
            </div>

            <div style={{ marginTop: '16px' }}>
              <Paginate
                page={numPage}
                limit={rowsCount}
                totalData={totalRows}
                onChange={onChangePaging}
                onChangeLimit={onChangeLimit}
              />
            </div>
          </Grid>

          <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
            <div style={{ marginTop: '32px' }}>
              <Grid
                container
                spacing={'32px'}
              >
                <Grid
                  item
                  xl={6}
                  lg={6}
                  sm={6}
                  xs={6}
                >
                  <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                    <CardContent>
                      <Stack direction={'column'}>
                        <TickBox
                          checked={formik.values.oriBOL}
                          label="Original Bill Of Lading Master/Ocean"
                          baseLabelProps={{ sx: { marginTop: '16px' } }}
                          onChange={() => handleChange('oriBOL', formik.values.oriBOL)}
                        />

                        <TickBox
                          label="Original House BL"
                          checked={formik.values.oriHBL}
                          baseLabelProps={{ sx: { marginTop: '16px' } }}
                          onChange={() => handleChange('oriHBL', formik.values.oriHBL)}
                        />

                        <TickBox
                          label="Original Invoices"
                          checked={formik.values.oriINV}
                          baseLabelProps={{ sx: { marginTop: '16px' } }}
                          onChange={() => handleChange('oriINV', formik.values.oriINV)}
                        />

                        <TickBox
                          checked={formik.values.oriOR}
                          label="Original Official Receipt"
                          baseLabelProps={{ sx: { marginTop: '16px' } }}
                          onChange={() => handleChange('oriOR', formik.values.oriOR)}
                        />

                        <TickBox
                          label="PEB"
                          checked={formik.values.peb}
                          baseLabelProps={{ sx: { marginTop: '16px' } }}
                          onChange={() => handleChange('peb', formik.values.peb)}
                        />
                      </Stack>

                      {
                        Array.from(Array(10)).map((val, index) => (
                          <Grid key={index} container item direction="row">
                            <Grid item xs={8} alignItems='right' className='text-end' display={'flex'} justifyContent={'flex-end'}>
                              <TickBox
                                key={index}
                                name={"cbText" + (index + 1)}
                                baseLabelProps={{ sx: { marginTop: '16px' } }}
                                checked={formik.values['cbText' + (index + 1)]}
                                onChange={() => handleChange("cbText" + (index + 1), formik.values['cbText' + (index + 1)])}
                              />

                              <Input
                                fullWidth
                                variant="standard"
                                name={"text" + (index + 1)}
                                InputLabelProps={{ shrink: true }}
                                value={formik.values['text' + (index + 1)] || ''}
                                disabled={!formik.values['cbText' + (index + 1)]}
                                formControlProps={{ sx: { width: '100%', marginTop: '16px' } }}
                                onChange={(e) => { formik.setFieldValue("text" + (index + 1), (e.target.value)) }}
                              />
                            </Grid>
                          </Grid>
                        ))
                      }
                    </CardContent>
                  </Card>
                </Grid>

                <Grid
                  item
                  xl={6}
                  lg={6}
                  sm={6}
                  xs={6}
                >
                  <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                    <CardContent>
                      <Input
                        fullWidth
                        name={"copies1"}
                        variant="standard"
                        label='No. of Copies'
                        value={formik.values.copies1}
                        InputLabelProps={{ shrink: true }}
                        formControlProps={{ sx: { width: '100%' } }}
                        onChange={(e) => { formik.setFieldValue('copies1', (e.target.value)) }}
                      />

                      <Input
                        fullWidth
                        name={"copies2"}
                        variant="standard"
                        label='No. of Copies'
                        value={formik.values.copies2}
                        InputLabelProps={{ shrink: true }}
                        formControlProps={{ sx: { width: '100%', marginTop: '16px' } }}
                        onChange={(e) => { formik.setFieldValue('copies2', (e.target.value)) }}
                      />

                      <Input
                        fullWidth
                        name={"copies3"}
                        variant="standard"
                        label='No. of Copies'
                        value={formik.values.copies3}
                        InputLabelProps={{ shrink: true }}
                        formControlProps={{ sx: { width: '100%', marginTop: '16px' } }}
                        onChange={(e) => { formik.setFieldValue('copies3', (e.target.value)) }}
                      />

                      <Input
                        fullWidth
                        name={"copies4"}
                        variant="standard"
                        label='No. of Copies'
                        value={formik.values.copies4}
                        InputLabelProps={{ shrink: true }}
                        formControlProps={{ sx: { width: '100%', marginTop: '16px' } }}
                        onChange={(e) => { formik.setFieldValue('copies4', (e.target.value)) }}
                      />

                      <TickBox
                        checked={formik.values.oriCOF}
                        label="Original Certificate Of Fumigation"
                        baseLabelProps={{ sx: { marginTop: '16px' } }}
                        onChange={() => handleChange('oriCOF', formik.values.oriCOF)}
                      />

                      <Input
                        fullWidth
                        name={"submittedBy"}
                        variant="standard"
                        label='Submitted By'
                        value={formik.values.submittedBy}
                        InputLabelProps={{ shrink: true }}
                        formControlProps={{ sx: { width: '100%', marginTop: '16px' } }}
                        onChange={(e) => { formik.setFieldValue('submittedBy', (e.target.value)) }}
                      />

                      <Input
                        fullWidth
                        name={"receivedBy"}
                        variant="standard"
                        label='Received By'
                        value={formik.values.receivedBy}
                        InputLabelProps={{ shrink: true }}
                        formControlProps={{ sx: { width: '100%', marginTop: '16px' } }}
                        onChange={(e) => { formik.setFieldValue('receivedBy', (e.target.value)) }}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  )
})

export default TandaTerimaReport