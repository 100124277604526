import { Grid, Box, MenuItem, Stack } from '@mui/material';
import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';

import ApiService from '../../../../services/ApiService';
import TableX from '../../../../components/Tables/TableX';
import freezeContainer from '../../../../Styles/freezeContainer';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import ButtonPecahPosView from './Buttons/ButtonPecahPosView';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Input, Paginate, Selection, showToast } from '../../../../components/ui';
import { setDateFormat } from '../../../../utils/date';

const PecahPosView = forwardRef((props, ref) => {
    const btnPageRef = useRef(null)
    const btnTableRef = useRef(null)

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const history = useNavigate();
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const paramShipmentOrder = 'shipmentOrder/shipmentOrder';

    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [dataJobType, setDataJobType] = useState([]);
    const [jobType, setJobType] = useState(2);

    const dataETARange = [
        { id: 1, name: 'All', value: false },
        { id: 2, name: 'Custom', value: true }
    ];
    const [isETARange, setIsETARange] = useState(false);
    const [ETD, setETD] = useState('');
    const [ETA, setETA] = useState('');

    const [rowsCount, setRowsCount] = useState(50);
    const [numPage, setNumPage] = useState(1);

    const [dataMap, setDataMap] = useState([]);
    const [columnData, setColumnData] = useState([]);
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50);
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');
    const [dataSelected, setDataSelected] = useState({});

    const BreadcrumbsItems = [
        { label: 'Infoss', link: '/Infoss', style: '' },
        { label: 'Pecah Pos Report', link: '/Infoss/PecahPosReport', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'Pecah Pos Report - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const selectJobType = (jobType, setJobType) => {
        return (
            <Selection
                id='0'
                label={'Job Type'}
                fullWidth={true}
                placeholder={'Select Job Type'}
                value={jobType}
                onChange={(e) => handleSelectJobType(setJobType, e.target.value)}
            >
                {
                    dataJobType.map((data) => (
                        <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.code} value={data.id}>
                            {data.name}
                        </MenuItem>
                    ))
                }
            </Selection>
        )
    }

    function handleSelectJobType(setJobType, value) {
        setJobType(value);
    }

    const getDataAccess = () => {
        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'OSHOR')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);

                        setDataJobType([
                            {
                                id: 2,
                                code: '11',
                                name: 'Sea Import'
                            }
                        ]);

                        getData(1, 50, []);
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Infoss');
                })
        }
        else {
            history('/Infoss');
        }
    }

    const getData = (pageNumber, pageSize, filters, orderBy) => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        }
        else {
            setIsLoadingBackDrop(true);
        }

        let filter = filters;
        let params = [
            {
                "attr": "isWithRouting",
                "value": '' + true
            },
            {
                "attr": "vesselType",
                "value": "CONNECTING, FEEDER"
            },
            {
                "attr": "jobTypeId",
                "value": '' + jobType
            }
        ];

        if (isETARange === true) {
            params = [
                ...params,
                {
                    "attr": "eta, eta",
                    "mathSign": ">=, <=",
                    "value": ETD + ',' + ETA
                }
            ];
        }

        ApiService.OperationDynamicPostByPage(paramShipmentOrder, 'SEADOW', pageNumber, pageSize, params, filter, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})

                    let temp = response.data.data.shipmentOrder
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    setColumnData(response.data.data.columns.headerColumns)
                    setTotalRows(response.data.totalRowCount)

                    if (isLoadingBackDrop === false) {
                        setIsLoading(false);
                    }
                    setIsLoadingBackDrop(false);
                }

                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                setIsLoadingBackDrop(false);
            })
            .catch(function (error) {
                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    const emptyState = () => {
        setNumPage(1);
        setRowsCount(50);
        setTotalRows(50);
        setFilter([]);
        setGoTo(1);
        setFilterBy([]);
        setStateX({});
        setSortArr([]);
    };

    const handleReset = () => {
        emptyState();
        getData(1, 50, []);
    };

    function handleIsETARange(value) {
        setIsETARange(value);

        if (value === true) {
            setDateFormat(new Date(), setETA);
            setDateFormat(new Date(), setETD);
        }
        else {
            setETA('');
            setETD('');
        }
    }

    function handleETD(value) {
        if (ETA < value) {
            showToast({ type: 'error', message: 'ETD Can\'t be Greater Than ETA' });
        }
        else {
            setETD(value);
        }
    }

    function handleETA(value) {
        if (ETD > value) {
            showToast({ type: 'error', message: 'ETA Can\'t be Smaller Than ETD' });
        }
        else {
            setETA(value);
        }
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
            setDataSelected(SelectedData);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationDOWOBL() {
        if (titleConfirmation === 'Print') {
            PrintPDF();
        }
        setIsModalConfirmation(false);
    }

    const ReloadData = () => {
        showToast({ type: 'success', message: 'Reload Data' });
        emptyState();
        getData(1, 50, []);
    }

    const PrintData = () => {
        if (SelectedData.id !== undefined) {
            setTitleConfirmation('Print')
            setTitleModalConfirmation('Pecah Pos');
            handleClickConfirmation();
        }
        else {
            showToast({ type: 'error', message: 'Please Select Data Shipment First!' });
        }
    };

    function PrintPDF() {
        setIsLoadingBackDrop(true)
        let variable = "PrintPPP?id=" + dataSelected.id;
        ApiService.ReportPrint("Reports/Operation/Print/PecahPosReport/", variable)
            .then((response) => {
                if (response.status === 200) {
                    let rptFormat = "application/pdf";

                    const file = new Blob([response.data], { type: rptFormat });
                    const fileURL = URL.createObjectURL(file);

                    setIsLoadingBackDrop(false);
                    showToast({ type: 'success', message: 'Print Pecah Pos Report Success' });
                    window.open(fileURL);
                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'Can\'t Print Pecah Pos Report, ' + response.data.message });
                }
            })
            .catch((error) => {
                setIsLoadingBackDrop(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Print Pecah Pos Report, ' + error });
            });
    }

    const CellDoubleClick = () => {

    }

    const getContainerHeight = () => {
        const hTitle = 64
        const hHeader = 116
        const hPadding = 10 * 2
        const hBtnPage = btnPageRef?.current?.clientHeight || 28
        const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

        const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable

        return `${totalHeight}px`
    }

    const ButtonComponents = () => {
        return (
            <>
                <ButtonPecahPosView
                    ReloadData={ReloadData}
                    PrintData={PrintData}
                    DataRegUserAccess={DataRegUserAccess}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px', marginTop: '16px' }}>
                    <div ref={btnTableRef}>
                        <div className="mt-2">
                            <Grid
                                container
                                columnSpacing={{ xs: 0, md: 3 }}
                                rowSpacing={{ xs: 2, md: 0 }}
                            >
                                <Grid
                                    item
                                    xl={2}
                                    lg={2}
                                    sm={2}
                                    xs={12}
                                >
                                    <div>
                                        {selectJobType(jobType, setJobType)}
                                    </div>
                                </Grid>

                                <Grid
                                    item
                                    xl={2}
                                    lg={2}
                                    sm={2}
                                    xs={12}
                                >
                                    <div>
                                        <Selection
                                            id='0'
                                            label={'ETA/ETD Range'}
                                            fullWidth={true}
                                            placeholder={'Select ETA/ETD Range'}
                                            value={isETARange}
                                            onChange={(e) => handleIsETARange(e.target.value)}
                                        >
                                            {
                                                dataETARange.map((data) => (
                                                    <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.value}>
                                                        {data.name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Selection>
                                    </div>
                                </Grid>

                                <Grid
                                    item
                                    xl={4}
                                    lg={4}
                                    sm={4}
                                    xs={12}
                                >
                                    <Stack spacing={'32px'} direction="row" hidden={!isETARange}>
                                        <Input
                                            label='Date From'
                                            value={ETD}
                                            onChange={(e) => handleETD(e.target.value)}
                                            type={'date'}
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                }
                                            }}
                                        />

                                        <Input
                                            label='Date To'
                                            value={ETA}
                                            onChange={(e) => handleETA(e.target.value)}
                                            type={'date'}
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                }
                                            }}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </>
        )
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        getData(value, rowsCount, filter);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        getData(1, value, filter);
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: 3, ...freezeContainer }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo 
                    btnPageRef={btnPageRef}
                    title={'Pecah Pos Report'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                    <div ref={btnTableRef}>
                        {
                            ButtonComponent.RefreshButton(
                                sortArr,
                                rowsCount,
                                columnData,
                                setGoTo,
                                handleReset,
                                setFilterBy,
                                filterBy,
                                setFilter,
                                setNumPage,
                                setRowsCount,
                                getData,
                                stateX,
                                setStateX
                            )
                        }
                    </div>

                    <div
                        className="rounded-10 table-responsive"
                        style={{
                            maxHeight: `calc(100vh - ${getContainerHeight()})`,
                            marginTop: '16px',
                            padding: '2px 16px',
                            borderRadius: '12px',
                            background: '#F2F2F2'
                        }}
                    >
                        {
                            isLoading ?
                                <LoadingSpinner />
                                :
                                <>
                                    <div className='mt-1'>
                                        <TableX
                                            uniqueKey={'id'}
                                            goTo={goTo}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={getData}
                                            columnData={columnData}
                                            dataMap={dataMap}
                                            SelectedData={SelectedData}
                                            setSelectedData={setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            CellDoubleClick={CellDoubleClick}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                        />
                                    </div>
                                </>
                        }
                    </div>

                    <div style={{ marginTop: '16px' }}>
                        <Paginate
                            page={numPage}
                            limit={rowsCount}
                            totalData={totalRows}
                            onChange={onChangePaging}
                            onChangeLimit={onChangeLimit}
                        />
                    </div>
                </Grid>
            </Grid>

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationDOWOBL}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </Box>
    )
})

export default PecahPosView