import React from 'react';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import { Grid } from '@mui/material';

const ButtonCreateEditOfficialReceipt = ({
    isEdit,
    isApprovedAcc,
    isUnapproved,
    check,
    DataRegUserAccess,
    Save,
    Print,
    AddNew,
    Approve
}) => {

    const renderButton = (data) => {
        switch (data.accessCode) {
            case 'PRI':
                return isEdit ? ButtonComponent.PrintButton(Print, '', true) : null;
            case 'CRE':
                return isEdit ? ButtonComponent.AddButton(AddNew, 'New', false, isUnapproved, true) : null;
            case 'APP':
                return isEdit && !isApprovedAcc ? ButtonComponent.ApproveButton(Approve, 'Approve', true) : null;
            default:
                return null;
        }
    };

    return (
        <div className="row mt-4">
            <div className="col-12">
                <Grid
                    container
                    item
                    justifyContent="right"
                    direction="row"
                    style={{ maxWidth: "100vw", gap: "12px" }}
                >
                    {check !== 0 && !isApprovedAcc && (
                        ButtonComponent.SaveButton(() => Save(false), false, false, true)
                    )}

                    {DataRegUserAccess.map((data) => (
                        <React.Fragment key={data.accessId}>
                            {renderButton(data)}
                        </React.Fragment>
                    ))}
                </Grid>
            </div>
        </div>
    );
};

export default ButtonCreateEditOfficialReceipt;