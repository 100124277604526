import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { HubConnectionState } from '@microsoft/signalr';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Box, Grid, Button, Typography } from '@mui/material';

import ButtonToDo from './Buttons/ButtonToDo';
import ApiService from '../../../services/ApiService';
import TableX from '../../../components/Tables/TableX';
import freezeContainer from "../../../Styles/freezeContainer";
import { signalRConnection } from '../../../services/ApiService';
import TableNoFilter from '../../../components/Tables/TableNoFilter';
import ButtonComponent from '../../../components/Buttons/ButtonComponent';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import CustomToastContainer, { Icon, Paginate, showToast } from '../../../components/ui';
import HeaderInfo from '../../../components/Components/HeaderInfo';

const ToDo = forwardRef((props, ref) => {
    const btnPageRef = useRef(null)
    const btnTableRef = useRef(null)

    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const param = "todo/todo";

    const [title, setTitle] = useState('To Do')
    const [title2, setTitle2] = useState('Activity Tracker')
    const [hide, setHide] = useState(false)
    const [hide2, setHide2] = useState(true)

    const [rowsCount, setRowsCount] = useState(JSON.parse(localStorage.getItem("rows")))
    const [numPage, setNumPage] = useState(JSON.parse(localStorage.getItem("page")))
    const [dataMap, setDataMap] = useState([])
    const [columnData, setColumnData] = useState([])
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50);
    const history = useNavigate();
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);

    const [rowsCount2, setRowsCount2] = useState(50)
    const [numPage2, setNumPage2] = useState(1)
    const [dataMap2, setDataMap2] = useState([])
    const [columnData2, setColumnData2] = useState([])
    const [SelectedData2, setSelectedData2] = useState({});
    const [totalRows2, setTotalRows2] = useState(50);
    const [sortArr, setSortArr] = useState([])
    const [goTo, setGoTo] = useState(1);
    let countRefresh = 0;

    const [stateX, setStateX] = useState({});
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);

    const BreadcrumbsItems = [
        { label: 'Infoss', link: '/Infoss', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        ApiService.refreshToken();
        if (countRefresh === 0) {
            document.title = 'To Do - ' + JSON.parse(localStorage.getItem('branchName'))
            // ApiService.refreshToken();
            getConnection();
            countRefresh++;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countRefresh]);

    const getData = (pageNumber, pageSize, filters) => {
        let params = [];
        ApiService.OperationDynamicPostByPage(param, 'PAGE', pageNumber, pageSize, params, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})

                    let temp = response.data.data.toDo
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    setColumnData(response.data.data.columns)
                    setTotalRows(response.data.totalRowCount)

                    setIsLoading(false)
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });
                    setIsLoading(false)
                }
                else {
                    setIsLoading(false);
                }
            })
            .catch(function (error) {
                setIsLoading(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + error });
            })
    }

    const getData2 = (pageNumber, pageSize, filters) => {
        setIsLoading2(true);
        let params = [
            {
                "attr": "IsActive",
                "value": "0"
            },
            {
                "attr": "IsComplete",
                "value": "1"
            }
        ]
        ApiService.OperationDynamicPostByPage(param, "PAGE", pageNumber, pageSize, params, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData2({})

                    let temp = response.data.data.toDo
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap2(indexed)
                    setColumnData2(response.data.data.columns)
                    setTotalRows2(response.data.totalRowCount)

                    setIsLoading2(false)
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });
                    setIsLoading(false)
                }

                setIsLoading2(false)
            })
            .catch(function (error) {
                setIsLoading2(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + error });
            })
    }

    const GoTo = (el) => {
        let url = el.url.replace('/', '')
        history('' + url)
    }

    async function getConnection() {
        try {
            ApiService.refreshToken();
            signalRConnection.on("ReceiveMessage", (res) => {
                if (res === 'ToDoNotif') {
                    let params = [];
                    ApiService.refreshToken();
                    ApiService.OperationDynamicPostByPage(param, 'PAGE', JSON.parse(localStorage.getItem("page")), JSON.parse(localStorage.getItem("rows")), params)
                        .then((response) => {
                            if (response.data.code === 200) {
                                setSelectedData({});

                                let temp = response.data.data.toDo
                                let indexed = temp.map((el, index) => {
                                    let indexedTemp = {
                                        ...el,
                                        index
                                    }
                                    return indexedTemp
                                })

                                setDataMap(indexed)
                                setColumnData(response.data.data.columns)
                                setTotalRows(response.data.totalRowCount)
                                setIsLoadingBackDrop(false);
                                setIsLoading(false);
                            }
                            else {
                                setIsLoadingBackDrop(false);
                                setIsLoading(false);
                            }
                        })
                        .catch(function (error) {
                            console.error('error saat fetch', error)
                            setIsLoadingBackDrop(false);
                            setIsLoading(false);
                        })
                }
                else {
                    setIsLoadingBackDrop(false);
                    setIsLoading(false);
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator' });
                }
            });

            signalRConnection.onclose(e => { });

            if (signalRConnection.state === HubConnectionState.Disconnected) {
                await signalRConnection.start();
            }

            await signalRConnection.invoke("Show", JSON.parse(localStorage.getItem("userCode")));
        }
        catch (e) {
            setIsLoadingBackDrop(false);
            setIsLoading(false);
            console.log(e);
        }
    }

    const switchTitle = () => {
        setGoTo(1);

        if (title2 === 'Activity Tracker') {
            document.title = 'Activity Tracker - ' + JSON.parse(localStorage.getItem('branchName'))
            setTitle('Activity Tracker')
            setTitle2('To Do')
            setHide(true)
            setHide2(false)
            setNumPage2(1);
            setRowsCount2(50);
            setTotalRows2(50);
            setFilter([]);
            setFilterBy([]);
            setStateX({});
            setGoTo(1);
            getData2(1, 50, []);
        }
        else {
            document.title = 'To Do - ' + JSON.parse(localStorage.getItem('branchName'))
            setTitle2('Activity Tracker')
            setTitle('To Do')
            setHide2(true)
            setHide(false)
        }
    }

    const handleReset = () => {
        setGoTo(1);
        setFilter([]);
        setFilterBy([]);
        getData2(1, 50, []);
        setStateX({})
    }

    const ReloadData = () => {
        showToast({ type: 'success', message: 'Reload Data' });
        setNumPage2(1);
        setRowsCount2(50);
        setTotalRows2(50);
        setFilter([]);
        setGoTo(1);
        setFilterBy([]);
        setStateX({});
        getData2(1, 50, []);
    }

    const getContainerHeight = () => {
        const hTitle = 36
        const hHeader = 64
        const hPagination = 64
        const hPadding = 24 * 2
        const hBtnPage = btnPageRef?.current?.clientHeight || 28
        const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

        const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable + hPagination

        return `${totalHeight}px`
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        localStorage.setItem("page", JSON.stringify(value));
        getData(value, rowsCount, filter);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        localStorage.setItem("page", JSON.stringify(1));
        localStorage.setItem("rows", JSON.stringify(value));
        setRowsCount(value);
        getData(1, value, filter);
    }

    const onChangePaging2 = (value) => {
        setNumPage2(value);
        getData2(value, rowsCount, filter);
    }

    const onChangeLimit2 = (value) => {
        setNumPage2(1);
        setRowsCount2(value);
        getData2(1, value, filter);
    }

    const ButtonComponents = () => {
        return (
            title2 === 'Activity Tracker' ?
                <div className="row" spacing={'12px'}>
                    <div className='col-12' spacing={'12px'}>
                        <Grid container item spacing={1} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
                            <Button
                                size='small'
                                variant="outlined"
                                className='d-flex align-items-center'
                                sx={{
                                    padding: '3px 6px',
                                    gap: '4px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '18px !important',
                                    minHeight: '28px !important',
                                    borderRadius: '8px',
                                    '&:hover': {
                                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                    }
                                }}
                                onClick={() => switchTitle()}
                            >
                                <Icon
                                    svgIconProps={{
                                        sx: {
                                            height: '12px',
                                            width: '12px',
                                        }
                                    }}
                                    iconName={'posted'}
                                />
                                <Typography
                                    sx={{
                                        fontFamily: 'Nunito',
                                        marginTop: '2px !important',
                                        fontSize: '12px',
                                        fontWeight: '700',
                                        lineHeight: '20px',
                                        letterSpacing: '0em',
                                        textAlign: 'center',
                                        color: '#0087C2',
                                    }}
                                >
                                    {title2}
                                </Typography>
                            </Button>
                        </Grid>
                    </div>
                </div>
                :
                <ButtonToDo ReloadData={ReloadData} title2={title2} switchTitle={switchTitle} />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: '24px 32px', ...freezeContainer }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    btnPageRef={btnPageRef}
                    title={title}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div id='todo' hidden={hide}>
                        <div ref={btnTableRef} />

                        <div
                            className="rounded-10 table-responsive"
                            style={{
                                maxHeight: `calc(100vh - ${getContainerHeight()})`,
                                marginTop: '16px',
                                padding: '2px 16px',
                                borderRadius: '12px',
                                background: '#F2F2F2'
                            }}
                        >
                            {
                                isLoading ?
                                    <LoadingSpinner />
                                    :
                                    <div className='mt-1'>
                                        <TableNoFilter
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={getData}
                                            columnData={columnData}
                                            dataMap={dataMap}
                                            SelectedData={SelectedData}
                                            setSelectedData={setSelectedData}
                                            GoTo={GoTo}
                                        />
                                    </div>
                            }
                        </div>

                        <div style={{ marginTop: '16px' }}>
                            <Paginate
                                page={numPage}
                                limit={rowsCount}
                                totalData={totalRows}
                                onChange={onChangePaging}
                                onChangeLimit={onChangeLimit}
                            />
                        </div>
                    </div>

                    <div id='done' hidden={hide2}>
                        <div ref={btnTableRef}>
                            {
                                ButtonComponent.RefreshButton(
                                    sortArr,
                                    rowsCount,
                                    columnData,
                                    setGoTo,
                                    handleReset,
                                    setFilterBy,
                                    filterBy,
                                    setFilter,
                                    setNumPage2,
                                    setRowsCount2,
                                    getData2,
                                    stateX,
                                    setStateX
                                )
                            }
                        </div>

                        <div
                            className="rounded-10 table-responsive"
                            style={{
                                maxHeight: `calc(100vh - ${getContainerHeight()})`,
                                marginTop: '16px',
                                padding: '2px 16px',
                                borderRadius: '12px',
                                background: '#F2F2F2'
                            }}
                        >
                            {
                                isLoading2 ?
                                    <LoadingSpinner />
                                    :
                                    <>
                                        <div className='mt-1'>
                                            <TableX
                                                uniqueKey={'id'}
                                                goTo={goTo}
                                                rowsCount={rowsCount2}
                                                setNumPage={setNumPage2}
                                                setRowsCount={setRowsCount2}
                                                getData={getData2}
                                                columnData={columnData2}
                                                dataMap={dataMap2}
                                                SelectedData={SelectedData2}
                                                setSelectedData={setSelectedData2}
                                                setFilter={setFilter}
                                                setFilterBy={setFilterBy}
                                                stateX={stateX}
                                                setStateX={setStateX}
                                                sortArr={sortArr}
                                                setSortArr={setSortArr}
                                                setGoTo={setGoTo}
                                            />
                                        </div>
                                    </>
                            }
                        </div>

                        <div style={{ marginTop: '16px' }}>
                            <Paginate
                                page={numPage2}
                                limit={rowsCount2}
                                totalData={totalRows2}
                                onChange={onChangePaging2}
                                onChangeLimit={onChangeLimit2}
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>
            
            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </Box>
    );
})

export default ToDo